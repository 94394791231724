import React from "react";
import PropTypes from "prop-types";
import { isFunction, merge } from "lodash";
import i18n from "@viz-ui/i18n/i18n";
import { PIE_CHART } from "@visualizer/modules/visualization/highCharts/boost/boostThresholds";
import tokens from "@paprika/tokens";
import pubsub from "pubsub-js";

import ReactHighChart from "../highChart/reactHighChart";
import {
  canAnimate,
  updateHcConfig,
  updateLabelFontSize,
  getBoostConfig,
  clickWrapper,
  baseConfig,
  configPath,
} from "../services/highChartService";

class PieChart extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    config: PropTypes.object,
    rawData: PropTypes.array,
    redrawIndex: PropTypes.number,
    zoomInHandler: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    config: {},
    rawData: [],
    redrawIndex: 0,
    zoomInHandler: () => {},
  };

  constructor(props) {
    super(props);
    this.state = { hcConfig: {}, chartReDraw: false };
  }

  componentDidMount() {
    pubsub.subscribe("chartRedraw", () => {
      this.setState({ chartReDraw: !this.state.chartReDraw });
    });
    this.setState({ hcConfig: this.getConfig(this.props.config, this.props.rawData) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.config !== nextProps.config)
      this.setState({ hcConfig: this.getConfig(nextProps.config, nextProps.rawData) });
  }

  onLegendItemClick = item => {
    const showAll = !this.state.hcConfig.series.some(entry => entry.visible && entry.name !== item.name);
    const series = this.state.hcConfig.series.map(entry =>
      Object.assign({}, entry, {
        visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
      })
    );
    this.setState({
      hcConfig: updateHcConfig(this.state.hcConfig, { series }),
    });
  };

  onLegendItemDoubleClick = item => {
    const series = this.state.hcConfig.series.map(entry =>
      Object.assign({}, entry, { visible: entry.name === item.name })
    );
    this.setState({
      hcConfig: updateHcConfig(this.state.hcConfig, { series }),
    });
  };

  getConfig = (config, data) => {
    let totalTextToShowInChart = "";
    const localeTotalString = i18n.t("_Chart.StatisticsViz.Total.Label_");

    let hcConfig = {
      legend: {
        enabled: config.showLegend,
        align: "right",
        verticalAlign: "top",
        labelFormatter: function() {
          return config.labelFormatter && this.options.name !== "(blank)"
            ? config.labelFormatter(this.options.legendDisplayName)
            : this.options.legendDisplayName;
        },
      },
      boost: getBoostConfig(config),
      chart: {
        events: {
          render: function() {
            if (config.showTotals) {
              let total = 0;
              this.series[0].data.forEach(value => {
                total += value.visible ? value.value : 0;
              });
              total = total % 1 === 0 ? total : total.toFixed(2); // if total is integer return integer else return float fixed to decimal.
              const totalString = `<span style="font-size:.95rem;color:#666;font-weight:600;">${localeTotalString.toUpperCase()}: ${total}</span>`;
              const left = this.chartWidth * 0.65; // moving total position 65% of the width of the chart
              const top = this.chartHeight * 0.95; //moving the total to 99% of the total height of the chart to keep it responsive.
              if (totalTextToShowInChart) {
                totalTextToShowInChart.attr({ text: "" });
              }
              totalTextToShowInChart = this.renderer
                .text(totalString, left, top)
                .attr({
                  zIndex: 5,
                })
                .add();
            }
          },
        },
      },
      plotOptions: {
        series: {
          events: {},
          point: {
            events: {
              click: clickWrapper(null, point => {
                this.zoomIn([point.name]);
              }),
              legendItemClick: clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
            },
          },
          dataLabels: {
            enabled: config.displayDataLabels || config.showPercentageTicks,
            formatter: function() {
              const percentLabel = config.displayDataLabels
                ? ` (${this.percentage.toFixed(2)}%)`
                : `${this.percentage.toFixed(2)}%`;
              return `${config.displayDataLabels ? config.valueFormatter(this.point.value) : ""}${
                config.showPercentageTicks ? percentLabel : ""
              }`;
            },
            distance: 20,
          },
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              const header = `
                  <span style="text-decoration: underline grey;">${
                    config.labelFormatter && this.name !== "(blank)"
                      ? config.labelFormatter(this.legendDisplayName)
                      : this.legendDisplayName
                  }</span>
                  <br/>
                `;
              const body = `<b>${config.valueFormatter(this.value)} (${this.percentage.toFixed(2)}%)</b>`;
              return header + body;
            },
          },
          turboThreshold: 0,
          animation: canAnimate(),
        },
      },
      // Adding legendDisplayName property to handle (blank) value  & to localize the string (issue GRCPRD-4135)
      series: [
        data && {
          type: "pie",
          showInLegend: true,
          name: config.colorByField,
          boostThreshold: PIE_CHART,
          dataLabels: {
            connectorColor: this.isPatternFill && tokens.border.color,
          },
          data: data.map((value, index) => ({
            color: this.isPatternFill ? this.patternFillPalettes(index) : value.color,
            name: value.key,
            legendDisplayName: value.key === "(blank)" ? i18n.t("_Filter.BlankValue.Label_") : value.key,
            y: Math.abs(value.values),
            value: value.values,
            visible: value.visible !== false,
          })),
          innerSize: config.donut ? `${config.donutRatio * 100}%` : null,
        },
      ],
    };

    hcConfig = updateLabelFontSize(hcConfig, configPath);
    //update value for hcConfig to re render the chart to reflect the changes//
    hcConfig.showValue = config.displayDataLabels;
    hcConfig.showPercentage = config.showPercentageTicks;
    hcConfig.showTotals = config.showTotals;
    return merge({}, baseConfig(), hcConfig);
  };

  zoomIn = keyArray => {
    if (isFunction(this.props.zoomInHandler)) {
      this.props.zoomInHandler(keyArray);
    }
  };

  render() {
    return (
      <ReactHighChart
        className={`redraw-index-${this.props.redrawIndex}`}
        config={this.state.hcConfig}
        redrawIndex={this.props.redrawIndex}
        chartReDraw={this.state.chartReDraw}
      />
    );
  }
}
export default PieChart;
