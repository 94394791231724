import StoryboardFilter from "./storyboardFilter";
import FieldFormat from "../field/fieldFormat";
import FilterFieldAdapter from "./filterFieldAdapter";
import FilterValueAdapter from "./filterValueAdapter";
import filterTypes from "@viz-ui/models/storyboardFilter/storyboardFilterTypes";
import filterConditions from "@viz-ui/models/storyboardFilter/storyboardFilterConditions";
import RelativeDateTranslator from "@viz-ui/models/relativeDate/relativeDateTranslator";

function serializeStoryboardFilter(filter) {
  const filterObject = {
    id: filter.id(),
    display_name: filter.displayName(),
    is_active: filter.isActive(),
    type: filter.type(),
    operator: filter.operator(),
    fields: FilterFieldAdapter.serializeFilterFieldMap(filter.filterFields()),
    field_format: fieldFormatModelToFieldFormat(filter.fieldFormat()),
    field_type: filter.fieldType(),
    excludeToday: filter.excludeToday(),
    select_all_state: filter.selectAllState(),
    is_current_edited_dynamic_filter: filter.isCurrentEditedDynamicFilter(), //to set current filter as the currently edited one on frozen filter
    is_selected_dynamic_filter: filter.isSelectedDynamicFilter(), //selected filter to set as dynamic filter
    is_current_dynamic_filter: filter.isCurrentDynamicFilter(), //set filter as dynamic filter
    is_selected_frozen_filter: filter.isSelectedFrozenFilter(), // filter selectd as current filter
    is_frozen_filter: filter.isFrozenFilter(), // filter selected as current filter after apply filter
    is_current_session_filter: filter.isCurrentSessionFilter(), // currentSession filter will maintain true if edited but not saved
  };

  // Need to set these only if they exist. Important to distinguish null from not defined.
  if (filter.isValueDefined()) {
    filterObject.value = FilterValueAdapter.serializeFilterValueModelToString(filter.value());
  }
  if (filter.isValuesDefined()) {
    filterObject.values = FilterValueAdapter.serializeFilterValueModelsToStrings(filter.values());
  }
  if (filter.isUnSelectedValuesDefined()) {
    filterObject.un_selected_values = FilterValueAdapter.serializeFilterValueModelsToStrings(filter.unSelectedValues());
  }

  return filterObject;
}

function fieldFormatModelToFieldFormat(fieldFormat) {
  if (!fieldFormat) return null;
  return fieldFormat.toJson();
}

function getValueStringsForQuery(filter) {
  let values = null;
  switch (filter.type()) {
    case filterTypes.OPTIONAL_SINGLESELECT:
    case filterTypes.MANDATORY_SINGLESELECT:
      if (filter.isValueDefined()) values = [filter.value()];
      break;
    case filterTypes.CONDITIONAL:
      values = normalizeConditionalFilterValues(filter);
      break;
    case filterTypes.MULTISELECT:
      if (filter.isUnSelectedValuesDefined() && filter.unSelectedValues().length > 0) {
        values = filter.unSelectedValues();
      } else if (filter.isValuesDefined()) {
        values = filter.values();
      }
      break;
  }
  return FilterValueAdapter.serializeFilterValueModelsToStrings(values);
}

function normalizeConditionalFilterValues(filter) {
  let values = null;
  switch (filter.operator()) {
    case filterConditions.BLANK:
    case filterConditions.NOT_BLANK:
      values = [];
      break;
    case filterConditions.BETWEEN_OR_EQUAL_TO:
      if (filter.isValuesDefined()) values = filter.values().slice(0, 2);
      break;
    default:
      if (filter.isValuesDefined()) values = filter.values().slice(0, 1);
  }
  return values;
}

function serializeForQuery(filter, tableId) {
  const field = filter.filterFields().find(filterField => filterField.tableId() === tableId);
  if (!field) return null;
  let values = getValueStringsForQuery(filter);
  if (!values) return null;

  let operator;
  switch (filter.type()) {
    case filterTypes.CONDITIONAL:
      operator = filter.operator();
      break;
    case filterTypes.OPTIONAL_SINGLESELECT:
    case filterTypes.MANDATORY_SINGLESELECT:
    case filterTypes.MULTISELECT:
      if (filter.isUnSelectedValuesDefined() && filter.unSelectedValues().length > 0) {
        if (filter.unSelectedValues()[0].value() == "") {
          // To address un-selecting blank value
          operator = filterConditions.NOT_BLANK;
          values = [];
        } else {
          operator = filterConditions.NOT_EQUAL;
        }
      } else {
        operator = filterConditions.EQUAL;
      }
      break;
  }
  return {
    name: field.name(),
    globalFilter: { operator, values },
    active: true,
  };
}

function deserializeStoryboardFilter(filterData) {
  return new StoryboardFilter()
    .id(filterData.id)
    .displayName(filterData.display_name)
    .isActive(filterData.is_active)
    .type(filterData.type)
    .operator(filterData.operator)
    .value(FilterValueAdapter.deserializeFilterValueStringToModel(filterData.value))
    .values(FilterValueAdapter.deserializeFilterValueStringsToModels(filterData.values))
    .filterFields(FilterFieldAdapter.deserializeFilterFieldMap(filterData.fields))
    .fieldFormat(fieldFormatToFieldModel(filterData.field_format))
    .fieldType(filterData.field_type)
    .excludeToday(filterData.excludeToday)
    .selectAllState(filterData.select_all_state)
    .isSelectedDynamicFilter(filterData.is_selected_dynamic_filter)
    .isCurrentDynamicFilter(filterData.is_current_dynamic_filter)
    .isSelectedFrozenFilter(filterData.is_selected_frozen_filter)
    .isFrozenFilter(filterData.is_frozen_filter)
    .isCurrentEditedDynamicFilter(filterData.is_current_edited_dynamic_filter)
    .isCurrentSessionFilter(filterData.is_current_session_filter)
    .unSelectedValues(FilterValueAdapter.deserializeFilterValueStringsToModels(filterData.un_selected_values));
}

function fieldFormatToFieldModel(fieldFormat) {
  return FieldFormat.fromJson(fieldFormat);
}

function translateStoryboardFilter(globalFilter) {
  const useFiscalYear = false;
  if (globalFilter.operator === "relative") {
    globalFilter = Object.assign({}, globalFilter);
    const optimizedValue = globalFilter.values.filter(v => !!v);
    const { startDate, endDate } = RelativeDateTranslator.getRelativeDate(
      optimizedValue,
      globalFilter.field_type,
      useFiscalYear,
      globalFilter.excludeToday
    );
    globalFilter.values = [startDate, endDate];
    globalFilter.operator = filterConditions.BETWEEN_OR_EQUAL_TO;
  }
  return globalFilter;
}

export default {
  serializeStoryboardFilter,
  serializeForQuery,
  deserializeStoryboardFilter,
  translateStoryboardFilter,
  normalizeConditionalFilterValues,
};
