import Button from "acl-ui/components/Button";
import services from "../../services/glue/appGlue";
import Modal, { ModalHeader, ModalFooter, ModalBody } from "acl-ui/components/Modal";
import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  openModal: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
class DeleteConfirmModal extends React.Component {
  state = {
    isOpen: this.props.isOpen || false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.openModal !== this.props.openModal) {
      this.setState({ isOpen: this.props.openModal });
    }
  }

  handleClose = () => {
    this.props.onCancel();
  };

  render() {
    return (
      <Modal
        isOpen={this.state.isOpen}
        onEscKey={this.handleClose}
        onOverlayClick={this.handleClose}
        ariaLabel={this.props.ariaLabel}
      >
        <ModalHeader hasCloseButton onClose={this.handleClose}>
          <h3>{services.Localize.getLocalizedString("_Storyboards.Delete.Header_")}</h3>
        </ModalHeader>
        <ModalBody>
          <p>{services.Localize.getLocalizedString("_Storyboards.Delete.Body_")}</p>
        </ModalBody>
        <ModalFooter>
          <Button className="delete-confirm-button" onClick={this.props.onConfirm} type="destructive">
            {services.Localize.getLocalizedString("_Storyboards.Delete.DeleteButton_")}
          </Button>
          <Button className="delete-cancel-button" onClick={this.props.onCancel} type="minor">
            {services.Localize.getLocalizedString("_Storyboards.Delete.CancelLink_")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

DeleteConfirmModal.propTypes = propTypes;

window.DeleteConfirmModal = DeleteConfirmModal;
