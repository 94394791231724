class UserService {
  setCurrentUser(currentUser) {
    this._currentUser = currentUser;
  }

  setStoryboardRole(role) {
    this._storyboardRole = role;
  }

  setCanCreateStoryboard(canCreateStoryboard) {
    this._canCreateStoryboard = canCreateStoryboard;
  }

  getCurrentUser() {
    return this._currentUser;
  }

  isAnonymous() {
    return this._storyboardRole === "anonymous";
  }

  isViewer() {
    return this._storyboardRole === "viewer";
  }

  isAdmin() {
    return this._storyboardRole === "admin" || this.isCreator();
  }

  isCreator() {
    return this._storyboardRole === "creator";
  }

  isEditor() {
    return this._storyboardRole === "editor";
  }

  role() {
    return this._storyboardRole;
  }

  canCreateStoryboard() {
    return this._canCreateStoryboard;
  }

  canEditStoryboard() {
    return this.isEditor() || this.isAdmin() || this.isCreator();
  }

  canViewAndDuplicateStoryboard() {
    return this.isViewer() && this.canCreateStoryboard();
  }
}

export default new UserService();
