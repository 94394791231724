import extend from "lodash/extend";
import i18n from "@viz-ui/i18n/i18n";
import { fieldset } from "react-dom-factories";

const resultObj = {
  metaData: {
    id: 0,
    name: "",
    recordCount: 0,
    fields: [],
  },
  result: {
    columns: {},
    data: [],
    lastRecNo: 0,
    totalRecordCount: 0,
  },
};

const metadataFieldNames = [
  "metadata.assignee",
  "metadata.closed_at",
  "metadata.group",
  "metadata.priority",
  "metadata.publish_date",
  "metadata.publisher",
  "metadata.status",
  "metadata.updated_at",
];

const systemColumnNames = ["metadata.position", "metadata.record_id", "metadata.row_number"];

export default class TableDataAdapter {
  static format(tableId, data, startIndex = 1) {
    return extend({}, resultObj, {
      metaData: metadata(tableId, data),
      result: {
        columns: columnMap(data.table.fieldDefs),
        data: rowMap(data.table.rows.analytic, startIndex),
        lastRecNo: data.table.rows.lastRowOffset,
        totalRecordCount: data.table.rows.totalRowCount,
      },
    });
  }

  static formatMetadata(tableId, data) {
    return extend({}, resultObj.metaData, metadata(tableId, data));
  }

  static formatTableValues(fieldName, responseData) {
    const tableData = responseData.table.unique;
    const { lastRowOffset, totalRowCount } = tableData;

    return {
      values: tableData.analytic.map(valueObj => valueObj[fieldName]),
      lastRowOffset,
      totalRowCount,
    };
  }
}

function metadata(tableId, data) {
  return {
    id: tableId,
    name: data.table.name,
    recordCount: parseInt(data.table.count, 10),
    fields: fields(data.table.fieldDefs),
    systemFields: systemFields(data.table.fieldDefs),
  };
}

function fields(fieldDefs) {
  return fieldDefs.reduce((fields, field) => {
    const fieldInfo = {
      name: field.fieldName,
      columnTitle: fieldDisplayName(field),
      type: field.type,
      isPrimaryKey: !!field.isPrimaryKey,
    };
    if (!systemColumnNames.includes(field.fieldName)) fields.push(fieldInfo);
    return fields;
  }, []);
}

function systemFields(fieldDefs) {
  return fieldDefs
    .filter(fieldDef => systemColumnNames.includes(fieldDef.fieldName))
    .map(fieldDef => ({
      name: fieldDef.fieldName,
      columnTitle: fieldDef.fieldName,
      type: fieldDef.type,
      isPrimaryKey: !!fieldDef.isPrimaryKey,
    }));
}

function columnMap(fieldDefs) {
  return fieldDefs.reduce((fields, curCol) => {
    if (!systemColumnNames.includes(curCol.fieldName)) {
      fields[curCol.fieldName] = curCol.type;
    }
    return fields;
  }, {});
}

function fieldDisplayName({ fieldName, displayName }) {
  if (metadataFieldNames.includes(fieldName)) {
    return i18n.t(`_Table.${fieldName}.DisplayName_`);
  }
  return displayName;
}

function rowMap(rows, startIndex) {
  return rows.map((row, rowIndex) => {
    if (row["metadata.position"]) row["metadata.position"] = parseInt(row["metadata.position"], 10);
    if (!row["metadata.record_id"]) {
      const dataSetPosition = startIndex + rowIndex;
      row["metadata.position"] = dataSetPosition;
      row["metadata.record_id"] = dataSetPosition;
    }
    return row;
  });
}
