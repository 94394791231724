(() => {
  var aclDataTablePanel = {
    bindings: {
      commentCounts: "<",
      data: "<",
      fields: "<",
      fieldFormatIdentifier: "<",
      filteredRecordCount: "<",
      onAddComment: "&",
      onScrollToBottom: "&",
      onDeleteRows: "&",
      enableAllButtons: "&",
      disableAllButtons: "&",
      onBulkUpdate: "&",
      onDeleteInProgress: "&",
      onProcessRecord: "&",
      onReload: "&",
      tabIndex: "<",
    },
    controller: "DataTablePanelController",
    controllerAs: "dataTablePanel",
    templateUrl: "visualizer/js/modules/core/dataTablePanel/dataTablePanel.tpl.html",
  };

  angular.module("acl.visualizer.dataTablePanel").component("aclDataTablePanel", aclDataTablePanel);
})();
