const ALL_MODE = "all";
const INDETERMINATE_MODE = "indeterminate";

export default class RowSelection {
  constructor() {
    this.selectAllMode = "";
    this.inverseMode = false;
    this.checkedRowIds = [];
    this.selectedRowId = undefined;
  }

  getSelectAllMode() {
    return this.selectAllMode;
  }

  setSelectAllMode() {
    this.selectAllMode = ALL_MODE;
  }

  setIndeterminateMode() {
    this.selectAllMode = INDETERMINATE_MODE;
  }

  getCheckedRowIds() {
    return this.checkedRowIds;
  }

  setCheckedRowIds(checkedRowIds = []) {
    this.checkedRowIds = checkedRowIds;
  }

  getSelectedRowId() {
    return this.selectedRowId;
  }

  isSelectAllMode() {
    return this.selectAllMode === ALL_MODE;
  }

  isIndeterminateMode() {
    return this.selectAllMode === INDETERMINATE_MODE;
  }

  setInverseMode(inverseMode) {
    this.inverseMode = inverseMode;
  }

  getInverseMode() {
    return this.inverseMode;
  }

  resetCheckboxes() {
    this.selectAllMode = "";
    this.inverseMode = false;
    this.checkedRowIds = [];
  }

  selectRow(rowDataId) {
    this.selectedRowId = rowDataId;
  }

  checkRow(rowDataId, dataSetSize) {
    if (this.inverseMode) {
      this._removeRowDataIdFromCheckedList(rowDataId);
    } else {
      this._addRowDataIdToCheckedList(rowDataId);
    }
    this._updateSelectAllMode(dataSetSize);
  }

  uncheckRow(rowDataId, dataSetSize) {
    if (this.isSelectAllMode() && this._isCheckedRowIdsEmpty()) {
      this.setInverseMode(true);
    }
    if (this.inverseMode) {
      this._addRowDataIdToCheckedList(rowDataId);
    } else {
      this._removeRowDataIdFromCheckedList(rowDataId);
    }
    this._updateSelectAllMode(dataSetSize);
  }

  _addRowDataIdToCheckedList(rowDataId) {
    if (!this.checkedRowIds.includes(rowDataId)) {
      this.checkedRowIds.push(rowDataId);
    }
  }
  _removeRowDataIdFromCheckedList(rowDataId) {
    if (this.checkedRowIds.includes(rowDataId)) {
      const checkedRowIndex = this.checkedRowIds.indexOf(rowDataId);
      this.checkedRowIds.splice(checkedRowIndex, 1);
    }
  }

  _updateSelectAllMode(dataSetSize) {
    const isSelectAll =
      (this.inverseMode && this._isCheckedRowIdsEmpty()) ||
      (!this.inverseMode && this._isCheckedRowIdsFull(dataSetSize));
    const isIndeterminate = !this._isCheckedRowIdsEmpty() && this.checkedRowIds.length < dataSetSize;
    const shouldReset =
      (this.inverseMode && this._isCheckedRowIdsFull(dataSetSize)) ||
      (!this.inverseMode && this._isCheckedRowIdsEmpty());

    if (isSelectAll) {
      this.setSelectAllMode();
    } else if (isIndeterminate) {
      this.setIndeterminateMode();
    } else if (shouldReset) {
      this.resetCheckboxes();
    }
  }

  _isCheckedRowIdsEmpty() {
    return this.checkedRowIds.length === 0;
  }

  _isCheckedRowIdsFull(dataSetSize) {
    return this.checkedRowIds.length === dataSetSize;
  }
}
