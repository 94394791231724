(() => {
  const actions = {
    drilldown: "drilldown",
  };
  const entities = {
    metric: "metric",
    interpretation: "interpretation",
  };

  var actionControlMap = {};

  class AccessChecker {
    constructor($q, AccessControlAdapter, AccessControlManager) {
      this._$q = $q;
      this._AccessControlAdapter = AccessControlAdapter;
      this._AccessControlManager = AccessControlManager;

      this.actions = actions;
      this.entities = entities;

      initActionControlMap();
    }

    can(action, entity, id) {
      var deferred = this._$q.defer();

      if (actionControlMap[entity][id]) {
        deferred.resolve(!!actionControlMap[entity][id][action]);
      }

      this._AccessControlManager.loadAccessControl(entity, id).then(accessControl => {
        actionControlMap[entity][id] = this._AccessControlAdapter.serializeAccessControl(accessControl);
        deferred.resolve(!!actionControlMap[entity][id][action]);
      });

      return deferred.promise;
    }
  }

  function initActionControlMap() {
    Object.keys(entities).forEach(key => {
      actionControlMap[entities[key]] = {};
    });
  }

  angular.module("acl.visualizer.model.accessControl").service("AccessChecker", AccessChecker);
})();
