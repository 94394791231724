import ReactDirective from "acl-ui/angular/reactDirective.umd";
import HamburgerMenu from "@viz-ui/components/hamburgerMenu/hamburgerMenu";
import ChartBaseContainer from "@viz-ui/components/charts/ChartBaseContainer";
import slidingNavTabs from "./slidingNavTabs.directive";

angular
  .module("acl.visualizer.slidingNavTabs", ["acl.common.event", "acl.visualizer.dataModel", "acl.visualizer.tabState"])
  .directive("aclSlidingNavTabs", slidingNavTabs)
  .directive("hamburgerMenu", ReactDirective.getFactory(HamburgerMenu))
  .directive("chartBaseContainer", ReactDirective.getFactory(ChartBaseContainer));
