import tableCapability from "@visualizer/modules/core/tableCapability/tableCapability.service";
import VisualizerAbilities from "@viz-ui/services/abilities/visualizerAbilitiesService";

export default class ProcessPanelController {
  constructor($element, $window, AppConfig, DataModel, ProcessPanelService, $timeout) {
    "ngInject";
    VisualizerAbilities.fetchAbilities().then(() => this.updatePanel());

    this.element = $element.find(".process-panel__body")[0];
    this.$window = $window;
    this.$timeout = $timeout;
    this.dataModel = DataModel;
    this.AppConfig = AppConfig;
    this.ProcessPanelService = ProcessPanelService;
    this.useRecordProcessPanelInViz = !!AppConfig.features.recordProcessPanelInViz;

    this.$onChanges = () => {
      this.updatePanel();
    };
  }

  //Setting the focus back to checkbox
  handleCloseClick = () => {
    //setting focus back to the select-all checkbox when having large data set
    if (this.checkedRowCount !== this.checkedRowIds.length) {
      document.querySelector(".data-grid__select-all").focus();
    } else {
      // to get the correct row index, to set the focus back
      const rowId = this.selectedRowData["metadata.position"];
      let rows = document.querySelectorAll(".htCenter");
      let checkBoxElements = document.querySelectorAll(".htCheckboxRendererInput");
      //adding conditions to prevent focus to checkbox when the user clicks on cells instead of a checkbox
      if (rows.length && checkBoxElements.length) {
        let rowIndex = 0;
        rows.forEach((row, index) => {
          if (row.innerHTML === `${rowId}`) {
            rowIndex = index;
          }
        });
        checkBoxElements[rowIndex].focus();
      }
    }
    this.onClosePanel();
  };

  updatePanel = () => {
    if (typeof this.selectedRowId === "undefined" || typeof this.selectedRowData === "undefined") return;
    const { renderProcessPanel } = this.$window;

    this.headlineLabelLocalizeKey = tableCapability.canRemediateRecords()
      ? "_ProcessPanel.Headline.Label_"
      : "_ProcessPanel.Headline.ViewRecord.Label_";

    const rowId = this.selectedRowId;
    const rowPosition = this.selectedRowData["metadata.position"];
    const tableConfigColumns = this.dataModel.tableConfig.columns();

    const rowData = this.ProcessPanelService.formatRowData(
      this.fieldFormatIdentifier,
      this.selectedRowData,
      this.fieldModels,
      tableConfigColumns
    );

    // it includes all character fields data (both visible and non visible) that are in the table.
    const characterFieldsRowData = this.ProcessPanelService.formatRowData(
      this.fieldFormatIdentifier,
      this.selectedRowData,
      this.fieldModels.filter(x => x.type() === "character"),
      tableConfigColumns.map(x => ({ ...x, visible: true }))
    );

    const isArchived = this.dataModel.project.archived();

    const abilities = VisualizerAbilities.getAbilities();

    const props = {
      activeRecordId: rowId,
      activeRowId: rowPosition,
      selectedRecords: this.checkedRowIds,
      isSelectionInversed: this.rowCheckedInverse,
      onCheck: () => {
        this.onCheck({ rowId: this.selectedRowId });
      },
      numSelectedRecords: this.checkedRowCount,

      isOpen: this.isOpen,

      projectId: this.AppConfig.project_id,
      controlId: this.AppConfig.control_id,
      controlTestId: this.AppConfig.control_test_id,

      isFirstRecord: this.isFirstRecord,
      isLastRecord: this.isLastRecord,
      onClickPrevArrow: this.onPrevRowClick,
      onClickNextArrow: this.onNextRowClick,

      data: rowData,
      characterFieldsData: characterFieldsRowData,
      filters: this.dataModel.getFilterConfig(),

      onProcessedRecord: (processed, unprocessed, newFieldData, removeFromView, questionnaireFieldData) => {
        const metadataFields = this.ProcessPanelService.fixMetadataNames(newFieldData);

        const oldFields = this.selectedRowData;
        const newFields = { ...questionnaireFieldData, ...metadataFields };

        const changedFields = this.ProcessPanelService.onlyChangedFields(newFields, oldFields);

        this.onProcessRecord({
          processed,
          unprocessed,
          newFieldData: changedFields,
          removeFromView,
        });
      },

      onDeletedRecord: () => {
        this.onDeleteRecord({ rowId: this.selectedRowId });
      },
      onDeleteInProgress: () => {
        this.onDeleteInProgress();
      },
      onAddedComment: (comment, attachment) => {
        this.onAddComment({
          rowId: this.selectedRowId,
          hasComment: !!comment,
          hasAttachment: !!attachment,
        });
      },

      // Bulk review mode props
      onClickReviewRecords: () => {
        this.onEnterBulkReviewMode();
      },
      onCancelReviewRecords: () => {
        this.onExitBulkReviewMode();
      },

      onProcessedRecords: (requireDelayJob, processed, unprocessed, removeFromView, fromQuestionnaire) => {
        this.onProcessBulk({
          requireDelayJob,
          processed,
          unprocessed,
          fromQuestionnaire,
          removeFromView,
        });
      },

      onDeletedRecords: (deleted, notDeleted) => {
        this.onDeleteBulk({ deleted, notDeleted });
      },

      // feature based on user permissions
      isGroupSelectorEnabled: abilities.can_manage_exceptions_groups,
      isFooterVisible: tableCapability.canRemediateRecords() && !isArchived && abilities.can_process_exceptions,
      isDeleteVisible: abilities.can_delete_exception,
      isAddCommentVisible: !isArchived && abilities.can_process_exceptions,
      isRecordCheckboxVisible: tableCapability.canRemediateRecords(),
      isActivityTabVisible: tableCapability.canRemediateRecords(),
    };

    if (this.useRecordProcessPanelInViz) {
      this.recordProcessPanelProps = props;
    }
    if (!this.useRecordProcessPanelInViz && renderProcessPanel) {
      this.panelRef = renderProcessPanel(props, this.element);
    }
  };
}
