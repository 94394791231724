import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";
import services from "../../services/glue/appGlue";
import Switch from "acl-ui/components/Switch";

window.StoryboardModeToggleBar = createReactClass({
  propTypes: {
    showDesignToggle: PropTypes.bool,
    editMode: PropTypes.bool,
    onDesignModeToggle: PropTypes.func,
  },

  getInitialState() {
    return {
      editMode: this.props.editMode,
    };
  },

  onDesignModeToggle(value) {
    this.setState({ editMode: value });
    this.props.onDesignModeToggle(value);
  },

  getDesignModeSwitch() {
    const label = services.Localize.getLocalizedString("_Storyboards.TopToolbar.DesignModeLabel_");
    return (
      <span>
        <span id="designSwitch" className="switch-button">
          <span className="switch-label">{label}</span>
          <Switch labelText={label} value={this.state.editMode} onChange={this.onDesignModeToggle} />
        </span>
      </span>
    );
  },

  render() {
    var panel = <div />;
    if (this.props.showDesignToggle) {
      panel = <div className="storyboard-mode-toggle">{this.getDesignModeSwitch()}</div>;
    }
    return panel;
  },
});
