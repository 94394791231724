import FieldFormat from "../../models/field/fieldFormat";
import GlobalFieldFormatMap from "./globalFieldFormatMap";
import { TableDataFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import cloneDeep from "lodash/cloneDeep";
import numeral from "numeral";

export const formatFieldValueShortNumber = (value, fieldFormatIdentifier, fieldName) => {
  let fieldFormat = GlobalFieldFormatMap.getFieldFormat(fieldFormatIdentifier, fieldName);
  const fieldType = GlobalFieldFormatMap.getFieldType(fieldFormatIdentifier, fieldName);

  fieldFormat = fieldFormat ? cloneDeep(fieldFormat) : new FieldFormat();

  fieldFormat
    .isRaw(false)
    .precision(2)
    .keepTrailingZeros(false)
    .abbreviate(true);
  const fieldFormatJson = fieldFormat.toJson();

  return TableDataFormatter.formatValue(value, fieldType, fieldFormatJson);
};

export const numeralFormatter = (value, decimalSpace) => {
  if (isNaN(value)) return value;

  var formatter = "0";
  if (decimalSpace > 0) {
    formatter += ".[";
    for (var i = 0; i < decimalSpace; i++) {
      formatter += "0";
    }
    formatter += "]";
  }

  return numeral(value).format(formatter + "a");
};
