angular.module("acl.visualizer.charts").factory("StatisticsViz", function(DataModel, Sorter) {
  return {
    defaultDisplayConfig: function() {},

    getChartDirectiveConfig: function(interpretationId, chartConfig) {
      return {
        interpretationId: interpretationId,
        fieldNames: chartConfig.dataConfig.chartCategory.map(category => category.fieldName),
      };
    },

    populateChartConfigColumnDefs: function(chartConfigColumnDefs) {
      var fields = DataModel.table.fields();
      if (fields) {
        for (var fieldName in fields) {
          var tempColumnDef = {};
          tempColumnDef.displayName = fields[fieldName].displayName;
          tempColumnDef.fieldId = fields[fieldName].colId;
          tempColumnDef.fieldName = fieldName;
          tempColumnDef.type = fields[fieldName].type;

          if (
            tempColumnDef.type === "numeric" ||
            tempColumnDef.type === "character" ||
            tempColumnDef.type === "date" ||
            tempColumnDef.type === "datetime" ||
            tempColumnDef.type === "time"
          ) {
            chartConfigColumnDefs.chartCategory.push(tempColumnDef);
          }
        }
        chartConfigColumnDefs.chartCategory = Sorter.sort(chartConfigColumnDefs.chartCategory, {
          valueParser: item => item.displayName,
        });
      }
      return chartConfigColumnDefs;
    },

    chartConfigColumnDefs: function() {
      return {
        chartCategory: [],
      };
    },

    isValidDataConfig: function(dataConfig) {
      return !!(
        dataConfig &&
        dataConfig.chartCategory &&
        dataConfig.chartCategory.length &&
        dataConfig.chartCategory[0] &&
        dataConfig.chartCategory[0].fieldName
      );
    },
  };
});
