import i18n from "@viz-ui/i18n/i18n";

const operators = [
  {
    displayName: i18n.t("_CriteriaFilter.GreaterThan.Label_"),
    fieldName: ">",
    thresholdTypes: ["numeric", "percentage", "date", "time", "datetime", "character"],
    filterTypes: ["numeric", "percentage", "date", "time", "datetime"],
    argumentCount: 1,
  },
  {
    displayName: i18n.t("_CriteriaFilter.LessThan.Label_"),
    fieldName: "<",
    thresholdTypes: ["numeric", "percentage", "date", "time", "datetime", "character"],
    filterTypes: ["numeric", "percentage", "date", "time", "datetime"],
    argumentCount: 1,
  },
  {
    displayName: i18n.t("_CriteriaFilter.GreaterThanAndEqual.Label_"),
    fieldName: ">=",
    thresholdTypes: ["numeric", "percentage", "date", "time", "datetime", "character"],
    filterTypes: ["numeric", "percentage", "date", "time", "datetime"],
    argumentCount: 1,
  },
  {
    displayName: i18n.t("_CriteriaFilter.LessThanAndEqual.Label_"),
    fieldName: "<=",
    thresholdTypes: ["numeric", "percentage", "date", "time", "datetime", "character"],
    filterTypes: ["numeric", "percentage", "date", "time", "datetime"],
    argumentCount: 1,
  },
  {
    displayName: i18n.t("_CriteriaFilter.Equals.Label_"),
    fieldName: "==",
    thresholdTypes: [],
    filterTypes: ["numeric", "percentage", "date", "time", "datetime"],
    argumentCount: 1,
  },
  {
    displayName: i18n.t("_CriteriaFilter.NotEqual.Label_"),
    fieldName: "!=",
    thresholdTypes: [],
    filterTypes: ["numeric", "percentage", "date", "time", "datetime"],
    argumentCount: 1,
  },
  {
    displayName: i18n.t("_CriteriaFilter.Between.Label_"),
    fieldName: "between",
    thresholdTypes: [],
    filterTypes: ["numeric", "percentage", "date", "time", "datetime"],
    argumentCount: 2,
  },
  {
    displayName: i18n.t("_CriteriaFilter.NotBetween.Label_"),
    fieldName: "not-between",
    thresholdTypes: ["numeric", "percentage", "date", "time", "datetime", "character"],
    filterTypes: [],
    argumentCount: 2,
  },
  {
    displayName: i18n.t("_CriteriaFilter.BeginsWith.Label_"),
    fieldName: "begins-with",
    thresholdTypes: [],
    filterTypes: ["character"],
    argumentCount: 1,
  },
  {
    displayName: i18n.t("_CriteriaFilter.Contains.Label_"),
    fieldName: "contains",
    thresholdTypes: [],
    filterTypes: ["character"],
    argumentCount: 1,
  },
  {
    displayName: i18n.t("_CriteriaFilter.NotContain.Label_"),
    fieldName: "does-not-contain",
    thresholdTypes: [],
    filterTypes: ["character"],
    argumentCount: 1,
  },
  {
    displayName: i18n.t("_CriteriaFilter.IsBlank.Label_"),
    fieldName: "is-blank",
    thresholdTypes: [],
    filterTypes: ["numeric", "percentage", "date", "time", "datetime", "character"],
    argumentCount: 0,
  },
  {
    displayName: i18n.t("_CriteriaFilter.IsNotBlank.Label_"),
    fieldName: "is-not-blank",
    thresholdTypes: [],
    filterTypes: ["numeric", "percentage", "date", "time", "datetime", "character"],
    argumentCount: 0,
  },
];

function getOperatorByName(operatorName) {
  var index;
  for (index = 0; index < operators.length; index++) {
    if (operators[index].fieldName === operatorName) {
      return operators[index];
    }
  }
  return null;
}

class ConditionalCriteriaOperators {
  isValidOperator(operatorName) {
    return !!getOperatorByName(operatorName);
  }

  getOperators(datatype, filter = false, threshold = false) {
    return operators.filter(function(item) {
      var include = false;
      if (filter && item.filterTypes.indexOf(datatype) > -1) {
        include = true;
      }

      if (threshold && item.thresholdTypes.indexOf(datatype) > -1) {
        include = true;
      }
      return include;
    });
  }

  getDefaultOperator(dataType) {
    return this.getOperators(dataType, true, false)[0].fieldName;
  }

  canOperatorAcceptFilterType(operatorName, dataType) {
    return getOperatorByName(operatorName).filterTypes.indexOf(dataType) >= 0;
  }

  getArgumentCount(operatorName) {
    return getOperatorByName(operatorName).argumentCount;
  }
}

export default new ConditionalCriteriaOperators();
