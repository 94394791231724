import Button from "acl-ui/components/Button";
import createReactClass from "create-react-class";
import Icon from "acl-ui/components/Icon";
import PropTypes from "prop-types";
import React from "react";
import services from "../../services/glue/appGlue";
import ToolTip from "@viz-ui/components/ToolTip";
import OverflowMenu from "@paprika/overflow-menu";
import Switch from "@paprika/switch";
import SaveButton from "@viz-ui/components/SaveButton";
import StoryboardSaveAsModal from "@viz-ui/components/duplicateStoryboard/storyboard.saveAs.component";

window.StoryboardActionPanel = createReactClass({
  propTypes: {
    enableDeleteButton: PropTypes.bool,
    enableFilterButton: PropTypes.bool,
    enablePresentationButton: PropTypes.bool,
    enableSaveButton: PropTypes.bool,
    enableShareButton: PropTypes.bool,
    onDeleteConfirm: PropTypes.func,
    onFilterClick: PropTypes.func,
    onPresentationClick: PropTypes.func,
    onPatternFillClick: PropTypes.func,
    onShareClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onSaveAsClick: PropTypes.func,
    saveButtonTip: PropTypes.string,
    filterButtonTip: PropTypes.string,
    showSaveButton: PropTypes.bool,
    showSaveAsButton: PropTypes.bool,
    storyboardName: PropTypes.string,
    showDeleteButton: PropTypes.bool,
    showFilterButton: PropTypes.bool,
    showPresentationButton: PropTypes.bool,
    showShareButton: PropTypes.bool,
    flipDuplicateStoryboards: PropTypes.bool,
    usePatternFill: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      showPresentationButton: true,
    };
  },

  getInitialState() {
    return {
      openDeleteConfirmModal: false,
      openSaveAsPopup: false,
    };
  },

  componentDidMount() {
    ACLUISetup.aclPanel();
  },

  openDeleteConfirmModal(e) {
    e.preventDefault();
    this.setState({ openDeleteConfirmModal: true });
  },

  openSaveAsPopup() {
    this.setState({ openSaveAsPopup: true });
  },

  handleSaveAsConfirm(storyboardName, copyFilters) {
    this.props.onSaveAsClick(storyboardName, copyFilters);
    this.setState({ openSaveAsPopup: false });
  },

  handleSaveAsCancel() {
    this.setState({ openSaveAsPopup: false });
  },

  handleDeleteConfirm() {
    this.props.onDeleteConfirm();
    this.setState({ openDeleteConfirmModal: false });
  },

  handleDeleteCancel() {
    this.setState({ openDeleteConfirmModal: false });
  },

  handleFilterClick() {
    this.props.onFilterClick();
  },

  getSaveButton() {
    var saveButton;
    if (this.props.flipDuplicateStoryboards && this.props.showSaveButton) {
      const saveButtonProps = {
        saveTooltip: this.props.saveButtonTip,
        isSaveDisabled: !this.props.enableSaveButton,
        isSaveAsDisabled: !this.props.showSaveAsButton,
        onClickSave: this.props.onSaveClick,
        onClickSaveAs: this.openSaveAsPopup,
      };
      return (
        <div className="storyboard-action-item">
          <SaveButton {...saveButtonProps} />
        </div>
      );
    } else if (this.props.showSaveButton) {
      saveButton = (
        <span className="storyboard-action-item">
          <ToolTip content={this.props.saveButtonTip}>
            <Button
              id="storyboardSaveBtn"
              isDisabled={!this.props.enableSaveButton}
              onClick={this.props.onSaveClick}
              type="primary"
            >
              {services.Localize.getLocalizedString("_Storyboards.Save_")}
            </Button>
          </ToolTip>
        </span>
      );
    }
    return saveButton;
  },

  getPresentationButton() {
    if (this.props.showPresentationButton) {
      return (
        <span className="storyboard-action-item">
          <ToolTip content={services.Localize.getLocalizedString("_Storyboards.PresentationTip_")}>
            <Button
              id="storyboardPresentationBtn"
              isDisabled={!this.props.enablePresentationButton}
              isSquare
              onClick={this.props.onPresentationClick}
              type="flat"
            >
              <Icon type="chart-arrow" size={20} />
            </Button>
          </ToolTip>
        </span>
      );
    }
  },

  getMoreOptionsButton() {
    const label = services.Localize.getLocalizedString("_Storyboards.MoreOptions.PatternFillTip_");
    return (
      <span className="storyboard-action-item">
        <ToolTip content={services.Localize.getLocalizedString("_Storyboards.MoreOptionsTip_")}>
          <OverflowMenu edge="right" zIndex={2}>
            <OverflowMenu.Trigger buttonType="icon" className="storyboard-action-item__pattern-trigger">
              <Icon type="ellipsis" size={20} />
            </OverflowMenu.Trigger>
            <OverflowMenu.Item onClick={this.props.onPatternFillClick}>
              <div className="storyboard-action-item__pattern-switch">
                <Switch isChecked={this.props.usePatternFill} labelText={label} size="small" />
                <span className="storyboard-action-item__pattern-switch-label">{label}</span>
              </div>
            </OverflowMenu.Item>
          </OverflowMenu>
        </ToolTip>
      </span>
    );
  },

  getDeleteButton() {
    var deleteButton;
    if (this.props.showDeleteButton) {
      deleteButton = (
        <span className="storyboard-action-item">
          <ToolTip content={services.Localize.getLocalizedString("_Storyboards.DeleteTip_")}>
            <Button
              id="storyboardDeleteBtn"
              isDisabled={!this.props.enableDeleteButton}
              isSquare
              onClick={this.openDeleteConfirmModal}
              type="flat"
            >
              <Icon type="trashbin" size={20} />
            </Button>
          </ToolTip>
        </span>
      );
    }
    return deleteButton;
  },

  getShareButton() {
    var shareButton;
    if (this.props.showShareButton) {
      shareButton = (
        <span className="storyboard-action-item">
          <ToolTip content={services.Localize.getLocalizedString("_Storyboards.ShareTip_")}>
            <Button
              id="storyboardShareBtn"
              isDisabled={!this.props.enableShareButton}
              isSquare
              className="share-btn"
              onClick={this.props.onShareClick}
              type="flat"
            >
              <Icon type="share" size={20} />
            </Button>
          </ToolTip>
        </span>
      );
    }
    return shareButton;
  },

  getFilterButton() {
    var filterButton;
    if (this.props.showFilterButton) {
      filterButton = (
        <span className="storyboard-action-item">
          <ToolTip content={this.props.filterButtonTip}>
            <Button
              id="storyboardFilterBtn"
              isDisabled={!this.props.enableFilterButton}
              isSquare
              onClick={this.handleFilterClick}
              type="flat"
            >
              <Icon type="filter" size={20} />
            </Button>
          </ToolTip>
        </span>
      );
    }
    return filterButton;
  },

  render() {
    return (
      <div className="storyboard-actions">
        {this.getSaveButton()}
        {this.getPresentationButton()}
        {this.getDeleteButton()}
        {this.getShareButton()}
        {this.getMoreOptionsButton()}
        {this.getFilterButton()}
        <DeleteConfirmModal
          openModal={this.state.openDeleteConfirmModal}
          onConfirm={this.handleDeleteConfirm}
          onCancel={this.handleDeleteCancel}
          ariaLabel=""
        />
        <StoryboardSaveAsModal
          openModal={this.state.openSaveAsPopup}
          onConfirm={this.handleSaveAsConfirm}
          onCancel={this.handleSaveAsCancel}
          storyboardName={this.props.storyboardName}
        />
      </div>
    );
  },
});
