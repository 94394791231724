import ConditionalCriteriaOperators from "@viz-ui/services/quickMenu/conditionalFormat/conditionalCriteriaOperatorsService";

angular.module("acl.core.conditionalCriteriaSelector").directive("aclConditionalCriteria", function() {
  return {
    restrict: "E",
    replace: true,
    controllerAs: "vm",
    require: ["ngModel"],
    scope: {
      type: "<",
      threshold: "<",
      filter: "<",
      ngModel: "=",
      ngRequired: "=",
      ngChange: "&",
    },
    bindToController: true,
    templateUrl: "visualizer/js/modules/core/conditionalCriteriaSelector/conditionalCriteriaSelector.tpl.html",
    controller: function($scope, $timeout, Localize) {
      var self = this;

      self.dateOnlyPickerOptions = {
        timePicker: false,
        autoApply: true,
        locale: {
          format: Localize.getLocalizedString("_Input.Date.Pattern.ForMomentJsParsing_"),
        },
      };

      self.init = function() {
        self.operators = ConditionalCriteriaOperators.getOperators(self.type, self.filter, self.threshold);
      };

      $scope.$watch("vm.type", function(newValue) {
        if (newValue) {
          self.init();
        }
      });

      self.dataChanged = function() {
        if (self.ngModel && (self.ngModel.operator === "is-blank" || self.ngModel.operator === "is-not-blank")) {
          self.ngModel.values = null;
        }
        if ($scope.vm.ngChange) {
          //FIXME: For some reason this ng-change was getting lost in certain circumstances and forcing it to the next digest cycle seemed to fix it but not sure of best way to do it otherwise.
          $timeout(function() {
            $scope.vm.ngChange($scope.ngModel);
          });
        }
      };
    },
    link: function(scope, element, attr, ctrls) {
      scope.conditionForm = ctrls[0];
    },
  };
});
