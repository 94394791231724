import FieldTypeChecker from "../fieldTypeChecker/fieldTypeChecker.service";

const chartConfigSingleSelect = {
  bindings: {
    className: "<",
    columnDefs: "<",
    configTitleKey: "<",
    dataConfigField: "<",
    fieldNameToFieldObj: "<",
    isDisabled: "<",
    onSelectChange: "&",
    selectedField: "<",
    selectId: "<",
    placeholder: "<",
    position: "<",
  },
  templateUrl: "visualizer/js/modules/core/chartConfigPanel/chartConfigSingleSelect.tpl.html",
  controllerAs: "chartConfigSingleSelect",
  controller: ChartConfigSingleSelectController,
};

function ChartConfigSingleSelectController(Localize) {
  "ngInject";

  const configSingleSelect = this;

  configSingleSelect.fieldHasMismatchType = false;
  configSingleSelect.fieldTypeInvalidInConfig = false;
  configSingleSelect.hasFieldError = false;
  configSingleSelect.hasFieldWarning = false;
  configSingleSelect.errorFieldDisplayName = "";

  configSingleSelect.$onChanges = function configSingleSelectOnChanges(changesObj) {
    const fieldsOrConfigChanges =
      changesObj.dataConfigField || changesObj.fieldNameToFieldObj || changesObj.selectedField || changesObj.columnDefs;
    const { dataConfigField, fieldNameToFieldObj, selectedField } = configSingleSelect;
    if (fieldsOrConfigChanges && dataConfigField && fieldNameToFieldObj && selectedField) {
      updateErrorState();
    }
  };

  function updateErrorState() {
    const { columnDefs, dataConfigField, fieldNameToFieldObj, selectedField } = configSingleSelect;
    const fieldTypeChecker = new FieldTypeChecker().setFieldsFromMap(fieldNameToFieldObj);
    const fieldSelectionChanged = dataConfigField ? selectedField !== dataConfigField.fieldName : true;
    const fieldHasMismatchType = fieldSelectionChanged
      ? false
      : fieldTypeChecker.chartConfigFieldHasFieldTypeMismatch(dataConfigField);
    const fieldTypeInvalidInConfig = fieldTypeChecker.chartConfigFieldTypeInvalid(selectedField, columnDefs);
    const localizeKey =
      fieldTypeInvalidInConfig && fieldExists(selectedField)
        ? "_ChartConfig.InvalidFieldType_"
        : "_ChartConfig.CloseButton.DeletedOption_";
    const hasDeletedField = !!selectedField && !fieldExists(selectedField);
    const hasFieldTypeChanged = fieldHasMismatchType && fieldTypeInvalidInConfig;

    configSingleSelect.hasFieldError = hasDeletedField || hasFieldTypeChanged;
    configSingleSelect.hasFieldWarning = fieldHasMismatchType && !fieldTypeInvalidInConfig;
    configSingleSelect.fieldTypeInvalidInConfig = fieldTypeInvalidInConfig;
    configSingleSelect.fieldHasMismatchType = fieldHasMismatchType;
    configSingleSelect.errorFieldDisplayName = Localize.getLocalizedString(localizeKey);
  }

  function fieldExists(fieldName) {
    const { fieldNameToFieldObj } = configSingleSelect;
    return Object.prototype.hasOwnProperty.call(fieldNameToFieldObj, fieldName);
  }

  configSingleSelect.onFieldChange = () => {
    const { selectedField } = configSingleSelect;
    configSingleSelect.onSelectChange({ selectedField });
  };
}

export default chartConfigSingleSelect;
