import checkboxState from "@viz-ui/models/checkboxState";
import MultiSelectFilterStore from "@viz-ui/services/quickMenu/MultiSelectFilterStoreService";

var aclCheckboxFilter = {
  restrict: "E",
  bindings: {
    errorMessage: "<",
    filterDisabled: "<",
    field: "<",
    fieldFormatIdentifier: "<",
    onLoadMoreClick: "&",
    onSearchChange: "&",
    onToggle: "&",
    prevSelectedValues: "<",
    selectedValues: "<",
    showLoadMoreButton: "<",
    values: "<",
    indeterminateValues: "<",
    storyboardIndicatorValues: "<",
    selectAllState: "<",
    onSelectAllClick: "&",
    totalValuesCount: "<",
    isDataLoading: "<",
  },
  controllerAs: "checkboxFilter",
  controller: CheckboxFilterController,
  templateUrl: "visualizer/js/modules/core/checkboxFilter/checkboxFilter.tpl.html",
};

function CheckboxFilterController($scope, $filter, AppConfig, Localize) {
  "ngInject";

  var checkboxFilter = this;

  checkboxFilter.flipperEnableSelectAllForMultiSelect = AppConfig.features.enableSelectAllForMultiSelect;
  checkboxFilter.visibleValuesLength = 0;
  checkboxFilter.visibleSelectedValuesLength = 0;
  checkboxFilter.resetCheckedValuesInvoked = false;

  // It is to keep the backup of initial selected values - consider it as a readonly property & don't alter it.
  checkboxFilter.selectedFilterValuesImmutable =
    checkboxFilter.prevSelectedValues && checkboxFilter.prevSelectedValues.length > 0
      ? [...checkboxFilter.prevSelectedValues]
      : undefined;

  checkboxFilter.$onChanges = changeObj => {
    if (changeObj.field) {
      clearSearchInput();
    }

    if (changeObj.values && changeObj.values.currentValue) {
      checkboxFilter.valuesAsArray = changeObj.values.currentValue.map(function(x) {
        return x.value;
      });
      isScrollVisible();
    }

    initialize();
  };

  function initialize() {
    checkboxFilter.selectedValuesForWatch = checkboxFilter.selectedValues;
    checkboxFilter.indeterminateValuesForWatch = checkboxFilter.indeterminateValues;
    checkboxFilter.storyboardIndicatorValuesForWatch = checkboxFilter.storyboardIndicatorValues;
    checkboxFilter.unSelectedValues = MultiSelectFilterStore.getUnCheckedValues(checkboxFilter.fieldName()) || [];
    checkboxFilter.visibleValuesLength = checkboxFilter.valuesAsArray ? checkboxFilter.valuesAsArray.length : 0;

    if (!checkboxFilter.searchApplied) {
      checkboxFilter.selectAllStatePriorSearch = checkboxFilter.selectAllState;
      checkboxFilter.totalVisibleValuesPriorSearch = checkboxFilter.valuesAsArray; // It holds all visible items
      checkboxFilter.totalValuesCountPriorSearch = checkboxFilter.totalValuesCount; // It holds the total items length (both visible & non-visible values count)
    }

    checkboxFilter.totalLoadedValues = getTotalLoadedValues(
      checkboxFilter.valuesAsArray || [],
      checkboxFilter.totalVisibleValuesPriorSearch || []
    );

    if (
      checkboxFilter.selectAllState == checkboxState.CHECKED ||
      (checkboxFilter.unSelectedValues && checkboxFilter.unSelectedValues.length > 0)
    ) {
      checkboxFilter.selectedValuesForWatch = getSelectedValuesForWatch(
        checkboxFilter.totalLoadedValues,
        checkboxFilter.unSelectedValues
      );

      resetCheckedValues(
        checkboxFilter.fieldName(),
        checkboxFilter.selectedValuesForWatch,
        checkboxFilter.unSelectedValues
      );
    }

    checkboxFilter.visibleSelectedValuesLength = getVisibleSelectedValuesLength(
      checkboxFilter.searchApplied,
      checkboxFilter.valuesAsArray || [],
      checkboxFilter.selectedValuesForWatch || []
    );
  }

  function resetCheckedValues(fieldName, selectedValuesForWatch, unSelectedValues) {
    if (selectedValuesForWatch && selectedValuesForWatch.length > 0) {
      MultiSelectFilterStore.resetCheckedValues(fieldName, selectedValuesForWatch, unSelectedValues);
    }
  }

  checkboxFilter.toolTipProps = {
    showIndeterminateToolTip: item => {
      let showToolTip = null;
      if (!(AppConfig.features.carryStoryboardFilters && checkboxFilter.isIndeterminateState(item))) {
        showToolTip = false;
      }
      return showToolTip;
    },
  };

  checkboxFilter.checkboxProps = {
    checkBoxSize: "small",

    getFilterValueState: item => {
      let checkState = "unchecked";
      if (checkboxFilter.isValueChecked(item)) {
        checkState = "checked";
      } else if (AppConfig.features.carryStoryboardFilters && checkboxFilter.isIndeterminateState(item)) {
        checkState = "indeterminate";
      } else checkState = "unchecked";
      return checkState;
    },
    getSelectAllState: () => {
      return getSelectAllStateProp(checkboxFilter.visibleSelectedValuesLength, checkboxFilter.visibleValuesLength);
    },
  };

  checkboxFilter.isIndeterminateState = item =>
    checkboxFilter.indeterminateValuesForWatch && checkboxFilter.indeterminateValuesForWatch.includes(item.value);

  checkboxFilter.visualIndicatorProps = {
    height: "12px",
    width: "12.5px",
    showVisualIndicator: item =>
      AppConfig.features.carryStoryboardFilters &&
      checkboxFilter.storyboardIndicatorValuesForWatch &&
      checkboxFilter.storyboardIndicatorValuesForWatch.includes(item.value) &&
      ((checkboxFilter.isValueChecked(item) && checkboxFilter.isValueAvailableInInitSelectedValues(item)) ||
        checkboxFilter.isIndeterminateState(item)),
  };

  checkboxFilter.showNoMatchesError = () => checkboxFilter.searchApplied && checkboxFilter.values.length === 0;

  checkboxFilter.placeholderText = () => {
    if (checkboxFilter.fieldType() === "date" || checkboxFilter.fieldType() === "datetime") {
      return $filter("aclLocalize")("_QuickFilter.Date.Search.Placeholder_");
    }
    return $filter("aclLocalize")("_QuickFilter.Search.Placeholder_");
  };

  checkboxFilter.searchValueModel = "";
  checkboxFilter.searchApplied = false;
  const searchInterval = 500;

  checkboxFilter.handleSearchValueModelChange = () => {
    if (checkboxFilter.onSearchChange) {
      checkboxFilter.searchTimer = setTimeout(() => {
        checkboxFilter.onSearchChange({ value: checkboxFilter.searchValueModel });
      }, searchInterval);
      if (checkboxFilter.searchValueModel) {
        checkboxFilter.searchApplied = true;
      } else {
        checkboxFilter.searchApplied = false;
      }
    }
  };

  checkboxFilter.handleSearchValueKeyDown = () => {
    clearTimeout(checkboxFilter.searchTimer);
  };
  function clearSearchInput() {
    checkboxFilter.searchValueModel = "";
    checkboxFilter.searchApplied = false;
  }

  function isScrollVisible() {
    checkboxFilter.isScrollable = false;
    if (checkboxFilter.values && checkboxFilter.values.length > 2) {
      //scroll is visible if more then 2 check box items loaded
      checkboxFilter.isScrollable = true;
    }
  }

  checkboxFilter.fieldName = () => checkboxFilter.field && checkboxFilter.field.fieldName;

  checkboxFilter.fieldType = () => {
    var field = checkboxFilter.field;
    return field && checkboxFilter.field.type;
  };

  checkboxFilter.isValueChecked = item =>
    checkboxFilter.selectedValuesForWatch && checkboxFilter.selectedValuesForWatch.includes(item.value);

  checkboxFilter.isValueAvailableInInitSelectedValues = item =>
    checkboxFilter.selectedFilterValuesImmutable && checkboxFilter.selectedFilterValuesImmutable.includes(item.value);

  checkboxFilter.toggleCheckboxFilterValue = item => {
    if (checkboxFilter.onToggle && !checkboxFilter.filterDisabled) {
      checkboxFilter.onToggle({
        field: checkboxFilter.field,
        value: item.value,
        totalLoadedValuesLength: checkboxFilter.totalLoadedValues.length,
        filterApplied: !!checkboxFilter.searchApplied,
        flipperSelectAll: checkboxFilter.flipperEnableSelectAllForMultiSelect,
      });
      checkboxFilter.clearIndeterminateState(item);
    }
  };

  checkboxFilter.clearIndeterminateState = (item, selectAllClick = false) => {
    if (selectAllClick) {
      const visibleIndeterminateValues =
        getVisibleIndeterminateValues(
          checkboxFilter.searchValueModel,
          checkboxFilter.valuesAsArray || [],
          checkboxFilter.indeterminateValuesForWatch || []
        ) || [];

      if (checkboxFilter.indeterminateValuesForWatch) {
        checkboxFilter.indeterminateValues = checkboxFilter.indeterminateValuesForWatch.filter(
          value => visibleIndeterminateValues.findIndex(x => x == value) == -1
        );
      }
    } else if (checkboxFilter.isIndeterminateState(item)) {
      checkboxFilter.indeterminateValues = checkboxFilter.indeterminateValuesForWatch.filter(
        value => value !== item.value
      );
    }
  };

  checkboxFilter.onKeyupHandleCheckboxFilter = (item, event) => {
    if (event.keyCode === 13 || event.keyCode === 32) checkboxFilter.toggleCheckboxFilterValue(item);
  };

  checkboxFilter.handleLoadMoreClick = () => {
    if (checkboxFilter.onLoadMoreClick) {
      checkboxFilter.onLoadMoreClick();
    }
  };

  checkboxFilter.onKeyupHandleLoadMore = event => {
    if (event.keyCode === 13 || event.keyCode === 32) checkboxFilter.handleLoadMoreClick();
  };

  checkboxFilter.isItemChanged = item => {
    var selectedValues = checkboxFilter.selectedValuesForWatch;
    var isSelectedValue = !!(selectedValues && selectedValues.includes(item.value));

    var prevSelectedValues = checkboxFilter.prevSelectedValues;
    var isPrevSelectedValue = !!(prevSelectedValues && prevSelectedValues.includes(item.value));

    return isSelectedValue !== isPrevSelectedValue;
  };

  checkboxFilter.isValueEmpty = item => item.value === "";

  checkboxFilter.itemCount = item => item.COUNT;

  checkboxFilter.handleSelectAllClick = () => {
    if (checkboxFilter.onSelectAllClick && !checkboxFilter.filterDisabled) {
      const selectAllStateProp = getSelectAllStateProp(
        checkboxFilter.visibleSelectedValuesLength,
        checkboxFilter.visibleValuesLength
      );
      checkboxFilter.onSelectAllClick({
        field: checkboxFilter.field,
        action: selectAllStateProp === checkboxState.CHECKED ? "remove" : "add",
        visibleValues: checkboxFilter.valuesAsArray,
        totalLoadedValuesLength: checkboxFilter.totalLoadedValues.length,
        filterApplied: !!checkboxFilter.searchApplied,
        selectAllStatePriorSearch: checkboxFilter.selectAllStatePriorSearch,
        flipperSelectAll: checkboxFilter.flipperEnableSelectAllForMultiSelect,
      });

      checkboxFilter.clearIndeterminateState(null, true);
    }
  };

  checkboxFilter.onKeyupHandleSelectAllClick = event => {
    if (event.keyCode === 13 || event.keyCode === 32) checkboxFilter.handleSelectAllClick();
  };

  checkboxFilter.getStatusTitle = () => {
    const selectedValuesLength =
      (checkboxFilter.selectedValuesForWatch && checkboxFilter.selectedValuesForWatch.length) || 0;
    if (selectedValuesLength === 0) return Localize.getLocalizedString("_Multiselect.Placeholder_");

    const selectedValuesCount =
      checkboxFilter.selectAllState == checkboxState.CHECKED || checkboxFilter.unSelectedValues.length > 0
        ? checkboxFilter.totalValuesCountPriorSearch - checkboxFilter.unSelectedValues.length
        : selectedValuesLength;

    return Localize.getLocalizedStringWithTokenReplacement("_Multiselect.statusTitle.Label_", [
      selectedValuesCount,
      checkboxFilter.totalValuesCountPriorSearch,
    ]);
  };

  checkboxFilter.disableSelectAll = () => {
    return checkboxFilter.showLoadMoreButton && checkboxFilter.searchApplied ? true : false;
  };

  function getTotalLoadedValues(values, totalVisibleValuesPriorSearch) {
    return [...new Set([...values.map(x => x), ...totalVisibleValuesPriorSearch.map(x => x)])];
  }

  function getVisibleSelectedValuesLength(filterString, values, selectedValues) {
    if (!filterString) {
      return selectedValues.length;
    } else {
      return values.filter(value => selectedValues.findIndex(selectedValue => selectedValue == value) > -1).length;
    }
  }

  function getSelectedValuesForWatch(totalLoadedValues, unSelectedValues) {
    return totalLoadedValues.filter(
      value => unSelectedValues.findIndex(unSelectedValue => unSelectedValue == value) == -1
    );
  }

  function getSelectAllStateProp(visibleSelectedValuesLength, visibleValuesLength) {
    let selectAllStateProp = checkboxState.UNCHECKED;
    if (!checkboxFilter.flipperEnableSelectAllForMultiSelect) {
      return selectAllStateProp;
    }

    return ((checkboxFilter.selectAllState == checkboxState.UNCHECKED || !checkboxFilter.selectAllState) &&
      checkboxFilter.selectedValuesForWatch &&
      checkboxFilter.selectedValuesForWatch.length > 0) ||
      checkboxFilter.searchApplied
      ? MultiSelectFilterStore.getSelectAllStateProp(
          visibleSelectedValuesLength,
          visibleValuesLength,
          checkboxFilter.flipperEnableSelectAllForMultiSelect
        )
      : checkboxFilter.selectAllState;
  }

  function getVisibleIndeterminateValues(filterString, values, indeterminateValues) {
    if (!filterString) {
      return indeterminateValues;
    } else {
      return values.filter(
        value => indeterminateValues.findIndex(indeterminateValue => indeterminateValue == value) > -1
      );
    }
  }

  function getIndicatorValuesForWatch() {
    // FixMe - Needs to address indicator scenarios for select all feature
    if (
      checkboxFilter.totalLoadedValues &&
      checkboxFilter.indeterminateValuesForWatch &&
      checkboxFilter.indeterminateValuesForWatch.length > 0 &&
      checkboxFilter.indeterminateValuesForWatch[0] == "ALL_UN_CHECKED_VALUES"
    ) {
      checkboxFilter.indeterminateValuesForWatch = checkboxFilter.totalLoadedValues.filter(
        value =>
          checkboxFilter.unSelectedValues.findIndex(unSelectedValue => unSelectedValue == value) == -1 &&
          checkboxFilter.selectedValuesForWatch.findIndex(selectedValue => selectedValue == value) == -1
      );
    }

    if (
      checkboxFilter.totalLoadedValues &&
      checkboxFilter.storyboardIndicatorValuesForWatch &&
      checkboxFilter.storyboardIndicatorValuesForWatch.length > 0 &&
      checkboxFilter.storyboardIndicatorValuesForWatch[0] == "ALL_UN_CHECKED_VALUES"
    ) {
      checkboxFilter.storyboardIndicatorValuesForWatch = checkboxFilter.totalLoadedValues.filter(
        value =>
          checkboxFilter.unSelectedValues.findIndex(unSelectedValue => unSelectedValue == value) == -1 &&
          checkboxFilter.selectedValuesForWatch.findIndex(selectedValue => selectedValue == value) == -1
      );
    }
  }
}

export default aclCheckboxFilter;
