angular.module('acl.visualizer.accessControl.backend', [
  'acl.common.event',
  'acl.visualizer.model.accessControl',
])
.run(function(EventService, AccessControlBackend) {
  var eventService = EventService.register('acl.visualizer.accessControl.backend');

  eventService.subscribe('request.accessControlRequest', function(eventName, token, entity, id) {
    AccessControlBackend.accessControlRequested(entity, id)
      .then(function(data) {
        eventService.publish('resolve.accessControlRequest', token, data);
      })
      .catch(function(error) {
        eventService.publish('reject.accessControlRequest', token, error);
      });
  })
});
