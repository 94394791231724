import ReactDirective from "acl-ui/angular/reactDirective.umd";
import ConditionFormattingIcon from "@viz-ui/components/conditionalFormattingIcon/conditionalFormattingIcon";

angular
  .module("acl.visualizer.conditionalFormatting", [
    "acl.common.filters",
    "acl.common.localize",
    "acl.visualizer.condition",
    "acl.visualizer.criteriaFilter",
    "acl.visualizer.dataModel",
    "acl.visualizer.model.field",
    "templates-visualizer",
    "ui.bootstrap",
  ])
  .directive("conditionFormattingIcon", ReactDirective.getFactory(ConditionFormattingIcon));
