import PropTypes from "prop-types";
import React from "react";
import services from "../../../services/glue/appGlue";

class LinkActionsSection extends React.Component {
  constructor(props) {
    super(props);

    this.toggleRegeneratePopover = this.toggleRegeneratePopover.bind(this);
    this.toggleDeletePopover = this.toggleDeletePopover.bind(this);
    this.handleRegenerate = this.handleRegenerate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.closeRegeneratePopover = this.closeRegeneratePopover.bind(this);
    this.closeDeletePopover = this.closeDeletePopover.bind(this);

    this.state = {
      showRegeneratePopover: false,
      showDeletePopover: false,
    };
  }

  handleRegenerate() {
    this.closeRegeneratePopover();
    this.props.onRegenerate();
  }

  handleDelete() {
    this.closeDeletePopover();
    this.props.onDelete();
  }

  toggleRegeneratePopover() {
    this.setState({
      showRegeneratePopover: !this.state.showRegeneratePopover,
      showDeletePopover: false,
    });
  }

  toggleDeletePopover() {
    this.setState({
      showRegeneratePopover: false,
      showDeletePopover: !this.state.showDeletePopover,
    });
  }

  closeRegeneratePopover() {
    this.setState({ showRegeneratePopover: false });
  }

  closeDeletePopover() {
    this.setState({ showDeletePopover: false });
  }

  render() {
    return (
      <div className="storyboard-link__actions-wrapper">
        <a className="storyboard-link__regenerate" onClick={this.toggleRegeneratePopover}>
          <i className="acl-i-refresh" />
        </a>
        <AclPopover
          show={this.state.showRegeneratePopover}
          showCloseBtn={false}
          placement="bottom"
          cssClass="storyboard-link__regenerate-confirm"
          onCloseClick={this.closeRegeneratePopover}
        >
          <span>{services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.RegenerateConfirm_")}</span>
          <div className="storyboard-link-actions__popover-action-btns">
            <a className="acl-btn warning" onClick={this.handleRegenerate}>
              {services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.DeleteBtn_")}
            </a>
            <a onClick={this.closeRegeneratePopover}>Cancel</a>
          </div>
        </AclPopover>
        <a className="storyboard-link__delete" onClick={this.toggleDeletePopover}>
          <i className="acl-i-trashbin" />
        </a>
        <AclPopover
          show={this.state.showDeletePopover}
          placement="bottom"
          cssClass="storyboard-link__delete-confirm"
          onCloseClick={this.closeDeletePopover}
        >
          {this.props.accessType && this.props.accessType === "group" ? (
            <span>{services.Localize.getLocalizedString("_Storyboards.SharePanel.group.Link.DeleteConfirm_")}</span>
          ) : (
            <span>{services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.DeleteConfirm_")}</span>
          )}
          <div className="storyboard-link-actions__popover-action-btns">
            <a className="acl-btn warning" onClick={this.handleDelete}>
              {services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.DeleteBtn_")}
            </a>
            <a onClick={this.closeDeletePopover}>{services.Localize.getLocalizedString("_Cancel_")}</a>
          </div>
        </AclPopover>
      </div>
    );
  }
}

LinkActionsSection.propTypes = {
  onRegenerate: PropTypes.func,
  onDelete: PropTypes.func,
  accessType: PropTypes.string,
};

window.LinkActionsSection = LinkActionsSection;
