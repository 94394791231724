import React from "react";
import PropTypes from "prop-types";
import tokens from "acl-ui/components/helpers/tokens.json";
import Button from "acl-ui/components/Button";
import RawButton from "acl-ui/components/RawButton";
import Icon from "acl-ui/components/Icon";
import UsageTracker from "@viz-ui/services/tracking/usageTracker";
import i18n from "@viz-ui/i18n/i18n";
import "./filterConfigPanel.scss";

FilterConfigPanel.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

FilterConfigPanel.defaultProps = {
  children: null,
  disabled: false,
};

function isHighbond() {
  return window.AclVisualizerFeatureToggles && window.AclVisualizerFeatureToggles.galvanizeHighbond;
}

function handleCancel(e, onClose) {
  onClose(e);
  UsageTracker.createEvent(".filterpanel.configpanel.cancelled");
}

function handleClose(e, onClose) {
  onClose(e);
  UsageTracker.createEvent(".filterpanel.configpanel.closed");
}

function FilterConfigPanel({ children, disabled, onClose, onSubmit, saveButtonLabel, title }) {
  return (
    <div className="filter-config__container">
      <form className="filter-config__form" onSubmit={onSubmit}>
        <header className="filter-config__header">
          <div className="filter-config__header-title" role="heading">
            {title}
          </div>
          <RawButton
            className="filter-config__close-button"
            onClick={e => handleClose(e, onClose)}
            ariaLabel={i18n.t("_General.Close.Label_")}
          >
            <Icon type="times" color={tokens.textColorIcon} />
          </RawButton>
        </header>

        <div className="filter-config__form-content">
          <div className="filter-config__section filter-config__description">
            <p>
              <span dangerouslySetInnerHTML={{ __html: i18n.t("_GlobalFilter.Description_") }} /> &nbsp;
              <a
                href={
                  isHighbond()
                    ? i18n.t("_GlobalFilter.DescriptionMoreURL_")
                    : i18n.t("_GlobalFilter.DescriptionMoreURL.ACL_")
                }
                title={
                  isHighbond()
                    ? i18n.t("_GlobalFilter.DescriptionMoreTitle_")
                    : i18n.t("_GlobalFilter.DescriptionMoreTitle.ACL_")
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n.t("_GlobalFilter.DescriptionMoreLink_")}
              </a>
            </p>
          </div>
          {children}
        </div>

        <footer className="filter-config__footer">
          <Button type="primary" onClick={onSubmit} isDisabled={disabled} className="filter-config__submit-filter">
            {saveButtonLabel}
          </Button>
          <Button type="minor" onClick={e => handleCancel(e, onClose)}>
            {i18n.t("_General.Cancel.Label_")}
          </Button>
        </footer>
      </form>
    </div>
  );
}

export default FilterConfigPanel;
