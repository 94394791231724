const storyboardFilterConditions = Object.freeze({
  EQUAL: "=",
  NOT_EQUAL: "!=",
  GREATER_THAN: ">",
  LESS_THAN: "<",
  GREATER_THAN_OR_EQUAL_TO: ">=",
  LESS_THAN_OR_EQUAL_TO: "<=",
  BETWEEN_OR_EQUAL_TO: "between",
  BLANK: "is_blank",
  NOT_BLANK: "is_not_blank",
  START_WITH: "^=",
  CONTAIN: "contain",
  NOT_CONTAIN: "!contain",
  RELATIVE: "relative",
});

export default storyboardFilterConditions;
