import StoryboardDataStore from "@storyboards/modules/storyboardDataStore/storyboardDataStore.service";
import User from "@storyboards/modules/services/user.service";
import { JwtHighbondClient } from "@acl-services/jwt-highbond-client";

export default class QueryClient {
  static jwtClient = new JwtHighbondClient();

  static query(queryObj) {
    const isStoryboardPublicLink = User.isAnonymous();
    const querySlug = isStoryboardPublicLink ? { "X-Storyboard-Slug": StoryboardDataStore.getUid() } : {};
    // Adding x-client-ip header for GRCPRD-7788 fix
    const xClientIp = window.xClientIp ? { "x-forwarded-for": window.xClientIp } : {};
    const queryUrl = this.getQueryUrl();

    return this.jwtClient
      .fetchWithTokenRefresh(queryUrl, {
        method: "POST",
        credentials: "include",
        headers: {
          "content-type": "application/json",
          ...querySlug,
          ...xClientIp,
        },
        body: JSON.stringify({
          query: queryObj.query,
          variables: queryObj.variables,
        }),
      })
      .then(response => response.json());
  }

  static getBaseUrl() {
    return window.location.href;
  }

  static getQueryUrl() {
    const baseUrl = this.getBaseUrl();
    const matchLocal = baseUrl.match(/^(http:\/\/localhost)([^/]*)/);

    if (matchLocal) {
      return "http://localhost:8000/graphql";
    } else {
      const match = baseUrl.match(/^(https?:[^\.]+)\.([^\.]+)\.([^/]+)/);
      if (!match) {
        throw `Unable to parse window.location.href URL: ${location}`;
      }

      const urlSuffix = match[3];
      const [, domain, intialGovSuffix, subDomain] = urlSuffix.match(
        /^(highbond|diligentoneplatform)(-gov|-gov2|-gov3|-mil)?(-[\w-]+)?\.(?:com|mil)$/
      );
      let govSuffix = intialGovSuffix;
      if (
        domain === "diligentoneplatform" &&
        subDomain &&
        subDomain !== "-dev" &&
        subDomain !== "-staging" &&
        subDomain !== "-preprod"
      ) {
        const matchGovSuffix = subDomain.match(/(gov|gov2|gov3|mil)$/);
        govSuffix = matchGovSuffix[1];
      }

      const isGovCloud = govSuffix || urlSuffix === "highbond.mil" || urlSuffix === "diligentoneplatform.mil";

      if (subDomain === "-s3" || subDomain === "-dev") {
        /* Playground */
        return `https://query-us2-main.${urlSuffix}/graphql`;
      } else if (subDomain === "-s2" || subDomain === "-preprod") {
        /* Preprod */
        return `https://query-us-main.${urlSuffix}/graphql`;
      } else if (
        subDomain === "-s1" ||
        subDomain === "-staging" ||
        subDomain === "-staging-gov" ||
        subDomain === "-staging-mil"
      ) {
        /* Staging and staging gov cloud */
        return isGovCloud
          ? `https://query-usg-gov.${urlSuffix}/graphql`
          : `https://query-us2-main.${urlSuffix}/graphql`;
      } else {
        if (isGovCloud) {
          /* Production gov cloud and gov cloud SLED */
          return `https://query.${urlSuffix}/graphql`;
        } else {
          /* Production regions others*/
          const region = match[2].match(/^(results|storyboards|picasso)(-..)?$/);
          const regionSuffix = region && region[2] ? region[2] : "-us";
          return `https://query${regionSuffix}-main.${urlSuffix}/graphql`;
        }
      }
    }
  }
}
