import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Modal, { ModalHeader, ModalBody, ModalFooter } from "acl-ui/components/Modal";
import DialogActions from "acl-ui/components/DialogActions";
import { Localize } from "../../services/glue/appGlue";

window.storyboardSaveModal = function(modalProps, element) {
  return ReactDOM.render(<StoryboardSaveModal {...modalProps} />, element);
};

class StoryboardSaveModal extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onDiscard: PropTypes.func,
  };

  static defaultProps = {
    isOpen: false,
    onCancel: () => {},
    onClose: () => {},
    onSave: () => {},
    onDiscard: () => {},
  };

  footerActions() {
    return (
      <DialogActions
        hasDecline
        labelDecline={Localize.t("_Storyboards.UnsavedChanges.Discard_")}
        onCancel={this.props.onCancel}
        onConfirm={this.props.onSave}
        onDecline={this.props.onDiscard}
      />
    );
  }

  render() {
    return (
      <Modal ariaLabel={Localize.t("_Warning_")} isOpen={this.props.isOpen}>
        <ModalHeader hasCloseButton onClose={this.props.onClose}>
          {Localize.t("_Warning_")}
        </ModalHeader>
        <ModalBody>
          <p>{Localize.t("_Storyboards.Errors.UnsavedChanges_")}</p>
        </ModalBody>
        <ModalFooter footerActions={this.footerActions()} />
      </Modal>
    );
  }
}
