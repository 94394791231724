import MetricBackendService from "./metricBackendService";
import MetricQueryBackendService from "./metricQueryBackend.service";

angular
  .module("acl.visualizer.metric.backend", [
    "acl.common.event",
    "acl.visualizer.model.metric",
    "acl.visualizer.model.table",
  ])
  .run(function(AppConfig, EventService, MetricBackendService, MetricQueryBackendService) {
    var eventService = EventService.register("acl.visualizer.metric.backend");
    var dataRequestMapping = {
      metricRequest: MetricBackendService.metricRequested,
      metricCreate: MetricBackendService.metricCreated,
      metricChange: MetricBackendService.metricChanged,
      metricDelete: MetricBackendService.metricDeleted,
      metricsRequest: MetricBackendService.metricsRequested,
      metricSummaryRequest: MetricBackendService.metricSummaryRequested,
      metricSparklineRequest: MetricBackendService.metricSparklineRequested,
    };

    let eventMappingToQuery = {
      metricSparklineRequest: MetricQueryBackendService.getMetricSparklineData,
    };

    for (var eventName in dataRequestMapping) {
      let requestFunction;

      if (AppConfig.features.queryService && eventMappingToQuery[eventName]) {
        requestFunction = eventMappingToQuery[eventName];
      } else {
        requestFunction = dataRequestMapping[eventName];
      }

      eventService.subscribe("request." + eventName, delegateDataRequest(eventName, requestFunction));
    }

    function delegateDataRequest(eventName, requestFunction) {
      return function(_, token) {
        var args = Array.prototype.slice.call(arguments, 2);
        requestFunction(...args)
          .then(function(data) {
            eventService.publish("resolve." + eventName, token, data);
          })
          .catch(function(error) {
            eventService.publish("reject." + eventName, token, error);
          });
      };
    }
  })
  .service("MetricBackendService", MetricBackendService)
  .service("MetricQueryBackendService", MetricQueryBackendService);
