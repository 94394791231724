angular.module("acl.visualizer.multiSelect").component("aclMultiSelect", {
  templateUrl: "visualizer/js/modules/core/multiSelect/multiSelect.tpl.html",
  controllerAs: "multiSelect",
  bindings: {
    allowDuplicate: "<",
    initOptions: "<",
    limit: "<",
    options: "<",
    onSelectExt: "&onSelect",
    onRemoveExt: "&onRemove",
    onChange: "&",
  },
  controller: function($scope) {
    var multiSelect = this;

    multiSelect.$onInit = function() {
      multiSelect.currentID = 0;
      multiSelect.initCopy = angular.copy(multiSelect.options);
    };

    $scope.$watch("multiSelect.initOptions", function(iOptions) {
      multiSelect.selectedOptions = iOptions;
    });

    $scope.$on("uiSelectSort:change", function() {
      multiSelect.onChange({ selectedOptions: multiSelect.selectedOptions });
    });

    multiSelect.onSelect = function() {
      if (multiSelect.allowDuplicate) {
        resetOptions();
      }
      multiSelect.onSelectExt({ selectedOptions: multiSelect.selectedOptions });
    };
    multiSelect.onRemove = function() {
      multiSelect.onRemoveExt({ selectedOptions: multiSelect.selectedOptions });
    };

    function resetOptions() {
      multiSelect.options = angular.copy(multiSelect.initCopy).map(function(opt) {
        opt.multiSelectId = multiSelect.currentID++;
        return opt;
      });
    }
  },
});
