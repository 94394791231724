(() => {
  class DeleteMetricConfirmer {
    constructor($modal) {
      this.$modal = $modal;
    }

    openModal(metricNameArg) {
      var modalInstance;
      var ModalController = function($scope, $modalInstance, metricName) {
        $scope.model = {
          metricName: metricName,
        };

        $scope.cancel = function() {
          $modalInstance.dismiss("cancel");
        };

        $scope.delete = function() {
          $modalInstance.close(true);
        };
      };
      ModalController.$inject = ["$scope", "$modalInstance", "metricName"];

      modalInstance = this.$modal.open({
        templateUrl: "visualizer/js/modules/core/deleteMetricConfirmer/deleteMetricConfirmer.tpl.html",
        controller: ModalController,
        windowClass: "delete_metric_dialog",
        resolve: {
          metricName: function() {
            return metricNameArg;
          },
        },
      });

      return modalInstance.result;
    }
  }

  angular.module("acl.visualizer.metricApp").service("DeleteMetricConfirmer", DeleteMetricConfirmer);
})();
