import uniq from "lodash/uniq";
import isArray from "lodash/isArray";

import { aliasColumnAggregationFragment, countAggregationFragment, columnAggregationFragment } from "./queryFragment";

import ChartConfigTranslator from "../chartConfigTranslator/chartConfigTranslator";
import FilterTranslator from "../filterTranslator/filterTranslator";

import TreemapQueryGenerator from "./treemapQueryGenerator";
import CombinationChartQueryGenerator from "./combinationChartQueryGenerator";

const METRIC_FUNCTION_MAP = {
  average: "avg",
  blanks: "count,countBlanks",
  highest: "max",
  lowest: "min",
  standard_deviation: "stddev",
  total: "sum",
};

export default class QueryGenerator {
  static tableMetadata(tableId, scope) {
    const query = `
      query tableMetadata($id: Int!, $scope: Scope) {
        table(id: $id, scope: $scope) {
          name,
          count,
          fieldDefs {
            fieldName,
            displayName,
            type
          }
        }
      }
    `;

    return {
      query: query,
      variables: {
        id: tableId,
        scope,
      },
    };
  }

  static tablesMetadata(tableIds, scope) {
    const tablesMetadataFragments = tableIds.map(
      tableId => `
        table${tableId}: table(id: ${tableId}, scope: $scope) {
          name,
          count,
          fieldDefs {
            fieldName,
            displayName,
            type
          }
        }
      `
    );
    const query = `
      query tablesMetadata($scope: Scope) {
        ${tablesMetadataFragments}
      }
    `;

    return {
      query: query,
      variables: { scope },
    };
  }

  static table({
    tableId,
    filterList,
    sortField,
    sortOrder,
    secondarySortField,
    secondarySortOrder,
    offset,
    globalFilters,
    scope,
  }) {
    const query = `
      query dataTable(
        $id: Int!,
        $count: Int = 200,
        $offset: Int = 0,
        $filter: String,
        $orderBy: [OrderBy!],
        $analytic: Boolean,
        $scope: Scope
      ) {
        table(id: $id, scope: $scope) {
          name,
          count,
          fieldDefs {
            fieldName,
            displayName,
            type
          },
          rows(
            count: $count,
            offset: $offset,
            filter: $filter,
            orderBy: $orderBy,
            analytic: $analytic
          )
        }
      }
    `;

    const orderBy = [];
    if (sortField && sortOrder) orderBy.push({ field: sortField, order: sortOrder });
    if (secondarySortField && secondarySortOrder)
      orderBy.push({ field: secondarySortField, order: secondarySortOrder });

    const chartFilterTree = FilterTranslator.parse(filterList);
    const globalFilterTree = FilterTranslator.parseGlobalFilters(globalFilters);
    const filterObject = this.getCombinedFilterObject(chartFilterTree, globalFilterTree);
    const variables = {
      id: tableId,
      count: 200,
      offset: offset,
      filter: JSON.stringify(filterObject),
      orderBy,
      analytic: true,
      scope,
    };

    return {
      query: query,
      variables: variables,
    };
  }

  static tableUniqueValues(tableOptions, scope, allFilters = []) {
    const notMalformedFilter = FilterTranslator.generateNotMalformedFilter([tableOptions.fieldName]);
    let filterObject = "";
    if (allFilters.length > 0) {
      filterObject = this.getCombinedFilterObject(tableOptions.filter, allFilters, notMalformedFilter);
    } else {
      filterObject = this.getCombinedFilterObject(tableOptions.filter, notMalformedFilter);
    }
    const query = `
      query tableUniqueValues(
        $scope: Scope,
        $count: Int = 20,
        $offset: Int = 0,
        $filter: String,
        $fieldName: String!
      ) {
        table(id: ${tableOptions.tableId}, scope: $scope) {
          unique(
            fieldName: $fieldName,
            filter: $filter,
            count: $count,
            offset: $offset
          )
        }
      }
    `;

    return {
      query: query,
      variables: {
        scope,
        fieldName: tableOptions.fieldName,
        filter: JSON.stringify(filterObject),
        count: tableOptions.count,
        offset: tableOptions.offset,
      },
    };
  }

  static tablesUniqueValues(tablesOptions, scope) {
    const tablesUniqueValuesFragments = tablesOptions.map(tableOptions => {
      const notMalformedFilter = FilterTranslator.generateNotMalformedFilter([tableOptions.fieldName]);
      const filterObject = this.getCombinedFilterObject(tableOptions.filter, notMalformedFilter);
      return `
          table${tableOptions.tableId}: table(id: ${tableOptions.tableId}, scope: $scope) {
            unique(
              fieldName: "${tableOptions.fieldName}",
              filter: "${filterString(filterObject)}",
              count: ${tableOptions.count},
              offset: ${tableOptions.offset}
            )
          }
        `;
    });
    const query = `
      query tablesUniqueValues($scope: Scope) {
        ${tablesUniqueValuesFragments}
      }
    `;

    return {
      query: query,
      variables: { scope },
    };
  }

  static metricTimeline(options) {
    const { tableId, filterList, metricConfig, globalFilters, timezoneOffset, scope } = options;
    if (metricConfig.func() === "percent-of") return this.metricPercentOfTimeline(options);

    const chartFilterTree = FilterTranslator.parse(filterList);
    const globalFilterTree = FilterTranslator.parseGlobalFilters(globalFilters);
    const notMalformedFilter = FilterTranslator.generateNotMalformedFilter([metricConfig.timeFieldName()]);
    const filterObject = this.getCombinedFilterObject(chartFilterTree, globalFilterTree, notMalformedFilter);
    const functions = METRIC_FUNCTION_MAP[metricConfig.func()] || metricConfig.func();

    const timeFieldName = metricConfig.timeFieldName();
    const interval = metricConfig.interval();

    const variables = {
      tableId: tableId,
      filter: JSON.stringify(filterObject),
      fieldName: metricConfig.fieldName(),
      timeFieldName: metricConfig.timeFieldName(),
      groupBy: groupByFieldStringForTimeline(timeFieldName, interval, timezoneOffset),
      scope,
    };

    const METRIC_TIMELINE_QUERY = `
      query metricTimeline(
        $tableId: Int!,
        $filter: String,
        $fieldName: String!
        $timeFieldName: String!,
        $groupBy: String,
        $scope: Scope
      ) {
        table(id: $tableId, scope: $scope) {
          name,
          fieldDefs {
            fieldName,
            displayName,
            type
          },
          timeline: columnAgg(filter: $filter, groupBy: $groupBy) {
            measureField: column(fieldName: $fieldName) {
              ${functions}
            }
          },
          range: columnAgg(filter: $filter) {
            timeField: column(fieldName: $timeFieldName) {
              min, max
            }
          }
        }
      }
    `;

    return {
      query: METRIC_TIMELINE_QUERY,
      variables: variables,
    };
  }

  static metricPercentOfTimeline({ tableId, filterList, metricConfig, globalFilters, scope }) {
    const percentOfTimelineQuery = `
      query metricPercentOfTimeline(
        $tableId: Int!,
        $filter: String,
        $combinedFilter: String,
        $fieldName: String!
        $timeFieldName: String!,
        $groupBy: String,
        $scope: Scope
      ) {
        table(id: $tableId, scope: $scope) {
          name,
          fieldDefs {
            fieldName,
            displayName,
            type
          },
          totalCount: columnAgg(filter: $filter, groupBy: $groupBy) {
            measureField: column(fieldName: $fieldName) {
              count
            }
          },
          percentOfCount: columnAgg(filter: $combinedFilter, groupBy: $groupBy) {
            measureField: column(fieldName: $fieldName) {
              count
            }
          },
          range: columnAgg(filter: $filter) {
            timeField: column(fieldName: $timeFieldName) {
              min, max
            }
          }
        }
      }
    `;

    const chartFilterTree = FilterTranslator.parse(filterList);
    const percentOfFilterTree = FilterTranslator.parse([
      {
        name: metricConfig.fieldName(),
        filters: [
          {
            operator: metricConfig.aggregatorArgs().operator(),
            values: metricConfig.aggregatorArgs().values(),
          },
        ],
      },
    ]);
    const globalFilterTree = FilterTranslator.parseGlobalFilters(globalFilters);
    const notMalformedFilter = FilterTranslator.generateNotMalformedFilter([metricConfig.timeFieldName()]);
    const filterObject = this.getCombinedFilterObject(chartFilterTree, globalFilterTree, notMalformedFilter);
    const percentOfFilterObject = this.getCombinedFilterObject(
      chartFilterTree,
      percentOfFilterTree,
      globalFilterTree,
      notMalformedFilter
    );

    const variables = {
      tableId: tableId,
      filter: JSON.stringify(filterObject),
      combinedFilter: JSON.stringify(percentOfFilterObject),
      fieldName: metricConfig.fieldName(),
      timeFieldName: metricConfig.timeFieldName(),
      groupBy: groupByFieldStringForTimeline(metricConfig.timeFieldName(), metricConfig.interval()),
      scope,
    };

    return {
      query: percentOfTimelineQuery,
      variables: variables,
    };
  }

  static getCombinedFilterObject(...filters) {
    let filterObject = {};
    const dFilters = [];
    const definedFilters = filters.filter(filter => filter);
    definedFilters.forEach(filter => {
      if (isArray(filter)) {
        filter.forEach(fields => dFilters.push(fields));
      } else {
        dFilters.push(filter);
      }
    });
    if (dFilters.length > 1) {
      filterObject = { "#and": dFilters };
    } else {
      [filterObject] = definedFilters;
    }
    return filterObject;
  }

  static chartData(options) {
    const { tableId, chartType, filterList, dataConfig, globalFilters, scope } = options;
    if (chartType === "SummaryTable") return this.summaryTable(options);

    const groupByFields = ChartConfigTranslator.groupByFields(chartType, dataConfig);
    const chartFilterTree = FilterTranslator.parse(filterList);
    const globalFilterTree = FilterTranslator.parseGlobalFilters(globalFilters);
    const notMalformedFilter = FilterTranslator.generateNotMalformedFilter(groupByFields);
    const filterObject = this.getCombinedFilterObject(chartFilterTree, globalFilterTree, notMalformedFilter);
    const aggregationFields = ChartConfigTranslator.aggregationFields(chartType, dataConfig);

    if (chartType === "Treemap") {
      return TreemapQueryGenerator.generate(tableId, filterObject, groupByFields, aggregationFields, scope);
    } else if (chartType === "CombinationChart") {
      return CombinationChartQueryGenerator.generate(tableId, filterObject, groupByFields, aggregationFields, scope);
    }

    const variables = {
      tableId: tableId,
      filter: JSON.stringify(filterObject),
      groupBy: groupByFieldString(groupByFields),
      scope,
    };

    const query = `
      query chartData($tableId: Int!, $groupBy: String, $filter: String, $scope: Scope) {
        table(id: $tableId, scope: $scope) {
          columnAgg(groupBy: $groupBy, filter: $filter) {
            ${this.getAggregationFragments(aggregationFields)}
          }
        }
      }
      `;

    return {
      query: query,
      variables: variables,
    };
  }

  static getAggregationFragments(aggregationFields) {
    return aggregationFields.map(aggregationFragment).join(",");
  }

  static summaryTable({ tableId, chartType, filterList, dataConfig, globalFilters, scope }) {
    const groupByFields = ChartConfigTranslator.groupByFields(chartType, dataConfig);
    const chartFilterTree = FilterTranslator.parse(filterList);
    const globalFilterTree = FilterTranslator.parseGlobalFilters(globalFilters);
    const notMalformedFilter = FilterTranslator.generateNotMalformedFilter(groupByFields);
    const filterObject = this.getCombinedFilterObject(chartFilterTree, globalFilterTree, notMalformedFilter);
    let aggregationFields = ChartConfigTranslator.aggregationFields(chartType, dataConfig);
    aggregationFields = aliasAggregationFields(aggregationFields);

    const variables = {
      tableId: tableId,
      filter: JSON.stringify(filterObject),
      groupBy: groupByFieldString(groupByFields),
      subtotalGroupBy: dataConfig.chartColumns.length
        ? groupByFieldString([dataConfig.chartColumns[0].fieldName])
        : undefined,
      scope,
    };

    const query = `
      query summaryTable(
        $tableId: Int!,
        $filter: String,
        $groupBy: String,
        $subtotalGroupBy: String,
        $scope: Scope
      ) {
        table(id: $tableId, scope: $scope) {
          name,
          count,
          fieldDefs {
            fieldName,
            displayName,
            type
          },
          summarizedData: columnAgg(groupBy: $groupBy, filter: $filter) {
            ${this.getAggregationFragments(aggregationFields) || "count"}
          },
          subtotalData: columnAgg(groupBy: $subtotalGroupBy, filter: $filter) {
            ${this.getAggregationFragments(aggregationFields) || "count"}
          }
        }
      }
    `;

    return {
      query: query,
      variables: variables,
    };
  }
}

function groupByFieldString(fieldNames) {
  return JSON.stringify(fieldNames.map(fieldName => ({ field: fieldName })));
}

function groupByFieldStringForTimeline(fieldName, aggFn, timezoneOffset) {
  const utcOffsetString = timezoneOffset ? timezoneOffset.utcOffsetString() : undefined;
  return JSON.stringify([{ field: fieldName, function: aggFn, hoursMinutesOffset: utcOffsetString }]);
}

function filterString(filterTree) {
  return JSON.stringify(filterTree).replace(/"/g, '\\"');
}

function aliasAggregationFields(aggregationFields) {
  return aggregationFields.map((aggregation, index) => ({ ...aggregation, ...{ alias: "__valueField" + index } }));
}

function aggregationFragment(agg) {
  const functions = uniq(agg.functions).join(",");
  if (agg.fieldName && functions !== "count") {
    if (agg.alias) {
      return aliasColumnAggregationFragment(agg.alias, agg.fieldName, functions);
    }
    return columnAggregationFragment(agg.fieldName, functions);
  }
  return countAggregationFragment();
}
