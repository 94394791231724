import reduce from "lodash/reduce";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";
import cloneDeep from "lodash/cloneDeep";

const getWeightedSumOfBubbles = bubbles => {
  let totalX = 0;
  let totalY = 0;
  let totalSize = 0;
  const result = cloneDeep(bubbles[0]);
  bubbles.forEach(function(bubble) {
    totalX += bubble.x * (bubble.size / 1000);
    totalY += bubble.y * (bubble.size / 1000);
    totalSize += bubble.size;
  });
  result.x = totalX / (totalSize / 1000);
  result.y = totalY / (totalSize / 1000);
  result.size = totalSize;
  return result;
};

const processBubbleBin = (bubbles, aggregationType) => {
  let returnBubble;
  switch (aggregationType) {
    case "min":
      returnBubble = cloneDeep(
        minBy(bubbles, function(bubble) {
          return bubble.size;
        })
      );
      break;
    case "max":
      returnBubble = cloneDeep(
        maxBy(bubbles, function(bubble) {
          return bubble.size;
        })
      );
      break;
    case "sum":
    case "count":
      returnBubble = getWeightedSumOfBubbles(bubbles);
      break;
    case "average":
      returnBubble = getWeightedSumOfBubbles(bubbles);
      returnBubble.size /= bubbles.length;
      break;
    default:
      throw new Error("Unknown aggregation type.");
  }

  returnBubble.rangeX = {
    max: maxBy(bubbles, function(bubble) {
      return bubble.x;
    }).x,
    min: minBy(bubbles, function(bubble) {
      return bubble.x;
    }).x,
  };
  returnBubble.rangeY = {
    max: maxBy(bubbles, function(bubble) {
      return bubble.y;
    }).y,
    min: minBy(bubbles, function(bubble) {
      return bubble.y;
    }).y,
  };

  return returnBubble;
};

const processStackedAreaBin = (points, aggregationType) => {
  let returnPoint;
  let sum;
  switch (aggregationType) {
    case "min":
      returnPoint = cloneDeep(
        minBy(points, function(point) {
          return point.y;
        })
      );
      returnPoint.x = points[0].x;
      return returnPoint;
    case "max":
      returnPoint = cloneDeep(
        maxBy(points, function(point) {
          return point.y;
        })
      );
      returnPoint.x = points[0].x;
      return returnPoint;
    case "sum":
    case "count":
      sum = reduce(
        points,
        function(memo, point) {
          return memo + point.y;
        },
        0
      );
      returnPoint = cloneDeep(points[0]);
      returnPoint.y = sum;
      return returnPoint;
    case "average":
      sum = reduce(
        points,
        function(memo, point) {
          return memo + point.y;
        },
        0
      );
      returnPoint = cloneDeep(points[0]);
      returnPoint.y = sum / points.length;
      return returnPoint;
    default:
      throw new Error("Unknown aggregation type.");
  }
};

export default class DataDownSamplerService {
  //FIXME: this mutates the inputs while returning another value
  reduceStackedAreaData = (chartData, dataLimit, aggregationType) => {
    if (!chartData || !chartData.length) {
      return 1;
    }

    const inputCount = chartData[0].values.length * chartData.length;

    if (inputCount <= dataLimit) {
      return 1;
    }

    const reductionFactor = dataLimit / inputCount;
    const numBins = Math.max(Math.floor(reductionFactor * chartData[0].values.length), 1);
    const averageBinSize = chartData[0].values.length / numBins;

    chartData.forEach(function(series) {
      const samples = series.values.sort(function(a, b) {
        return a.x - b.x;
      });
      const reducedSamples = [];
      let bin = 1;
      while (samples.length) {
        const binSize = Math.round(bin * averageBinSize) - Math.round((bin - 1) * averageBinSize);
        reducedSamples.push(processStackedAreaBin(samples.splice(0, binSize), aggregationType));
        bin++;
      }
      series.values = reducedSamples; // eslint-disable-line no-param-reassign
    });

    return averageBinSize.toFixed(2);
  };

  reduceBubbleData = (chartData, dataLimit, aggregationType) => {
    if (!chartData || !chartData.length) {
      return 1;
    }

    let inputCount = 0;
    let outputCount = 0;
    let remainingCount;
    chartData.forEach(function(series) {
      inputCount += series.values.length;
    });

    if (inputCount <= dataLimit) {
      return 1;
    }

    remainingCount = inputCount;

    /* eslint-disable no-param-reassign */
    chartData = chartData.sort(function(a, b) {
      return a.values.length - b.values.length;
    });
    /* eslint-enable no-param-reassign */

    chartData.forEach(function(series) {
      const reductionFactor = (dataLimit - outputCount) / remainingCount;
      let numBins = Math.max(Math.floor(reductionFactor * series.values.length), 1);
      const numColumns = Math.max(Math.floor(Math.sqrt(numBins)), 1);
      numBins = numColumns;

      remainingCount -= series.values.length;

      const samples = series.values.sort(function(a, b) {
        return a.x - b.x;
      });
      const reducedSamples = [];

      const averageColumnSize = samples.length / numColumns;
      const columns = [];
      let col = 1;
      while (samples.length) {
        const columnSize = Math.round(col * averageColumnSize) - Math.round((col - 1) * averageColumnSize);
        columns.push(samples.splice(0, columnSize));
        col++;
      }

      columns.forEach(function(column) {
        const columnSample = column.sort(function(a, b) {
          return a.y - b.y;
        });
        const averageBinSize = columnSample.length / numBins;
        let bin = 1;
        while (columnSample.length) {
          const binSize = Math.round(bin * averageBinSize) - Math.round((bin - 1) * averageBinSize);
          reducedSamples.push(processBubbleBin(columnSample.splice(0, binSize), aggregationType));
          bin++;
        }
      });

      outputCount += reducedSamples.length;
      series.values = reducedSamples; // eslint-disable-line no-param-reassign
    });

    return (inputCount / outputCount).toFixed(2);
  };
}
