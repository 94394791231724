import PropTypes from "prop-types";
import SidePanel from "@paprika/sidepanel";
import Button from "@paprika/button";
import Input from "@paprika/input";
import ListBox from "@paprika/list-box";
import Checkbox from "@paprika/checkbox";
import React, { useState, useEffect } from "react";
import i18n from "@viz-ui/i18n/i18n";
import DownloadModal from "../../downloadModal/downloadModal";
import RelativeDateTranslator from "@viz-ui/models/relativeDate/relativeDateTranslator";
import Icon from "@paprika/icon/lib/InfoCircle";
import Popover from "@paprika/popover";
import Toast from "@paprika/toast";
import PasswordValidatorService from "@viz-ui/services/password/validator";

const FOOTER_DATE_FORMAT = "MMMM DD, YYYY hh:mm A";
const { CHECKED, UNCHECKED } = Checkbox.types.state;

function isValidFileNameString(fileName) {
  const regEx = /^[^\\/:\*\?"<>\|]+$/;
  return regEx.test(fileName);
}

const ExportStoryboardToPDF = props => {
  const { onExport, onClose } = props;
  const [fileName, setFileName] = useState("");
  const [chartFitToPage, setChartFitToPage] = useState(false);
  const [isValidFileName, setIsValidFileName] = useState(true);

  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true);
  const [encryptFile, setEncryption] = useState(UNCHECKED);

  const [displayFilters, setDisplayFilters] = useState(UNCHECKED);

  const refPassword = React.useRef();
  const refConfirmPassword = React.useRef();

  useEffect(() => {
    if (props.isOpen === false) {
      setEncryption(UNCHECKED);
    }
    if (encryptFile === UNCHECKED) {
      setIsValidPassword(true);
      setIsValidConfirmPassword(true);
    }
  });

  const handleDisplayFilters = () => {
    setDisplayFilters(displayFilters === UNCHECKED ? CHECKED : UNCHECKED);
  };
  const handlePasswordOptions = () => {
    setEncryption(encryptFile === UNCHECKED ? CHECKED : UNCHECKED);
  };

  function validatePasswords() {
    const password = refPassword.current.value;
    const confirmPassword = refConfirmPassword.current.value;
    const invalidTypes = PasswordValidatorService.validatePdfPassword(password);
    if (invalidTypes.length > 0) {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(true);
    }
    if (password === confirmPassword) {
      setIsValidConfirmPassword(true);
    } else {
      setIsValidConfirmPassword(false);
    }
  }

  function handleExport() {
    let encryptionOptions = { encrypt: false };
    if (encryptFile === CHECKED) {
      const password = refPassword.current.value;
      validatePasswords();
      encryptionOptions = { encrypt: true, password };
      if (!password || !isValidPassword || !isValidConfirmPassword || !isOptionsValid(fileName)) return;
    } else if (!isOptionsValid(fileName)) {
      return;
    }
    const today = RelativeDateTranslator.getTimeZoneDate();
    const date = `${today.format(FOOTER_DATE_FORMAT)}`;

    const footerTemplate = `<div style="font-family: 'Times New Roman', Times, serif; margin-left:20px; margin-right:20px;border-top: solid lightgray 1px; font-size: 11px; padding-top: 3px; text-align: left; width: 100%; display:flex; justify-content: space-between; color: lightgray;">
        <div>${i18n.t("_Export.Footer.Created_", { date })}</div>
        <div>${i18n.t("_Export.Footer.Paginator_")}</div>
        <div>${i18n.t("_Export.Footer.Copyright_")}</div>
      </div>`;
    const options = {
      fileName,
      chartFitToPage,
      displayFilters: displayFilters === "checked" ? true : false,
      date,
      ...encryptionOptions,
    };
    const pdfOptions = { footerTemplate };
    onExport(options, pdfOptions);
  }

  function isOptionsValid(fileName) {
    let isValid = true;
    const name = fileName.trim();
    if (name.length === 0 || !isValidFileNameString(name)) {
      isValid = false;
      setIsValidFileName(false);
    } else {
      setIsValidFileName(true);
    }
    return isValid;
  }

  function renderFileNameError() {
    if (!isValidFileName) {
      const message =
        fileName.trim().length === 0
          ? i18n.t("_ExportConfig.Field.FileName.Error_")
          : i18n.t("_ExportConfig.Field.FileName.Invalid_");
      return (
        <div class="aclui-form-error" role="alert">
          <i aria-hidden="true" class="aclui-icon acl-i-exclamation-circle filename-error-icon" role="img"></i>
          <div>{message}</div>
        </div>
      );
    }
  }

  function renderFilNameInput() {
    return (
      <div class="item-group">
        <label>{i18n.t("_ExportConfig.Field.FileName_")}</label>
        <Input
          placeholder={i18n.t("_ExportConfig.Field.FileName_")}
          value={fileName}
          onChange={e => {
            const { value } = e.target;
            if (value.length > 250) {
              setFileName(fileName);
              isOptionsValid(fileName);
            } else {
              setFileName(value);
              isOptionsValid(value);
            }
          }}
        />
        {renderFileNameError()}
      </div>
    );
  }

  function renderLayoutOptions() {
    return (
      <div class="item-group">
        <label>{i18n.t("_ExportConfig.Field.Layout_")}</label>
        <ListBox
          key="export-type"
          onChange={(index, options) => {
            const chartFitToPage = options[index].value === "ChartFitToPage" ? true : false;
            setChartFitToPage(chartFitToPage);
          }}
        >
          <ListBox.Trigger hasClearButton={false} />
          <ListBox.Option value="SameAsStoryboard" defaultIsSelected={!chartFitToPage}>
            {i18n.t("_ExportConfig.Layout.SameAsStoryboard_")}
          </ListBox.Option>
          <ListBox.Option value="ChartFitToPage" defaultIsSelected={chartFitToPage}>
            {i18n.t("_ExportConfig.Layout.ChartFitToPage_")}
          </ListBox.Option>
        </ListBox>
      </div>
    );
  }

  function renderDisplayFilters() {
    return (
      <div class="item-group">
        <Checkbox onChange={handleDisplayFilters} checkedState={displayFilters}>
          {i18n.t("_ExportConfig.Field.IncludeFilters_")}
        </Checkbox>
      </div>
    );
  }

  function renderPasswordOptions() {
    if (!props.pdfPasswordOptionsFlipper) return "";
    return (
      <>
        <div class="item-group">
          <Checkbox onChange={handlePasswordOptions} checkedState={encryptFile}>
            {i18n.t("_ExportConfig.Field.PasswordProtect_")}
            <Popover isEager isDark zIndex={8}>
              <Popover.Trigger>
                {(handler, a11yAttributes) => (
                  <Icon
                    onMouseOver={handler}
                    onMouseOut={handler}
                    onFocus={handler}
                    onBlur={handler}
                    tabIndex={0}
                    aria-label="info"
                    role="img"
                    size="20px"
                    style={{
                      verticalAlign: "top",
                      marginLeft: "4px",
                    }}
                    {...a11yAttributes}
                  />
                )}
              </Popover.Trigger>
              <Popover.Content>
                <Popover.Card>{i18n.t("_ExportConfig.Toast.PasswordProtectInfo_")}</Popover.Card>
              </Popover.Content>
              <Popover.Tip />
            </Popover>
          </Checkbox>
        </div>
        {encryptFile === CHECKED ? (
          <>
            <div className="item-group">
              <Toast>{i18n.t("_ExportConfig.Toast.PasswordInfo_")}</Toast>
            </div>
            <div class="item-group">
              <label>{i18n.t("_ExportConfig.Field.Password_")}</label>
              <Input
                ref={refPassword}
                placeholder={i18n.t("_ExportConfig.Placeholder.Password_")}
                onBlur={validatePasswords}
                type="password"
                defaultValue=""
              />
              {isValidPassword === true ? (
                ""
              ) : (
                <div class="aclui-form-error" role="alert">
                  <i aria-hidden="true" class="aclui-icon acl-i-exclamation-circle filename-error-icon" role="img"></i>
                  <div>{i18n.t("_ExportConfig.Error.Password_")}</div>
                </div>
              )}
            </div>
            <div class="item-group">
              <label>{i18n.t("_ExportConfig.Field.ReEnterPassword_")}</label>
              <Input
                ref={refConfirmPassword}
                placeholder={i18n.t("_ExportConfig.Placeholder.Password_")}
                onBlur={validatePasswords}
                type="password"
                defaultValue=""
              />
              {isValidConfirmPassword === true ? (
                ""
              ) : (
                <div class="aclui-form-error" role="alert">
                  <i aria-hidden="true" class="aclui-icon acl-i-exclamation-circle filename-error-icon" role="img"></i>
                  <div>{i18n.t("_ExportConfig.Error.ConfirmPassword_")}</div>
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  return (
    <div>
      <SidePanel isOpen={props.isOpen} onClose={props.onClose} width={"600px"} className="pdf-config-panel">
        <SidePanel.Overlay />
        <SidePanel.Header isSticky className="stb-panel__header">
          {i18n.t("_ExportConfig.Header.ExportOptions_")}
        </SidePanel.Header>
        <SidePanel.Content className="stb-panel__body">
          {renderFilNameInput()}
          {renderDisplayFilters()}
          {renderLayoutOptions()}
          {renderPasswordOptions()}
        </SidePanel.Content>
        <SidePanel.Footer isSticky>
          <div class="pdf-config-panel__footer">
            <Button kind={"primary"} onClick={handleExport}>
              {i18n.t("_ExportConfig.Button.Export_")}
            </Button>
            <Button kind={"default"} onClick={onClose}>
              {i18n.t("_General.Close.Label_")}
            </Button>
          </div>
        </SidePanel.Footer>
      </SidePanel>
      <DownloadModal
        isOpen={props.isModalOpen}
        onModalCancel={props.hideDownloadModal}
        showDownload={props.showDownload}
        downloadLink={props.downloadLink}
        downloadError={props.downloadError}
        fileName={props.fileName}
      ></DownloadModal>
    </div>
  );
};

ExportStoryboardToPDF.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onExport: PropTypes.func,
  hideDownloadModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  showDownload: PropTypes.bool,
  downloadLink: PropTypes.string,
  downloadError: PropTypes.bool,
  fileName: PropTypes.string,
  pdfPasswordOptionsFlipper: PropTypes.bool,
};

ExportStoryboardToPDF.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onExport: opts => {},
  hideDownloadModal: () => {},
  isModalOpen: false,
  showDownload: false,
  downloadLink: "",
  downloadError: false,
  fileName: "",
  pdfPasswordOptionsFlipper: false,
};

export default ExportStoryboardToPDF;

window.ExportStoryboardToPDF = ExportStoryboardToPDF;
