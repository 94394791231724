angular.module("acl.storyboard.storyboard").factory("UnsavedData", function() {
  let hasUnsavedData = false;
  return {
    set: function(value) {
      hasUnsavedData = value;
    },
    get: function() {
      return hasUnsavedData;
    },
  };
});
