import ReactDirective from "acl-ui/angular/reactDirective.umd";
import Icon from "acl-ui/components/Icon";
import Modal, { ModalHeader, ModalFooter, ModalBody } from "acl-ui/components/Modal";
import ConfirmationModal from "@viz-ui/components/confirmationModal/confirmationModal.jsx";
import CopyInterpretation from "@viz-ui/components/copyInterpretation/copyInterpretation.jsx";
import ChartSelectorModal from "@viz-ui/components/chartSelectorModal/chartSelectorModal.jsx";
import CopyInterpretationToast from "@viz-ui/components/copyInterpretation/copyInterpretationToast.jsx";
import ResultsRecordCount from "@viz-ui/components/resultsRecordCount/resultsRecordCount.jsx";
import ChartTabFactory from "./chartTab.service";

angular
  .module("acl.visualizer.dataVisualizer", [
    "acl.angular.ui",
    "acl.common.config",
    "acl.common.event",
    "acl.common.filters",
    "acl.common.globalNav",
    "acl.common.localize",
    "acl.common.locationManager",
    "acl.common.resizeListener",
    "acl.common.userAgent",
    "acl.visualizer.aclColorPicker",
    "acl.visualizer.biView",
    "acl.visualizer.charts.controllers",
    "acl.visualizer.charts",
    "acl.visualizer.dataFilter",
    "acl.visualizer.dataModel",
    "acl.visualizer.dataTable",
    "acl.visualizer.chartConfigPanel",
    "acl.visualizer.csv",
    "acl.visualizer.fieldValue",
    "acl.visualizer.filterPanel",
    "acl.visualizer.formatters",
    "acl.visualizer.loadingAnimation",
    "acl.visualizer.model.field",
    "acl.visualizer.saveViz",
    "acl.visualizer.shareToGRC",
    "acl.visualizer.slidingNavTabs",
    "acl.visualizer.sorters",
    "acl.visualizer.statistics",
    "acl.visualizer.summary",
    "ui.bootstrap",
    "ui.router",
    "ngAnimate",
    "highbond.visualizer.navigation",
    "acl.visualizer.downloadReports.backend",
  ])
  .directive("acluiIcon", ReactDirective.getFactory(Icon))
  .directive("acluiModal", ReactDirective.getFactory(Modal))
  .directive("acluiModalHeader", ReactDirective.getFactory(ModalHeader))
  .directive("acluiModalFooter", ReactDirective.getFactory(ModalFooter))
  .directive("acluiModalBody", ReactDirective.getFactory(ModalBody))
  .directive("confirmationModal", ReactDirective.getFactory(ConfirmationModal))
  .directive("copyInterpretation", ReactDirective.getFactory(CopyInterpretation))
  .directive("copyInterpretationToast", ReactDirective.getFactory(CopyInterpretationToast))
  .directive("chartSelectorModal", ReactDirective.getFactory(ChartSelectorModal))
  .directive("resultsRecordCount", ReactDirective.getFactory(ResultsRecordCount))
  .factory("ChartTab", ChartTabFactory);
