/* eslint no-param-reassign: 0 */
// ^-- This rule does not allow you to change params passed into function but
// HOT requires us to change TD elements during render hence it has been
// turned off rather than cheating workaround of assigning to new variable.
import React from "react";
import ReactDOMServer from "react-dom/server";
import $ from "jquery";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import Handsontable from "handsontable/dist/handsontable";
import i18n from "@viz-ui/i18n/i18n";

import GlobalValueFormatter from "../../services/formatters/globalValueFormatter";
import UsageTracker from "../../services/tracking/usageTracker";

import GridHeader from "./gridHeader/gridHeader";
import ConditionFormattingIcon from "../conditionalFormattingIcon/conditionalFormattingIcon";
import reorderFieldModelsByColumnConfigs from "./fieldOrderer";
import {
  isRowSelected,
  getRowDataId,
  isCheckAllClicked,
  checkAllColumnId,
  highlightSelectedRow,
} from "./rowSelectionHelper";
import StringFormatter from "../../services/formatters/stringFormatter";
import logger from "../../services/logger/logger";

import "handsontable/dist/handsontable.full.css";
import backendApi from "@results/services/apiCall/backendApi";

const INVERSE_SELECTION_THRESHOLD = 200;

const HOT_KEY = "894a1-209ce-91403-d482b-2c04e";
const VIEW_PORT_ROW_RENDERING_OFFSET = 30; // Reducing the offset row rendering to 30 from 50 to handle freezing checkbox column for 200 columns
const VIEW_PORT_COLUMN_RENDERING_OFFSET = 150;
let selectedColumn = null;

function initializeHandsontable(getProps, getData, getRowSelection, getGridHeaderModifier, targetElement) {
  const props = getProps();
  logger.log(`${props.fieldFormatIdentifier} - Init HoT`);
  const hotProps = propsToHotSettings(getProps(), getData(), getRowSelection());
  // remove mergeCell when not required, to avoid error.
  if (hotProps.mergeCells.length === 0) {
    delete hotProps.mergeCells;
  }
  const hot = new Handsontable(targetElement, hotProps);
  addColumnHeaderClickHandler(getProps, getData, getRowSelection, hot);
  addColumnResizeHandler(getProps, hot);
  addRowClickHandler(getProps, getData, getRowSelection, hot);
  addAfterScrollVerticallyHook(getProps, getData, hot, targetElement);
  addDoubleClickHook(getProps, getData, getRowSelection, hot);
  addAfterRenderHook(getProps, getData, getRowSelection, hot);
  addAfterGetColHeader(getGridHeaderModifier, hot);
  addBeforeChangeHook(getProps, getData, getRowSelection, hot);
  addAfterUpdateSettingHook(hot);

  return hot;
}

function addAfterScrollVerticallyHook(getProps, getData, hot, containerElement) {
  let prevLastVisibleRow = 0;
  const scrollHandler = debounce(
    () => {
      const props = getProps();
      const transformedData = getData();
      const hasCallback = !!props.onScrollToBottom;

      const firstVisibleRow = getFirstVisibleRow(hot);
      const lastVisibleRow = firstVisibleRow + hot.countVisibleRows();
      const isScrollingDown = prevLastVisibleRow < lastVisibleRow;

      const error = 2;
      const wasAtBottom = prevLastVisibleRow + error >= transformedData.length;
      const isAtBottom = lastVisibleRow + error >= transformedData.length;

      if (props.detectScrollToBottomByHeight) {
        const tableScrollDiv = $(containerElement).find(".ht_master .wtHolder");
        const tableScrollTop = tableScrollDiv.scrollTop();
        const tableInnerHeight = tableScrollDiv.innerHeight();
        const tableScrollHeight = tableScrollDiv[0].scrollHeight;
        const isBottom = tableScrollTop + tableInnerHeight >= tableScrollHeight;

        if (hasCallback && isBottom) {
          props.onScrollToBottom();
        }
      } else {
        if (hasCallback && isScrollingDown && !wasAtBottom && isAtBottom) {
          props.onScrollToBottom();
        }
      }

      prevLastVisibleRow = lastVisibleRow;
    },
    10,
    { maxWait: 10 }
  );
  hot.addHook("afterScrollVertically", scrollHandler);
}

function addDoubleClickHook(getProps, getData, getRowSelection, hot) {
  // Extend Handsontable to introduce double click event
  var eventManager = new Handsontable.EventManager(this);

  eventManager.addEventListener(hot.rootElement, "dblclick", function(e) {
    let { target } = e;

    if (["TD", "TH"].indexOf(target.nodeName) === -1) {
      target = Handsontable.dom.closest(target, "TH") || Handsontable.dom.closest(target, "TD");
    }

    if (!target) return;

    const coords = hot.view._wt._wot.wtTable.getCoords(target);
    const { row, col } = coords;

    if (row >= 0 && col >= 0) {
      Handsontable.hooks.run(hot, "dblClickCell", row, col, e);
    }
  });

  hot.addHook("dblClickCell", function(row, col) {
    const props = getProps();
    const transformedData = getData();
    if (col === checkAllColumnId(props)) return;

    if (props.onRowDoubleClick) {
      const rowData = transformedData[row];
      const rowDataId = getRowDataId(rowData, props.rowIdKey);
      props.onRowDoubleClick(row, rowDataId);
    }
  });
}

function updateHandsontable(props, transformedData, rowSelection, hot) {
  UsageTracker.mark("handsontable.updateSettings");
  logger.log(`${props.fieldFormatIdentifier} - updateHandsontable`);

  const prevFirstVisibleRow = getFirstVisibleRow(hot);
  const prevFirstVisibleColumn = getFirstVisibleColumn(hot);
  const hotProps = propsToHotSettings(props, transformedData, rowSelection);

  // remove mergeCell when not required, to avoid error.
  if (hotProps.mergeCells.length === 0) {
    delete hotProps.mergeCells;
  }

  if (hotProps.data.length !== 0) {
    delete hotProps.data;
  }

  // don't update data using updateSettings except empty data
  hot.updateSettings(hotProps);

  // for non-empty data load it separately.
  if (transformedData.length !== 0) {
    hot.loadData(transformedData);
  }

  if (prevFirstVisibleRow !== getFirstVisibleRow(hot)) {
    hot.scrollViewportTo(prevFirstVisibleRow, prevFirstVisibleColumn);
  }
}

function addAfterUpdateSettingHook(hot) {
  hot.addHook("afterUpdateSettings", () => {
    UsageTracker.mark("handsontable.afterUpdateSettings");
  });
}

function propsToHotSettings(props, transformedData, rowSelection) {
  var reorderedFieldModels = reorderFieldModelsByColumnConfigs(props.fields, props.columnConfigs);

  return {
    autoRowSize: props.isShrinkWrapEnabled && !props.fixedColumnsLeft,
    autoColumnSize: false,
    viewportRowRenderingOffset: VIEW_PORT_ROW_RENDERING_OFFSET,
    viewportColumnRenderingOffset: VIEW_PORT_COLUMN_RENDERING_OFFSET,
    data: transformedData,
    rowHeaders: false,
    colHeaders: fieldModelsToColumnHeaders(props, reorderedFieldModels, rowSelection),
    colWidths: fieldModelsToColumnWidths(props, reorderedFieldModels),
    columns: fieldModelsToColumns(props, transformedData, rowSelection, reorderedFieldModels),
    mergeCells: props.mergeCells,
    fixedRowsBottom: props.fixedRowsBottom,
    hiddenColumns: getHiddenColumns(props),
    manualColumnResize: props.manualColumnResize === false ? false : true,
    nestedHeaders: fieldModelsToNestedHeaders(props, reorderedFieldModels),
    copyable: true, // make the cells copyable via CTRL+C
    fillHandle: false, // small box in corner of selection forces just vertical or horizontal extending
    outsideClickDeselects: props.outsideClickDeselects, // clicking outside of the table will deselect
    // beforeKeyDown: event => { // disabling this prop as it seems to lead to issues with keyboard navigation in record processing panel (record counts changing)
    // Prevent Handsontable from stealing input from the following containers
    //  if (event.target.closest(".process-panel__main-container, .quick-menu__content"))
    //    event.stopImmediatePropagation();
    //},
    disableVisualSelection: false, // disable highlight for cell or area or none
    fragmentSelection: "cell", // native browser selection vs HOT selection
    renderAllRows: false,
    fixedColumnsLeft: 0,
    licenseKey: HOT_KEY,
    afterInit: () => {
      UsageTracker.mark("handsontable.afterInit");
    },
    columnHeaderHeight: props.fixedColumnsLeft ? undefined : props.manualColumnResize === false ? undefined : 29, // added to handle word wrap of column headers
    afterSelection: function(r, c, r2, c2, preventScrolling, selectionLayerLevel) {
      preventScrolling.value = true;
    },
  };
}

function getFirstVisibleRow(hot) {
  return hot.view._wt.wtTable.getFirstVisibleRow();
}

function getFirstVisibleColumn(hot) {
  return hot.view._wt.wtTable.getFirstVisibleColumn();
}

function checkFixedRowsBottom(props, hot) {
  const totalRows = hot.countRows();
  const hotSettings = hot.getSettings();
  const fixedRowsBottom = hot.countVisibleRows() < totalRows ? props.fixedRowsBottom : 0;
  const bottomOverlayElement = hot.view._wt._wot.wtOverlays.bottomOverlay.clone.wtTable.wtRootElement;
  if (fixedRowsBottom === 0 && bottomOverlayElement.style.bottom) {
    bottomOverlayElement.style.bottom = null;
  }
  if (fixedRowsBottom !== hotSettings.fixedRowsBottom) {
    logger.log(`${props.fieldFormatIdentifier} - checkFixedRowsBottom updateSetting`);
    hot.updateSettings({
      fixedRowsBottom: fixedRowsBottom,
    });
  }
}

function addColumnHeaderClickHandler(getProps, getData, getRowSelection, hot) {
  hot.addHook("afterOnCellMouseUp", function(mouseEvent, { col, row }) {
    const props = getProps();
    if (isCheckAllClicked(props, row, col, mouseEvent.target)) return;

    if (row === -1 && props.onColumnHeaderClick) {
      const column = getDataGridColumnIndex(props, col);
      if (column >= 0) {
        selectedColumn = column;
        const fieldName = getFieldName(props, column);
        props.onColumnHeaderClick(col, fieldName, mouseEvent);
      }
    }
  });
}

function checkAllClickHandler(getProps, getData, getRowSelection, hot, checkbox) {
  const isChecked = checkbox.checked;
  const props = getProps();
  const rowSelection = getRowSelection();
  const transformedData = getData();

  if (isChecked) {
    rowSelection.setSelectAllMode();
    if (props.filteredRecordCount > INVERSE_SELECTION_THRESHOLD) {
      rowSelection.setInverseMode(true);
    } else {
      const checkedRowIds = transformedData.map(rowData => getRowDataId(rowData, props.rowIdKey));
      rowSelection.setCheckedRowIds(checkedRowIds);
    }
  } else {
    rowSelection.resetCheckboxes();
  }

  transformedData.forEach(rowData => {
    rowData.__checked = isChecked;
  });

  if (isChecked && transformedData.length) {
    const selectedRowId = rowSelection.getSelectedRowId();
    const selectedRowIndex = transformedData.findIndex(rowData => rowData[props.rowIdKey].value === selectedRowId);
    const rowId = selectedRowIndex > -1 ? selectedRowIndex : 0;
    selectRow(props, rowSelection, rowId, transformedData[rowId]);
  }
  updateFieldColumnHeaders(props, rowSelection, hot);
  onSelectionChange(props, rowSelection);
}

function addBeforeChangeHook(getProps, getData, getRowSelection, hot) {
  hot.addHook("beforeChange", changes => {
    if (changes) {
      const [rowId, field] = changes[0];
      if (field === "__checked") {
        if (rowId === -1) {
          return false;
        }
        selectRowFromCheckBoxChange(getProps, getData, getRowSelection, changes);
        applySelectionChange(getProps, getData, getRowSelection, changes);
        updateRowStyleOnCheckboxChange(getData(), changes);
        return false;
      }
    }
  });
}

function applySelectionChange(getProps, getData, getRowSelection, changes) {
  const props = getProps();
  const transformedData = getData();
  const rowSelection = getRowSelection();
  const prevMode = rowSelection.getSelectAllMode();
  // changes are handsontable way of identifying changes in the table
  // it is a 2-dimension array of a list of changes
  // where each change represents [row, prop (a.k.a. field), oldVal, newVal]
  changes.forEach(change => updateRowSelection(props, transformedData, rowSelection, change));
  if (prevMode !== rowSelection.getSelectAllMode()) {
    const selectAllCheckbox = $(".data-grid__select-all");
    const isSelectAllChecked = rowSelection.isSelectAllMode() || rowSelection.isIndeterminateMode();
    const isIndeterminate = rowSelection.isIndeterminateMode();
    selectAllCheckbox.prop("checked", isSelectAllChecked);
    selectAllCheckbox.prop("indeterminate", isIndeterminate);
  }
  onSelectionChange(props, rowSelection);
}

function selectRowFromCheckBoxChange(getProps, getData, getRowSelection, changes) {
  const props = getProps();
  const transformedData = getData();
  const rowSelection = getRowSelection();
  const firstCheckedRow = changes.find(change => change[3]);
  if (firstCheckedRow) {
    const [rowId] = firstCheckedRow;
    selectRow(props, rowSelection, rowId, transformedData[rowId]);
  }
}

function updateRowSelection(props, transformedData, rowSelection, change) {
  const [rowId, field, prevValue, newValue] = change;
  const rowDataId = getRowDataId(transformedData[rowId], props.rowIdKey);
  const checkbox = $(`input.htCheckboxRendererInput[data-row=${rowId}]`);
  // FIXME: refactor data transformer to a class that keep state of transformed data, and provide interface to set data.
  if (transformedData[rowId]) {
    transformedData[rowId].__checked = newValue;
  }
  checkbox.prop("checked", newValue);
  if (field === "__checked") {
    if (!prevValue && newValue) {
      rowSelection.checkRow(rowDataId, transformedData.length);
    } else if (prevValue && !newValue) {
      rowSelection.uncheckRow(rowDataId, transformedData.length);
    }
  }
}

function onSelectionChange(props, rowSelection) {
  props.onSelectionChange(
    rowSelection.getCheckedRowIds(),
    rowSelection.getSelectedRowId(),
    rowSelection.getInverseMode()
  );
}

function updateRowStyleOnCheckboxChange(transformedData, changes) {
  changes.forEach(change => {
    const [rowId] = change;
    const rowCellElements = $(`input.htCheckboxRendererInput[data-row=${rowId}]`)
      .closest("tr")
      .find("td");
    setCheckedRowClass(transformedData[rowId], rowCellElements);
  });
}

function updateFieldColumnHeaders(props, rowSelection, hot) {
  const reorderedFieldModels = reorderFieldModelsByColumnConfigs(props.fields, props.columnConfigs);
  logger.log(`${props.fieldFormatIdentifier} - updateFieldColumnHeaders updateSetting`);
  hot.updateSettings({
    colHeaders: fieldModelsToColumnHeaders(props, reorderedFieldModels, rowSelection),
  });
}

function getDataGridColumnIndex(props, hotColumn) {
  // negates the added special columns
  const addedColumns = [props.isRowSelectionEnabled, props.isPositionEnabled, props.isCommentsEnabled];
  return addedColumns.reduce((index, isColEnabled) => (isColEnabled ? index - 1 : index), hotColumn);
}

function getHiddenColumns(props) {
  const hiddenColumns = {
    columns: [],
    indicators: false,
  };
  const hideCheckboxColumn = props.isRowSelectionEnabled && props.showCheckedRowsOnly;

  if (hideCheckboxColumn) {
    const checkBoxColumnId = props.isPositionEnabled ? 1 : 0;
    hiddenColumns.columns = [checkBoxColumnId];
  }
  return hiddenColumns;
}

function addColumnResizeHandler(getProps, hot) {
  hot.addHook("afterColumnResize", function(size, col) {
    const props = getProps();
    if (props.onColumnResize) {
      let columnIndex = col;
      columnIndex = props.isPositionEnabled ? columnIndex - 1 : columnIndex;
      columnIndex = props.isRowSelectionEnabled ? columnIndex - 1 : columnIndex;
      columnIndex = props.isCommentsEnabled ? columnIndex - 1 : columnIndex;

      const fieldName = getFieldName(props, columnIndex);
      props.onColumnResize(columnIndex, fieldName, size);
    }

    checkFixedRowsBottom(props, hot);
  });
}

function addRowClickHandler(getProps, getData, getRowSelection, hot) {
  var startingCoordinates;
  var endCoordinates;

  hot.addHook("afterOnCellMouseDown", function(mouseEvent, { col, row }) {
    if (col === checkAllColumnId(getProps())) return;
    startingCoordinates = [col, row];
  });

  hot.addHook("afterOnCellMouseUp", function(mouseEvent, { col, row }) {
    if (col === checkAllColumnId(getProps())) return;
    const isHeader = row < 0;
    const isAnchorTag = mouseEvent.target.tagName === "A";
    endCoordinates = [col, row];

    if (!isHeader && !isAnchorTag && isEqual(startingCoordinates, endCoordinates)) {
      const props = getProps();
      const transformedData = getData();
      const rowSelection = getRowSelection();
      const rowData = transformedData[row];
      selectRow(props, rowSelection, row, rowData);
      if (props.checkRowOnRowClick) {
        $(`.ht_master input.htCheckboxRendererInput[data-row="${row}"]`).click();
      } else {
        onSelectionChange(props, rowSelection);
      }
    }
  });
}

function addAfterRenderHook(getProps, getData, getRowSelection, hot) {
  hot.addHook("afterViewRender", function() {
    const props = getProps();
    logger.log(`${props.fieldFormatIdentifier} - After HoT render`);
    UsageTracker.mark("handsontable.afterViewRender");
    addCheckAllChangeHandler(getProps, getData, getRowSelection, hot);
    checkFixedRowsBottom(getProps(), hot);
    updateFixedColumnsLeft(getProps(), hot);
  });
}

function updateFixedColumnsLeft(props, hot) {
  var settings = hot.getSettings();
  var fixedColumnsLeft = props.fixedColumnsLeft || 0;
  if (settings.fixedColumnsLeft !== fixedColumnsLeft) {
    logger.log(`${props.fieldFormatIdentifier} - updateFixedColumnsLeft updateSetting`);
    hot.updateSettings({
      fixedColumnsLeft: fixedColumnsLeft,
    });
  }
}

function addCheckAllChangeHandler(getProps, getData, getRowSelection, hot) {
  $(".data-grid__select-all").change(event => {
    checkAllClickHandler(getProps, getData, getRowSelection, hot, event.target);
  });
  $(".data-grid__select-all").mousedown(event => {
    if (event.target.indeterminate) {
      event.target.checked = false;
      $(event.target).trigger("change");
    }
  });
  const rowSelection = getRowSelection();
  $(".data-grid__select-all").prop("indeterminate", rowSelection.isIndeterminateMode());
}

function addAfterGetColHeader(getGridHeaderModifier, hot) {
  const plugin = hot.getPlugin("autoRowSize");
  const headerRowHeight = plugin.getColumnHeaderHeight();
  let afterCallCounter = 0;
  hot.addHook("afterGetColHeader", (column, TH) => {
    const gridHeaderBody = $(TH).find(".grid-header__body");
    const row = gridHeaderBody.data("headerRowIndex");
    const col = gridHeaderBody.data("headerColIndex");
    const gridHeaderModifier = getGridHeaderModifier();
    gridHeaderModifier.applyModifier(TH, { row, col });
    if (selectedColumn !== null && selectedColumn === col) {
      afterCallCounter++;
      if (afterCallCounter === 2) {
        /*  If column filter popup is open, highlight the respective column.
        Typically required when the grid is scrolled and the filter popup is open.
         */
        if ($(".quick-menu").is(":visible")) {
          gridHeaderBody.addClass("grid-header--is-active");
        }

        afterCallCounter = 0;
      }
    }
    // adjust header height when it has rowspan
    const rowspan = TH.getAttribute("rowspan");
    if (rowspan > 1) {
      const headerHeight = headerRowHeight * rowspan + 1;
      TH.style.height = headerHeight + "px";
    }
  });
}

function getFieldName(props, col) {
  const reorderedFieldModels = reorderFieldModelsByColumnConfigs(props.fields, props.columnConfigs);
  return (reorderedFieldModels && reorderedFieldModels[col].name()) || undefined;
}

function sanitizeAttributeValue(v) {
  return v.replace(/[&<>"]/g, "");
}

function fieldModelsToColumnHeaders(props, fieldModels, rowSelection) {
  const fields = [];

  if (props.isPositionEnabled && props.positionKey) {
    fields.push("");
  }

  if (props.isRowSelectionEnabled) {
    const isCheckedAll = rowSelection.isSelectAllMode() || rowSelection.isIndeterminateMode();
    const checkboxColumnId = checkAllColumnId(props);
    const checkAll = `<input class="data-grid__select-all" data-row="-1" data-col="${checkboxColumnId}" type="checkbox" ${
      isCheckedAll ? "checked" : ""
    } aria-label="${i18n.t("_CheckBox.SelectAll.Label_")}" tabindex="0"/>`;
    fields.push(checkAll);
  }

  if (props.isCommentsEnabled) {
    fields.push(`<span class="grid-header__comments-header">${i18n.t("_DataGrid.CommentsAndAttachments_")}</span>`);
  }

  return fields.concat(
    fieldModels.map((fieldModel, headerColIndex) => {
      const headerRowIndex = props.nestedHeaders.length;
      return fieldModelToColumnHeader(props)(fieldModel, headerRowIndex, headerColIndex);
    })
  );
}

function fieldModelsToNestedHeaders(props, fieldModels) {
  if (props.nestedHeaders && props.nestedHeaders.length) {
    const headers = nestedFieldModelsToNestedHeaders(props, props.nestedHeaders);
    headers.push(fieldModelsToColumnHeaders(props, fieldModels));
    return headers;
  }
}

function nestedFieldModelsToNestedHeaders(props, nestedHeaderRows) {
  return nestedHeaderRows.map((nestedHeaderRow, headerRowIndex) =>
    nestedHeaderRow.map((nestedField, headerColIndex) =>
      getNestedHeader(props, nestedField, headerRowIndex, headerColIndex)
    )
  );
}

function getNestedHeader(props, nestedField, headerRowIndex, headerColIndex) {
  return {
    label: fieldModelToColumnHeader(props)(nestedField.field, headerRowIndex, headerColIndex),
    colspan: nestedField.colspan ? nestedField.colspan : 1,
    rowspan: nestedField.rowspan ? nestedField.rowspan : 1,
  };
}

function fieldModelsToColumnWidths(props, fieldModels) {
  const {
    columnConfigs,
    fontZoomRatio,
    isCommentsEnabled,
    isPositionEnabled,
    isPresentationMode,
    isRowSelectionEnabled,
    positionKey,
  } = props;
  const defaultKeyWidth = 50;
  const defaultColWidth = 200;
  const columnZoomRatio = fontZoomRatio * 1.4;
  const fields = [];

  if (isPositionEnabled && positionKey) {
    const posColumnConfig = columnConfigs.find(config => config.name() === positionKey);
    const colWidth = (posColumnConfig && posColumnConfig.width()) || defaultKeyWidth;
    fields.push(isPresentationMode ? colWidth * columnZoomRatio : colWidth);
  }
  if (isRowSelectionEnabled) {
    fields.push(24);
  }
  if (isCommentsEnabled) {
    fields.push(26);
  }

  return fields.concat(
    fieldModels.map(fieldModel => {
      const columnConfig = columnConfigs.find(config => config.name() === fieldModel.name());
      const colWidth = (columnConfig && columnConfig.width()) || defaultColWidth;
      return isPresentationMode ? colWidth * columnZoomRatio : colWidth;
    })
  );
}

function fieldModelsToColumns(props, transformedData, rowSelection, fieldModels) {
  const { isPositionEnabled, isRowSelectionEnabled } = props;
  const fields = [];

  if (isPositionEnabled && props.positionKey) {
    fields.push({
      data: props.positionKey,
      readOnly: true,
      renderer: getPositionCellRenderer(props, transformedData, rowSelection),
      className: "htCenter",
    });
  }

  if (isRowSelectionEnabled) {
    fields.push({
      data: "__checked",
      type: "checkbox",
      renderer: getCheckboxCellRenderer(props, transformedData, rowSelection),
      copyable: false, // don't want checkbox part of selection
    });
  }

  if (props.isCommentsEnabled) {
    fields.push({
      data: "__comment",
      copyable: false,
      readOnly: true,
      renderer: getCommentCellRenderer(props, transformedData, rowSelection),
    });
  }

  return fields.concat(
    fieldModels.map(function(fieldModel) {
      const currentColumnConfig = props.columnConfigs.find(columnConfig => columnConfig.name() === fieldModel.name());
      return {
        data: fieldModel.name(),
        readOnly: true,
        renderer: getCellRenderer(props, transformedData, rowSelection, fieldModel),
        wordWrap: currentColumnConfig ? !!currentColumnConfig.wordWrap() : false,
      };
    })
  );
}

function getCommentCellRenderer(props, transformedData, rowSelection) {
  return function(instance, td, row, col, prop, field, cellProperties) {
    if (!field) return;
    const rowData = transformedData[row];
    const rowDataId = getRowDataId(rowData, props.rowIdKey);
    td.dataset.recordId = rowDataId;

    setSelectedRowBorderColor(props, rowData, rowSelection, td);
    setCheckedRowClass(rowData, $(td));

    const { description, iconClass } = field;
    const formattedValue = iconClass
      ? `<span><span class="data-grid__comment-description">${description}</span><i class="${iconClass} data-grid__comment-icon"></i></span>`
      : "";

    const args = [instance, td, row, col, prop, formattedValue, cellProperties];
    Handsontable.renderers.HtmlRenderer.apply(this, args);
  };
}

function getPositionCellRenderer(props, transformedData, rowSelection) {
  return function(instance, td, row, col, prop, field, cellProperties) {
    if (!field) return;
    const rowData = transformedData[row];
    const rowDataId = getRowDataId(rowData, props.rowIdKey);
    td.dataset.recordId = rowDataId;

    setSelectedRowBorderColor(props, rowData, rowSelection, td);
    setCheckedRowClass(rowData, $(td));

    const args = [instance, td, row, col, prop, field.value, cellProperties];

    Handsontable.renderers.HtmlRenderer.apply(this, args);
  };
}

function getCheckboxCellRenderer(props, transformedData, rowSelection) {
  return function(instance, td, row, col, prop, field, cellProperties) {
    const rowData = transformedData[row];
    const rowDataId = getRowDataId(rowData, props.rowIdKey);
    td.dataset.recordId = rowDataId;

    setSelectedRowBorderColor(props, rowData, rowSelection, td);
    setCheckedRowClass(rowData, $(td));

    const args = [instance, td, row, col, prop, field, cellProperties];

    Handsontable.cellTypes.checkbox.renderer.apply(this, args);

    if (rowData && rowData["metadata.position"]) setCheckboxRowAriaLabel($(td), rowData["metadata.position"].value);
  };
}

function getCellRenderer(props, transformedData, rowSelection, fieldModel) {
  return function(instance, td, row, col, prop, field, cellProperties) {
    const rowData = transformedData[row];
    const rowDataId = getRowDataId(rowData, props.rowIdKey);
    td.dataset.recordId = rowDataId;

    if (!field) {
      return;
    }
    if (fieldModel.type() === "numeric") {
      td.className = "htRight";
    }

    let { formattedValue } = field;
    try {
      if (GlobalValueFormatter.isHtmlFormatted(props.fieldFormatIdentifier, fieldModel.name())) {
        formattedValue = StringFormatter.sanitizeHtml(formattedValue);
      } else if (GlobalValueFormatter.isUrlFormatted(props.fieldFormatIdentifier, fieldModel.name())) {
        formattedValue = StringFormatter.convertUrlLink(formattedValue);
      } else {
        formattedValue = StringFormatter.encode(formattedValue);
      }
    } catch (error) {
      //to handle invalid data error for sanitize html
      console.error(error);
    }
    if ((fieldModel.type() === "file" || fieldModel.type() === "digital_signature") && field.files)
      formattedValue = addLinksToFiles(field);
    if (field.iconName) formattedValue = addIcon(field, formattedValue);

    Handsontable.renderers.HtmlRenderer.apply(this, [instance, td, row, col, prop, formattedValue, cellProperties]);

    setSelectedRowBorderColor(props, rowData, rowSelection, td);
    setCheckedRowClass(rowData, $(td));

    if (field.backgroundColor)
      td.style.setProperty("background", sanitizeAttributeValue(field.backgroundColor), "important");
    if (field.textColor) td.style.setProperty("color", sanitizeAttributeValue(field.textColor), "important");

    if (field.fontWeight) {
      td.style.fontWeight = sanitizeAttributeValue(field.fontWeight);
    }
    //render cell with blank value
    if (!field.value) {
      field.value = "";
    }
  };
}

function selectRow(props, rowSelection, row, rowData) {
  const rowDataId = getRowDataId(rowData, props.rowIdKey);
  rowSelection.selectRow(rowDataId);
  props.onRowClick(row, rowDataId);
  if (props.highlightSelectedRow) {
    highlightSelectedRow(props, rowSelection, rowData);
  }
}

function setSelectedRowBorderColor(props, rowData, rowSelection, td) {
  if (props.highlightSelectedRow && isRowSelected(props, rowData, rowSelection)) {
    $(td).addClass("selected-row");
  } else {
    $(td).removeClass("selected-row");
  }
}

function setCheckedRowClass(rowData, cellElements) {
  if (rowData && rowData.__checked) {
    cellElements.addClass("checked-row");
  } else {
    cellElements.removeClass("checked-row");
  }
}

function setCheckboxRowAriaLabel(cellElements, rowNo) {
  if (cellElements) {
    cellElements
      .find("input.htCheckboxRendererInput")
      .attr("aria-label", i18n.t("_CheckBox.Record.Label_", { count: rowNo }))
      .attr("tabindex", "0");
  }
}

function addLinksToFiles(field) {
  let color;
  if (field.textColor) color = `color: ${field.textColor};`;
  const links = field.files.map(
    ({ path, title }) =>
      `<i class="acl-i-attachment data-grid__inline-icon" style="${color}"></i><a target="_blank" href="${backendApi.getLoganUrl() +
        path}" style="${color}">${title}</a>`
  );
  return links.join(", ");
}

function addIcon({ iconColor = "#000", iconName }, formattedValue) {
  const sanatizedIconColor = sanitizeAttributeValue(iconColor);
  const icon = ReactDOMServer.renderToString(
    <ConditionFormattingIcon iconName={iconName} color={sanatizedIconColor} />
  );
  return `${icon}${formattedValue}`;
}

function fieldModelToColumnHeader(props) {
  return function(fieldModel, headerRowIndex, headerColIndex) {
    const headerProps = {
      fieldModel: fieldModel,
      sortFieldName: props.sortFieldName,
      sortOrder: props.sortOrder,
      filterFieldNames: props.filterFieldNames,
      selectedFieldName: props.selectedFieldName,
      nestedHeaders: props.nestedHeaders,
      className: "htRight",
      headerRowIndex,
      headerColIndex,
      sourcePage: props.sourcePage,
    };
    return ReactDOMServer.renderToString(<GridHeader {...headerProps} />);
  };
}

export { checkFixedRowsBottom, initializeHandsontable, propsToHotSettings, updateHandsontable };
