import isEqual from "lodash/isEqual";
import Model from "../model";
import Field from "../field/field";
import FieldFilterSet from "./fieldFilterSet";

class FilterConfig extends Model {
  static modelType = "FilterConfig";

  constructor(data) {
    super({ fieldFilterSets: [], ...data });
  }

  filtersOpen(...args) {
    return this.accessor("filtersOpen", args, "boolean");
  }

  startRecord(...args) {
    return this.accessor("startRecord", args, "number");
  }

  sortField(...args) {
    return this.accessor("sortField", args, Field);
  }

  sortOrder(...args) {
    return this.accessor("sortOrder", args, "string");
  }

  fieldFilterSets(...args) {
    return this.accessor("fieldFilterSets", args, [FieldFilterSet]);
  }

  filterEquals(filterConfig) {
    return (
      filterConfig &&
      isEqual(this.sortField(), filterConfig.sortField()) &&
      isEqual(this.sortOrder(), filterConfig.sortOrder()) &&
      isEqual(this.fieldFilterSets(), filterConfig.fieldFilterSets())
    );
  }

  clone() {
    return new FilterConfig(this._data);
  }

  static fromJson(json) {
    return new FilterConfig()
      .filtersOpen(json.filtersOpen)
      .startRecord(json.params ? json.params.start : 1)
      .sortField(deserializeSortField(json))
      .sortOrder(json.sortField ? json.sortField.order : undefined)
      .fieldFilterSets(deserializeFieldFilterSets(json.filterList));
  }

  toJson() {
    return this._deleteUndefinedProperties({
      filtersOpen: this.filtersOpen(),
      sortField: serializeSortField(this),
      params: {
        start: this.startRecord(),
      },
      filterList: serializeFieldFilterSets(this.fieldFilterSets()),
    });
  }
}

function serializeSortField(filterConfig) {
  if (filterConfig.sortField() && filterConfig.sortOrder()) {
    return {
      field: filterConfig.sortField().name(),
      order: filterConfig.sortOrder(),
    };
  }
  return undefined;
}

function serializeFieldFilterSets(sets) {
  if (sets && sets.length > 0) {
    return sets.map(set => set.toJson());
  }
  return undefined;
}

function deserializeSortField(data) {
  if (data.sortField) {
    return new Field().name(data.sortField.field);
  }
  return undefined;
}

function deserializeFieldFilterSets(data) {
  if (data) {
    return data.map(FieldFilterSet.fromJson);
  }
  return [];
}

export default FilterConfig;
