angular.module("acl.visualizer.notification").directive("aclNotification", function() {
  function NotificationCtrl($scope) {
    var self = this;

    self.onDismiss = function() {
      if ($scope.onDismiss) {
        $scope.onDismiss();
      }
    };

    $scope.$watch("type", function(newValue) {
      switch (newValue) {
        case "error":
        case "warning":
        case "success":
          self.type = newValue;
          break;

        case "info":
        default:
          self.type = "info";
          break;
      }
    });
  }

  return {
    restrict: "E",
    replace: true,
    scope: {
      onDismiss: "&",
      type: "<",
    },
    templateUrl: "visualizer/js/modules/core/notification/notification.tpl.html",
    controller: NotificationCtrl,
    controllerAs: "vm",
    bindToController: {
      message: "<",
    },
  };
});
