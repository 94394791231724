import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import debounce from "lodash/debounce";
import ColorStops from "@viz-ui/services/chartConfig/colorStopsService";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";
import UrlFlipper from "@viz-ui/services/urlFlipper/urlFlipper";
import ChartDataConfigAdapter from "@viz-ui/services/charts/chartConfigPanel/chartDataConfigAdapterService";
import SectionAdapter from "@viz-ui/services/chartConfig/sectionAdapterService";

const DATA_TAB = "data";
const DISPLAY_TAB = "display";
const SORT_TAB = "sort";

const aclChartConfigPanel = {
  bindings: {
    chartType: "<",
    closeChartConfigPanel: "&",
    colorData: "<",
    dataConfig: "<",
    displayConfig: "<",
    fieldFormatIdentifier: "<",
    fieldNameToFieldObj: "<",
    onColorMappingChange: "&",
    onDataConfigChange: "&",
    onDeleteChartClick: "&",
    onDisplayConfigChange: "&",
    vizId: "<",
  },
  templateUrl: "visualizer/js/modules/core/chartConfigPanel/chartConfigPanel.tpl.html",
  controllerAs: "chartConfigPanel",
  controller: class ChartConfigPanelController {
    constructor(
      $scope,
      AppConfig,
      ChartBaseInterface,
      ChartDisplayConfigAdapter,
      ChartService,
      Localize,
      SectionValidator,
      Sorter,
      Field,
      FieldFormat
    ) {
      "ngInject";

      this.$scope = $scope;
      this.AppConfig = AppConfig;
      this.ChartBaseInterface = ChartBaseInterface;
      this.ChartDisplayConfigAdapter = ChartDisplayConfigAdapter;
      this.ChartService = ChartService;
      this.Localize = Localize;
      this.SectionAdapter = SectionAdapter;
      this.SectionValidator = SectionValidator;
      this.Sorter = Sorter;
      this.Field = Field;
      this.FieldFormat = FieldFormat;

      let currentSelectedTab = DATA_TAB;
      this.selectTab = selectedTab => {
        currentSelectedTab = selectedTab;
      };
      this.isDataTabSelected = () => currentSelectedTab === DATA_TAB;
      this.isDisplayTabSelected = () => currentSelectedTab === DISPLAY_TAB;
      this.isSortTabSelected = () => currentSelectedTab === SORT_TAB;
      this.isSortTabEnabledForType = chartType => this.ChartService.isSortTabEnabledForType(chartType);

      this.sectionData = {};
      this.defaultPropValue = "";

      this.showChartFormattingOptions = this.AppConfig.features.chartFormatOptions;
      this.sortDirectionOptions = [
        {
          key: "",
          value: Localize.getLocalizedString("_Sort.Default.Label_"),
        },
        {
          key: "asc",
          value: Localize.getLocalizedString("_Sort.Ascending.Values.Label_"),
        },
        {
          key: "desc",
          value: Localize.getLocalizedString("_Sort.Descending.Values.Label_"),
        },
      ];

      this.viewModel = { dataConfig: undefined, displayConfig: undefined };

      this.tabProps = {
        selectedItem: DATA_TAB,
        showSortTab: false,
        handleTabChange: selectedTab => {
          this.tabProps.selectedItem = selectedTab;
          this.selectTab(selectedTab);
        },
      };

      this.summaryTableDataConfigProps = {
        handleDataConfigChange: dataConfig => {
          this.viewModel.dataConfig = cloneDeep(dataConfig);
          this._dataConfigChanged();
        },
      };

      this.treemapDataConfigProps = {
        handleDataConfigChange: dataConfig => {
          this.viewModel.dataConfig = dataConfig;
        },
      };

      this.combinationChartDataConfigProps = {
        handleDataConfigChange: dataConfig => {
          this.viewModel.dataConfig = dataConfig;
        },
      };

      this.mapChartDataConfigProps = {
        handleDataConfigChange: dataConfig => {
          this.viewModel.dataConfig = dataConfig;
        },
      };

      this.statisticsDataConfigProps = {
        handleDataConfigChange: dataConfig => {
          this.viewModel.dataConfig = dataConfig;
        },
      };

      this.displayConfigChanged = debounce(
        () => {
          disableHorizontalBarChartXAxisLabel(this.chartType, this.viewModel.displayConfig);
          ensureValidMinMaxValues(this.chartType, this.viewModel.displayConfig);
          ensureValidColorStops(this.chartType, this.viewModel.displayConfig);
          ensureValidNumBins(this.chartType, this.viewModel.displayConfig, this.displayConfig);
          removeNullValueColorStops(this.chartType, this.viewModel.displayConfig);
          enableDualYAxisToggle(this.chartType, this.viewModel.displayConfig);

          this._displayConfigChanged();
        },
        1000,
        { leading: true }
      );

      this.sortConfigPanelProps = {
        sortOrder: "default",
        dataType: undefined,
        fieldName: undefined,
        values: [],
        onSortConfigChange: sortConfig => {
          const { sortOrder, valuesOrder } = sortConfig;
          this.viewModel.displayConfig.order = sortOrder;
          if (valuesOrder) {
            this.viewModel.displayConfig.valuesOrder = valuesOrder;
          }
          this.displayConfigChanged();
        },
        onDiscardSortConfigChange: () => {
          this.sortConfigPanelProps.displayConfig = cloneDeep(this.displayConfig);
          this.resetDisplayConfig();
        },
      };

      this.chartFieldFormatProps = {
        onChangeFieldFormat: this.handleChangeFieldFormat.bind(this),
      };

      this.bubbleScaleProps = {
        dropdownOptions: ChartService.BubbleScaleInfo.options,
        showBubbleScale: () => {
          return this.AppConfig.features.bubbleScaling && this.chartType === "BubbleChart";
        },
      };
    }

    $onChanges(changesObj) {
      // Because one chart config panel works with multiple tabs (visualizations),
      // need to be be aware of props/flags cleanup when switching between chartType/dataConfig/displayConfig
      if (changesObj.chartType && this.chartType) {
        this.sectionData = this.SectionAdapter.getSection(this.chartType);

        const currentChartType = changesObj.chartType.currentValue;
        if (this.isSortTabSelected() && !this.isSortTabEnabledForType(currentChartType)) {
          this.selectTab(DATA_TAB);
          this.tabProps.selectedItem = DATA_TAB;
        }

        const isStatisticsOrSummaryViz = this.chartType === "StatisticsViz";
        if (isStatisticsOrSummaryViz) {
          this.selectTab(DATA_TAB);
        }
      }

      if ((changesObj.chartType && this.chartType) || changesObj.dataConfig) {
        this._updateTemplateVisibilityProperties(this.chartType);
      }

      if ((changesObj.chartType || changesObj.fieldNameToFieldObj) && this.chartType && this.fieldNameToFieldObj) {
        this.chartConfigColumnDefs = this._getColumnDefs(this.chartType, this.fieldNameToFieldObj);
      }

      if (changesObj.displayConfig && this.chartType) {
        this.viewModel.displayConfig = this.displayConfig
          ? this.ChartDisplayConfigAdapter.configToViewModel(this.chartType, this.displayConfig)
          : {};

        this.sortConfigPanelProps.displayConfig = this.viewModel.displayConfig;

        if (this.chartType === "SummaryTable") {
          this.viewModel.displayConfig.showTotals = this.viewModel.displayConfig.showTotals === false ? false : true;
        } else if (this.chartType === "MapChart") {
          this.viewModel.displayConfig.ColorScaleTypes = this.viewModel.displayConfig.ColorScaleTypes || "";
          this.handleColorStopVisibility();
        } else {
          this.viewModel.displayConfig.order = this.viewModel.displayConfig.order || "";
        }
      }

      if (changesObj.dataConfig && this.chartType) {
        const viewModelDataConfig = this.dataConfig
          ? ChartDataConfigAdapter.configToViewModel(this.chartType, this.dataConfig)
          : {};

        this.sortConfigPanelProps.dataConfig = this.dataConfig;
        this.viewModel.dataConfig = viewModelDataConfig;
      }

      if (changesObj.dataConfig || changesObj.displayConfig) {
        this._setupFieldFormatOptionProps(this.dataConfig, this.displayConfig);
      }

      if (changesObj.colorData) {
        this.sortConfigPanelProps.chartData = this.colorData;
      }
      if (changesObj.fieldFormatIdentifier) {
        this.sortConfigPanelProps.fieldFormatIdentifier = this.fieldFormatIdentifier;
      }
    }

    _updateTemplateVisibilityProperties(chartType) {
      this.displayAggregationInput = false;
      this.displayAxisConfigInputs = false;
      this.displayBarChartHorizontalInput = false;
      this.displayBarChartTypeInput = false;
      this.displayCategoriesSelect = false;
      this.displayChartSeriesDropdown = false;
      this.displayConfigPanelMenu = false;
      this.tabProps.showSortTab = false;
      this.displayCountsInput = false;
      this.displayInterpolateInput = false;
      this.displayLineChartSmoothEdgeInput = false;
      this.displayPieChartCountsInput = false;
      this.displayPieChartPercentagesInput = false;
      this.displayPieChartCategoryInput = false;
      this.displayPieChartDonutInput = false;
      this.displaySortByDropdown = false;
      this.displayStackedAreaChartTypeInput = false;
      this.displayStackedAreaSeriesInput = false;
      this.displayStatisticsVizCategoryInput = false;
      this.displayTreemapGroupLabelsInputs = false;
      this.displayXAxisInput = false;
      this.displayYAxisMinMax = false;
      this.displayYAxisInput = false;
      this.displayCombinationChartGroupInputLabel = false;
      this.displayCombinationChartTypeInput = false;
      this.displayBoostInput = UrlFlipper.isRenderMode("boost");
      this.displayMapChartOptions = false;
      this.displayLegend = true;
      this.displayTotal = false;
      this.displayTotals = false;
      this.ColorStopType3 = false;
      this.ColorStopType5 = false;

      const showSortTab = this.AppConfig.features.sortConfigPanel && this.isSortTabEnabledForType(chartType);
      switch (chartType) {
        case "BarChart":
          this.displayAggregationInput = true;
          this.displayAxisConfigInputs = true;
          this.displayChartSeriesDropdown = true;
          this.displayConfigPanelMenu = true;
          this.tabProps.showSortTab = showSortTab;
          this.displayCountsInput = true;
          this.displayBarChartHorizontalInput = true;
          this.displayBarChartTypeInput = true;
          this.displaySortByDropdown = !showSortTab;
          this.displayXAxisInput = true;
          this.displayYAxisMinMax = true;
          this.displayPieChartPercentagesInput = true;
          break;

        case "BubbleChart":
          this.displayAggregationInput = true;
          this.displayAxisConfigInputs = true;
          this.displayChartSeriesDropdown = true;
          this.displayConfigPanelMenu = true;
          this.tabProps.showSortTab = showSortTab;
          this.displayCountsInput = true;
          this.displayXAxisInput = true;
          this.displayYAxisInput = true;
          this.displayYAxisMinMax = this._isYAxisNumeric();
          break;

        case "Heatmap":
          this.displayAggregationInput = true;
          this.displayAxisConfigInputs = true;
          this.displayConfigPanelMenu = true;
          this.tabProps.showSortTab = showSortTab;
          this.displayCountsInput = true;
          this.displayXAxisInput = true;
          this.displayYAxisInput = true;
          break;

        case "LineChart":
          this.displayAggregationInput = true;
          this.displayAxisConfigInputs = true;
          this.displayConfigPanelMenu = true;
          this.tabProps.showSortTab = showSortTab;
          this.displayCountsInput = true;
          this.displayChartSeriesDropdown = true;
          this.displayInterpolateInput = true;
          this.displayLineChartSmoothEdgeInput = true;
          this.displayXAxisInput = true;
          this.displayYAxisMinMax = true;
          break;

        case "PieChart":
          this.displayAggregationInput = true;
          this.displayConfigPanelMenu = true;
          this.tabProps.showSortTab = showSortTab;
          this.displayPieChartCategoryInput = true;
          this.displayPieChartCountsInput = true;
          this.displayPieChartDonutInput = true;
          this.displayPieChartPercentagesInput = true;
          this.displayTotal = true;
          break;

        case "StackedAreaChart":
          this.displayAggregationInput = true;
          this.displayAxisConfigInputs = true;
          this.displayConfigPanelMenu = true;
          this.tabProps.showSortTab = showSortTab;
          this.displayCountsInput = true;
          this.displayInterpolateInput = true;
          this.displayStackedAreaChartTypeInput = true;
          this.displayStackedAreaSeriesInput = true;
          this.displayXAxisInput = true;
          this.displayYAxisMinMax = true;
          break;

        case "StatisticsViz":
          this.displayAxisConfigInputs = true;
          this.displayCategoriesSelect = true;
          this.displayCountsInput = true;
          this.displayStatisticsVizCategoryInput = true;
          break;

        case "SummaryTable":
          this.displayAggregationInput = false;
          this.displayAxisConfigInputs = false;
          this.displayCategoriesSelect = false;
          this.displayCountsInput = false;
          this.displayConfigPanelMenu = this.AppConfig.features.displaySummaryMenu;
          this.displayBoostInput = false;
          this.displayLegend = false;
          this.displayTotals = true;
          break;

        case "Treemap":
          this.displayAggregationInput = true;
          this.displayConfigPanelMenu = true;
          this.tabProps.showSortTab = false;
          this.displayTreemapGroupLabelsInputs = true;
          break;

        case "CombinationChart":
          this.displayConfigPanelMenu = true;
          this.tabProps.showSortTab = true;
          this.displayLineChartSmoothEdgeInput = true;
          this.displayCombinationChartGroupInputLabel = true;
          this.displayCombinationChartTypeInput = true;
          this.displayCountsInput = true;
          break;

        case "MapChart":
          this.displayAggregationInput = true;
          this.displayConfigPanelMenu = true;
          this.tabProps.showSortTab = false;
          this.displayXAxisInput = true;
          this.displayLegend = false;
          this.displayBoostInput = false;
          this.displayMapChartOptions = true;
          this.handleColorStopVisibility();
          break;
      }
    }

    resetDisplayConfig() {
      this.viewModel.displayConfig = cloneDeep(this.displayConfig);
      this._displayConfigChanged();
      if (this.displayMapChartOptions) {
        this.handleColorStopChange(this.viewModel.displayConfig.colorStopType);
      }
    }

    isApplyButtonEnabled() {
      return (
        this.chartType &&
        this.viewModel.dataConfig &&
        this.SectionValidator.isSectionValid(this.chartType, this.viewModel.dataConfig)
      );
    }

    focusOnLastSelectedItem(t) {
      t.$select.setFocus();
    }

    submitDataConfig() {
      this._dataConfigChanged();
    }

    handleConfigFieldChange(configKey, selectedField) {
      this.viewModel.dataConfig[configKey] = selectedField;
    }

    handleDisplayConfigFieldChange(configKey, selectedField) {
      this.viewModel.displayConfig[configKey] = selectedField;
      this._displayConfigChanged();
    }

    handleColorStopChange(colorStopType) {
      this.viewModel.displayConfig.colorStopType = colorStopType;
      this.handleColorStopVisibility();
      this._displayConfigChanged();
    }

    handleColorStopVisibility() {
      this.ColorStopType3 = false;
      this.ColorStopType5 = false;
      if (this.viewModel.displayConfig) {
        if (this.viewModel.displayConfig.colorStopType === "3stops") {
          this.ColorStopType3 = true;
        } else if (this.viewModel.displayConfig.colorStopType === "5stops") {
          this.ColorStopType5 = true;
        }
      }
    }

    showColorStopControls() {
      return !!(
        this.chartType === "MapChart" &&
        this.displayMapChartOptions &&
        !(this.viewModel.dataConfig.chartRows && this.viewModel.dataConfig.chartRows.length > 1)
      );
    }

    showContinuousColorControls() {
      return !!(
        this.chartType === "Heatmap" ||
        (this.chartType === "Treemap" &&
          this.viewModel.dataConfig &&
          ((this.viewModel.dataConfig.aggregationTypes && this.viewModel.dataConfig.aggregationTypes[1] === "count") ||
            (this.viewModel.dataConfig.chartValues && this.viewModel.dataConfig.chartValues[1] !== "")))
      );
    }

    showDiscreteColorControls() {
      return !!(this.chartType === "Treemap"
        ? this.viewModel.dataConfig &&
          this.viewModel.dataConfig.aggregationTypes &&
          this.viewModel.dataConfig.aggregationTypes[1] !== "count" &&
          this.viewModel.dataConfig.chartValues &&
          this.viewModel.dataConfig.chartValues[1] === ""
        : this.chartType !== "Heatmap" && this.chartType !== "MapChart" && this.chartType !== "SummaryTable");
    }

    handleColorPickerChange(colorMapping) {
      if (this.onColorMappingChange) {
        this.onColorMappingChange({ colorMapping: colorMapping });
      }
    }

    onAggregationSelectorChange(type, fieldName) {
      this.viewModel.dataConfig.aggregationType = type;
      this.viewModel.dataConfig.chartValue = fieldName;
    }

    toggleDisplayConfigSetting(propertyPath) {
      const fullPropertyPath = `displayConfig.${propertyPath}`;
      set(this.viewModel, fullPropertyPath, !get(this.viewModel, fullPropertyPath));
      this.displayConfigChanged();
    }

    setDefaultValue(controlId) {
      this.defaultPropValue = document.getElementById(controlId).value;
    }

    verifyValue(controlId, propertyPath) {
      const fullPropertyPath = `displayConfig.${propertyPath}`;
      const propVal = get(this.viewModel, fullPropertyPath);
      const controlValue = document.getElementById(controlId).value;
      if (
        this.defaultPropValue !== controlValue &&
        ((typeof propVal === "boolean" && propVal) ||
          (typeof propVal === "number" && !Number.isNaN(propVal)) ||
          this.defaultPropValue.length !== controlValue.length)
      ) {
        this.displayConfigChanged();
      }
    }

    _dataConfigChanged() {
      if (this.onDataConfigChange) {
        if (
          this.viewModel.dataConfig &&
          this.viewModel.dataConfig.chartXAxis &&
          this.dataConfig &&
          this.dataConfig.chartXAxis &&
          this.viewModel.dataConfig.chartXAxis !== this.dataConfig.chartXAxis.fieldName
        ) {
          this.viewModel.displayConfig = { ...this.viewModel.displayConfig, valuesOrder: [] };
          this.sortConfigPanelProps.chartData = [{ values: [] }];
          this.sortConfigPanelProps.displayConfig = this.viewModel.displayConfig;
        }
        this.onDataConfigChange({ dataConfig: this.viewModel.dataConfig });
      }
    }

    _displayConfigChanged() {
      if (this.onDisplayConfigChange) {
        this.onDisplayConfigChange({ displayConfig: this.viewModel.displayConfig });
      }
    }

    hasFieldError(fieldName) {
      return fieldName && !this.fieldExists(fieldName);
    }

    fieldExists(fieldName) {
      return Object.prototype.hasOwnProperty.call(this.fieldNameToFieldObj, fieldName);
    }

    _getColumnDefs(chartType, fields) {
      const result = this.ChartBaseInterface.populateChartConfigColumnDefs(chartType, fields);

      const columnDefProperties = [
        "chartXAxis",
        "chartYAxis",
        "chartValue",
        "chartSeries",
        "chartRows",
        "chartValues",
        "chartCoordinates",
      ];
      columnDefProperties.forEach(columnDefProperty => {
        if (result) {
          if (result[columnDefProperty] !== undefined) {
            result[columnDefProperty] = this.Sorter.sort(result[columnDefProperty], {
              valueParser: item => item.displayName,
            });
          }
        }
      });

      return result;
    }

    _setupFieldFormatOptionProps(dataConfig, displayConfig) {
      if (this.isChartFormattingEnabled() && dataConfig && displayConfig) {
        const chartValueFieldName = `${this.vizId}-chart-value`;
        let chartValueField = new this.Field()
          .name(chartValueFieldName)
          .displayName(chartValueFieldName)
          .type("numeric");
        let chartValueFormattingOption = displayConfig.valueFormattingOptions || {};
        this.chartFieldFormatProps.field = chartValueField;
        this.chartFieldFormatProps.fieldFormat = this.FieldFormat.fromJson(chartValueFormattingOption);
        this.chartFieldFormatProps.linkLabel = this.Localize.getLocalizedString("_MetricsConfig.FormatOptions.Label_");
      }
    }

    handleChangeFieldFormat(field, fieldFormat) {
      // setting field format in global format map
      GlobalFieldFormatMap.setFieldFormat(this.fieldFormatIdentifier, field.name(), fieldFormat);
      GlobalFieldFormatMap.setFieldType(this.fieldFormatIdentifier, field.name(), field.type());

      // updating chart field format props so it can keep selection
      this.chartFieldFormatProps.fieldFormat = fieldFormat;

      // serialize field format and set it in displayConfig
      const fieldFormatObj = fieldFormat.toJson();
      this.viewModel.displayConfig.valueFormattingOptions = fieldFormatObj;

      // call display config changed call back so it will update data model
      this.displayConfigChanged();
    }

    isChartFormattingEnabled() {
      return this.AppConfig.features.chartFormatOptions;
    }

    _isYAxisNumeric() {
      return this.dataConfig && this.dataConfig.chartYAxis && this.dataConfig.chartYAxis.type === "numeric";
    }

    chartTypeClass() {
      switch (this.chartType) {
        case "SummaryTable":
          return "summary-table__config";
        case "Treemap":
          return "treemap__config";
        case "CombinationChart":
          return "combination-chart__config";
        default:
          return "";
      }
    }
  },
};

function disableHorizontalBarChartXAxisLabel(chartType, displayConfig) {
  if (chartType === "BarChart" && displayConfig.horizontal) {
    displayConfig.xAxis.showLabel = false;
  }
}

function enableDualYAxisToggle(chartType, displayConfig) {
  if (chartType === "CombinationChart" && displayConfig.chartStyle === "expand") {
    displayConfig.showDualYAxis = true;
  }
}

function ensureValidMinMaxValues(chartType, displayConfig) {
  const isMinMaxApplicableChart = ["BarChart", "LineChart", "StackedAreaChart"].includes(chartType);
  const { yAxis } = displayConfig;
  const isMinFinite = yAxis ? Number.isFinite(yAxis.minimum) : false;
  const isMaxFinite = yAxis ? Number.isFinite(yAxis.maximum) : false;
  if (isMinMaxApplicableChart && isMinFinite && isMaxFinite && yAxis.minimum >= yAxis.maximum) {
    yAxis.maximum = yAxis.minimum + 1;
  }
}

function ensureValidColorStops(chartType, viewModelDisplayConfig) {
  const { colorAxis } = viewModelDisplayConfig;
  if (chartType === "Heatmap" || chartType === "Treemap") {
    colorAxis.colorStops = ColorStops.getEnsuredAscendingStopValues(colorAxis.colorStops);
  } else if (viewModelDisplayConfig.colorStopType && chartType === "MapChart") {
    if (viewModelDisplayConfig.colorStopType && viewModelDisplayConfig.colorStopType === "3stops")
      colorAxis.colorStops.colorStops3 = ColorStops.getEnsuredAscendingStopValues(colorAxis.colorStops.colorStops3);
    else if (viewModelDisplayConfig.colorStopType && viewModelDisplayConfig.colorStopType === "5stops")
      colorAxis.colorStops.colorStops5 = ColorStops.getEnsuredAscendingStopValues(colorAxis.colorStops.colorStops5);
  }
}

function ensureValidNumBins(chartType, viewModelDisplayConfig, displayConfig) {
  if (chartType === "Heatmap" || chartType === "Treemap") {
    const { colorAxis } = viewModelDisplayConfig;
    const defaultNumBins = displayConfig && displayConfig.colorAxis && displayConfig.colorAxis.numBins;
    colorAxis.numBins = ColorStops.getEnsuredValidNumBins(colorAxis.numBins, defaultNumBins);
  }
}

function removeNullValueColorStops(chartType, viewModelDisplayConfig) {
  if (chartType === "Heatmap" || chartType === "Treemap") {
    viewModelDisplayConfig.colorAxis.colorStops.forEach(colorStop => {
      if (colorStop.value === null) {
        delete colorStop.value;
      }
    });
  } else if (chartType === "MapChart") {
    if (viewModelDisplayConfig.colorAxis) {
      viewModelDisplayConfig.colorAxis.colorStops.colorStops3.forEach(colorStop => {
        if (colorStop.value === null) {
          delete colorStop.value;
        }
      });
      viewModelDisplayConfig.colorAxis.colorStops.colorStops5.forEach(colorStop => {
        if (colorStop.value === null) {
          delete colorStop.value;
        }
      });
    }
  }
}

export default aclChartConfigPanel;
