import AggregationSelector from "./aggregationSelector.component";

angular
  .module("acl.visualizer.aggregationSelector", [
    "acl.common.filters",
    "acl.common.localize",
    "acl.common.directives",
    "acl.visualizer.dataModel",
    "acl.visualizer.style",
    "templates-visualizer",
    "ui.select",
  ])
  .component("aclAggregationSelector", AggregationSelector);
