angular
  .module("acl.visualizer.charts.backend")
  .service("LineChartBackendService", function(ChartBackendService, Sorter) {
    return {
      fetchJson: ChartBackendService.fetchJson,

      getRepresentation: function(resultData, config) {
        var data = [];

        var multipleSeries = config.chartSeries && config.chartSeries.fieldName;

        const seriesType = multipleSeries ? resultData.config.series.data_type : resultData.config.key.data_type;
        const xAxisType = resultData.config.key.data_type;
        const valueType = resultData.config.values.data_type;

        var seriesParser = ChartBackendService.getParserForType(seriesType);
        var xAxisParser = ChartBackendService.getParserForType(xAxisType);
        var valueParser = ChartBackendService.getParserForType(valueType);

        data = ChartBackendService.nest()
          .key(function(d) {
            return multipleSeries ? seriesParser(d.series) : "";
          })
          .key(function(d) {
            return xAxisParser(d.key);
          })
          .rollup(function(array) {
            if (array.length > 1) throw "Error: Found multiple data points for single category combination.";
            return valueParser(array[0].values[0]);
          })
          .excludeNullKeys(true)
          .entries(resultData.values);

        data.forEach(function(series) {
          // Sort x-axis
          series.values = Sorter.sort(series.values, {
            dataType: xAxisType,
            valueParser: item => item.key,
          });
        });

        // Sort the series
        data = Sorter.sort(data, {
          dataType: seriesType,
          valueParser: item => item.key,
        });

        return data;
      },
    };
  });
