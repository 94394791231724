import createReactClass from "create-react-class";
import PropTypes from "prop-types";

window.BoardSelector = createReactClass({
  propTypes: {
    hasDataError: PropTypes.bool.isRequired,
    projectInterpretations: PropTypes.object.isRequired,
    projectMetrics: PropTypes.object,
    positionToAdd: PropTypes.object,
    selectorOrientation: PropTypes.oneOf(["vertical", "horizontal"]),
    onSelectVisualization: PropTypes.func,
    onSelectMetric: PropTypes.func,
    onSelectTrigger: PropTypes.func,
    onSelectInterpretation: PropTypes.func,
    onAddTextBoard: PropTypes.func,
    onFanOpen: PropTypes.func,
    onFanClose: PropTypes.func,
  },

  //FIXME vizDropdown.es6.jsx visualizationSupported should not be derived from chartIconClass
  chartIconClass: {
    BarChart: "icon-bar-charts",
    LineChart: "icon-line-chart",
    StackedAreaChart: "icon-area-chart-stacked",
    PieChart: "icon-pie-chart",
    BubbleChart: "icon-bubble-chart",
    Heatmap: "icon-heatmap-chart",
    SummaryTable: "icon-summary-table-chart",
    Treemap: "icon-treemap-chart",
    CombinationChart: "icon-combination-chart",
    MapChart: "icon-map-chart",
  },

  componentDidMount() {
    // initialize tooltip
    $(this.dropdown)
      .find("li.has-tip")
      .tooltip();

    // initialize fan buttons
    $(this.button)
      .fanButtons()
      .on("fanButtons.open", e => {
        this.onFanOpen();
      })
      .on("fanButtons.close", e => {
        this.closeDropdowns();
        this.onFanClose();
      });
  },

  onFanOpen() {
    this.props.onFanOpen(this.props.id);
  },

  onFanClose() {
    this.props.onFanClose(this.props.id);
  },

  handleAddTextBoard(e) {
    e.preventDefault();
    var positionToAdd = this.props.positionToAdd;
    this.button.click();
    this.props.onAddTextBoard(positionToAdd);
  },

  handleSelectVisualization(interpretation, visualizationId) {
    var positionToAdd = this.props.positionToAdd;
    this.onFanClose();
    this.props.onSelectVisualization(interpretation, visualizationId, positionToAdd);
  },

  handleSelectInterpretation(interpretation) {
    var positionToAdd = this.props.positionToAdd;
    this.onFanClose();
    this.props.onSelectInterpretation(interpretation, positionToAdd);
  },

  handleSelectMetric(metric) {
    var positionToAdd = this.props.positionToAdd;
    this.onFanClose();
    this.props.onSelectMetric(metric, positionToAdd);
  },

  handleSelectTrigger(metric, conditionGroup) {
    var positionToAdd = this.props.positionToAdd;
    this.onFanClose();
    this.props.onSelectTrigger(metric, conditionGroup, positionToAdd);
  },

  closeDropdowns() {
    this.refs.vizDropdown.close();
    this.refs.metricDropdown.close();
  },

  toggleVizDropdown(e) {
    var button = $(this.button);
    this.closeDropdowns();
    this.refs.vizDropdown.toggle(button);
  },

  toggleMetricDropdown(e) {
    var button = $(this.button);
    this.closeDropdowns();
    this.refs.metricDropdown.toggle(button);
  },

  wrapperRef() {
    return this.refs.wrapper;
  },

  render() {
    var selectorOrientationClass = this.props.selectorOrientation === "vertical" ? "selector-vert" : "selector-horiz";
    return (
      <div ref="wrapper" className={"interpretation-selector-wrapper " + selectorOrientationClass}>
        <div className="interpretation-selector js-fan-button" ref={element => (this.button = element)}>
          <span />
        </div>
        <div className="interpretation-option option--1 js-fan-button__option" onClick={this.handleAddTextBoard}>
          <i className="acl-i-edit" />
        </div>
        <div className="interpretation-option option--2 js-fan-button__option" onClick={this.toggleVizDropdown}>
          <i className="acl-i-chart-bar" />
        </div>
        <div className="interpretation-option option--3 js-fan-button__option" onClick={this.toggleMetricDropdown}>
          <i className="acl-i-metric" />
        </div>
        <VizDropdown
          ref="vizDropdown"
          hasDataError={this.props.hasDataError}
          interpretations={this.props.projectInterpretations}
          chartIconClass={this.chartIconClass}
          onSelectVisualization={this.handleSelectVisualization}
          onSelectInterpretation={this.handleSelectInterpretation}
          boardSelectorWrapperRef={this.wrapperRef}
          vizBoards={this.props.listOfBoards.filter(item => item.type === "table" || item.type === "visualization")}
        />
        <MetricDropdown
          ref="metricDropdown"
          hasDataError={this.props.hasDataError}
          metrics={this.props.projectMetrics}
          onSelectMetric={this.handleSelectMetric}
          onSelectTrigger={this.handleSelectTrigger}
          boardSelectorWrapperRef={this.wrapperRef}
          metricBoards={this.props.listOfBoards.filter(item => item.type === "metric" || item.type === "trigger")}
        />
      </div>
    );
  },
});
