angular
  .module("acl.visualizer.model.chart")
  .factory("ChartDataManager", function(AppConfig, DataRequester, PromiseCache) {
    return {
      loadChartData: loadChartData,
    };

    function loadChartData(table, type, interpretationConfig, dataConfig, globalFilters, queryScope, displayConfig) {
      return PromiseCache.getPromise(
        requestChartData(table, type, interpretationConfig, dataConfig, globalFilters, queryScope, displayConfig),
        {
          requestType: "chartdata",
          chartType: type,
          tableId: table.id(),
          interpretationConfig,
          dataConfig,
          globalFilters,
          displayConfig,
        }
      );
    }

    function requestChartData(table, type, interpretationConfig, dataConfig, globalFilters, queryScope, displayConfig) {
      return function() {
        return DataRequester.getInstance("chartData").request(
          AppConfig.timeout,
          table,
          type,
          interpretationConfig,
          dataConfig,
          globalFilters,
          queryScope,
          displayConfig
        );
      };
    }
  });
