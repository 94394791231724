import EventService from "@viz-ui/services/eventService/eventService";
import i18n from "@viz-ui/i18n/i18n";

class Utils {
  constructor() {
    this.eventService = EventService.register("Utils");
  }

  generateFilename(type) {
    const date = new Date();
    const title = "data-export";

    let filename = [
      title,
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ].join("-");

    filename += "." + type;

    return filename;
  }

  handleError(error, type) {
    if (error) {
      this.eventService.publish("notify", "error", i18n.t("_Export." + type + ".error.unknown_"));
    }
  }
}

export default new Utils();
