angular
  .module("acl.visualizer.model.table")
  .factory("TableManager", function($q, AppConfig, DataRequester, PromiseCache) {
    return {
      loadTable,
      loadTableForMetric,
    };

    function loadTable(tableId, scope) {
      return PromiseCache.getPromise(tableRequested(tableId, scope), {
        requestType: "tableRequest",
        tableId,
        scope,
      });
    }

    function loadTableForMetric(metricId) {
      return PromiseCache.getPromise(metricTableRequested(metricId), {
        requestType: "metricId",
        metricId,
      });
    }

    function tableRequested(tableId, scope) {
      return function() {
        return DataRequester.getInstance("tableRequest").request(AppConfig.timeout, tableId, scope);
      };
    }

    function metricTableRequested(metricId) {
      return function() {
        return DataRequester.getInstance("metricTableRequest").request(AppConfig.timeout, metricId);
      };
    }
  });
