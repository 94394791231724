class LinkActions {
  static setLink(link) {
    return {
      type: "SET_LINK",
      link,
    };
  }

  static removeLink() {
    return {
      type: "REMOVE_LINK",
    };
  }
}

window.LinkActions = LinkActions;