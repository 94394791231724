/**
 * acl-focus-model
 *
 * Binds the focus of an element to a property on the scope.
 * When input is focused, the property is set true;
 * when input is blurred, the property is set false.
 *
 * If the value is empty, this directive just ignores it (doesn't throw error)
 */
angular.module("acl.common.input").directive("aclFocusModel", function($parse, $timeout) {
  return {
    restrict: "A",
    link: function(scope, element, attr) {
      var focusModelGet;
      var focusModelSet;

      if (attr.aclFocusModel) {
        // We can't use isolate scope because it will break other directives in the element (e.g. ng-model)
        // So we'll just copy how NgModelController does it:
        focusModelGet = $parse(attr.aclFocusModel);
        focusModelSet = focusModelGet.assign;
        if (!focusModelSet) {
          throw new Error('Non-assignable acl-focus-model="' + attr.aclFocusModel + '" (in ' + element + " element)");
        }

        // Set false by default
        focusModelSet(scope, false);

        element.bind("focus", function() {
          // Use $timeout per https://github.com/angular/angular.js/issues/1250 and other sites
          // (solves dilemma where value didn't take effect without being in a scope.$apply(),
          // but other times that same scope.$apply fails with "$digest was already in progress")
          $timeout(function() {
            focusModelSet(scope, true);
          });
        });

        element.bind("blur", function() {
          $timeout(function() {
            focusModelSet(scope, false);
          });
        });
      }
    },
  };
});
