import FieldFormatter from "@viz-ui/services/formatters/fieldFormatter";

(() => {
  var timeFormatOptions = {
    restrict: "E",
    bindings: {
      fieldFormat: "<",
      fieldName: "<",
      onFieldFormatChange: "&",
    },
    controllerAs: "timeOptions",
    controller: TimeFormatOptionsController,
    templateUrl: "visualizer/js/modules/core/fieldFormatOptions/timeFormatOptions.tpl.html",
  };

  function TimeFormatOptionsController(FieldFormat, FieldFormatAdapter) {
    "ngInject";
    var timeOptions = this;

    timeOptions.$onInit = function() {
      setupOptions();
    };

    timeOptions.$onChanges = function(changes) {
      if (changes.fieldFormat) {
        setupOptions();
      }
    };

    timeOptions.handleFormatClick = function(format) {
      let fieldFormat;
      if (timeOptions.fieldFormat) {
        fieldFormat = timeOptions.fieldFormat.clone().assign(format);
      } else {
        fieldFormat = new FieldFormat(format);
      }
      timeOptions.onFieldFormatChange({ fieldFormat: fieldFormat });
    };

    function setupOptions() {
      timeOptions.formatOptions = FieldFormatter.getTimeFormattingOptions(selectedFormattingOption());
    }

    function selectedFormattingOption() {
      return timeOptions.fieldFormat ? timeOptions.fieldFormat.toJson() : {};
    }
  }

  angular.module("acl.visualizer.fieldFormatOptions").component("timeFormatOptions", timeFormatOptions);
})();
