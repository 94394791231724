angular
  .module("acl.visualizer.charts")
  .factory("LineChart", function(ChartService, DataModel, AppConfig, ChartDataSorter) {
    var defaultMargin = { top: 90, right: 90, bottom: 120, left: 100 };

    return {
      defaultDisplayConfig: function() {
        return {
          showLegend: true,
          displayDataLabels: true,
          smoothEdged: false,
          enableBoost: true,
          interpolate: false,
          xAxis: {
            showLabel: true,
          },
          yAxis: {
            showLabel: true,
            minimum: null,
            maximum: null,
          },
        };
      },

      getChartDirectiveConfig: function(interpretationId, chartConfig) {
        var getColorByField = function() {
          return (
            (chartConfig &&
              chartConfig.dataConfig &&
              chartConfig.dataConfig.chartSeries &&
              chartConfig.dataConfig.chartSeries.fieldName) ||
            ""
          );
        };

        function valueFormatter(interpretationId, chartConfig) {
          const { vizId, dataConfig, displayConfig } = chartConfig;
          if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
            const chartValueFieldName = `${vizId}-chart-value`;
            const chartValueFieldType = "numeric";
            return ChartService.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
          }
          return ChartService.getValueFormatter(
            interpretationId,
            dataConfig.chartValue.fieldName,
            dataConfig.chartValue.type
          );
        }

        return {
          hasMultipleSeries: !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName),
          showLegend: chartConfig.displayConfig.showLegend,
          displayDataLabels: chartConfig.displayConfig.displayDataLabels,
          smoothEdged: chartConfig.displayConfig.smoothEdged,
          enableBoost: chartConfig.displayConfig.enableBoost,
          interpolate: chartConfig.displayConfig.interpolate,
          order: chartConfig.displayConfig.order,
          valuesOrder: chartConfig.displayConfig.valuesOrder,
          xAxis: {
            label: chartConfig.displayConfig.xAxis.showLabel
              ? chartConfig.displayConfig.xAxis.title
                ? chartConfig.displayConfig.xAxis.title
                : chartConfig.dataConfig.chartXAxis.displayName
              : "",
            tickFormatter: ChartService.getValueFormatter(
              interpretationId,
              chartConfig.dataConfig.chartXAxis.fieldName,
              chartConfig.dataConfig.chartXAxis.type
            ),
            title: chartConfig.displayConfig.xAxis.title,
            axisType: chartConfig.dataConfig.chartXAxis.type,
            fieldType: chartConfig.dataConfig.chartXAxis.type,
          },
          yAxis: {
            label: chartConfig.displayConfig.yAxis.showLabel
              ? chartConfig.displayConfig.yAxis.title
                ? chartConfig.displayConfig.yAxis.title
                : ChartService.getFieldLabel(chartConfig.dataConfig.chartValue)
              : "",
            tickFormatter: valueFormatter(interpretationId, chartConfig),
            title: chartConfig.displayConfig.yAxis.title,
            minimum: chartConfig.displayConfig.yAxis.minimum,
            maximum: chartConfig.displayConfig.yAxis.maximum,
          },
          DATA_ITEM_LIMIT: ChartService.getDataItemLimit(),
          colorByField: getColorByField(),
          labelFormatter: ChartService.getLabelFormatter(chartConfig, interpretationId),
        };
      },

      // FIXME: Make this function pure.
      populateChartConfigColumnDefs: function(chartConfigColumnDefs) {
        var tempColumnDef;
        var fields = DataModel.table.fields();
        if (fields) {
          for (var fieldName in fields) {
            tempColumnDef = {};
            tempColumnDef.displayName = fields[fieldName].displayName;
            tempColumnDef.fieldId = fields[fieldName].colId;
            tempColumnDef.fieldName = fieldName;
            tempColumnDef.type = fields[fieldName].type;

            switch (tempColumnDef.type) {
              case "character":
              case "logical":
                if (AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
                  chartConfigColumnDefs.chartSeries.push(tempColumnDef);
                  chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                }
                break;

              case "numeric":
                chartConfigColumnDefs.chartValue.push(tempColumnDef);
                chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                break;

              case "date":
              /* Falls through */
              case "datetime":
              /* Falls through */
              case "time":
                chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                break;
            }
          }
        }
        return chartConfigColumnDefs;
      },

      chartConfigColumnDefs: function() {
        return {
          chartSeries: [],
          chartValue: [],
          chartXAxis: [],
        };
      },

      isValidDataConfig: function(dataConfig) {
        return !!(
          dataConfig &&
          dataConfig.chartXAxis &&
          dataConfig.chartValue &&
          ((dataConfig.chartValue.aggregationType && dataConfig.chartValue.fieldName) ||
            dataConfig.chartValue.aggregationType === "count")
        );
      },

      reorderXAxisDataPoints: function(chartData, chartConfig) {
        const {
          order,
          valuesOrder,
          xAxis: { fieldType },
        } = chartConfig;

        if (!order || order === "default") {
          return ChartDataSorter.getXAxisDataPointsInAscOrder(chartData, fieldType);
        } else if (order === "custom") {
          return ChartDataSorter.getXAxisDataPointsInCustomOrder(chartData, valuesOrder, fieldType);
        }
      },

      reorderValues: function(chartData, chartConfig) {
        const {
          order,
          valuesOrder,
          xAxis: { fieldType },
        } = chartConfig;
        switch (order) {
          case "custom":
            return ChartDataSorter.sortChartDataByCustomOrder(chartData, valuesOrder, fieldType);
          default:
            return chartData.slice(0);
        }
      },
    };
  });
