import Field from "./field";

function deserializeField(fieldName, fieldData) {
  if (arguments.length === 1 && typeof fieldName === "object") {
    throw new Error("deserializeField(fieldName, fieldData) requires field name as first argument.");
  }

  return Field.fromJson(fieldName, fieldData);
}

function deserializeFieldMapToArray(fieldMap) {
  return Object.keys(fieldMap).reduce((result, key) => {
    const value = fieldMap[key];
    result.push(deserializeField(key, value));
    return result;
  }, []);
}

export default {
  deserializeFieldMapToArray,
};
