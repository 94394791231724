import {
  DateFormatter,
  DatetimeFormatter,
  LogicalFormatter,
  NumberFormatter,
  PercentageFormatter,
  TableDataFormatter,
  TimeFormatter,
} from "@acl-services/sriracha-formatters/dist/Formatters";
import StringFormatter from "@viz-ui/services/formatters/stringFormatter";

angular
  .module("acl.visualizer.formatters", [])
  .service("DateFormatter", () => DateFormatter)
  .service("DatetimeFormatter", () => DatetimeFormatter)
  .service("LogicalFormatter", () => LogicalFormatter)
  .service("NumberFormatter", () => NumberFormatter)
  .service("PercentageFormatter", () => PercentageFormatter)
  .service("StringFormatter", () => StringFormatter)
  .service("TableDataFormatter", () => TableDataFormatter)
  .service("TimeFormatter", () => TimeFormatter);
