angular.module("acl.visualizer.charts", [
  "acl.common.config",
  "acl.common.event",
  "acl.common.filters",
  "acl.common.localize",
  "acl.common.userAgent",
  "acl.visualizer.color",
  "acl.visualizer.dataModel",
  "acl.visualizer.dataMessage",
  "acl.visualizer.formatters",
  "acl.visualizer.heatmap",
  "acl.visualizer.summaryTable",
  "acl.visualizer.sorters",
  "acl.visualizer.chartConfigPanel",
  "acl.visualizer.treemap",
  "acl.visualizer.combinationChart",
  "acl.visualizer.mapChart",
  "ngSanitize",
]);
