import find from "lodash/find";
import PropTypes from "prop-types";
import React from "react";
import filter from "lodash/filter";
import Spinner from "@paprika/spinner";
import services from "../../../services/glue/appGlue";
class UserPane extends React.Component {
  constructor(props) {
    super(props);

    this.handleUpdateAssignments = this.handleUpdateAssignments.bind(this);
    this.toggleUserPanel = this.toggleUserPanel.bind(this);
    this.state = {
      paneMode: this.props.paneMode,
    };
  }

  getUserAssignments() {
    return filter(this.props.assignments, function(userAssignment) {
      return !userAssignment.isAdmin;
    });
  }

  getAccountUsers() {
    if (!this.props.users || !this.props.users.length) return [];
    let admins = this.props.admins;
    return this.props.users.filter(function(user) {
      let isAdmin = !!find(admins, function(admin) {
        return admin.userId === user.id;
      });
      if (!isAdmin) return user;
    });
  }

  getAccountGroups() {
    if (!this.props.groups || !this.props.groups.length) return [];

    let users = this.props.users;
    return this.props.groups.map(function(group) {
      const groupAssignments = group.group_assignments || [];
      return {
        id: group.id,
        name: group.name,
        users: groupAssignments.map(function(assignment) {
          return find(users, function(user) {
            return user.id === assignment.user_id;
          });
        }),
      };
    });
  }

  toggleUserPanel(paneMode) {
    return e => {
      this.setState({ paneMode: paneMode });
    };
  }

  handleUpdateAssignments(users, groups) {
    this.toggleUserPanel("currentUsers")();
    //added true to enable calling apis again to get the updated value
    this.props.onUpdateAssignment(users, groups, true);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.paneMode !== this.state.paneMode) {
      this.setState({ paneMode: nextProps.paneMode });
    }
  }

  showSpinner() {
    return (
      <div className="user-selector__spinner">
        <Spinner
          caption={services.Localize.getLocalizedString("_Storyboards.SharePanel.UserSelector.spinnerMessage")}
        />
      </div>
    );
  }

  render() {
    let panelBody;
    if (this.state.paneMode === "currentUsers") {
      panelBody = (
        <UsersList
          users={this.props.assignments}
          onPermissionChange={this.props.onPermissionChange}
          onRemoveUser={this.props.onRemoveUser}
          toggleUserPanel={this.toggleUserPanel("manageUsers")}
          paginationData={this.props.paginationData}
          handleCurrentUsersPageChange={this.props.handleCurrentUsersPageChange}
          isLoading={this.props.isLoading}
        />
      );
    } else {
      panelBody = (
        <div>
          <StoryboardShareUserSelector
            assignments={this.getUserAssignments()}
            accountUsers={this.getAccountUsers()}
            accountGroups={this.getAccountGroups()}
            onUpdateAssignment={this.handleUpdateAssignments}
            onCancel={() => {
              this.toggleUserPanel("currentUsers");
              this.props.handleCurrentUsersPageChange(1);
            }}
          />
        </div>
      );
    }
    return panelBody;
  }
}

UserPane.propTypes = {
  assignments: PropTypes.array,
  admins: PropTypes.array,
  users: PropTypes.array,
  groups: PropTypes.array,
  paneMode: PropTypes.string,
  onPermissionChange: PropTypes.func,
  onRemoveUser: PropTypes.func,
  onUpdateAssignment: PropTypes.func,
  isLoading: PropTypes.bool,
  paginationData: PropTypes.object,
  handleCurrentUsersPageChange: PropTypes.func,
};

window.UserPane = UserPane;
