import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import backendApi from "@results/services/apiCall/backendApi";
import { isPublicStoryboard } from "@viz-ui/services/storyboardUrlDetection/storyboardUrlDetection";
const GLOBAL_NAV_PLACEHOLDER = { background: "#FFF" };

const GlobalNavigationBar = ({ userFullName, currentAppId, userBelongsToOrg }) => {
  const isNavigatorPublic = () => {
    //only show private navigator if user is logged in and belongs to an org
    if (userFullName && userBelongsToOrg) {
      return false;
    } else {
      return true;
    }
  };

  const isPublic = isPublicStoryboard(window.location.href) ? isNavigatorPublic() : !userFullName;
  const globalNavDivRef = useRef(null);
  useEffect(() => {
    if (globalNavDivRef.current) {
      const height = globalNavDivRef.current.offsetHeight === 0 ? 64 : globalNavDivRef.current.offsetHeight; //set new global nav bar height
      document.documentElement.style.setProperty("--highbond-global-nav-height", `${height}px`);
    }
  }, [globalNavDivRef]);

  return (
    <div
      ref={globalNavDivRef}
      className="global-nav-wrapper"
      style={backendApi.isHostLocal() ? GLOBAL_NAV_PLACEHOLDER : undefined}
    >
      <hb-global-navigator app-origin={window.location.origin} is-public={isPublic} app-id={currentAppId} />
    </div>
  );
};

GlobalNavigationBar.propTypes = {
  userFullName: PropTypes.string.isRequired,
  currentAppId: PropTypes.string.isRequired,
  userBelongsToOrg: PropTypes.bool,
};
GlobalNavigationBar.defaultProps = {};
GlobalNavigationBar.displayName = "GlobalNavigationBar";

export default GlobalNavigationBar;
