import PropTypes from "prop-types";
import services from "../../services/glue/appGlue";
import RichTextEditor from "./common/rich_text_editor.es6";
import UrlFlipper from "@viz-ui/services/urlFlipper/urlFlipper";
import sanitizeHtml from "sanitize-html";

class TextBoard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        title: this.props.title,
        text: this.props.text,
      },
    };
  }

  static propTypes = {
    boardId: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    editMode: PropTypes.bool,
    palette: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    onChange: () => {},
    editMode: false,
  };

  handleTitleChange = value => {
    this.updateFieldValue("title", value);
  };

  handleTextChange = value => {
    this.updateFieldValue("text", value);
  };

  updateFieldValue(field, value) {
    let fieldChange = {};
    fieldChange[field] = value;
    this.setState({ fields: _.extend(this.state.fields, fieldChange) });
    this.props.onChange(this.state.fields);
  }

  getContentMarkup(contentType) {
    return { __html: this.getSanitizedText(contentType) };
  }

  getSanitizedText(contentType) {
    //configs are borrowed from acl-exception and are very specific to text board.
    const allowedAttributes = {
      div: ["style"],
      span: ["style", "class"],
      p: ["style", "class"],
      b: ["style", "class"],
      s: ["style", "class"],
      i: ["style", "class"],
      u: ["style", "class"],
      ul: ["style", "class"],
      ol: ["style", "class"],
      li: ["style", "class"],
      strong: ["style", "class"],
      a: ["target", "href", "name", "style", "rel", "class"],
    };

    return sanitizeHtml(this.state.fields[contentType], {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(["div", "span", "s", "strong", "em"]),
      allowedAttributes: { ...sanitizeHtml.defaults.allowedAttributes, ...allowedAttributes },
      transformTags: {
        a: function(tagName, attribs, text) {
          return {
            tagName,
            text,
            attribs: { ...attribs, target: "_blank", rel: "nofollow" },
          };
        },
      },
    });
  }

  componentDidMount() {
    if (UrlFlipper.isRenderMode("export")) {
      $(this.refs.textBoard)
        .find(".text-board-content *[style], .text-board-title *[style]")
        .each(function() {
          if (this.style.color) this.style.setProperty("color", this.style.color, "important");
          if (this.style.backgroundColor) {
            this.style.setProperty("background-color", this.style.backgroundColor, "important");
          }
        });
    }
  }

  getEditor() {
    const text = this.getSanitizedText("text");
    const title = this.getSanitizedText("title");

    return (
      <div className="editor">
        <RichTextEditor type="title" value={title} onChange={this.handleTitleChange} palette={this.props.palette} />
        <RichTextEditor type="text" value={text} onChange={this.handleTextChange} palette={this.props.palette} />
      </div>
    );
  }

  getViewer() {
    return (
      <div className="viewer">
        <div className="text-board-title">
          <div className="ql-editor" tabIndex="0" dangerouslySetInnerHTML={this.getContentMarkup("title")}></div>
        </div>
        <div className="text-board-content">
          <div className="ql-editor" tabIndex="0" dangerouslySetInnerHTML={this.getContentMarkup("text")}></div>
        </div>
      </div>
    );
  }

  render() {
    var content = this.props.editMode ? this.getEditor() : this.getViewer();
    return (
      <div className="text-board" ref="textBoard">
        {content}
      </div>
    );
  }
}

export default TextBoard;
window.TextBoard = TextBoard;
