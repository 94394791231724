import PubSub from "pubsub-js";
import EventService from "@viz-ui/services/eventService/eventService";

angular.module("acl.common.event").service("EventService", function($q, $timeout, AppConfig) {
  var listeners = [];
  var subscribers = [];
  const flipMigrateEventService = AppConfig.features.migrateEventService;

  if (!flipMigrateEventService) {
    return {
      register: function(subscriber, scope) {
        var _subscriber = register(subscriber, scope);
        subscribers.push(subscriber);
        return _subscriber;
      },
      registerListener: function(listener) {
        listeners.push(listener);
      },
    };
  } else {
    return EventService;
  }

  function register(registrant, registrantScope) {
    var subscriber = registrant,
      scope = registrantScope,
      subTokens = [];

    var instance = {
      subscribe: function(event, cb) {
        var token = PubSub.subscribe(event, function(event, data) {
          var args = [event].concat(data);
          invokeListeners.apply(null, ["onReceive", subscriber].concat(args));

          if (scope) {
            scope.$apply(function() {
              cb.apply(null, args);
            });
          } else {
            cb.apply(null, args);
          }
        });
        subTokens.push(token);
        return function() {
          PubSub.unsubscribe(token);
        };
      },

      publish: function(event) {
        //FIXME: Have formal message instead of arguments
        var args = Array.prototype.slice.call(arguments);
        invokeListeners.apply(null, ["onPublish", subscriber].concat(args));
        var data = args.slice(1);

        PubSub.publish(event, data);
      },

      //TODO: this uses a promise to correlate the message response. Not a real message request-reply.
      //Message metadata should be part of message envelope.
      request: function(event, timeout) {
        var args = Array.prototype.slice.call(arguments, 2);
        invokeListeners.apply(null, ["onRequest", subscriber].concat(arguments));
        var deferred = $q.defer();
        var promise = deferred.promise;

        if (timeout !== undefined && timeout !== null && !isNaN(timeout) && timeout >= 0) {
          var timeoutPromise = $timeout(function() {
            invokeListeners.apply(null, ["onRequestTimeout", subscriber].concat([event, timeout]));
            deferred.reject("timeout");
          }, timeout);

          var cancelTimeout = function() {
            $timeout.cancel(timeoutPromise);
          };
          promise.then(cancelTimeout, cancelTimeout);
        }

        var applyArgs = [event, deferred].concat(args);
        instance.publish.apply(null, applyArgs);
        return promise;
      },

      unregister: function() {
        subTokens.forEach(function(token) {
          PubSub.unsubscribe(token);
        });
        subTokens = [];
      },
    };
    return instance;
  }

  function invokeListeners(method, subscriber, event, data) {
    var args = Array.prototype.slice.call(arguments, 3);
    listeners
      .filter(function(listener) {
        return !!listener[method];
      })
      .forEach(function(listener) {
        listener[method](subscriber, event, args);
      });
  }
});
