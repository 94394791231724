import omit from "lodash/omit";
import moment from "moment";
import ConditionalFormatting from "@viz-ui/services/quickMenu/conditionalFormat/conditionalFormattingService";
import ConditionValidator from "@viz-ui/services/quickMenu/conditionalFormat/conditionValidator";

angular
  .module("acl.visualizer.conditionalFormatting")
  .controller("ConditionalFormattingController", function(
    $scope,
    $modalInstance,
    DataModel,
    EventService,
    fieldFormatIdentifier,
    fieldName
  ) {
    const eventService = EventService.register("ConditionalFormattingController", $scope);
    const conditionalFormatting = this;

    conditionalFormatting.iconOptions = getIconOptions();

    conditionalFormatting.field = angular.extend(DataModel.table.field(fieldName), {
      fieldName: fieldName,
    });

    conditionalFormatting.criteriaFilterProps = {
      fieldFormatIdentifier,
      fieldName,

      operator: conditionObj => conditionObj.operator,
      value1: conditionObj => conditionObj.values && conditionObj.values[0],
      value2: conditionObj => conditionObj.values && conditionObj.values[1],

      operatorChanged: (conditionObj, operator) => {
        conditionObj.operator = operator;
      },
      value1Changed: (conditionObj, value) => {
        conditionObj.values[0] = value;
      },
      value2Changed: (conditionObj, value) => {
        conditionObj.values[1] = value;
      },
    };

    $scope.$on("$destroy", () => {
      eventService.unregister();
    });

    function init() {
      const conditions = DataModel.tableConfig.formattingConditions(fieldName) || [];
      conditionalFormatting.viewModel = toViewModel({ conditions: conditions });
    }

    conditionalFormatting.addCondition = () => {
      conditionalFormatting.viewModel.conditions.push({
        operator: null,
        values: [],
        icon: "",
        iconEnabled: true,
        textColorEnabled: true,
        backgroundColorEnabled: true,
      });
    };

    conditionalFormatting.removeCondition = conditionObj => {
      let { conditions } = conditionalFormatting.viewModel;
      conditions.splice(conditions.indexOf(conditionObj), 1);
    };

    conditionalFormatting.submit = () => {
      let dataModel;
      if (areConditionsValid()) {
        dataModel = toDataModel(conditionalFormatting.viewModel);
        DataModel.tableConfig.formattingConditions(fieldName, dataModel.conditions);

        ConditionalFormatting.updateFormattingConditions(
          DataModel.table.fields(),
          DataModel.tableConfig.formattingOptions()
        );

        DataModel.interpretation.currentTabIndex() === 0
          ? eventService.publish("dataTable.configChange", null, "conditionalFormatting")
          : eventService.publish("conditionalFormatting.save.toast");
        $modalInstance.close();
      }
    };

    function toViewModel(dataModel) {
      const viewModel = { ...dataModel };
      viewModel.conditions.forEach(conditionObj => {
        // FIXME: Move this defensive versioning code into DataModelFormatService.
        if (angular.isUndefined(conditionObj.iconEnabled)) conditionObj.iconEnabled = false;
        if (angular.isUndefined(conditionObj.textColorEnabled)) conditionObj.textColorEnabled = true;
        if (angular.isUndefined(conditionObj.backgroundColorEnabled)) conditionObj.backgroundColorEnabled = true;
      });
      return viewModel;
    }

    function toDataModel(viewModel) {
      const dataModel = { ...viewModel };
      dataModel.conditions = dataModel.conditions.map(conditionObj => {
        conditionObj.iconEnabled = !!conditionObj.icon;
        (conditionObj.values || []).forEach((value, i) => {
          conditionObj.values[i] = toApiValue(value, conditionalFormatting.field.type);
        });
        return omit(conditionObj, "form");
      });
      return dataModel;
    }

    function toApiValue(value, type) {
      switch (type) {
        case "date":
          return moment(value).format("YYYY-MM-DD");
        case "datetime":
          return moment(value).format("YYYY-MM-DD HH:mm:ss");
        case "time":
          return value;
        case "numeric":
          return value;
        default:
          return value.trim();
      }
    }

    function areConditionsValid() {
      return conditionalFormatting.viewModel.conditions.every(
        conditionObj =>
          conditionalFormatting.field &&
          conditionalFormatting.field.type &&
          ConditionValidator.isValid(conditionObj, conditionalFormatting.field.type)
      );
    }

    conditionalFormatting.close = () => {
      $modalInstance.dismiss();
    };

    conditionalFormatting.getFormattingOptions = () =>
      omit(DataModel.tableConfig.formattingOptions(fieldName), "precision");

    init();

    function getIconOptions() {
      const emptyOption = { "": "--" };
      const iconClasses = angular.extend({}, emptyOption, ConditionalFormatting.getIconClasses());
      return Object.keys(iconClasses).map(key => ({
        key: key,
        value: iconClasses[key],
      }));
    }
  });
