import GlobalFiltersStore from "@storyboards/modules/globalFilters/services/GlobalFiltersStore";
import appConfig from "@storyboards/appConfig";
import QueryClient from "../queryClient/queryClient";
import QueryGenerator from "../queryGenerator/queryGenerator";
import QueryScope from "../queryScope/queryScope";
import TableCacheKeyHelper from "./tableCacheKeyHelper";
import TableDataAdapter from "../queryAdapters/tableDataAdapter";

import FilterTranslator from "../filterTranslator/filterTranslator";
import DataFilter from "../../charts/dataFilter/dataFilterService";

export default class TableValuesLoader {
  static loadValues(tableFieldsToLoad, searchString, count, useDynamicFilter, currentFilterId) {
    const queryScope = QueryScope.getScopeForGlobalFilter();
    return Promise.all(
      tableFieldsToLoad.map(async tableFieldToLoad => {
        //Getting all the related filters associated with this table
        const tableFieldObjToLoad = getTableFieldObjToLoad(tableFieldToLoad, searchString, count);
        const relatedFilters = getRelatedFilters(tableFieldObjToLoad.tableId, useDynamicFilter, currentFilterId);
        let queryObj;
        if (relatedFilters.length > 0) {
          queryObj = QueryGenerator.tableUniqueValues(tableFieldObjToLoad, queryScope, relatedFilters);
        } else {
          queryObj = QueryGenerator.tableUniqueValues(tableFieldObjToLoad, queryScope);
        }
        let formattedTableValues;
        try {
          const response = await QueryClient.query(queryObj);
          formattedTableValues = getFormattedValues(tableFieldToLoad, searchString, response.data);
        } catch (error) {
          formattedTableValues = { values: [] };
        }
        return Promise.resolve(formattedTableValues);
      })
    ).then(tableValues =>
      tableFieldsToLoad.reduce((resultValuesObj, currTableField, currIndex) => {
        const cacheKey = currTableField.cacheKey;
        resultValuesObj[cacheKey] = tableValues[currIndex];
        return resultValuesObj;
      }, {})
    );
  }
}

//function to get related filters from global filters store
function getRelatedFilters(tableId, useDynamicFilter, currentFilterId) {
  const clearAllFilter = window.clearAllFilter;
  //Dynamic filter logic, to consider other filters for query
  const relatedFilters = [];
  if (useDynamicFilter) {
    //Initializing DataFilter service
    const dataFilter = new DataFilter(appConfig);
    // Checking for filters from results
    if (window.filtersFromResults) {
      const filterFromResult = window.filtersFromResults.find(resultFilter => resultFilter.tableId === tableId);
      if (filterFromResult) {
        const { filterConfig } = filterFromResult;
        const filterObj = dataFilter.getFilterRequestBody(filterConfig);
        const resultFilters = filterObj.filterList;
        if (resultFilters) {
          const resultFilterTranslator = FilterTranslator.parse(resultFilters);
          relatedFilters.push(resultFilterTranslator);
        }
      }
    }
    //get related filters if clearAllfilter flag is false
    if (!clearAllFilter) {
      // Checking for filters from global filters store for the table
      const uniqueGlobalFilter = GlobalFiltersStore.getUniqueFilters(currentFilterId);
      const transformedFilters = GlobalFiltersStore.forDynamicQuery(uniqueGlobalFilter, tableId);
      if (transformedFilters.length > 0) {
        relatedFilters.push(FilterTranslator.parseGlobalFilters(transformedFilters));
      }
    }
  }
  return relatedFilters;
}

function getTableFieldObjToLoad(tableFieldToLoad, searchString, count) {
  const { cacheKey, timezoneOffset } = tableFieldToLoad;
  const tableFieldObj = TableCacheKeyHelper.serializeTableCacheKey(cacheKey);
  const startIndex = tableFieldToLoad.lastRecordIndex;
  return {
    tableId: tableFieldObj.tableId,
    fieldName: tableFieldObj.fieldName,
    filter: getSearchFilterTree(tableFieldObj.fieldName, searchString, timezoneOffset),
    count,
    offset: startIndex,
  };
}

function getSearchFilterTree(fieldNameToSearch, searchString, timezoneOffset) {
  if (searchString) {
    const filterTree = {};
    if (timezoneOffset) {
      filterTree[fieldNameToSearch] = {
        contains: {
          containsValue: searchString,
          hoursMinutesOffset: timezoneOffset,
        },
      };
    } else {
      filterTree[fieldNameToSearch] = {
        contains: searchString,
      };
    }
    return filterTree;
  }
}

function getFormattedValues(tableFieldToLoad, searchString, responseData) {
  const tableFieldObj = TableCacheKeyHelper.serializeTableCacheKey(tableFieldToLoad.cacheKey);
  return TableDataAdapter.formatTableValues(tableFieldObj.fieldName, responseData);
}
