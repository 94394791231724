import cloneDeep from "lodash/cloneDeep";

class Model {
  static modelType = "Model";

  constructor(data) {
    this.setData(data);
  }

  get modelType() {
    return this.constructor.modelType;
  }

  setData(data) {
    this._data = data ? cloneDeep(data) : {};
  }

  accessor(key, args, type) {
    if (!args.length) {
      return cloneDeep(this._data[key]);
    }

    if (typeof type === "undefined" || type === null) {
      throw new TypeError("Expected type with call to Model accessor.");
    }

    if (!isType(args[0], type)) {
      throw new TypeError(
        `${this.constructor.name}: expected ${key} to be an instance of ${type} but got ${args[0]}`
      );
    }

    this._data[key] = cloneDeep(args[0]);
    return this;
  }

  assign(...sourceObjs) {
    Object.assign(this._data, ...sourceObjs);
    return this;
  }

  isInstanceOf(type) {
    return !!(isModel(type) && isModelType(this, type));
  }

  dump() {
    return `${this.modelType}(${JSON.stringify(this._data)})`;
  }

  _deleteUndefinedProperties(obj) {
    // eslint-disable-next-line no-param-reassign
    Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
    return obj;
  }
}

function isType(value, type) {
  // Always allow a value of undefined.
  if (typeof value === "undefined") {
    return true;
  }

  // Always allow a value of null.
  if (value === null) {
    return true;
  }

  switch (type) {
    case "*":
      return true;

    case "boolean":
      return typeof value === "boolean";

    case "number":
      return typeof value === "number";

    case "string":
      return typeof value === "string";

    case "color":
      return typeof value === "string" && value.match(/^#[0-9a-fA-F]{3}([0-9a-fA-F]{3})?$/);

    default:
      return (isModel(value) && isModelType(value, type)) || isComplexType(value, type);
  }
}

function isModel(value) {
  return value && (value.modelType === Model.modelType || isModel(Object.getPrototypeOf(value)));
}

function isModelType(value, type) {
  return value && (value.modelType === type.modelType || isModelType(Object.getPrototypeOf(value), type));
}

function isComplexType(value, type) {
  if (value instanceof Array) {
    if (type instanceof Array) {
      return isArrayType(value, type[0]);
    }
    return false;
  }

  if (type instanceof Array) {
    return false;
  }
  return value instanceof type;
}

function isArrayType(values, type) {
  let i;
  for (i = 0; i < values.length; i++) {
    if (!isType(values[i], type)) {
      return false;
    }
  }
  return true;
}

export default Model;
