import backendApi from "@results/services/apiCall/backendApi";
import ApiPath from "@viz-ui/services/apiPath/apiPathService";

class VisualizerAbilities {
  constructor() {
    this.result = null;
    this.promise = null;
  }

  fetchAbilities = () => {
    if (this.result) {
      return Promise.resolve(this.result);
    }

    if (!this.promise) {
      this.promise = backendApi
        .get(ApiPath.abilities.getVisualizerAbilitiesUrl())
        .then(response => {
          if (response) {
            this.result = response.data;
            return response.data;
          }
          throw new Error("Failed to parse visualizer_abilities response");
        })
        .catch(error => {
          throw new Error("Failed to request visualizer abilities", error);
        });
    }

    return this.promise;
  };

  getAbilities = () => this.result;
}

export default new VisualizerAbilities();
