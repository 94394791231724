/*
    Copyright ©2014 ACL Services Ltd.
    Use of this code is governed by the ACL Software License Agreement that applies to the Software of which this code is a part:
    http://www.acl.com/pdfs/ACL_License_Agreement.pdf
*/
import backendApi from "@results/services/apiCall/backendApi";

angular.module("acl.common.queuedHttp", []).factory("queuedHttp", function($rootScope) {
  var queue = [];

  function step() {
    if (queue.length <= 0) return;
    nextTick(function() {
      queue[0]();
    });
  }

  function queuedHttp(config) {
    return new Promise((resolve, reject) => {
      const caller = () => {
        return backendApi
          .http(config)
          .then(response => {
            resolve(response);
            queue.shift();
            step();
          })
          .catch(data => {
            reject(data);
            queue.shift();
            step();
          });
      };

      queue.push(caller);
      if (queue.length === 1) {
        step();
      }
    });
  }

  ["get", "delete", "head", "jsonp"].forEach(function(method) {
    queuedHttp[method] = function(url, config) {
      return queuedHttp(
        angular.extend(config || {}, {
          method: method,
          url: url,
        })
      );
    };
  });

  ["post", "put"].forEach(function(method) {
    queuedHttp[method] = function(url, data, config) {
      return queuedHttp(
        angular.extend(config || {}, {
          method: method,
          url: url,
          body: data,
        })
      );
    };
  });

  function nextTick(callback) {
    if ($rootScope.$$phase) {
      $rootScope.$evalAsync(callback);
    } else {
      $rootScope.$apply(function() {
        $rootScope.$evalAsync(callback);
      });
    }
  }

  return queuedHttp;
});
