export default class AsyncCallManager {
  static CALL_DROPPED = "CALL_DROPPED";

  static resolveOnlyLastCallTo(asyncFunc) {
    let activePromise;
    let activePromiseReject;

    return (...params) => {
      if (activePromise) {
        activePromiseReject(this.CALL_DROPPED);
        activePromise = undefined;
      }

      activePromise = new Promise((resolve, reject) => {
        activePromiseReject = reject;
        asyncFunc(...params)
          .then(resolve)
          .catch(reject);
      });

      return activePromise;
    };
  }

  static queueOverlappingCallsTo(asyncFunc) {
    const queue = [];
    let busy = false;

    const execute = () => {
      const { resolve, reject, params } = queue.shift();

      asyncFunc(...params)
        .then(resolve)
        .catch(reject)
        .finally(() => {
          if (queue.length > 0) {
            busy = true;
            execute();
          } else {
            busy = false;
          }
        });
    };

    return (...params) => {
      const promise = new Promise((resolve, reject) => {
        queue.push({
          resolve: resolve,
          reject: reject,
          params: [...params],
        });
      });

      if (!busy) {
        busy = true;
        execute();
      }

      return promise;
    };
  }

  static mergeOverlappingCallsTo(asyncFunc) {
    const queue = [];
    let busy = false;

    const execute = () => {
      const { resolve, reject, params } = queue.shift();

      asyncFunc(...params)
        .then(data => {
          resolve(data);
          while (queue.length > 0) {
            queue.shift().resolve(data);
          }
        })
        .catch(reason => {
          reject(reason);
          while (queue.length > 0) {
            queue.shift().reject(reason);
          }
        })
        .finally(() => {
          busy = false;
        });
    };

    return (...params) => {
      const promise = new Promise((resolve, reject) => {
        queue.push({
          resolve: resolve,
          reject: reject,
          params: [...params],
        });
      });

      if (!busy) {
        busy = true;
        execute();
      }

      return promise;
    };
  }
}
