import Model from "../model";
import Metric from "./metric";

class MetricSummary extends Model {
  static modelType = "MetricSummary";

  metric(...args) {
    return this.accessor("metric", args, Metric);
  }

  count(...args) {
    return this.accessor("count", args, "number");
  }

  uniques(...args) {
    return this.accessor("uniques", args, "number");
  }

  positives(...args) {
    return this.accessor("positives", args, "number");
  }

  negatives(...args) {
    return this.accessor("negatives", args, "number");
  }

  zeros(...args) {
    return this.accessor("zeros", args, "number");
  }

  blanks(...args) {
    return this.accessor("blanks", args, "number");
  }

  total(...args) {
    return this.accessor("total", args, "number");
  }

  average(...args) {
    return this.accessor("average", args, "*");
  }

  lowest(...args) {
    return this.accessor("lowest", args, "*");
  }

  highest(...args) {
    return this.accessor("highest", args, "*");
  }

  standardDeviation(...args) {
    return this.accessor("standardDeviation", args, "number");
  }

  date(...args) {
    // FIXME: We should use Date here.
    return this.accessor("date", args, "string");
  }

  static fromJson(metricModel, json) {
    return new MetricSummary()
      .metric(metricModel)
      .count(json.count)
      .positives(json.positives)
      .negatives(json.negatives)
      .zeros(json.zeros)
      .blanks(json.blanks)
      .total(json.total)
      .average(json.average)
      .lowest(json.lowest)
      .highest(json.highest)
      .standardDeviation(json.standard_deviation)
      .date(json.date)
      .uniques(json.uniques);
  }

  clone() {
    return new MetricSummary(this._data);
  }
}

export default MetricSummary;
