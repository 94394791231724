import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import i18n from "@viz-ui/i18n/i18n";
import Heading from "@paprika/heading";
import ListBox from "@paprika/list-box";
import ValuesSortable from "./valuesSortable";
import "./sortConfig.scss";

const { arrayOf, func, instanceOf, number, oneOfType, string } = PropTypes;

const sortConfig = props => {
  const { dataType, fieldFormatIdentifier, fieldName, values, valuesOrder, sortOrder, onChange, chartType } = props;
  const sortOrderOptions = (() => {
    switch (chartType) {
      case "LineChart":
        return ["default", "_divider_", "custom"];
      default:
        return ["default", "asc", "desc", "_divider_", "custom"];
    }
  })();
  const getListBoxItems = order =>
    sortOrderOptions.map(option => {
      const isSelected = option === order;
      return { option, isSelected };
    });
  const [listBoxItems, setListBoxItems] = useState(getListBoxItems(sortOrder));

  useEffect(() => setListBoxItems(getListBoxItems(sortOrder)), [sortOrder]);

  const handleSortOrderChange = (index, options) => {
    const selectedOrder = options[index] ? options[index].value : "default";
    onChange({ sortOrder: selectedOrder });
  };

  const handleValuesOrderChange = newValuesOrder => {
    const newSortOrder = "custom";
    if (sortOrder !== "custom") {
      setListBoxItems(getListBoxItems(newSortOrder));
    }
    onChange({ sortOrder: newSortOrder, valuesOrder: newValuesOrder });
  };

  const getListBoxItem = item => {
    const { option, isSelected } = item;
    if (item.option === "_divider_") return <ListBox.Divider key="divider" />;

    return (
      <ListBox.Option key={option} value={option} isSelected={isSelected}>
        {i18n.t(`_SortConfig.Sorting.options.${option}_`)}
      </ListBox.Option>
    );
  };

  return (
    <div className="sort-config__container">
      <Heading level={5}>{i18n.t("_SortConfig.Sorting.Label_")}</Heading>
      <ListBox onChange={handleSortOrderChange}>
        <ListBox.Popover zIndex={10000} />
        <ListBox.Trigger hasClearButton={false} />
        {listBoxItems.map(getListBoxItem)}
      </ListBox>
      <div className="sort-config__rule" />
      <ValuesSortable
        fieldFormatIdentifier={fieldFormatIdentifier}
        fieldName={fieldName}
        dataType={dataType}
        sortOrder={sortOrder}
        values={values}
        valuesOrder={valuesOrder}
        onChange={handleValuesOrderChange}
      />
    </div>
  );
};

sortConfig.propTypes = {
  dataType: string,
  fieldFormatIdentifier: string,
  fieldName: string,
  onChange: func,
  sortOrder: string,
  values: arrayOf(oneOfType([number, string, instanceOf(Date)])),
  valuesOrder: arrayOf(oneOfType([number, string, instanceOf(Date)])),
  chartType: string,
};

sortConfig.defaultProps = {
  dataType: "character",
  fieldFormatIdentifier: "",
  fieldName: "",
  onChange: () => {},
  sortOrder: "default",
  values: [],
  valuesOrder: [],
  chartType: "",
};

export default sortConfig;
