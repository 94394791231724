angular
  .module("acl.visualizer.charts.backend")
  .service("StackedAreaChartBackendService", function(ChartBackendService, Sorter) {
    return {
      fetchJson: ChartBackendService.fetchJson,

      getRepresentation: function(resultData, config) {
        var data = [];

        var multipleSeries = config.chartSeries && config.chartSeries.fieldName;

        const seriesType = multipleSeries ? resultData.config.series.data_type : resultData.config.key.data_type;
        const xAxisType = resultData.config.key.data_type;
        const valueType = resultData.config.values.data_type;

        var seriesParser = ChartBackendService.getParserForType(seriesType);
        var xAxisParser = ChartBackendService.getParserForType(xAxisType);
        var valueParser = ChartBackendService.getParserForType(valueType);

        var uniqueXValues = [];
        data = ChartBackendService.nest()
          .key(function(d) {
            return multipleSeries ? seriesParser(d.series) : "";
          })
          .rollup(function(d) {
            for (var i = 0, l = d.length; i < l; i++) {
              var parsedSubCat = xAxisParser(d[i].key);
              if (uniqueXValues.indexOf(parsedSubCat) === -1) {
                uniqueXValues.push(parsedSubCat);
              }
              d[i] = { x: parsedSubCat, y: valueParser(d[i].values[0]) };
            }
            return Sorter.sort(d, {
              dataType: xAxisType,
              valueParser: item => item.x,
            });
          })
          .excludeNullKeys(true)
          .entries(resultData.values);

        uniqueXValues = Sorter.sort(uniqueXValues, { dataType: xAxisType });
        let xToScalar = xAxisType === "character" ? x => uniqueXValues.indexOf(x) : x => x;

        var insertInterpolation = function(x, data, index) {
          if (data.length === 0) {
            data.push({ x: x, y: null, interpolated: true });
            return;
          } else if (data.length === 1 || index === 0 || index === data.length) {
            if (index === 0) {
              data.splice(0, 0, { x: x, y: data[0].y, interpolated: true });
            } else {
              data.push({ x: x, y: data[index - 1].y, interpolated: true });
            }
          } else {
            var y =
              data[index - 1].y + //previous y
              (data[index].y - data[index - 1].y) * //total change in y
                ((xToScalar(x) - xToScalar(data[index - 1].x)) /
                  (xToScalar(data[index].x) - xToScalar(data[index - 1].x))); //percent change of x
            data.splice(index, 0, { x: x, y: y, interpolated: true });
          }
        };

        for (var x = 0; x < uniqueXValues.length; x++) {
          for (var cat = 0; cat < data.length; cat++) {
            for (var v = 0; v < data[cat].values.length; v++) {
              if (data[cat].values[v].x === uniqueXValues[x]) {
                break;
              }
              if (xToScalar(data[cat].values[v].x) > xToScalar(uniqueXValues[x])) {
                insertInterpolation(uniqueXValues[x], data[cat].values, v);
                break;
              }
            }
            if (v === data[cat].values.length) {
              insertInterpolation(uniqueXValues[x], data[cat].values, v);
            }
          }
        }

        // Just want to sort the series if it exists.
        data = Sorter.sort(data, {
          dataType: seriesType,
          valueParser: item => item.key,
        });

        return data;
      },
    };
  });
