import ApiCall from "./apiCall";

/*
 * Create a singleton instance of the ApiCall class, using the current window's URL
 * as the basis of all other URLs. Any code that imports this module will use the
 * same ApiCall instance, refered to as "backendApi".
 */
const backendApi = new ApiCall(window.location.href, false);
export default backendApi;

/*
 * This is required so that we can boot-strap Visualizer/Storyboards in the first place,
 * which involves calling ApiCall methods from separate JavaScript files. If we don't
 * store this in window.picassoApi, it'll be minified away.
 *
 * Note: we use a different name here (picassoApi instead of backendApi) so that we're
 * not accidentally picking up the wrong variable. By importing the backendApi from this
 * module, we'll get better type-checking than just using the picassoApi global variable.
 */
(window as any).picassoApi = backendApi;
