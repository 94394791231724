import GlobalFieldFormatMap from "../../../services/formatters/globalFieldFormatMap";

class FieldFormatAttributes {
  constructor(fieldFormatIdentifier, fieldName) {
    this.updateIdentifiers(fieldFormatIdentifier, fieldName);
  }

  updateIdentifiers = (fieldFormatIdentifier, fieldName) => {
    this.fieldFormatIdentifier = fieldFormatIdentifier;
    this.fieldName = fieldName;

    this._setFormatProperties();
    this._subscribeToFormatObserver();
  };

  subscribeToFieldFormatChange = formatUpdateCallback => {
    this.formatUpdateCallback = formatUpdateCallback;
  };

  _setFormatProperties = () => {
    this.fieldFormat = GlobalFieldFormatMap.getFieldFormat(this.fieldFormatIdentifier, this.fieldName);
    this.fieldType = GlobalFieldFormatMap.getFieldType(this.fieldFormatIdentifier, this.fieldName);
  };

  _subscribeToFormatObserver = () => {
    if (this._unsubObserver) {
      this._unsubObserver();
    }

    this._unsubObserver = GlobalFieldFormatMap.subscribeToFieldObserver(
      this.fieldFormatIdentifier,
      this.fieldName,
      this._handleFormatUpdate
    );
  };

  _handleFormatUpdate = () => {
    this._setFormatProperties();
    if (typeof this.formatUpdateCallback === "function") {
      this.formatUpdateCallback();
    }
  };
}

export default FieldFormatAttributes;
