import Model from "../model";

class FilterValue extends Model {
  static modelType = "FilterValue";

  value(...args) {
    return this.accessor("value", args, "string");
  }

  count(...args) {
    return this.accessor("count", args, "number");
  }

  isBlank() {
    return this._data.value === "";
  }
}

export default FilterValue;
