import groupBy from "lodash/groupBy";
import values from "lodash/values";
import flatten from "lodash/flatten";
import sortBy from "lodash/sortBy";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import logger from "@viz-ui/services/logger/logger";
import RowConfigManager from "../rowConfig/rowConfig.service";

export default class BoardOrganizer {
  static _boards = [];

  static _MAX_ROW_SIZE = 3;

  static initBoards({ boards, positions }) {
    const positionsByRows = values(groupBy(positions, "row"));
    this._boards = positionsByRows.map(positionRow =>
      sortBy(positionRow, "col")
        .map(position => boards.find(board => board.id === position.id))
        .filter(board => board !== undefined)
        .map(fillTableIdForBoard)
        .slice(0, this._MAX_ROW_SIZE)
    );
    this._logging(boards);
  }

  static addBoard(board, position) {
    const { row, col } = position;
    if (!board.id) {
      board.id = this._generateBoardId();
    }
    if (this._boards.length >= row && !this.isRowFull(row)) {
      if (this.isRowEmpty(row)) {
        RowConfigManager.addRowConfig(row);
      }
      this._boards[row].splice(col, 0, board);
    }
  }

  static addRow(row) {
    this._boards.splice(row, 0, []);
  }

  static removeBoard(boardId) {
    this._removeBoard(boardId);
    this._removeEmptyRow();
  }

  static moveBoard(boardId, newPosition) {
    const currentPosition = this._findPosition(boardId);
    if (currentPosition && newPosition && !isEqual(currentPosition, newPosition)) {
      const isMovingWithinRow = currentPosition.row === newPosition.row;
      if (!isMovingWithinRow && this.isRowFull(newPosition.row)) return;

      const boardToMove = this._removeBoard(boardId);
      this.addBoard(boardToMove, newPosition);
      this._removeEmptyRow();
    }
  }

  static updateTextBoard(boardId, title = "", text = "") {
    const board = this._findBoard(boardId);
    board.title = title;
    board.text = text;
  }

  static getBoardsByRow(rows = []) {
    let boards = [];
    rows.forEach(rowIndex => {
      if (this._boards[rowIndex]) {
        boards = boards.concat(this._boards[rowIndex]);
      }
    });
    return boards;
  }

  static getMaxRowSize() {
    return this._MAX_ROW_SIZE;
  }

  static numBoardsInRow(row) {
    return this._boards[row].length;
  }

  static isRowFull(row) {
    if (row < 0 || row >= this._boards.length) throw "row index is out of bound";
    return this._boards[row].length >= this.getMaxRowSize();
  }

  static isRowEmpty(row) {
    return this._boards[row].length === 0;
  }

  static getBoards() {
    return flatten(this._boards);
  }

  static getBoardRows() {
    return this._boards.map(boardRow => {
      return {
        boards: boardRow,
        id: boardRow.map(board => board.id).join("-"),
      };
    });
  }

  static toJsonConfig() {
    const positions = [];
    this._boards.forEach((boardRow, rowIndex) => {
      boardRow.forEach((board, colIndex) => {
        positions.push({ id: board.id, row: rowIndex, col: colIndex });
      });
    });
    return {
      panels: this.getBoards(),
      positions,
    };
  }

  static _generateBoardId() {
    const boards = flatten(this._boards);
    let newBoardId;
    do {
      newBoardId = Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    } while (find(boards, board => board.id === newBoardId));
    return newBoardId;
  }

  static _removeBoard(boardId) {
    const boardPosition = this._findPosition(boardId);
    if (boardPosition) {
      const { row, col } = boardPosition;
      const removedBoards = this._boards[row].splice(col, 1);
      if (this.isRowEmpty(row)) {
        RowConfigManager.removeRowConfig(row);
      }
      return removedBoards.length ? removedBoards[0] : undefined;
    }
  }

  static _removeEmptyRow() {
    this._boards = this._boards.filter(boardRow => boardRow.length > 0);
  }

  static _findBoard(boardId) {
    return flatten(this._boards).find(board => board.id === boardId);
  }

  static _findPosition(boardId) {
    let position;
    let rowIndex;
    for (rowIndex = 0; rowIndex < this._boards.length; rowIndex++) {
      const boardRow = this._boards[rowIndex];
      const colIndex = boardRow.findIndex(board => board.id === boardId);
      if (colIndex > -1) {
        position = {
          row: rowIndex,
          col: colIndex,
        };
        break;
      }
    }
    return position;
  }

  static _logging(boards = []) {
    logger.log(`Total # of tiles: ${boards.length}`);
    this._boards.forEach((row, index) => {
      if (row) {
        logger.log(`row index ${index}`);
        logger.log(`number of tiles - ${row.length}`);
        row.forEach(board => {
          this._logBoard(board);
        });
      }
    });
  }

  static _logBoard(board) {
    const { id, type, visualization_id: vizId, interpretation_id: interpretationId } = board;
    logger.log(`id: ${id}`);
    logger.log(`type: ${type}`);
    if (vizId) {
      logger.log(`vizId: ${vizId}`);
    }
    if (interpretationId) {
      logger.log(`interpretationId: ${interpretationId}`);
    }
  }
}

function fillTableIdForBoard(board) {
  const { controlTestId, tableId, type } = board;
  if (!tableId && controlTestId && type !== "text") board.tableId = controlTestId;
  return board;
}
