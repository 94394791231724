import parseColor from "parse-color";

export default class ChartColorConfigHelper {
  static getColorStops(colorStops, minDataValue, maxDataValue) {
    const values = colorStops.map(stop => stop.value);
    return values.map((stop, i) => {
      values[i] = getStopValue(i, values, minDataValue, maxDataValue);
      return {
        value: values[i],
        color: colorStops[i].color,
      };
    });
  }

  static getColorBins(colorStops, numBins) {
    const min = colorStops[0].value;
    const max = colorStops[colorStops.length - 1].value;
    const bins = [];
    for (let i = 0; i < numBins; i++) {
      const colorValue = min + (i * (max - min)) / (numBins - 1);
      const lowerStop = colorStops.reduce((result, stop) => (stop.value <= colorValue ? stop : result), colorStops[0]);
      const upperStop = colorStops.reduceRight(
        (result, stop) => (stop.value > colorValue ? stop : result),
        colorStops[colorStops.length - 1]
      );
      const color =
        upperStop.value === lowerStop.value
          ? lowerStop.color
          : getColorBetween(
              lowerStop.color,
              upperStop.color,
              (colorValue - lowerStop.value) / (upperStop.value - lowerStop.value)
            );
      bins.push({
        ...(i === 0 ? undefined : { from: min + ((max - min) / numBins) * i }),
        ...(i === numBins - 1 ? undefined : { to: min + ((max - min) / numBins) * (i + 1) }),
        color: color,
      });
    }
    return bins;
  }
}

function getColorBetween(colorA, colorB, fraction) {
  const rgbA = parseColor(colorA).rgb;
  const rgbB = parseColor(colorB).rgb;
  const r = rgbA[0] + Math.round(fraction * (rgbB[0] - rgbA[0]));
  const g = rgbA[1] + Math.round(fraction * (rgbB[1] - rgbA[1]));
  const b = rgbA[2] + Math.round(fraction * (rgbB[2] - rgbA[2]));
  return parseColor(`rgb(${r}, ${g}, ${b})`).hex.toUpperCase();
}

function getStopValue(i, values, minValue, maxValue) {
  if (isFinite(values[i])) return values[i];
  if (i === 0) {
    const nextValue = values.slice(i + 1).find(v => isFinite(v));
    return !isFinite(nextValue) || minValue < nextValue ? minValue : nextValue - values.indexOf(nextValue);
  }
  if (values.length === 5 && i === 2) {
    const prevValue = values[i - 2];
    const nextValue = getStopValue(
      i + 2,
      [...values.slice(0, i), prevValue, ...values.slice(i + 1)],
      minValue,
      maxValue
    );
    return prevValue + (nextValue - prevValue) / 2;
  }
  if (i === values.length - 1) {
    const iIndex = values.length === 5 ? values.length - 1 : 1;
    const prevValue = values[i - iIndex];
    if (values.length === 5 && maxValue < prevValue) {
      const maxVal = Math.max.apply(
        null,
        values.filter(x => !isNaN(x))
      );
      return maxValue > prevValue ? maxValue : maxVal + (i - values.indexOf(maxVal));
    }
    return maxValue > prevValue ? maxValue : prevValue + (i - values.indexOf(prevValue));
  }
  const prevValue = values[i - 1];
  const nextValue = getStopValue(i + 1, [...values.slice(0, i), prevValue, ...values.slice(i + 1)], minValue, maxValue);
  return prevValue + (nextValue - prevValue) / 2;
}
