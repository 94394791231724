angular
  .module("acl.visualizer.mapChart")
  .controller("MapChartController", ($scope, ChartService, InheritController, MapChart) => {
    let base = InheritController("ChartBaseController", $scope)
      .override("chartType", "MapChart")
      .override("isDataConfigParsed", false)
      .override("defaultDisplayConfig", MapChart.defaultDisplayConfig)
      .override("getChartDirectiveConfig", MapChart.getChartDirectiveConfig)
      .override("populateChartConfigColumnDefs", MapChart.populateChartConfigColumnDefs)
      .override("chartConfigColumnDefs", MapChart.chartConfigColumnDefs)
      .override("isValidDataConfig", MapChart.isValidDataConfig)
      .override("tooltipPointFormatter", MapChart.tooltipPointFormatter)
      .override("mapTypes", MapChart.mapTypes)
      .override("valueFormatter", MapChart.valueFormatter)
      .override("dataLabelFormatter", MapChart.dataLabelFormatter)
      .override("isAdminMapType", MapChart.isAdminMapType)
      .override("isRegionDrillEnabled", MapChart.isRegionDrillEnabled)
      .override("isCoordinateDrillEnabled", MapChart.isCoordinateDrillEnabled)
      .override("getMapTypeOnCoordinates", MapChart.getMapTypeOnCoordinates);
    base.initChart();
  });
