import cloneDeep from "lodash/cloneDeep";
import FieldTypeChecker from "@visualizer/modules/core/fieldTypeChecker/fieldTypeChecker.service";
import DataModel from "@viz-ui/services/charts/DataModel/dataModelService";

angular
  .module("acl.visualizer.dataModel")
  .factory("DataModel", function(DataModelFormat, EventService, FieldFormat, NamespacedObserver, AppConfig) {
    "use strict";
    const eventService = EventService.register("dataModel.DataModel");

    const currentFormatVersion = "1.17";

    const namespacedObserver = NamespacedObserver.getInstance();

    const model = {
      saveViz: {
        formatVersion: currentFormatVersion,
        visualizationInfo: {
          title: "",
          //tableName: undefined,
          //tableId: undefined,
          //analyticName: undefined,
          summary: "",

          currentTabIndex: 0,
          linkLatestTable: false,
        },
        filterConfig: {
          filtersOpen: false,
          params: { start: 1 },
          myRecords: false,
          openStatuses: false,
          // Current code expects sortField to not exist when no column is sorted. Here it is in comment form:
          // sortField: {
          //     field: '', // field name
          //     order: '' // 'asc' or 'desc'
          // }
        },
        visualizations: [],
        visualizationConfig: {
          colorMapping: {},
        },
        tableConfig: {
          columns: [],
          formatting: {
            // fieldName: {
            //     dateFormat: 'YYYY-MM-DD',
            //     isHtml: false
            // },
            // otherFieldName: {
            //     datetimeFormat: 'YYYY-MM-DD h:mm:ss A',
            // },
            // yetAnotherFieldName: {
            //     prefix: '$',
            //     precision: 2,
            //     postfix: '%',
            //     sign: '('
            // }
          },
        },
      },

      table: {
        metaDataLoaded: false,
        // FIXME: Collapse metadata property.
        metaData: {
          name: "",
          recordCount: 0,
          fields: {},
          hasStaticField: false,
          certificationsTable: false,
        },
        filteredRecordCount: 0,
        data: [],
        sortFields: [],
      },

      project: {
        archived: false,
      },
      hide_metadata: false,
    };

    var tableIdForWatch = undefined;

    function changeSaveVizModel(targetKeys, value) {
      let targetRef = model.saveViz;
      targetKeys.forEach((key, index) => {
        if (index === targetKeys.length - 1 && !angular.equals(targetRef[key], value)) {
          targetRef[key] = value;
          if (targetKeys.join(".") !== "filterConfig.filtersOpen") {
            eventService.publish("saveViz.modelChange");
          }
        } else {
          targetRef = targetRef[key];
        }
      });
    }

    function addVisualizationToModel(viz) {
      let visualizations = angular.copy(model.saveViz.visualizations);
      if (!visualizations) {
        visualizations = [];
      }
      viz = angular.copy(viz);
      if (viz.title === undefined) viz.title = "";
      if (viz.id === undefined) viz.id = DataModelFormat.generateVisualizationId();
      if (!angular.isObject(viz.config)) viz.config = {};
      if (!angular.isObject(viz.config.dataConfig)) viz.config.dataConfig = {};
      if (!angular.isObject(viz.config.displayConfig)) viz.config.displayConfig = {};
      visualizations.push(viz);
      changeSaveVizModel(["visualizations"], visualizations);
      return viz.id;
    }

    function isDataTableTab(vizId) {
      return vizId === null;
    }

    function isDataTableTabIndex(tabIndex) {
      return tabIndex === 0;
    }

    const dataModel = {
      formatVersion: () => model.saveViz.formatVersion,

      subscribe: (propertyName, callback) => namespacedObserver.subscribe(propertyName, callback),

      getFilterConfigDeprecated: function() {
        // @TODO Need to remove all use of this function to use
        // `getFilterConfig`
        removeInvalidSortField(model.saveViz);
        return model.saveViz.filterConfig;
      },

      getFilterConfig: () => {
        removeInvalidSortField(model.saveViz);
        return cloneDeep(model.saveViz.filterConfig);
      },

      //TODO: can dependencies be further namespaced for separate parts of .table?
      // FIXME: Get rid of this and the need for it.
      getTableObj: function() {
        return model.table;
      },

      // FIXME: Get rid of this and the need for it.
      getTableConfigObj: function() {
        return model.saveViz.tableConfig;
      },

      // FIXME: Get rid of this and the need for it.
      setTableConfigObj: function(x) {
        model.saveViz.tableConfig = angular.copy(x);
        return model.saveViz.tableConfig;
      },

      fromSaveViz: function(saveViz) {
        var saveViz = deFormatDataConfig(saveViz);
        if (saveViz.formatVersion !== currentFormatVersion) {
          model.saveViz = DataModelFormat.convertSaveViz(saveViz, saveViz.formatVersion, currentFormatVersion);
        } else {
          model.saveViz = angular.copy(saveViz);
        }
        publishAllPropertiesChanged();
        return dataModel;
      },

      toSaveViz: function() {
        var result = angular.copy(model.saveViz);
        result = removeInvalidSortField(result);
        result = cleanUpFilterList(result);
        result = removeLazyloadParams(result);
        return result;
      },

      toBiView: function(type, config) {
        var result = angular.copy(model.saveViz);
        result.visualizations = [
          {
            type: type,
            title: "",
            config: { dataConfig: config },
          },
        ];
        return result;
      },

      filterErrors: function() {
        return [];
      },

      filtersValid: function() {
        return this.filterErrors().length === 0;
      },

      addVisualization: function(viz) {
        const vizId = addVisualizationToModel(viz);
        publishPropertyChanged("visualizations");
        return vizId;
      },

      removeAllVisualizations: function() {
        changeSaveVizModel(["visualizations"], []);
        publishPropertyChanged("visualizations");
      },

      reorderVisualizations: function(mapping) {
        const visualizations = angular.copy(dataModel.visualizations());
        changeSaveVizModel(["visualizations"], []);

        const sortedViz = [];

        mapping.forEach((newIndex, index) => {
          sortedViz[newIndex] = visualizations[index];
        });

        sortedViz.forEach(viz => {
          addVisualizationToModel(viz);
        });

        publishPropertyChanged("visualizations");
      },

      visualization: function(index, visualizationConfig) {
        if (arguments.length < 2) return angular.copy(model.saveViz.visualizations[index]);
        changeSaveVizModel(["visualizations", index], angular.copy(visualizationConfig));
        publishPropertyChanged("visualizations");
        return dataModel;
      },

      visualizations: function() {
        return angular.copy(model.saveViz.visualizations);
      },

      removeVisualization: function(index) {
        let visualizations = angular.copy(model.saveViz.visualizations);
        visualizations.splice(index, 1);
        changeSaveVizModel(["visualizations"], visualizations);

        publishPropertyChanged("visualizations");
      },

      getTabIndexByVizId: vizId => {
        if (isDataTableTab(vizId)) {
          return 0;
        }

        let visualizations = dataModel.visualizations();
        let vizIdTabIndex = visualizations.findIndex(visualization => visualization.id === vizId);

        if (vizIdTabIndex === -1) {
          return vizIdTabIndex;
        }

        const vizIdTabIndexOffsetByDataTab = vizIdTabIndex + 1;
        return vizIdTabIndexOffsetByDataTab;
      },

      interpretation: {
        currentTabIndex: function(tabIndex) {
          if (!arguments.length) {
            return dataModel.getTabIndexByVizId(model.saveViz.visualizationInfo.selectedVizId);
          }

          if (isDataTableTabIndex(tabIndex)) {
            model.saveViz.visualizationInfo.selectedVizId = null;
          } else {
            const vizIdIndex = tabIndex - 1;
            model.saveViz.visualizationInfo.selectedVizId = model.saveViz.visualizations[vizIdIndex].id;
          }

          publishPropertyChanged("interpretation.currentTabIndex");

          return dataModel.interpretation;
        },

        selectedVisualizationIndex: function(visualizationIndex) {
          if (!arguments.length) {
            return dataModel.interpretation.currentTabIndex() - 1;
          }
          return dataModel.interpretation.currentTabIndex(visualizationIndex + 1);
        },

        grcInfo: function(x) {
          // FIXME: Freeze this before returning.
          if (!arguments.length) return model.saveViz.visualizationInfo.grcInfo;
          if (x === undefined) {
            delete model.saveViz.visualizationInfo.grcInfo;
          } else {
            model.saveViz.visualizationInfo.grcInfo = angular.copy(x);
          }

          publishPropertyChanged("interpretation.grcInfo");

          return dataModel.interpretation;
        },
      },

      tableConfig: {
        columns: function(x) {
          if (!arguments.length) return angular.copy(model.saveViz.tableConfig.columns);
          changeSaveVizModel(["tableConfig", "columns"], angular.copy(x));
          publishPropertyChanged("tableConfig.columns");
          return dataModel.tableConfig;
        },

        getFieldFormatModelsByName: () => {
          let result = new Map();
          let fieldFormatObjsByName = model.saveViz.tableConfig.formatting;
          for (let fieldName in fieldFormatObjsByName) {
            let fieldFormatObj = fieldFormatObjsByName[fieldName];
            result.set(fieldName, FieldFormat.fromJson(fieldFormatObj));
          }
          return result;
        },

        dateFormat: getFieldFormattingAccessor("dateFormat"),
        datetimeFormat: getFieldFormattingAccessor("datetimeFormat"),
        precision: getFieldFormattingAccessor("precision"),
        prefix: getFieldFormattingAccessor("prefix"),
        postfix: getFieldFormattingAccessor("postfix"),
        sign: getFieldFormattingAccessor("sign"),
        isHtml: getFieldFormattingAccessor("isHtml"),
        isRaw: getFieldFormattingAccessor("isRaw"),
        abbreviate: getFieldFormattingAccessor("abbreviate"),
        keepTrailingZeros: getFieldFormattingAccessor("keepTrailingZeros"),
        displayTime: getFieldFormattingAccessor("displayTime"),
        isURL: getFieldFormattingAccessor("isURL"),
        hasOnlyRadixSeparator: getFieldFormattingAccessor("hasOnlyRadixSeparator"),
        thousandsDelimiter: getFieldFormattingAccessor("thousandsDelimiter"),

        formattingConditions: getFieldFormattingAccessor("conditions"),

        formattingOptions: function(fieldName) {
          if (!arguments.length) {
            if (
              typeof model.saveViz.tableConfig !== "object" ||
              typeof model.saveViz.tableConfig.formatting !== "object"
            )
              return undefined;
            return angular.copy(model.saveViz.tableConfig.formatting);
          }
          if (
            typeof model.saveViz.tableConfig !== "object" ||
            typeof model.saveViz.tableConfig.formatting !== "object" ||
            typeof model.saveViz.tableConfig.formatting[fieldName] !== "object"
          )
            return undefined;
          return angular.copy(model.saveViz.tableConfig.formatting[fieldName]);
        },

        removeDeletedFields: function() {
          const tableConfig = model.saveViz.tableConfig;

          if (tableConfig.columns) {
            const validColumns = tableConfig.columns.filter(col => dataModel.table.fieldExists(col.fieldName));
            model.saveViz.tableConfig.columns = validColumns;
          }
        },
      },

      table: {
        id: function(x) {
          var info = model.saveViz.visualizationInfo;
          if (!arguments.length) {
            var id = {};
            if (info.tableId) id.tableId = info.tableId;
            if (info.tableName) id.tableName = info.tableName;
            if (info.analyticName) id.analyticName = info.analyticName;

            if (!angular.equals(tableIdForWatch, id)) {
              tableIdForWatch = id;
              Object.freeze(tableIdForWatch);
            }

            return tableIdForWatch;
          } else {
            info.tableName = x.tableName;
            info.tableId = x.tableId;
            info.analyticName = x.analyticName;

            publishPropertyChanged("table.id");
          }

          return dataModel.table;
        },

        field: function(fieldName, x) {
          if (arguments.length < 2) return angular.copy(model.table.metaData.fields[fieldName]);
          if (x === undefined) {
            delete model.table.metaData.fields[fieldName];
          } else {
            model.table.metaData.fields[fieldName] = angular.copy(x);

            publishPropertyChanged("table.field");
          }
          return dataModel.table;
        },

        fieldForWatch: function(fieldName) {
          return model.table.metaData.fields[fieldName];
        },

        fields: function(fieldsByNameObj) {
          if (arguments.length < 1) return angular.copy(model.table.metaData.fields);
          model.table.metaData.fields = angular.copy(fieldsByNameObj);
          publishPropertyChanged("table.fields");
          return dataModel.table;
        },

        fieldsForWatch: function() {
          return model.table.metaData.fields;
        },

        filteredRecordCount: function(x) {
          if (!arguments.length) return parseInt(model.table.filteredRecordCount, 10);
          model.table.filteredRecordCount = String(x);
          publishPropertyChanged("table.filteredRecordCount");
          return dataModel.table;
        },

        fieldExists: fieldName => Object.prototype.hasOwnProperty.call(model.table.metaData.fields, fieldName),

        fieldsExist: fieldNamesArray => fieldNamesArray.every(dataModel.table.fieldExists),

        isMetadataField: function(fieldName) {
          const metadataFields = [
            "metadata.closed_at",
            "metadata.updated_at",
            "metadata.group",
            "metadata.assignee",
            "metadata.publisher",
            "metadata.publish_date",
            "metadata.status",
            "metadata.priority",
          ];
          return metadataFields.includes(fieldName);
        },

        reset: () => {
          const table = model.table;

          table.metaData.name = "";
          table.metaData.recordCount = 0;
          table.sortFields = [];
          table.metaData.hasStaticField = false;
          table.metaDataLoaded = false;
          table.data = [];
          table.filteredRecordCount = "0";
          table.metaData.certificationsTable = false;
        },

        updateRecordBy: (recordIdKey, recordId, newFieldData) => {
          let data = dataModel.table.data();
          const recordIndex = data.findIndex(record => recordId === record[recordIdKey]);

          if (recordIndex !== -1) {
            data = angular.copy(data);
            data[recordIndex] = { ...data[recordIndex], ...newFieldData };
            dataModel.table.data(data);
          }

          return dataModel.table.data();
        },
      },

      filterConfig: {
        disableInvalidFilterFields: () => {
          const filterConfig = model.saveViz.filterConfig;

          if (filterConfig.filterList) {
            filterConfig.filterList.forEach(filter => {
              const isDeletedField = !dataModel.table.fieldExists(filter.name);
              const isFieldTypeChanged = dataModel.filterConfig.filterTypeMismatch(filter);
              if (isDeletedField || isFieldTypeChanged) {
                filter.active = false;
              }
            });
          }
        },

        hasDeletedField: () => {
          const filterConfig = model.saveViz.filterConfig;
          if (filterConfig.filterList) {
            return filterConfig.filterList.some(filter => !dataModel.table.fieldExists(filter.name));
          }
          return false;
        },

        hasFieldTypeMismatch: () => {
          const filterConfig = model.saveViz.filterConfig;
          if (filterConfig.filterList) {
            return filterConfig.filterList.some(dataModel.filterConfig.filterTypeMismatch);
          }
          return false;
        },

        filterTypeMismatch: filter => {
          const fields = model.table.metaData.fields;
          const fieldTypeChecker = FieldTypeChecker.create().setFieldsFromMap(fields);
          return fieldTypeChecker.filterTypeMismatch(filter);
        },

        removeDeletedSortField: () => {
          const filterConfig = model.saveViz.filterConfig;

          if (filterConfig.sortField && !dataModel.table.fieldExists(filterConfig.sortField.field)) {
            filterConfig.sortField = undefined;
          }
        },

        removeFilter: fieldName => {
          let filterList = angular.copy(model.saveViz.filterConfig.filterList);

          filterList = filterList.filter(filter => filter.name !== fieldName);
          changeSaveVizModel(["filterConfig", "filterList"], filterList);
          publishPropertyChanged("filterConfig.filterList");
        },

        toggleMyRecords: () => {
          const filterConfig = model.saveViz.filterConfig;
          return (filterConfig.myRecords = !filterConfig.myRecords);
        },

        toggleOpenStatuses: () => {
          const filterConfig = model.saveViz.filterConfig;
          return (filterConfig.openStatuses = !filterConfig.openStatuses);
        },

        setParams: newParams => {
          const filterConfig = model.saveViz.filterConfig;

          filterConfig.params = cloneDeep(newParams);
        },

        resetParams: () => {
          const filterConfig = model.saveViz.filterConfig;

          filterConfig.params = { start: 1 };
        },
      },

      dump: function() {
        return model;
      },

      dumpString: function(spacer) {
        return JSON.stringify(model, null, spacer);
      },

      deleteRows: testExceptionIdsForDelete => {
        let data = dataModel.table.data();
        let currentRecords = data.filter(record => {
          return !testExceptionIdsForDelete.includes(record["metadata.exception_id"]);
        });
        return dataModel.table.data(currentRecords);
      },

      deleteRowsExcept: testExceptionIdsForDelete => {
        let data = dataModel.table.data();
        let currentRecords = data.filter(record => {
          return testExceptionIdsForDelete.includes(record["metadata.exception_id"]);
        });
        return dataModel.table.data(currentRecords);
      },

      deleteAllRows: () => {
        return dataModel.table.data([]);
      },
      isHideMetaData: () => model.hide_metadata,
      setHideMetaData: flag => {
        model.hide_metadata = flag;
      },
    };

    createGetterSetters({
      filtersOpen: "saveViz.filterConfig.filtersOpen",

      "interpretation.title": "saveViz.visualizationInfo.title",
      "interpretation.linkLatestTable": "saveViz.visualizationInfo.linkLatestTable",
      "interpretation.summary": "saveViz.visualizationInfo.summary",

      visualizations: "saveViz.visualizations",
      "visualizationConfig.colorMapping": "saveViz.visualizationConfig.colorMapping",

      // FIXME: The data model shouldn't be aware of the implementation-specific IDs at all.
      "table.tableId": "saveViz.visualizationInfo.tableId",
      "table.tableName": "saveViz.visualizationInfo.tableName",
      "table.analyticName": "saveViz.visualizationInfo.analyticName",

      "table.metadataLoaded": "table.metaDataLoaded",
      "table.displayName": "table.metaData.name",
      "table.recordCount": "table.metaData.recordCount",
      "table.hasStaticField": "table.metaData.hasStaticField",
      "table.certificationsTable": "table.metaData.certificationsTable",
      "table.data": "table.data",
      "table.sortFields": "table.sortFields",
      "table.model": "table.model",

      "filterConfig.sortFieldId": "saveViz.filterConfig.sortField.field",
      "filterConfig.sortOrder": "saveViz.filterConfig.sortField.order",
      "filterConfig.jobId": "saveViz.filterConfig.jobId",

      "filterConfig.myRecords": "saveViz.filterConfig.myRecords",
      "filterConfig.openStatuses": "saveViz.filterConfig.openStatuses",

      "project.archived": "project.archived",
    });

    /**
     * Takes an object where property names are chained method names and
     * values are string paths to the relevant property of the model object.
     */
    function createGetterSetters(defs) {
      var chainPathStr, chainPath, chainRef, chainProp, dataPath, p;
      for (chainPathStr in defs) {
        dataPath = defs[chainPathStr].split(".");

        chainPath = chainPathStr.split(".");
        chainRef = dataModel;
        while (chainPath.length > 1) {
          p = chainPath.shift();
          if (chainRef[p] === undefined) chainRef[p] = {};
          chainRef = chainRef[p];
        }
        chainProp = chainPath[0];

        chainRef[chainProp] = (function(_chainRef, _chainPathStr, _dataRef, _dataPath) {
          var dataProp;
          return function(newValue) {
            var path = _dataPath.slice();
            var ref = _dataRef;
            while (path.length > 1) {
              p = path.shift();
              if (ref[p] === undefined) ref[p] = {};
              ref = ref[p];
            }
            dataProp = path[0];

            if (!arguments.length) {
              // FIXME: Copy the return value if it's an object.
              return ref[dataProp];
            }

            if (_dataPath.length && _dataPath[0] === "saveViz") {
              changeSaveVizModel(_dataPath.slice(1), newValue);
            } else {
              // FIXME: Copy the new value to protect ourselves from pointer fun.
              ref[dataProp] = newValue;
            }
            publishPropertyChanged(_chainPathStr);

            return _chainRef;
          };
        })(chainRef, chainPathStr, model, dataPath);
      }
    }

    function getFieldFormattingAccessor(formattingPropName) {
      return (function(propName) {
        return function(fieldName, newValue) {
          if (arguments.length < 2) {
            if (
              typeof model.saveViz.tableConfig !== "object" ||
              typeof model.saveViz.tableConfig.formatting !== "object" ||
              typeof model.saveViz.tableConfig.formatting[fieldName] !== "object"
            )
              return undefined;
            return model.saveViz.tableConfig.formatting[fieldName][propName];
          }
          if (typeof model.saveViz.tableConfig !== "object") {
            model.saveViz.tableConfig = {};
          }
          if (typeof model.saveViz.tableConfig.formatting !== "object") {
            model.saveViz.tableConfig.formatting = {};
          }
          if (typeof model.saveViz.tableConfig.formatting[fieldName] !== "object") {
            model.saveViz.tableConfig.formatting[fieldName] = {};
          }
          changeSaveVizModel(["tableConfig", "formatting", fieldName, propName], newValue);

          publishPropertyChanged("tableConfig.formatting." + propName);

          return dataModel.tableConfig;
        };
      })(formattingPropName);
    }

    function removeInvalidSortField(saveViz) {
      var sortField = saveViz.filterConfig.sortField;
      if (
        sortField &&
        (typeof sortField.field === "undefined" ||
          sortField.field === null ||
          typeof sortField.order === "undefined" ||
          sortField.order === null)
      ) {
        saveViz.filterConfig.sortField = undefined;
      }
      return saveViz;
    }

    function cleanUpFilterList(saveViz) {
      removeEmptyFilterList(saveViz);
      removeStoryboardVisualIndicatorProps(saveViz.filterConfig.filterList);
      return saveViz;
    }

    function removeEmptyFilterList(saveViz) {
      const filterList = saveViz.filterConfig.filterList;
      // when saving filters with filterList == [] backend errors out with
      // "Invalid tree format" so set to null
      if (filterList && filterList.length === 0) {
        delete saveViz.filterConfig.filterList;
      }

      return saveViz;
    }

    function removeStoryboardVisualIndicatorProps(filterList) {
      if (filterList && filterList.length > 0) {
        filterList.forEach(filterSet => {
          if (filterSet.hasOwnProperty("showStoryboardIndicator")) {
            delete filterSet.showStoryboardIndicator;
          }

          if (filterSet.quickFilter) {
            if (filterSet.quickFilter.hasOwnProperty("storyboardIndicatorValues")) {
              delete filterSet.quickFilter.storyboardIndicatorValues;
            }

            if (filterSet.quickFilter.hasOwnProperty("indeterminateValues")) {
              delete filterSet.quickFilter.indeterminateValues;
            }
          }

          if (filterSet.filters && filterSet.filters.length > 0) {
            filterSet.filters.forEach(filter => {
              if (filter.hasOwnProperty("showStoryboardIndicator")) {
                delete filter.showStoryboardIndicator;
              }
            });
          }
        });
      }
    }

    function removeLazyloadParams(saveViz) {
      delete saveViz.filterConfig.params;
      return saveViz;
    }

    function publishPropertyChanged(prop) {
      const args = ["dataModel." + prop].concat(Array.prototype.slice.call(arguments, 2));
      eventService.publish.apply(eventService, args);

      const propAsObserverEventPath = prop;
      const propForSubscriberCallback = prop;
      namespacedObserver.broadcast(propAsObserverEventPath, propForSubscriberCallback);
    }

    function publishAllPropertiesChanged() {
      const propAsObserverEventPath = "*";
      const propForSubscriberCallback = "*";
      namespacedObserver.broadcast(propAsObserverEventPath, propForSubscriberCallback);
    }

    function deFormatDataConfig(saveViz) {
      const visualizations = saveViz.visualizations;
      if (!visualizations) return saveViz;

      visualizations.forEach(({ vizType, config }, index) => {
        if (vizType === "CombinationChart") {
          saveViz.visualizations[index].config.dataConfig.chartXAxis = config.dataConfig.chartRows[0];
          delete saveViz.visualizations[index].config.dataConfig.chartRows;
          if (config.dataConfig.chartColumns && config.dataConfig.chartColumns.length) {
            saveViz.visualizations[index].config.dataConfig.chartSeries = config.dataConfig.chartColumns[0];
          }
          delete saveViz.visualizations[index].config.dataConfig.chartColumns;
        }
      });
      return saveViz;
    }
    return dataModel;
  });
