angular.module("acl.visualizer.statistics").directive("aclStatistics", () => ({
  restrict: "E",
  replace: true,
  scope: {
    data: "<",
    fieldFormatIdentifier: "<",
    fieldName: "<",
    isClickable: "<",
    onStatisticsClick: "&",
  },
  templateUrl: "visualizer/js/modules/visualization/statistics/statistics.tpl.html",
  controller: StatisticsController,
  controllerAs: "statistics",
  bindToController: true,
}));

class StatisticsController {
  constructor($scope, StatisticsService) {
    "ngInject";
    this.StatisticsService = StatisticsService;

    $scope.$watchGroup(
      ["statistics.data", "statistics.fieldFormatIdentifier", "statistics.fieldName"],
      this.updateData
    );
    $scope.$watchGroup(["statistics.isClickable", "statistics.onStatisticsClick"], this.updateClickHandler);
  }

  updateData = () => {
    if (this.data) {
      this.summaryRows = this.StatisticsService.formatData(this.data, this.fieldFormatIdentifier, this.fieldName);
    }
  };

  updateClickHandler = () => {
    this.onClick = this.isClickable ? this.onStatisticsClick : () => {};
  };
}
