import Observer from "./observer";

const SEPARATOR = ".";
const GLOBAL_OBSERVER_NAME = "*";

class NamespacedObserver {
  constructor() {
    this._globalObserver = new Observer();
    this._eventPathToObserver = new Map();
  }

  static getInstance() {
    return new NamespacedObserver();
  }

  subscribe(path, callback) {
    if (path === GLOBAL_OBSERVER_NAME) {
      return this._subscribeToGlobalEvent(callback);
    }
    return this._subscribeToEvent(path, callback);
  }

  _subscribeToGlobalEvent(callback) {
    return this._globalObserver.subscribe(callback);
  }

  _subscribeToEvent(path, callback) {
    let observer = this._getObserver(path);
    if (!observer) {
      observer = this._createObserver(path);
    }
    return observer.subscribe(callback);
  }

  _createObserver(path) {
    const observer = new Observer();
    this._eventPathToObserver.set(path, observer);
    return observer;
  }

  _getObserver(path) {
    return this._eventPathToObserver.get(path);
  }

  broadcast(path, ...data) {
    if (path === GLOBAL_OBSERVER_NAME) {
      this._broadcastToAll(...data);
    } else {
      this._broadcastToPath(path, ...data);
    }
    this._broadcastToGlobalObserver(path, ...data);
  }

  _broadcastToAll(...data) {
    this._eventPathToObserver.forEach(observer => {
      observer.broadcast(...data);
    });
  }

  _broadcastToPath(path, ...data) {
    const subPaths = getSubPaths(path);
    subPaths.forEach(subPath => {
      const observer = this._eventPathToObserver.get(subPath);
      if (observer) {
        observer.broadcast(...data);
      }
    });
  }

  _broadcastToGlobalObserver(path, ...data) {
    this._globalObserver.broadcast(...data);
  }
}

function getSubPaths(path) {
  const pathParts = path.split(SEPARATOR);
  return pathParts.map((_, index) => pathParts.slice(0, index + 1).join(SEPARATOR));
}

export default NamespacedObserver;
