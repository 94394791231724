import moment from "moment";

angular
  .module("acl.common.directives", [
    "acl.common.filters",
    "acl.common.localize",
    "acl.visualizer.formatters",
    "acl.visualizer.model.field",
    "ui.bootstrap.tooltip", // for acl-tooltip
    "ui.format",
  ])
  // acl-localize
  .directive("aclLocalize", function() {
    return {
      restrict: "E",
      replace: true,
      scope: {
        key: "@key",
      },
      template: '<span ng-bind-html="key|aclLocalize|aclTrustAsHtml"></span>',
    };
  })
  // no-href
  .directive("noHref", function() {
    return {
      restrict: "A",
      compile: function(elem, attrs) {
        $(elem).removeAttr("no-href");
        $(elem).attr("href", "#");
        $(elem).attr("onclick", "return false");
      },
    };
  })
  // acl-loading
  .directive("aclLoading", function() {
    return {
      restrict: "E",
      replace: true,
      template:
        '<div class="loading">' +
        '<i class="icon-spinner icon-spin"></i> ' +
        '<acl-localize key="_Loading.Label_"></acl-localize>' +
        "</div>",
    };
  })
  // acl-visible
  // Like 'ng-show' (ngShowDirective), but when false make it 'visibility: hidden' instead of 'display: none'
  .directive("aclVisible", function() {
    return {
      priority: -10,
      restrict: "A",
      link: function(scope, element, attr) {
        scope.$watch(attr.aclVisible, function(visible) {
          setVisibility(visible);
        });

        function setVisibility(visible) {
          element.css("visibility", visible ? "visible" : "hidden");
        }
      },
    };
  })
  // acl-quick-menu drag functionality
  .directive("aclQuickMenuDrag", function($timeout) {
    return {
      link: function(scope, elem, attrs) {
        elem.draggable({
          handle: attrs.aclQuickMenuHandle ? attrs.aclQuickMenuHandle : undefined,
        });

        elem.find(".filter-value-list").on("mousedown", function() {
          //This is a hack for IE10 compatibility. (IE does not trigger mouseup events on scroll bars.)
          elem.draggable("disable");
          $timeout(function() {
            elem.draggable("enable");
          });
        });

        scope.$on("quickMenu.resetPosition", function() {
          if (elem.attr("style")) {
            elem.removeAttr("style");
          }
        });
      },
    };
  })
  //acl-calc-tab-content-height
  .directive("aclCalcTabContentHeight", function($window, $timeout, EventService, AppConfig) {
    return {
      restrict: "A",
      replace: true,
      link: function(scope, $elem, $attrs) {
        let eventService = EventService.register("aclCalcTabContentHeight");
        let height;
        let contentHeightOffset = 0;
        let windowInnerHeight;

        function adjustWindowSize() {
          if ($window.innerHeight !== windowInnerHeight) {
            windowInnerHeight = $window.innerHeight;
            let $tabs = $elem.closest(".tabs");
            if ($tabs.length > 0) {
              const onPremiseNavHeight = 44;
              const globalNavHeight = 64;
              const breadcrumbHeight = 65;
              const tabContainerOffset = 5;

              const showBreadcrumbFromViz = AppConfig.features.highbondNavigationInVisualizer;
              const showBreadcrumbFromResults = AppConfig.features.featureVizBreadcrumb;

              const tabsOffset =
                showBreadcrumbFromViz || showBreadcrumbFromResults
                  ? globalNavHeight + breadcrumbHeight
                  : onPremiseNavHeight;

              contentHeightOffset = tabsOffset + $tabs.outerHeight() - tabContainerOffset;
            }
            height = windowInnerHeight - contentHeightOffset;
          }

          scope.tabContentHeight = {
            height: height + "px",
          };
        }

        adjustWindowSize();

        eventService.subscribe("windowResizeEvent", adjustWindowSize);
      },
    };
  })
  /**
   * acl-uib-datepicker-formatter directive
   * This directive converts date value passed to uib-datepicker value as string to date format
   */
  .directive("aclUibDatepickerFormatter", function() {
    return {
      restrict: "A",
      priority: 100,
      require: "ngModel",
      link: function(scope, element, attrs, ngModel) {
        if (!attrs.aclMomentjsParsePattern) {
          throw new Error(
            'In input that uses datepickerPopup directive missing expected "acl-momentjs-parse-pattern" attribute'
          );
        }
        ngModel.$formatters.push(function() {
          var formattedDate = "";
          if (ngModel.$$rawModelValue) {
            formattedDate = moment(ngModel.$$rawModelValue, attrs.aclMomentjsParsePattern, true).toDate();
          }
          return formattedDate;
        });
      },
    };
  })
  /**
   * acl-default-date-pattern attribute directive
   *
   * This directive adds in "standard" date pattern values for the pattern attributes that are
   * required by <acl-input-datetime>. Simplifies calling code since everywhere we
   * use acl-input-datetime, we basically want either the date or the datetime
   * pattern strings loaded from the localization files.
   *
   * Indebted to the following for an example of how to do a directive to insert attributes
   * for consumption by another directive:
   * http://stackoverflow.com/a/19228302/135114
   */
  .directive("aclDefaultDatePattern", function(Localize, $compile) {
    return {
      restrict: "A",
      terminal: true, // Terminal since since it changes the dom and then compiles it
      priority: 1000, // High priority so does that before the directive it's declared in gets compiled
      compile: function(elem, attrs) {
        // First, remove this directive attribute itself, to prevent infinite recursion
        elem.removeAttr("acl-default-date-pattern");

        // Then, add in the default localization values for the required datepicker patterns
        if (!attrs.aclMomentjsParsePattern) {
          var defaultMomentJsDatePattern = Localize.getLocalizedString("_Input.Date.Pattern.ForMomentJsParsing_");
          elem.attr("acl-momentjs-parse-pattern", defaultMomentJsDatePattern);
        }

        if (!attrs.aclDatepickerPattern) {
          var defaultDatepickerPattern = Localize.getLocalizedString("_Input.Date.Pattern.ForDatepickerDisplay_");
          elem.attr("acl-datepicker-pattern", defaultDatepickerPattern);
        }

        if (!attrs.placeholder) {
          var defaultPlaceholder = Localize.getLocalizedString("_Input.Date.Pattern.ToShowUser_");
          elem.attr("placeholder", defaultPlaceholder);
        }

        return {
          // Finally, compile
          post: function(scope, element) {
            $compile(element)(scope);
          },
        };
      },
    };
  })
  /**
   * acl-default-datetime-pattern attribute directive
   *
   * This directive adds in "standard" datetime pattern values for the pattern attributes that are
   * required by <acl-input-datetime>. See acl-default-date-pattern for a few more details
   */
  .directive("aclDefaultDatetimePattern", function(Localize, $compile) {
    return {
      restrict: "A",
      terminal: true,
      priority: 1000,
      compile: function(elem, attrs) {
        // First, remove this directive attribute itself, to prevent infinite recursion
        elem.removeAttr("acl-default-datetime-pattern");

        // Then, add in the default localization values for the required datepicker patterns
        if (!attrs.aclMomentjsParsePattern) {
          var defaultMomentJsDatePattern = Localize.getLocalizedString("_Input.Datetime.Pattern.ForMomentJsParsing_");
          elem.attr("acl-momentjs-parse-pattern", defaultMomentJsDatePattern);
        }

        if (!attrs.aclMomentjsAlternateParsePattern) {
          var momentJsDatePatternWithoutSeconds = Localize.getLocalizedString(
            "_Input.Datetime.Pattern.ForMomentJsParsing.WithoutSeconds_"
          );
          elem.attr("acl-momentjs-alternate-parse-pattern", momentJsDatePatternWithoutSeconds);
        }

        if (!attrs.aclDatepickerPattern) {
          var defaultDatepickerPattern = Localize.getLocalizedString("_Input.Datetime.Pattern.ForDatepickerDisplay_");
          elem.attr("acl-datepicker-pattern", defaultDatepickerPattern);
        }

        if (!attrs.placeholder) {
          var defaultPlaceholder = Localize.getLocalizedString("_Input.Datetime.Pattern.ToShowUser_");
          elem.attr("placeholder", defaultPlaceholder);
        }

        return {
          // Finally, compile
          post: function(scope, element) {
            $compile(element)(scope);
          },
        };
      },
    };
  })
  // acl-message
  .directive("aclMessage", function($compile) {
    return {
      restrict: "E",
      replace: true,
      scope: {
        msg: "=",
        close: "&",
      },
      template:
        '<div id="acl-message" class="alert alert__stickied" ng-class="[msg.type, (msg.minimized ? \'minimized\' : \'\')]"' +
        ' ng-show="msg" style="display: flex; flex-direction: row-reverse;justify-content: space-between;">' +
        '<a ng-show="closeable" class="close" ng-click="close()">&times;</a>' +
        '<div class="message" style="display: flex;">' +
        '<span ng-show="msg.header"><strong>{{msg.header}}</strong></span>' +
        "&nbsp" +
        '<div class="msg-content" style="display: inline-block;"></div>' +
        "</div>" +
        "</div>",
      link: function($scope, $elem, attrs) {
        $scope.closeable = "close" in attrs;

        var $content = $elem.find(".msg-content");

        $scope.$watch(
          "msg",
          function(msg) {
            if (!msg) return;

            var html = msg.content || "";

            if (msg.unsafehtmlcontent) {
              var unsafehtmlcontent = angular.isArray(msg.unsafehtmlcontent)
                ? msg.unsafehtmlcontent
                : [msg.unsafehtmlcontent];

              unsafehtmlcontent.forEach(function(item, i) {
                if (html.indexOf("$" + i) < 0) {
                  html += "$" + i;
                }
                html = html.replace("$" + i, item);
              });
            }

            html = "<div>" + html + "</div>";
            $content.empty().html(html);
            $compile($content.contents())($scope.$parent);
          },
          true
        );
      },
    };
  })
  // acl-table
  .directive("aclTable", function(Localize) {
    return {
      restrict: "E",
      replace: true,
      scope: {
        columns: "=",
        options: "=",
        data: "=",
      },
      template:
        '<table class="table table-hover aclviz-table {{options.cssClass.table}}">' +
        "<thead>" +
        "<tr>" +
        '<th ng-repeat="col in columns" class="{{col.cssClass}}">' +
        "{{col.displayName}}" +
        '<i class="icon-sort padding_left" ng-show="!col.suppressSort && options.allowSort" ng-click="options.sortField=col.name; options.reverse=!options.reverse"></i>' +
        "</th>" +
        '<th ng-show="options.hasActionLink" class="{{options.cssClass.actionLinkHeader}}">{{options.actionLinkColumnHeader}}</th>' +
        "</tr>" +
        "</thead>" +
        "<tbody>" +
        '<tr ng-repeat="row in data | orderBy:options.sortField:options.reverse" ng-class="row.rowCssClass">' +
        '<td ng-repeat="col in columns">' +
        '<span ng-switch on="col.type">' +
        '<span ng-switch-when="datetime"><span acl-overflow-tooltip>{{row[col.name] | aclLocalizeDatetime}}</span></span>' +
        '<span ng-switch-when="date"><span acl-overflow-tooltip>{{row[col.name] | aclLocalizeDate}}</span></span>' +
        '<span ng-switch-when="number"><span acl-overflow-tooltip>{{row[col.name]|number}}</span></span>' +
        '<span ng-switch-when="size"><span acl-overflow-tooltip>{{row[col.name] | aclDisplaySize:2 }}</span></span>' +
        '<span ng-switch-when="html"><span ng-bind-html="row[col.name]"></span></span>' +
        "<span ng-switch-default><span acl-overflow-tooltip>{{row[col.name]}}</span></span>" +
        "</span>" +
        "</td>" +
        '<td ng-show="options.hasActionLink" class="{{options.cssClass.actionLinkBody}}">' +
        '<span class="action-link" ng-repeat="actionLink in row.actionLinks">' +
        '<span ng-switch on="actionLink.linkType">' +
        '<a ng-switch-when="link" href="{{actionLink.link}}">{{actionLink.label}}</a>' +
        '<a ng-switch-when="click" href="#" onclick="return false" ng-click="actionLink.onClick(actionLink.onClickParam)">{{actionLink.label}}</a>' +
        '<span ng-switch-when="label">{{actionLink.label}}</span>' +
        "</span>" +
        "</span>" +
        "</td>" +
        "</tr>" +
        '<tr ng-hide="data && data.length"><td colspan="{{columns.length}}"><span ng-bind-html="options.noDataMessage | aclTrustAsHtml"></span></td></tr>' +
        "</tbody>" +
        "</table>",
      link: {
        pre: function preLink(scope, elem, attrs) {
          if (!scope.columns || !scope.data) {
            return false;
          }
          if (!scope.options) {
            scope.options = {};
          }
          if (!scope.options.noDataMessage) {
            scope.options.noDataMessage = Localize.getLocalizedString("_General.Table.NoData.Message_");
          }
        },
        post: function postLink(scope, elem, attrs) {
          scope.$watch("data", function(newValue) {
            // need to update the hasActionLink flag
            scope.options.hasActionLink = checkActionLink(scope.data);
          });

          function checkActionLink(savedVizList) {
            var hasActionLink = false;
            if (savedVizList) {
              for (var iRow = 0; iRow < savedVizList.length; iRow++) {
                if (savedVizList[iRow].actionLinks && savedVizList[iRow].actionLinks.length) {
                  var actionLinks = savedVizList[iRow].actionLinks;
                  for (var iLink = 0; iLink < actionLinks.length; iLink++) {
                    var actionLink = actionLinks[iLink];
                    if (actionLink) {
                      if (actionLink.link) {
                        hasActionLink = true;
                        actionLink.linkType = "link";
                      } else if (typeof actionLink.onClick === "function") {
                        hasActionLink = true;
                        actionLink.linkType = "click";
                      } else if (actionLink.label) {
                        hasActionLink = true;
                        actionLink.linkType = "label";
                      }
                    }
                  }
                }
              }
            }
            return hasActionLink;
          }
        },
      },
    };
  })
  // acl-tooltip
  .directive("aclTooltip", function($compile, $timeout) {
    return {
      priority: 1000,
      terminal: true,
      compile: function(tElem, tAttr) {
        // Get acl-tooltip value at compile time so we can set tooltip to the raw (unevaluated) value
        var unevaluatedValue = tAttr.aclTooltip;
        tElem.removeAttr("acl-tooltip");
        tElem.attr("uib-tooltip", unevaluatedValue);

        if (angular.isUndefined(tAttr.tooltipPlacement)) {
          tElem.attr("tooltip-placement", "top");
        }
        if (angular.isUndefined(tAttr.tooltipPopupDelay)) {
          tElem.attr("tooltip-popup-delay", "500");
        }
        if (angular.isUndefined(tAttr.tooltipAppendToBody)) {
          tElem.attr("tooltip-append-to-body", "true");
        }

        return function postlink(scope, elem, attr) {
          decompile(elem);
          $compile(elem)(scope);
        };
      },
    };

    function decompile(elem) {
      elem.unbind();
    }
  })
  /**
   * @name acl-hover-edit-input
   *
   * @description
   * An edit box that looks like text by default,
   * but when you hover over it & edit it the background and border are visible.
   *
   * - acl-auto-resize-input:  This is key to the 'look' of the hover-to-edit style; the input
   *                           text box width adjusts to fit the text inside it.
   * - ng-trim="false": The acl-auto-resize-input directive needs this
   * - acl-trim-on-blur: Compensate for the ng-trim="false"
   * - acl-enter-key-blurs-if-valid:  Hit enter to accept a value
   * - acl-revert-on-blur-if-invalid:  Don't apply the new value if it's invalid
   * - acl-escape-key-reverts-and-blurs:  If you change the value and then hit escape, reverts to initial value
   */
  .directive("aclHoverEditInput", function() {
    return {
      restrict: "E",
      require: "ngModel",
      replace: true,
      template:
        '<input type="text" ' +
        "acl-auto-resize-input " +
        'ng-trim="false" ' +
        "acl-trim-on-blur " +
        "acl-enter-key-blurs-if-valid " +
        "acl-revert-on-blur-if-invalid " +
        "acl-escape-key-reverts-and-blurs " +
        'class="edit-when-hover" />',
    };
  })
  // acl-hover-edit-area
  .directive("aclHoverEditArea", function() {
    return {
      restrict: "E",
      require: "ngModel",
      replace: true,
      template: "<textarea " + 'ng-trim="false" ' + "acl-escape-key-reverts-and-blurs" + "></textarea>",
    };
  })
  /**
   * @name acl-enter-key-blurs-if-valid
   *
   * @description
   * When you hit enter, if the value is valid (e.g. matches any ng-pattern you've used) then it blurs
   * (This works nicely with actions that take place when the input is blurred)
   * If the value is not valid then nothing happens when you hit enter.
   */
  .directive("aclEnterKeyBlursIfValid", function() {
    return {
      restrict: "A",
      require: "ngModel",
      link: function(scope, element, attr, ngModelCtrl) {
        element.bind("keypress", function(event) {
          var enter = 13;
          if (event.which === enter && ngModelCtrl.$valid) {
            element.blur();
          }
        });
      },
    };
  })
  /**
   * @name acl-trim-on-blur
   * @description Trims an input value when the element is blurred.
   * This directive is given a higher priority so it will happen before other blur event handlers.
   *
   * (This is useful because the acl-auto-resize-input directive requires ng-trim=false
   * for resizing, but normally you don't want the resulting value to be untrimmed.)
   */
  .directive("aclTrimOnBlur", function() {
    return {
      restrict: "A",
      priority: 100,
      require: "ngModel",
      link: function(scope, element, attr, ngModelCtrl) {
        element.on("blur", function() {
          var untrimmedValue = element.val(),
            blahAsString = untrimmedValue.toString();
          var trimmedValue = blahAsString.trim();
          if (trimmedValue !== untrimmedValue) {
            ngModelCtrl.$setViewValue(trimmedValue);
            ngModelCtrl.$render();
          }
        });
      },
    };
  })
  /**
   * @name acl-revert-on-blur-if-invalid
   *
   * @description
   * If the text is not valid and you blur (change focus to elsewhere), then the value reverts to
   * what it was initially.
   * (Note that IE 11 does this for all text inputs by default. This directive brings it to FF & Chrome.)
   */
  .directive("aclRevertOnBlurIfInvalid", function() {
    return {
      restrict: "A",
      require: "ngModel",
      priority: 200, // Higher priority than acl-trim-on-blur (fix value before it tries to trim!)
      link: function(scope, element, attr, ngModelCtrl) {
        var initialViewValue;

        element.bind("focus", function() {
          initialViewValue = ngModelCtrl.$viewValue;
        });

        element.bind("blur", function() {
          if (!ngModelCtrl.$valid) {
            ngModelCtrl.$setViewValue(initialViewValue);
            ngModelCtrl.$render();
          }
        });
      },
    };
  })
  /**
   * @name acl-escape-key-reverts-and-blurs
   *
   * @description
   * If you change the value and then hit escape, it reverts to the initial value
   * (IE 10+ does this on its own, but this directive makes Chrome & FF do it too)
   */
  .directive("aclEscapeKeyRevertsAndBlurs", function() {
    return {
      restrict: "A",
      require: "ngModel",
      link: function(scope, element, attr, ngModelCtrl) {
        var initialViewValue;

        element.bind("focus", function() {
          initialViewValue = ngModelCtrl.$viewValue;
        });

        element.bind("keydown", function(e) {
          // Escape key seems to not fire keypress (only some browsers? Not sure.)
          var escape = 27;
          // Note current jquery/FF doesn't put ESCAPE into e.which, but e.keyCode works for all 3 browsers
          if (e.keyCode === escape) {
            ngModelCtrl.$setViewValue(initialViewValue);
            ngModelCtrl.$render();
            element.blur();
          }
        });
      },
    };
  })
  /**
   * @name acl-auto-resize-input
   *
   * @description
   * Input fields don't resize like other elements - width is normally fixed.
   * We want the input to be wide enough for the containing text, not a fixed size.
   *
   * Solution: Make a temporary hidden element with the same font style, to measure the width
   * we need to set the input to (input will respect setting css 'width').
   * Big thanks to http://stackoverflow.com/a/19304556/135114 for implementation
   *
   * (Note: this whole directive is kindof a pain, don't you think? But seems to be necessary if you want
   * to use an actual *input* element.   Why use input? Well, then you get to use all of angular's input
   * stuff like ng-pattern, ng-maxwidth, etc. etc.)
   *
   * - acl-auto-resize-input="10"
   *      Optional value passed for this attribute becomes the added width (i.e. right padding) used to
   *      avoid jitter. If not specified, defaults to 10.
   *      Normally, there would be no need to specify a value; just use the default 10px and subtract
   *      that from your right padding.
   *      You probably would only need to make it bigger if you use a really big font; and only smaller
   *      if you wanted padding < 10px and you were using a small font.
   *      At 18px italic font the smallest for IE to not jitter with trailing space is 7. But 10 is nicer number.
   * - acl-shown-event
   *      Optional attribute. You can use it to specify a name of an event. Then broadcast that event when
   *      you want the width to be recalculated.
   *      (This is handy because if the parent elements are not sized at the time the page is loaded
   *      then they need to be resized when that portion of the UI becomes visible. Chart title uses this
   *      when you show a tab, because when multiple tabs are loaded only the visible one gets sized right.)
   *
   * - ng-trim="false" is a required attribute, in order for resizing to still happen.
   *   While editing the value, it will not be trimmed; but normally you want trimming, so you can
   *   just add the acl-trim-on-blur directive to the same input element that has acl-auto-resize-input.
   */
  .directive("aclAutoResizeInput", function($window, $timeout) {
    return {
      restrict: "A",
      require: "ngModel",
      link: function(scope, element, attr, ngModelCtrl) {
        function checkRequiredAttributes() {
          if (attr.ngTrim !== "false") {
            throw new Error('acl-auto-resize-input found on an element without required attribute ng-trim="false"');
          }
        }

        function getPaddingToAvoidJitter() {
          // Without a fex extra px width, trailing f gets cut off in all 3 browsers.
          //
          // Without a little more extra width, typing a trailing space in IE and FF causes a jitter.
          // I have no idea why trailing space is different than any other letter, and I hate fudge
          // factors... but it works to just make the width a little wider.
          //
          // You can style it with less right padding to compensate, and effectively have it look
          // just as though you didn't have it.
          //
          // Defaults to 10. For a different value, specify via attribute, e.g. acl-auto-resize-input="15"

          var autoResizeRightPaddingInPx,
            rightPaddingToAvoidJitter = attr.aclAutoResizeInput;

          if (rightPaddingToAvoidJitter) {
            autoResizeRightPaddingInPx = Number(rightPaddingToAvoidJitter);
            if (isNaN(autoResizeRightPaddingInPx)) {
              throw new Error(
                'acl-auto-resize-input must be an integer, instead got "' + rightPaddingToAvoidJitter + '"'
              );
            }
          }

          var defaultPaddingToAvoidJitter = 10; // Arbitrary default padding. Looks good for 18px font
          return autoResizeRightPaddingInPx || defaultPaddingToAvoidJitter;
        }

        function valueChanged(newValue) {
          var resizeIsRequired = newValue !== valueThatHasBeenSizedFor;
          if (resizeIsRequired) {
            valueThatHasBeenSizedFor = newValue;
            resizeWidth(newValue);
          }
        }

        function resizeWidth(newValue) {
          var widthTesterElement = angular.element("<span/>"),
            elementStyle = $window.document.defaultView.getComputedStyle(inputElement, "");

          widthTesterElement.css({
            "font-family": elementStyle.fontFamily,
            "font-size": elementStyle.fontSize,
            "font-weight": elementStyle.fontWeight,
            "font-style": elementStyle.fontStyle,
            "white-space": "pre", // (Avoid span wrapping to next line, which messes up bounding box measurement.)
            visibility: "hidden",
            position: "absolute",
          });

          widthTesterElement.text(newValue); // Set the test text to the input's value
          element.parent().append(widthTesterElement); // Temporarily put into DOM so we can measure

          var bounds = widthTesterElement[0].getBoundingClientRect(),
            measuredWidth = Math.round(bounds.width), // round because FF bounds have fractional but css is int
            currentWidth = element.css("width"),
            newWidthWithPxSuffix = measuredWidth + paddingToAvoidJitter + "px";

          if (newWidthWithPxSuffix !== currentWidth) {
            element.css("width", newWidthWithPxSuffix);
          }

          // Finally, remove the temporary tester element
          widthTesterElement.remove();
        }

        var paddingToAvoidJitter = getPaddingToAvoidJitter(),
          inputElement = element[0],
          valueThatHasBeenSizedFor;

        checkRequiredAttributes();

        // Hide the IE 10+ 'x'-to-clear (which covers up the right part of the input, since we're setting
        // the width manually based on the text and not taking into account the width of the ::-ms-clear
        // pseudo element.
        // (Note: only because I couldn't figure out how to measure ::-ms-clear. If you know how to
        // accommodate it feel free to drop this class — Daryn Mitchell, May 2014)
        element.addClass("hide-ie-clear-x");

        element.on("keydown cut paste", function(e) {
          // Call in $evalAsync, so it runs "*after* the DOM has been manipulated by Angular, but before
          // the browser renders" (http://stackoverflow.com/a/17303759/135114)
          scope.$evalAsync(function() {
            valueChanged(inputElement.value);
          });
        });

        element.on("blur", function() {
          // Make sure we do the width again on blur, in case another directive changed the input value.
          // (E.g. 2 directives designed to be used with this one, acl-trim-on-blur and
          // acl-revert-on-blur-if-invalid, both can change the value on blur. They have higher priority
          // so the value will already have been updated by the time this even is received here.)
          scope.$evalAsync(function() {
            resizeWidth(inputElement.value);
          });
        });

        // $formatters lets us update when value is set from model, including initial page load/show. Yay.
        ngModelCtrl.$formatters.push(function(newValue) {
          // Have to resize after the DOM has rendered (otherwise can't measure required bounds):
          // For some reason, evalAsync doesn't work in IE only (too soon?), but timeout works fine.
          $timeout(function() {
            valueChanged(newValue);
          }, 0);
          return newValue;
        });

        // acl-shown-event provides a way for tab switches to trigger size recalculation
        if (attr.aclShownEvent) {
          scope.$on(attr.aclShownEvent, function() {
            // The first time we switch to each loaded tab, we need to recalculate the size.
            // Resize directly, instead of calling valueChanged(). (The value probably *hasn't* changed
            // when tab is switched, so valueChanged would skip the resize call!)
            resizeWidth(inputElement.value);
          });
        }
      },
    };
  })
  /*
  TODO: This is a candidate to be refactored after upgrading to Angular 1.2 which includes an ngKeydown directive.
  */
  // acl-on-key-down
  .directive("aclOnKeyDown", function() {
    return {
      link: function($scope, element, attr) {
        var options = JSON.parse(attr.aclOnKeyDown);

        $("body").bind("keydown", function(evt) {
          if (evt.keyCode === options.keyCode) {
            $scope.$apply(function() {
              $scope.$eval(options.callback);
            });
          }
        });
      },
    };
  })
  // acl-dynamic-name
  .directive("aclDynamicName", function($compile) {
    return {
      restrict: "EA",
      priority: 100,
      require: "ngModel",
      link: function(scope, element, attrs, ngModel) {
        element.attr("name", scope.$eval(attrs.aclDynamicName));
        element.removeAttr("acl-dynamic-name");
      },
    };
  })
  // acl-toggle-btn
  .directive("aclToggleBtn", function() {
    return {
      restrict: "E",
      replace: true,
      scope: {
        toggleBtnValue: "=",
        toggleBtnLabel: "@",
      },
      template:
        '<div class="acl-toggle-btn-wrapper">' +
        '<div class="acl-toggle-btn" ng-class="{on: toggleBtnValue, off: !toggleBtnValue}" ng-click="toggleBtnValue=!toggleBtnValue">' +
        '<div class="toggler"></div>' +
        "</div>" +
        '<div class="acl-toggle-label">{{toggleBtnLabel}}</div>' +
        "</div>",
    };
  })
  // acl-select2-open
  .directive("aclSelect2Open", function() {
    return {
      restrict: "A",
      link: function(scope, element) {
        element.on("select2-open", function() {
          // trigger click on parent container so click event can go through.
          $(this)
            .parent()
            .click();
        });
      },
    };
  })
  // acl-expose-property
  .directive("aclExposeProperty", function($parse) {
    return {
      restrict: "A",
      link: function(scope, element, attrs) {
        var properties = $parse(attrs.aclExposeProperty)(scope);
        angular.forEach(properties, function(value, key) {
          $parse(value).assign(scope, scope[key]);
        });
      },
    };
  });
