import uniq from "lodash/uniq";
import { aliasColumnAggregationFragment, countAggregationFragment, columnAggregationFragment } from "./queryFragment";

export default class CombinationChartQueryGenerator {
  static generate(tableId, filter, groupByFields, aggregationFields, scope) {
    return {
      query: query(aggregationFields),
      variables: {
        tableId: tableId,
        filter: JSON.stringify(filter),
        groupByBarChart: groupByFieldString(groupByFields, 0),
        groupByLineChart: groupByFieldString(groupByFields, 1),
        scope,
      },
    };
  }
}

function query(aggregationFields) {
  let [barChartAggregationField, lineChartAggregationField] = aggregationFields;
  lineChartAggregationField = lineChartAggregationField || barChartAggregationField;
  return `
        query combinationChartData(
            $tableId: Int!, $groupByBarChart: String, $groupByLineChart: String, $filter: String, $scope: Scope
        ) {
          table(id: $tableId, scope: $scope) {
            barChart: columnAgg(groupBy: $groupByBarChart, filter: $filter) {
                ${getAggregationFragments([barChartAggregationField])}
            },
            lineChart: columnAgg(groupBy: $groupByLineChart, filter: $filter) {
                ${getAggregationFragments([lineChartAggregationField])}
            }
          }
        }
      `;
}

function groupByFieldString(fieldNames, index) {
  const fieldNamesNew = index === 1 ? (fieldNames.length > 1 ? [fieldNames[1]] : fieldNames) : fieldNames;
  return JSON.stringify(fieldNamesNew.map(fieldName => ({ field: fieldName })));
}

function getAggregationFragments(aggregationFields) {
  return aggregationFields.map(aggregationFragment).join(",");
}

function aggregationFragment(agg) {
  const functions = uniq(agg.functions).join(",");
  if (agg.fieldName && functions !== "count") {
    if (agg.alias) {
      return aliasColumnAggregationFragment(agg.alias, agg.fieldName, functions);
    }
    return columnAggregationFragment(agg.fieldName, functions);
  }
  return countAggregationFragment();
}
