import React from "react";
import PropTypes from "prop-types";
import i18n from "@viz-ui/i18n/i18n";
import Checkbox from "@paprika/checkbox";
import ToolTip from "@viz-ui/components/ToolTip";
import "./multiSelectFilter.scss";
import checkboxState from "@viz-ui/models/checkboxState";
import FilterValueService from "@viz-ui/services/storyboardFilter/filterValueService";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import VisualIndicator from "@viz-ui/components/visualIndicator/visualIndicator";
import EventService from "@viz-ui/services/eventService/eventService";
import MultiSelectFilterStore from "@viz-ui/services/quickMenu/MultiSelectFilterStoreService";

export default class MultiSelectFilter extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    filterDisabled: PropTypes.bool,
    field: PropTypes.oneOfType([PropTypes.object]).isRequired,
    fieldFormatIdentifier: PropTypes.string,
    onLoadMoreClick: PropTypes.func,
    onSearchChange: PropTypes.func,
    onToggle: PropTypes.func,
    prevSelectedValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
    selectedValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
    showLoadMoreButton: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.object),
    indeterminateValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
    storyboardIndicatorValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
    selectAllState: PropTypes.string,
    onSelectAllClick: PropTypes.func,
    totalValuesCount: PropTypes.number,
    isDataLoading: PropTypes.bool,
  };

  static defaultProps = {
    filterDisabled: false,
    onLoadMoreClick: PropTypes.func,
    onSearchChange: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    selectedValues: [],
    indeterminateValues: [],
    storyboardIndicatorValues: [],
    selectAllState: checkboxState.UNCHECKED,
    errorMessage: "",
    fieldFormatIdentifier: undefined,
    prevSelectedValues: [],
    showLoadMoreButton: false,
    values: [],
    onSelectAllClick: PropTypes.func,
    totalValuesCount: 0,
    isDataLoading: false,
  };

  constructor(props) {
    super(props);
    this.visibleValuesLength = 0;
    this.visibleSelectedValuesLength = 0;
    this.resetCheckedValuesInvoked = false;
    this.selectedValuesForWatch = [];
    this.searchInterval = 500;
    this.searchValueModel = "";
    this._eventServiceInstance = EventService.register("MultiSelectFilter");
    this._eventServiceInstance.subscribe("clearQuickMenuSearch", () => {
      this.clearQuickMenuSearchText();
    });
    this.timeoutId;
  }

  clearQuickMenuSearchText = () => {
    setTimeout(() => {
      this.searchValueModel = "";
      $("#filterPanel-" + this.escapeHtml(this.fieldName()) + "-quickFilterSearchInput").val("");
    }, 0);
  };

  initialize = () => {
    this.multiSelectItems = this.props.values;
    this.valuesAsArray =
      this.props.values &&
      this.props.values.map(function(x) {
        return x.value;
      });
    this.selectedValuesForWatch = this.props.selectedValues;
    this.indeterminateValuesForWatch = this.props.indeterminateValues;
    this.unSelectedValues =
      (MultiSelectFilterStore && MultiSelectFilterStore.getUnCheckedValues(this.fieldName())) || [];

    this.visibleValuesLength = this.valuesAsArray ? this.valuesAsArray.length : 0;
    if (!this.searchValueModel) {
      this.selectAllStatePriorSearch = this.props.selectAllState;
      this.totalVisibleValuesPriorSearch = this.valuesAsArray; // It holds all visible items
      this.totalValuesCountPriorSearch = this.props.totalValuesCount; // It holds the total items length (both visible & non-visible values count)
    }
    this.totalLoadedValues = this.getTotalLoadedValues(
      this.valuesAsArray || [],
      this.totalVisibleValuesPriorSearch || []
    );

    if (
      this.props.selectAllState === checkboxState.CHECKED ||
      (this.unSelectedValues && this.unSelectedValues.length > 0)
    ) {
      this.selectedValuesForWatch = this.getSelectedValuesForWatch(this.totalLoadedValues, this.unSelectedValues);

      this.resetCheckedValues(this.fieldName(), this.selectedValuesForWatch, this.unSelectedValues);
    }
    this.visibleSelectedValuesLength = this.getVisibleSelectedValuesLength(
      !!this.searchValueModel,
      this.valuesAsArray || [],
      this.selectedValuesForWatch || []
    );

    //update values for check box state
    this.updateMultiselectCheckState(this.props, this.multiSelectItems, this.selectedValuesForWatch);
  };

  resetCheckedValues = (fieldName, selectedValuesForWatch, unSelectedValues) => {
    if (selectedValuesForWatch && selectedValuesForWatch.length > 0) {
      return MultiSelectFilterStore.resetCheckedValues(fieldName, selectedValuesForWatch, unSelectedValues);
    }
  };

  getSelectedValuesForWatch = (totalLoadedValues, unSelectedValues) =>
    totalLoadedValues.filter(value => unSelectedValues.findIndex(unSelectedValue => unSelectedValue === value) === -1);

  getSelectAllState = () =>
    this.getSelectAllStateProp(this.props, this.visibleSelectedValuesLength, this.visibleValuesLength);

  getSelectAllStateProp = (props, visibleSelectedValuesLength, visibleValuesLength) =>
    ((props.selectAllState === checkboxState.UNCHECKED || !props.selectAllState) &&
      this.selectedValuesForWatch &&
      this.selectedValuesForWatch.length > 0) ||
    !!this.searchValueModel
      ? MultiSelectFilterStore.getSelectAllStateProp(visibleSelectedValuesLength, visibleValuesLength)
      : props.selectAllState;

  getVisibleSelectedValuesLength = (filterString, values, selectedValues) =>
    !filterString
      ? selectedValues.length
      : values.filter(value => selectedValues.findIndex(selectedValue => selectedValue === value) > -1).length;

  getTotalLoadedValues = (values, totalVisibleValuesPriorSearch) => [
    ...new Set([...values.map(x => x), ...totalVisibleValuesPriorSearch.map(x => x)]),
  ];

  updateMultiselectCheckState = (props, multiSelectItems, selectedValuesForWatch) => {
    let checkState = checkboxState.UNCHECKED;
    const updatedMultiSelectItems = multiSelectItems;
    if (updatedMultiSelectItems) {
      for (let index = 0; index <= updatedMultiSelectItems.length - 1; index++) {
        if (
          selectedValuesForWatch &&
          selectedValuesForWatch.length > 0 &&
          selectedValuesForWatch.includes(multiSelectItems[index].value)
        ) {
          checkState = checkboxState.CHECKED;
        } else if (this.indeterminateValuesForWatch && this.isIndeterminateState(multiSelectItems[index])) {
          checkState = checkboxState.INDETERMINATE;
        } else checkState = checkboxState.UNCHECKED;
        updatedMultiSelectItems[index].checkedState = checkState;
      }
    }
  };

  fieldName = () => this.props.field && this.props.field.fieldName;

  fieldType = () => this.props.field && this.props.field.type;

  getStatusTitle = () => {
    const selectedValuesLength = (this.selectedValuesForWatch && this.selectedValuesForWatch.length) || 0;
    if (selectedValuesLength === 0) return i18n.t("_Multiselect.Placeholder_");

    const selectedValuesCount =
      this.props.selectAllState === checkboxState.CHECKED || this.unSelectedValues.length > 0
        ? this.totalValuesCountPriorSearch - this.unSelectedValues.length
        : selectedValuesLength;

    return i18n.t("_Multiselect.statusTitle.Label_", {
      selectedValuesCount: selectedValuesCount,
      totalValuesCount: this.totalValuesCountPriorSearch,
    });
  };

  placeholderText = () => {
    if ((this.fieldType() && this.fieldType() === "date") || this.fieldType() === "datetime") {
      return i18n.t("_General.FilterField.DatePlaceholder_");
    }
    return i18n.t("_General.FilterField.Placeholder_");
  };

  //events
  handleSearchInputKeyUP = (e, props) => {
    if (props && props.onSearchChange) {
      clearTimeout(this.timeoutId);
      this.searchValueModel = e.target.value;
      this.timeoutId = setTimeout(() => {
        this.handleInputChange(this.searchValueModel);
      }, 1000);

      this.searchTimer = setTimeout(() => {}, this.searchInterval);
    }
  };

  handleInputChange = searchValue => {
    this.props.onSearchChange(searchValue);
  };

  handleSearchInputKeyDown = () => {
    clearTimeout(this.searchTimer);
  };

  toggleCheckboxFilterValue = item => {
    if (this.props.onToggle && !this.filterDisabled) {
      this.props.onToggle(this.props.field, item.value, this.totalLoadedValues.length, !!this.searchValueModel);
      this.clearIndeterminateState(item);
    }
  };

  handleSelectAllClick = () => {
    if (this.props.onSelectAllClick && !this.filterDisabled) {
      const selectAllStateProp = this.getSelectAllStateProp(
        this.props,
        this.visibleSelectedValuesLength,
        this.visibleValuesLength
      );
      this.props.onSelectAllClick(
        this.props.field,
        selectAllStateProp === checkboxState.CHECKED ? "remove" : "add",
        this.valuesAsArray,
        this.totalLoadedValues.length,
        !!this.searchValueModel,
        this.selectAllStatePriorSearch
      );

      this.clearIndeterminateState(null, true);
    }
  };

  handleLoadMoreClick = () => {
    if (this.props.onLoadMoreClick) {
      this.props.onLoadMoreClick();
    }
  };

  onKeyupHandleLoadMore = event => {
    if (event.keyCode === 13 || event.keyCode === 32) this.handleLoadMoreClick();
  };

  onKeyupHandleSelectAllClick = event => {
    if (event.keyCode === 13 || event.keyCode === 32) this.handleSelectAllClick();
  };

  onKeyupHandleCheckboxFilter = (event, item) => {
    if (event.keyCode === 13 || event.keyCode === 32) this.toggleCheckboxFilterValue(item);
  };

  //}
  clearIndeterminateState = (item, selectAllClick = false) => {
    if (selectAllClick) {
      const visibleIndeterminateValues =
        this.getVisibleIndeterminateValues(
          !!this.searchValueModel,
          this.valuesAsArray || [],
          this.indeterminateValuesForWatch || []
        ) || [];

      if (this.indeterminateValuesForWatch) {
        this.indeterminateValuesForWatch = this.indeterminateValuesForWatch.filter(
          value => visibleIndeterminateValues.findIndex(x => x === value) === -1
        );
      }
    } else if (this.isIndeterminateState(item)) {
      this.indeterminateValuesForWatch = this.indeterminateValuesForWatch.filter(value => value !== item.value);
    }
  };

  getVisibleIndeterminateValues = (filterString, values, indeterminateValues) =>
    !filterString
      ? indeterminateValues
      : values.filter(value => indeterminateValues.findIndex(indeterminateValue => indeterminateValue === value) > -1);

  isIndeterminateState = item =>
    this.indeterminateValuesForWatch &&
    this.indeterminateValuesForWatch.includes(item.value) &&
    !this.selectedValuesForWatch.includes(item.value);

  showNoMatchesError = () => !!this.searchValueModel && this.props.values && this.props.values.length === 0;

  selectAllDisabled = () =>
    this.showNoMatchesError() || (this.props.showLoadMoreButton && this.searchValueModel) || this.props.filterDisabled;

  getItemDisplayValue = (filterValue, fieldFormatIdentifier, fieldName) =>
    this.isBlank(filterValue)
      ? i18n.t("_Filter.BlankValue.Label_")
      : GlobalValueFormatter.formatValue(filterValue, fieldFormatIdentifier, fieldName);

  isBlank = filterValue => filterValue === "";

  escapeHtml = text => {
    const escapedHtmlText = text || "";
    return escapedHtmlText
      .replace(/&/g, "&amp;")
      .replace(/>/g, "&gt;")
      .replace(/</g, "&lt;")
      .replace(/'/g, "&apos;");
  };

  //{render html components
  renderSelectAllSection = () => (
    <div
      tabIndex="0"
      className="filter-value-select-all"
      onKeyUp={event => {
        this.onKeyupHandleSelectAllClick(event);
      }}
      role="button"
      data-testid="select-all-test-id"
    >
      <div className="filter-panel-value-select-all" state={this.getSelectAllState()}>
        <Checkbox
          onChange={() => {
            this.handleSelectAllClick();
          }}
          size="small"
          checkedState={this.getSelectAllState()}
          isDisabled={this.selectAllDisabled()}
          id="chkSelectAll"
        >
          <span>
            <b>{`(${i18n.t("_Multiselect.SelectAll.Label_")})`}</b>
          </span>
        </Checkbox>

        {this.selectAllDisabled() && !this.showNoMatchesError() ? (
          <span className="selectall-disable-tooltip">
            <ToolTip content={i18n.t("_Multiselect.selectAllSearch.InfoIcon.ToolTip_")}>
              <i className="acl-i-info-circle" />
            </ToolTip>
          </span>
        ) : null}
      </div>
    </div>
  );

  renderSearchTextSection = () => (
    <div className="search-filter">
      <i className="icon-filter position-icon-inside-input" />
      <input
        id={`filterPanel-${this.escapeHtml(this.fieldName())}-quickFilterSearchInput`}
        type="text"
        name="value"
        className="search-filter-value search-input search-filter-value-select-all"
        placeholder={this.placeholderText()}
        onChange={event => {
          this.handleSearchInputKeyUP(event, this.props);
        }}
        disabled={this.props.filterDisabled}
      />
    </div>
  );

  renderCheckboxSection = () => {
    if (this.showNoMatchesError()) {
      return;
    }
    const that = this;

    const html =
      this.multiSelectItems &&
      this.multiSelectItems.map(function(item, index) {
        return (
          <div
            role="button"
            className={`filter-panel-value ${item.checkedState === "checked" ? "filter-panel-value--is-checked" : ""}`}
            id={`filterPanel-${that.escapeHtml(that.fieldName())}-quickFilterValueToggler-${index + 1}`}
            onKeyUp={event => {
              that.onKeyupHandleCheckboxFilter(event, item);
            }}
            onClick={() => {
              that.toggleCheckboxFilterValue(item);
            }}
            tabIndex="0"
          >
            <div className="checkbox-filter__value">
              <Checkbox
                size="small"
                onChange={() => {
                  that.toggleCheckboxFilterValue(item);
                }}
                id={FilterValueService.hashCode(item)}
                checkedState={item.checkedState}
                isDisabled={that.props.filterDisabled}
              >
                <span
                  className="unique-value"
                  id={`filterPanel-${that.escapeHtml(that.fieldName())}-quickFilterValue-${index + 1}`}
                >
                  <span>
                    {that.getItemDisplayValue(item.value, that.props.fieldFormatIdentifier, that.fieldName())}
                  </span>
                </span>
                <span
                  className="value-count"
                  id={`filterPanel-${that.escapeHtml(that.fieldName())}-quickFilterValueCount-${index + 1}`}
                >
                  {`(${item.COUNT})`}
                </span>

                {that.isIndeterminateState(item) ? (
                  <ToolTip
                    content={i18n.t("_Storyboard.Post.Drilldown.IndeterminateState.ToolTip_")}
                    maxWidth="230"
                    edge="middle"
                  >
                    <span className="visual-indicator">
                      <VisualIndicator height="10px" width="10px" />
                    </span>
                  </ToolTip>
                ) : (
                  ""
                )}
              </Checkbox>
            </div>
          </div>
        );
      });
    return html;
  };

  renderLoadMoreSection = () => {
    if (this.props.showLoadMoreButton) {
      return (
        <>
          <hr className="checkbox-filter__load-more-hr" />

          <div
            id={`filterPanel-${this.escapeHtml(this.fieldName())}-load-more`}
            className="checkbox-filter__load-more"
            disabled={this.props.filterDisabled}
            onClick={event => {
              this.handleLoadMoreClick(event);
            }}
            onKeyUp={event => {
              this.onKeyupHandleLoadMore(event);
            }}
            role="button"
            tabIndex="0"
          >
            {i18n.t("_Select.LoadMore.Label_")}
          </div>
        </>
      );
    }
  };

  renderNoDataMessageSection = () =>
    this.showNoMatchesError() && !this.props.isDataLoading ? (
      <div id="filter-value-no-matches" className="filter-error-message">
        {i18n.t("_General.NoMatches.Label_")}
      </div>
    ) : null;

  renderErrorMessageSection = () =>
    this.props.errorMessage && this.props.errorMessage.length > 0 && !this.props.isDataLoading ? (
      <div id="filter-value-error" className="filter-error-message">
        <span>{this.props.errorMessage}</span>
      </div>
    ) : null;
  //}

  render() {
    if (this.props.values) {
      this.initialize();

      return (
        <>
          <div className="checkbox-item-count">
            <span>{this.getStatusTitle()}</span>
          </div>

          {this.renderSearchTextSection()}

          <div id="filter-value-list-container" className="filter-value-list-container">
            {this.renderSelectAllSection()}

            <div id="filter-value-list" className="filter-value-list filter-checkbox-item">
              {this.renderErrorMessageSection()}

              {this.renderNoDataMessageSection()}

              {this.renderCheckboxSection()}

              {this.renderLoadMoreSection()}
            </div>
          </div>
        </>
      );
    }
  }
}
