import cloneDeep from "lodash/cloneDeep";
import ApiPath from "@viz-ui/services/apiPath/apiPathService";

angular
  .module("acl.visualizer.charts.backend")
  .service(
    "CombinationChartBackendService",
    (queuedHttp, DataModel, BarChartBackendService, LineChartBackendService, Localize, DataFilter) => {
      return {
        fetchJson: async (tableId, filter, config) => {
          const myRecords = DataModel.filterConfig.myRecords();
          const openStatuses = DataModel.filterConfig.openStatuses();
          const raw = DataModel.toSaveViz();
          const requestParams = { raw, myRecords, openStatuses };
          const chartIndex = requestParams.raw.visualizations.findIndex(d =>
            angular.equals(d.config.dataConfig, config)
          );

          //get filter request body in proper format
          let barChartConfig = cloneDeep(requestParams);
          barChartConfig.raw.filterConfig = DataFilter.getFilterRequestBody(requestParams.raw.filterConfig);
          let lineChartConfig = cloneDeep(requestParams);
          lineChartConfig.raw.filterConfig = DataFilter.getFilterRequestBody(requestParams.raw.filterConfig);

          barChartConfig.raw.visualizations[chartIndex] = convertToBarChartVisualization(
            barChartConfig.raw.visualizations[chartIndex]
          );

          lineChartConfig.raw.visualizations[chartIndex] = convertToLineChartVisualization(
            lineChartConfig.raw.visualizations[chartIndex]
          );
          const [barChartResponse, lineChartResponse] = await Promise.all([
            queuedHttp.post(url(tableId, chartIndex, "combo_bar"), barChartConfig),
            queuedHttp.post(url(tableId, chartIndex, "combo_line"), lineChartConfig),
          ]);
          let combinedResponse = {
            data: {
              result: {
                data: [barChartResponse.data.result.data, lineChartResponse.data.result.data],
              },
            },
          };
          return combinedResponse;
        },

        getRepresentation: (data, dataConfig) => {
          //separating the barChart config from the dataConfig, so that it can be passed to barChart to get representations
          let barChartDataConfig = getDataConfigByType(dataConfig, "BarChart");
          //getting data from barChartBackend service
          let barChartRepresentation = BarChartBackendService.getRepresentation(data[0], barChartDataConfig);
          barChartRepresentation = barChartRepresentation.map(d => ({
            ...d,
            type: "column",
            legendDisplayName: d.key || "",
          }));

          //separating the lineChart config from the dataConfig, so that it can be passed to lineChart to get representation.
          let lineChartDataConfig = getDataConfigByType(dataConfig, "LineChart");
          let lineChartAggregationType = getLocalizedAggregationType(
            lineChartDataConfig.chartValue.aggregationType,
            lineChartDataConfig.chartValue.displayName
          );
          //getting data from lineChartBackendService.
          let lineChartRepresentation = LineChartBackendService.getRepresentation(data[1], lineChartDataConfig);
          lineChartRepresentation = lineChartRepresentation.map(d => ({
            ...d,
            type: "line",
            key: lineChartDataConfig.chartValue.displayName || lineChartDataConfig.chartValue.aggregationType, //when aggregation type is count and display name will be undefined
            legendDisplayName: lineChartAggregationType,
          }));

          //combining the representations from BarChart and lineChart to get a single representation.
          let representation = [...barChartRepresentation, ...lineChartRepresentation];
          return representation;
        },
      };

      function getDataConfigByType(dataConfig, type) {
        const chartXAxis = dataConfig.chartXAxis || dataConfig.chartRows[0];
        delete dataConfig.chartRows;
        const chartColumns = dataConfig.chartColumns ? dataConfig.chartColumns[0] : dataConfig.chartSeries;
        const { chartValues } = dataConfig;
        if (type === "BarChart") {
          let barChartDataConfig = {
            ...dataConfig,
            chartXAxis,
            chartValue: chartValues[0],
            chartSeries: chartColumns,
          };
          delete barChartDataConfig.chartValues;
          return barChartDataConfig;
        }
        if (type === "LineChart") {
          let lineChartDataConfig = { ...dataConfig, chartXAxis, chartValue: chartValues[1], chartSeries: "" };
          delete lineChartDataConfig.chartValues;
          return lineChartDataConfig;
        }
        return dataConfig;
      }

      function url(tableId, chartIndex, chartType) {
        return ApiPath.table.getSummarizeDataUrl(tableId) + "?chart_index=" + chartIndex + "&type=" + chartType;
      }

      function convertToBarChartVisualization(combinationChartVisualization) {
        let chartValue = combinationChartVisualization.config.dataConfig.chartValues[0];
        return {
          id: combinationChartVisualization.id,
          title: combinationChartVisualization.title,
          type: "BarChart",
          config: {
            displayConfig: {},
            dataConfig: {
              chartValue,
              chartXAxis: combinationChartVisualization.config.dataConfig.chartXAxis,
              chartSeries: combinationChartVisualization.config.dataConfig.chartSeries,
            },
          },
        };
      }

      function convertToLineChartVisualization(combinationChartVisualization) {
        let chartValue = combinationChartVisualization.config.dataConfig.chartValues[1];
        return {
          id: combinationChartVisualization.id,
          title: combinationChartVisualization.title,
          type: "LineChart",
          config: {
            displayConfig: {},
            dataConfig: {
              chartValue,
              chartXAxis: combinationChartVisualization.config.dataConfig.chartXAxis,
            },
          },
        };
      }

      //Due to current code limitations, we need to localize the string in backend service.
      //This would be provided as a key to lineChart and will show under color series in display config section.
      //Fix me if better approach found.
      function getLocalizedAggregationType(type, fieldName) {
        switch (type) {
          case "count":
            return Localize.getLocalizedString("_CombinationChart.Legend.Line.Count_");
          case "average":
            return Localize.getLocalizedStringWithTokenReplacement("_CombinationChart.Legend.Line.Average_", {
              fieldName,
            });
          case "sum":
            return Localize.getLocalizedStringWithTokenReplacement("_CombinationChart.Legend.Line.Sum_", { fieldName });
          case "min":
            return Localize.getLocalizedStringWithTokenReplacement("_CombinationChart.Legend.Line.Min_", { fieldName });
          case "max":
            return Localize.getLocalizedStringWithTokenReplacement("_CombinationChart.Legend.Line.Max_", { fieldName });
          default:
            return type;
        }
      }
    }
  );
