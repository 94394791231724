import ReactDirective from "acl-ui/angular/reactDirective.umd";
import aclCheckboxFilter from "./checkboxFilter.component";
import CheckboxFilterStore from "./checkboxFilterStore.service";
import checkbox from "@paprika/checkbox";
import visualIndicator from "@viz-ui/components/visualIndicator/visualIndicator";

angular
  .module("acl.visualizer.checkboxFilter", ["acl.visualizer.model.field"])
  .component("aclCheckboxFilter", aclCheckboxFilter)
  .service("CheckboxFilterStore", CheckboxFilterStore)
  .directive("checkbox", ReactDirective.getFactory(checkbox))
  .directive("visualIndicator", ReactDirective.getFactory(visualIndicator));
