import cloneDeep from "lodash/cloneDeep";

export default class ColorStops {
  static getEnsuredAscendingStopValues(colorStops) {
    const indexOfFirstStopWithValue = colorStops.findIndex(stop => isFinite(stop.value));
    if (indexOfFirstStopWithValue >= 0) {
      let min = colorStops[indexOfFirstStopWithValue].value;
      const result = cloneDeep(colorStops);
      result.slice(indexOfFirstStopWithValue + 1).forEach(stop => {
        if (isFinite(stop.value)) {
          if (stop.value <= min) {
            stop.value = ++min;
          }
          min = stop.value;
        }
      });
      return result;
    }

    return colorStops;
  }

  static getEnsuredValidNumBins(numBins, defaultNumBins = 5) {
    if (isFinite(numBins)) {
      return Math.max(Math.min(numBins, 20), 2);
    }
    return defaultNumBins;
  }
}
