import moment from "moment";
import { DateFormatter, DatetimeFormatter, NumberFormatter, TimeFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import i18n from "@viz-ui/i18n/i18n";
import isEqual from "lodash/isEqual";
import AppConfig from "@visualizer/appConfig";

class FieldFormatter {
  constructor() {
    this.unformattedValues = AppConfig.features.unformattedValues; // value will be fetched from appConfig once appConfig is migrated
  }

  isDateFormattableType = fieldType => fieldType === "date";

  getDateFormattingOptions = selectedFormatObj => {
    const that = this;
    const exampleDate = moment(new Date(new Date().getFullYear(), 0, 31)).format("MM/DD/YYYY");
    let formats = [];

    if (this.unformattedValues) {
      formats.push({ isRaw: true, dateFormat: undefined });
    }
    formats = formats.concat([
      { isRaw: false, dateFormat: undefined }, // Default format.
      { isRaw: false, dateFormat: "YYYY-MM-DD" },
      { isRaw: false, dateFormat: "MM-DD-YYYY" },
      { isRaw: false, dateFormat: "DD-MMM-YYYY" },
      { isRaw: false, dateFormat: "YYYY/MM/DD" },
      { isRaw: false, dateFormat: "MM/DD/YYYY" },
      { isRaw: false, dateFormat: "DD/MMM/YYYY" },
    ]);

    return formats.map(function(fieldFormatJson) {
      const example = fieldFormatJson.isRaw
        ? i18n.t("_Format.Date.Raw.Example_")
        : DateFormatter.format(exampleDate, fieldFormatJson);

      return {
        example: example,
        label: i18n.t(getDateFormatLabelKey(fieldFormatJson)),
        selected: that.compareFormattingOptions(fieldFormatJson, selectedFormatObj),
        format: fieldFormatJson,
      };
    });
  };

  isDatetimeFormattableType = fieldType => fieldType === "datetime";

  getDatetimeHeading = () => ({
    isHeading: true,
    label: i18n.t("_Format.Section.Label_"),
  });

  getDatetimeFormattingOptions = selectedFormatObj => {
    const that = this;
    const selectedDisplayTime = selectedFormatObj.displayTime === undefined ? true : selectedFormatObj.displayTime;
    const timeFormat = getLocalizedTimeFormat();
    let formats = [];
    if (this.unformattedValues) {
      formats.push({ isRaw: true, datetimeFormat: undefined });
    }
    formats = formats.concat([
      { isRaw: false, datetimeFormat: undefined }, // Default format.
      { isRaw: false, datetimeFormat: "YYYY-MM-DD " + timeFormat },
      { isRaw: false, datetimeFormat: "MM-DD-YYYY " + timeFormat },
      { isRaw: false, datetimeFormat: "DD-MMM-YYYY " + timeFormat },
      { isRaw: false, datetimeFormat: "YYYY/MM/DD " + timeFormat },
      { isRaw: false, datetimeFormat: "MM/DD/YYYY " + timeFormat },
      { isRaw: false, datetimeFormat: "DD/MMM/YYYY " + timeFormat },
    ]);

    return formats.map(function(fieldFormatJson) {
      const mergedFieldFormatJson = Object.assign({}, fieldFormatJson, { displayTime: selectedDisplayTime });
      const exampleDate = moment(new Date(new Date().getFullYear(), 0, 31, 22, 14, 42)).format("MM/DD/YYYY HH:mm:ss");
      const example = mergedFieldFormatJson.isRaw
        ? i18n.t("_Format.Datetime.Raw.Example_")
        : DatetimeFormatter.format(exampleDate, mergedFieldFormatJson);

      return {
        example: example,
        format: mergedFieldFormatJson,
        isHeading: false,
        label: i18n.t(getDatetimeFormatLabelKey(mergedFieldFormatJson)),
        selected: that.compareFormattingOptions(mergedFieldFormatJson, selectedFormatObj),
      };
    });
  };

  getTimeDisplayHeading = () => ({
    isHeading: true,
    label: i18n.t("_Format.Datetime.TimeSection.Label_"),
  });

  getTimeDisplayOptions = selectedFormatObj => {
    const selectedDisplayTime =
      selectedFormatObj.isRaw || (selectedFormatObj.displayTime === undefined ? true : selectedFormatObj.displayTime);
    const selectedDatetimeFormat = selectedFormatObj.datetimeFormat || "";
    const exampleDate = moment(new Date(new Date().getFullYear(), 0, 31, 22, 14, 42)).format("MM/DD/YYYY HH:mm:ss");

    return [
      {
        example: DatetimeFormatter.format(exampleDate, { datetimeFormat: selectedDatetimeFormat, displayTime: true }),
        format: {
          isRaw: selectedFormatObj.isRaw,
          datetimeFormat: selectedDatetimeFormat,
          displayTime: true,
        },
        isHeading: false,
        label: i18n.t("_Format.Datetime.Visible.Label_"),
        selected: selectedDisplayTime,
      },
      {
        example: DatetimeFormatter.format(exampleDate, {
          datetimeFormat: selectedDatetimeFormat,
          displayTime: false,
        }),
        format: {
          isRaw: selectedFormatObj.isRaw,
          datetimeFormat: selectedDatetimeFormat,
          displayTime: false,
        },
        isHeading: false,
        label: i18n.t("_Format.Datetime.Hidden.Label_"),
        selected: !selectedDisplayTime,
      },
    ];
  };

  isNumericFormattableType = fieldType => fieldType === "numeric";

  getNumericFormattingHeading = () => ({
    isHeading: true,
    label: i18n.t("_Format.Section.Label_"),
  });

  getNumericFormattingOptions = (selectedFormattingObj, selectedRoundingObj) => {
    const that = this;
    const exampleNumber = -1234;
    let optionDefs = [];
    optionDefs = optionDefs.concat([
      {
        labelKey: "_Format.Numeric.None.Label_",
        format: {
          hasOnlyRadixSeparator: true,
          thousandsDelimiter: false,
          prefix: undefined,
          sign: undefined,
          postfix: undefined,
        },
      },
      {
        labelKey: "_Format.Numeric.Number.Label_",
        format: {
          hasOnlyRadixSeparator: false,
          thousandsDelimiter: true,
          prefix: undefined,
          sign: undefined,
          postfix: undefined,
        },
      }, // Default format.
      {
        labelKey: "_Format.Numeric.Currency.Label_",
        format: {
          hasOnlyRadixSeparator: false,
          thousandsDelimiter: true,
          prefix: "$",
          sign: undefined,
          postfix: undefined,
        },
      },
      {
        labelKey: "_Format.Numeric.Financial.Label_",
        format: {
          hasOnlyRadixSeparator: false,
          thousandsDelimiter: true,
          prefix: undefined,
          sign: "(",
          postfix: undefined,
        },
      },
      {
        labelKey: "_Format.Numeric.Percent.Label_",
        format: {
          hasOnlyRadixSeparator: false,
          thousandsDelimiter: true,
          prefix: undefined,
          sign: undefined,
          postfix: "%",
        },
      },
    ]);

    return optionDefs.map(function(optionDef) {
      // The displayed formatting options should incorporate THIS formatting option, plus the selected ROUNDING option.
      const fieldFormatJson = Object.assign({}, selectedRoundingObj, optionDef.format);
      const example = NumberFormatter.format(exampleNumber, fieldFormatJson);

      return {
        example: example,
        format: fieldFormatJson,
        isHeading: false,
        label: i18n.t(optionDef.labelKey),
        selected: that.compareFormattingOptions(optionDef.format, selectedFormattingObj),
      };
    });
  };

  getNumericRoundingHeading = () => ({
    isHeading: true,
    label: i18n.t("_Format.Numeric.RoundingSection.Label_"),
  });

  getNumericRoundingOptions = (selectedRoundingObj, selectedFormattingObj) => {
    const that = this;
    const exampleNumber = 1234.567;
    const optionDefs = [
      {
        labelKey: "_Format.Numeric.Exact.Label_",
        format: { precision: 5, keepTrailingZeros: false, abbreviate: false },
      },
      {
        labelKey: "_Format.Numeric.2Decimals.Label_",
        format: { precision: 2, keepTrailingZeros: true, abbreviate: false },
      },
      {
        labelKey: "_Format.Numeric.NoDecimals.Label_",
        format: { precision: 0, keepTrailingZeros: false, abbreviate: false },
      },
      {
        labelKey: "_Format.Numeric.Rounded.Label_",
        format: { precision: 2, keepTrailingZeros: false, abbreviate: true },
      },
    ];

    return optionDefs.map(function(optionDef) {
      // The displayed rounding options should incorporate THIS rounding option, plus the selected FORMAT option.
      const mergedFieldFormatJson = Object.assign({}, selectedFormattingObj, optionDef.format);

      return {
        example: NumberFormatter.format(exampleNumber, mergedFieldFormatJson),
        format: mergedFieldFormatJson,
        isHeading: false,
        label: i18n.t(optionDef.labelKey),
        selected: that.compareRoundingOptions(optionDef.format, selectedRoundingObj),
      };
    });
  };

  getTimeFormattingOptions = selectedFormattingObj => {
    const that = this;
    if (!this.unformattedValues) {
      return [];
    }

    const exampleTime = moment(new Date(new Date().getFullYear(), 0, 31, 17, 30, 0)).format("H:mm:ss");
    const formats = [
      { isRaw: true, timeFormat: undefined },
      { isRaw: false, timeFormat: getLocalizedTimeFormat() }, // Default format.
    ];

    return formats.map(function(fieldFormatJson) {
      const example = fieldFormatJson.isRaw
        ? i18n.t("_Format.Time.Raw.Example_")
        : TimeFormatter.format(exampleTime, fieldFormatJson);

      return {
        example: example,
        label: i18n.t(getTimeFormatLabelKey(fieldFormatJson)),
        selected: that.compareFormattingOptions(fieldFormatJson, selectedFormattingObj),
        format: fieldFormatJson,
      };
    });
  };

  compareFormattingOptions = (a, b) => {
    if (a && b) {
      if (a.isRaw === true && b.isRaw === true) {
        return true;
      }

      return (
        (a.hasOnlyRadixSeparator === true) === (b.hasOnlyRadixSeparator === true) &&
        ((!a.dateFormat && !b.dateFormat) || a.dateFormat === b.dateFormat) &&
        ((!a.datetimeFormat && !b.datetimeFormat) ||
          extractDateFormat(a.datetimeFormat) === extractDateFormat(b.datetimeFormat)) &&
        ((!a.postfix && !b.postfix) || a.postfix === b.postfix) &&
        ((!a.prefix && !b.prefix) || a.prefix === b.prefix) &&
        ((a.sign === "(" && b.sign === "(") || (a.sign !== "(" && b.sign !== "("))
      );
    }

    return isFormatUndefined(a) && isFormatUndefined(b);
  };

  compareRoundingOptions = (a, b) =>
    (a &&
      b &&
      a.precision === b.precision &&
      ((!a.keepTrailingZeros && !b.keepTrailingZeros) || a.keepTrailingZeros === b.keepTrailingZeros) &&
      ((!a.abbreviate && !b.abbreviate) || a.abbreviate === b.abbreviate)) ||
    ((a === undefined || a === null || isEqual(a, empty)) && (b === undefined || b === null || isEqual(b, empty)));
}

const empty = {};

const getDateFormatLabelKey = fieldFormatJson => {
  const template = key => `_Format.Date.${key}.Label_`;
  if (fieldFormatJson) {
    if (fieldFormatJson.isRaw) {
      return template("Raw");
    }

    if (fieldFormatJson.dateFormat) {
      return template(fieldFormatJson.dateFormat);
    }
  }
  return template("Default");
};

const getLocalizedTimeFormat = () => i18n.t("_Format.Time.Default.Config_");

const getDatetimeFormatLabelKey = fieldFormatJson => {
  const template = key => `_Format.Datetime.${key}.Label_`;
  if (fieldFormatJson) {
    if (fieldFormatJson.isRaw) {
      return template("Raw");
    }

    if (fieldFormatJson.datetimeFormat) {
      return template(extractDateFormat(fieldFormatJson.datetimeFormat));
    }
  }
  return template("Default");
};

const extractDateFormat = formatString => {
  if (typeof formatString === "undefined") {
    return undefined;
  }
  return formatString.split(" ")[0];
};

const getTimeFormatLabelKey = fieldFormatJson => {
  const template = key => `_Format.Time.${key}.Label_`;
  if (fieldFormatJson) {
    if (fieldFormatJson.isRaw) {
      return template("Raw");
    }
  }
  return template("Default");
};

const isFormatUndefined = v => v === undefined || v === null || isEqual(v, empty);

export default new FieldFormatter();
