/**
 * @name acl-checklist-checkbox-input directive
 *
 * @description
 * This is used within the acl-checklist-input directive.
 * Might be usable standalone, I don't know - haven't tested!
 *
 * Mostly what it's doing is:
 * - Sets up the UI inputs the way we want (mouse click or space bar to toggle, Enter will submit
 *   the form (this individual param input). One extra step also to prevent mouse click from focusing on button.
 * - Sets up the connections between the model value (true/false) and what to show in the UI (adding the "checked"
 *   class to the <i> element).
 * - Creates a $setPristine() function on the ng-model controller so that when the form is $setPristine, this will be too
 *
 * The value to show next the checkbox is provided via transclude... but I've only tested it with text, I don't
 * know what happens if you do more complex html.
 */
angular.module("acl.common.input").directive("aclChecklistCheckboxInput", function() {
  return {
    restrict: "E",
    require: "ngModel",
    replace: true,
    transclude: true,
    template:
      '<button class="no-decoration aclviz-checkbox"  type="button">' +
      '<i class="aclviz-checkbox-image icon_check"></i>' +
      '<span class="aclviz-checkbox-label" ng-transclude></span>' +
      "</button>",
    link: function(scope, element, attr, ngModelCtrl) {
      var iconElement;

      // Add $setPristine so that it will be called by form.$setPristine
      ngModelCtrl.$setPristine = function() {
        setValue(false);
      };

      iconElement = element.children("i"); // Get a reference to the icon, so we can toggle its class

      // Model->UI
      ngModelCtrl.$render = function() {
        iconElement.toggleClass(getCheckedClass(), angular.equals(ngModelCtrl.$modelValue, true));
      };

      // UI->Model
      element.bind("click", function() {
        toggleValue();
      });

      element.bind("submit", function(e) {
        // Stop the button from submitting when clicking spacebar - we want spacebar to toggle but not submit
        e.preventDefault();
      });

      element.bind("keypress", function(e) {
        var spacebar = 32;
        var enter = 13;

        switch (e.which) {
          case spacebar:
            // No need to trigger 'click' here, since browser makes button do that by default
            break;
          case enter:
            // Prevent 'Enter' from making the button send a 'click' event.
            // Instead, trigger a 'submit' event (so that 'Enter' will continue)
            e.preventDefault();
            element.trigger("submit");
            break;
          default:
        }
      });

      element.bind("mousedown", function(e) {
        // Don't let the button take focus on mousedown (otherwise, makes user see the 'focus' highlight
        e.preventDefault();
      });

      function toggleValue() {
        scope.$apply(function() {
          setValue(!iconElement.hasClass(getCheckedClass()));
        });
      }

      function setValue(isChecked) {
        ngModelCtrl.$setViewValue(isChecked);
        ngModelCtrl.$render();
      }

      function getCheckedClass() {
        return "checked";
      }
    },
  };
});
