angular
  .module("acl.common.sessionManager", ["acl.common.config", "ui.bootstrap"])
  .config(function($provide, $httpProvider) {
    $provide.factory("ExpiredSessionInterceptor", function($q, EventService) {
      var eventService = EventService.register("app.Interceptor");
      return {
        responseError: function(rejection) {
          if (rejection.status === 499) {
            eventService.publish("saveViz.vizClean");
            eventService.publish("session.expired");
          }
          return $q.reject(rejection);
        },
      };
    });
    $httpProvider.interceptors.push("ExpiredSessionInterceptor");
  });
