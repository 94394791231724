import ReactDirective from "acl-ui/angular/reactDirective.umd";
import SwitchItem from "@viz-ui/components/switchItem/switchItem.jsx";

angular
  .module("acl.visualizer.summaryTable.dataConfig", [
    "acl.common.localize",
    "acl.visualizer.dataModel",
    "acl.visualizer.multiSelect",
    "templates-visualizer",
  ])
  .directive("switchItem", ReactDirective.getFactory(SwitchItem));
