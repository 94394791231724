angular.module("acl.common.globalNav").component("aclGlobalNavHeader", {
  bindings: {
    backButtonTip: "<",
    onHomeButtonClick: "&",
    onHighbondButtonClick: "&",
    onBackButtonClick: "&",
    interpretationTitle: "<",
    showSummaryButton: "<",
    showArchivedPill: "<",
    onSummaryButtonClick: "&",
  },
  controller: function HeaderCtrl(AppConfig, Localize, $window) {
    const globalNav = this;

    globalNav.appName = AppConfig.application.name;
    globalNav.galvanizeHighbond = AppConfig.features.galvanizeHighbond;

    globalNav.onHelpClick = () => {
      const helpLink = globalNav.galvanizeHighbond
        ? Localize.getLocalizedString("_App.Nav.HelpLink_")
        : Localize.getLocalizedString("_App.Nav.HelpLink.ACL_");
      $window.open(helpLink, "_blank");
    };
  },
  controllerAs: "globalNav",
  templateUrl: "visualizer/js/modules/core/globalNavHeader/globalNavHeader.tpl.html",
});
