/* eslint angular/controller-as: 0 */
import cloneDeep from "lodash/cloneDeep";
import uniq from "lodash/uniq";
import DialogActions from "acl-ui/components/DialogActions";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import tableCapability from "@visualizer/modules/core/tableCapability/tableCapability.service";
import getDataWithRowIndex from "@visualizer/common/dataTable/dataTableHelper";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";
import { focusProcessPanelTitle } from "@viz-ui/components/RecordProcessPanel/Utils/RecordProcessingHelpers";
import StoryBoardDrilldownService from "@viz-ui/services/storyboardDrilldown/storyboardDrilldownService";
import logger from "@viz-ui/services/logger/logger";
import SessionStorageHelper from "@viz-ui/services/sessionStorage/sessionStorageHelper";
import CopyInterpretationService from "@viz-ui/services/copyInterpretation/copyInterpretationService";
import {
  getStoryboardsSPABasePath,
  getLoganBasePath,
} from "@viz-ui/services/storyboardUrlDetection/storyboardUrlDetection";
import ConditionalFormatting from "@viz-ui/services/quickMenu/conditionalFormat/conditionalFormattingService";
import ConfirmLeave from "@viz-ui/services/common/confirmLeaveService";
import LoadingAnimationState from "@viz-ui/services/common/loadingAnimationStateService";
import VisualizerAbilities from "@viz-ui/services/abilities/visualizerAbilitiesService";
import ApiPath from "@viz-ui/services/apiPath/apiPathService";
import backendApi from "@results/services/apiCall/backendApi";
import addScript from "@results/services/addScripts/addScripts";

angular
  .module("acl.visualizer.dataVisualizer")
  .controller("VisualizerController", function(
    $document,
    $modal,
    $q,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $window,
    AppConfig,
    AsyncCallManager,
    BiViewService,
    ChartService,
    ChartTab,
    Csv,
    DataFilter,
    DataModel,
    DataTableService,
    EventService,
    FieldFormat,
    FieldAdapter,
    FieldValueStore,
    HighbondNavigationService,
    HistogramService,
    Localize,
    LocationManager,
    ProcessedDataHandler,
    SaveViz,
    Sorter,
    StatisticsService,
    TabStateService,
    UserAgent,
    Visualizer,
    downloadReportBackend
  ) {
    ApiPath.initialize(AppConfig);
    LocationManager.reloadOnStateChanges(true);

    const WINDOW_RESIZE_DELAY = 600;
    const RECORD_ROW_COUNT = 100000;

    const chartTab = ChartTab.getInstance();
    chartTab.setCallbackInterface(createChartTabCallbackInterface());

    const eventService = EventService.register("dataVisualizer.VisualizerController", $scope);
    $scope.$on("$destroy", () => {
      eventService.unregister();
    });

    SaveViz.setInitializing(true);

    logger.setFlipper(AppConfig.features.debugLogger);
    logger.log(">>>>>> Visualizer App started");

    eventService.subscribe("notify", (_, type, data, fade) => {
      //FIXME these messages do not stack; any previous warnings would disappear
      $scope.setMessage({ type: type, content: data }, fade);
    });

    eventService.subscribe("filterPanel.filterChange", (event, filterConfig, noReload, isApplyFilter) => {
      if (!$scope.isFilterPanelOpen && !isApplyFilter) {
        $scope.toggleFilterPanel();
      }

      if (noReload || !validateFilters()) return;
      reloadTabs(true, false, "filterChange");
    });

    //chart drilldown event from chartbase container for react chart
    eventService.subscribe("onHighChartZoomIn", (_, filters, zoominFromCharts) => {
      isZoomingInFromChart = zoominFromCharts;
      eventService.publish("setQuickFilters", filters);
    });

    $scope.noTransition = UserAgent.isIE();

    function reloadTabs(forceReloadTableTab = true, forceReloadChartTabs = false, triggeredFrom = null) {
      reloadTableTab(forceReloadTableTab, triggeredFrom);
      reloadChartTabs(forceReloadChartTabs);
    }

    function reloadTableTab(forceReload = true, triggeredFrom = null) {
      const tableTabIndex = 0;
      TabStateService.reload(tableTabIndex, forceReload, triggeredFrom);
    }

    function reloadChartTabs(forceReload = false) {
      const tableTabIndex = 0;
      TabStateService.reloadAllExcept([tableTabIndex], forceReload);
    }

    function validateFilters() {
      var filtersValid =
        DataModel.filtersValid() && !DataFilter.hasInvalidFilters(DataModel.getFilterConfigDeprecated());
      var allFieldsValid = !DataModel.filterConfig.hasDeletedField();

      if (!filtersValid) {
        $scope.setMessage(
          {
            type: "warning",
            content: Localize.getLocalizedString("_FilterConfig.Invalid.Error_"),
          },
          true
        );
      }

      if (!allFieldsValid) {
        $scope.setMessage({
          type: "error",
          content: Localize.getLocalizedString("_FilterConfig.DeletedField.Error_"),
        });
      }

      return filtersValid && allFieldsValid;
    }

    $scope.returnUrl = $stateParams.returnUrl;
    $scope.isNew = !$stateParams.savedVizId;
    $scope.hasUnsavedChanges = false;
    $scope.showCopyInterpretation = AppConfig.features.copyInterpretation;
    $scope.showExportToXls = AppConfig.features.exportToXls;
    $scope.isExportToCsvEnabled = false;
    $scope.isExportToXlsEnabled = false;
    $scope.isProcessingXls = false;
    $scope.disableAllButtons = false;
    $scope.isCopyInterpretationOpen = false;
    $scope.interpretationId = $stateParams.savedVizId;
    $scope.saveDefaultConfirmation = isStateRemediation();
    $scope.showOnPremiseVizNavBar = AppConfig.features.showOnPremiseVizNavBar;
    $scope.showHighbondNavigation = AppConfig.features.highbondNavigationInVisualizer;

    $scope.pageHeaderProps = AppConfig.pageHeaderProps;
    $scope.copyInterpretationsCollections = [];
    $scope.copyInterpretationConfig = {};
    $scope.isCopyInterpretationDisabled = false;
    $scope.isCopyInterpretationToast = false;
    $scope.copyInterpretationsErrorMessages = [];
    $scope.isRenameInterpretationDisabled = false;
    $scope.isUserPermittedToRenameInterpretation = false;
    $scope.renameInterpretationFailedCount = 0;
    $scope.migrateChartPickerModal = AppConfig.features.migrateChartPickerModal;
    $scope.enableRecordCountFlipper = AppConfig.features.enableRecordCount;

    // results impact report toggle
    $scope.reportTypes = [];
    $scope.showReportDownload = AppConfig.features.showReportDownload;
    $scope.isReportDownloadPanelOpen = false;
    $scope.isReportDownloadModalOpen = false;
    $scope.isReportDownloadDisabled = () =>
      AppConfig.features.showReportDownload && DataModel.table.filteredRecordCount() === 0;
    $scope.openStatuses = [];

    initGlobalNavBar();

    async function initGlobalNavBar() {
      try {
        addScript(`${backendApi.getWebComponentsUrl()}/global-navigator/index.js`);
        $scope.highbondNavBarProps = await HighbondNavigationService.getGlobalNavProps();
        initReportEssentials($scope.highbondNavBarProps.appSwitcherProps.initialOrganizationId);
      } catch (ex) {
        $scope.setMessage({
          type: "error",
          content: Localize.getLocalizedString("_Chart.LoadFailed.Error_"),
        });
      }
    }

    async function initCopyInterpretationsCollections() {
      try {
        const vizConfigs = $scope.copyInterpretationConfig();
        if ($scope.copyInterpretationsCollections.length === 0)
          $scope.copyInterpretationsCollections = await CopyInterpretationService.getCopyInterpretationsCollections(
            vizConfigs
          );
      } catch (ex) {
        $scope.setMessage({
          type: "error",
          content: Localize.getLocalizedString("_Chart.LoadFailed.Error_"),
        });
      }
    }

    DataModel.subscribe("interpretation.title", updateTitle);
    DataModel.subscribe("table.displayName", updateTitle);

    function updateTitle() {
      var title = DataModel.interpretation.title();
      if (title) {
        $scope.title = title;
      } else {
        $scope.title = DataModel.table.displayName();
      }

      if ($scope.title) {
        var platformName = AppConfig.features.galvanizeHighbond ? "HighBond" : "ACL";
        $document.title = `${platformName} - ${$scope.title}`;
      }
    }

    $scope.enableDisableCopyInterpretation = () => {
      VisualizerAbilities.fetchAbilities().then(
        () => ($scope.isCopyInterpretationDisabled = !VisualizerAbilities.getAbilities().can_create_interpretations)
      );

      $scope.copyInterpretationTooltip = "";
      if ($scope.isCopyInterpretationDisabled) {
        $scope.copyInterpretationTooltip = Localize.getLocalizedString("_Button.CopyInterpretation.Disable.Tooltip_");
      } else if (isNewInterpretation()) {
        $scope.copyInterpretationTooltip = Localize.getLocalizedString("_CopyInterpretation.IsNew.Disable.Tooltip_");
      }

      return $scope.isCopyInterpretationDisabled || isNewInterpretation();
    };

    $scope.toggleRenameInterpretation = () => {
      VisualizerAbilities.fetchAbilities().then(abilities => {
        $scope.isRenameNotPermitted = !VisualizerAbilities.getAbilities().can_create_interpretations;
        if (abilities) {
          if (isNewInterpretation()) {
            $scope.renameInterpretationTooltip = Localize.getLocalizedString("_RenameInterpretation.Disable.Tooltip_");
          }
          $scope.showRenameInterpretation =
            AppConfig.features.renameInterpretation && !$scope.isRenameNotPermitted && !$stateParams.remediation;
          $scope.isRenameInterpretationDisabled = $scope.isRenameNotPermitted || isNewInterpretation();
        }
      });
    };

    function isNewInterpretation() {
      return $scope.isNew && !$stateParams.remediation;
    }

    function updateExportToXls() {
      $scope.isExportToXlsEnabled =
        AppConfig.features.exportToXls &&
        DataModel.table.filteredRecordCount() !== 0 &&
        DataModel.table.filteredRecordCount() <= RECORD_ROW_COUNT;
    }

    function updateExportToCsv() {
      $scope.isExportToCsvEnabled =
        AppConfig.features.exportToCsv &&
        DataModel.table.filteredRecordCount() !== 0 &&
        DataModel.table.filteredRecordCount() <= RECORD_ROW_COUNT;
      $scope.exportToCsvTooltip =
        !AppConfig.features.exportToCsv && AppConfig.application.name === "AX"
          ? Localize.getLocalizedString("_Button.Export.Csv.Disable.Tooltip_")
          : Localize.getLocalizedString("_Buttons.Overflow.Disable.Tooltip_");
    }

    eventService.subscribe("saveViz.vizDirty", () => {
      $scope.hasUnsavedChanges = true;
      ConfirmLeave.on();
    });

    eventService.subscribe("saveViz.vizClean", () => {
      ConfirmLeave.off();
    });

    eventService.subscribe("saveViz.updateSuccess", () => {
      $scope.hasUnsavedChanges = false;
    });

    $scope.saveButtonTip = () => {
      if ($scope.isNew || $scope.hasUnsavedChanges) return "";
      return Localize.getLocalizedString("_ActionButtons.SaveNothing.Tooltip_");
    };

    initSideNav();

    function initSideNav() {
      const element = angular.element(".visualizer__side-nav")[0];
      if ($scope.showHighbondNavigation && $window.renderReactSideSplit) {
        $window.renderReactSideSplit(element);
      }

      const sideNavSelector = $scope.showHighbondNavigation ? ".visualizer__side-nav" : ".results-nav-wrapper";
      $(sideNavSelector).on("click", "a", e => {
        if (canLeave()) {
          ConfirmLeave.off();
        } else {
          e.preventDefault();
          const url = $(e.currentTarget).attr("href");
          showConfirmationModal(url);
        }
      });
    }

    eventService.subscribe("biView.chartClick", (event, fieldName) => {
      const fieldData = DataModel.table.field(fieldName);
      const histogramConfig = HistogramService.createHistogramViewConfig(fieldData);

      createVisualizationFromBiView("BarChart", histogramConfig);

      eventService.publish("quickMenu.close");
    });

    eventService.subscribe("biView.statisticsClick", (event, fieldName) => {
      const fieldData = DataModel.table.field(fieldName);
      const statisticsConfig = StatisticsService.createStatisticsViewConfig(fieldData);

      createVisualizationFromBiView("StatisticsViz", statisticsConfig);

      eventService.publish("quickMenu.close");
    });

    function createVisualizationFromBiView(chartType, config) {
      const vizConfig = BiViewService.prepareVizConfig(chartType, config);

      chartTab.openNewVisualization(vizConfig);
    }

    eventService.subscribe("biView.deleteField", (event, fieldName) => {
      var fieldData = DataModel.table.field(fieldName);
      eventService.publish("quickMenu.close");
      LoadingAnimationState.start("deleteField");

      DataTableService.deleteField(fieldData)
        .then(
          () => {
            reinitTableData();
          },
          () => {
            $scope.setMessage({
              type: "error",
              content: Localize.getLocalizedString("_Table.FieldDeleteFail.Error_"),
            });
          }
        )
        .finally(() => {
          LoadingAnimationState.stop("deleteField");
        });
    });

    eventService.subscribe("dataTable.formattingChange", () => {
      GlobalFieldFormatMap.setFieldFormats($scope.interpretationId, DataModel.tableConfig.getFieldFormatModelsByName());
    });

    Visualizer.initLoadingAnimationState();

    $scope.msgs = [];
    let tabs = [];
    $scope.isTabUpdated = false;
    $scope.getTabs = () => tabs;
    $scope.numTabs = () => tabs.length;
    $scope.tabProps = {
      0: {
        data: [],
      },
    };
    $scope.lastRecNo = 0;

    $scope.showLoadingSpinner = LoadingAnimationState.isActive;

    $scope.showChangeSourceTableLink = Visualizer.canLinkMostRecentTable();

    const dataTableTabConfig = {
      id: "Data table tab",
      title: Localize.getLocalizedString("_Tabs.Table.Label_"),
      data: {
        contentUrl: "visualizer/views/dataTablePanel.html",
        tabIcon: "data-table",
      },
      callbackInterface: createDataTableTabCallbackInterface(),
    };

    function updateTabs(_dataTableTabConfig, chartTabConfigs) {
      let tabConfigs = [_dataTableTabConfig];
      if (chartTabConfigs) {
        tabConfigs = tabConfigs.concat(chartTabConfigs);
      }

      const selectedTabIndex = DataModel.interpretation.currentTabIndex();
      tabs = tabConfigs.map((tabConfig, index) => ({
        id: tabConfig.id,
        active: index === selectedTabIndex,
        data: tabConfig.data,
        title: tabConfig.title,
        callbackInterface: tabConfig.callbackInterface,
        tabIndex: index,
      }));
    }

    updateTabs(dataTableTabConfig);

    function createDataTableTabCallbackInterface() {
      return {
        onReload: () => {
          if (DataModel.table.metadataLoaded()) {
            clearTableData();
            loadTableData();
          }
        },
        onScrollToBottom: () => {
          logger.log(">>>>>> Data visualizer onScrollToBottom");
          loadTableData();
        },
        onDeleteRows: async (recordIds, mode) => {
          LoadingAnimationState.start("deleteRows");
          deleteRowsFromDataModel(recordIds, mode);
          eventService.publish("dataTable.resetCheckedRows");
          await loadRowsAfterRemove(recordIds.length);

          reloadAllColumnValues();
          LoadingAnimationState.stop("deleteRows");
        },
        onAddComment: commentCounts => {
          setTabProps(0, { commentCounts });
        },
        onBulkUpdate: (tableRefresh, message, messageType, hardTableRefresh, isDeletion) => {
          if (LoadingAnimationState.isActive && isDeletion) {
            LoadingAnimationState.stop("deleteInProgress");
          }
          if (tableRefresh) {
            if (hardTableRefresh) {
              reinitTableData();
            } else {
              reloadTabs();
              reloadAllColumnValues();
              updateCommentCounts();
            }
          }

          if (message && messageType) {
            $scope.setMessage({
              type: messageType,
              content: message,
            });
          }
        },
        onDeleteInProgress: message => {
          LoadingAnimationState.start("deleteInProgress");
          $scope.setMessage({
            type: "warning",
            content: message,
          });
        },
        onProcessRecord: async (processed, rowIdKey, newFieldData, removeFromView) => {
          LoadingAnimationState.start("processRecord");

          const filterConfig = DataModel.getFilterConfigDeprecated();

          const sortFieldName = filterConfig.sortField ? filterConfig.sortField.field : "";
          const filterFieldNames = filterConfig.filterList ? filterConfig.filterList.map(filter => filter.name) : [];
          const onlyMyRecordFilter = filterConfig.myRecords;
          const onlyOpenFilter = filterConfig.openStatuses;

          const isNewField = !DataModel.table.fieldsExist(Object.keys(newFieldData));
          const isSortedField = Object.prototype.hasOwnProperty.call(newFieldData, sortFieldName);
          const isFilteredField =
            filterFieldNames.some(fieldName => Object.prototype.hasOwnProperty.call(newFieldData, fieldName)) ||
            (onlyMyRecordFilter && typeof newFieldData["metadata.assignee"] !== "undefined") ||
            (onlyOpenFilter && typeof newFieldData["metadata.status"] !== "undefined");

          if (isNewField) {
            reinitTableData();
          } else {
            const rowId = processed[0];
            if (typeof rowId !== "undefined") {
              DataModel.table.updateRecordBy(rowIdKey, rowId, newFieldData);
              if (isSortedField || isFilteredField) {
                await ProcessedDataHandler.correctTableDataAfterProcess(rowId, $scope.lastRecNo);
              }
              updateTableRows();
              reloadChartTabs();
            }

            if (removeFromView && removeFromView.length > 0) {
              deleteRowsFromDataModel(removeFromView, "deleteOne");
              await loadRowsAfterRemove(removeFromView.length);
            }
          }

          reloadAllColumnValues();
          LoadingAnimationState.stop("processRecord");
        },

        disableAllButtons: () => {
          $scope.disableAllButtons = true;
        },
        enableAllButtons: () => {
          $scope.disableAllButtons = false;
        },
      };
    }

    const setVisualizationsFormatMaps = visualizations => {
      if (visualizations) {
        visualizations.forEach(visualization => {
          const { id: vizId, config } = visualization;
          if (config.displayConfig && config.displayConfig.valueFormattingOptions) {
            const fieldFormat = FieldFormat.fromJson(visualization.config.displayConfig.valueFormattingOptions);
            const valueFormatFieldName = `${vizId}-chart-value`;
            let interpretationId = $scope.interpretationId ? $scope.interpretationId : "default";
            GlobalFieldFormatMap.setFieldFormat(interpretationId, valueFormatFieldName, fieldFormat);
            GlobalFieldFormatMap.setFieldType(interpretationId, valueFormatFieldName, "numeric");
          }
          if (config.displayConfig && config.displayConfig.valueFormattingOptionsFirstYAxis) {
            const fieldFormat = FieldFormat.fromJson(
              visualization.config.displayConfig.valueFormattingOptionsFirstYAxis
            );
            const valueFormatFieldName = `${vizId}-chart-value-FirstYAxis`;
            let interpretationId = $scope.interpretationId ? $scope.interpretationId : "default";
            GlobalFieldFormatMap.setFieldFormat(interpretationId, valueFormatFieldName, fieldFormat);
            GlobalFieldFormatMap.setFieldType(interpretationId, valueFormatFieldName, "numeric");
          }
          if (config.displayConfig && config.displayConfig.valueFormattingOptionsSecondYAxis) {
            const fieldFormat = FieldFormat.fromJson(
              visualization.config.displayConfig.valueFormattingOptionsSecondYAxis
            );
            const valueFormatFieldName = `${vizId}-chart-value-SecondYAxis`;
            let interpretationId = $scope.interpretationId ? $scope.interpretationId : "default";
            GlobalFieldFormatMap.setFieldFormat(interpretationId, valueFormatFieldName, fieldFormat);
            GlobalFieldFormatMap.setFieldType(interpretationId, valueFormatFieldName, "numeric");
          }
        });
      }
    };

    if (!isStoryboardDrilldown()) {
      initDataVisualizer();
    } else {
      const storyboardDrilldownStorageKey = getStoryboardDrilldownSessionStorageKey();
      if (!SessionStorageHelper.get(storyboardDrilldownStorageKey)) {
        let initiateDrilldown = true;
        let storyboardsSPAOrigin;
        let storyboardsLoganOrigin;
        if (AppConfig.environment !== AppConfig.environments.DEV) {
          storyboardsSPAOrigin = getStoryboardsSPABasePath();
          storyboardsLoganOrigin = getLoganBasePath();
        } else {
          storyboardsSPAOrigin = storyboardsLoganOrigin = $window.storyboardAppUrl;
        }

        //It's used to trigger from storyboard app.
        $window.addEventListener(
          "message",
          function(event) {
            if (
              (event.origin == storyboardsSPAOrigin || event.origin == storyboardsLoganOrigin) &&
              event.data.drilldownNotifier &&
              initiateDrilldown
            ) {
              initiateDrilldown = false;
              event.source.postMessage({ stopDrilldownNotifier: true }, event.origin); //Notifying storyboard app to stop the message event.
              SessionStorageHelper.set(storyboardDrilldownStorageKey, event.data.drilldownFilters);
              SessionStorageHelper.set(event.data.storyboardId, event.data.storyboardName);
            }
          },
          false
        );
      }
      initDataVisualizer(storyboardDrilldownStorageKey);
    }

    function initDataVisualizer(storyboardDrilldownStorageKey) {
      Visualizer.initDataVisualizer($stateParams, storyboardDrilldownStorageKey).then(
        message => {
          // Remediation default savedVizId is not explicit and is set after attempting
          // to load the default view.
          $scope.isNew = !SaveViz.savedVizId;
          $scope.savedViz = DataModel.toSaveViz();
          setVisualizationsFormatMaps($scope.savedViz.visualizations);
          $scope.isArchived = DataModel.project.archived();
          $scope.interpretationId = SaveViz.savedVizId;

          setTimezoneOffset();
          handleJumpTab();

          $scope.toggleRenameInterpretation();

          if (isStatePublic()) {
            DataModel.filterConfig.openStatuses(true);
            DataModel.filterConfig.myRecords(true);
          }

          if (AppConfig.features.remediationColumns) {
            updateCommentCounts();
          }

          initTableData();

          const sortFieldId = DataModel.filterConfig.sortFieldId();
          const sortOrder = DataModel.filterConfig.sortOrder();
          eventService.publish("dataTable.sortChange", sortFieldId, sortOrder);

          // set message to display
          if (message.type && message.content) {
            if (message.type === "success" && $stateParams.saved) {
              $scope.setMessage({
                type: "success",
                header: $stateParams.saved,
                content: Localize.getLocalizedString("_SaveViz.Save.Success.Message_"),
              });
              LocationManager.setLocation()
                .search("saved", null)
                .replace();
            } else {
              $scope.setMessage(message);
            }
          }

          $scope.isFilterPanelOpen = DataModel.filtersOpen();

          if (isStoryboardDrilldown()) {
            eventService.publish("saveViz.vizClean");
          }
        },
        message => {
          $scope.savedViz = DataModel.toSaveViz();
          if (message && message.errorCode === "table.not.exist") {
            // open change source modal error was table not exist
            var modalMessage = Localize.getLocalizedString(
              "_SaveViz.SourceTable.TableId.NotFound.Modal.Intro.Message_"
            );
            $scope.openChangeSourceModal(modalMessage);
            SaveViz.setSourceTableExist(false);
          }
          // error in init, set error message.
          $scope.setMessage(message);
        }
      );
    }

    if (isStateRemediation()) {
      DataModel.filterConfig.openStatuses(true);
      DataModel.filterConfig.myRecords(false);
    }

    if (isStateRemediation()) {
      if (AppConfig.features.saveDefault) {
        $scope.hasSaveButton = () => !!AppConfig.ability && AppConfig.ability.canSaveDefault();
      } else {
        $scope.hasSaveButton = () => false;
      }
    } else {
      $scope.hasSaveButton = () => !!AppConfig.ability && AppConfig.ability.canCreateInterpretations();
    }

    $scope.hasSaveAsButton = () => !!AppConfig.ability && AppConfig.ability.canSaveAsInterpretation();
    $scope.hasCreateVizButton = () => !!AppConfig.ability && AppConfig.ability.canCreateVisualization();

    // whereas 'save' saves the default 'save as' give user opportunity to
    // create a new interpretation based on the default
    $scope.saveAsButtonEnabled = () => (isRemediation() ? true : !$scope.isNew);

    $scope.hasFieldError = () =>
      DataModel.filterConfig.hasDeletedField() || DataModel.filterConfig.hasFieldTypeMismatch();

    function setTimezoneOffset() {
      if (AppConfig.features.timezoneShifting && AppConfig.timezoneOffsetSeconds) {
        GlobalValueFormatter.setUtcOffset(AppConfig.timezoneOffsetSeconds);
      }
    }

    function updateCommentCounts() {
      DataTableService.loadCommentCounts().then(({ data }) => {
        setTabProps(0, {
          commentCounts: data,
        });
      });
    }

    function reinitTableData() {
      // So changes to the DataModel.saveViz don't cause dirty state.
      SaveViz.setInitializing(true);
      DataModel.table.reset();
      reloadAllColumnValues();
      ProcessedDataHandler.resetProcessed();

      initTableData();
    }

    function initTableData() {
      LoadingAnimationState.start("initTableData");

      // some saveViz have params set to lazyload position when saved. Always
      // want to start a beginning of table_data
      DataModel.filterConfig.resetParams();

      setTabProps(0, {
        fieldFormatIdentifier: $scope.interpretationId,
      });

      const tableId = DataModel.table.id();
      DataTableService.loadTableMetadata(tableId)
        .then(metadata => {
          sanitizeFilterConfig();
          tableCapability.setData(metadata.capabilities);
          $scope.filterPanelProps.canRemediateRecords = tableCapability.canRemediateRecords();

          DataTableService.loadTableData(false).then(response => {
            response.metadata = metadata;
            updateTableData(response);
            updateGlobalFieldTypes();
            eventService.publish("tableDataLoaded");
          }, loadTableDataError);
        })
        .catch(loadTableDataError)
        .finally(() => {
          LoadingAnimationState.stop("initTableData");
        });
      $scope.isTabUpdated = true;
    }

    // fetches record statuses and report types for results impact report.
    async function initReportEssentials(OrgId) {
      $scope.reportTypes = await downloadReportBackend.getReportTypes(OrgId);
      $scope.openStatuses = await downloadReportBackend.getOpenStatuses(OrgId);
    }

    function sanitizeFilterConfig() {
      DataModel.filterConfig.disableInvalidFilterFields();
      DataModel.filterConfig.removeDeletedSortField();

      DataModel.tableConfig.removeDeletedFields();
    }

    function getUnprocessedRecords(records) {
      return ProcessedDataHandler.isProcessedEmpty()
        ? records
        : records.filter(rec => !ProcessedDataHandler.isRecordProcessed(rec));
    }

    var loadTableData = AsyncCallManager.queueOverlappingCallsTo(() => {
      logger.log(">>>>>> Data Visualzier loadTableData");
      var deferred = $q.defer();

      const tableEmpty = DataModel.table.data().length === 0;
      const lazyLoadMore = !tableEmpty && $scope.lastRecNo < DataModel.table.filteredRecordCount();
      logger.log(`tableEmpty: ${tableEmpty}`);
      logger.log(`$scope.lastRecNo: ${$scope.lastRecNo}`);
      logger.log(`DataModel.table.filteredRecordCount(): ${DataModel.table.filteredRecordCount()}`);

      if (tableEmpty || lazyLoadMore) {
        LoadingAnimationState.start("loadTableData");
        const start = $scope.lastRecNo + 1;

        DataModel.filterConfig.setParams({ start });

        const withMetadata = false;
        DataTableService.loadTableData(withMetadata)
          .then(
            responseData => {
              const newData = getUnprocessedRecords(responseData.result);

              updateConditionFormatting();

              const startIndex = getDataLength();
              const dataToAppend = getDataWithRowIndex(newData, startIndex);
              DataModel.table.data(DataModel.table.data().concat(dataToAppend));
              DataModel.table.filteredRecordCount(responseData.filteredRecordCount);
              setTabProps(0, {
                data: DataModel.table.data(),
                fields: DataModel.table.fields(),
              });

              if (tableEmpty) {
                updateDisplayRecordCounts(DataModel.table.filteredRecordCount());
              }

              updateLastRecNo(responseData.lastRecordNumber);

              updateFieldIsHtmlValues();

              eventService.publish("tableDataLoaded");

              deferred.resolve();
            },
            response => {
              var error;
              updateTableRows();

              if (response && response.errorMessage && Localize.getLocalizedString(response.errorMessage)) {
                error = Localize.getLocalizedString(response.errorMessage);
              } else {
                error = Localize.getLocalizedString("_Table.LoadFailed.Error_");
              }

              $scope.setMessage({
                type: "error",
                content: error,
              });

              deferred.resolve();
            }
          )
          .finally(() => {
            LoadingAnimationState.stop("loadTableData");
          });
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    });

    function loadTableDataError(error) {
      if (error === "item.notfound" && isSavedViz($state.current.name)) {
        if (AppConfig.features.saveViz.linkMostRecentTable) {
          $scope.setMessage({
            type: "error",
            content: Localize.getLocalizedString("_SaveViz.SourceTable.NotAvailable.WithChangeSource.Message_"),
          });
          $scope.openChangeSourceModal(
            Localize.getLocalizedString("_SaveViz.SourceTable.NotAvailable.Modal.Intro.Message_")
          );
          SaveViz.setSourceTableExist(false);
        } else {
          $scope.setMessage({
            type: "error",
            content: Localize.getLocalizedString("_SaveViz.SourceTable.NotAvailable.WithOutChangeSource.Message_"),
          });
        }
      } else {
        $scope.setMessage({
          type: "error",
          content: $scope.errorMessages.loadFailed,
        });
      }
    }

    function loadRowsAfterRemove(numberOfRemovedRows) {
      // Set params such that next table load will load just enough rows
      // to replace those that were removed
      DataModel.filterConfig.setParams({
        start: $scope.lastRecNo + 1 - numberOfRemovedRows,
        limit: numberOfRemovedRows,
      });
      // Load metadata too for record count
      return DataTableService.loadTableData()
        .then(response => {
          const difference = DataModel.table.recordCount() - response.metaData.recordCount;

          DataModel.table.recordCount(response.metaData.recordCount);
          DataModel.table.certificationsTable(response.metaData.certificationsTable);
          DataModel.table.filteredRecordCount(response.filteredRecordCount);

          updateDisplayRecordCounts($scope.recordCount.filtered - difference);

          const newData = getUnprocessedRecords(response.result);
          DataModel.table.data(DataModel.table.data().concat(newData));
          updateLastRecNo(response.lastRecordNumber);

          updateTableRows();
          reloadChartTabs();
        })
        .catch(loadTableDataError);
    }

    function clearTableData() {
      DataModel.filterConfig.setParams({ start: 1 });
      DataModel.table.data([]);
      $scope.lastRecNo = 0;
      ProcessedDataHandler.resetProcessed();
    }

    function updateTableData(responseData) {
      const data = responseData.result;
      const { metadata } = responseData;

      const startIndex = getDataLength();
      DataModel.table.data(getDataWithRowIndex(data, startIndex));
      DataModel.table.filteredRecordCount(responseData.filteredRecordCount);

      updateTableRows();

      updateLastRecNo(responseData.lastRecordNumber);
      processTableMetadata(metadata);
      updateConditionFormatting();
      updateDisplayRecordCounts(DataModel.table.filteredRecordCount());

      if (DataModel.interpretation.currentTabIndex() !== 0) TabStateService.reloadAll();
    }

    function getDataLength() {
      return DataModel.table.data() ? DataModel.table.data().length : 0;
    }

    function updateGlobalFieldTypes() {
      const { interpretationId } = $scope;
      const fields = DataModel.table.fields();
      const fieldModels = FieldAdapter.deserializeFieldMapToArray(fields);
      GlobalFieldFormatMap.setFieldTypes(interpretationId, fieldModels);
    }

    function deleteRowsFromDataModel(recordIds, mode) {
      if (mode === "deleteAll" && recordIds.length) {
        DataModel.deleteRowsExcept(recordIds);
      }
      if (mode === "deleteAll" && !recordIds.length) {
        DataModel.deleteAllRows(recordIds);
      }
      if (mode === "deleteOne" && recordIds.length) {
        DataModel.deleteRows(recordIds);
      }
    }

    function updateTableRows() {
      setTabProps(0, {
        data: DataModel.table.data(),
      });
    }

    function updateDisplayRecordCounts(filteredRecordCount) {
      setTabProps(0, {
        filteredRecordCount: filteredRecordCount,
      });
      $scope.recordCount = {
        filtered: filteredRecordCount,
        total: DataModel.table.recordCount(),
      };
    }

    function updateLastRecNo(lastRecordNumber) {
      $scope.lastRecNo = parseInt(lastRecordNumber, 10);
    }

    function updateConditionFormatting() {
      ConditionalFormatting.updateFormattingConditions(
        DataModel.table.fields(),
        DataModel.tableConfig.formattingOptions()
      );
    }

    function updateFieldIsHtmlValues() {
      var fields = angular.copy(DataModel.table.fields());
      for (var fieldName in fields) {
        fields[fieldName].isHtml = DataModel.tableConfig.isHtml(fieldName) || false;
      }
      DataModel.table.fields(fields);
    }

    // @FIXME explicit that this resets all the application's data values.
    // need to come up with name indicating that reloading affects quick
    // filter menu etc.
    function reloadAllColumnValues() {
      FieldValueStore.reloadAll();
    }

    function isSavedViz(currentState) {
      const isSavedRemediation = currentState === "remediation" && typeof SaveViz.savedVizId !== "undefined";
      return isSavedRemediation || ["saveViz", "openViz", "openTable"].includes(currentState);
    }

    let isZoomingInFromChart = false;
    function createChartTabCallbackInterface() {
      return {
        isLoading: booleanValue => {
          if (booleanValue) {
            LoadingAnimationState.start("loadChartTab");
          } else {
            LoadingAnimationState.stop("loadChartTab");
          }
        },
        notify: (type, message, fade) => {
          // FIXME these messages do not stack; any previous warnings would disappear
          $scope.setMessage({ type: type, content: message }, fade);
        },
        onZoomIn: filters => {
          isZoomingInFromChart = true;
          eventService.publish("setQuickFilters", filters);
        },
        setTitle: (tabIndex, title) => {
          tabs[tabIndex].title = title;
        },
      };
    }

    function handleJumpTab() {
      switch ($state.current.name) {
        case "openViz": {
          const { vizId } = $stateParams;
          const vizIdTabIndex = DataModel.getTabIndexByVizId(vizId);
          if (foundVizTab(vizIdTabIndex)) {
            DataModel.interpretation.currentTabIndex(vizIdTabIndex);
          }
          break;
        }
        case "openTable":
          DataModel.interpretation.currentTabIndex(0);
          break;
      }
    }

    function foundVizTab(vizIdTabIndex) {
      return vizIdTabIndex > 0;
    }

    function setTabProps(tabIndex, props) {
      if (!$scope.tabProps[tabIndex]) {
        $scope.tabProps[tabIndex] = props;
      } else {
        $scope.tabProps[tabIndex] = { ...$scope.tabProps[tabIndex], ...props };
      }
    }

    $scope.table = DataModel.getTableObj();

    DataModel.subscribe("table.recordCount", handleRecordCountChange);
    DataModel.subscribe("table.filteredRecordCount", handleRecordCountChange);
    function handleRecordCountChange() {
      updateExportToCsv();
      updateExportToXls();
    }

    // get all messages
    $scope.errorMessages = {
      tableNoData: Localize.getLocalizedString("_Table.NoData.Error_"),
      loadFailed: Localize.getLocalizedString("_Table.LoadFailed.Error_"),
    };

    function processTableMetadata(metadata) {
      DataModel.table.displayName(metadata.displayName);
      DataModel.table.recordCount(metadata.recordCount);
      DataModel.table.hasStaticField(metadata.hasStaticField);
      DataModel.table.certificationsTable(metadata.certificationsTable);

      setTabProps(0, {
        fields: DataModel.table.fields(),
      });

      // Note: have to make a copy and set the sortFields, because angular-ui select2 defect (in my opinion),
      // where its watch on the ng-options won't fire if values change, only if the reference changes (because
      // it uses $watch instead of $watchCollection). —Daryn.
      // https://github.com/angular-ui/ui-select2/pull/154
      let newSortFields = angular.copy(DataModel.table.sortFields());
      let fieldsAdded = false;
      angular.forEach(metadata.fields, (field, fieldName) => {
        if (field.isSortable) {
          fieldsAdded = true;
          newSortFields.push({
            fieldName: fieldName,
            displayName: field.displayName,
          });
        }
      });
      if (fieldsAdded) {
        newSortFields = Sorter.sort(newSortFields, {
          valueParser: item => item.displayName,
        });
        DataModel.table.sortFields(newSortFields);
      }

      DataModel.table.tableName(DataModel.table.displayName());

      Visualizer.initColumnConfigs($scope.isNew, isRemediation());

      var complete = (success, msg) => {
        DataModel.table.metadataLoaded(true);
        if (success) {
          UsageTracker.mark("dataVisualization.initialized");

          // Need to set initializing to false at the end of the stack so that the
          // one change to the DataModel that occur during initialization don't
          // make the interpretation dirty.
          //
          // 1) Visualizer.initColumnConfigs sets DataModel.tableConfig.columns()
          //
          $timeout(() => {
            SaveViz.setInitializing(false);
          });
        }
        if (msg && msg.type) {
          $scope.setMessage(msg);
        }
      };

      if (isSavedViz($state.current.name)) {
        $scope.interpretationSummaryProps = {
          summary: DataModel.interpretation.summary(),
          canUpdateSummary: !!AppConfig.ability && AppConfig.ability.canUpdateInterpretations(),
          onSaveSummary: summaryValue => saveSummary(summaryValue),
        };

        if (SaveViz.isCompatibleWithTable(DataModel.getTableObj().metaData)) {
          DataFilter.bindQuickFilterCheckedValues(DataModel.getFilterConfigDeprecated());
          complete(true);
        } else {
          let vizRecoverable = false;
          let filterRecoverable = false;
          if (DataModel.visualizations() && DataModel.visualizations().length) {
            vizRecoverable = DataModel.visualizations().some(viz => {
              const vizDataConfig = viz.config && viz.config.dataConfig;
              const dataConfigHasDeletedField = !ChartService.dataConfigFieldsExist(vizDataConfig);
              const dataConfigHasFieldTypeMismatch = ChartService.dataConfigHasFieldTypeMismatch(vizDataConfig);
              return vizDataConfig && (dataConfigHasDeletedField || dataConfigHasFieldTypeMismatch);
            });
          }

          const filterHasDeletedField = DataModel.filterConfig.hasDeletedField();
          const filterHasFieldTypeMismatch = DataModel.filterConfig.hasFieldTypeMismatch();
          filterRecoverable = filterHasDeletedField || filterHasFieldTypeMismatch;

          let errorMessage = Localize.getLocalizedString("_Invalid.Saved.Viz.Message_");
          if (vizRecoverable && filterRecoverable) {
            errorMessage = Localize.getLocalizedString("_Invalid.Saved.Viz.Filter.Message_");
          } else if (filterRecoverable) {
            errorMessage = Localize.getLocalizedString("_Invalid.Saved.Filter.Message_");
          }

          complete(vizRecoverable || filterRecoverable, {
            type: "error",
            content: errorMessage,
          });
        }
      } else {
        complete(true);
      }
    }

    function saveSummary(summary) {
      DataModel.interpretation.summary(summary);
      $scope.interpretationSummaryProps.summary = DataModel.interpretation.summary();
    }

    $scope.toggleConfigPanel = value => {
      if ($scope.isCopyInterpretationOpen) {
        $scope.isCopyInterpretationOpen = false;
      }
      if ($scope.isReportDownloadPanelOpen) {
        $scope.isReportDownloadPanelOpen = false;
        eventService.publish("reportDownloadPanel.toggle.fromDataVisualizer", $scope.reportTypes, $scope.openStatuses);
      }
      $scope.isConfigPanelOpen = value !== undefined ? value : !$scope.isConfigPanelOpen;

      if ($scope.isConfigPanelOpen) {
        eventService.publish("dataTableConfigPanelExternal.open");
      } else {
        eventService.publish("dataTableConfigPanelExternal.close");
      }

      eventService.publish("panelsStateChange");
    };

    eventService.subscribe("dataTableConfigPanel.close", () => {
      if ($scope.isConfigPanelOpen) {
        $scope.toggleConfigPanel(false);
      }
    });

    eventService.subscribe("processPanel.close", () => {
      setProcessPanelState(false);
    });

    eventService.subscribe("processPanel.open", () => {
      if ($scope.isCopyInterpretationOpen) {
        $scope.isCopyInterpretationOpen = false;
      }
      if ($scope.isReportDownloadPanelOpen) {
        $scope.isReportDownloadPanelOpen = false;
      }
      setProcessPanelState(true);
    });

    function setProcessPanelState(value) {
      $scope.isProcessPanelOpen = value;
      eventService.publish("panelsStateChange");

      if ($scope.isProcessPanelOpen) {
        $timeout(function() {
          focusProcessPanelTitle();
        }, 300);
      }
    }

    $scope.toggleFilterPanel = () => {
      if ($scope.isCopyInterpretationOpen) {
        $scope.isCopyInterpretationOpen = false;
      }
      if ($scope.isReportDownloadPanelOpen) {
        $scope.isReportDownloadPanelOpen = false;
        eventService.publish("reportDownloadPanel.toggle.fromDataVisualizer", $scope.reportTypes, $scope.openStatuses);
      }
      $scope.isFilterPanelOpen = !$scope.isFilterPanelOpen;

      DataModel.filtersOpen($scope.isFilterPanelOpen);
      eventService.publish("panelsStateChange");
      if ($scope.isFilterPanelOpen) {
        $timeout(function() {
          angular.element("#filter-panel-title").focus();
        }, 300);
      } else {
        document.querySelector(".action-buttons__filter-config-button").focus();
      }
    };

    $scope.toggleCopyInterpretation = async showPanel => {
      if (showPanel) {
        $scope.isFilterPanelOpen = false;

        $scope.isConfigPanelOpen = false;
        eventService.publish("copyInterpretationsPanel.open");
        $scope.isCopyInterpretationOpen = true;
        await initCopyInterpretationsCollections();
      } else {
        $scope.isCopyInterpretationOpen = false;
        eventService.publish("panelsStateChange");
      }
    };

    $scope.copyInterpretation = async copyConfig => {
      $scope.isCopyInterpretationToast = false;
      const vizConfigs = $scope.copyInterpretationConfig();
      const results = await CopyInterpretationService.copyInterpretation({ ...copyConfig, vizConfig: vizConfigs });

      $scope.copyInterpretationsErrorMessages = results
        .filter(result => result.value.status != 200)
        .flatMap(item => ({
          table_id: item.value.data.table_id,
          table_name: item.value.data.table_name,
          errors: item.value.data.errors.map(error => ({
            field_name: error.field_name,
            code: error.code,
            type: error.type,
          })),
        }));

      if ($scope.copyInterpretationsErrorMessages.length === 0) {
        $scope.setMessage({
          type: "success",
          content: Localize.getLocalizedString("_CopyInterpretation.Save.Success.Message_"),
        });
      } else {
        $scope.isCopyInterpretationToast = true;
      }
    };

    $scope.copyInterpretationConfig = () => {
      return {
        projectId: AppConfig.project_id,
        controlId: AppConfig.control_id,
        controlTestId: AppConfig.control_test_id,
        interpretationId: $stateParams.savedVizId,
      };
    };

    eventService.subscribe("filterPanel.open", () => {
      if (!$scope.isFilterPanelOpen) {
        $scope.toggleFilterPanel();
      }
    });

    eventService.subscribe("filterPanel.close", () => {
      if ($scope.isFilterPanelOpen) {
        $scope.toggleFilterPanel();
      }
    });

    $scope.isVizTabActive = () => isVizTabActive();

    function isVizTabActive() {
      return DataModel.interpretation.currentTabIndex() > 0;
    }

    $scope.tabChanged = tabIndex => {
      DataModel.interpretation.currentTabIndex(tabIndex);
    };

    function deferredTabChanged(tabIndex) {
      $timeout(() => $scope.tabChanged(tabIndex));
    }

    $scope.modalOptions = {
      backdropFade: false,
      dialogFade: true,
    };

    $scope.openChartSelectorModal = () => {
      if ($scope.migrateChartPickerModal) {
        const that = ($scope.openChartSelectorModalProps = {
          zIndex: 999,
          openModal: true,
          chartTypes: ChartService.getAllChartTypes().filter(function(d) {
            return !d.disabled;
          }),
          headerText: Localize.getLocalizedString("_ChartSelector.Modal.Title_"),
          onPickChart: chartType => {
            chartTab.openNewVisualization({
              type: chartType.type,
              vizType: chartType.vizType,
              config: chartType.config,
            });
            $scope.toggleConfigPanel(true);
            that.openModal = false;
          },
          onCancel: () => {
            that.openModal = false;
          },
        });
      } else {
        const chartSelectorInstance = $modal.open({
          templateUrl: "visualizer/views/chartSelector.html",
          controller: "ChartSelectorController",
          windowClass: "chart-selector-modal",
          resolve: {
            newTabIndex: () => tabs.length + 1,
          },
        });

        chartSelectorInstance.result.then(
          selectedChart => {
            let chartType = selectedChart.type;
            let chartVizType = selectedChart.vizType;
            let chartConfig = selectedChart.config;
            let vizConfig = { type: chartType, vizType: chartVizType, config: chartConfig };

            chartTab.openNewVisualization(vizConfig);
            $scope.toggleConfigPanel(true);
          },
          () => {
            // cancel
          }
        );
      }
    };
    function handleInvalidProcessedRecords(tabIndex) {
      const switchToTableTab = tabIndex === 0;
      const potentialInvalidProcessedRecords = !ProcessedDataHandler.isProcessedEmpty();

      if (switchToTableTab && potentialInvalidProcessedRecords) {
        // Ideally you would schedule this when switching away from table tab
        // but there is race condition to setting table tab as not active
        // meaning a reload will occur immediately.
        reloadTableTab();
      }
    }

    function handleOutdatedFilteredRecordCount(tabIndex) {
      const switchToChartTab = tabIndex > 0;
      const filteredRecordCountOutdated =
        $scope.recordCount && $scope.recordCount.filtered !== DataModel.table.filteredRecordCount();

      if (switchToChartTab && filteredRecordCountOutdated) {
        $scope.recordCount.filtered = DataModel.table.filteredRecordCount();
      }
    }

    let isCreateTabsPending = false;
    DataModel.subscribe("visualizations", () => {
      deferCreatingTabs();
    });

    DataModel.subscribe("interpretation.currentTabIndex", () => {
      const selectedTabIndex = DataModel.interpretation.currentTabIndex();
      deferCreatingTabs();
      handleInvalidProcessedRecords(selectedTabIndex);
      handleOutdatedFilteredRecordCount(selectedTabIndex);
      TabStateService.setSelectedTab(selectedTabIndex);
      $scope.selectedTabIndex = selectedTabIndex;
      handleChartTabError(selectedTabIndex);
    });

    function handleChartTabError(selectedTabIndex) {
      if (selectedTabIndex > 0) {
        const vizIndex = selectedTabIndex - 1;
        const chartHasError = ChartService.chartHasError(vizIndex);
        if (chartHasError) $scope.toggleConfigPanel(true);
      }
    }

    function deferCreatingTabs() {
      if (!isCreateTabsPending) {
        isCreateTabsPending = true;
        $timeout(createTabs);
      }
    }

    function createTabs() {
      isCreateTabsPending = false;

      const chartTabConfigs = chartTab.createChartTabConfigs(
        DataModel.visualizations(),
        createChartTabCallbackInterface()
      );
      updateTabs(dataTableTabConfig, chartTabConfigs);
    }

    {
      const colorData = {};

      $scope.chartConfigPanelProps = {
        chartType: undefined,
        colorData: () => {
          const visualizationIndex = DataModel.interpretation.selectedVisualizationIndex();
          return colorData[visualizationIndex];
        },
        dataConfig: undefined,
        displayConfig: undefined,
        fieldNameToFieldObj: {},
        onColorMappingChange: colorMapping => {
          DataModel.visualizationConfig.colorMapping(colorMapping);
          eventService.publish("chartConfigPanel.colorMappingChanged", colorMapping);
        },
        onDataConfigChange: dataConfig => {
          const tabIndex = DataModel.interpretation.currentTabIndex();
          const visualizationIndex = DataModel.interpretation.selectedVisualizationIndex();
          const visualization = DataModel.visualization(visualizationIndex);
          if (visualization.vizType === "SummaryTable") {
            if (dataConfig.chartColumns.length === 0 && dataConfig.chartRows.length === 0) {
              eventService.publish("chartConfigPanel.configClear");
            }
          }

          eventService.publish("chartConfigPanel.dataChange", dataConfig, tabIndex);
        },
        onDeleteChartClick: () => {
          const visualizationIndex = DataModel.interpretation.selectedVisualizationIndex();
          const newActiveTabIndex = Math.min(visualizationIndex, tabs.length - 1);
          DataModel.removeVisualization(visualizationIndex);

          $scope.tabChanged(newActiveTabIndex);
        },
        onDisplayConfigChange: displayConfig => {
          const tabIndex = DataModel.interpretation.currentTabIndex();
          const visualizationIndex = DataModel.interpretation.selectedVisualizationIndex();
          const visualization = DataModel.visualization(visualizationIndex);
          visualization.config.displayConfig = cloneDeep(displayConfig);
          DataModel.visualization(visualizationIndex, visualization);

          eventService.publish("chartConfigPanel.displayChange", tabIndex);
        },
      };

      eventService.subscribe(
        "chartData.configChanged",
        (eventName, vizIndex, chartType, tableId, filterConfig, dataConfig) => {
          const tabIndex = DataModel.interpretation.currentTabIndex();
          if (tabIndex === vizIndex) {
            updateChartConfigPanelProps();
          }
        }
      );

      DataModel.subscribe("table.field", () => {
        $scope.chartConfigPanelProps.fieldNameToFieldObj = DataModel.table.fields();
      });

      DataModel.subscribe("table.fields", () => {
        $scope.chartConfigPanelProps.fieldNameToFieldObj = DataModel.table.fields();
      });

      DataModel.subscribe("interpretation.currentTabIndex", updateChartConfigPanelProps);

      function updateChartConfigPanelProps() {
        const visualizationIndex = DataModel.interpretation.selectedVisualizationIndex();
        if (visualizationIndex >= 0) {
          const visualization = DataModel.visualization(visualizationIndex);
          $scope.chartConfigPanelProps.vizId = visualization.id;
          $scope.chartConfigPanelProps.chartType = visualization.vizType;
          $scope.chartConfigPanelProps.dataConfig = visualization.config.dataConfig;
          $scope.chartConfigPanelProps.displayConfig = visualization.config.displayConfig;
        }
      }

      eventService.subscribe("chartData.loaded", (_, tabIndex, type, dataConfig, representation) => {
        const visualizationIndex = DataModel.interpretation.selectedVisualizationIndex();
        if (type === "Treemap") {
          // FIXME: d3colorpicker shouldn't rely on chart data structure. It should have its own representation.
          const rowNames = representation.config.rows.map(row => row.field_name);
          const values = representation.values.map(valueObj => valueObj.rows[0][rowNames[0]]);
          const uniqueValues = uniq(values);
          colorData[visualizationIndex] = uniqueValues.map(value => ({
            key: value,
          }));
        } else {
          colorData[visualizationIndex] = representation;
        }

        if (isZoomingInFromChart) {
          deferredTabChanged(0);
          isZoomingInFromChart = false;
        }
      });
    }

    $scope.filterPanelProps = {
      canRemediateRecords: false,
    };

    $scope.setMessage = (msg, fade) => {
      $scope.message = msg;
      // reset (hide) message after 5 seconds
      if (msg.type === "success" || fade) {
        $scope.setMessage.messageTimeout = $timeout(() => {
          $scope.resetMessage();
        }, 5000);
      } else {
        $timeout.cancel($scope.setMessage.messageTimeout);
        $scope.setMessage.messageTimeout = $timeout(() => {
          $scope.minimizeMessage();
        }, 3000);
      }
    };

    $scope.minimizeMessage = () => {
      if ($scope.message) $scope.message.minimized = true;
    };

    $scope.resetMessage = () => {
      delete $scope.message;
    };

    $scope.onSaveClick = () => {
      if (isNew()) {
        saveVizAs();
      } else {
        const filterConfig = DataModel.getFilterConfig();
        const isDrilldownFiltersAvailable =
          isStoryboardDrilldown() &&
          filterConfig &&
          filterConfig.filterList &&
          isStoryboardFiltersAvailable(filterConfig.filterList);

        if (!isDrilldownFiltersAvailable) {
          saveViz();
        } else {
          saveConfirmationPostDrilldown();
        }
      }
    };

    function isStoryboardFiltersAvailable(filterList) {
      if (filterList.find(filter => filter.showStoryboardIndicator)) {
        return true;
      } else {
        return false;
      }
    }

    function isNew() {
      return !$state.current.name;
    }

    function isRemediation() {
      return isStateRemediation() || isStatePublic();
    }

    function isStateRemediation() {
      return $state.current.name === "remediation";
    }

    function isStatePublic() {
      return $state.current.name === "public";
    }

    function saveViz() {
      if (!validateFilters()) return;

      if (SaveViz.savedVizId) {
        if (isStateRemediation()) {
          SaveViz.updateDefaultViz().catch(handleSaveInterpretationError);
        } else {
          // When savedVizId already exist, update the savedViz
          $scope.renameInterpretation = false;
          SaveViz.saveInterpretation().catch(handleSaveInterpretationError);
        }
      } else if (isStateRemediation()) {
        SaveViz.saveDefaultViz().catch(handleSaveInterpretationError);
      } else {
        // If savedVizId does not exist, show modal to enter title.
        $scope.renameInterpretation = false;
        openSaveVizModal();
      }
    }

    $scope.onSaveAsClick = () => {
      saveVizAs();
    };

    function saveVizAs() {
      if (!validateFilters()) return;
      $scope.renameInterpretation = false;
      openSaveVizModal();
    }

    $scope.onExportToCsvClick = () => {
      if ($scope.isExportToCsvEnabled) {
        LoadingAnimationState.start("exportCsv");
        Csv.export().finally(() => {
          LoadingAnimationState.stop("exportCsv");
        });
      }
    };

    $scope.isDownloadXlsModalOpen = false;
    $scope.isSaveVizModalOpen = false;

    $scope.onExportToXlsClick = () => {
      if ($scope.isExportToXlsEnabled) {
        $scope.isProcessingXls = true;
        $scope.isDownloadXlsModalOpen = true;
      }
    };

    $scope.closeDownloadXlsModal = () => {
      $scope.isDownloadXlsModalOpen = false;
      $scope.isProcessingXls = false;
    };
    $scope.closeSaveVizModal = () => {
      $scope.isSaveVizModalOpen = false;
    };
    $scope.saveVizButtonClick = vizOptions => {
      $scope.renameInterpretation
        ? renameInterpretation(vizOptions)
        : SaveViz.saveInterpretationAs({
            ...vizOptions,
            linkLatestTable: $scope.savedViz.visualizationInfo.linkLatestTable,
          }).catch(error => {
            handleSaveInterpretationError(error);
          });
    };

    $scope.openRenameInterpretationPopup = () => {
      if (!validateFilters()) return;
      backUpTitleAndSummary();
      $scope.renameInterpretation = true;
      openSaveVizModal();
    };

    // results impact report download panel
    function setFocusToMoreActionBtn() {
      // resetting the focus back to action button once the pop up / panel is closed
      $timeout(function() {
        angular.element(".action-buttons__overflow").focus();
      }, 500);
    }

    eventService.subscribe("reportGenerationFailure", () => {
      if ($scope.isReportGenerating) {
        $scope.isReportGenerating = false;
        $scope.reportGeneratedDetails = {
          reportStatus: false,
        };
      }
    });

    eventService.subscribe("webSocketServerError", () => {
      $scope.isReportDownloadModalOpen = false;
      $scope.reportGeneratedDetails = undefined; // resetting reportGenerated to undefined
      $scope.isReportGenerating = false;
      $scope.setMessage(
        {
          type: "error",
          content: Localize.getLocalizedString("_ReportDownloadPanel.reportGenerationFailure.Notification_"),
        },
        true
      );
      setFocusToMoreActionBtn();
    });

    eventService.subscribe("webSocketConnectionError", () => {
      $scope.isReportDownloadModalOpen = false;
      $scope.isReportGenerating = false;
      $scope.setMessage(
        {
          type: "error",
          content: Localize.getLocalizedString("_ReportDownloadPanel.reportGenerationFailure.Notification_"),
        },
        true
      );
      setFocusToMoreActionBtn();
    });

    $scope.toggleReportDownloadPanel = eventFrom => {
      if ($scope.isFilterPanelOpen) {
        $scope.toggleFilterPanel();
      }
      if ($scope.isConfigPanelOpen) {
        $scope.toggleConfigPanel(false);
      }
      if ($scope.isProcessPanelOpen) {
        eventService.publish("processPanel.force.close");
      }
      if (eventFrom !== "fromDataPanel")
        eventService.publish("reportDownloadPanel.toggle.fromDataVisualizer", $scope.reportTypes, $scope.openStatuses);
      $scope.isReportDownloadPanelOpen = !$scope.isReportDownloadPanelOpen;
      if (!$scope.isReportDownloadPanelOpen) {
        setFocusToMoreActionBtn();
      }
    };

    $scope.closeDownloadReportModal = () => {
      $scope.isReportDownloadModalOpen = false; // setting to false to hide
      $scope.reportGeneratedDetails = undefined;
      // This is to handle when user clicks on cancel, after initiating report generation.
      // checking if report generation is in progress, then to initiate closing of websocket connection
      if ($scope.isReportGenerating) {
        $scope.isReportGenerating = false;
        eventService.publish("reportGenerationCancelled");
      }
      setFocusToMoreActionBtn();
    };

    eventService.subscribe("reportDownloadPanel.toggle.fromDataPanel", () => {
      $scope.toggleReportDownloadPanel("fromDataPanel");
    });

    eventService.subscribe("report.download", async (event, info) => {
      $scope.isReportDownloadModalOpen = true;
      $scope.isReportDownloadPanelOpen = false;
      const { targetReport, filterExpression } = info;
      // setting isReportGeneration flag to true
      $scope.isReportGenerating = true;
      const orgId = $scope.highbondNavBarProps.appSwitcherProps.initialOrganizationId;
      await downloadReportBackend.downloadReport(orgId, targetReport, filterExpression);
      eventService.subscribe("reportGenerated", (event, info) => {
        // checking if reportGeneration is started
        if ($scope.isReportGenerating) {
          $scope.isReportGenerating = false;
          $scope.reportGeneratedDetails = {
            reportStatus: info.reportStatus,
            s3SignedUrl: info.reportDownloadURL,
          };
        }
      });
    });

    eventService.subscribe("report.email", async (event, info) => {
      $scope.isReportDownloadPanelOpen = false;
      const { targetReport, filterExpression } = info;
      const orgId = $scope.highbondNavBarProps.appSwitcherProps.initialOrganizationId;
      const response = await downloadReportBackend.emailReport(orgId, targetReport, filterExpression);
      if (!response.error) {
        $scope.setMessage(
          { type: "success", content: Localize.getLocalizedString("_ReportDownloadPanel.emailReport.Notification_") },
          true
        );
      } else {
        $scope.setMessage(
          {
            type: "error",
            content: Localize.getLocalizedString("_ReportDownloadPanel.emailReportFailure.Notification_"),
          },
          true
        );
      }
      setFocusToMoreActionBtn();
    });

    $scope.isReportTypesAvailable = () => {
      return $scope.reportTypes.length > 0;
    };

    function updateTitleAndSummary(title, summary) {
      DataModel.interpretation.title(title);
      DataModel.interpretation.summary(summary);
    }

    function renameInterpretation(vizOption) {
      if (!validateFilters()) return;
      updateTitleAndSummary(vizOption.title, vizOption.summary);
      SaveViz.saveInterpretation().catch(error => {
        handleSaveInterpretationError(error);
        $scope.renameInterpretationFailedCount++;
        $scope.vizTitleError = $scope.savedViz.visualizationInfo.title;
        $scope.vizSummaryError = $scope.savedViz.visualizationInfo.summary;

        updateTitleAndSummary($scope.backUpTitle, $scope.backUpSummary);
        $scope.savedViz.visualizationInfo.title = $scope.backUpTitle;
        $scope.savedViz.visualizationInfo.summary = $scope.backUpSummary;
        $timeout(function() {
          eventService.publish("saveViz.vizClean");
        });
      });
    }

    function backUpTitleAndSummary() {
      $scope.backUpTitle = $scope.savedViz.visualizationInfo.title;
      $scope.backUpSummary = $scope.savedViz.visualizationInfo.summary;
    }

    function openSaveVizModal() {
      if (AppConfig.features.saveVizModalRevamp) {
        $scope.isSaveVizModalOpen = true;
        $scope.vizTitle = $scope.savedViz ? $scope.savedViz.visualizationInfo.title : "";
        $scope.vizSummary = $scope.savedViz ? $scope.savedViz.visualizationInfo.summary : "";
      } else {
        const saveVizModalInstance = $modal.open({
          templateUrl: "visualizer/views/saveVizModal.html",
          controller: "SaveVizModalController",
          windowClass: "save-viz-modal",
        });

        saveVizModalInstance.result.then(SaveViz.saveInterpretationAs).catch(handleSaveInterpretationError);
      }
    }

    $scope.onSummaryButtonClick = () => {
      openSummaryModal();
    };

    function openSummaryModal() {
      var summaryModalInstance = $modal.open({
        templateUrl: "visualizer/views/summaryModal.html",
        controller: "SummaryModalController",
        windowClass: "summary-modal",
        resolve: {
          title: () => DataModel.interpretation.title(),
          summary: () => DataModel.interpretation.summary(),
        },
      });

      summaryModalInstance.result.then(
        summaryValue => saveSummary(summaryValue),
        () => {
          // cancel
        }
      );
    }

    $scope.openChangeSourceModal = modalMessage => {
      var savedViz = DataModel.toSaveViz();
      var chooseSourceModalInstance = $modal.open({
        templateUrl: "visualizer/views/changeSourceTableModal.html",
        controller: "ChooseSourceTableController",
        windowClass: "change-source-modal",
        resolve: {
          table: () => ({
            id: DataModel.table.tableId(),
            name: savedViz.visualizationInfo.tableName,
          }),
          analytic: () => ({ id: $stateParams.analyticName }),
          introMessage: () => modalMessage,
        },
      });

      chooseSourceModalInstance.result.then(
        newSourceTable => {
          DataModel.table.tableId(newSourceTable.id);

          DataModel.table.reset();
          ProcessedDataHandler.resetProcessed();

          LoadingAnimationState.start("loadTableDataFromNewSource");
          DataTableService.loadTableData()
            .then(
              response => {
                let metadata = response.metaData;
                if (SaveViz.isCompatibleWithTable(metadata)) {
                  handleSourceTableUpdated();
                  // @TODO we really want to processTableMetadata twice?
                  updateTableData({ ...response, metadata });
                  FieldValueStore.reloadAll();
                  reloadTabs();
                } else {
                  var template = Localize.getLocalizedTemplateWithTokenGeneration(
                    "_Invalid.Saved.Viz.WithChangeSource.Message_"
                  );
                  var msg = {
                    type: "error",
                    content: template.template,
                    unsafehtmlcontent: '<a href ng-click="openChangeSourceModal()">' + template.tokens[0] + "</a>",
                  };
                  $scope.setMessage(msg);
                }
              },
              () => {
                $scope.setMessage({
                  type: "error",
                  content: $scope.errorMessages.loadFailed,
                });
              }
            )
            .finally(() => {
              LoadingAnimationState.stop("loadTableDataFromNewSource");
            });
        },
        () => {
          // cancel
        }
      );
    };

    function handleSourceTableUpdated() {
      $scope.setMessage({
        type: "success",
        header: DataModel.interpretation.title(),
        content: Localize.getLocalizedString("_SaveViz.ChangedSource.Message_"),
      });
    }

    //TODO this should be its own module
    $scope.onShareToGrcClick = () => {
      openShareToGrcModal();
    };

    function openShareToGrcModal() {
      if (!$scope.isShareToGrcOffline()) {
        $modal.open({
          templateUrl: "visualizer/views/shareInterpretationToGRCModal.html",
          controller: "ShareToGRCController",
          windowClass: "share-to-grc-modal",
          resolve: {
            state: () => "init",
            controlTest: () => null,
            error: () => "",
            dataRecords: () => null,
          },
        });
      }
    }

    $scope.showSummaryButton = isSavedViz($state.current.name);
    $scope.showShareToGrc = Visualizer.canShareToGrc();

    $scope.isShareToGrcOffline = () => {
      if (typeof global_grcAccessToken !== "undefined") {
        return global_grcAccessToken === "offlineToken";
      }
      return false;
    };

    $scope.onBackButtonClick = () => {
      if (canLeave()) {
        navigateBack();
      } else {
        showConfirmationModal();
      }
    };

    $scope.onHomeButtonClick = () => {
      if (canLeave()) {
        navigateHome();
      } else {
        showConfirmationModal();
      }
    };

    $scope.onHighbondButtonClick = () => {
      if (canLeave()) {
        navigateLaunchpad();
      } else {
        showConfirmationModal();
      }
    };

    $scope.modalIsOpen = false;

    $scope.closeModal = () => {
      $scope.modalIsOpen = false;
    };

    function showConfirmationModal(url) {
      const handleNavigate = url
        ? () => {
            navigateTo(url);
          }
        : () => {
            navigateBack();
          };

      const handleSave = () => {
        $scope.modalIsOpen = false;

        if (isNew()) {
          const unsubscribe = eventService.subscribe("saveViz.saveSuccess", handleNavigate);
          eventService.subscribe("saveViz.saveFailed", unsubscribe);
        } else {
          const unsubscribe = eventService.subscribe("saveViz.updateSuccess", handleNavigate);
          eventService.subscribe("saveViz.updateFailed", unsubscribe);
        }

        $scope.onSaveClick();
      };

      $scope.dialogActions = (
        <DialogActions
          hasCancel
          hasConfirm
          hasDecline
          labelCancel={Localize.getLocalizedString("_LeaveViz.Modal.StayOnPage_")}
          labelConfirm={Localize.getLocalizedString("_LeaveViz.Modal.Save_")}
          labelDecline={Localize.getLocalizedString("_LeaveViz.Modal.DiscardUnsavedChanges_")}
          onCancel={$scope.closeModal}
          onConfirm={handleSave}
          onDecline={handleNavigate}
        />
      );

      $scope.modalIsOpen = true;
    }

    function navigateTo(url) {
      $window.onbeforeunload = null;
      $window.location.assign(url);
    }

    function navigateBack() {
      let url;
      ConfirmLeave.off();

      if ($stateParams.returnUrl) {
        url = $stateParams.returnUrl ? getOrigin() + $stateParams.returnUrl : getOrigin();
      } else {
        url = AppConfig.projectUrl;
      }
      $window.location.assign(url);
    }

    function navigateHome() {
      const url = getOrigin();
      ConfirmLeave.off();
      $window.location.assign(url);
    }

    function navigateLaunchpad() {
      const launchpadUrl = $scope.highbondNavBarProps.links.launchpad;
      $window.location.assign(launchpadUrl);
    }

    function canLeave() {
      return !$scope.hasUnsavedChanges || isRemediation();
    }

    function getOrigin() {
      return (
        $window.location.origin ||
        $window.location.protocol +
          "//" +
          $window.location.hostname +
          ($window.location.port ? ":" + $window.location.port : "")
      );
    }

    function handleSaveInterpretationError(error) {
      if (error && error.invalidVisualizationIndex >= 0) {
        const { invalidVisualizationIndex } = error;
        DataModel.interpretation.currentTabIndex(invalidVisualizationIndex + 1);
        openInvalidSaveModal();
      }
    }

    function openInvalidSaveModal() {
      return $modal
        .open({
          templateUrl: "visualizer/views/invalidVizWarningModal.html",
          controller: "InvalidVizController",
          windowClass: "invalid-viz-modal",
        })
        .result.catch(() => {
          // Catching for when the modal is dismissed
        });
    }

    eventService.subscribe("saveViz.saveSuccess", (event, info) => {
      LocationManager.goToState("saveViz", {
        savedVizId: info.interpretationId,
        saved: info.title,
      });
    });

    eventService.subscribe("saveViz.saveDefaultSuccess", () => {
      LocationManager.goToState("remediation");
    });

    eventService.subscribe("saveViz.saveFailed", (event, info, errorCode) => {
      let errorMessageKey;
      let errorMessage;
      if (errorCode) {
        errorMessageKey = "_SaveViz.Save.Error." + errorCode + ".Message_";
      }
      errorMessage = Localize.getLocalizedString(errorMessageKey, "_SaveViz.Save.Error.Message_");
      $scope.setMessage({
        type: "error",
        header: info.title,
        content: errorMessage,
      });
    });

    eventService.subscribe("saveViz.updateSuccess", () => {
      if (!isStoryboardDrilldown()) {
        $scope.setMessage({
          type: "success",
          header: DataModel.interpretation.title(),
          content: Localize.getLocalizedString("_SaveViz.Update.Success.Message_"),
        });
        if ($scope.renameInterpretation) {
          $scope.renameInterpretationTitle = DataModel.interpretation.title();
          $scope.interpretationSummaryProps.summary = DataModel.interpretation.summary();
          $scope.vizTitleError = "";
          $scope.vizSummaryError = "";
          $scope.savedViz = DataModel.toSaveViz();
        }
      } else {
        const { savedVizId } = $stateParams;
        eventService.publish("saveViz.saveSuccess", {
          interpretationId: savedVizId,
        });
        SessionStorageHelper.remove(getStoryboardDrilldownSessionStorageKey());
      }
    });

    eventService.subscribe("saveViz.updateFailed", (event, info) => {
      $scope.setMessage({
        type: "error",
        header: info.title,
        content: Localize.getLocalizedString("_SaveViz.Update.Failed.Message_"),
      });
    });

    eventService.subscribe("windowResizeEvent", TabStateService.redrawAll);

    // Single window resize event with throttling to trigger other app updates
    let timeout = null;
    angular.element($window).bind("resize", () => {
      if (timeout) {
        $timeout.cancel(timeout);
      }
      timeout = $timeout(() => {
        eventService.publish("windowResizeEvent");
        timeout = null;
      }, WINDOW_RESIZE_DELAY);
    });

    function saveConfirmationPostDrilldown() {
      const { storyboard_id } = $stateParams;
      const storyboardName = SessionStorageHelper.get(storyboard_id) || "";
      let that = ($scope.saveConfirmationPropsPostDrilldown = {
        zIndex: 999,
        openModal: true,
        confirmationButtonType: "primary",
        confirmButtonText: Localize.getLocalizedString("_Storyboard.Post.Drilldown.Save.Confirmation.ConfirmButton_"),
        headerText: Localize.getLocalizedString("_Storyboard.Post.Drilldown.Save.Confirmation.HeaderText_"),
        bodyText: Localize.getLocalizedStringWithTokenReplacement(
          "_Storyboard.Post.Drilldown.Save.Confirmation.BodyText_",
          storyboardName
        ),
        onConfirm: () => {
          that.openModal = false;
          saveViz();
        },
        onCancel: () => {
          that.openModal = false;
        },
      });
    }

    function getStoryboardDrilldownSessionStorageKey() {
      const { savedVizId, vizId, storyboard_id } = $stateParams;

      switch ($state.current.name) {
        case "openTable":
          return StoryBoardDrilldownService.getSessionStorageKey({
            type: "table",
            interpretation_id: savedVizId,
            storyboardId: storyboard_id,
          });
        case "openViz":
          return StoryBoardDrilldownService.getSessionStorageKey({
            type: "visualization",
            interpretation_id: savedVizId,
            visualization_id: vizId,
            storyboardId: storyboard_id,
          });
        default:
          return undefined;
      }
    }

    function isStoryboardDrilldown() {
      return (
        AppConfig.features.carryStoryboardFilters &&
        ($state.current.name === "openTable" || $state.current.name === "openViz")
      );
    }
  });
