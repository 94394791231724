import Table from "@viz-ui/models/table/table";
import TableAdapter from "@viz-ui/models/table/tableAdapter";
import Recordset from "@viz-ui/models/table/recordset";
import RecordsetAdapter from "@viz-ui/models/table/recordsetAdapter";

angular
  .module("acl.visualizer.model.table", [
    "acl.common.config",
    "acl.common.dataRequester",
    "acl.visualizer.model.filter",
    "acl.visualizer.model.field",
    "acl.visualizer.model.model",
    "acl.visualizer.promiseCache",
  ])
  .factory("Table", () => Table)
  .factory("TableAdapter", () => TableAdapter)
  .factory("Recordset", () => Recordset)
  .factory("RecordsetAdapter", () => RecordsetAdapter);
