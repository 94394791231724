angular.module("acl.common.dataRequester").factory("DataRequester", ($q, $timeout, EventService) => {
  const eventNameToDataRequester = {};

  function DataRequester(eventName) {
    this._eventServiceInstance = EventService.register("acl.visualizer.dataRequester::" + eventName);
    this._eventName = eventName;
    this._requestMap = {};

    this._eventServiceInstance.subscribe("resolve." + this._eventName, (_, token, responseData) => {
      this._resolveToken(token, responseData);
    });

    this._eventServiceInstance.subscribe("reject." + this._eventName, (_, token, error) => {
      this._rejectToken(token, error);
    });
  }

  DataRequester.prototype.destroy = function() {
    if (this._eventServiceInstance) {
      this._eventServiceInstance.unregister();
      this._eventServiceInstance = undefined;
    }
  };

  DataRequester.prototype.request = function(timeout, ...requestData) {
    const deferred = $q.defer();
    const token = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    const request = {
      token: token,
      timeout: timeout,
      deferred: deferred,
      promise: deferred.promise,
      requestData: requestData,
    };

    request.promise = this._cancelAfterTimeout(request, timeout);

    this._requestMap[token] = request;
    this._eventServiceInstance.publish("request." + this._eventName, token, ...request.requestData);

    return deferred.promise;
  };

  DataRequester.prototype._cancelAfterTimeout = function(request) {
    const timeoutDuration = request.timeout;
    const token = request.token;
    if (timeoutDuration > 0) {
      const timeoutPromise = $timeout(() => {
        this._eventServiceInstance.publish(
          "reject." + this._eventName,
          token,
          "DataRequester got a timeout while waiting for a request."
        );
      }, timeoutDuration);

      const cancelTimeout = () => {
        $timeout.cancel(timeoutPromise);
      };
      return request.promise.then(cancelTimeout, cancelTimeout);
    }
    return request.promise;
  };

  DataRequester.prototype._resolveToken = function(token, responseData) {
    if (!this._requestMap[token]) {
      throw Error("Attempted to resolve unexpected token: " + token);
    }

    this._requestMap[token].deferred.resolve(responseData);
  };

  DataRequester.prototype._rejectToken = function(token, error) {
    if (!this._requestMap[token]) {
      throw Error("Attempted to reject unexpected token: " + token);
    }

    this._requestMap[token].deferred.reject(error);
  };

  return {
    getInstance: eventName => {
      let requester = eventNameToDataRequester[eventName];
      if (requester) {
        return requester;
      }

      requester = new DataRequester(eventName);
      eventNameToDataRequester[eventName] = requester;

      return requester;
    },
  };
});
