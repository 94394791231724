import Model from "../model";

class ValueFilter extends Model {
  static modelType = "ValueFilter";

  filterType() {
    return "value";
  }

  name(...args) {
    return this.accessor("name", args, "string");
  }

  operator(...args) {
    return this.accessor("operator", args, "string");
  }

  values(...args) {
    return this.accessor("values", args, ["*"]);
  }

  indeterminateValues(...args) {
    return this.accessor("indeterminateValues", args, ["*"]);
  }

  storyboardIndicatorValues(...args) {
    return this.accessor("storyboardIndicatorValues", args, ["*"]);
  }

  selectAllState(...args) {
    return this.accessor("selectAllState", args, "string");
  }

  unCheckedValues(...args) {
    return this.accessor("unCheckedValues", args, ["*"]);
  }

  static fromJson(json) {
    return new ValueFilter()
      .operator(json.operator)
      .values(json.values)
      .name(json.name)
      .indeterminateValues(json.indeterminateValues)
      .storyboardIndicatorValues(json.storyboardIndicatorValues)
      .selectAllState(json.selectAllState)
      .unCheckedValues(json.unCheckedValues);
  }

  toJson() {
    return this._deleteUndefinedProperties({
      operator: this.operator(),
      values: this.values(),
      name: this.name(),
      indeterminateValues: this.indeterminateValues(),
      storyboardIndicatorValues: this.storyboardIndicatorValues(),
      selectAllState: this.selectAllState(),
      unCheckedValues: this.unCheckedValues(),
    });
  }
}
export default ValueFilter;
