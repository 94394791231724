import ReactDirective from "acl-ui/angular/reactDirective.umd";
import SummaryTableToast from "./summaryTableToast";

angular.module("acl.visualizer.summaryTable", [
  "acl.common.autoHeight",
  "acl.visualizer.model.filter",
  "acl.visualizer.model.summaryTable",
  "acl.visualizer.charts.controllers",
  "acl.visualizer.charts",
])
.directive("summaryTableToast", ReactDirective.getFactory(SummaryTableToast));
require("./hotSummaryTable.component");