import moment from "moment";

const operators = {
  "=": function(reference, accessor) {
    return function(d) {
      return reference.some(function(v) {
        return accessor(d) === v;
      });
    };
  },
  ">": function(reference, accessor) {
    return function(d) {
      return accessor(d) > reference[0];
    };
  },
  "<": function(reference, accessor) {
    return function(d) {
      return accessor(d) < reference[0];
    };
  },
  ">=": function(reference, accessor) {
    return function(d) {
      return accessor(d) >= reference[0];
    };
  },
  "<=": function(reference, accessor) {
    return function(d) {
      return accessor(d) <= reference[0];
    };
  },
  between: function(reference, accessor) {
    var min = Math.min(reference[0], reference[1]);
    var max = Math.max(reference[0], reference[1]);
    return function(d) {
      return accessor(d) >= min && accessor(d) <= max;
    };
  },
  "!=": function(reference, accessor) {
    return function(d) {
      return accessor(d) !== reference[0];
    };
  },
  "^=": function(reference, accessor) {
    return function(d) {
      return (
        accessor(d)
          .toString()
          .indexOf(reference[0]) === 0
      );
    };
  },
  contain: function(reference, accessor) {
    return function(d) {
      return (
        accessor(d)
          .toString()
          .indexOf(reference[0]) > -1
      );
    };
  },
  "!contain": function(reference, accessor) {
    return function(d) {
      return (
        accessor(d)
          .toString()
          .indexOf(reference[0]) < 0
      );
    };
  },
  is_blank: function() {
    return function(d) {
      return !(d && d.length);
    };
  },
  is_not_blank: function() {
    return function(d) {
      return !!(d && d.length);
    };
  },
};

const comparisonFormattersByType = (function() {
  return {
    character: function(d) {
      return (d || "").toLowerCase();
    },
    numeric: function(d) {
      return Number(d);
    },
    date: function(d) {
      var date = moment(d);
      return date.isValid() ? date.valueOf() : null;
    },
    datetime: function(d) {
      var datetime = moment(d);
      return datetime.isValid() ? datetime.valueOf() : null;
    },
    time: function(d) {
      var time = moment(d, "h:mm:ss a");
      return time.isValid() ? time.valueOf() : null;
    },
    logical: function(d) {
      return (!!d).toString().toLowerCase() === "true" ? 1 : 0;
    },
    file: function(d) {
      return d ? d.toLowerCase() : "";
    },
  };
})();

function getConditionPredicate(condition, field) {
  var comparisonFormatter = comparisonFormattersByType[field.type];
  var reference = (condition.values || []).map(function(d) {
    return comparisonFormatter(d);
  });
  var accessor = comparisonFormatter;
  return operators[condition.operator](reference, accessor);
}

function textColorEnabled(condition) {
  return condition.textColorEnabled || condition.textColorEnabled === undefined;
}

function textColor(condition) {
  return condition.textColor;
}

function backgroundColorEnabled(condition) {
  return condition.backgroundColorEnabled || condition.backgroundColorEnabled === undefined;
}

function backgroundColor(condition) {
  return condition.backgroundColor;
}

function iconEnabled(condition) {
  return condition.iconEnabled;
}

function iconName(condition) {
  return condition.icon;
}

function iconColor(condition) {
  return condition.iconColor;
}

class ConditionalFormatting {
  constructor() {
    this.useIdentifier = false;
    this.formattingConditions = {};
  }

  getFormatter(conditionEnabled, conditionFormatter) {
    const that = this;
    return function(fieldName, interpretationIdentifier) {
      return (function() {
        return function(value) {
          var condition;

          let conditions = [];

          if (that.useIdentifier && interpretationIdentifier) {
            conditions = that.formattingConditions[interpretationIdentifier][fieldName] || [];
          } else {
            conditions = that.formattingConditions[fieldName] || [];
          }

          for (let index = 0; index < conditions.length; index++) {
            condition = conditions[index];
            if (condition.predicate(value) && conditionEnabled(condition)) {
              return conditionFormatter(condition);
            }
          }
          return null;
        };
      })();
    };
  }

  updateFormattingConditions(fieldMap, formattingOptions, interpretationIdentifier) {
    const formattingConditions = {};

    if (interpretationIdentifier) {
      this.useIdentifier = true;
    }

    Object.keys(formattingOptions).forEach(fieldName => {
      const field = fieldMap[fieldName];
      if (field) {
        formattingConditions[fieldName] = [];
        (formattingOptions[fieldName].conditions || []).forEach(function(conditionDefinition) {
          const condition = { ...conditionDefinition };
          condition.predicate = getConditionPredicate(condition, field);
          formattingConditions[fieldName].push(condition);
        });
      }
    });

    if (interpretationIdentifier) {
      this.formattingConditions[interpretationIdentifier] = formattingConditions;
    } else {
      this.formattingConditions = {
        ...this.formattingConditions,
        ...formattingConditions,
      };
    }
  }

  getIconClasses() {
    return {
      star: "star",
      flag: "flag",
      like: "thumbs-up",
      "arrow-up": "circle-arrow-up",
      "arrow-down": "circle-arrow-down",
      "arrow-left": "circle-arrow-left",
      "arrow-right": "circle-arrow-right",
      plus: "plus-sign",
      minus: "minus-sign",
      check: "ok-sign",
      times: "remove-sign",
    };
  }

  getTextColorFormatter = this.getFormatter(textColorEnabled, textColor);

  getBackgroundColorFormatter = this.getFormatter(backgroundColorEnabled, backgroundColor);

  getIconColorFormatter = this.getFormatter(iconEnabled, iconColor);

  getIconNameFormatter = this.getFormatter(iconEnabled, iconName);
}

export default new ConditionalFormatting();
