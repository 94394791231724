import logger from "@viz-ui/services/logger/logger";
import ChartData from "./chartData";
import ChartConfigTranslator from "../../services/query/chartConfigTranslator/chartConfigTranslator";

const CONFIG_KEY_MAP = {
  chartSeries: "series",
  chartValue: "values",
  chartValues: "values",
  chartXAxis: "key",
  chartYAxis: "y_axis",
  chartCategory: "key",
  chartRows: "rows",
};
const AGGREGATION_CONFIG_KEYS = ["chartValue", "chartValues"];
// FIXME: should have a consistent yaxis property key once we have migrated from logan backend to query service.
const VALUE_KEY_MAP = {
  chartYAxis: "yaxis",
};
const AGGREGATION_MAP = {
  average: "avg",
};

const ChartDataAdapter = {
  deserializeChartData: deserializeChartData,
};

function deserializeChartData(type, table, dataConfig, json) {
  if (type === "CombinationChart") return normalizeCombinationChartData(table, dataConfig, json);
  if (type === "MapChart") return deserializeMapChartData(type, table, dataConfig, json.columnAgg);
  table = table.clone();

  return new ChartData()
    .type(type)
    .table(table)
    .dataConfig(dataConfig)
    .data({
      config: type !== "Treemap" ? normalizeConfig(dataConfig) : dataConfig,
      values: type !== "Treemap" ? normalizeValues(type, dataConfig, json.columnAgg) : json,
    });
}

function normalizeCombinationChartData(table, dataConfig, aggregationData) {
  let barAggregationData = { columnAgg: aggregationData.barChart };
  let lineAggregationData = { columnAgg: aggregationData.lineChart };
  logger.log(`ComboChart - bar data length: ${barAggregationData.columnAgg.length}`);
  logger.log(`ComboChart - line data length: ${lineAggregationData.columnAgg.length}`);

  let barChartDataConfig = {
    chartXAxis: dataConfig.chartRows[0],
    chartValue: dataConfig.chartValues[0],
  };
  if (dataConfig.chartSeries) {
    barChartDataConfig.chartSeries = dataConfig.chartSeries;
  }
  let lineChartDataConfig = {
    chartXAxis: dataConfig.chartRows[0],
    chartValue: dataConfig.chartValues[1],
  };

  let barChartDeserializedData = deserializeChartData("BarChart", table, barChartDataConfig, barAggregationData);
  let lineChartDeserializedData = deserializeChartData("LineChart", table, lineChartDataConfig, lineAggregationData);
  barChartDeserializedData._data.type = "CombinationChart";
  barChartDeserializedData._data.data = [barChartDeserializedData._data.data, lineChartDeserializedData._data.data];
  barChartDeserializedData._data.dataConfig.chartValues = dataConfig.chartValues;
  delete barChartDeserializedData._data.dataConfig.chartValue;
  return barChartDeserializedData;
}
function normalizeConfig(dataConfig) {
  return Object.keys(dataConfig).reduce((config, key) => {
    const configKey = CONFIG_KEY_MAP[key];
    config[configKey] = {
      field_name: dataConfig[key].aggregationType === "count" ? "*" : dataConfig[key].fieldName,
      data_type: dataConfig[key].type,
    };
    return config;
  }, {});
}

function normalizeValues(type, dataConfig, json) {
  const groupByFields = ChartConfigTranslator.groupByFields(type, dataConfig);
  logger.log(`${type} - data length: ${json.length}`);

  return json
    .filter(entry => entryHasValidGroupByFields(entry, groupByFields))
    .map(entry =>
      Object.keys(dataConfig).reduce((currEntry, configKey) => {
        const entryField = dataConfig[configKey];
        const entryKey = VALUE_KEY_MAP[configKey] || CONFIG_KEY_MAP[configKey];
        if (isAggregationConfigKey(configKey, entryField)) {
          if (entryField.aggregationType === "count") {
            currEntry.values = [entry.count];
          } else if (entryField.fieldName) {
            const aggregationType = AGGREGATION_MAP[entryField.aggregationType] || entryField.aggregationType;
            currEntry.values = [entry.column[aggregationType]];
          }
        } else {
          currEntry[entryKey] = entry[entryField.fieldName];
        }
        return currEntry;
      }, {})
    );
}

function isAggregationConfigKey(configKey, field) {
  return AGGREGATION_CONFIG_KEYS.includes(configKey) && field.aggregationType;
}

function entryHasValidGroupByFields(entry, groupByFields) {
  const entryKeys = Object.keys(entry);
  return groupByFields.every(groupByField => entryKeys.includes(groupByField));
}

function deserializeMapChartData(type, table, dataConfig, jsonData) {
  const groupByFields = ChartConfigTranslator.groupByFields(type, dataConfig);
  logger.log(`${type} - data length: ${jsonData.length}`);

  return new ChartData()
    .type(type)
    .table(table)
    .dataConfig(dataConfig)
    .data({
      config: Object.keys(dataConfig).reduce((config, key) => {
        const configKey = CONFIG_KEY_MAP[key];
        config[configKey] = dataConfig[key].map(configRow => ({
          field_name: configRow.aggregationType === "count" ? "*" : configRow.fieldName,
          data_type: configRow.type,
        }));
        return config;
      }, {}),
      values: jsonData
        .filter(entry => entryHasValidGroupByFields(entry, groupByFields))
        .map(entry =>
          Object.keys(dataConfig).reduce((currEntry, configKey) => {
            const entryField = dataConfig[configKey];
            const entryKey = VALUE_KEY_MAP[configKey] || CONFIG_KEY_MAP[configKey];

            if (entryField[0] && isAggregationConfigKey(configKey, entryField[0])) {
              currEntry[entryKey] = entryField.reduce(function(entryValue, rowValue) {
                if (rowValue.aggregationType === "count") {
                  entryValue["*"] = entry.count;
                } else {
                  const aggregationType = AGGREGATION_MAP[rowValue.aggregationType] || rowValue.aggregationType;
                  entryValue[rowValue.fieldName] = entry.column[aggregationType];
                }
                return entryValue;
              }, {});
            } else {
              currEntry[entryKey] = entryField.map(rowField => {
                const rowFieldName = rowField.fieldName;
                return { [rowFieldName]: entry[rowFieldName] };
              });
            }
            return currEntry;
          }, {})
        ),
    });
}

export default ChartDataAdapter;
