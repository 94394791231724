import QueryScope from "@viz-ui/services/query/queryScope/queryScope";
import {
  storyboardIdFinder,
  storyboardUidFinder,
  isNewStoryboards,
  isPublicStoryboard,
  getLoganBasePath,
} from "@viz-ui/services/storyboardUrlDetection/storyboardUrlDetection";
import { UsageTracker, UsageTrackerHelper } from "@visualizer/common/services/usageTracker/usageTracker";
import globalFiltersStore from "@storyboards/modules/globalFilters/services/GlobalFiltersStore";
import User from "@storyboards/modules/services/user.service";
import StoryboardDataStore from "@storyboards/modules/storyboardDataStore/storyboardDataStore.service";

import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import UrlFlipper from "@viz-ui/services/urlFlipper/urlFlipper";
import logger from "@viz-ui/services/logger/logger";
import ApiService from "@storyboards/services/ApiService/ApiService";
import ScrollMonitor from "../scrollMonitor/scrollMonitor";
import BoardOrganizer from "../boardOrganizer/boardOrganizer.service";

import { StoryboardBackend } from "../storyboard/storyboard.backend";
import "@viz-ui/components/vizMonitor/vizMonitor";
import backendApi from "@results/services/apiCall/backendApi";
import { PAGE_HEADER_TITLE_ID } from "@viz-ui/components/PageHeader/PageHeader";
import LocalStorageHelper from "@viz-ui/services/localStorage/localStorageHelper";
import DynamicFilter from "../globalFilters/services/dynamicFilter";
import addScript from "@results/services/addScripts/addScripts";

angular
  .module("acl.storyboard")
  .controller("StoryboardAppController", function(
    $document,
    $location,
    $scope,
    $timeout,
    $window,
    AppConfig,
    BoardDataLoader,
    ComponentLoader,
    Localize,
    Storyboard,
    UnsavedData
  ) {
    logger.setFlipper(AppConfig.features.debugLogger);
    logger.log(">>>>>> Storyboard App started");

    $scope.isLoading = true;
    $scope.deletedViz = [];

    const filtersFromResults = [];

    const {
      getStoryboard,
      getStoryboardUser,
      getPublicStoryboard,
      getPublicStoryboardUser,
      getUsers,
      getAdminUsers,
      getGroups,
      getLaunchpadGroups,
    } = StoryboardBackend;
    if (isPublicStoryboard(window.location.href)) User.setStoryboardRole("anonymous");
    const storyboardUserRequest = isPublicStoryboard(window.location.href)
      ? getPublicStoryboardUser
      : getStoryboardUser;
    $scope.isPublicLink = isPublicStoryboard(window.location.href);
    if (!User.isAnonymous()) {
      $scope.accessMonitorScript = true;
    }

    if (isNewStoryboards(window.location.href)) {
      storyboardUserRequest().then(storyboardUserData => init(null, storyboardUserData));
    } else {
      const id = User.isAnonymous()
        ? storyboardUidFinder(window.location.href)
        : storyboardIdFinder(window.location.href);
      const request = User.isAnonymous() ? getPublicStoryboard : getStoryboard;
      logger.log("getStoryboard & getStoryboardUser - start");
      Promise.all([request(id), storyboardUserRequest(id)])
        .then(([storyboardData, storyboardUserData]) => {
          logger.log("getStoryboard & getStoryboardUser - End");
          init(storyboardData, storyboardUserData);
        })
        .catch(error => storyboardErrorHandler(error));
    }

    var storyboard;
    let hasOpenedEditMode = false;
    var platformName = AppConfig.features.galvanizeHighbond ? "HighBond" : "ACL";

    let scrollMonitor = new ScrollMonitor("storyboard-wrapper", "storyboard-row", boardDataHandler);
    $scope.boardModels = {};

    async function init(storyboardData, storyboardUserData) {
      $scope.isLoading = false;
      if (UrlFlipper.isRenderMode("export")) {
        AppConfig.features.lazyLoadingBoards = false;
      }
      initUserData(storyboardUserData);
      $scope.userBelongsToOrg = storyboardUserData.user_belongs_to_org;
      // Setting xClientIp address from the storyboardUserData
      if (storyboardUserData && storyboardUserData.user_ip) {
        window.xClientIp = storyboardUserData.user_ip;
      }
      storyboard = $scope.storyboard = new Storyboard(storyboardData);
      AppConfig.storyboard = storyboard;
      if (storyboardData) {
        const { config, id, name, uid } = storyboardData;
        StoryboardDataStore.setData({ config, id, name, uid });
      }

      $scope.user = User;

      $scope.storyBoardName = AppConfig.storyboard.getName();

      setDocumentTitle($scope.storyBoardName || Localize.getLocalizedString("_Storyboards.New_"));

      $scope.presentationMode = getPresentationModeParam() === "true";
      if ($scope.presentationMode) UsageTrackerHelper.setTrackerMode("present");

      $scope.editMode = !storyboard.getId();
      hasOpenedEditMode = $scope.editMode;

      QueryScope.setStoryboardId(storyboard.getId());

      UsageTracker.createEvent(`.open${storyboard.getId() || User.isAnonymous() ? "" : ".new"}`);

      $scope.message = {
        text: "",
        type: "success",
        onClose: clearMessage,
      };
      UnsavedData.set(false);
      initMessages();
      initUnsavedChanges();

      $scope.$watch(
        function() {
          return storyboard.isNameValid();
        },
        function(newValue) {
          setStoryboardActionProps();
        }
      );

      $scope.$watch(
        function() {
          return storyboard.hasUnsavedData();
        },
        function(newValue) {
          setStoryboardActionProps();
        }
      );

      $scope.$watch("editMode", function(newValue) {
        setStoryboardActionProps();
      });

      $scope.handleLoadMoreData = async function(board) {
        if (board.type === "table") {
          let boardModel = await BoardDataLoader.loadMoreBoardData(board, $scope.boardModels[board.id]);
          $scope.boardModels[board.id] = boardModel;
        }
      };

      $scope.toggleBarProps = {
        showDesignToggle: User.canEditStoryboard() || !storyboard.isSaved(),
        editMode: $scope.editMode,
        onDesignModeToggle: setEditMode,
      };

      $scope.storyboardActionPanelProps = {
        enableDeleteButton: false,
        enableFilterButton: false,
        enablePresentationButton: true,
        enableSaveButton: false,
        enableShareButton: false,
        onDeleteConfirm: deleteStoryboard,
        onFilterClick: toggleFilterPanel,
        onPresentationClick: gotoPresentationMode,
        onPatternFillClick: togglePatternFill,
        onShareClick: toggleSharePanel,
        onSaveClick: saveStoryboard,
        onSaveAsClick: saveAsStoryboard,
        saveButtonTip: false,
        showActionPanel: false,
        showDeleteButton: false,
        showFilterButton: false,
        showShareButton: false,
        usePatternFill: LocalStorageHelper.get("applyPatternFill"),
        flipDuplicateStoryboards: AppConfig.features.duplicateStoryboards,
      };

      initGlobalNavBar();

      setShareStoryboardProps();
      setStoryboardActionProps();

      $scope.exportStoryboardToPDFProps = {
        isOpen: false,
        onClose: closeExportToPDF,
        onExport: async (options, pdfOptions) => {
          const isPatternFill = LocalStorageHelper.get("applyPatternFill") || false;
          const storyboardUrl = window.location.href;
          const { chartFitToPage, displayFilters } = options;
          const exportOptions = {
            options: {
              title: $scope.storyboard.getOriginalStoryboardName(),
              url:
                storyboardUrl +
                `?mode=export&chartFitToPage=${chartFitToPage}&displayFilters=${displayFilters}&isPatternFill=${isPatternFill}`,
              logoUrl: $scope.organization.logo,
              ...options,
            },
            pdfOptions,
          };
          closeExportToPDF();
          showDownloadModal();
          const response = await new ApiService().exportAsPdf(exportOptions);
          if (response.ok) {
            const store = await response.json();
            $scope.exportStoryboardToPDFProps.showDownload = true;
            $scope.exportStoryboardToPDFProps.downloadLink = store.reportUrl;
            $scope.exportStoryboardToPDFProps.fileName = options.fileName;
          } else {
            $scope.exportStoryboardToPDFProps.downloadError = true;
          }
        },
        isModalOpen: false,
        hideDownloadModal,
        showDownload: false,
        downloadLink: "",
        fileName: "",
        pdfPasswordOptionsFlipper: AppConfig.features.pdfPasswordOptions,
      };
      $scope.filterPanelProps = {
        canValidate: false,
        tableIds: storyboard.getUniqTableIds(),
        onApplyGlobalFilters: handleApplyGlobalFilters,
        flipConditional: AppConfig.features.globalFiltersConditional,
        flipRelativeFilters: AppConfig.features.relativeDateFilters,
        flipMultiselect: AppConfig.features.globalFiltersMultiselect,
        flipQueryService: AppConfig.features.queryService,
        flipUnformattedValues: AppConfig.features.unformattedValues,
        isConfigPanelOpen: false,
        onClose: toggleFilterPanel,
        mode: filterPanelEditMode($scope.editMode),
        setConfigPanelOpen: setConfigPanelOpen,
        setUnsavedChanges: setUnsavedChanges,
        shouldFilterPanelForceUpdate: false,
        setShouldFilterPanelForceUpdate: setShouldFilterPanelForceUpdate,
        flipDynamicFilter: storyboard.isDynamicStoryboardFilterEnabled(),
      };
      $scope.leftNavigationProps = {
        showToast: false,
        searchIds: null,
        searchValues: null,
        searchValue: "",
        showSearchPopover: false,
        noSearchResults: false,
        hasFullStats: false,
      };

      boardChangeHandler(BoardOrganizer.getBoards());
    }

    function closeExportToPDF() {
      $scope.exportStoryboardToPDFProps.isOpen = false;
    }

    function openExportToPDF() {
      $scope.exportStoryboardToPDFProps.isOpen = true;
    }

    function showDownloadModal() {
      $scope.exportStoryboardToPDFProps.downloadError = false;
      $scope.exportStoryboardToPDFProps.showDownload = false;
      $scope.exportStoryboardToPDFProps.downloadLink = "";
      $scope.exportStoryboardToPDFProps.isModalOpen = true;
    }

    function hideDownloadModal() {
      $scope.exportStoryboardToPDFProps.isModalOpen = false;
    }

    function initUserData(userData) {
      const {
        role,
        current_user: currentUser,
        timezone_sec_offset: timezoneSecOffset,
        can_create_storyboard: canCreateStoryboard,
        purpose,
      } = userData;
      User.setStoryboardRole(role);
      User.setCurrentUser(currentUser);
      User.setCanCreateStoryboard(canCreateStoryboard);
      setTimezoneOffset(timezoneSecOffset);
    }

    function storyboardErrorHandler(error) {
      const errorMessage = getErrorMessage(error.status, User.isAnonymous());
      $scope.message = {
        text: errorMessage,
        type: "error",
      };
      initGlobalNavBar();
    }

    // Function to return valid error messages based on API error status code
    function getErrorMessage(errorStatusCode, isAnonymous) {
      // Returning blank in case error status code is passed as undefined (to Handle 302 errors)
      if (!errorStatusCode) {
        $scope.isLoading = true;
        return "";
      }

      $scope.isLoading = false;
      if (isAnonymous && errorStatusCode === 404) {
        return Localize.getLocalizedString("_Storyboards.Errors.InvalidStoryboardLink_");
      }
      if (errorStatusCode === 401) {
        return Localize.getLocalizedString("_Storyboards.Errors.StoryboardLinkAccessError_");
      }
      return Localize.getLocalizedString("_Storyboards.Errors.StoryboardLoadingError_");
    }

    function initGlobalNavBar() {
      //importing the globalNavBar script
      addScript(`${backendApi.getWebComponentsUrl()}/global-navigator/index.js`);
      $scope.highbondNavBarProps = AppConfig.highbondNavBarProps;
      $scope.showHighbondNavigation = AppConfig.features.highbondNavigationInVisualizer;
      setOrganization();
    }

    function setOrganization() {
      const userDetails = $scope.highbondNavBarProps.appSwitcherProps;
      const orgId = userDetails.initialOrganizationId;
      const userOrg = userDetails.organizations.find(org => org.id === orgId);
      $scope.organization = userOrg || {};
    }

    $scope.handleAddBoardToRow = async (board, row) => {
      BoardOrganizer.addRow(row);
      BoardOrganizer.addBoard(board, { row, col: 0 });
      UnsavedData.set(true);
      scrollMonitor.reset();
      boardChangeHandler([board]);
    };

    $scope.handleAddBoardToPosition = (board, position) => {
      BoardOrganizer.addBoard(board, position);
      boardChangeHandler([board]);
      UnsavedData.set(true);
    };

    function combineFilterNames(filters) {
      return filters.map(filter => filter.name).join(", ");
    }

    function setShouldFilterPanelForceUpdate(isForceUpdate) {
      $scope.filterPanelProps.shouldFilterPanelForceUpdate = isForceUpdate;
    }

    $scope.handleRemoveBoard = board => {
      const prevNumOfRows = $scope.boardsRow.length;
      BoardOrganizer.removeBoard(board.id);
      const deleteInvalidGlobalFilter = AppConfig.features.deleteInvalidStoryboardGlobalFilters;
      if (deleteInvalidGlobalFilter) {
        let inValidGlobalFilters = storyboard.getInvalidGlobalFilters();
        if (inValidGlobalFilters.length) {
          let filterNames = combineFilterNames(inValidGlobalFilters);
          let errorMessage = Localize.getLocalizedStringWithTokenReplacement(
            "_Storyboards.GlobalFilters.RemoveFilter.Error_",
            [filterNames]
          );
          $scope.message = {
            text: errorMessage,
            type: "warning",
            onClose: clearMessage,
          };
        }
      }
      $scope.boardsRow = BoardOrganizer.getBoardRows();
      if (prevNumOfRows !== $scope.boardsRow.length) {
        scrollMonitor.reset();
        $timeout(() => scrollMonitor.setup());
      }
      UnsavedData.set(true);
      UsageTracker.createEvent(".remove");
    };

    $scope.handleBoardMove = function() {
      $scope.boardsRow = BoardOrganizer.getBoardRows();
      UnsavedData.set(true);
    };

    async function boardChangeHandler(boards) {
      $scope.boardsRow = BoardOrganizer.getBoardRows();
      $scope.filterPanelProps.tableIds = storyboard.getUniqTableIds();

      logger.log(`load interpretations for boards - Start`);
      await BoardDataLoader.loadInterpretationsForBoards(boards);
      logger.log(`load interpretations for boards - End`);

      if (AppConfig.features.lazyLoadingBoards && !$scope.presentationMode) {
        $timeout(() => {
          logger.log(`scroll monitor setup`);
          scrollMonitor.setup();
        });
      } else {
        boards.forEach(board => {
          loadBoardData(board);
        });
      }
    }

    async function boardDataHandler() {
      const { priorityRenderRows } = scrollMonitor;
      const { delayedRenderRows } = scrollMonitor;
      const { delayedDataLoadingRows } = scrollMonitor;

      const priorityRenderBoards = BoardOrganizer.getBoardsByRow(priorityRenderRows);
      await loadBoardsData(priorityRenderBoards, true);

      $timeout(async () => {
        const delayedRenderBoards = BoardOrganizer.getBoardsByRow(delayedRenderRows);
        await loadBoardsData(delayedRenderBoards, true);

        $timeout(async () => {
          const delayedDataLoadingBoards = BoardOrganizer.getBoardsByRow(delayedDataLoadingRows);
          loadBoardsData(delayedDataLoadingBoards, false);
        });
      });
    }

    function loadBoardsData(boards, shouldRenderBoard) {
      return Promise.all(
        boards.map(async board => {
          if (!$scope.boardModels[board.id]) {
            let boardModel = await BoardDataLoader.loadBoard(board);
            if (boardModel && shouldRenderBoard) {
              boardModel.showBoard(true);
              $scope.boardModels[board.id] = boardModel;
              // checking for the dynamic storyboard filter
              if (storyboard.isDynamicStoryboardFilterEnabled()) {
                let model;
                // checking the type of the board model
                if (boardModel.type() === "table" || boardModel.type() === "visualization") {
                  model = boardModel.interpretation();
                }
                if (boardModel.type() === "metric") {
                  model = boardModel.metric();
                }
                if (model && model.filterConfig.filterList) {
                  const isFound = filtersFromResults.find(
                    resultFilter => resultFilter.tableId === boardModel.tableId()
                  );
                  if (!isFound) {
                    filtersFromResults.push({
                      tableId: boardModel.tableId(),
                      filterConfig: model.filterConfig,
                    });
                  }
                  // Adding filtersFromResult to the window object
                  window.filtersFromResults = filtersFromResults;
                }
              }
            }
          }
          return Promise.resolve($scope.boardModels[board.id]);
        })
      );
    }

    async function loadBoardData(board) {
      let boardModel = await BoardDataLoader.loadBoard(board);
      if (boardModel) {
        boardModel.showBoard(true);
        $scope.boardModels[board.id] = boardModel;
      }
    }

    function initMessages() {
      if ($window.AclExceptionStoryboardMessage && $window.AclExceptionStoryboardMessage.text) {
        $scope.message.text = $window.AclExceptionStoryboardMessage.text;

        if ($window.AclExceptionStoryboardMessage.type === "error" && User.isAnonymous()) {
          $scope.errorMode = true;
          $scope.errorMessage = angular.copy($window.AclExceptionStoryboardMessage);
        }
      } else if (backendApi.isStoryboardSPA() && sessionStorage.getItem("storyboardInitLoadStatus") === "CREATED") {
        $scope.message.text = Localize.getLocalizedString("_Storyboards.Created_");
        $scope.message.type = "success";
        sessionStorage.removeItem("storyboardInitLoadStatus");
      }
    }

    function initUnsavedChanges() {
      watchForBrowserLeave();
      watchForNavBarLeave();
      watchForResultsSideNavLeave();
    }

    function setTimezoneOffset(timezoneOffsetSeconds) {
      if (AppConfig.features.timezoneShifting && timezoneOffsetSeconds) {
        GlobalValueFormatter.setUtcOffset(timezoneOffsetSeconds);
      }
    }

    function watchForBrowserLeave() {
      $window.onbeforeunload = function(e) {
        if (UnsavedData.get() && hasOpenedEditMode) {
          var message = Localize.getLocalizedString("_Storyboards.Errors.UnsavedChanges_"),
            e = e || $window.event;
          // For IE and Firefox
          if (e) {
            e.returnValue = message;
          }
          // For Safari
          return message;
        }
      };
    }

    function watchForNavBarLeave() {
      $(".navbar a").on("click", function(e) {
        triggerUnsavedModal(e);
      });
    }

    function triggerUnsavedModal(event) {
      if (UnsavedData.get() && hasOpenedEditMode) {
        event.preventDefault();
        var href = angular.element(event.currentTarget).attr("href");
        if (href !== "#") openUnsavedModal(href);
      }
    }

    function watchForResultsSideNavLeave() {
      $("body").on("click", ".results-nav a", function(e) {
        if (UnsavedData.get() && hasOpenedEditMode) {
          e.preventDefault();
          var href = angular.element(e.currentTarget).attr("href");
          openUnsavedModal(href);
        }
      });
    }

    function openUnsavedModal(href) {
      renderUnsavedModal({ isOpen: true, href });
      UsageTracker.createEvent(".modal.unsaved");
    }

    function closeUnsavedModal() {
      let props = { isOpen: false };
      renderUnsavedModal(props);
    }

    function cancelUnsavedModal() {
      closeUnsavedModal();
      UsageTracker.createEvent(".modal.unsaved.cancel");
    }

    function renderUnsavedModal({ isOpen, href }) {
      let props = {
        isOpen,
        onCancel: cancelUnsavedModal,
        onClose: cancelUnsavedModal,
        onSave: () => {
          if (storyboard.getName()) {
            saveStoryboard().then(() => {
              closeUnsavedModal();
              $window.location.href = href;
            });
          } else {
            closeUnsavedModal();
            setStoryboardNoNameErr();
          }
        },
        onDiscard: () => {
          UnsavedData.set(false);
          cancelUnsavedModal();
          $window.location.href = href;
        },
      };
      ComponentLoader.saveModal(props, angular.element(".unsaved-changes-modal")[0]);
    }

    function setStoryboardActionProps() {
      $scope.storyboardActionPanelProps.enableDeleteButton = storyboard.isSaved();
      $scope.storyboardActionPanelProps.enablePresentationButton = enablePresentationButton();
      $scope.storyboardActionPanelProps.enableSaveButton = enableSaveButton();
      $scope.storyboardActionPanelProps.enableShareButton = storyboard.isSaved();
      $scope.storyboardActionPanelProps.enableFilterButton = enableFilterButton();
      $scope.storyboardActionPanelProps.saveButtonTip = getSaveButtonTipMessage();
      $scope.storyboardActionPanelProps.filterButtonTip = getFilterButtonTipMessage();
      $scope.storyboardActionPanelProps.showSaveButton =
        User.canEditStoryboard() || !storyboard.isSaved() || User.canViewAndDuplicateStoryboard();
      $scope.storyboardActionPanelProps.showSaveAsButton = storyboard.isSaved() && User.canCreateStoryboard();
      $scope.storyboardActionPanelProps.storyboardName = storyboard.getOriginalStoryboardName();
      $scope.storyboardActionPanelProps.showDeleteButton = User.isAdmin();
      $scope.storyboardActionPanelProps.showShareButton = User.isAdmin();
      $scope.storyboardActionPanelProps.showFilterButton = showFilterButton();
    }

    function showFilterButton() {
      if (User.isAnonymous()) {
        return globalFiltersStore.hasFilters();
      }
      return true;
    }

    function enableSaveButton() {
      return storyboard.isNameValid() && storyboard.hasUnsavedData() && $scope.editMode;
    }

    function enablePresentationButton() {
      return !storyboard.hasUnsavedData() && !$scope.editMode;
    }

    function enableFilterButton() {
      return ($scope.editMode && storyboard.hasControlTests()) || globalFiltersStore.hasFilters();
    }

    function getSaveButtonTipMessage() {
      if (!storyboard.isNameValid()) {
        return Localize.getLocalizedString("_Storyboards.SaveTip.NeedsName_");
      }
      if (!$scope.editMode) {
        return Localize.getLocalizedString("_Storyboards.SaveTip.GoToEditMode_");
      }
      if (!storyboard.hasUnsavedData()) {
        return Localize.getLocalizedString("_Storyboards.SaveTip.Nothing_");
      }
      return "";
    }

    function getFilterButtonTipMessage() {
      if (!$scope.editMode && User.canEditStoryboard() && !globalFiltersStore.hasFilters()) {
        return Localize.getLocalizedString("_Storyboards.FilterTip.EditorMode_");
      }
      return Localize.getLocalizedString("_Storyboards.FilterTip_");
    }

    function setEditMode(value) {
      $scope.editMode = value;
      let mode = filterPanelEditMode(value);
      $scope.filterPanelProps.mode = mode;
      $scope.filterPanelProps.isConfigPanelOpen = false;
      setStoryboardActionProps();
      UsageTracker.createEvent(`.${mode}`);
      if (mode === "editMode") hasOpenedEditMode = true;
      if ($scope.editMode) {
        setDeletedBoardModels($scope.boardModels);
        let count = $scope.deletedViz.length;
        if (count) {
          showInvalidBoardCountMessage(count);
        }
      }
    }

    /* 
    Created a function to show the deleted visualization count
    */

    function showInvalidBoardCountMessage(count) {
      let errorMessage = Localize.getLocalizedStringWithTokenReplacement(
        "_Storyboards.showInvalid.visualization,Message_",
        [count]
      );
      $scope.message = {
        text: errorMessage,
        type: "error",
        onClose: clearMessage,
      };
    }

    //checking for deleted interpretation and metrics which is deleted only
    function setDeletedBoardModels(boardModels) {
      $scope.deletedViz = [];
      Object.keys(boardModels).forEach(boardModel => {
        if (
          boardModels[boardModel]._data.boardError &&
          (boardModels[boardModel]._data.boardError.originalError() === "INTERPRETATION_DELETED" ||
            boardModels[boardModel]._data.boardError.originalError() === "404")
        ) {
          $scope.deletedViz.push(boardModel);
        }
      });
    }

    function filterPanelEditMode(isEditMode) {
      return isEditMode ? "editMode" : "viewMode";
    }

    function setDocumentTitle(name) {
      $document[0].title = `${name} - ${AppConfig.application.name} - ${platformName}`;
    }

    function setPageHeaderTitle(name) {
      const element = $document[0].getElementById(PAGE_HEADER_TITLE_ID);
      element.innerText = name;
      element.title = name;
    }

    function saveStoryboard() {
      const isValid = globalFiltersStore.hasOnlyValidFilters();
      $scope.filterPanelProps.canValidate = !isValid;
      const deleteInvalidGlobalFilter = AppConfig.features.deleteInvalidStoryboardGlobalFilters;
      if (isValid) {
        const storyboardId = storyboard.getId();
        return storyboard.save().then(function(data) {
          UnsavedData.set(false);
          if (storyboardId === undefined) {
            sessionStorage.setItem("storyboardInitLoadStatus", "CREATED");
            $window.location.href = "/storyboards/" + data.id;
          } else {
            $scope.message.text = Localize.getLocalizedString("_Storyboards.Saved_");
            $scope.message.type = "success";
            setDocumentTitle(data.name);
            setPageHeaderTitle(data.name);
            if (deleteInvalidGlobalFilter) {
              storyboard.setData(data, false);
              $scope.filterPanelProps.tableIds = storyboard.getUniqTableIds();
              setShouldFilterPanelForceUpdate(true);
            }
          }
        });
      }
    }

    function saveAsStoryboard(storyboardName, copyFilters) {
      return storyboard.saveAs(storyboardName, copyFilters).then(function(data) {
        UnsavedData.set(false);
        sessionStorage.setItem("storyboardInitLoadStatus", "CREATED");
        $window.location.href = "/storyboards/" + data.id;
      });
    }

    function setStoryboardNoNameErr() {
      $scope.$apply(function() {
        $scope.message.text = Localize.getLocalizedString("_Storyboards.Errors.NoStoryboardName_");
        $scope.message.type = "warning";
      });
    }

    function deleteStoryboard() {
      UnsavedData.set(false);
      storyboard.delete().then(() => {
        $window.location.href = `${getLoganBasePath()}/storyboards`;
      });
    }

    function toggleFilterPanel() {
      const isFilterPanelOpen = !$scope.filterPanelProps.isOpen;
      $scope.filterPanelProps.isOpen = isFilterPanelOpen;
      UsageTracker.createEvent(`.filterpanel.${isFilterPanelOpen ? "opened" : "closed"}`);
      $scope.filterPanelProps.isConfigPanelOpen = false;
      setStoryboardActionProps();
    }

    function gotoPresentationMode() {
      UsageTracker.createEvent(".present.buttonclicked");
      $window.open($window.location.href + "?presentationMode=true", "_blank");
    }

    function togglePatternFill() {
      const isPatternFillApplied = !$scope.storyboardActionPanelProps.usePatternFill;
      LocalStorageHelper.set("applyPatternFill", isPatternFillApplied);
      $scope.storyboardActionPanelProps.usePatternFill = isPatternFillApplied;
      rerenderStoryboard();
    }

    function getPresentationModeParam() {
      let presentationModeParam = $window.location.search
        .substr(1)
        .split("&")
        .find(param => param.split("=")[0] === "presentationMode");
      return $location.search().presentationMode || (presentationModeParam && presentationModeParam.split("=")[1]);
    }

    function toggleSharePanel() {
      $scope.shareStoryboardProps.isOpen = !$scope.shareStoryboardProps.isOpen;
    }

    function handleApplyGlobalFilters() {
      const isValid = globalFiltersStore.hasOnlyValidFilters();
      $scope.filterPanelProps.canValidate = !isValid;
      if (isValid) {
        globalFiltersStore.resetDrilldownFilters();
        $scope.globalFilters = globalFiltersStore.get();
        DynamicFilter.setCurrentDynamicFilter($scope.globalFilters);
        rerenderStoryboard();
      }
    }

    function rerenderStoryboard() {
      if (AppConfig.features.lazyLoadingBoards) {
        $scope.boardModels = {};
      }
      storyboard.resetData();
      reloadBoardData();
    }

    function reloadBoardData() {
      if (AppConfig.features.lazyLoadingBoards && !$scope.presentationMode) {
        boardDataHandler();
      } else {
        boardChangeHandler(BoardOrganizer.getBoards());
      }
    }

    function setUnsavedChanges() {
      if (User.canEditStoryboard()) {
        UnsavedData.set(true);
      }
    }

    function setConfigPanelOpen(isOpen) {
      $scope.filterPanelProps.isConfigPanelOpen = isOpen;
    }

    function clearMessage() {
      $scope.message.text = "";
    }

    function getLaunchpadGroupIds() {
      if (
        (backendApi.isStoryboardSPA() || AppConfig.environment == AppConfig.environments.DEV) &&
        !User.isAnonymous()
      ) {
        getLaunchpadGroups(AppConfig.highbondNavBarProps.appSwitcherProps.initialOrganizationId).then(
          launchpadGroups => {
            $window.launchpadGroups = launchpadGroups;
          }
        );
      }
    }

    async function setShareStoryboardProps() {
      $scope.shareStoryboardProps = {
        storyboardId: storyboard.getId(),
        storyboardLink: storyboard.getUid(),
        linkType: storyboard.getLinkType(),
        storyboardShareLink: storyboard.getStoryboardLink(),

        selectedGroups: storyboard.getAssociatedWorkflowGroups(),

        selectedLaunchpadGroups: storyboard.getAssociatedLaunchpadGroups(),
        creatorId: storyboard.getCreatorId(),
        isOpen: false,
        onClose: toggleSharePanel,
        exportStoryboardFlipper: AppConfig.features.exportStoryboard,
        onExportStoryboardToPDFClick: openExportToPDF,
        showLinkNav: User.isAdmin(),
        showMemberNav: User.isAdmin(),
      };
    }
  });
