import Table from "@viz-ui/models/table/table";
import Recordset from "@viz-ui/models/table/recordset";
import Board from "./board";

export default class TableBoard extends Board {
  static modelType = "TableBoard";

  controlTestId(...args) {
    return this.accessor("controlTestId", args, "number");
  }

  canDrilldown(...args) {
    return this.accessor("canDrilldown", args, "boolean");
  }

  interpretation(...args) {
    return this.accessor("interpretation", args, Object);
  }

  table(...args) {
    return this.accessor("table", args, Table);
  }

  recordset(...args) {
    return this.accessor("recordset", args, Recordset);
  }

  isValid() {
    return this.interpretation() && this.table() && this.recordset();
  }

  clone() {
    return new TableBoard(this._data);
  }
}
