import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "acl-ui/components/Button";
import "./Footer.scss";
import i18n from "@viz-ui/i18n/i18n";

export default class Footer extends Component {
  static propTypes = {
    activeRowId: PropTypes.number.isRequired,
    numSelectedRecords: PropTypes.number.isRequired,
    onShowProcessRecordSubPanel: PropTypes.func.isRequired,
    onShowReviewRecordsSubPanel: PropTypes.func.isRequired,
  };

  renderReviewRecordsText() {
    const { numSelectedRecords } = this.props;
    const translationPrefix = "_RecordProcessing.ReviewRecords";

    if (numSelectedRecords === 0) {
      return i18n.t(`${translationPrefix}.ZeroSelected_`);
    }
    if (numSelectedRecords === 1) {
      return i18n.t(`${translationPrefix}.OneSelected_`);
    }
    return i18n.t(`${translationPrefix}.ManySelected_`, { count: numSelectedRecords });
  }

  render() {
    const { onShowProcessRecordSubPanel, activeRowId, numSelectedRecords, onShowReviewRecordsSubPanel } = this.props;
    return (
      <footer className="record-processing__footer">
        <Button
          aria-haspopup
          key="processRecord"
          id="record-processing-process-records-button"
          type="primary"
          onClick={onShowProcessRecordSubPanel}
        >
          {i18n.t("_RecordProcessing.ProcessRecordRowId_", { rowId: activeRowId })}
        </Button>
        <Button
          aria-haspopup
          id="record-processing-review-records-button"
          className="record_processing__review-records"
          isDisabled={numSelectedRecords < 1}
          key="review"
          type="secondary"
          onClick={onShowReviewRecordsSubPanel}
        >
          {this.renderReviewRecordsText()}
        </Button>
      </footer>
    );
  }
}
