import WorldCountries from "@highcharts/map-collection/custom/world.geo.json";
import WorldContinents from "@highcharts/map-collection/custom/world-continents.geo.json";
import USAStates from "@highcharts/map-collection/countries/us/us-all.geo.json";
import Europe from "@highcharts/map-collection/custom/europe.geo.json";
import NorthAmerica from "@highcharts/map-collection/custom/north-america.geo.json";
import Asia from "@highcharts/map-collection/custom/asia.geo.json";
import Africa from "@highcharts/map-collection/custom/africa.geo.json";
import SouthAmerica from "@highcharts/map-collection/custom/south-america.geo.json";
import Oceania from "@highcharts/map-collection/custom/oceania.geo.json";
import China from "@highcharts/map-collection/countries/cn/cn-all.geo.json";
import Australia from "@highcharts/map-collection/countries/au/au-all.geo.json";
import proj4 from "proj4";

import { HighChartDirective, HighChartController } from "../highChartDirective";
import { MAP_CHART } from "../boost/boostThresholds";

// add proj4 to window, so HighCharts will be able to find it
if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}
const blankKey = "(blank)";
const zoomOutIcon =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMi43NjM2MiAzLjk1NTU0SDQuNTA5MDZWMi43OTk5OUgyLjc2MzYyQzIuMTIxODYgMi43OTk5OSAxLjU5OTk4IDMuMzE4MjUgMS41OTk5OCAzLjk1NTU0VjUuNjg4ODhIMi43NjM2MlYzLjk1NTU0WiIgZmlsbD0iIzMzMzMzMyIvPg0KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4yMzY0IDIuNzk5OTlIMTEuNDkwOFYzLjk1NTU0SDEzLjIzNjRWNS42ODg4OEgxNC40VjMuOTU1NTRDMTQuNCAzLjMxODI1IDEzLjg3OCAyLjc5OTk5IDEzLjIzNjQgMi43OTk5OVoiIGZpbGw9IiMzMzMzMzMiLz4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNC41MDkwNiAxMy4yVjEyLjA0NDVIMi43NjM2MlYxMC4zMTExSDEuNTk5OThWMTIuMDQ0NUMxLjU5OTk4IDEyLjY4MTggMi4xMjE4NiAxMy4yIDIuNzYzNjIgMTMuMkg0LjUwOTA2WiIgZmlsbD0iIzMzMzMzMyIvPg0KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4yMzY0IDEyLjA0NDVIMTEuNDkwOFYxMy4ySDEzLjIzNjRDMTMuODc4IDEzLjIgMTQuNCAxMi42ODE4IDE0LjQgMTIuMDQ0NVYxMC4zMTExSDEzLjIzNjRWMTIuMDQ0NVoiIGZpbGw9IiMzMzMzMzMiLz4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS4wOTA4OSA2LjI2NjY3SDEwLjkwOTFWOS43MzMzNkg1LjA5MDg5VjYuMjY2NjdaTTMuOTI3MjUgMTAuODg4OUgxMi4wNzI3VjUuMTExMTFIMy45MjcyNVYxMC44ODg5WiIgZmlsbD0iIzMzMzMzMyIvPg0KPC9zdmc+";
class MapChartDirective extends HighChartDirective {
  controller = Controller;

  static factory = () => new MapChartDirective();
}

const baseFontSizeConfigPath = [
  "plotOptions.series.dataLabels.style.fontSize",
  "tooltip.style.fontSize",
  "xAxis.labels.style.fontSize",
  "xAxis.title.style.fontSize",
  "yAxis.labels.style.fontSize",
  "yAxis.title.style.fontSize",
];
const colorByFontSizeConfigPath = ["colorAxis.labels.style.fontSize", "legend.itemStyle.fontSize"];

export class Controller extends HighChartController {
  static configPath = {
    labelFontSize: baseFontSizeConfigPath,
    labelFontSizeWithColorBy: [...colorByFontSizeConfigPath, ...baseFontSizeConfigPath],
  };

  constructor($scope, $timeout, HighChartLabelSizer, MapChart, Localize) {
    "ngInject";
    super($scope, $timeout, HighChartLabelSizer);
    this.MapChart = MapChart;
    this.Localize = Localize;
  }

  static ISO_FORMAT = {
    ISOa2: "iso-a2",
    ISOa3: "iso-a3",
    HcKey: "hc-key",
    Name: "name",
  };

  getISOFormat = (config, data) => {
    let isoFormat = Controller.ISO_FORMAT.HcKey;

    if (data.config.rows.length > 0 && config.isAdminMapType) {
      if (config.mapType === this.MapChart.mapTypes().WorldContinents) return Controller.ISO_FORMAT.Name;
      isoFormat = Controller.ISO_FORMAT.ISOa3;
      const rowField = data.config.rows.map(row => row.field_name)[0];
      const valueField = data.values.map(value => value.rows[0][rowField]);

      valueField.every(value => {
        if (value && value !== blankKey && value.length === 2) {
          isoFormat = Controller.ISO_FORMAT.ISOa2;
          return false;
        }
        return true;
      });
    }
    return isoFormat;
  };

  getMapData = mapType => {
    switch (mapType) {
      case this.MapChart.mapTypes().WorldCountries:
        return WorldCountries;
      case this.MapChart.mapTypes().WorldContinents:
        return WorldContinents;
      case this.MapChart.mapTypes().USAStates:
        return USAStates;
      case this.MapChart.mapTypes().Europe:
        return Europe;
      case this.MapChart.mapTypes().NorthAmerica:
        return NorthAmerica;
      case this.MapChart.mapTypes().Asia:
        return Asia;
      case this.MapChart.mapTypes().Africa:
        return Africa;
      case this.MapChart.mapTypes().SouthAmerica:
        return SouthAmerica;
      case this.MapChart.mapTypes().Oceania:
        return Oceania;
      case this.MapChart.mapTypes().China:
        return China;
      case this.MapChart.mapTypes().Australia:
        return Australia;
      default:
        return WorldCountries;
    }
  };

  getConfig = (config, data) => {
    const that = this;

    const { mapType } = config;
    let mapTypes = [mapType];
    const joinByISO = !config.isCoordinate ? this.getISOFormat(config, data) : "";

    let hcTranslations = {
      resetZoom: `<i class="map-reset-zoom"></i>`,
      resetZoomTitle: this.Localize.getLocalizedString("_ChartConfig.Display.Button.ResetZoom_"),
      zoomIn: this.Localize.getLocalizedString("_ChartConfig.Display.Button.ZoomIn_"),
      zoomOut: this.Localize.getLocalizedString("_ChartConfig.Display.Button.ZoomOut_"),
      drillUpText: `◀ ${this.Localize.getLocalizedString("_ChartConfig.Display.Button.Back_")}`,
    };

    let hcConfig = {
      chart: {
        map: this.getMapData(config.mapType),
        proj4: proj4,
        resetZoomButton: {
          position: {
            align: "left",
            x: -1,
            y: 99,
          },
          theme: {
            width: 11.5,
            height: 13,
            cursor: "pointer",
          },
          relativeTo: "spacingBox",
          attributes: {
            url: zoomOutIcon,
            x: 7,
            y: 8,
            width: 15,
            height: 15,
          },
        },
        events: {
          render: function() {
            if (this.yAxis[0].userMax && !this.resetZoomButton) {
              if (this.drilldownLevels && this.drilldownLevels.length > 0 && this.drilldownLevels[0].resetZoomButton)
                this.drilldownLevels[0].resetZoomButton.show();
              else this.showResetZoom();
            } else if (!this.yAxis[0].userMax && this.resetZoomButton) {
              this.resetZoomButton.hide();
            } else if (this.yAxis[0].userMax && this.resetZoomButton) {
              this.resetZoomButton.show();
            } else if (
              !this.yAxis[0].userMax &&
              this.drilldownLevels &&
              this.drilldownLevels.length > 0 &&
              this.drilldownLevels[0].resetZoomButton
            ) {
              this.drilldownLevels[0].resetZoomButton.hide();
            } else if (
              this.yAxis[0].userMax &&
              this.drilldownLevels &&
              this.drilldownLevels.length > 0 &&
              this.drilldownLevels[0].resetZoomButton
            ) {
              this.drilldownLevels[0].resetZoomButton.show();
            }
          },
          drillup: function(e) {
            config.mapType = mapTypes[e.seriesOptions._levelNumber || 0] || config.mapType;
            config.isAdminMapType = that.MapChart.isAdminMapType(config.mapType);
            if (!e.seriesOptions._levelNumber) mapTypes = [mapType];
          },
          drilldown: function(e) {
            if (
              !e.point.seriesOptions &&
              (that.MapChart.isRegionDrillEnabled(e) || that.MapChart.isCoordinateDrillEnabled(config, e))
            ) {
              const chart = this;
              chart.showLoading();

              if (config.isCoordinate)
                config.mapType = that.MapChart.getMapTypeOnCoordinates(config.mapType, e.point.lat, e.point.lon);
              else config.mapType = e.point.name;

              mapTypes.push(config.mapType);
              config.isAdminMapType = that.MapChart.isAdminMapType(config.mapType);

              that.$timeout(() => {
                if (chart.resetZoomButton) {
                  chart.resetZoomButton = chart.resetZoomButton.destroy();
                }
                chart.showResetZoom();

                chart.addSingleSeriesAsDrilldown(e.point, {
                  joinBy: [!config.isCoordinate ? that.getISOFormat(config, data) : "", "name"],
                  data: !config.isCoordinate ? that.MapChart.convertToHighChartData(data, config) : [],
                  mapData: that.getMapData(config.mapType),
                });

                if (config.isCoordinate) {
                  chart.addSingleSeriesAsDrilldown(e.point, {
                    marker: {
                      symbol: "circle",
                    },
                    type: "mappoint",
                    data: config.isCoordinate ? that.MapChart.convertToHighChartData(data, config) : [],
                  });
                }

                chart.applyDrilldown();
                chart.hideLoading();
              }, 100);
            }
          },
        },
      },

      legend: {
        enabled: !config.isCoordinate && config.showGradientScale,
        align: "left",
        verticalAlign: "bottom",
        layout: "vertical",
        floating: true,
        y: 8,
        symbolHeight: 160,
      },

      mapNavigation: {
        enabled: config.enableDoubleClickZoom || config.showZoomButton,
        enableButtons: config.showZoomButton,
        buttonOptions: {
          alignTo: "spacingBox",
          verticalAlign: "top",
        },
        buttons: {
          zoomIn: {
            y: 37,
          },
          zoomOut: {
            y: 66,
          },
        },
        enableDoubleClickZoom: config.enableDoubleClickZoom,
      },

      boost: this.getBoostConfig(config),

      colorAxis: this.MapChart.getHighChartsColorAxis(config, data),

      plotOptions: {
        map: {
          allAreas: true,
          allowPointSelect: false,
          joinBy: [joinByISO, "name"],

          dataLabels: {
            enabled: true,
            overflow: false,
            shadow: false,
            color: "rgba(0,0,0)",
            style: {
              fontWeight: "normal",
            },
            formatter: function() {
              if (this.point && !this.point.isNull && this.point.labelrank) {
                return that.MapChart.dataLabelFormatter(config, this.point.name, this.point.value);
              }
            },
          },
        },
        series: {
          point: {
            events: {
              mouseOver: function() {
                if (
                  !this.seriesOptions &&
                  (that.MapChart.isRegionDrillEnabled(this) || that.MapChart.isCoordinateDrillEnabled(config, this))
                ) {
                  if (this.graphic)
                    this.graphic.css({
                      cursor: "pointer",
                    });
                } else {
                  if (this.graphic)
                    this.graphic.css({
                      cursor: "default",
                    });
                  if (this.series.halo)
                    this.series.halo.css({
                      cursor: "default",
                    });
                  if (this.dataLabel)
                    this.dataLabel.css({
                      cursor: "default",
                    });
                }
              },
            },
          },
        },
      },

      tooltip: {
        style: {
          display: "none",
        },
        headerFormat: "",
        useHTML: true,
        shape: "rectangle",
        pointFormatter: function() {
          return that.MapChart.tooltipPointFormatter(config, this.name, this.options);
        },
        positioner: function(labelWidth, labelHeight, point) {
          let plotX;
          let plotY;
          if (config.fixedTooltip) {
            plotX = this.chart.chartWidth - this.chart.plotLeft * 2 - 4;
            plotY = 10;
          } else {
            plotX = point.plotX + this.chart.plotLeft - labelWidth / 2;
            plotY = point.plotY + this.chart.plotTop - labelHeight - 10;
          }
          return { x: plotX, y: plotY };
        },
      },

      series: [
        {
          type: "map",
          colorIndex: 0,
          animation: false,
          animationLimit: 1000,
          boostThreshold: MAP_CHART,
          data: !config.isCoordinate ? this.MapChart.convertToHighChartData(data, config) : [],
        },
        {
          type: "mapline",
          showInLegend: false,
          enableMouseTracking: false,
        },
        {
          marker: {
            symbol: "circle",
          },
          type: "mappoint",
          color: "rgba(0,0,0)",
          animation: false,
          showInLegend: false,
          stickyTracking: false,
          data: config.isCoordinate ? this.MapChart.convertToHighChartData(data, config) : [],
        },
      ],

      drilldown: {
        activeDataLabelStyle: {
          color: "#3F3D3C",
          textDecoration: "none",
          textOutline: "1px #F7F7F7",
          fontWeight: "normal",
        },
        drillUpButton: {
          position: {
            x: -1,
            y: 0,
            align: "left",
            verticalAlign: "top",
          },
          relativeTo: "spacingBox",
        },
      },
    };

    if (!this.getBoostConfig(config, data.values.length >= MAP_CHART).enabled && this.$scope.isPatternFill) {
      if (hcConfig.colorAxis.stops) {
        hcConfig.colorAxis.stops.forEach((v, index) => {
          v[1] = this.patternFillPalettes(index);
          if (v.color) v.color.input = this.patternFillPalettes(index);
        });
      }
    }
    const dataConfig = this.getDataConfig(data);
    const labelFontSizeConfigPaths = this.getLabelFontSizeConfigPaths(dataConfig);
    hcConfig = this.updateLabelFontSize(hcConfig, labelFontSizeConfigPaths);
    this.setTheme(hcConfig, config.mapThemeType);
    this.updateTranslations(hcTranslations);
    return hcConfig;
  };

  setTheme = (hcConfig, theme) => {
    switch (theme) {
      case "Dark":
        hcConfig.chart.backgroundColor = {
          linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
          stops: [
            [0, "#3F3D3C"],
            [1, "#262424"],
          ],
        };
        hcConfig.colorAxis.labels.style = {
          color: "#FFFFFF",
        };
        break;
      case "Light":
        hcConfig.chart.backgroundColor = "#F7F7F7";
        break;
      default:
        break;
    }
  };

  getLabelFontSizeConfigPaths = config => {
    const { labelFontSizeWithColorBy, labelFontSize } = Controller.configPath;
    return hasColorBy(config) ? labelFontSizeWithColorBy : labelFontSize;
  };

  getDataConfig = data => {
    if (data.modelType === "ChartData") {
      const dataConfig = data.dataConfig();
      return { rows: dataConfig.chartRows, values: dataConfig.chartValues };
    }
    return data.config;
  };
}

function hasColorBy(config) {
  return config.values.length > 0;
}
export default MapChartDirective;
