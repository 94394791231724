import backendApi from "@results/services/apiCall/backendApi";

(() => {
  class AccessControlBackend {
    constructor($q, AccessControlAdapter, AppConfig) {
      this._$q = $q;
      this._AccessControlAdapter = AccessControlAdapter;
      this._AppConfig = AppConfig;

      this._accessControlUrl = {
        metric: this._accessControlUrlForMetric,
        interpretation: this._accessControlUrlForInterpretation,
      };
    }

    accessControlRequested(entity, id) {
      var url = this._accessControlUrl[entity](id) + this._AppConfig.apiQueryString;
      var accessControlAdapter = this._AccessControlAdapter;

      return backendApi.get(url).then(function(response) {
        if (response.data) {
          return accessControlAdapter.deserializeAccessControl(response.data);
        }
        return this._$q.reject();
      });
    }

    _accessControlUrlForMetric(metricId) {
      return "/metrics/" + metricId + "/access_controls";
    }

    _accessControlUrlForInterpretation(interpretationId) {
      return "/interpretations/" + interpretationId + "/access_controls";
    }
  }

  angular.module("acl.visualizer.accessControl.backend").service("AccessControlBackend", AccessControlBackend);
})();
