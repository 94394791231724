import isEqual from "lodash/isEqual";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import tableCapability from "@visualizer/modules/core/tableCapability/tableCapability.service";
import quickMenuToggler from "@viz-ui/services/quickMenu/quickMenuTogglerService";

angular
  .module("acl.visualizer.dataTablePanel")
  .controller("DataTablePanelController", function(
    $scope,
    AppConfig,
    ColumnConfig,
    DataFilter,
    DataModel,
    EventService,
    Field,
    Localize,
    TabStateService,
    $timeout
  ) {
    "ngInject";
    var dataTablePanel = this;
    dataTablePanel.tabIndex = 0;
    var tabState = TabStateService.instance(() => Number(dataTablePanel.tabIndex), reload, redraw);

    dataTablePanel.checkedRecordIds = [];
    dataTablePanel.checkedRecordCount = 0;
    dataTablePanel.rowCheckedInverse = false;
    dataTablePanel.bulkModeEnabled = false;

    dataTablePanel.dataTableConfig = {
      columnConfigs: [],
      fields: [],
    };

    dataTablePanel.dataGrid = {
      commentCounts: [],
      checkRowOnRowClick: false,
      data: [],
      fields: [],
      fieldFormatIdentifier: undefined,
      filterFieldNames: [],
      fixedColumnsLeft: getFixedColumnsLeft(),
      highlightSelectedRow: true,
      getAttachmentUrl: getAttachmentUrl,
      showCommentCount: tableCanRemediateRecords(),
      isHeaderIconEnabled: true,
      isRowSelectionEnabled: tableCanRemediateRecords(),
      onColumnHeaderClick,
      onColumnResize: onColumnResize,
      onRowClick: onDataGridRowClick,
      onScrollToBottom: onDataGridScrollToBottom,
      onSelectionChange: onDataGridSelectionChange,
      rowIdKey: "metadata.exception_id",
      rowCheckedInverse: false,
      selectedFieldName: undefined,
      sortFieldName: undefined,
      sortOrder: undefined,
      tableConfigFieldObjs: [],
      selectedRecordId: undefined,
    };

    dataTablePanel.$onChanges = function(changesObj) {
      if (changesObj.fieldFormatIdentifier) {
        setDataGridProps({
          fieldFormatIdentifier: dataTablePanel.fieldFormatIdentifier,
        });
      }

      if (dataTablePanel.data && dataTablePanel.fields && typeof dataTablePanel.filteredRecordCount !== "undefined") {
        setupData();
      }

      if (changesObj.filteredRecordCount) {
        setDataGridProps({
          filteredRecordCount: changesObj.filteredRecordCount.currentValue,
        });
        dataTablePanel.isExportToCsvEnabled = changesObj.filteredRecordCount.currentValue > 0;
      }
    };

    function setupData() {
      updateFieldsModels();
      updateDataGridData();
      updateSelectedRowData();
      tabState.scheduleRedraw();
    }

    function updateDataGridData() {
      var filterFieldNames;
      var sortFieldName;
      var sortOrder;

      const filterConfig = DataModel.getFilterConfig();
      filterFieldNames = DataFilter.getFilterFieldNames(filterConfig);

      if (filterConfig.sortField) {
        sortFieldName = filterConfig.sortField.field;
        sortOrder = filterConfig.sortField.order;
      }

      updateIsFirstOrLastRecord();

      setDataGridProps({
        commentCounts: dataTablePanel.commentCounts,
        data: dataTablePanel.data,
        filteredRecordCount: dataTablePanel.filteredRecordCount,
        filterFieldNames: filterFieldNames,
        sortFieldName: sortFieldName,
        sortOrder: sortOrder,
      });
    }

    function onDataGridScrollToBottom() {
      dataTablePanel.onScrollToBottom();
      updateDataGridSelections();
    }

    function onDataGridSelectionChange(checkedRecordIds, selectedRecordId, inverseMode) {
      dataTablePanel.checkedRecordIds = angular.copy(checkedRecordIds);
      dataTablePanel.selectedRecordId = selectedRecordId;
      dataTablePanel.rowCheckedInverse = inverseMode;

      updateCheckedRecordCount();
    }

    var pendingDataGridProps = {};
    function setDataGridProps(newProps) {
      var key;
      for (key in newProps) {
        if (Object.prototype.hasOwnProperty.call(newProps, key)) {
          pendingDataGridProps[key] = newProps[key];
        }
      }
    }

    function updateDataGridProps() {
      var key;
      for (key in pendingDataGridProps) {
        if (Object.prototype.hasOwnProperty.call(pendingDataGridProps, key)) {
          dataTablePanel.dataGrid[key] = pendingDataGridProps[key];
        }
      }
      pendingDataGridProps = {};
    }

    function onColumnHeaderClick(fieldName, offset) {
      if (!dataTablePanel.bulkModeEnabled) {
        closeProcessPanel();
        quickMenuToggler.toggle(offset, fieldName, true);
      }
    }

    function hasPermissionsProcessRecords() {
      return tableCanRemediateRecords() && !!AppConfig.ability && AppConfig.ability.canProcessExceptions();
    }
    // @TODO Need until we get rid of all the watches on ability and get rid of
    // timeout in visualizerApp
    $scope.$watch(hasPermissionsProcessRecords, function(newValue, oldValue) {
      if (newValue !== oldValue) {
        const newIsRowSelectionEnabled = tableCanRemediateRecords();
        if (dataTablePanel.dataGrid.isRowSelectionEnabled !== newIsRowSelectionEnabled) {
          dataTablePanel.dataGrid.isRowSelectionEnabled = newIsRowSelectionEnabled;
        }
        const newShowCommentsCount = tableCanRemediateRecords();
        if (dataTablePanel.dataGrid.showCommentCount !== newShowCommentsCount) {
          dataTablePanel.dataGrid.showCommentCount = newShowCommentsCount;
        }
        const newFixedColumnsLeft = getFixedColumnsLeft();
        if (dataTablePanel.dataGrid.fixedColumnsLeft !== newFixedColumnsLeft) {
          dataTablePanel.dataGrid.fixedColumnsLeft = newFixedColumnsLeft;
        }
      }
    });

    function tableCanRemediateRecords() {
      return tableCapability.canRemediateRecords();
    }

    function toggleConfigPanel() {
      dataTablePanel.showTableConfig = !dataTablePanel.showTableConfig;

      if (dataTablePanel.showTableConfig) {
        $timeout(() => {
          angular.element("#tableConfigHeadline").focus();
        }, 300);
      }

      if (dataTablePanel.showTableConfig) {
        dataTablePanel.showProcessPanel && closeProcessPanel();
        $scope.isReportDownloadPanelOpen && closeDownloadReportPanel();
      }
    }

    function getFixedColumnsLeft() {
      if (AppConfig.features.fixedColumnLeftInViz) {
        return tableCapability.canRemediateRecords() ? 2 : 1;
      }
      return 0;
    }

    var eventService = EventService.register("dataTable.DataTablePanelController");

    eventService.subscribe("dataTableConfigPanelExternal.close", function() {
      if (dataTablePanel.showTableConfig) {
        toggleConfigPanel();
      }
    });

    eventService.subscribe("dataTableConfigPanelExternal.open", function() {
      if (!dataTablePanel.showTableConfig) {
        toggleConfigPanel();
      }
    });

    eventService.subscribe("copyInterpretationsPanel.open", () => {
      if (dataTablePanel.showTableConfig) {
        toggleConfigPanel();
      }
      closeProcessPanel();
    });

    dataTablePanel.$onDestroy = function() {
      eventService.unregister();
    };

    eventService.subscribe("dataTable.configChange", function(e, tableViewRedirect, triggeredFrom) {
      if (tableViewRedirect) DataModel.interpretation.currentTabIndex(0);
      tabState.scheduleReload(false, triggeredFrom);
    });

    eventService.subscribe("sortUpdated", function(event, fieldName, order) {
      if (validateFilters()) {
        applySort(fieldName, order);
      }
    });

    eventService.subscribe("biView.columnSelected", function(event, fieldName) {
      setDataGridProps({
        selectedFieldName: fieldName,
      });
      dataTablePanel.currentQuickFilter = DataModel.table.field(fieldName);
      updateDataGridSelections();
    });

    eventService.subscribe("dataTable.resetCheckedRows", function() {
      resetCheckedRows();
    });

    function resetCheckedRows() {
      dataTablePanel.checkedRecordIds = [];
      dataTablePanel.rowCheckedInverse = false;
      updateDataGridSelections();
      updateCheckedRecordCount();
    }

    function validateFilters() {
      var valid = DataModel.filtersValid() && !DataFilter.hasInvalidFilters(DataModel.getFilterConfig());
      if (!valid) {
        eventService.publish("notify", "warning", Localize.getLocalizedString("_FilterConfig.Invalid.Error_"), true);
      }
      return valid;
    }

    function reload(triggeredFrom) {
      updateFieldsModels();
      let doLoadData = triggeredFrom !== "conditionalFormatting";
      const doNotRenderActions = ["columnSort", "filterChange", "selectedTab"];
      let doRenderGrid = !doNotRenderActions.includes(triggeredFrom);

      if (doLoadData) {
        //if we are calling onReload it will take care of redrawing the grid.
        dataTablePanel.onReload();
      }

      tabState.reloaded(doRenderGrid);
      UsageTracker.mark("dataTablePanel.reload");

      resetCheckedRows();
      closeProcessPanel();
    }

    function updateFieldsModels() {
      const fieldModels = fieldObjsToFieldModels(DataModel.table.fields());
      const columnConfigObjs = DataModel.tableConfig.columns();

      dataTablePanel.dataTableConfig.fields = fieldModels;
      dataTablePanel.dataTableConfig.columnConfigs = columnConfigObjs.map(columnConfigObjToColumnConfigModel);

      // Defer passing props to the datagrid until the next redraw.
      setDataGridProps({
        fields: fieldModels,
        tableConfigFieldObjs: columnConfigObjs,
      });
    }

    function fieldObjsToFieldModels(fieldObjs) {
      const fieldModels = [];
      for (const fieldName in fieldObjs) {
        const fieldObj = fieldObjs[fieldName];
        fieldModels.push(fieldObjToFieldModel(fieldName, fieldObj));
      }
      return fieldModels;
    }

    function fieldObjToFieldModel(fieldName, fieldObj) {
      return new Field()
        .name(fieldName)
        .displayName(fieldObj.displayName)
        .type(fieldObj.type)
        .isPrimaryKey(fieldObj.isPrimaryKey);
    }

    DataModel.subscribe("tableConfig.columns", () => {
      const newValue = DataModel.tableConfig.columns();
      setDataGridProps({
        tableConfigFieldObjs: newValue,
      });
      updateDataGridProps();
      dataTablePanel.dataTableConfig.columnConfigs = newValue.map(columnConfigObjToColumnConfigModel);
    });

    function columnConfigObjToColumnConfigModel(columnConfigObj) {
      const columnConfigModel = new ColumnConfig()
        .name(columnConfigObj.fieldName)
        .visible(columnConfigObj.visible !== false)
        .wordWrap(!!columnConfigObj.wordWrap);
      if (typeof columnConfigObj.columnWidth !== "undefined") {
        columnConfigModel.width(columnConfigObj.columnWidth);
      }
      return columnConfigModel;
    }

    function columnConfigModelToColumnConfigObj(columnConfigModel) {
      const columnConfigObj = {
        fieldName: columnConfigModel.name(),
        visible: columnConfigModel.visible(),
        wordWrap: columnConfigModel.wordWrap(),
      };
      if (typeof columnConfigModel.width() !== "undefined") {
        columnConfigObj.columnWidth = columnConfigModel.width();
      }
      return columnConfigObj;
    }

    function redraw() {
      updateDataGridSelections();
      updateDataGridProps();
      $scope.$broadcast("tableRedraw");
      tabState.redrew();
      UsageTracker.mark("dataTablePanel.redraw");
    }

    eventService.subscribe("filterPanel.filterChange", function() {
      setDataGridProps({
        filterFieldNames: DataFilter.getFilterFieldNames(DataModel.getFilterConfig()),
      });
    });

    function applySort(sortField, sortOrder) {
      if (sortField && sortOrder) {
        DataFilter.setOrToggleSort(sortField, sortOrder);

        const dataModelSortFieldId = DataModel.filterConfig.sortFieldId();
        const dataModelSortOrder = DataModel.filterConfig.sortOrder();
        eventService.publish("dataTable.sortChange", dataModelSortFieldId, dataModelSortOrder);

        TabStateService.reloadAll(false, "columnSort");
        closeQuickMenu();
      }

      setDataGridProps({
        sortFieldName: sortField,
        sortOrder: sortOrder,
      });
    }

    function closeQuickMenu() {
      eventService.publish("quickMenu.close");
    }

    function getAttachmentUrl() {
      return AppConfig.getAttachmentUrl;
    }

    function updateSelectedRowData() {
      if (
        typeof dataTablePanel.selectedRecordData !== "undefined" &&
        typeof dataTablePanel.selectedRecordId !== "undefined"
      ) {
        if (typeof dataTablePanel.data !== "undefined") {
          const rowIndex = findRowIndexById(dataTablePanel.selectedRecordId, dataTablePanel.data);
          dataTablePanel.selectedRecordData = dataTablePanel.data[rowIndex];
        } else {
          closeProcessPanel();
        }
      }
    }

    function onDataGridRowClick(row, rowId) {
      const rowIndex = findRowIndexById(rowId, dataTablePanel.dataGrid.data);
      const rowData = dataTablePanel.dataGrid.data[rowIndex];

      updateIsFirstOrLastRecord(rowIndex);

      dataTablePanel.selectedRecordData = rowData;
      dataTablePanel.selectedRecordId = rowId;

      openProcessPanel();
    }

    function checkRowCheckbox(rowId) {
      let checkbox = $(`.ht_clone_left table td[data-record-id="${rowId}"] input.htCheckboxRendererInput`);
      if (checkbox.length) {
        checkbox.click();
      } else {
        updateDataGridSelections();
        let checkedRecordIds = dataTablePanel.checkedRecordIds.slice();
        let index = checkedRecordIds.indexOf(rowId);
        if (index < 0) {
          checkedRecordIds.push(rowId);
        } else {
          checkedRecordIds.splice(index, 1);
        }

        // list of ids could be checked vs not checked depending on
        // rowCheckedInverse
        dataTablePanel.checkedRecordIds = checkedRecordIds;

        updateDataGridSelections();
        updateCheckedRecordCount();
      }
    }

    function updateDataGridSelections() {
      if (
        !isEqual(dataTablePanel.dataGrid.checkedRecordIds, dataTablePanel.checkedRecordIds) ||
        dataTablePanel.dataGrid.rowCheckedInverse !== dataTablePanel.rowCheckedInverse
      ) {
        dataTablePanel.dataGrid.checkedRecordIds = angular.copy(dataTablePanel.checkedRecordIds);
        dataTablePanel.dataGrid.rowCheckedInverse = dataTablePanel.rowCheckedInverse;

        updateCheckedRecordCount();
      }
      if (dataTablePanel.dataGrid.selectedRecordId !== dataTablePanel.selectedRecordId) {
        dataTablePanel.dataGrid.selectedRecordId = dataTablePanel.selectedRecordId;
      }
    }

    function onDeleteRows(recordIds, mode) {
      dataTablePanel.onDeleteRows({ recordIds, mode });
    }

    function onColumnResize(columnIndex, fieldName, width) {
      const columns = angular.copy(DataModel.getTableConfigObj().columns);
      const column = columns.find(c => c.fieldName === fieldName);
      if (column) {
        column.columnWidth = width;
        DataModel.tableConfig.columns(columns);
        eventService.publish("dataTable.columnResized", column);
        dataTablePanel.dataGrid.tableConfigFieldObjs = columns;
      }
    }

    function updateCheckedRecordCount() {
      const { checkedRecordIds } = dataTablePanel;
      const { filteredRecordCount } = dataTablePanel;
      const { rowCheckedInverse } = dataTablePanel;

      let count = 0;

      if (checkedRecordIds) {
        if (rowCheckedInverse) {
          count = filteredRecordCount - checkedRecordIds.length;
        } else {
          count = checkedRecordIds.length;
        }
      }
      dataTablePanel.checkedRecordCount = count;
    }

    //======================= Display Config =============================

    dataTablePanel.onDataTableConfigSubmitClick = function(newColumnConfigModels) {
      var columnConfigObjs = newColumnConfigModels.map(columnConfigModelToColumnConfigObj);
      DataModel.tableConfig.columns(columnConfigObjs);
    };

    eventService.subscribe("dataTable.setShowTableConfig", function(event, showTableConfig) {
      dataTablePanel.showTableConfig = showTableConfig;
      if (dataTablePanel.showTableConfig && dataTablePanel.showProcessPanel) {
        closeProcessPanel();
      }
    });

    dataTablePanel.onDataTableConfigCancelClick = function() {
      closeConfigPanel();
    };

    dataTablePanel.onDataTableConfigCloseClick = function() {
      closeConfigPanel();
    };

    function closeConfigPanel() {
      dataTablePanel.showTableConfig = false;
      eventService.publish("dataTableConfigPanel.close");
    }

    //======================= Process Panel =============================
    let tableNeedsRefresh = false;
    let tableNeedsHardRefresh = false;

    DataModel.subscribe("filtersOpen", () => {
      const newValue = DataModel.filtersOpen();
      if (newValue) {
        closeProcessPanel();
      }
    });

    dataTablePanel.processPanel = {
      onAddComment,
      onProcessBulk,
      onCheck: checkRowCheckbox,
      onClose,
      onDeleteBulk,
      onDeleteRecord,
      onDeleteInProgress,
      onEnterBulkReviewMode: enableBulkMode,
      onExitBulkReviewMode: disableBulkModeAndUpdate,
      onNextRowClick: () => incrementSelectedRowBy(1),
      onPrevRowClick: () => incrementSelectedRowBy(-1),
      onProcessRecord,
      isFirstRecord: false,
      isLastRecord: false,
    };

    eventService.subscribe("processPanel.force.close", () => {
      closeProcessPanel();
    });

    function uncheckRows(rows) {
      const originalCheckCount = dataTablePanel.checkedRecordIds;
      rows.forEach(rowId => {
        let index = dataTablePanel.checkedRecordIds.indexOf(rowId);
        if (index > -1) {
          dataTablePanel.checkedRecordIds.splice(index, 1);
        }
      });

      if (originalCheckCount < dataTablePanel.checkedRecordIds.length) {
        updateCheckedRecordCount();
      }
    }

    function findRowIndexById(id, data) {
      const { rowIdKey } = dataTablePanel.dataGrid;
      return data.findIndex(row => row[rowIdKey] === id);
    }

    function incrementSelectedRowBy(increment) {
      const { data } = dataTablePanel.dataGrid;
      const { rowIdKey } = dataTablePanel.dataGrid;
      const currentRowIndex = findRowIndexById(dataTablePanel.selectedRecordId, data);
      const newRowIndex = currentRowIndex + increment;

      // Use datagrid data length to prevent user from spamming while its
      // loading
      if (newRowIndex >= 0 && newRowIndex < dataTablePanel.dataGrid.data.length) {
        dataTablePanel.selectedRecordId = data[newRowIndex][rowIdKey];
        dataTablePanel.selectedRecordData = data[newRowIndex];

        // Need to lazy load more values as user moves selection to bottom
        if (newRowIndex === dataTablePanel.dataGrid.data.length - 1) {
          dataTablePanel.onScrollToBottom();
        }

        updateIsFirstOrLastRecord(newRowIndex);
        updateDataGridSelections();
      }
    }

    function updateIsFirstOrLastRecord(newRowIndex) {
      if (!dataTablePanel.data) {
        dataTablePanel.processPanel.isFirstRecord = true;
        dataTablePanel.processPanel.isLastRecord = true;
      } else {
        dataTablePanel.processPanel.isFirstRecord = newRowIndex === 0;
        dataTablePanel.processPanel.isLastRecord = newRowIndex === dataTablePanel.data.length - 1;
      }
    }

    function onClose() {
      closeProcessPanel();
      if (dataTablePanel.bulkModeEnabled) {
        disableBulkModeAndUpdate();
      }
    }

    function closeProcessPanel() {
      dataTablePanel.showProcessPanel = false;
      eventService.publish("processPanel.close");
    }

    function openProcessPanel() {
      dataTablePanel.showProcessPanel = true;
      eventService.publish("processPanel.open");
      closeConfigPanel();
      closeDownloadReportPanel();
      eventService.publish("quickMenu.close");
      eventService.publish("filterPanel.close");
    }

    function onAddComment(rowId, hasComment, hasAttachment) {
      const commentCounts = dataTablePanel.dataGrid.commentCounts
        ? angular.copy(dataTablePanel.dataGrid.commentCounts)
        : [];
      const index = commentCounts.findIndex(record => record.id === rowId);
      const commentIncrement = hasComment ? 1 : 0;
      const attachmentIncrement = hasAttachment ? 1 : 0;

      if (index >= 0) {
        commentCounts[index].comments_count += commentIncrement;
        commentCounts[index].attachments_count += attachmentIncrement;
      } else {
        commentCounts.push({
          id: rowId,
          comments_count: commentIncrement,
          attachments_count: attachmentIncrement,
        });
      }

      dataTablePanel.onAddComment({ commentCounts });
    }

    function onDeleteRecord(recordId) {
      onDeleteRows([recordId], "deleteOne");
      incrementSelectedRowBy(1);
      resetCheckedRows();
    }

    function onDeleteInProgress() {
      dataTablePanel.onDeleteInProgress({
        message: Localize.getLocalizedString("_BulkUpdate.DeleteInProgress.Message_"),
      });
    }

    function onDeleteBulk(deleted, notDeleted) {
      tableNeedsRefresh = true;
      tableNeedsHardRefresh = true;

      const bulkUpdateObject = _bulkUpdateAction(
        deleted,
        notDeleted,
        "_BulkUpdate.DeleteSuccess.Message_",
        "_BulkUpdate.DeletePartial.Message_"
      );

      bulkUpdateObject.isDeletion = true;

      dataTablePanel.onBulkUpdate(bulkUpdateObject);
    }

    function onProcessRecord(processed, unprocessed, newFieldData, removeFromView) {
      const { rowIdKey } = dataTablePanel.dataGrid;

      if (removeFromView && removeFromView.length > 0) {
        uncheckRows(removeFromView);
        if (removeFromView.includes(dataTablePanel.selectedRecordId)) {
          closeProcessPanel();
        }
      }

      dataTablePanel.onProcessRecord({ processed, rowIdKey, newFieldData, removeFromView });
    }

    function enableBulkMode() {
      dataTablePanel.disableAllButtons();
      dataTablePanel.bulkModeEnabled = true;
      updateDataGridSelections();
    }

    function disableBulkMode() {
      dataTablePanel.enableAllButtons();
      dataTablePanel.bulkModeEnabled = false;
      updateDataGridSelections();

      tableNeedsRefresh = false;
      tableNeedsHardRefresh = false;
    }

    function disableBulkModeAndUpdate() {
      if (tableNeedsRefresh) {
        dataTablePanel.onBulkUpdate({
          tableRefresh: true,
          hardTableRefresh: tableNeedsHardRefresh,
        });
        resetCheckedRows();
      }

      disableBulkMode();
    }

    function onProcessBulk(requireDelayJob, processed, unprocessed, fromQuestionnaire) {
      if (fromQuestionnaire) {
        tableNeedsRefresh = true;
        tableNeedsHardRefresh = true;
      }

      if (requireDelayJob) {
        dataTablePanel.onBulkUpdate({
          tableRefresh: tableNeedsRefresh,
          message: Localize.getLocalizedString("_BulkUpdate.DelayedJob.Message_"),
          messageType: "warning",
          hardTableRefresh: tableNeedsHardRefresh,
        });

        resetCheckedRows();
        closeProcessPanel();
        disableBulkMode();
      } else {
        const bulkUpdateObject = _bulkUpdateAction(
          processed,
          unprocessed,
          "_BulkUpdate.Success.Message_",
          fromQuestionnaire ? "_BulkQuestionnaire.Partial.Message_" : "_BulkUpdate.Partial.Message_"
        );

        dataTablePanel.onBulkUpdate(bulkUpdateObject);
      }
    }

    function _bulkUpdateAction(successfulRecords, unsuccessfulRecords, successfulMessageKey, unsuccessfulMessageKey) {
      let message;
      let messageType;

      let checkedRecordIdsAfterUpdate;
      let successfulCount = successfulRecords.length;
      let unsuccessfulCount = unsuccessfulRecords.length;

      if (dataTablePanel.rowCheckedInverse) {
        checkedRecordIdsAfterUpdate = dataTablePanel.checkedRecordIds.concat(successfulRecords);
      } else {
        checkedRecordIdsAfterUpdate = dataTablePanel.checkedRecordIds.filter(id => !successfulRecords.includes(id));
      }

      const tableRefresh = unsuccessfulCount === 0;
      const hardTableRefresh = tableNeedsHardRefresh;

      if (tableRefresh) {
        message = Localize.getLocalizedString(successfulMessageKey);
        messageType = "success";

        resetCheckedRows();
        closeProcessPanel();
        disableBulkMode();
      } else {
        message = Localize.getLocalizedStringWithTokenReplacement(unsuccessfulMessageKey, {
          successfulCount,
          unsuccessfulCount,
        });
        messageType = "warning";

        dataTablePanel.checkedRecordIds = checkedRecordIdsAfterUpdate;
        updateCheckedRecordCount();
        updateDataGridSelections();

        tableNeedsRefresh = true;
      }

      return { tableRefresh, message, messageType, hardTableRefresh };
    }

    function closeDownloadReportPanel() {
      $scope.isReportDownloadPanelOpen = false;
    }

    $scope.isReportDownloadPanelOpen = false;
    $scope.isReportDownloadModalOpen = false;
    $scope.reportTypes = [];
    $scope.vizDataModel = DataModel;
    $scope.openStatuses = [];

    $scope.generateReportDownload = (targetReport, filterExpression) => {
      $scope.isReportDownloadModalOpen = true;
      $scope.isReportDownloadPanelOpen = false;
      eventService.publish("report.download", { targetReport, filterExpression });
    };
    $scope.emailReport = (targetReport, filterExpression) => {
      $scope.isReportDownloadPanelOpen = false;
      eventService.publish("report.email", { targetReport, filterExpression });
    };
    $scope.toggleReportDownloadPanel = () => {
      eventService.publish("reportDownloadPanel.toggle.fromDataPanel");
      $scope.isReportDownloadPanelOpen = !$scope.isReportDownloadPanelOpen;
    };
    eventService.subscribe("reportDownloadPanel.toggle.fromDataVisualizer", (e, reportTypes, openStatuses) => {
      if (!$scope.isReportDownloadModalOpen) $scope.reportTypes = reportTypes;
      $scope.isReportDownloadPanelOpen = !$scope.isReportDownloadPanelOpen;
      $scope.openStatuses = openStatuses;
    });
  });
