const aclTileLoading = {
  bindings: {},
  controllerAs: "tileLoading",
  template: `
    <div class="tile-loading">
      <div class="bar bar1"></div>
      <div class="space actg1"></div>
      <div class="bar bar2"></div>
      <div class="space actg1"></div>
      <div class="bar bar3"></div>
      <div class="space actg1"></div>
      <div class="bar bar4"></div>
      <div class="space actg1"></div>
      <div class="bar bar5"></div>
    </div>
  `,
  controller: () => {},
};

export default aclTileLoading;
