/* eslint-disable no-param-reassign */

import React from "react";
import PropTypes from "prop-types";
import JumpToField from "@acl-services/wasabicons/lib/JumpToField";
import DragHandle from "@paprika/icon/lib/DragHandle";
import Sortable from "@paprika/sortable";
import Switch from "@paprika/switch";
import Button from "@paprika/button";
import Toast from "@paprika/toast";
import ToolTip from "@viz-ui/components/ToolTip";
import i18n from "@viz-ui/i18n/i18n";
import "./sortableSwitch.scss";

class SortableSwitch extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    fieldTypeIcon: PropTypes.bool,
    switchToggleCallback: PropTypes.func,
    fieldPositionChangeCallback: PropTypes.func,
    fieldOnClickCallback: PropTypes.func,
    fieldsRenderCallback: PropTypes.func,
  };

  static defaultProps = {
    fieldTypeIcon: false,
    switchToggleCallback: () => {},
    fieldPositionChangeCallback: () => {},
    fieldOnClickCallback: () => {},
    fieldsRenderCallback: () => {},
  };

  constructor(props) {
    super(props);
    this.sortChangeHandler = this.sortChangeHandler.bind(this);
    this.fieldTypeIcon = props.fieldTypeIcon;
    this.switchToggleCallback = props.switchToggleCallback;
    this.fieldPositionChangeCallback = props.fieldPositionChangeCallback;
    this.fieldOnClickCallback = props.fieldOnClickCallback;
    this.renderCallback = props.fieldsRenderCallback;
    this.state = {
      sortableItems: props.items.map((item, index) => {
        item.sortIndex = index;
        return item;
      }),
    };
  }

  componentDidMount() {
    this.renderCallback();
  }

  onSwitchClick(index, checked, e) {
    e.stopPropagation();

    this.setState(
      function(state) {
        return {
          sortableItems: state.sortableItems.map((item, itemIndex) => {
            if (index === itemIndex) {
              item.checked = !checked;
            }
            return item;
          }),
        };
      },
      function() {
        this.switchToggleCallback(this.state.sortableItems[index]);
      }
    );
  }

  onListItemClick(e, selectedItem) {
    const target = $(e.target);
    let jumpToField = false;
    const isJumpToFieldIcon = target.hasClass("jump-to-field") || target.closest(".jump-to-field").length;
    if (e.type === "keydown") {
      jumpToField = [13, 32].includes(e.keyCode);
    } else if (e.type === "click") {
      jumpToField = true;
    }
    if (isJumpToFieldIcon && jumpToField) {
      this.fieldOnClickCallback(selectedItem);
    }
  }

  getIconClass(type) {
    switch (type) {
      case "numeric":
        return "acl-i-number-sign";
      case "time":
        return "acl-i-clock";
      case "datetime":
        return "acl-i-time-and-date";
      case "date":
        return "acl-i-calendar";
      case "character":
        return "acl-i-character";
      case "count":
        return "acl-i-function-symbol";
      case "logical":
        return "acl-i-boolean";
      case "file":
        return "acl-i-file-text";
      case "digital_signature":
        return "acl-i-digital-signature";
      default:
        return "acl-i-caret-right";
    }
  }

  sortChangeHandler(result) {
    const { source, destination } = result;

    if (destination === null || source === destination) return;
    const reorderedChildren = [...this.state.sortableItems];
    const movedChild = reorderedChildren.splice(source, 1);
    reorderedChildren.splice(destination, 0, movedChild[0]);
    this.setState(
      () => ({
        sortableItems: reorderedChildren.map(item => {
          item.highLight = false;
          return item;
        }),
      }),
      () => {
        this.fieldPositionChangeCallback(this.state.sortableItems);
      }
    );
  }

  buildSortableItems() {
    return this.state.sortableItems.map((item, index) => {
      const handleIcon = item.isDragDisabled ? (
        <Button.Icon
          a11yText={i18n.t("_TableConfig.Field.JumpToTheField_", { fieldName: item.label })}
          className="jump-to-field"
          isSemantic={false}
          kind="minor"
          size="small"
        >
          <JumpToField size="24px" />
        </Button.Icon>
      ) : (
        <DragHandle />
      );

      return (
        <Sortable.Item
          aria-describedby=""
          aria-label={i18n.t("_TableConfig.Field.Label_", { fieldName: item.label })}
          className={item.highLight ? "sortable-highlight" : ""}
          handleElement={handleIcon}
          hasNumbers={false}
          isDragDisabled={item.isDragDisabled}
          key={item.sortIndex}
          onClick={e => {
            this.onListItemClick(e, item);
          }}
          onKeyDown={e => {
            this.onListItemClick(e, item);
          }}
          sortId={item.sortIndex}
        >
          <div className="sortable-item">
            {this.fieldTypeIcon ? (
              <i className={"sortable-item__field-type-icon " + this.getIconClass(item.type)} />
            ) : (
              ""
            )}
            <span className={"sortable-item__label " + (item.checked !== true ? "sortable-item__label--off" : "")}>
              <ToolTip offset={68} align="left" content={item.label}>
                {item.label}
              </ToolTip>
            </span>
            <Switch
              a11yText={i18n.t("_TableConfig.Field.SwitchLabel_", { fieldName: item.label })}
              className="table-field-switch"
              isChecked={item.checked === true}
              onClick={e => {
                this.onSwitchClick(index, item.checked === true, e);
              }}
              size={Switch.types.size.SMALL}
            />
          </div>
        </Sortable.Item>
      );
    });
  }

  render() {
    if (this.state.sortableItems.length === 0) {
      return (
        <>
          <div className="sortable__no-results">{i18n.t("_TableConfig.NoSearchResult.Label_")}</div>
          <Toast isPolite canAutoClose autoCloseDelay={2000} renderDelay={1000} kind={Toast.types.kind.VISUALLY_HIDDEN}>
            {i18n.t("_TableConfig.NoSearchResult.Label_")}
          </Toast>
        </>
      );
    }

    return (
      <Sortable onChange={this.sortChangeHandler} hasNumbers={false}>
        {this.buildSortableItems()}
      </Sortable>
    );
  }
}

export default SortableSwitch;
