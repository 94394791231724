angular.module("acl.visualizer.model.accessControl").factory("AccessControlAdapter", function(AccessControl) {
  function serializeAccessControl(accessControl) {
    return {
      drilldown: accessControl.drilldown(),
    };
  }

  function deserializeAccessControl(accessControlData) {
    return new AccessControl().drilldown(accessControlData.drilldown);
  }

  return {
    serializeAccessControl,
    deserializeAccessControl,
  };
});
