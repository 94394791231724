import React from "react";
import PropTypes from "prop-types";
import i18n from "@viz-ui/i18n/i18n";
import CommonSubPanel from "./CommonSubPanel";
import "./ReviewSubPanel.scss";
import "./CommentInput.scss";

export default class ReviewSubPanel extends CommonSubPanel {
  static propTypes = {
    ...CommonSubPanel.propTypes,
    numSelectedRecords: PropTypes.number.isRequired,
    onChangeComment: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="record-processing__review-panel record-processing__review-panel--visible">
        <CommonSubPanel
          {...this.props}
          minimizeIcon="arrow-left-b"
          minimizeIconSize={16}
          minimizeText={i18n.t("_RecordProcessing.CancelReview_")}
          a11yText={i18n.t("_RecordProcessing.ProcessRecordsA11y_")}
          deleteButtonMenuAlign="top"
          isReviewMode
        />
      </div>
    );
  }
}
