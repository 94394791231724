import TableCacheKeyHelper from "./tableCacheKeyHelper";
import TableIndexMap from "./tableIndexMap";
import TableValuesDataSource from "./tableValuesDataSource";
import TableValuesLoader from "./tableValuesLoader";
import MultiTableValuesIterator from "./multiTableValuesIterator";

const VALUE_PAGE_SIZE = 200;

class MultiTableValueStore {
  constructor() {
    this._filterTableIndexMap = new Map();
    this._tableValuesDataSources = new Map();
  }

  loadValues(filter, searchString, useDynamicFilter, forceRerender) {
    this._init(filter.id(), filter.filterFields(), searchString, forceRerender);
    const tableValuesIterator = this._getTableValuesIterator(filter, searchString);
    if (!tableValuesIterator.hasValues()) {
      const tableFieldsToLoad = tableValuesIterator.getTableFieldsToLoad(forceRerender);
      return TableValuesLoader.loadValues(
        tableFieldsToLoad,
        searchString,
        VALUE_PAGE_SIZE,
        useDynamicFilter,
        filter.id()
      )
        .then(tableValuesData => {
          this._updateTableValueDataSources(tableFieldsToLoad, tableValuesData);
          return this._getNextPageValues(tableValuesIterator);
        })
        .catch(() => {});
    }
    return Promise.resolve(this._getNextPageValues(tableValuesIterator));
  }

  _init(filterId, filterFields, searchString, forceRerender) {
    const tableCacheKeys = TableCacheKeyHelper.getTableCacheKeys(filterFields, searchString);
    const filterCacheKey = getFilterCacheKey(filterId, filterFields, searchString);
    if (!this._filterTableIndexMap.has(filterCacheKey) || forceRerender) {
      this._filterTableIndexMap.set(filterCacheKey, new TableIndexMap(tableCacheKeys));
    }
    tableCacheKeys.forEach(tableCacheKey => {
      if (!this._tableValuesDataSources.has(tableCacheKey) || forceRerender) {
        this._tableValuesDataSources.set(tableCacheKey, new TableValuesDataSource());
      }
    });
  }

  _getTableValuesIterator(filter, searchString) {
    const filterCacheKey = getFilterCacheKey(filter.id(), filter.filterFields(), searchString);
    const lastIteratedIndexMap = this._filterTableIndexMap.get(filterCacheKey);
    const dataSources = new Map();
    filter.filterFields().forEach(filterField => {
      const tableCacheKey = TableCacheKeyHelper.getTableCacheKey(filterField, searchString);
      dataSources.set(tableCacheKey, this._getTableValueDataSource(filterField, searchString));
    });
    const tableCacheKeys = TableCacheKeyHelper.getTableCacheKeys(filter.filterFields(), searchString);
    return new MultiTableValuesIterator(
      lastIteratedIndexMap,
      dataSources,
      tableCacheKeys,
      filter.fieldType(),
      VALUE_PAGE_SIZE
    );
  }

  _getNextPageValues(tableValuesIterator) {
    return {
      values: tableValuesIterator.nextPageValues(),
      hasMoreValues: tableValuesIterator.hasMoreValues(),
      count: tableValuesIterator.getTotalRowCount(),
    };
  }

  _getTableValueDataSource(filterField, searchString) {
    const tableCacheKey = TableCacheKeyHelper.getTableCacheKey(filterField, searchString);
    return this._tableValuesDataSources.get(tableCacheKey);
  }

  _updateTableValueDataSources(tableFieldsToUpdate, tableValuesData) {
    tableFieldsToUpdate.forEach(tableFieldToUpdate => {
      const tableValuesDataSource = this._tableValuesDataSources.get(tableFieldToUpdate.cacheKey);
      tableValuesDataSource.addData(tableValuesData[tableFieldToUpdate.cacheKey]);
    });
  }
}

function getFilterCacheKey(filterId, filterFields, searchString) {
  return JSON.stringify({
    filterId,
    tableCacheKeys: TableCacheKeyHelper.getTableCacheKeys(filterFields, searchString),
  });
}

export default new MultiTableValueStore();
