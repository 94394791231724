angular.module("acl.visualizer.style").filter("aclFieldTypeIconClassName", function() {
  return function(type) {
    switch (type) {
      case "numeric":
        return "acl-i-number-sign";
      case "time":
        return "acl-i-clock";
      case "datetime":
        return "acl-i-time-and-date";
      case "date":
        return "acl-i-calendar";
      case "character":
        return "acl-i-character";
      case "count":
        return "acl-i-function-symbol";
      case "logical":
        return "acl-i-boolean";
      case "file":
        return "acl-i-file-text";
      case "digital_signature":
        return "acl-i-digital-signature";
      default:
        return "acl-i-caret-right";
    }
  };
});
