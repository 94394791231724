angular
  .module("acl.visualizer.charts.backend")
  .service("PieChartBackendService", function(ChartBackendService, Sorter) {
    return {
      fetchJson: ChartBackendService.fetchJson,

      getRepresentation: function(resultData) {
        var data = [];

        const categoryType = resultData.config.key.data_type;
        const valueType = resultData.config.values.data_type;

        var categoryParser = ChartBackendService.getParserForType(categoryType);
        var valueParser = ChartBackendService.getParserForType(valueType);

        data = ChartBackendService.nest()
          .key(function(d) {
            return categoryParser(d.key);
          })
          .rollup(function(array) {
            if (array.length > 1) throw "Error: Found multiple data points for single category combination.";
            return valueParser(array[0].values[0]);
          })
          .excludeNullKeys(true)
          .entries(resultData.values);

        // Order first by the category which will be preserved in next sort by
        // stable sort.
        data = Sorter.sort(data, {
          dataType: categoryType,
          valueParser: item => item.key,
        });
        // Order then by the value.
        data = Sorter.sort(data, {
          dataType: "numeric",
          order: "desc",
          valueParser: item => item.values,
        });

        return data;
      },
    };
  });
