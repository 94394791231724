import logger from "@viz-ui/services/logger/logger";

(() => {
  let aclVisualizationBoard = {
    restrict: "E",
    replace: true,
    bindings: {
      boardModel: "<",
      editMode: "<",
      fontZoomRatio: "<",
      onMouseEnterDrilldown: "&",
      onMouseLeaveDrilldown: "&",
      onDrilldownClick: "&",
      presentationMode: "<",
    },
    controllerAs: "visualizationBoard",
    template: `
      <div class="storyboard-board__visualization-board">
        <div class="visualization-title"
          ng-if="!visualizationBoard.boardType || visualizationBoard.boardType=='visualization'">
          {{visualizationBoard.visualization.title}}
        </div>
        <div class="storyboard-board__loading" ng-if="visualizationBoard.showLoadingSpinner && !visualizationBoard.showBoard && !visualizationBoard.boardError && !visualizationBoard.boardModel">
          <acl-tile-loading></acl-tile-loading>
        </div>
        ${visualizationTemplate()}
        ${notificationPanelTemplate()}
      </div>`,
    controller: VisualizationBoardController,
  };

  function visualizationTemplate() {
    return `<span ng-if="visualizationBoard.showBoard && visualizationBoard.vizLoaded && !visualizationBoard.boardError">
        <hc-bar-chart
            ng-if="visualizationBoard.chartType==='BarChart'"
            table-id="visualizationBoard.tableId"
            config="visualizationBoard.visualizationConfig"
            data="visualizationBoard.data"
            label-font-size="visualizationBoard.labelFontSize"
            font-zoom-ratio="visualizationBoard.fontZoomRatio"></hc-bar-chart>
        <hc-bubble-chart
            ng-if="visualizationBoard.chartType==='BubbleChart'"
            table-id="visualizationBoard.tableId"
            config="visualizationBoard.visualizationConfig"
            data="visualizationBoard.data"
            label-font-size="visualizationBoard.labelFontSize"
            font-zoom-ratio="visualizationBoard.fontZoomRatio"></hc-bubble-chart>
        <hc-line-chart
            ng-if="visualizationBoard.chartType==='LineChart'"
            table-id="visualizationBoard.tableId"
            config="visualizationBoard.visualizationConfig"
            data="visualizationBoard.data"
            label-font-size="visualizationBoard.labelFontSize"
            font-zoom-ratio="visualizationBoard.fontZoomRatio"></hc-line-chart>
        <hc-pie-chart
            ng-if="visualizationBoard.chartType==='PieChart'"
            table-id="visualizationBoard.tableId"
            config="visualizationBoard.visualizationConfig"
            data="visualizationBoard.data"
            label-font-size="visualizationBoard.labelFontSize"
            font-zoom-ratio="visualizationBoard.fontZoomRatio"></hc-pie-chart>
        <hc-area-chart
            ng-if="visualizationBoard.chartType==='StackedAreaChart'"
            table-id="visualizationBoard.tableId"
            config="visualizationBoard.visualizationConfig"
            data="visualizationBoard.data"
            label-font-size="visualizationBoard.labelFontSize"
            font-zoom-ratio="visualizationBoard.fontZoomRatio"></hc-area-chart>
        <hc-heat-map
            ng-if="visualizationBoard.chartType==='Heatmap'"
            table-id="visualizationBoard.tableId"
            config="visualizationBoard.visualizationConfig"
            data="visualizationBoard.data"
            label-font-size="visualizationBoard.labelFontSize"
            font-zoom-ratio="visualizationBoard.fontZoomRatio"></hc-heat-map>
        <acl-summary-table-board
            ng-if="visualizationBoard.chartType==='SummaryTable'"
            table-id="visualizationBoard.tableId"
            board-id="visualizationBoard.boardId"
            columns="visualizationBoard.interpretation.tableConfig.columns"
            formatting-options="visualizationBoard.interpretation.tableConfig.formatting"
            raw-data="visualizationBoard.data"
            presentation-mode="visualizationBoard.presentationMode"
            font-zoom-ratio="visualizationBoard.fontZoomRatio"
            config="visualizationBoard.visualizationConfig"></acl-summary-table-board>
        <hc-treemap
            ng-if="visualizationBoard.chartType==='Treemap'"
            table-id="visualizationBoard.tableId"
            config="visualizationBoard.visualizationConfig"
            data="visualizationBoard.data"
            label-font-size="visualizationBoard.labelFontSize"
            font-zoom-ratio="visualizationBoard.fontZoomRatio"></hc-treemap>
        <hc-combination-chart
            ng-if="visualizationBoard.chartType==='CombinationChart'"
            table-id="visualizationBoard.tableId"
            config="visualizationBoard.visualizationConfig"
            data="visualizationBoard.data"
            font-zoom-ratio="visualizationBoard.fontZoomRatio"></hc-combination-chart>
        <hc-map-chart
            ng-if="visualizationBoard.chartType==='MapChart'"
            table-id="visualizationBoard.tableId"
            config="visualizationBoard.visualizationConfig"
            data="visualizationBoard.data"
            font-zoom-ratio="visualizationBoard.fontZoomRatio"><hc-map-chart>
      </span> `;
  }

  function notificationPanelTemplate() {
    return `<div
      ng-if="visualizationBoard.showBoard && visualizationBoard.boardError"
      acl-auto-height
      auto-height-match-element=".storyboard-board__visualization-board"
      auto-height-subtract-elements="['.visualization-title']">
      <acl-board-notification
        message="visualizationBoard.boardError" type="visualizationBoard.boardErrorType"></acl-board-notification>
    </div>`;
  }

  function VisualizationBoardController(AppConfig, ChartDisplayConfigAdapter, ChartService, Localize, Visualization) {
    "ngInject";

    let visualizationBoard = this;

    visualizationBoard.vizLoaded = false;

    visualizationBoard.showLoadingSpinner = AppConfig.features.showLoadingSpinner;

    visualizationBoard.$onChanges = function(changesObj) {
      if (changesObj.boardModel && changesObj.boardModel.currentValue) {
        const boardModel = changesObj.boardModel.currentValue;
        if (boardModel.isValid()) {
          logger.log(`${boardModel.visualizationId()} visualizationBoard.$onChanges - Start`);
          visualizationBoard.showBoard = boardModel.showBoard();
          visualizationBoard.boardError = "";
          visualizationBoard.boardErrorType = "";
          visualizationBoard.boardType = boardModel.type();
          visualizationBoard.boardId = boardModel.id();
          visualizationBoard.tableId = boardModel.controlTestId();
          visualizationBoard.interpretation = boardModel.interpretation();
          visualizationBoard.visualization = boardModel.visualization();
          visualizationBoard.chartType =
            visualizationBoard.visualization.vizType || visualizationBoard.visualization.type;
          visualizationBoard.visualizationConfig = visualizationBoard.getVisualizationConfig(boardModel);
          visualizationBoard.data = visualizationBoard.getVisualizationData(boardModel);
          logger.log(`${boardModel.visualizationId()} visualizationBoard.$onChanges - End`);
          _logging();

          visualizationBoard.vizLoaded = true;
        } else if (boardModel.boardError()) {
          visualizationBoard.showBoard = true;
          setBoardError(boardModel);
        } else {
          visualizationBoard.vizLoaded = false;
        }
      }

      if (changesObj.presentationMode) {
        visualizationBoard.labelFontSize = changesObj.presentationMode.currentValue ? "large" : undefined;
      }
    };

    function _logging() {
      const { chartType, data } = visualizationBoard;
      logger.log(`${visualizationBoard.visualization.id} - chartType: ${chartType}`);
      if (chartType !== "SummaryTable") {
        logger.log(`${visualizationBoard.visualization.id} - series length: ${data.length}`);
      }
    }

    function setBoardError(boardModel) {
      const boardError = boardModel.boardError();
      const boardErrorLocalizeKey = boardError.localizeKey();
      visualizationBoard.boardError = Localize.getLocalizedString(boardErrorLocalizeKey);
      visualizationBoard.boardErrorType = boardError.originalError();
    }

    visualizationBoard.getVisualizationData = boardModel => {
      const visualization = boardModel.visualization();
      const chartType = visualization.vizType || visualization.type;
      switch (chartType) {
        case "SummaryTable":
          return boardModel.visualizationData() || boardModel.summaryTable();
        case "Treemap":
          return getTreemapData(boardModel);
        case "MapChart":
          return getMapChartData(boardModel);
        default:
          return addColorsToData(boardModel.chartData().representation(), visualizationBoard.visualizationConfig);
      }
    };

    function getTreemapData(boardModel) {
      if (AppConfig.features.queryService) {
        return boardModel.chartData();
      }
      return boardModel.visualizationData();
    }

    function addColorsToData(data, config) {
      if (data && config) {
        const getKeyColor = ChartService.getKeyColor(config.colorByField, config.colorMapping);
        return data.map(series =>
          angular.extend({}, series, {
            color: getKeyColor(series),
          })
        );
      }
      return data;
    }

    function getMapChartData(boardModel) {
      if (AppConfig.features.queryService) {
        return boardModel.chartData().data();
      }
      return boardModel.visualizationData();
    }

    visualizationBoard.getVisualizationConfig = boardModel => {
      if (boardModel.boardError()) return {};

      const interpretation = boardModel.interpretation();
      const visualization = boardModel.visualization();
      const visualizationConfig = boardModel.visualizationConfig();
      const chartType = visualization.vizType || visualization.type;
      const chartService = Visualization.getChartService(chartType);
      let { dataConfig } = visualizationConfig;

      if (chartType === "CombinationChart") {
        dataConfig = { ...visualizationConfig.dataConfig, chartXAxis: visualizationConfig.dataConfig.chartRows[0] };
        delete dataConfig.chartRows;
      }

      const chartConfig =
        chartType === "SummaryTable"
          ? { dataConfig, displayConfig: { ...{ showTotals: true }, ...visualizationConfig.displayConfig } }
          : chartService.getChartDirectiveConfig(interpretation.id, {
              dataConfig,
              displayConfig: ChartDisplayConfigAdapter.configToViewModel(chartType, visualizationConfig.displayConfig),
              vizId: visualization.id,
            });
      const interpretationConfig = interpretation.visualizationConfig;
      return {
        ...chartConfig,
        ...{ showControls: false },
        colorMapping: interpretationConfig.colorMapping,
      };
    };
  }

  angular.module("acl.storyboard.board").component("aclVisualizationBoard", aclVisualizationBoard);
})();
