import StoryboardDataStore from "@storyboards/modules/storyboardDataStore/storyboardDataStore.service";

export default class QueryScope {
  static _storyboardId;

  static setStoryboardId(storyboardId) {
    this._storyboardId = storyboardId;
  }

  static getScope(board) {
    if (!isNewTile(board.id)) {
      return { storyboardId: this._storyboardId, ...getScopeForBoard(board) };
    }
  }

  static getScopeForGlobalFilter() {
    return { storyboardId: this._storyboardId };
  }
}

function getScopeForBoard(board = {}) {
  switch (board.type) {
    case "table":
    case "visualization":
      return { interpretationId: board.interpretation_id };
    case "metric":
    case "trigger":
      return { metricId: board.metricId };
    default:
      return {};
  }
}

function isNewTile(boardId) {
  const storyboardConfig = StoryboardDataStore.getConfig();
  const panels = storyboardConfig ? storyboardConfig.panels : [];
  return !panels.map(panel => panel.id).includes(boardId);
}
