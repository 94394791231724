/* eslint-disable no-console */
import moment from "moment";

class Logger {
  constructor() {
    this._enableLogger = false;
  }

  setFlipper(enableFlipper) {
    this._enableLogger = enableFlipper;
  }

  log(message, args) {
    if (this._enableLogger) {
      console.log(`${this.now()}: ${message}`, args ? args : "");
    }
  }

  now() {
    return moment().format("YYYY-MM-DD HH:mm:ss");
  }
}

export default new Logger();
