import GlobalFieldFormatMap from "../../services/formatters/globalFieldFormatMap";

class FieldFormatSubscriber {
  constructor() {
    this.formatListenersUnsub = null;
  }

  shouldUpdateFormatListener(currFieldFormatIdentifier, nextFieldFormatIdentifier) {
    return currFieldFormatIdentifier !== nextFieldFormatIdentifier;
  }

  subscribe(fieldFormatIdentifier, callback) {
    if (this._hasSubscribers()) {
      this.unsubscribe();
    }
    this.formatListenersUnsub = GlobalFieldFormatMap.subscribeToIdentifierObserver(fieldFormatIdentifier, callback);
  }

  _hasSubscribers() {
    return this.formatListenersUnsub !== null;
  }

  unsubscribe() {
    if (this._hasSubscribers()) {
      this.formatListenersUnsub();
      this.formatListenersUnsub = null;
    }
  }
}

export default FieldFormatSubscriber;
