import moment from "moment";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";

const FORMATS = {
  present: ["current"],
  relative: ["last", "next"],
  singular: ["day", "week", "month", "quarter", "year"],
  plural: ["days", "weeks", "months", "quarters", "years"],
};

const DATE_TIME_FORMAT = { datetime: "YYYY-MM-DD HH:mm:ss", date: "YYYY-MM-DD" };
const ISO = {
  week: "isoWeek",
  weeks: "isoWeek",
};
const MONTHS_PER_QUARTER = 3;

class RelativeDateTranslator {
  getOrgFiscalYearEnd() {
    return "02-28"; //TODO: Integrate API to get configured fiscal year end date of organization
  }

  getTimezoneOffset() {
    return GlobalValueFormatter.getTimezoneOffset();
  }

  getTimeZoneDate() {
    const timezoneOffset = GlobalValueFormatter.getTimezoneOffset();
    return moment()
      .utcOffset(timezoneOffset.utcOffsetMinutes())
      .clone();
  }

  getRelativeDate(relativeValue, formatType = "datetime", useFiscalYear = false, excludeToday = false) {
    let relativeDate;

    if (this._isCurrentFormat(relativeValue)) {
      relativeDate = this._getCurrent(relativeValue[1], useFiscalYear);
    } else if (this._isRelativeSingularFormat(relativeValue)) {
      relativeDate = this._getShortRelative(...relativeValue, useFiscalYear);
    } else if (this._isRelativePluralFormat(relativeValue)) {
      if (useFiscalYear) {
        relativeDate = this._getRelativeFiscal(...relativeValue);
      } else {
        relativeDate = this._getRelative(...relativeValue, excludeToday);
      }
    } else {
      throw "invalid relative date format";
    }

    return this._formatByType(relativeDate, formatType);
  }

  _formatByType(relativeDate, formatType) {
    const { startDate, endDate } = relativeDate;
    if (formatType === "datetime") {
      startDate.utc();
      endDate.utc();
    }
    return {
      startDate: startDate.format(DATE_TIME_FORMAT[formatType]),
      endDate: endDate.format(DATE_TIME_FORMAT[formatType]),
    };
  }

  _getCurrent(unitType, useFiscalYear) {
    let startDate, endDate;
    if (useFiscalYear) {
      if (unitType === "year") {
        return this._getCurrentFiscalYear();
      } else if (unitType === "quarter") {
        return this._getCurrentFiscalQuarter();
      }
    } else {
      const isoUnitType = ISO[unitType] || unitType;
      startDate = this.getTimeZoneDate().startOf(isoUnitType);
      endDate = this.getTimeZoneDate().endOf(isoUnitType);
      return { startDate, endDate };
    }
  }

  _getShortRelative(relativeType, unitType, useFiscalYear) {
    if (useFiscalYear) {
      return this._getRelativeFiscal(relativeType, "1", unitType);
    } else {
      return this._getRelative(relativeType, "1", unitType, false);
    }
  }

  _getRelative(relativeType, units, unitType, excludeToday) {
    let startDate, endDate;
    const isoUnitType = ISO[unitType] || unitType;
    const skipUnits = unitType === "days" ? (excludeToday ? 1 : 0) : 1;
    const skipUnitType = skipUnits ? isoUnitType : "";

    if (relativeType === "next") {
      startDate = this.getTimeZoneDate()
        .add(skipUnits, unitType)
        .startOf(skipUnitType);
      endDate = this.getTimeZoneDate()
        .add(units, unitType)
        .endOf(isoUnitType);
    } else {
      endDate = this.getTimeZoneDate()
        .subtract(skipUnits, unitType)
        .endOf(skipUnitType);
      startDate = this.getTimeZoneDate()
        .subtract(units, unitType)
        .startOf(isoUnitType);
    }
    return { startDate, endDate };
  }

  _getCurrentFiscalYearEndDate() {
    const today = this.getTimeZoneDate();
    const year = today.year();
    const orgFiscalYearEnd = this.getOrgFiscalYearEnd();
    const fiscalEndDate = moment(`${year}-${orgFiscalYearEnd}T00:00:00Z`).utcOffset(this.getTimezoneOffset());
    const nextFiscalEndDate = moment(`${year + 1}-${orgFiscalYearEnd}T00:00:00Z`).utcOffset(this.getTimezoneOffset());
    const endDate = today <= fiscalEndDate ? fiscalEndDate : nextFiscalEndDate;

    return endDate.endOf("day").clone();
  }

  _getCurrentFiscalYearStartDate() {
    return this._getCurrentFiscalYearEndDate()
      .subtract("1", "year")
      .add("1", "day")
      .startOf("day")
      .clone();
  }

  _getCurrentFiscalYear() {
    const startDate = this._getCurrentFiscalYearStartDate();
    const endDate = this._getCurrentFiscalYearEndDate();
    return { startDate, endDate };
  }

  _getCurrentFiscalQuarter() {
    const today = this.getTimeZoneDate();
    const fiscalYearStartDate = this._getCurrentFiscalYearStartDate();

    let startDate, endDate;
    for (let quarter = 0; quarter < 4; quarter++) {
      startDate = fiscalYearStartDate
        .clone()
        .add(MONTHS_PER_QUARTER * quarter, "months")
        .startOf("day");
      endDate = fiscalYearStartDate
        .clone()
        .add(MONTHS_PER_QUARTER * (quarter + 1), "months")
        .subtract("1", "day")
        .endOf("day");
      if (startDate <= today && today <= endDate) {
        break;
      }
    }
    return { startDate, endDate };
  }

  _getRelativeFiscal(relativeType, units, unitType) {
    let relativeStartDate, relativeEndDate;
    const skipUnits = parseInt(units) + 1;

    const { startDate, endDate } =
      unitType === "year" || unitType === "years" ? this._getCurrentFiscalYear() : this._getCurrentFiscalQuarter();

    if (relativeType === "next") {
      relativeStartDate = endDate
        .clone()
        .add(1, "day")
        .startOf("day");
      relativeEndDate = endDate
        .clone()
        .add(units, unitType)
        .endOf("day");
    } else {
      relativeEndDate = startDate
        .clone()
        .subtract(1, "day")
        .endOf("day");
      relativeStartDate = endDate
        .clone()
        .subtract(skipUnits, unitType)
        .add(1, "day")
        .startOf("day");
    }

    return { startDate: relativeStartDate, endDate: relativeEndDate };
  }

  _isCurrentFormat(relativeValue) {
    const { present, singular } = FORMATS;
    const [relativeType, unitType] = relativeValue;
    return relativeValue.length === 2 && present.includes(relativeType) && singular.includes(unitType);
  }

  _isRelativeSingularFormat(relativeValue) {
    const { relative, singular } = FORMATS;
    const [relativeType, unitType] = relativeValue;
    return relativeValue.length === 2 && relative.includes(relativeType) && singular.includes(unitType);
  }

  _isRelativePluralFormat(relativeValue) {
    const { relative, plural } = FORMATS;
    const [relativeType, , unitType] = relativeValue;
    return relativeValue.length === 3 && relative.includes(relativeType) && plural.includes(unitType);
  }

  isRelativeValueValid(value) {
    value = value || [];
    return this._isCurrentFormat(value) || this._isRelativeSingularFormat(value) || this._isRelativePluralFormat(value);
  }
}

export default new RelativeDateTranslator();
