import aclValueFilter from "./valueFilter.component";
import ReactDirective from "acl-ui/angular/reactDirective.umd";
import MultiSelectFilter from "@viz-ui/components/vizFilter/quickFilter/multiSelectFilter";

angular
  .module("acl.visualizer.valueFilter", [
    "acl.common.asyncCallManager",
    "acl.visualizer.charts",
    "acl.visualizer.checkboxFilter",
    "acl.visualizer.fieldValue",
    "acl.visualizer.model.field",
  ])
  .component("aclValueFilter", aclValueFilter)
  .directive("multiSelectFilter", ReactDirective.getFactory(MultiSelectFilter));
