import React from "react";
import PropTypes from "prop-types";
import NumericStatistics from "./numericStatistics";
import CharacterStatistics from "./characterStatistics";
import TemporalStatistics from "./temporalStatistics";

class Statistics extends React.Component {
  childComponentTypeMap = {
    character: CharacterStatistics,
    numeric: NumericStatistics,
    date: TemporalStatistics,
    datetime: TemporalStatistics,
    time: TemporalStatistics,
  };

  static propTypes = {
    statistics: PropTypes.arrayOf(PropTypes.any),
    config: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    statistics: [],
    config: {},
  };

  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  componentDidMount() {
    this.setState({ ...this.props, displayPopup: {} });
  }

  setDisplayForPopup = (attribute, popup, showOrHide) => {
    if (this.state.displayPopup[attribute] === undefined) {
      this.state.displayPopup[attribute] = {};
    }
    this.state.displayPopup[attribute][popup] = showOrHide;
    this.setState({ ...this.state, displayPopup: this.state.displayPopup });
  };

  showPopup = (statistic, type) =>
    statistic[type] &&
    statistic[type].length > 1 &&
    this.state.displayPopup &&
    this.state.displayPopup[statistic.fieldName] &&
    this.state.displayPopup[statistic.fieldName][type];

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.statistics !== nextProps.statistics) {
      this.setState({ ...this.state, statistics: nextProps.statistics, config: nextProps.config });
    }
  }

  render() {
    const statisticChild = this.state.statistics.map((statistic, index) => {
      const ResolvedChild = this.childComponentTypeMap[statistic.type];
      return (
        <div className="statistic-row">
          <div className="col-xs-12">
            <div className="statistic-container">
              <ResolvedChild
                statistic={statistic}
                config={this.state.config}
                index={index}
                setDisplayForPopup={this.setDisplayForPopup}
                showPopup={this.showPopup}
              />
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="chart-viz-container">
        <div className="statistics-container">{statisticChild}</div>
      </div>
    );
  }
}
export default Statistics;
