import Model from "@viz-ui/models/model";

class FilterField extends Model {
  static modelType = "FilterField";

  name(...args) {
    return this.accessor("name", args, "string");
  }

  tableId(...args) {
    return this.accessor("tableId", args, "number");
  }
}

export default FilterField;
