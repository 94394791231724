import { DateFormatter, DatetimeFormatter, TimeFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import Adapter from "@viz-ui/models/storyboardFilter/storyboardFilterAdapter";
import filterTypes from "@viz-ui/models/storyboardFilter/storyboardFilterTypes";
import filterConditions from "@viz-ui/models/storyboardFilter/storyboardFilterConditions";
import RelativeDateTranslator from "@viz-ui/models/relativeDate/relativeDateTranslator";
import ConditionalFilterService from "@viz-ui/services/storyboardFilter/conditionalFilterService";
import FilterValueService from "@viz-ui/services/storyboardFilter/filterValueService";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";

let filters = [];
let drilldownFilters = [];

class GlobalFiltersStore {
  load(storyboardConfigFilters = [], loadDrilldownFilters = true) {
    filters = storyboardConfigFilters;
    if (loadDrilldownFilters) {
      this.resetDrilldownFilters();
    }
  }

  add(model) {
    model.id(generateId(filters));
    model.isActive(true);
    let json = Adapter.serializeStoryboardFilter(model);
    filters.push(json);
    return filters;
  }

  get() {
    return deserialize(filters);
  }

  update(model) {
    let index = findIndex(model);
    if (index !== -1) {
      let newFilterObj = Adapter.serializeStoryboardFilter(model);
      filters[index] = newFilterObj;
    }
  }

  delete(model) {
    let index = findIndex(model);
    if (index !== -1) {
      filters.splice(index, 1);
    }
  }

  hasFilters() {
    return filters.length > 0;
  }

  //enable clear fliter if one of the filter has value
  isClearFilterEnabled() {
    const validFilterFound = this.get().some(filter => {
      switch (filter.type()) {
        case filterTypes.MULTISELECT:
        case filterTypes.CONDITIONAL:
          return filter.values() && filter.values().length > 0;

        case filterTypes.MANDATORY_SINGLESELECT:
        case filterTypes.OPTIONAL_SINGLESELECT:
          return filter.value() && filter.value(); //to return true
        default:
          return false;
      }
    });
    return validFilterFound;
  }

  hasOnlyValidFilters() {
    if (!this.hasFilters()) return true;
    return this.get()
      .filter(filter => filter.type() === filterTypes.CONDITIONAL)
      .every(
        filter => isValidForConditionalFilterQuery(filter) || isEmptyConditionalFilter(filter) || !filter.isActive()
      );
  }

  forQuery(controlTestId) {
    return filters.reduce((validFilters, globalFilter) => {
      const hasControlTestId = globalFilter.fields.find(field => field.table_id === controlTestId);
      globalFilter = Adapter.translateStoryboardFilter(globalFilter);
      const model = Adapter.deserializeStoryboardFilter(globalFilter);
      if (isValidFilterForQuery(model, globalFilter.type) && hasControlTestId) {
        if (model.isUnSelectedValuesDefined() && model.unSelectedValues().length > 0) {
          const unSelectedValueModel = model.clone();
          model.unSelectedValues().forEach(unSelectedValue => {
            unSelectedValueModel.unSelectedValues([unSelectedValue]);
            validFilters.push(Adapter.serializeForQuery(unSelectedValueModel, controlTestId));
          });
        } else {
          validFilters.push(Adapter.serializeForQuery(model, controlTestId));
        }
      }
      return validFilters;
    }, []);
  }

  //Removing the filters other than current filter id
  getUniqueFilters(currentFilterId) {
    return filters.filter(flt => flt.id !== currentFilterId);
  }

  // generating query based on the dynamic filter
  forDynamicQuery(dynamicFilters, controlTestId) {
    return dynamicFilters.reduce((validFilters, globalFilter) => {
      const hasControlTestId = globalFilter.fields.find(field => field.table_id === controlTestId);
      globalFilter = Adapter.translateStoryboardFilter(globalFilter);
      const model = Adapter.deserializeStoryboardFilter(globalFilter);
      if (isValidFilterForQuery(model, globalFilter.type) && hasControlTestId) {
        if (model.isUnSelectedValuesDefined() && model.unSelectedValues().length > 0) {
          const unSelectedValueModel = model.clone();
          model.unSelectedValues().forEach(unSelectedValue => {
            unSelectedValueModel.unSelectedValues([unSelectedValue]);
            validFilters.push(Adapter.serializeForQuery(unSelectedValueModel, controlTestId));
          });
        } else {
          validFilters.push(Adapter.serializeForQuery(model, controlTestId));
        }
      }
      return validFilters;
    }, []);
  }

  resetDrilldownFilters() {
    drilldownFilters = [...filters];
  }

  getDrilldownFilters() {
    return deserialize(drilldownFilters);
  }

  toJson() {
    return filters.slice();
  }

  toExportJson() {
    const exportFilters = [];
    filters.forEach(filter => {
      if (filter.is_active) {
        const globalFilter = Adapter.translateStoryboardFilter(filter);
        const model = Adapter.deserializeStoryboardFilter(globalFilter);
        const filterFieldName = getFilterFieldName(model);

        GlobalFieldFormatMap.setFieldFormat(globalFilter.id, filterFieldName, model.fieldFormat());
        GlobalFieldFormatMap.setFieldType(globalFilter.id, filterFieldName, model.fieldType());
        const displayValues = getDisplayValues(model, filterFieldName);
        exportFilters.push({
          name: globalFilter.display_name,
          operator: ConditionalFilterService.getOperatorName(globalFilter.operator || "="),
          value: displayValues,
        });
      }
    });
    return exportFilters;
  }
}

const getFilterFieldName = model => {
  return model
    .filterFields()
    .map(field => field.name())
    .join("-");
};

const getDisplayValues = (model, filterFieldName) => {
  const value = model.value();
  const values =
    filterTypes.CONDITIONAL === model.type() && model.values()
      ? Adapter.normalizeConditionalFilterValues(model)
      : model.values();

  let displayValues = [];
  if (value) {
    displayValues = [FilterValueService.getItemDisplayValue(value, model.id(), filterFieldName)];
  } else if (values) {
    displayValues = values.map(value => FilterValueService.getItemDisplayValue(value, model.id(), filterFieldName));
  }
  return displayValues;
};

const findIndex = model => filters.findIndex(filter => filter.id === model.id());

const isValidFilterForQuery = (filter, filterType) => {
  if (!filter.isActive()) return false;
  switch (filterType) {
    case filterTypes.OPTIONAL_SINGLESELECT:
    case filterTypes.MANDATORY_SINGLESELECT:
      return !!filter.value();
    case filterTypes.CONDITIONAL:
      return isValidForConditionalFilterQuery(filter);
    case filterTypes.MULTISELECT:
      return (
        (!!filter.values() && !!filter.values().length) ||
        (!!filter.unSelectedValues() && !!filter.unSelectedValues().length)
      );
    default:
      return false;
  }
};

const isValidForConditionalFilterQuery = filter => {
  let realValues = [];
  if (filter.operator()) {
    switch (filter.operator()) {
      case filterConditions.BLANK:
      case filterConditions.NOT_BLANK:
        return true;
      case filterConditions.BETWEEN_OR_EQUAL_TO:
        if (!filter.values()) return false;
        realValues = filter.values().filter(v => !v.isBlank());
        return (
          realValues.length === 2 &&
          realValues.every(v => isValidConditionalValue(v.value(), filter.fieldType(), filter.fieldFormat()))
        );
      case filterConditions.RELATIVE:
        if (!filter.values()) return false;
        realValues = filter
          .values()
          .filter(v => !v.isBlank())
          .map(v => v.value());
        if (realValues[1] === "1") return false;
        return RelativeDateTranslator.isRelativeValueValid(realValues);
      default:
        if (!filter.values()) return false;
        realValues = filter.values().filter(v => !v.isBlank());
        return (
          realValues.length > 0 &&
          realValues.every(v => isValidConditionalValue(v.value(), filter.fieldType(), filter.fieldFormat()))
        );
    }
  }

  return false;
};

const isValidConditionalValue = (value, fieldType, fieldFormat) => {
  switch (fieldType) {
    case "numeric":
      return !Number.isNaN(value);
    case "datetime":
      return fieldFormat && fieldFormat.displayTime() !== false
        ? DatetimeFormatter.isUnformatted(value)
        : DateFormatter.isUnformatted(value);
    case "date":
      return DateFormatter.isUnformatted(value);
    case "time":
      return TimeFormatter.isValid(value);
    default:
      return true;
  }
};

const isEmptyConditionalFilter = filter =>
  !filter.operator() && (!filter.values() || filter.values().filter(v => !v.isBlank()).length === 0);

const generateId = _filters => {
  const findFilterById = _id => _filters.find(filter => filter.id === _id);
  let id;
  do {
    id = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  } while (findFilterById(id));
  return id;
};

const deserialize = filtersJson => filtersJson.map(globalFilter => Adapter.deserializeStoryboardFilter(globalFilter));

// eslint-disable-next-line no-unused-vars
const serialize = filterModels => filterModels.map(globalFilter => Adapter.serializeStoryboardFilter(globalFilter));

export default new GlobalFiltersStore();
