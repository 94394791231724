import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Button from "acl-ui/components/Button";
import Icon from "acl-ui/components/Icon";
import L10n from "acl-ui/components/L10n/L10n";
import Toast from "acl-ui/components/Toast";
import Bubbly from "acl-ui/components/Bubbly";
import SidePanel from "@paprika/sidepanel";

import StoryboardFilter from "@viz-ui/models/storyboardFilter/storyboardFilter";
import TableAdapter from "@viz-ui/models/table/tableAdapter";
import FilterConfigPanel from "@viz-ui/components/stbGlobalFilter/filterConfigPanelContainer";
import NewTab from "@paprika/icon/lib/NewTab";
import { Localize } from "@storyboards/services/glue/appGlue";
import i18n from "@viz-ui/i18n/i18n";
import { i18nFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import User from "@storyboards/modules/services/user.service";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import checkboxState from "@viz-ui/models/checkboxState";
import filterTypes from "@viz-ui/models/storyboardFilter/storyboardFilterTypes";
import MetadataManager from "../services/MetadataManager";
import globalFiltersStore from "../services/GlobalFiltersStore";
import Filter from "./Filter";
import "./FilterPanel.scss";

class FilterPanel extends Component {
  static propTypes = {
    canValidate: PropTypes.bool,
    tableIds: PropTypes.arrayOf(PropTypes.number),
    flipConditional: PropTypes.bool.isRequired,
    flipMultiselect: PropTypes.bool.isRequired,
    flipRelativeFilters: PropTypes.bool.isRequired,
    flipQueryService: PropTypes.bool,
    flipUnformattedValues: PropTypes.bool,
    isConfigPanelOpen: PropTypes.bool,
    isOpen: PropTypes.bool,
    mode: PropTypes.oneOf(["editMode", "viewMode"]).isRequired,
    onApplyGlobalFilters: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    setConfigPanelOpen: PropTypes.func,
    setUnsavedChanges: PropTypes.func.isRequired,
    shouldFilterPanelForceUpdate: PropTypes.bool,
    setShouldFilterPanelForceUpdate: PropTypes.func,
    flipDynamicFilter: PropTypes.bool,
  };

  static defaultProps = {
    canValidate: false,
    isConfigPanelOpen: false,
    tableIds: [],
    isOpen: false,
    flipUnformattedValues: false,
    setConfigPanelOpen: () => {},
    shouldFilterPanelForceUpdate: false,
    flipQueryService: true,
    setShouldFilterPanelForceUpdate: () => {},
    flipDynamicFilter: false,
  };

  constructor(props) {
    super(props);
    this.metadataManager = MetadataManager.getInstance(props.flipQueryService);

    i18n.locale = Localize.language;
    i18nFormatter.locale = Localize.language;

    this.state = {
      highlightedFilter: null,
      tables: [],
      forceRerender: false,
      clearAllFilter: false,
    };
    this.sidePanelWidth = "100%";
    this.refHeading = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.mode) {
      if (this.panelShouldClose(nextProps)) this.props.onClose();
    }
    if (!nextProps.isOpen || !nextProps.isConfigPanelOpen) {
      this.closeConfigPanel();
    }
    if (nextProps.tableIds && !User.isAnonymous()) {
      this.setState({ tables: [] });
      this.getMetadata(nextProps.tableIds);
    }
    if (nextProps.shouldFilterPanelForceUpdate) {
      this.props.setShouldFilterPanelForceUpdate(false);
      this.forceUpdate();
    }
  }

  getMetadata(tableIds = this.props.tableIds) {
    this.metadataManager.get(tableIds).then(metadata => {
      this.setState({
        tables: metadata,
      });
    });
  }

  resetForceReRenderFlag = () => {
    this.setState({ forceRerender: false, clearAllFilter: false });
    window.clearAllFilter = false; //settting as window object to reuse in table serivces
  };

  handleFilterEdit = filter => {
    this.setState({
      currentFilter: filter,
      highlightedFilter: filter.id(),
      saveButtonLabel: Localize.t("_Storyboards.GlobalFilters.FilterPanel.EditButton_"),
      title: Localize.t("_Storyboards.GlobalFilters.FilterPanel.EditTitle_").replace("$0", filter.displayName()),
    });
    this.props.setConfigPanelOpen(true);
    UsageTracker.createEvent(".filterpanel.edited");
  };

  handleAddFilterClick = () => {
    this.setState({
      currentFilter: new StoryboardFilter(),
      saveButtonLabel: Localize.t("_Storyboards.GlobalFilters.FilterPanel.AddButton_"),
      title: Localize.t("_Storyboards.GlobalFilters.FilterPanel.AddTitle_"),
    });
    this.props.setConfigPanelOpen(true);
    UsageTracker.createEvent(".filterpanel.added");
  };

  handleFilterDelete = filter => {
    this.forceUpdate();
    this.handleFilterChange();

    if (!globalFiltersStore.hasFilters(this.props.mode)) this.props.onApplyGlobalFilters();
    if (this.configPanelShouldClose(filter)) this.closeConfigPanel();
    if (this.panelShouldClose()) this.props.onClose();
  };

  handleSubmit = stbFilterModel => {
    if (stbFilterModel.id()) {
      // resetting the filter flags post edit
      if (this.props.flipDynamicFilter) {
        stbFilterModel = this.handleFilterReset(stbFilterModel);
      }
      globalFiltersStore.update(stbFilterModel);
    } else {
      globalFiltersStore.add(stbFilterModel);
    }

    this.handleFilterChange();
    this.closeConfigPanel();
    this.forceUpdate();
  };

  handleFilterChange = () => {
    this.props.setUnsavedChanges();
  };

  handleClose = () => {
    this.closeConfigPanel();
    this.props.onClose();
    UsageTracker.createEvent(".filterpanel.closed");
  };

  handleConfigPanelClose = () => {
    this.closeConfigPanel();
  };

  handleApplyFiltersClick = () => {
    //only force update when dynamic filter enabled
    //helps to remove unselected values for dynamic parent filters
    const hasOnlyValidFilters = globalFiltersStore.hasOnlyValidFilters();
    //check if the filters contains valid filters, only then force update
    if (this.props.flipDynamicFilter && hasOnlyValidFilters) {
      this.forceUpdate();
      this.setState({ forceRerender: true });
    }
    this.props.onApplyGlobalFilters();
    UsageTracker.createEvent(".filterpanel.filtersapplied");
  };

  //to Reset Filter
  handleClearFiltersClick = () => {
    let filtersAvailable = globalFiltersStore.get();
    filtersAvailable.forEach(item => {
      const filterToBeCleared = this.handleFilterReset(item);
      globalFiltersStore.update(filterToBeCleared);
    });
    this.handleFilterChange();
    this.setState({ forceRerender: true, clearAllFilter: true });
    window.clearAllFilter = true; //settting as window object to reuse in table serivces
    this.forceUpdate();
    this.props.onApplyGlobalFilters();
  };

  // handle reset filter
  handleFilterReset = filter => {
    let filterToBeCleared = filter.clone();
    switch (filterToBeCleared.type()) {
      case filterTypes.MULTISELECT:
        filterToBeCleared.selectAllState(checkboxState.UNCHECKED);
        filterToBeCleared.unSelectedValues([]);
        break;
      case filterTypes.CONDITIONAL:
        filterToBeCleared.operator("");
        break;
      case filterTypes.OPTIONAL_SINGLESELECT:
      case filterTypes.MANDATORY_SINGLESELECT:
        filterToBeCleared.value(null);
        break;

      default:
        break;
    }
    filterToBeCleared.isCurrentDynamicFilter(false);
    filterToBeCleared.isSelectedDynamicFilter(false);
    filterToBeCleared.isSelectedFrozenFilter(false);
    filterToBeCleared.isCurrentEditedDynamicFilter(false);
    filterToBeCleared.isFrozenFilter(false);
    filterToBeCleared.values(null);
    return filterToBeCleared;
  };

  handlePanelAfterOpen = () => {
    if (this.refHeading.current) this.refHeading.current.focus();
  };

  handlePanelAfterClose = () => {
    const filterBtn = document.querySelector("#storyboardFilterBtn");
    if (filterBtn) filterBtn.focus();
  };

  panelShouldClose = (props = this.props) =>
    props.isOpen && !globalFiltersStore.hasFilters() && (props.mode === "viewMode" || props.tableIds.length === 0);

  configPanelShouldClose(filter) {
    return this.state.currentFilter && this.state.currentFilter.id() === filter.id() && this.props.isConfigPanelOpen;
  }

  closeConfigPanel() {
    this.props.setConfigPanelOpen(false);
    this.setState({
      highlightedFilter: null,
    });
  }

  isEditDisabled(filter, tableModels) {
    if (tableModels) {
      let filterTableIds = filter.filterFields().map(field => field.tableId());
      let allowedTableIds = tableModels.map(table => table.id());
      return filterTableIds.some(filterTableId => !allowedTableIds.includes(filterTableId));
    }
  }

  renderAddFilter = () => {
    if (this.props.mode === "editMode") {
      return (
        <p>
          <Button
            className="stb-panel__add-filter"
            isDisabled={this.props.isConfigPanelOpen}
            isFullWidth
            onClick={this.handleAddFilterClick}
            size="large"
          >
            <Icon type="add" />
            {Localize.t("_Storyboards.GlobalFilters.FilterPanel.AddButton_")}
          </Button>
        </p>
      );
    }
  };

  renderFilters = tableModels =>
    globalFiltersStore.get().map(filter => (
      <Filter
        canValidate={this.props.canValidate}
        filter={filter}
        flipConditional={this.props.flipConditional}
        flipMultiselect={this.props.flipMultiselect}
        flipRelativeFilters={this.props.flipRelativeFilters}
        flipQueryService={this.props.flipQueryService}
        isEditDisabled={this.isEditDisabled(filter, tableModels)}
        isHighlighted={this.state.highlightedFilter === filter.id()}
        mode={this.props.mode}
        onClickEdit={() => this.handleFilterEdit(filter)}
        onConfirmDelete={this.handleFilterDelete}
        onFilterChange={this.handleFilterChange}
        clearAllFilter={this.state.clearAllFilter}
        flipDynamicFilter={this.props.flipDynamicFilter} // passing dynamic filter flipper to child components
        forceRerender={this.state.forceRerender}
        resetForceReRenderFlag={this.resetForceReRenderFlag}
      />
    ));

  renderEditorHelp = () => {
    const bubblyContent = <p>{Localize.t("_Storyboards.GlobalFilters.FilterPanel.EditorHelp.BubblyMain_")}</p>;
    return globalFiltersStore.hasFilters() ? (
      <Toast>{Localize.t("_Storyboards.GlobalFilters.FilterPanel.Help.SetDefaults_")}</Toast>
    ) : (
      <Bubbly
        alignBubbly="top"
        header={Localize.t("_Storyboards.GlobalFilters.FilterPanel.EditorHelp.BubblyHeader_")}
        content={bubblyContent}
      />
    );
  };

  renderViewerHelp = () => {
    if (globalFiltersStore.hasFilters()) return null;
    const bubblyContent = <p>{Localize.t("_Storyboards.GlobalFilters.FilterPanel.ViewerHelp.BubblyMain_")}</p>;
    return (
      <Bubbly
        alignBubbly="top"
        header={Localize.t("_Storyboards.GlobalFilters.FilterPanel.ViewerHelp.BubblyHeader_")}
        content={bubblyContent}
      />
    );
  };

  renderHelp = () => (this.props.mode === "editMode" ? this.renderEditorHelp() : this.renderViewerHelp());

  renderDynamicFilterEnabledToast = () => {
    if (!this.props.flipDynamicFilter) return null;
    return (
      <Toast kind="info" hasCloseButton={false} className="stb-panel__dynamic-filter-toast">
        {Localize.t("_Storyboards.GlobalFilters.FilterPanel.DynamicFilterToastEnableMessage")}{" "}
        <a
          href="https://help.highbond.com/helpdocs/highbond/en-us/Default.htm#cshid=stb-dynamic-filters"
          target="_blank"
          rel="noopener noreferrer"
        >
          {Localize.t("_Storyboards.GlobalFilters.FilterPanel.DynamicFilterToastLink")}
          <NewTab className="stb-panel__dynamic-filter-toast__icon" />
        </a>
      </Toast>
    );
  };

  renderActions = () => {
    if (!globalFiltersStore.hasFilters()) return null;
    return (
      <div className="stb-panel__actions">
        <Button onClick={this.handleApplyFiltersClick} type="primary">
          {Localize.t("_Storyboards.GlobalFilters.FilterPanel.ApplyButton_")}
        </Button>
        {this.props.flipDynamicFilter && (
          <Button
            type="minor"
            className="stb-panel__clear-button"
            onClick={this.handleClearFiltersClick}
            // Clear all filter will be enabled at all time for now. Reason addressed in VRMRG-2591.
            // isDisabled={!globalFiltersStore.isClearFilterEnabled()}
          >
            {Localize.t("_Storyboards.GlobalFilters.FilterPanel.ClearFilterButton_")}
          </Button>
        )}
      </div>
    );
  };

  render() {
    const rootClasses = classNames("filter-panel-wrapper", { "filter-panel--open": this.props.isOpen });

    const configPanelClasses = classNames("stb-panel__config-panel", {
      "stb-panel__config-panel--open": this.props.isConfigPanelOpen,
    });
    const sidePanelClass = classNames("stb-panel__content", {
      "stb-panel__content-withDynamicFilterEnabled": this.props.flipDynamicFilter,
      "stb-panel__content-withDynamicFilterNotEnabled": !this.props.flipDynamicFilter,
    });

    const tableModels = this.state.tables.map(table => TableAdapter.deserializeTable(table.id, table));

    let filterConfigPanel = "";

    if (this.state.currentFilter) {
      const props = {
        filterModel: this.state.currentFilter,
        flipConditional: this.props.flipConditional,
        flipMultiselect: this.props.flipMultiselect,
        flipUnformattedValues: this.props.flipUnformattedValues,
        onClose: this.handleConfigPanelClose,
        onSubmit: this.handleSubmit,
        saveButtonLabel: this.state.saveButtonLabel,
        tableModels: tableModels,
        title: this.state.title,
      };
      filterConfigPanel = <FilterConfigPanel {...props} />;
    }

    return (
      <div className={rootClasses}>
        <L10n locale={i18n.locale}>
          {this.renderDynamicFilterEnabledToast()}
          <SidePanel
            isInline
            isOpen={this.props.isOpen}
            onAfterOpen={this.handlePanelAfterOpen}
            onAfterClose={this.handlePanelAfterClose}
            onClose={this.handleClose}
            width={this.sidePanelWidth}
            className={sidePanelClass}
          >
            <SidePanel.Header isSticky refHeading={this.refHeading} className="stb-panel__header">
              {Localize.t("_Storyboards.GlobalFilters.FilterPanel.Title_")}
            </SidePanel.Header>

            <SidePanel.Content className="stb-panel__body">
              {this.props.isConfigPanelOpen}
              {this.renderAddFilter()}
              {this.renderHelp()}
              {this.renderFilters(tableModels)}
            </SidePanel.Content>
            <SidePanel.Footer isSticky>{this.renderActions()}</SidePanel.Footer>
          </SidePanel>
          <div className={configPanelClasses}>{filterConfigPanel}</div>
        </L10n>
      </div>
    );
  }
}

window.FilterPanel = FilterPanel;
