export default function(
  AppConfig,
  BarChart,
  BubbleChart,
  HeatmapService,
  LineChart,
  PieChart,
  StackedAreaChart,
  CombinationChart
) {
  "ngInject";

  class ChartDisplayConfigAdapter {
    static configToViewModel(chartType, config) {
      switch (chartType) {
        case "BarChart":
          return this._barChartConfigToViewModel(config);
        case "LineChart":
          return this._lineChartConfigToViewModel(config);
        case "StackedAreaChart":
          return this._areaChartConfigToViewModel(config);
        case "PieChart":
          return this._pieChartConfigToViewModel(config);
        case "BubbleChart":
          return this._bubbleChartConfigToViewModel(config);
        case "Heatmap":
          return this._heatmapConfigToViewModel(config);
        case "SummaryTable":
        case "StatisticsViz":
        case "Treemap":
        case "MapChart":
          return angular.copy(config);
        case "CombinationChart":
          return this._combinationChartConfigToViewModel(config);
        default:
          throw Error(`Unexpected chart type. type=${chartType}`);
      }
    }

    static _barChartConfigToViewModel(config) {
      const result = angular.copy(config);
      const isPreHighCharts = typeof result.displayDataLabels === "undefined";
      if (isPreHighCharts) {
        result.displayDataLabels = false;
        if (!result.yAxis) {
          result.yAxis = {};
        }
        if (result.yAxis.minimum === 0) {
          result.yAxis.minimum = null;
        }
      }
      if (!result.chartStyle && result.isStacked) {
        result.chartStyle = "stack";
      }
      this.forceDefaultsDeep(result, BarChart.defaultDisplayConfig());
      return result;
    }

    static _lineChartConfigToViewModel(config) {
      const result = angular.copy(config);
      const isPreHighCharts = typeof result.displayDataLabels === "undefined";
      if (isPreHighCharts) {
        result.displayDataLabels = false;
        if (!result.yAxis) {
          result.yAxis = {};
        }
        if (result.yAxis.minimum === 0) {
          result.yAxis.minimum = null;
        }
        result.interpolate = true;
        this.forceDefaultsDeep(result, LineChart.defaultDisplayConfig());
      }
      return result;
    }

    static _areaChartConfigToViewModel(config) {
      let result = angular.copy(config);
      const isPreHighCharts = typeof result.displayDataLabels === "undefined";
      if (isPreHighCharts) {
        result.displayDataLabels = false;
        if (!result.yAxis) {
          result.yAxis = {};
        }
        if (result.yAxis.minimum === 0) {
          result.yAxis.minimum = null;
        }
        result.interpolate = true;
        result = this.forceDefaultsDeep(result, StackedAreaChart.defaultDisplayConfig());
        if (result.chartStyle === "stream") {
          result.chartStyle = "overlap";
        }
      }
      return result;
    }

    static _pieChartConfigToViewModel(config) {
      const result = angular.copy(config);
      const isPreHighCharts = typeof result.displayDataLabels === "undefined";
      if (isPreHighCharts) {
        this.forceDefaultsDeep(result, PieChart.defaultDisplayConfig());
      }
      return result;
    }

    static _bubbleChartConfigToViewModel(config) {
      const result = angular.copy(config);
      const isPreHighCharts = typeof result.displayDataLabels === "undefined";
      if (isPreHighCharts) {
        result.displayDataLabels = false;
        this.forceDefaultsDeep(result, BubbleChart.defaultDisplayConfig());
      }
      return result;
    }

    static _heatmapConfigToViewModel(config) {
      let result = angular.copy(config);
      const isPreHighCharts = typeof result.displayDataLabels === "undefined";
      if (isPreHighCharts) {
        result.displayDataLabels = false;
        result = this.forceDefaultsDeep(result, HeatmapService.defaultDisplayConfig());
        result.colorAxis.colorStops = result.colorAxis.colorStops.slice(
          0,
          HeatmapService.defaultDisplayConfig().colorAxis.colorStops.length
        );
        if (config.reverseColors) {
          result.colorAxis.colorStops = result.colorAxis.colorStops.reverse();
        }
      }
      if (!result.colorAxis) {
        result.colorAxis = {};
      }
      if (!result.colorAxis.colorStops) {
        result.colorAxis.colorStops = HeatmapService.defaultDisplayConfig().colorAxis.colorStops;
      }
      return result;
    }

    static _combinationChartConfigToViewModel(config) {
      const result = angular.copy(config);
      const isPreHighCharts = typeof result.displayDataLabels === "undefined";
      if (isPreHighCharts) {
        result.displayDataLabels = false;
        if (!result.yAxis) {
          result.yAxis = [
            {
              minimum: null,
            },
            {
              minimum: null,
            },
          ];
        }
        if (result.yAxis[0].minimum === 0) {
          result.yAxis[0].minimum = null;
        }
        if (result.yAxis[1].minimum === 0) {
          result.yAxis[1].minimum = null;
        }
      }
      if (!result.chartStyle && result.isStacked) {
        result.chartStyle = "stack";
      }
      this.forceDefaultsDeep(result, CombinationChart.defaultDisplayConfig());
      return result;
    }

    static forceDefaultsDeep(target, defaults) {
      if (arguments.length > 2) {
        let result = this.forceDefaultsDeep(target, defaults);
        Array.prototype.slice.call(arguments, 2).forEach(moreDefaults => {
          result = this.forceDefaultsDeep(result, moreDefaults);
        });
        return result;
      }

      if (angular.isObject(defaults)) {
        if (angular.isArray(defaults)) {
          if (!angular.isArray(target)) {
            const result = [];
            defaults.forEach(value => {
              result.push(this.forceDefaultsDeep(undefined, value));
            });
            return result;
          }

          target.forEach((value, i) => {
            target[i] = this.forceDefaultsDeep(value, defaults[i]);
          });
          return target;
        }

        const result = angular.isObject(target) && !angular.isArray(target) ? target : {};
        angular.forEach(defaults, (value, key) => {
          result[key] = this.forceDefaultsDeep(result[key], value);
        });
        return result;
      }

      return typeof target === "undefined" ? defaults : target;
    }
  }

  return ChartDisplayConfigAdapter;
}
