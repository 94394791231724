import Button from "@paprika/button";
import Modal from "@paprika/modal";
import { zValue } from "@paprika/stylers/lib/helpers";
import PropTypes from "prop-types";
import React from "react";
import i18n from "@viz-ui/i18n/i18n";
import "./chartSelectorModal.scss";
import ToolTip from "@viz-ui/components/ToolTip";

const propTypes = {
  openModal: PropTypes.bool,
  headerText: PropTypes.string.isRequired,
  zIndex: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  chartTypes: PropTypes.arrayOf(Object).isRequired,
  onPickChart: PropTypes.func.isRequired,
};

const defaultProps = {
  openModal: false,
  zIndex: zValue(6),
};
function ChartSelectorModal(props) {
  function handleKeyDown(event, chartType) {
    if (event.keyCode === 13 || event.keyCode === 32) {
      props.onPickChart(chartType);
    }
  }
  let options = [];
  if (props.chartTypes) {
    options = props.chartTypes.map((chartType, index) => {
      return (
        <li className="thumbnail chartselector-modal-items">
          <ToolTip align="bottom" content={chartType.tooltip}>
            <div
              data-autofocus
              tabIndex="0"
              aria-label={chartType.tooltip}
              data-testid={`chartType-${index + 1}`}
              id={`chartType-${index + 1}`}
              className={`chartselector-thumbnail-icon  ${chartType.thumbnailIcon}`}
              onClick={() => {
                props.onPickChart(chartType);
              }}
              onKeyDown={() => {
                handleKeyDown(event, chartType);
              }}
              uib-tooltip={chartType.tooltip}
            ></div>
          </ToolTip>
        </li>
      );
    });
  }
  return (
    <Modal style={{ width: "585px" }} zIndex={props.zIndex} isOpen={props.openModal} onClose={props.onCancel}>
      <Modal.Header>{props.headerText}</Modal.Header>
      <Modal.Content>
        <div data-testid="chart-selector-body" className="chart-selector-body">
          <ul className="chartselector-modal-thumbnails">{options}</ul>
        </div>
      </Modal.Content>
      <Modal.Footer className="modal-footer">
        <Button data-testid="cancel-button" kind="minor" className="footer-button--cancel" onClick={props.onCancel}>
          {i18n.t("_General.Cancel.Label_")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
ChartSelectorModal.displayName = "ChartSelectorModal";
ChartSelectorModal.propTypes = propTypes;
ChartSelectorModal.defaultProps = defaultProps;

export default ChartSelectorModal;
