import PropTypes from "prop-types";
import React from "react";
class AclPopover extends React.Component {
  constructor(props) {
    super(props);

    this.getCssClass = this.getCssClass.bind(this);
    this.closePopover = this.closePopover.bind(this);

    this.state = { show: props.show };
  }

  closePopover() {
    this.setState({ show: false });
    this.props.onCloseClick();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState({ show: nextProps.show });
    }
  }

  getCssClass() {
    let cssClass = "acl-popover wa";
    switch (this.props.placement) {
      case "top":
        cssClass = cssClass + " wa-bottom";
        break;
      case "bottom":
        cssClass = cssClass + " wa-top";
        break;
      case "left":
        cssClass = cssClass + " wa-right";
        break;
      case "right":
        cssClass = cssClass + " wa-left";
        break;
      default:
        cssClass = cssClass + " wa-bottom";
    }

    if (this.props.cssClass) {
      cssClass = cssClass + " " + this.props.cssClass;
    }

    if (this.state.show) {
      cssClass = cssClass + " is-visible";
    }

    return cssClass;
  }

  getCloseBtn() {
    let closeBtn;
    if (this.props.showCloseBtn) {
      closeBtn = (
        <a className="acl-popover-close" onClick={this.closePopover}>
          ×
        </a>
      );
    }
    return closeBtn;
  }

  render() {
    return (
      <div className={this.getCssClass()}>
        <div className="acl-popover-wrapper">{this.props.children}</div>
        {this.getCloseBtn()}
      </div>
    );
  }
}

AclPopover.propTypes = {
  show: PropTypes.bool,
  showCloseBtn: PropTypes.bool,
  placement: PropTypes.string,
  cssClass: PropTypes.string,
  onCloseClick: PropTypes.func,
};

AclPopover.defaultProps = {
  showCloseBtn: false,
  onCloseClick: () => {},
};

window.AclPopover = AclPopover;
