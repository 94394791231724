import React from "react";
import PropTypes from "prop-types";
import ToolTip from "./ToolTip";
import i18n from "@viz-ui/i18n/i18n";

function getContent() {
  return (
    <>
      {i18n.t("_ChartConfig.Display.EnableBoost.Tooltip_")}
      &nbsp;
      <a href="https://help.highbond.com/helpdocs/highbond/en-us/Default.htm#cshid=viz-chart-boost" target="_blank">
        <b>{i18n.t("_GlobalFilter.DescriptionMoreLink_")}</b>
      </a>
    </>
  );
}

function BoostToolTip(props) {
  const { children, ...moreProps } = props;

  return (
    <ToolTip content={getContent()} className="boost-info-icon" {...moreProps}>
      {children}
    </ToolTip>
  );
}

BoostToolTip.propTypes = {
  children: PropTypes.node.isRequired,
};

BoostToolTip.defaultProps = {};

export default BoostToolTip;
