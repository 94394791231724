import Metric from "@viz-ui/models/metric/metric";
import MetricSparkline from "@viz-ui/models/metric/metricSparkline";
import Table from "@viz-ui/models/table/table";
import Board from "./board";

export default class MetricBoard extends Board {
  static modelType = "MetricBoard";

  projectId(...args) {
    return this.accessor("projectId", args, "number");
  }

  controlId(...args) {
    return this.accessor("controlId", args, "number");
  }

  controlTestId(...args) {
    return this.accessor("controlTestId", args, "number");
  }

  metricId(...args) {
    return this.accessor("metricId", args, "number");
  }

  triggerId(...args) {
    return this.accessor("triggerId", args, "number");
  }

  conditionGroupId(...args) {
    return this.accessor("conditionGroupId", args, "number");
  }

  table(...args) {
    return this.accessor("table", args, Table);
  }

  metric(...args) {
    return this.accessor("metric", args, Metric);
  }

  metricSparkline(...args) {
    return this.accessor("metricSparkline", args, MetricSparkline);
  }

  metricConfig() {
    return this.metric() ? this.metric().metricConfig() : undefined;
  }

  metricName() {
    return this.metric() ? this.metric().name() : "";
  }

  metricType() {
    const metricConfig = this.metricConfig();
    return metricConfig ? this.metric().name() : "";
  }

  fieldName() {
    const metricConfig = this.metricConfig();
    return metricConfig ? metricConfig.fieldName() : "";
  }

  timeFieldName() {
    const metricConfig = this.metricConfig();
    return metricConfig ? metricConfig.timeFieldName() : "";
  }

  showSparkline() {
    const metricConfig = this.metricConfig();
    return metricConfig ? this.type() === "trigger" || metricConfig.showSparkline() : false;
  }

  isValid() {
    return this.metric() && this.table() && this.metricSparkline();
  }
}
