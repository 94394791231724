import DataGrid from "@viz-ui/components/dataGrid/dataGrid";
import ConditionalFormatting from "@viz-ui/services/quickMenu/conditionalFormat/conditionalFormattingService";

(() => {
  var aclDataGrid = {
    restrict: "E",
    replace: true,
    bindings: {
      commentCounts: "<",
      checkRowOnRowClick: "<",
      data: "<",
      fields: "<",
      fieldFormatIdentifier: "<",
      filterFieldNames: "<",
      filteredRecordCount: "<",
      fixedColumnsLeft: "<",
      fontZoomRatio: "<",
      getAttachmentUrl: "<",
      highlightSelectedRow: "<",
      isHeaderClickingEnabled: "<",
      isHeaderIconEnabled: "<",
      isPresentationMode: "<",
      isRowHighlightingEnabled: "<",
      isRowSelectionEnabled: "<",
      isShrinkWrapEnabled: "<",
      isZebraStripingEnabled: "<",
      onColumnHeaderClick: "&",
      onColumnResize: "&",
      onRowClick: "&",
      onRowDoubleClick: "&",
      onScrollToBottom: "&",
      onSelectionChange: "&",
      outsideClickDeselects: "<",
      rowCheckedInverse: "<",
      selectedFieldName: "<",
      selectedRowId: "<",
      showCheckedRecordsOnly: "<",
      checkedRowIds: "<",
      sortFieldName: "<",
      sortOrder: "<",
      tableConfigFieldObjs: "<",
      showCommentCount: "<",
    },
    controllerAs: "dataGrid",
    controller: DataGridController,
  };

  function DataGridController($element, $scope, AppConfig, ColumnConfig) {
    "ngInject";
    var dataGrid = this;
    var ref;

    let data = [];

    dataGrid.$onChanges = function(changesObj) {
      if (changesObj.data || changesObj.fields) {
        data = transformRowsForDataGrid(dataGrid.data, dataGrid.fields);
      }

      if (dataGrid.data && dataGrid.fields) {
        const componentProps = getProps();

        if ($element.find(".data-grid__container").width() === 0 && (changesObj.data || changesObj.fields)) {
          unmountDataGrid();
        }
        ref = ReactDOM.render(React.createElement(DataGrid, componentProps), $element[0]);
      }
    };

    dataGrid.$onDestroy = function() {
      unmountDataGrid();
    };

    $scope.$on("tableRedraw", function() {
      if (ref) ref.redraw();
    });

    function getProps() {
      const headerFunction = dataGrid.isHeaderClickingEnabled ? columnHeaderClickHandler : null;
      return {
        columnConfigs: tableConfigFieldObjsToConfigColumnModels(dataGrid.tableConfigFieldObjs),
        checkRowOnRowClick: dataGrid.checkRowOnRowClick,
        data: data,
        detectScrollToBottomByHeight: !!AppConfig.features.detectScrollToBottomByHeight,
        fields: dataGrid.fields,
        fieldFormatIdentifier: dataGrid.fieldFormatIdentifier,
        filterFieldNames: dataGrid.filterFieldNames,
        filteredRecordCount: dataGrid.filteredRecordCount,
        fixedColumnsLeft: dataGrid.fixedColumnsLeft,
        fontZoomRatio: dataGrid.fontZoomRatio,
        getAttachmentUrl: dataGrid.getAttachmentUrl,
        highlightSelectedRow: dataGrid.highlightSelectedRow,
        isHeaderIconEnabled: dataGrid.isHeaderIconEnabled,
        isPresentationMode: dataGrid.isPresentationMode,
        isRowHighlightingEnabled: dataGrid.isRowHighlightingEnabled,
        isRowSelectionEnabled: dataGrid.isRowSelectionEnabled,
        isShrinkWrapEnabled: dataGrid.isShrinkWrapEnabled,
        isZebraStripingEnabled: dataGrid.isZebraStripingEnabled,
        onColumnHeaderClick: headerFunction,
        onColumnResize: columnResizeHandler,
        onRowClick: rowClickHandler,
        onRowDoubleClick: rowDoubleClickHandler,
        onScrollToBottom: scrollToBottomHandler,
        rowIdKey: "metadata.exception_id",
        rowSelectInverse: dataGrid.rowCheckedInverse,
        onSelectionChange: selectionChangeHandler,
        selectedFieldName: dataGrid.selectedFieldName,
        selectedRowId: dataGrid.selectedRowId,
        showCheckedRowsOnly: dataGrid.showCheckedRecordsOnly,
        checkedRowIds: dataGrid.checkedRowIds,
        sortFieldName: dataGrid.sortFieldName,
        sortOrder: dataGrid.sortOrder,
        isPositionEnabled: AppConfig.features.remediationColumns,
        outsideClickDeselects: dataGrid.outsideClickDeselects,
        positionKey: "metadata.position",
        isCommentsEnabled: AppConfig.features.remediationColumns && dataGrid.showCommentCount,
        commentCounts: dataGrid.commentCounts,
      };
    }

    function transformRowsForDataGrid(rawRows, fieldModels) {
      let formatters = getFormatters();
      let transformedFieldModels = {};

      if (rawRows && rawRows.length) {
        if (fieldModels) {
          transformedFieldModels = transformFieldModels(fieldModels);
        }
        return rawRows.map(rawRow => {
          let fieldName;
          let transformedRow = {};
          let value;

          for (fieldName in rawRow) {
            if (Object.prototype.hasOwnProperty.call(rawRow, fieldName)) {
              const fieldModel = transformedFieldModels[fieldName];
              if (fieldModel) {
                const fieldFormatters = formatters[fieldName];
                value = rawRow[fieldName];
                transformedRow[fieldName] = {
                  value: value,
                  textColor: format(fieldFormatters, "textColor", value),
                  backgroundColor: format(fieldFormatters, "backgroundColor", value),
                  iconColor: format(fieldFormatters, "iconColor", value),
                  iconName: format(fieldFormatters, "iconName", value),
                };

                if (
                  (fieldModel.type === "file" || fieldModel.type === "digital_signature") &&
                  transformedRow[fieldName].value
                ) {
                  const fileNames = transformedRow[fieldName].value;
                  const files = getFilePaths(fileNames);
                  transformedRow[fieldName].files = files;
                }
              } else if (fieldName === "metadata.exception_id" || fieldName === "metadata.position") {
                value = rawRow[fieldName];
                transformedRow[fieldName] = {
                  value: value,
                };
              }
            }
          }

          return transformedRow;
        });
      }
      return rawRows;
    }

    function unmountDataGrid() {
      if (ref && $element[0]) {
        ReactDOM.unmountComponentAtNode($element[0]);
      }
    }

    function getFormatters() {
      var fieldModels = dataGrid.fields || [];
      var result = {};

      fieldModels.forEach(function(fieldModel) {
        var fieldName = fieldModel.name();
        result[fieldName] = {
          textColor: ConditionalFormatting.getTextColorFormatter(fieldName, dataGrid.fieldFormatIdentifier),
          backgroundColor: ConditionalFormatting.getBackgroundColorFormatter(fieldName, dataGrid.fieldFormatIdentifier),
          iconColor: ConditionalFormatting.getIconColorFormatter(fieldName, dataGrid.fieldFormatIdentifier),
          iconName: ConditionalFormatting.getIconNameFormatter(fieldName, dataGrid.fieldFormatIdentifier),
        };
      });

      return result;
    }

    function format(fieldFormatters, name, value) {
      return fieldFormatters && fieldFormatters[name] && fieldFormatters[name](value);
    }

    function transformFieldModels(fieldModels) {
      let newFieldModels = {};
      fieldModels.forEach(fieldModel => {
        newFieldModels[fieldModel.name()] = {
          ...fieldModel._data,
        };
      });
      return newFieldModels;
    }

    function getFilePaths(fileNames) {
      var files = fileNames.split(",");
      return files.map(file => {
        const id = parseInt(file, 10);
        const filename = file.replace(/^[0-9]+-/, "");
        return { path: dataGrid.getAttachmentUrl(id), title: filename };
      });
    }

    function tableConfigFieldObjsToConfigColumnModels(fieldObjs) {
      return (
        (fieldObjs &&
          fieldObjs.map(function(fieldObj) {
            return new ColumnConfig()
              .name(fieldObj.fieldName)
              .visible(fieldObj.visible)
              .width(fieldObj.columnWidth)
              .wordWrap(fieldObj.wordWrap);
          })) ||
        []
      );
    }

    function columnHeaderClickHandler(columnIndex, fieldName, mouseEvent) {
      if (dataGrid.onColumnHeaderClick) {
        $scope.$apply(function() {
          dataGrid.onColumnHeaderClick({
            columnIndex: columnIndex,
            fieldName: fieldName,
            mouseEvent: mouseEvent,
            offset: getColumnHeaderOffset(mouseEvent),
          });
        });
      }
    }

    function getColumnHeaderOffset(mouseEvent) {
      var pos;
      var thElem;

      if (mouseEvent) {
        if (mouseEvent.target) {
          thElem = $(mouseEvent.target).closest("th");
          if (thElem.length) {
            pos = thElem.offset();
          } else {
            pos = $(mouseEvent.target).offset();
          }
        } else {
          pos = { left: mouseEvent.x, top: mouseEvent.y };
        }
      } else {
        pos = { left: 0, top: 0 };
      }

      return pos;
    }

    function columnResizeHandler(columnIndex, fieldName, width) {
      if (dataGrid.onColumnResize) {
        $scope.$apply(function() {
          dataGrid.onColumnResize({ columnIndex, fieldName, width });
        });
      }
    }

    function rowClickHandler(row, rowId) {
      if (dataGrid.onRowClick) {
        $scope.$apply(function() {
          dataGrid.onRowClick({
            row: row,
            rowId: rowId,
          });
        });
      }
    }

    function rowDoubleClickHandler(row, rowId) {
      if (dataGrid.onRowDoubleClick) {
        $scope.$apply(function() {
          dataGrid.onRowDoubleClick({
            row: row,
            rowId: rowId,
          });
        });
      }
    }

    function selectionChangeHandler(checkedRowIds, selectedRowId, inverseMode) {
      if (dataGrid.onSelectionChange) {
        $scope.$apply(function() {
          dataGrid.onSelectionChange({ checkedRowIds, selectedRowId, inverseMode });
        });
      }
    }

    function scrollToBottomHandler() {
      if (dataGrid.onScrollToBottom) {
        $scope.$apply(function() {
          dataGrid.onScrollToBottom();
        });
      }
    }
  }

  angular.module("acl.visualizer.dataGrid").component("aclDataGrid", aclDataGrid);
})();
