angular.module("acl.visualizer.indicatorConfigPanel", [
  "acl.common.aclInput",
  "acl.common.tabSwitcher",
  "acl.visualizer.conditionalFormatting",
  "acl.visualizer.dataTypeFunctions",
  "acl.visualizer.model.filter",
  "acl.visualizer.model.metric",
  "acl.visualizer.indicator",
  "acl.visualizer.dataModel",
  "acl.visualizer.style",
  "acl.visualizer.aclColorPicker",
  "acl.core.conditionalCriteriaSelector",
  "templates-visualizer",
]);
