import PropTypes from "prop-types";
import React from "react";
import services from "../../../services/glue/appGlue";
import DownloadPdfIcon from "@acl-services/wasabicons/lib/DownloadPdf";

const ExportPane = props => {
  return (
    <div class="sidebar-block-content">
      <ul class="export-storyboard-options">
        <li class="pdf">
          <a onClick={props.onExportStoryboardToPDFClick}>
            <DownloadPdfIcon
              aria-label={services.Localize.getLocalizedString("_ExportPanel.Export.PDF_")}
              title={services.Localize.getLocalizedString("_ExportPanel.Export.PDF_")}
              role="button"
              size="20px"
              width="20px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
            <span class="export-label">{services.Localize.getLocalizedString("_ExportPanel.Export.PDF_")}</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

ExportPane.propTypes = {
  onExportStoryboardToPDFClick: PropTypes.func,
};

ExportPane.defaultProps = {
  onExportStoryboardToPDFClick: () => {},
};

export default ExportPane;
