import React from "react";
import i18n from "@viz-ui/i18n/i18n";
import PropTypes from "prop-types";

class CharacterStatistics extends React.PureComponent {
  static propTypes = {
    index: PropTypes.number,
    statistic: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    index: 0,
    statistic: {},
  };

  render() {
    return (
      <div id={`statistic-${this.props.statistic.fieldName}-${this.props.index}`} className="character">
        <div className="statistic_info">
          <h2 className="statistic_title">{this.props.statistic.displayName}</h2>
        </div>

        <div className="statistic_stats">
          <div className="count stat">
            <h3 className="value">{this.props.statistic.uniqueValues}</h3>
            <p className="title">{i18n.t("_Chart.StatisticsViz.UniqueValues.Label_")}</p>
          </div>
          <div className="blanks stat">
            <h3 className="value">{this.props.statistic.blanks}</h3>
            <p className="title">{i18n.t("_Chart.StatisticsViz.Blanks.Label_")}</p>
          </div>
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default CharacterStatistics;
