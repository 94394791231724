export default  (AppConfig, Localize) => {
  "ngInject";

  // FIXME: Merge this with conditionalCriteriaSelector/conditionalOperators.service.js
  class ConditionalOperators {
    getOperatorsByType(fieldType) {
      let operatorDefs = getOperatorDefs();
      return getOperatorsByType(fieldType)
        .filter(
          operatorName =>
            AppConfig.features.hasBlankFilter || (operatorName !== "is_blank" && operatorName !== "is_not_blank")
        )
        .map(operatorName => {
          let def = operatorDefs.find(operatorDef => operatorDef.operator === operatorName);
          return {
            operator: def.operator,
            name: def.name,
          };
        });
    }

    getArgumentCount(operatorName) {
      let operatorDefs = getOperatorDefs();
      return operatorDefs.find(operator => operator.operator === operatorName).argumentCount;
    }

    isValid(operatorName, fieldType) {
      let operatorsByType = getOperatorsByType(fieldType);
      return operatorsByType.includes(operatorName);
    }
  }

  let getOperatorDefs = () => [
    { operator: ">", name: Localize.getLocalizedString("_CriteriaFilter.GreaterThan.Label_"), argumentCount: 1 },
    { operator: "<", name: Localize.getLocalizedString("_CriteriaFilter.LessThan.Label_"), argumentCount: 1 },
    {
      operator: ">=",
      name: Localize.getLocalizedString("_CriteriaFilter.GreaterThanAndEqual.Label_"),
      argumentCount: 1,
    },
    {
      operator: "<=",
      name: Localize.getLocalizedString("_CriteriaFilter.LessThanAndEqual.Label_"),
      argumentCount: 1,
    },
    { operator: "=", name: Localize.getLocalizedString("_CriteriaFilter.Equals.Label_"), argumentCount: 1 },
    { operator: "!=", name: Localize.getLocalizedString("_CriteriaFilter.NotEqual.Label_"), argumentCount: 1 },
    { operator: "between", name: Localize.getLocalizedString("_CriteriaFilter.Between.Label_"), argumentCount: 2 },
    { operator: "^=", name: Localize.getLocalizedString("_CriteriaFilter.BeginsWith.Label_"), argumentCount: 1 },
    { operator: "contain", name: Localize.getLocalizedString("_CriteriaFilter.Contains.Label_"), argumentCount: 1 },
    {
      operator: "!contain",
      name: Localize.getLocalizedString("_CriteriaFilter.NotContain.Label_"),
      argumentCount: 1,
    },
    { operator: "is_blank", name: Localize.getLocalizedString("_CriteriaFilter.IsBlank.Label_"), argumentCount: 0 },
    {
      operator: "is_not_blank",
      name: Localize.getLocalizedString("_CriteriaFilter.IsNotBlank.Label_"),
      argumentCount: 0,
    },
  ];

  let getOperatorsByType = type => {
    let mapping = {
      character: ["^=", "contain", "!contain", "is_blank", "is_not_blank"],
      numeric: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank"],
      date: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank"],
      time: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank"],
      datetime: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank"],
      logical: ["="],
      file: ["is_blank", "is_not_blank"],
      digital_signature: ["is_blank", "is_not_blank"],
    };
    if (!mapping[type]) {
      throw new Error(`Unknown type. type=${type}`);
    }
    return mapping[type];
  };

  return new ConditionalOperators();
};
