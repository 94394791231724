import CriteriaFilter from "./criteriaFilter";

class Threshold extends CriteriaFilter {
  static modelType = "Threshold";

  indicatorColor(...args) {
    return this.accessor("indicatorColor", args, "string");
  }

  trigger(...args) {
    return this.accessor("trigger", args, Object);
  }

  id(...args) {
    return this.accessor("id", args, "number");
  }

  static fromJson(json) {
    return new Threshold()
      .id(json.id)
      .indicatorColor(json.indicator_color)
      .operator(json.operator)
      .values(json.values)
      .trigger(json.trigger);
  }

  toJson() {
    return this._deleteUndefinedProperties({
      id: this.id(),
      operator: this.operator(),
      values: this.values(),
      indicator_color: this.indicatorColor(),
      trigger: this.trigger(),
    });
  }
}
export default Threshold;
