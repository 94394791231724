export const patternFillPalettes = [
  "url(#starling-pattern-0-Lighter)",
  "url(#starling-pattern-1-Lighter)",
  "url(#starling-pattern-2-Lighter)",
  "url(#starling-pattern-3-Lighter)",
  "url(#starling-pattern-4-Lighter)",
  "url(#starling-pattern-5-Lighter)",
  "url(#starling-pattern-6-Lighter)",
  "url(#starling-pattern-7-Lighter)",
  "url(#starling-pattern-8-Lighter)",
  "url(#starling-pattern-9-Lighter)",
  "url(#starling-pattern-10-Lighter)",
  "url(#starling-pattern-11-Lighter)",
  "url(#starling-pattern-0-Light)",
  "url(#starling-pattern-1-Light)",
  "url(#starling-pattern-2-Light)",
  "url(#starling-pattern-3-Light)",
  "url(#starling-pattern-4-Light)",
  "url(#starling-pattern-5-Light)",
  "url(#starling-pattern-6-Light)",
  "url(#starling-pattern-7-Light)",
  "url(#starling-pattern-8-Light)",
  "url(#starling-pattern-9-Light)",
  "url(#starling-pattern-10-Light)",
  "url(#starling-pattern-11-Light)",
  "url(#starling-pattern-0-Base)",
  "url(#starling-pattern-1-Base)",
  "url(#starling-pattern-2-Base)",
  "url(#starling-pattern-3-Base)",
  "url(#starling-pattern-4-Base)",
  "url(#starling-pattern-5-Base)",
  "url(#starling-pattern-6-Base)",
  "url(#starling-pattern-7-Base)",
  "url(#starling-pattern-8-Base)",
  "url(#starling-pattern-9-Base)",
  "url(#starling-pattern-10-Base)",
  "url(#starling-pattern-11-Base)",
  "url(#starling-pattern-0-Dark)",
  "url(#starling-pattern-1-Dark)",
  "url(#starling-pattern-2-Dark)",
  "url(#starling-pattern-3-Dark)",
  "url(#starling-pattern-4-Dark)",
  "url(#starling-pattern-5-Dark)",
  "url(#starling-pattern-6-Dark)",
  "url(#starling-pattern-7-Dark)",
  "url(#starling-pattern-8-Dark)",
  "url(#starling-pattern-9-Dark)",
  "url(#starling-pattern-10-Dark)",
  "url(#starling-pattern-11-Dark)",
];
