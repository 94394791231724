import cloneDeep from "lodash/cloneDeep";
import checkboxState from "@viz-ui/models/checkboxState";

export default class GraphqlPayloadGenerator {
  constructor(filterConfig, tableId, loggedInUserName, selectedRecords = [], openStatuses = []) {
    this.tableId = tableId;
    this.loggedInUserName = loggedInUserName;
    this.filterConfig = filterConfig;
    this.selectedRecords = selectedRecords;
    this.SORT_DIRECTION = { asc: "ASC", desc: "DESC" };
    this.FILTER_CONFIG_OPERATORS = {
      "=": "IN",
      "!=": "NEQ",
      ">": "GT",
      "<": "LT",
      ">=": "GTE",
      "<=": "LTE",
      between: "BETWEEN",
      not_between: "NOT_BETWEEN",
      is_blank: "IS_BLANK",
      is_not_blank: "NOT_BLANK",
      "^=": "BEGINS",
      contain: "CONTAINS",
      "!contain": "NOT_CONTAIN",
    };
    this.FILTER_CONNECTORS = { or: "OR", and: "AND" };
    this.GRAPHQL_FILTER_OPERATION = { and: "AND", or: "OR", not: "NOT" };
    this.ARRAY_VALUE_OPERATORS = ["=", "between"]; // viz "=" is used as "IN" in the graphql
    this.EMPTY_VALUE_OPERATORS = ["is_blank", "is_not_blank"];
    this.RECORD_ID_FIELD = "metadata.record_id";
    this.ASSIGNEE_FIELD = "metadata.assignee";
    this.STATUS_FIELD = "metadata.status";
    this.OPEN_STATUS_FILTER = openStatuses;
  }

  // add the selected records to the filter
  _addSelectedRecordsFilter = () =>
    this._buildFieldFilterObject(
      this.RECORD_ID_FIELD,
      "=",
      this.selectedRecords.map(recordId => String(recordId))
    );

  // add assigned to user filter
  _addAssignedToMeFilter = () => this._buildFieldFilterObject(this.ASSIGNEE_FIELD, "=", [this.loggedInUserName]);

  // add open status filter
  _addStatusOpenFilter = () => this._buildFieldFilterObject(this.STATUS_FIELD, "=", this.OPEN_STATUS_FILTER);

  // get top level filters like assignedToMe, statusOpen.
  _getTopLevelFilters = () => {
    const topLevelFilters = [];
    if (this.filterConfig.myRecords) topLevelFilters.push(this._addAssignedToMeFilter());
    if (this.filterConfig.openStatuses) topLevelFilters.push(this._addStatusOpenFilter());
    return topLevelFilters;
  };

  // format the visualization order by expression to match graphql order by expression
  _translateOrderByExpression = () => {
    if (this.filterConfig.sortField) {
      return {
        field: this.filterConfig.sortField.field,
        order: this.SORT_DIRECTION[this.filterConfig.sortField.order],
      };
    }
    return null;
  };

  // builds filter object from quick filter
  _formatQuickFilters = (quickFilter, fieldName) => {
    // handle empty values separately
    if (quickFilter.operator === "=" && quickFilter.values.includes("")) {
      const nonBlankFilter = this._removeEmptyQuickFilterValue(quickFilter);
      return this._joinIsBlankFilter(nonBlankFilter, fieldName);
    }

    // non-empty values
    return this._buildFieldFilterObject(fieldName, quickFilter.operator, quickFilter.values);
  };

  // based on empty and non-empty values prepares the filter object
  _joinIsBlankFilter = (filter, fieldName) => {
    const isBlankFilter = this._buildFieldFilterObject(fieldName, "is_blank", "");
    // if quickFilter has no other value return just isBlankFilter
    if (filter.values.length === 0) {
      return isBlankFilter;
    }

    // combine isBlankFilter and nonBlankFilter
    return {
      operation: this.GRAPHQL_FILTER_OPERATION.or,
      fieldFilters: [isBlankFilter, this._buildFieldFilterObject(fieldName, filter.operator, filter.values)],
    };
  };

  // removes empty value from quick filter and returns the new filter
  _removeEmptyQuickFilterValue = filter => {
    const filterObj = cloneDeep(filter);
    const quickFilterValues = filterObj.values;
    const emptyValueIndex = quickFilterValues.findIndex(value => value === "");
    quickFilterValues.splice(emptyValueIndex, 1);
    return filterObj;
  };

  // returns graphql compatible fieldFilter object
  _buildFieldFilterObject = (fieldName, operator, filterValue) => ({
    field: fieldName,
    comparison: [
      {
        operation: this.FILTER_CONFIG_OPERATORS[operator],
        value: this.EMPTY_VALUE_OPERATORS.includes(operator) ? "" : filterValue,
      },
    ],
  });

  _createFieldFilters = (filters, connector) => {
    let output = {};
    if (filters.length > 1) {
      output.operation = this.GRAPHQL_FILTER_OPERATION[connector];
      output.fieldFilters = filters;
    } else {
      output = filters[0];
    }
    return output;
  };

  // build filter sub tree
  _buildFilterSubTree = fieldFilter => {
    let rootTree = { fieldFilters: [] };
    const filterList = [];
    const filtersLength = fieldFilter.filters ? fieldFilter.filters.length : 0;
    let lastConnector = null;
    let andFilters = [];
    let orFilters = [];

    if (filtersLength === 0) {
      // when no conditional filters are there
      const quickFilter = this._formatQuickFilters(fieldFilter.quickFilter, fieldFilter.name);
      filterList.push({ filters: [quickFilter] });
    }

    for (let j = 0; j < filtersLength; j++) {
      const filter = fieldFilter.filters[j];
      const filterValue = this.ARRAY_VALUE_OPERATORS.includes(filter.operator) ? filter.values : filter.values[0];

      if (filter.connector === "and" || lastConnector === "and") {
        // if last filter, take care of quick filter as well
        if (j === filtersLength - 1) {
          andFilters.push(this._buildFieldFilterObject(fieldFilter.name, filter.operator, filterValue));
          if (filter.connector === "and") {
            if (orFilters.length) {
              filterList.push({ operation: "or", filters: orFilters });
              orFilters = [];
            }
            if (fieldFilter.quickFilter) {
              andFilters.push(this._formatQuickFilters(fieldFilter.quickFilter, fieldFilter.name));
            }
            filterList.push({ operation: "and", filters: andFilters });
          } else {
            filterList.push({ operation: "and", filters: andFilters });
            if (fieldFilter.quickFilter) {
              filterList.push({
                operation: "or",
                filters: [this._formatQuickFilters(fieldFilter.quickFilter, fieldFilter.name)],
              });
            }
          }
        } else {
          // not last filter
          if (orFilters.length) {
            filterList.push({ operation: "or", filters: orFilters });
            orFilters = [];
          }
          andFilters.push(this._buildFieldFilterObject(fieldFilter.name, filter.operator, filterValue));
          if (filter.connector === "or") {
            filterList.push({ operation: "and", filters: andFilters });
            andFilters = [];
          }
        }
      }

      if (filter.connector === "or" && lastConnector !== "and") {
        // if last filter, take care of quick filter as well
        if (j === filtersLength - 1) {
          orFilters.push(this._buildFieldFilterObject(fieldFilter.name, filter.operator, filterValue));
          if (fieldFilter.quickFilter) {
            orFilters.push(this._formatQuickFilters(fieldFilter.quickFilter, fieldFilter.name));
          }
          filterList.push({ operation: "or", filters: orFilters });
        } else {
          // not last filter
          if (andFilters.length) {
            filterList.push({
              operation: "and",
              filters: andFilters,
            });
            andFilters = [];
          }
          orFilters.push(this._buildFieldFilterObject(fieldFilter.name, filter.operator, filterValue));
        }
      }

      if (filter.connector === "or") {
        rootTree.operation = this.FILTER_CONNECTORS[filter.connector];
      }

      lastConnector = filter.connector;
    }

    const filterListLength = filterList.length;
    if (filterListLength > 1) rootTree.fieldFilters = [];
    for (let index = 0; index < filterListLength; index++) {
      const obj = filterList[index];
      const fieldFilterNode = this._createFieldFilters(obj.filters, obj.operation);

      if (filterListLength > 1) rootTree.fieldFilters.push(fieldFilterNode);
      else rootTree = fieldFilterNode;
    }

    return rootTree;
  };

  // format the visualization filter expression to match graphql filter expression
  _translateFilterExpression = () => {
    let filterExpression = { fieldFilters: [] };
    if (this.selectedRecords.length > 0) {
      filterExpression.fieldFilters.push(this._addSelectedRecordsFilter());
      return filterExpression;
    }
    const topLevelFilters = this._getTopLevelFilters();
    let filterList = [];
    if (this.filterConfig && this.filterConfig.filterList && this.filterConfig.filterList.length > 0) {
      filterList = this._excludeFilters(this.filterConfig.filterList);
    }
    const totalActiveFilterFields = filterList.filter(filter => filter.active === true).length;
    const topLevelFiltersCount = topLevelFilters.length;
    if (totalActiveFilterFields === 0 && topLevelFiltersCount === 0) return null;
    if (topLevelFiltersCount > 0) {
      if (topLevelFiltersCount > 1) {
        filterExpression.fieldFilters = topLevelFilters;
        filterExpression.operation = this.GRAPHQL_FILTER_OPERATION.and;
      } else if (topLevelFiltersCount === 1) {
        if (totalActiveFilterFields) {
          filterExpression.fieldFilters = topLevelFilters;
          filterExpression.operation = this.GRAPHQL_FILTER_OPERATION.and;
        } else filterExpression.fieldFilters = topLevelFilters[0];
      }
    }
    for (let i = 0; i < filterList.length; i++) {
      const fieldFilter = filterList[i];

      // ignore inactive filters
      if (!fieldFilter.active) {
        continue;
      }
      const filterSubTree = this._buildFilterSubTree(fieldFilter);
      if (filterExpression.operation === undefined && totalActiveFilterFields === 1) {
        if (filterSubTree.operation) filterExpression = filterSubTree;
        else filterExpression.fieldFilters = filterSubTree;
      } else {
        filterExpression.operation = this.GRAPHQL_FILTER_OPERATION.and;
        filterExpression.fieldFilters.push(filterSubTree);
      }
    }

    if (filterExpression.fieldFilters) return filterExpression;
    return null;
  };

  _excludeFilters = fieldFiltersList => {
    return fieldFiltersList.reduce((updatedFieldFilterList, currentFieldFilter) => {
      if (currentFieldFilter.quickFilter && currentFieldFilter.quickFilter.selectAllState == checkboxState.CHECKED) {
        const criteriaFiltersLength = currentFieldFilter.filters ? currentFieldFilter.filters.length : 0;
        if (criteriaFiltersLength > 0) {
          updatedFieldFilterList.push({ ...currentFieldFilter, quickFilter: null });
        }
      } else if (
        currentFieldFilter.quickFilter &&
        currentFieldFilter.quickFilter.unCheckedValues &&
        currentFieldFilter.quickFilter.unCheckedValues.length > 0
      ) {
        const criteriaFilters = currentFieldFilter.filters || [];

        currentFieldFilter.quickFilter.unCheckedValues.forEach(unCheckedValue => {
          criteriaFilters.push({ connector: "and", operator: "!=", values: [unCheckedValue] });
        });

        updatedFieldFilterList.push({ ...currentFieldFilter, quickFilter: null, filters: criteriaFilters });
      } else {
        updatedFieldFilterList.push(currentFieldFilter);
      }
      return updatedFieldFilterList;
    }, []);
  };

  getPayload = function() {
    return {
      sort: this._translateOrderByExpression(),
      filter: this._translateFilterExpression(),
      tableId: this.tableId,
    };
  };
}
