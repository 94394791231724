import cloneDeep from "lodash/cloneDeep";

function padArray(array, length, value) {
  const result = cloneDeep(array);
  while (result.length < length) {
    result.push(value);
  }
  return result;
}

export default class ChartDataConfigAdapter {
  static configToViewModel(chartType, config) {
    switch (chartType) {
      case "SummaryTable":
        return angular.copy(config);
      case "BarChart":
      case "LineChart":
      case "StackedAreaChart":
        return this.twoAxisChartConfigToViewModel(config);
      case "PieChart":
        return this.pieChartConfigToViewModel(config);
      case "BubbleChart":
        return this.bubbleChartConfigToViewModel(config);
      case "StatisticsViz":
        return this.statisticsConfigToViewModel(config);
      case "Heatmap":
        return this.heatmapConfigToViewModel(config);
      case "Treemap":
        return this.treemapConfigToViewModel(config);
      case "CombinationChart":
        return this.combinationChartConfigToViewModel(config);
      case "MapChart":
        return this.mapChartConfigToViewModel(config);
      default:
        throw new Error("Unexpected chart type");
    }
  }

  static twoAxisChartConfigToViewModel(config) {
    return {
      chartSeries: this.getViewModelFieldName(config.chartSeries),
      chartXAxis: this.getViewModelFieldName(config.chartXAxis),
      chartValue: this.getViewModelFieldName(config.chartValue),
      aggregationType: this.getViewModelAggregationType(config.chartValue),
      fieldType: this.getViewModelFieldType(config.chartSeries),
    };
  }

  static pieChartConfigToViewModel(config) {
    return {
      chartCategory: this.getViewModelFieldName(config.chartCategory),
      aggregationType: this.getViewModelAggregationType(config.chartValue),
      chartValue: this.getViewModelFieldName(config.chartValue),
      fieldType: this.getViewModelFieldType(config.chartCategory),
    };
  }

  static bubbleChartConfigToViewModel(config) {
    return {
      chartSeries: this.getViewModelFieldName(config.chartSeries),
      chartXAxis: this.getViewModelFieldName(config.chartXAxis),
      chartYAxis: this.getViewModelFieldName(config.chartYAxis),
      chartValue: this.getViewModelFieldName(config.chartValue),
      aggregationType: this.getViewModelAggregationType(config.chartValue),
      fieldType: this.getViewModelFieldType(config.chartSeries),
    };
  }

  static heatmapConfigToViewModel(config) {
    return {
      chartXAxis: this.getViewModelFieldName(config.chartXAxis),
      chartYAxis: this.getViewModelFieldName(config.chartYAxis),
      chartValue: this.getViewModelFieldName(config.chartValue),
      aggregationType: this.getViewModelAggregationType(config.chartValue),
    };
  }

  static treemapConfigToViewModel(config) {
    return {
      chartRows: this.getViewModelFieldNames(config.chartRows),
      chartValues: padArray(this.getViewModelFieldNames(config.chartValues), 2, ""),
      aggregationTypes: padArray(this.getViewModelAggregationTypes(config.chartValues), 2, "sum"),
    };
  }

  static statisticsConfigToViewModel(config) {
    return {
      chartCategory: this.getViewModelFieldNames(config.chartCategory),
    };
  }

  static combinationChartConfigToViewModel(config) {
    return {
      chartXAxis: this.getViewModelFieldName(config.chartXAxis),
      chartValues: padArray(this.getViewModelFieldNames(config.chartValues), 2, ""),
      chartSeries: this.getViewModelFieldName(config.chartSeries),
      aggregationTypes: padArray(this.getViewModelAggregationTypes(config.chartValues), 2, "sum"),
      fieldType: this.getViewModelFieldType(config.chartSeries),
    };
  }

  static mapChartConfigToViewModel(config) {
    return {
      chartRows: this.getViewModelFieldNames(config.chartRows),
      chartValues: this.getViewModelFieldNames(config.chartValues),
      aggregationTypes: this.getViewModelAggregationTypes(config.chartValues),
    };
  }

  static getViewModelFieldName(fieldObj) {
    return fieldObj && fieldObj.fieldName ? fieldObj.fieldName : "";
  }

  static getViewModelFieldNames(fieldObjs) {
    return fieldObjs ? fieldObjs.map(this.getViewModelFieldName) : [];
  }

  static getViewModelAggregationType(fieldObj) {
    return fieldObj && fieldObj.aggregationType ? fieldObj.aggregationType : "sum";
  }

  static getViewModelAggregationTypes(fieldObjs) {
    return fieldObjs ? fieldObjs.map(this.getViewModelAggregationType) : [];
  }

  static getViewModelFieldType(fieldObj) {
    return fieldObj && fieldObj.type ? fieldObj.type : "";
  }
}
