const assigneeIsInActiveGroup = function(groups, assigneeId, activeGroupId) {
  if (!+activeGroupId) {
    return true;
  }

  let activeGroup = groups.find(group => group.id === +activeGroupId);
  if (!activeGroup) {
    return true;
  }

  return activeGroup.user_ids.indexOf(assigneeId) > -1;
};

const assigneeIsInActiveGroups = function(groups, assigneeId, activeGroups, projectRole) {
  const containsWorkflowGroup = groups.some(group => {
    return activeGroups.indexOf(group.id) > -1;
  });

  const clientWithSelectedGroup = projectRole === "client" && activeGroups.some(group => +group);

  for (var i = 0; i < activeGroups.length; i++) {
    const groupId = activeGroups[i];
    if (groupId) {
      const group = groups.find(group => group.id === groupId);
      if (group) {
        if (group.user_ids.find(userId => userId === assigneeId)) {
          return true;
        }
      } else if (!containsWorkflowGroup) {
        return true;
      }
      // return whole list unless client has an assigned group in selected records
    } else if (!clientWithSelectedGroup) {
      return true;
    }
  }
  return false;
};

const focusProcessPanelTitle = () => document.querySelector("#process-panel-title").focus();

export { assigneeIsInActiveGroup, assigneeIsInActiveGroups, focusProcessPanelTitle };
