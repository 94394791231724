angular.module("acl.visualizer.treemap.dataConfig").component("aclTreemapDataConfig", {
  bindings: {
    columnDefs: "<",
    dataConfig: "<",
    fieldNameToFieldObj: "<",
    onDataConfigChange: "&",
    onApplyClick: "&",
    removeTab: "&",
    showChartFormatOptions: "&",
    chartFieldFormatProps: "&",
  },
  restrict: "E",
  templateUrl: "visualizer/js/modules/visualization/treemap/treemapDataConfig.tpl.html",
  controllerAs: "treemapConfig",
  controller: function TreemapDataConfig() {
    const treemapConfig = this;

    treemapConfig.viewModel = defaultViewModel();

    treemapConfig.isApplyButtonEnabled = false;

    treemapConfig.updateRows = selectedRows => {
      treemapConfig.viewModel.chartRows = selectedRows;
      onDataConfigChange();
    };

    treemapConfig.$onChanges = changeObj => {
      if ((changeObj.columnDefs || changeObj.dataConfig) && treemapConfig.columnDefs && treemapConfig.dataConfig) {
        treemapConfig.viewModel = dataConfigToViewModel(treemapConfig.dataConfig);
        updateIsApplyButtonEnabled();
      }
    };

    function dataConfigToViewModel(dataConfig) {
      const chartRows = dataConfig.chartRows ? dataConfig.chartRows : [];
      if (chartRows.includes(undefined)) {
        return defaultViewModel();
      }

      const viewModel = defaultViewModel();
      viewModel.chartRows = chartRows;

      const { chartValues } = dataConfig;
      if (chartValues) {
        if (chartValues.length > 0) {
          const sizeAggregation = dataConfig.chartValues[0];
          viewModel.sizeAggregationType = sizeAggregation.aggregationType;
          viewModel.sizeAggregationFieldName = sizeAggregation.fieldName;
        }
        if (chartValues.length > 1) {
          const colorAggregation = dataConfig.chartValues[1];
          viewModel.colorAggregationType = colorAggregation.aggregationType;
          viewModel.colorAggregationFieldName = colorAggregation.fieldName;
        }
      }

      return viewModel;
    }

    function viewModelToDataConfig(viewModel) {
      const dataConfig = {
        aggregationTypes: [viewModel.sizeAggregationType, viewModel.colorAggregationType],
        chartRows: viewModel.chartRows.map(chartRow => chartRow.fieldName),
        chartValues: [
          (viewModel.sizeAggregationType !== "count" && viewModel.sizeAggregationFieldName) || "",
          (viewModel.colorAggregationType !== "count" && viewModel.colorAggregationFieldName) || "",
        ],
      };
      return dataConfig;
    }

    treemapConfig.sizeAggregationSelectorChange = (type, fieldName) => {
      treemapConfig.viewModel.sizeAggregationType = type;
      treemapConfig.viewModel.sizeAggregationFieldName = fieldName;

      handleAggregationTypeMatching(treemapConfig.viewModel.sizeAggregationType);
      updateChartValues();
    };

    treemapConfig.colorAggregationSelectorChange = (type, fieldName) => {
      treemapConfig.viewModel.colorAggregationType = type;
      treemapConfig.viewModel.colorAggregationFieldName = fieldName;

      handleAggregationTypeMatching(treemapConfig.viewModel.colorAggregationType);
      updateChartValues();
    };

    function handleAggregationTypeMatching(newType) {
      // Due to a backend limitation, if size by and color by fields are non-empty and matching
      // then the aggregation types must also match unless the aggregation type is count.

      if (
        treemapConfig.viewModel.colorAggregationType !== "count" &&
        treemapConfig.viewModel.sizeAggregationType !== "count" &&
        treemapConfig.viewModel.sizeAggregationFieldName &&
        treemapConfig.viewModel.sizeAggregationFieldName === treemapConfig.viewModel.colorAggregationFieldName
      ) {
        treemapConfig.viewModel.colorAggregationType = newType;
        treemapConfig.viewModel.sizeAggregationType = newType;
      }
    }

    function updateChartValues() {
      const values = [];
      const aggregationTypes = [];

      if (treemapConfig.viewModel.sizeAggregationType === "count") {
        values.push("");
        aggregationTypes.push("count");
      } else if (treemapConfig.viewModel.sizeAggregationFieldName) {
        values.push(treemapConfig.viewModel.sizeAggregationFieldName);
        aggregationTypes.push(treemapConfig.viewModel.sizeAggregationType);
      }

      // Only add colour by field if there's already a size by field since there's no
      // way to distinguish them for validation in the dataConfig format.
      if (values.length > 0) {
        if (treemapConfig.viewModel.colorAggregationType === "count") {
          values.push("");
          aggregationTypes.push("count");
        } else if (treemapConfig.viewModel.colorAggregationFieldName) {
          values.push(treemapConfig.viewModel.colorAggregationFieldName);
          aggregationTypes.push(treemapConfig.viewModel.colorAggregationType);
        }
      }

      treemapConfig.viewModel.chartValues = values;
      treemapConfig.viewModel.aggregationTypes = aggregationTypes;

      updateIsApplyButtonEnabled();
      onDataConfigChange();
    }

    function updateIsApplyButtonEnabled() {
      treemapConfig.isApplyButtonEnabled = isValidDataConfig(viewModelToDataConfig(treemapConfig.viewModel));
    }

    function isValidDataConfig(dataConfig) {
      if (!dataConfig) return false;

      const rows = dataConfig.chartRows;
      if (!rows) return false;
      if (rows.length < 1 || rows.length > 2) return false;
      if (!isValidDataConfigRow(rows[0])) return false;
      if (rows.length > 1 && !isValidDataConfigRow(rows[1])) return false;

      const values = dataConfig.chartValues;
      const aggregationTypes = dataConfig.aggregationTypes;
      if (!values || !aggregationTypes) return false;
      if (values.length !== 2 || aggregationTypes.length !== 2) return false;
      if (!isValidDataConfigValue(values[0], aggregationTypes[0])) return false;
      if (!isValidOptionalDataConfigValue(values[1], aggregationTypes[1])) return false;
      return true;
    }

    function isValidDataConfigRow(row) {
      return typeof row === "string";
    }

    function isValidDataConfigValue(value, aggregationType) {
      return typeof value === "string" && (aggregationType === "count" || value !== "");
    }

    function isValidOptionalDataConfigValue(value, aggregationType) {
      return typeof value === "string" && typeof aggregationType === "string";
    }

    function onDataConfigChange() {
      treemapConfig.onDataConfigChange({ dataConfig: viewModelToDataConfig(treemapConfig.viewModel) });
    }

    function defaultViewModel() {
      return {
        chartRows: [],
        sizeAggregationType: "sum",
        sizeAggregationField: null,
        colorAggregationType: "sum",
        colorAggregationField: null,
      };
    }
  },
});
