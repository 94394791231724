(() => {
  let aclSummaryTableBoard = {
    restrict: "E",
    replace: true,
    bindings: {
      boardId: "<",
      fontZoomRatio: "<",
      columns: "<",
      formattingOptions: "<",
      presentationMode: "<",
      rawData: "<",
      config: "<",
      displayConfig: "<",
    },
    controllerAs: "summaryTableBoard",
    template: `<div
      id='summary-table-{{summaryTableBoard.boardId}}'
      class='summary-table__board'
      acl-auto-height
      auto-height-match-element='{{summaryTableBoard.autoHeight.matchElement}}'
      auto-height-subtract-elements='summaryTableBoard.autoHeight.subtractElements'>
      <hot-summary-table
        data='summaryTableBoard.data'
        display-config='summaryTableBoard.displayConfig'
        field-format-identifier='summaryTableBoard.boardId'
        font-zoom-ratio="summaryTableBoard.fontZoomRatio"
        is-presentation-mode="summaryTableBoard.presentationMode"
        is-shrink-wrap-enabled='true'
        fixed-columns-left = "summaryTableBoard.fixedColumnsLeftCount"
        outside-click-deselects="summaryTableBoard.presentationMode"></hot-summary-table>
    </div>`,
    controller: SummaryTableBoardController,
  };

  function SummaryTableBoardController($element, $scope, $window, AppConfig, TableAdapter) {
    "ngInject";
    let summaryTableBoard = this;

    const heightOffset = 30;

    summaryTableBoard.data = AppConfig.features.queryService ? undefined : {};

    summaryTableBoard.autoHeight = {
      matchElement: "#storyboard-board-" + summaryTableBoard.boardId,
      subtractElements: ["#storyboard-board-" + summaryTableBoard.boardId + " .visualization-title", heightOffset],
    };

    $scope.$watch(
      () => $element.closest(".storyboard-content").height(),
      newValue => {
        if (newValue !== undefined) {
          $($window).trigger("resize");
        }
      }
    );

    $scope.$watch("summaryTableBoard.rawData", newValue => {
      if (newValue) {
        if (!AppConfig.features.queryService) {
          let metadata = newValue.metaData;
          summaryTableBoard.data = {
            ...summaryTableBoard.data,
            table: TableAdapter.deserializeTable(metadata.id, metadata),
            rawData: newValue,
          };
        } else {
          summaryTableBoard.data = newValue;
        }
      }
    });

    $scope.$watch("summaryTableBoard.formattingOptions", newValue => {
      summaryTableBoard.data.formattingOptions = newValue;
    });

    $scope.$watch("summaryTableBoard.columns", newValue => {
      summaryTableBoard.data.columns = newValue;
    });

    $scope.$watch("summaryTableBoard.config.dataConfig", dataConfig => {
      var { freezeRowHeaders, chartRows } = dataConfig || {};
      summaryTableBoard.fixedColumnsLeftCount = freezeRowHeaders && chartRows ? chartRows.length : 0;
    });
    $scope.$watch("summaryTableBoard.config.displayConfig", displayConfig => {
      summaryTableBoard.displayConfig = displayConfig;
    });
  }

  angular.module("acl.storyboard.board").component("aclSummaryTableBoard", aclSummaryTableBoard);
})();
