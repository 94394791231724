import AppConfig from "@visualizer/appConfig";
import AirbrakeClient from "airbrake-js";

class ErrorReporter {
  instance(appName) {
    if (AppConfig.features.airbrake) {
      const airbrake = new AirbrakeClient(AppConfig.airbrake.credentials);
      const filteredPaths = ["storyboard_links", "questionnaire_responses", "survey_responses"];

      const filteredUrl = (url, filterString) => {
        const regex = new RegExp(`${filterString}/.*`);
        return url.replace(regex, `${filterString}/[filtered]`);
      };

      // This version of airbrake has URLs in context url and history that needs to be scrubbed.
      // Future upgrades may require a further audit of the payload.
      airbrake.addFilter(function(noticeArg) {
        const notice = noticeArg;
        notice.context.application = appName;

        filteredPaths.forEach(filter => {
          const errorUrl = notice.context.url;
          notice.context.url = filteredUrl(errorUrl, filter);

          notice.context.history
            .filter(history => history.url)
            .forEach(historyArg => {
              const history = historyArg;
              const historyUrl = history.url;
              history.url = filteredUrl(historyUrl, filter);
            });
        });

        return notice;
      });

      return (exception, cause) => {
        console.error(exception);
        airbrake.notify({ error: exception, params: { angular_cause: cause } });
      };
    }

    //unwrap console methods that Airbrake wraps
    ["debug", "log", "info", "warn", "error"].forEach(method => {
      if (method in console && console[method].inner) {
        console[method] = console[method].inner;
      }
    });

    return exception => {
      console.error(exception);
    };
  }
}

export default new ErrorReporter();
