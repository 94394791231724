import ReactDirective from "acl-ui/angular/reactDirective.umd";
import MetricIndicator from "@acl-services/sriracha-metrics/dist/Metrics";
import Indicator from "./indicator.directive";

import "@acl-services/sriracha-metrics/dist/Metrics.scss";

angular
  .module("acl.visualizer.indicator", ["acl.visualizer.highCharts"])
  .directive("aclIndicator", Indicator)
  .directive("aclMetricIndicator", ReactDirective.getFactory(MetricIndicator));
