import PubSub from "pubsub-js";

export default class EventServiceRegistrant {
  constructor(registrant, scope, invokeListenersCallback) {
    this.registrant = registrant;
    this.scope = scope;
    this.invokeListenersCallback = invokeListenersCallback;

    this.subscriberTokens = [];
  }

  subscribe(subscribeEvent, callBack) {
    const that = this;
    const token = PubSub.subscribe(subscribeEvent, function(event, data) {
      const args = [event].concat(data);
      that.invokeListenersCallback(...["onReceive", that.subscriber].concat(args));

      if (that.scope) {
        that.scope.$apply(function() {
          callBack(...args);
        });
      } else {
        callBack(...args);
      }
    });

    this.subscriberTokens.push(token);

    return () => {
      PubSub.unsubscribe(token);
    };
  }

  publish(event) {
    const args = Array.prototype.slice.call(arguments);
    this.invokeListenersCallback(...["onPublish", this.registrant].concat(args));
    const data = args.slice(1);

    PubSub.publish(event, data);
  }

  request(event, timeout) {
    const that = this;
    let resolver;
    let rejecter;
    const args = Array.prototype.slice.call(arguments, 2);
    this.invokeListenersCallback(...["onRequest", this.registrant].concat(arguments));
    const promise = new Promise((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    });

    if (timeout !== undefined && timeout !== null && timeout > 0) {
      const timeoutPromise = setTimeout(function() {
        that.invokeListenersCallback(...["onRequestTimeout", that.subscriber].concat([event, timeout]));
        rejecter("timeout");
      }, timeout);

      const cancelTimeout = function() {
        clearTimeout(timeoutPromise);
      };
      promise.then(cancelTimeout, cancelTimeout);
    }

    this.publish(...[event, { promise, resolve: resolver, reject: rejecter }].concat(args));
    return promise;
  }

  unregister() {
    this.subscriberTokens.forEach(function(token) {
      PubSub.unsubscribe(token);
    });
    this.subscriberTokens = [];
  }
}
