import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import StoryboardFilter from "@viz-ui/models/storyboardFilter/storyboardFilter";
import filterTypes from "@viz-ui/models/storyboardFilter/storyboardFilterTypes";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";
import FilterWidget from "@viz-ui/components/stbGlobalFilter/filterWidget/filterWidget";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import globalFiltersStore from "../services/GlobalFiltersStore";
import Singleselect from "./Singleselect";
import Multiselect from "./Multiselect";
import Conditional from "./Conditional";
import FilterValue from "@viz-ui/models/storyboardFilter/filterValue";
import DynamicFilter from "../services/dynamicFilter";

export default class Filter extends Component {
  static propTypes = {
    canValidate: PropTypes.bool,
    filter: PropTypes.instanceOf(StoryboardFilter).isRequired,
    flipConditional: PropTypes.bool.isRequired,
    flipMultiselect: PropTypes.bool.isRequired,
    flipRelativeFilters: PropTypes.bool.isRequired,
    flipQueryService: PropTypes.bool.isRequired,
    isEditDisabled: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    mode: PropTypes.oneOf(["editMode", "viewMode"]).isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onConfirmDelete: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    flipDynamicFilter: PropTypes.bool.isRequired,
    forceRerender: PropTypes.bool.isRequired,
    resetForceReRenderFlag: PropTypes.func.isRequired,
    clearAllFilter: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    canValidate: false,
    isEditDisabled: false,
    isHighlighted: false,
    forceRerender: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: this.props.filter,
    };
    this.fieldFormatIdentifier = this.props.filter.id();
    this.updateGlobalFilterFieldName(this.props.filter);
    this.updateGlobalFormatter(this.props.filter);
    this.prevValues = this._getInitPreviousFilterValues(this.props.filter);
    if (
      this.props.flipDynamicFilter &&
      this.props.filter &&
      !this.props.filter.isFrozenFilter() &&
      !this.props.filter.isCurrentSessionFilter()
    ) {
      DynamicFilter.setFrozenFilteronLoad(this.props.filter);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ filter: nextProps.filter });

    const filterFieldsChanged = !isEqual(this.props.filter.filterFields(), nextProps.filter.filterFields());
    const fieldFormatChanged = !isEqual(this.props.filter.fieldFormat(), nextProps.filter.fieldFormat());
    if (filterFieldsChanged) {
      this.updateGlobalFilterFieldName(nextProps.filter);
    }
    if (filterFieldsChanged || fieldFormatChanged) {
      this.updateGlobalFormatter(nextProps.filter);
    }
  }

  updateGlobalFilterFieldName(filter) {
    this.globalFilterFieldName = this.getGlobalFilterFieldName(filter);
  }

  updateGlobalFormatter(filter) {
    GlobalFieldFormatMap.setFieldFormat(this.fieldFormatIdentifier, this.globalFilterFieldName, filter.fieldFormat());
    GlobalFieldFormatMap.setFieldType(this.fieldFormatIdentifier, this.globalFilterFieldName, filter.fieldType());
  }

  getGlobalFilterFieldName(filter) {
    return filter
      .filterFields()
      .map(field => field.name())
      .join("-");
  }

  handleEditClick = () => {
    this.props.onClickEdit(this.state.filter);
  };

  handleToggleActive = () => {
    this.handleFilterChange("isActive", !this.state.filter.isActive());
    UsageTracker.createEvent(this.state.filter.isActive() ? ".filterpanel.enabled" : ".filterpanel.disabled");
  };

  _getInitPreviousFilterValues = filter => {
    if (filter.operator() === "relative") {
      return [new FilterValue().value(""), new FilterValue().value(""), new FilterValue().value("")];
    } else {
      return filter.values();
    }
  };

  _getDefaultFilterValues = operator => {
    if (operator === "relative") {
      return [new FilterValue().value("current"), new FilterValue().value(""), new FilterValue().value("day")];
    } else {
      return [new FilterValue().value(""), new FilterValue().value(""), new FilterValue().value("")];
    }
  };

  resetValues(operator, filter) {
    if (operator === "relative") {
      this.prevValues = filter.values();
      filter.values(this._getDefaultFilterValues(operator));
      filter.excludeToday(false);
    } else {
      filter.values(this.prevValues);
    }
  }

  handleFilterChange = (prop, value) => {
    let newFilter = this.state.filter.clone();

    newFilter[prop](value);
    if (prop === "operator") {
      this.resetValues(value, newFilter);
    }
    if (
      this.props.flipDynamicFilter &&
      newFilter.type() === filterTypes.CONDITIONAL &&
      DynamicFilter.isValidForConditionalFilterQuery(newFilter) //check blank value for conditional filter
    ) {
      DynamicFilter.setInitialDynamicFilter(newFilter);
    } else if (this.props.flipDynamicFilter && !(newFilter.value() === "" || newFilter.value() === null)) {
      DynamicFilter.setInitialDynamicFilter(newFilter);
    }

    //Setting current session filter to true on change
    if (this.props.flipDynamicFilter) {
      newFilter.isCurrentSessionFilter(true);
    }

    this.setState({ filter: newFilter });
    globalFiltersStore.update(newFilter);
    this.props.onFilterChange();
  };

  handleMultiSelectFilterChange = (selectedValues, unSelectedValues, selectAllState, clickedItemAction) => {
    let newFilter = this.state.filter.clone();
    if (
      this.props.flipDynamicFilter &&
      (newFilter.values() === null || (newFilter.values() && newFilter.values().length === 0))
    ) {
      DynamicFilter.setInitialDynamicFilter(newFilter);
    }
    newFilter["values"](selectedValues);
    newFilter["unSelectedValues"](unSelectedValues);
    newFilter["selectAllState"](selectAllState);
    //check if the filter is frozen and the click action is remove item then set the isCurrentEditedDynamicFilter to true
    if (this.props.flipDynamicFilter && newFilter.isFrozenFilter() && clickedItemAction === "remove") {
      newFilter.isCurrentEditedDynamicFilter(true);
    }
    //Setting current session filter to true on change
    if (this.props.flipDynamicFilter) {
      newFilter.isCurrentSessionFilter(true);
    }

    this.setState({ filter: newFilter });
    globalFiltersStore.update(newFilter);
    this.props.onFilterChange();
  };

  handleDeleteClick = () => {
    globalFiltersStore.delete(this.state.filter);
    this.props.onConfirmDelete(this.state.filter);
    UsageTracker.createEvent(".filterpanel.delete.confirmed");
  };

  hasOverflow = () => this.props.mode === "editMode";

  renderSingleselect = isOptional => (
    <Singleselect
      fieldFormatIdentifier={this.fieldFormatIdentifier}
      fieldName={this.globalFilterFieldName}
      filter={this.state.filter}
      flipQueryService={this.props.flipQueryService}
      isDisabled={!this.state.filter.isActive()}
      isMandatory={!isOptional}
      onFilterChange={this.handleFilterChange}
      forceRerender={this.props.forceRerender}
      clearAllFilter={this.props.clearAllFilter} // passing clear all filter to rerender while clearing
      flipDynamicFilter={this.props.flipDynamicFilter} //  passing dynamic filter flipper to the child component
      resetForceReRenderFlag={this.props.resetForceReRenderFlag}
    />
  );

  renderMultiSelect = () => {
    if (!this.props.flipMultiselect) return null;
    return (
      <Multiselect
        fieldFormatIdentifier={this.fieldFormatIdentifier}
        fieldName={this.globalFilterFieldName}
        filter={this.state.filter}
        flipQueryService={this.props.flipQueryService}
        isDisabled={!this.state.filter.isActive()}
        onFilterChange={this.handleMultiSelectFilterChange}
        forceRerender={this.props.forceRerender}
        clearAllFilter={this.props.clearAllFilter} // passing clear all filter to rerender while clearing
        resetForceReRenderFlag={this.props.resetForceReRenderFlag}
        flipDynamicFilter={this.props.flipDynamicFilter} // passing dynamic filter flipper to the child component
      />
    );
  };

  renderConditional = () => {
    if (!this.props.flipConditional) return null;
    return (
      <Conditional
        canValidate={this.props.canValidate}
        fieldFormatIdentifier={this.fieldFormatIdentifier}
        flipRelativeFilters={this.props.flipRelativeFilters}
        fieldName={this.globalFilterFieldName}
        filter={this.state.filter}
        isDisabled={!this.state.filter.isActive()}
        onFilterChange={this.handleFilterChange}
      />
    );
  };

  renderFilter = () => {
    switch (this.state.filter.type()) {
      case filterTypes.OPTIONAL_SINGLESELECT:
        return this.renderSingleselect(true);
      case filterTypes.MANDATORY_SINGLESELECT:
        return this.renderSingleselect(false);
      case filterTypes.MULTISELECT:
        return this.renderMultiSelect();
      case filterTypes.CONDITIONAL:
        return this.renderConditional();
      default:
        return null;
    }
  };

  render() {
    return (
      <FilterWidget
        displayName={this.state.filter.displayName()}
        hasOverflow={this.hasOverflow()}
        isActive={this.state.filter.isActive()}
        isEditDisabled={this.props.isEditDisabled}
        isHighlighted={this.props.isHighlighted}
        onActiveClick={this.handleToggleActive}
        onDeleteClick={this.handleDeleteClick}
        onEditClick={this.handleEditClick}
      >
        {this.renderFilter()}
      </FilterWidget>
    );
  }
}
