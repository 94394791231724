import BoardDataLoader from "./boardDataLoader.service";
import MetricDataLoader from "./metricDataLoader.service";
import InterpretationDataLoader from "./interpretationDataLoader.service";
import InterpretationTableDataLoader from "./interpretationTableDataLoader.service";
import VisualizationDataLoader from "./visualizationDataLoader.service";

angular
  .module("acl.storyboard.dataLoader", [
    "acl.common.config",
    "acl.common.localize",
    "acl.storyboard.dataStore",
    "acl.storyboard.visualization",
    "acl.storyboard.interpretation",
    "acl.visualizer.model.interpretation",
    "acl.visualizer.model.metric",
    "acl.visualizer.model.table",
  ])
  .service("BoardDataLoader", BoardDataLoader)
  .service("InterpretationDataLoader", InterpretationDataLoader)
  .service("InterpretationTableDataLoader", InterpretationTableDataLoader)
  .service("MetricDataLoader", MetricDataLoader)
  .service("VisualizationDataLoader", VisualizationDataLoader);
