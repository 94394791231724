import QueryClient from "@viz-ui/services/query/queryClient/queryClient";
import QueryGenerator from "@viz-ui/services/query/queryGenerator/queryGenerator";
import QueryErrorInterpreter from "@viz-ui/services/query/queryErrorInterpreter/queryErrorInterpreter.service";
import MetricDataAdapter from "@viz-ui/services/query/queryAdapters/metricDataAdapter";
import TableDataAdapter from "@viz-ui/services/query/queryAdapters/tableDataAdapter";

export default (DataFilter, MetricSparkline, TableAdapter) => {
  "ngInject";

  class MetricQueryBackendService {
    getMetricSparklineData(metric, timezoneOffset, tableId, globalFilters, scope) {
      let metricObj = metric.toJson();
      let metricConfig = metric.metricConfig();
      let filterObj = DataFilter.getFilterRequestBody(metricObj.filter_config);
      let { filterList } = filterObj;
      let queryObj = QueryGenerator.metricTimeline({
        tableId,
        filterList,
        metricConfig,
        globalFilters,
        timezoneOffset,
        scope,
      });

      return new Promise((resolve, reject) => {
        QueryClient.query(queryObj)
          .then(response => {
            const responseData = response.data;
            const metadata = TableDataAdapter.formatMetadata(tableId, responseData);
            const table = TableAdapter.deserializeTable(tableId, metadata);
            const measureField = table.fieldByName(metricConfig.fieldName());
            const parsedTimelineData = MetricDataAdapter.formatTimelineData({
              fieldType: measureField ? measureField.type() : undefined,
              period: `${metricConfig.interval()}(${metricConfig.timeFieldName()})`,
              aggFn: metricConfig.func(),
              data: responseData.table,
            });
            const metricSparkline = MetricSparkline.fromJson(parsedTimelineData);
            resolve(metricSparkline);
          })
          .catch(response => {
            const errorCode = QueryErrorInterpreter.errorCode(response);
            reject(errorCode);
          });
      });
    }
  }

  return new MetricQueryBackendService();
};
