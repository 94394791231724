angular.module("acl.visualizer.dataTable").component("aclDataTableRecordCount", {
  restrict: "E",
  replace: true,
  templateUrl: "visualizer/js/modules/core/dataTableRecordCount/dataTableRecordCount.tpl.html",
  controller: function DataTableRecordCount($scope, Localize) {
    var recordCount = this;

    recordCount.displayString = "";

    $scope.$watchGroup(["recordCount.filteredCount", "recordCount.totalCount"], function(newValues) {
      if (newValues && newValues.length && newValues[0] >= 0 && newValues[1] >= 0) {
        updateLocalizedRecordCountString(newValues[0], newValues[1]);
      }
    });

    function updateLocalizedRecordCountString(filteredCount, totalCount) {
      if (filteredCount === null || isNaN(filteredCount) || filteredCount === totalCount) {
        recordCount.displayString = Localize.getLocalizedStringWithTokenReplacement("_Record.All.Count.Label_", {
          total: totalCount,
        });
      } else {
        recordCount.displayString = Localize.getLocalizedStringWithTokenReplacement("_Record.Filtered.Count.Label_", {
          filtered: filteredCount,
          total: totalCount,
        });
      }
    }
  },
  controllerAs: "recordCount",
  bindings: {
    filteredCount: "<",
    totalCount: "<",
  },
});
