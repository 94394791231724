import FilterFieldAdapter from "../../../models/storyboardFilter/filterFieldAdapter";

export default {
  getTableCacheKey,
  getTableCacheKeys,
  serializeTableCacheKey,
};

function getTableCacheKeys(filterFields, searchString) {
  return filterFields.map(filterField => {
    return getTableCacheKey(filterField, searchString);
  });
}

function getTableCacheKey(filterField, searchString) {
  return JSON.stringify({ ...serializeFilterField(filterField), ...{ searchString } });
}

function serializeTableCacheKey(tableCacheKey) {
  return JSON.parse(tableCacheKey);
}

function serializeFilterField(filterField) {
  let { name: fieldName, table_id: tableId } = FilterFieldAdapter.serializeFilterField(filterField);
  return { tableId, fieldName };
}
