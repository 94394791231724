const DEFAULT_THRESHOLD = 150;

export const AREA_CHART = DEFAULT_THRESHOLD;
export const BAR_CHART = DEFAULT_THRESHOLD;
export const BUBBLE_CHART = DEFAULT_THRESHOLD;
export const COMBINATION_CHART = DEFAULT_THRESHOLD;
export const HEAT_MAP_CHART = DEFAULT_THRESHOLD;
export const HISTOGRAM_CHART = DEFAULT_THRESHOLD;
export const LINE_CHART = DEFAULT_THRESHOLD;
export const PIE_CHART = DEFAULT_THRESHOLD;
export const TREE_CHART = DEFAULT_THRESHOLD;
export const MAP_CHART = DEFAULT_THRESHOLD;
