import find from "lodash/find";

angular.module("acl.visualizer.promiseCache").factory("PromiseCache", function($q) {
  var promises = [];

  return {
    getPromise: getPromise,
  };

  function getPromise(fn, promiseIdentifier) {
    var promise;

    promise = getPendingPromise(promiseIdentifier);
    if (promise) {
      return promise;
    }

    promise = fn()
      .then(function(data) {
        removePromise(promiseIdentifier);
        return data;
      })
      .catch(function(error) {
        removePromise(promiseIdentifier);
        return $q.reject(error);
      });

    addPromise(promiseIdentifier, promise);
    return promise;
  }

  function addPromise(promiseIdentifier, promise) {
    promises.push({
      promiseIdentifier: promiseIdentifier,
      promise: promise,
    });
  }

  function getPendingPromise(promiseIdentifier) {
    let promiseObj = find(promises, promise => angular.equals(promise.promiseIdentifier, promiseIdentifier));

    return promiseObj && promiseObj.promise;
  }

  function removePromise(promiseIdentifier) {
    promises = promises.filter(function(promise) {
      return !angular.equals(promise.promiseIdentifier, promiseIdentifier);
    });
  }
});
