import PropTypes from "prop-types";
import React from "react";
import filter from "lodash/filter";
import services from "../../../services/glue/appGlue";

class StoryboardShareUserSelector extends React.Component {
  constructor(props) {
    super(props);

    this.handleUsersSelected = this.handleUsersSelected.bind(this);
  }

  handleUsersSelected(users, groups) {
    this.props.onUpdateAssignment(users, groups);
  }

  getSelectedUser() {
    //use window object as because of pagination assignment value is based on paginated data
    if (!window.assignments.userAssignments) return [];
    return filter(window.assignments.userAssignments, function(assignment) {
      return assignment.userId;
    }).map(function(assignment) {
      return assignment.userId;
    });
  }

  getSelectedGroup() {
    if (!window.assignments.groupAssignments) return [];
    return filter(window.assignments.groupAssignments, function(assignment) {
      return assignment.groupId;
    }).map(function(assignment) {
      return assignment.groupId;
    });
  }

  render() {
    return (
      <div className="share-user-selector">
        <div className="share-user-selector__header">
          <i className="acl-i-user" />{" "}
          {services.Localize.getLocalizedString("_Storyboards.SharePanel.ManageUsers.Header_")}
        </div>
        <UserSelector
          users={this.props.accountUsers}
          groups={this.props.accountGroups}
          selectedUsers={this.getSelectedUser()}
          selectedGroups={this.getSelectedGroup()}
          onUsersSelected={this.handleUsersSelected}
          onCancel={this.props.onCancel}
        />
      </div>
    );
  }
}

StoryboardShareUserSelector.propTypes = {
  onUpdateAssignment: PropTypes.func,
  onCancel: PropTypes.func,
  assignments: PropTypes.array,
  accountUsers: PropTypes.array,
  accountGroups: PropTypes.array,
};

window.StoryboardShareUserSelector = StoryboardShareUserSelector;
