import values from "lodash/values";
import StoryBoardDrilldownService from "@viz-ui/services/storyboardDrilldown/storyboardDrilldownService";
import backendApi from "@results/services/apiCall/backendApi";
import ApiPath from "@viz-ui/services/apiPath/apiPathService";

/* jshint loopfunc:true */
angular
  .module("acl.visualizer.saveViz")
  .factory("SaveViz", function($q, $state, AppConfig, DataModel, EventService, Localize, ChartService) {
    var initializing = false;
    var sourceTableExist = true;
    var storyboardDrilldownFiltersExist = false;

    var eventService = EventService.register("saveViz.module.SaveViz");

    function setClean() {
      if (!initializing) {
        eventService.publish("saveViz.vizClean");
      }
    }

    function updateViz() {
      return service.updateViz().then(
        function() {
          eventService.publish("saveViz.updateSuccess");
          setClean();
        },
        function(data) {
          eventService.publish("saveViz.updateFailed", {
            title: DataModel.interpretation.title(),
            errorMessage: Localize.getLocalizedString(
              "_SaveViz.Update.Failed." + data.data.error,
              "_SaveViz.Update.Failed.Message_"
            ),
          });
        }
      );
    }

    function getInvalidVisualizationIndex() {
      const visualizations = DataModel.visualizations();
      if (visualizations) {
        return visualizations.findIndex(d => !isValidVisualization(d));
      }
      return -1;
    }

    function isValidVisualization(visualization) {
      return (
        visualization.config &&
        visualization.config.dataConfig &&
        !angular.equals(visualization.config.dataConfig, {}) &&
        ChartService.dataConfigFieldsExist(visualization.config.dataConfig)
      );
    }

    // handlers for events
    DataModel.subscribe("interpretation.summary", setDirty);
    // FIXME: this event should be removed once filter panel and quick menu are no longer changing reference of filterConfig in DataModel directly.
    eventService.subscribe("filterPanel.filterChange", setDirty);
    eventService.subscribe("filterPanel.filtersChanged", setDirty);
    eventService.subscribe("saveViz.modelChange", setDirty);

    function setDirty() {
      if (!isPublic() && (!sourceTableExist || !initializing || storyboardDrilldownFiltersExist)) {
        eventService.publish("saveViz.vizDirty");
      }
    }

    function isPublic() {
      return $state.current.name === "public";
    }

    eventService.subscribe("shareToGRC.interpretationShared", function(event, grcInfo) {
      DataModel.interpretation.grcInfo(grcInfo);
      updateViz();
    });

    var service = {
      setSavedVizId: function(savedVizId) {
        this.savedVizId = savedVizId;
      },
      loadSavedViz: function(savedVizId, analyticName, storyboardDrilldownStorageKey) {
        // construct api url
        const url = ApiPath.saveViz.getRetrieveSavedVizUrl({ savedVizId: savedVizId, analyticName: analyticName });
        service.setSavedVizId(savedVizId);

        const promise = new Promise((resolve, reject) => {
          const deferred = {
            resolve: resolve,
            reject: reject,
          };

          backendApi.get(url).then(
            response => handleLoadVizResolve(deferred, response, storyboardDrilldownStorageKey),
            response => handleLoadVizReject(deferred, response)
          );
        });

        return promise;
      },

      retrieveSavedVizList: function(analyticName) {
        var url = ApiPath.saveViz.getSaveVizListUrl({ tableId: null, analyticName: analyticName });
        return backendApi.get(url);
      },

      isCompatibleWithTable: function(tableMetadata) {
        const fields = values(tableMetadata.fields);

        if (this.savedVizId === undefined) return false;

        var filterConfig = DataModel.getFilterConfigDeprecated();
        if (filterConfig) {
          if (
            filterConfig.sortField &&
            filterConfig.sortField.field &&
            !fields.some(field => {
              return field.fieldName === filterConfig.sortField.field;
            })
          ) {
            return false;
          }

          if (filterConfig.filterList) {
            for (var iField = 0; iField < filterConfig.filterList.length; iField++) {
              var filter = filterConfig.filterList[iField];
              if (
                !fields.some(field => {
                  const fieldNameMatch = field.fieldName === filter.name;
                  const fieldTypeMatch = field.type === filter.type;
                  return fieldNameMatch && fieldTypeMatch;
                })
              ) {
                return false;
              }
            }
          }
        }

        var visualizations = DataModel.visualizations();
        if (visualizations) {
          for (var iViz = 0; iViz < visualizations.length; iViz++) {
            if (visualizations[iViz].type) {
              if (visualizations[iViz].config && visualizations[iViz].config.dataConfig) {
                var vizConfig = visualizations[iViz].config.dataConfig;
                for (var configProp in vizConfig) {
                  var configFields = angular.isArray(vizConfig[configProp])
                    ? vizConfig[configProp]
                    : [vizConfig[configProp]];
                  // TODO: Factor out functions.
                  if (
                    configFields.some(configField => {
                      return (
                        configField.fieldName &&
                        !fields.some(field => {
                          const fieldNameMatch = field.fieldName === configField.fieldName;
                          const fieldTypeMatch = field.type === configField.type;
                          return fieldNameMatch && fieldTypeMatch;
                        })
                      );
                    })
                  ) {
                    return false;
                  }
                }
              }
            } else {
              return false;
            }
          }
        }

        return true;
      },

      saveViz: function(saveVizObj) {
        if (!arguments.length) {
          saveVizObj = DataModel.toSaveViz();
        }
        saveVizObj = formatDataConfig(saveVizObj);
        var url = ApiPath.saveViz.getSaveVizUrl(DataModel.table.id(), saveVizObj);
        return backendApi.post(url, {}, saveVizObj);
      },

      deleteViz: function(savedVizId, analyticName) {
        var url = ApiPath.saveViz.getDeleteVizUrl({ savedVizId: savedVizId, analyticName: analyticName });

        return backendApi.delete(url);
      },

      updateViz: function() {
        var url = ApiPath.saveViz.getUpdateSavedVizUrl({
          savedVizId: this.savedVizId,
          analyticName: DataModel.table.analyticName(),
        });
        const saveVizObj = formatDataConfig(DataModel.toSaveViz());
        return backendApi.put(url, {}, saveVizObj);
      },

      compatibleTables: function(tableName, analyticName) {
        if (!AppConfig.features.saveViz.linkMostRecentTable) {
          throw new Error("Link to Most Recent Table feature is disabled in your app.");
        }

        var url = ApiPath.saveViz.getCompatibleTablesUrl(tableName, analyticName, 100);

        return backendApi.get(url);
      },

      canLinkMostRecentTableOnSave: function() {
        return AppConfig.features.saveViz.linkMostRecentTable && $state.params.analyticName;
      },

      saveInterpretation: saveInterpretation,
      saveInterpretationAs: saveInterpretationAs,
      setInitializing: setInitializing,
      setSourceTableExist: setSourceTableExist,

      loadDefaultViz: () => {
        const url = ApiPath.saveViz.getRetrieveDefaultVizUrl();
        const deferred = $q.defer();

        backendApi.get(url).then(
          response => {
            // null interpretation means the default interpretation has not
            // been saved before
            if (response.data.interpretation === null) {
              setArchived(response);
              deferred.reject({ errorCode: 404, hide_metadata: response.data.hide_metadata || false });
            } else {
              // define savedVizId thus indicating updates are updates and
              // not creating a new one.
              service.setSavedVizId("default");
              handleLoadVizResolve(deferred, response);
            }
          },
          response => handleLoadVizReject(deferred, response)
        );

        return deferred.promise;
      },

      updateDefaultViz: () => {
        const invalidVisualizationIndex = getInvalidVisualizationIndex();
        if (invalidVisualizationIndex >= 0) {
          return $q.reject({ invalidVisualizationIndex });
        }
        const url = ApiPath.saveViz.getUpdateDefaultVizUrl();

        return backendApi.put(url, {}, formatDataConfig(DataModel.toSaveViz())).then(
          function() {
            eventService.publish("saveViz.updateSuccess");
            setClean();
          },
          function(data) {
            eventService.publish("saveViz.updateFailed", {
              title: DataModel.interpretation.title(),
              errorMessage: Localize.getLocalizedString(
                "_SaveViz.Update.Failed." + data.data.error,
                "_SaveViz.Update.Failed.Message_"
              ),
            });
          }
        );
      },

      saveDefaultViz: () => {
        const invalidVisualizationIndex = getInvalidVisualizationIndex();
        if (invalidVisualizationIndex >= 0) {
          return $q.reject({ invalidVisualizationIndex });
        }
        const url = ApiPath.saveViz.getSaveDefaultVizUrl();
        let vizToSave = DataModel.toSaveViz();
        vizToSave = formatDataConfig(vizToSave);

        return backendApi.post(url, {}, vizToSave).then(
          () => {
            DataModel.fromSaveViz(vizToSave);
            setClean();

            eventService.publish("saveViz.saveDefaultSuccess");
          },
          response => {
            eventService.publish("saveViz.saveFailed", {}, response.data.error);
          }
        );
      },
    };

    function saveInterpretation() {
      const invalidVisualizationIndex = getInvalidVisualizationIndex();
      if (invalidVisualizationIndex >= 0) {
        return $q.reject({ invalidVisualizationIndex });
      }

      return updateViz();
    }

    function saveInterpretationAs(saveVizOptions) {
      const invalidVisualizationIndex = getInvalidVisualizationIndex();
      if (invalidVisualizationIndex >= 0) {
        return $q.reject({ invalidVisualizationIndex });
      }

      var vizToSave = DataModel.toSaveViz();

      if (saveVizOptions && saveVizOptions.title) {
        vizToSave.visualizationInfo.title = saveVizOptions.title;
      }

      if (saveVizOptions && saveVizOptions.summary !== DataModel.interpretation.summary()) {
        DataModel.interpretation.summary(saveVizOptions.summary);
      }
      vizToSave.visualizationInfo.summary = saveVizOptions.summary;

      if (saveVizOptions && saveVizOptions.linkLatestTable) {
        vizToSave.visualizationInfo.linkLatestTable = saveVizOptions.linkLatestTable;
      }

      if (vizToSave.visualizationInfo.grcInfo) {
        delete vizToSave.visualizationInfo.grcInfo;
      }

      return service.saveViz(vizToSave).then(
        function(response) {
          DataModel.fromSaveViz(vizToSave);
          setClean();

          eventService.publish("saveViz.saveSuccess", {
            interpretationId: response.data.visualizationInterpretationId,
            title: vizToSave.title,
          });
        },
        function(response) {
          eventService.publish("saveViz.saveFailed", { title: saveVizOptions.title }, response.data.error);
        }
      );
    }

    function setInitializing(value) {
      initializing = value;
      return service;
    }

    function setSourceTableExist(value) {
      sourceTableExist = value;
      return service;
    }

    function setStoryboardDrilldownFiltersExist(value) {
      storyboardDrilldownFiltersExist = value;
    }

    function setArchived(response) {
      if (response.data.project && response.data.project.archived) {
        DataModel.project.archived(response.data.project.archived);
      }
    }

    async function handleLoadVizResolve(deferred, response, storyboardDrilldownStorageKey) {
      if (response.data && response.data.interpretation) {
        if (storyboardDrilldownStorageKey) {
          await StoryBoardDrilldownService.processDrilldownFiltersAsync(
            response.data.interpretation.filterConfig.filterList,
            storyboardDrilldownStorageKey
          ).then(data => {
            if (data.storyboardFiltersExists) {
              response.data.interpretation.filterConfig.filterList = data.updatedVizFilters;
              setStoryboardDrilldownFiltersExist(true);
            }
          });
        }

        DataModel.fromSaveViz(response.data.interpretation);
        eventService.publish("dataTable.formattingChange");

        setArchived(response);

        var successMessage = {
          type: "success",
          header: DataModel.interpretation.title(),
          content: Localize.getLocalizedString("_Saved.Viz.Loaded.Success.Message_"),
        };

        if (DataModel.table.analyticName() && DataModel.table.tableName()) {
          deferred.resolve(successMessage);
        } else if (DataModel.interpretation.linkLatestTable() && response.data.tableId) {
          DataModel.table.tableId(response.data.tableId);
          deferred.resolve(successMessage);
        } else if (!DataModel.interpretation.linkLatestTable()) {
          deferred.resolve(successMessage);
        } else if (AppConfig.application.name === "HighBond") {
          //FIXME need appropriate analog in GRC for tableId
          DataModel.table.tableId("undefined in results");
          deferred.resolve(successMessage);
        } else {
          deferred.reject({
            type: "error",
            header: DataModel.interpretation.title(),
            content: Localize.getLocalizedString("_Saved.Viz.TableId.NotFound.Message_"),
            errorCode: "table.not.exist",
          });
        }
      } else {
        deferred.reject({
          type: "error",
          content: Localize.getLocalizedString("_Invalid.Saved.Viz.Message_"),
        });
      }
    }

    function handleLoadVizReject(deferred, response) {
      const errorCode = response.data.error;
      let errorMessageKey;
      let errorMessage;
      if (errorCode) {
        errorMessageKey = "_Saved.Viz.Loaded." + errorCode + ".Message_";
      }
      errorMessage = Localize.getLocalizedString(errorMessageKey, "_Saved.Viz.Loaded.Failed.Message_");
      deferred.reject({
        type: "error",
        content: errorMessage,
      });
    }

    function updateColorStop(displayConfig) {
      if (displayConfig.colorAxis.colorStops) {
        if (displayConfig.colorStopType === "3stops") {
          resetColorStop(displayConfig.colorAxis.colorStops.colorStops5);
        } else if (displayConfig.colorStopType === "5stops") {
          resetColorStop(displayConfig.colorAxis.colorStops.colorStops3);
        } else {
          resetColorStop(displayConfig.colorAxis.colorStops.colorStops3);
          resetColorStop(displayConfig.colorAxis.colorStops.colorStops5);
        }
      }
    }

    function resetColorStop(colorStops) {
      if (colorStops) {
        colorStops.forEach(colorStop => {
          delete colorStop.value;
        });
      }
    }

    function formatDataConfig(saveViz) {
      const { visualizations } = saveViz;
      if (!visualizations) return saveViz;

      visualizations.forEach(({ vizType, config: { dataConfig } }, index) => {
        if (vizType === "CombinationChart") {
          saveViz.visualizations[index].config.dataConfig.chartRows = [dataConfig.chartXAxis];
          delete saveViz.visualizations[index].config.dataConfig.chartXAxis;
          saveViz.visualizations[index].config.dataConfig.chartColumns = dataConfig.chartSeries
            ? [dataConfig.chartSeries]
            : [];
          delete saveViz.visualizations[index].config.dataConfig.chartSeries;
        } else if (vizType === "MapChart") {
          updateColorStop(saveViz.visualizations[index].config.displayConfig);
        }
      });
      return saveViz;
    }

    return service;
  });
