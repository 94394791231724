import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";

angular.module("acl.visualizer.dataTableConfig").directive("aclDataTableConfig", () => {
  function DataTableConfigCtrl($scope, $timeout, AppConfig) {
    var self = this;

    init();

    function init() {
      let fieldsByName = new Map();
      let timeoutPromise;
      self.highlightLabel = null;

      self.columnConfigs = [];
      self.columnConfigsChanged = [];
      self.showTheList = false;
      self.showSearchBox = true;
      self.columnConfigsFormatted = [];
      self.columnConfigsFormattedOriginal = [];
      self.searchTerm = "";
      self.configureTableViewRevamp = AppConfig.features.configureTableViewRevamp;

      function updateViewModelFromScope() {
        if (self.configureTableViewRevamp) {
          self.columnConfigs = angular.copy($scope.columnConfigs);
          self.columnConfigsChanged = angular.copy($scope.columnConfigs);
          self.columnConfigsFormatted = [];
          self.columnConfigsFormattedOriginal = [];
          let columnConfigsUnformatted = angular.copy($scope.columnConfigs);

          if (fieldsByName.size) {
            columnConfigsUnformatted.forEach((element, index) => {
              let configObject = {};
              configObject.label = $scope.getDisplayNameByFieldName(element.name());
              configObject.type = $scope.getDataTypeByFieldName(element.name());
              configObject.checked = element.visible();
              configObject.wordWrap = element.wordWrap();
              configObject.isDragDisabled = false;
              configObject.highLight = false;
              configObject.originalIndex = index;

              self.columnConfigsFormattedOriginal.push(configObject);
            });

            self.columnConfigsFormatted = filterFields(self.columnConfigsFormattedOriginal, self.searchTerm);
          }
          self.showTheList = false;
          self.showSearchBox = false;
          //For re-rendering the SortableSwitch and SearchBox component with the latest data.
          $timeout(() => {
            self.showTheList = true;
            self.showSearchBox = true;
          }, 500);
        } else {
          self.columnConfigs = angular.copy($scope.columnConfigs);
        }

        self.areAllFieldsVisible = areAllFieldsVisible();
      }

      $scope.$watch(
        "columnConfigs",
        () => {
          updateViewModelFromScope();
        },
        true
      );

      $scope.$watch("fields", newValue => {
        fieldsByName = new Map();
        newValue.forEach(field => {
          fieldsByName.set(field.name(), field);
        });
        updateViewModelFromScope();
      });

      $scope.getDisplayNameByFieldName = fieldName => {
        const fieldModel = fieldsByName.get(fieldName);
        if (!fieldModel) return;
        return fieldModel.displayName();
      };

      $scope.getDataTypeByFieldName = fieldName => {
        const fieldModel = fieldsByName.get(fieldName);
        if (!fieldModel) return;
        return fieldModel.type();
      };

      self.handleAllFieldsVisibleClick = event => {
        setAllFieldsVisible(!areAllFieldsVisible());
        self.areAllFieldsVisible = areAllFieldsVisible();

        //Preventing the space bar to scroll the webpage when used for toggling
        if (event && event.keyCode === 32) {
          event.preventDefault();
        }
      };

      self.handleFieldVisibleClick = (field, event) => {
        if (self.configureTableViewRevamp) {
          self.columnConfigs.forEach((d, index) => {
            if ($scope.getDisplayNameByFieldName(d.name()) === field.label && index === field.originalIndex)
              d.visible(!d.visible());
          });
          self.columnConfigsChanged = angular.copy(self.columnConfigs);
        } else {
          field.visible(!field.visible());
        }
        self.areAllFieldsVisible = areAllFieldsVisible();

        //Preventing the space bar to scroll the webpage when used for toggling
        if (event && event.keyCode === 32) {
          event.preventDefault();
        }
      };

      self.handleFieldPositionChange = newSortedList => {
        let newColumnConfigs = [];
        newSortedList.forEach(field => {
          self.columnConfigs.forEach((d, index) => {
            if ($scope.getDisplayNameByFieldName(d.name()) === field.label && index === field.originalIndex)
              newColumnConfigs.push(d);
          });
        });

        self.columnConfigsChanged = angular.copy(newColumnConfigs);
      };

      self.onSearchTextChange = e => {
        $timeout.cancel(timeoutPromise);
        self.searchTerm = e ? e.target.value : "";

        self.columnConfigsFormatted = filterFields(self.columnConfigsFormattedOriginal, self.searchTerm);
        self.showTheList = false;
        timeoutPromise = $timeout(() => {
          self.showTheList = true;
        }, 500);
      };

      self.handleFieldOnClick = selectedItem => {
        const { label } = selectedItem;
        self.highlightLabel = label;
        self.searchTerm = "";
        self.columnConfigsFormatted = self.columnConfigsFormattedOriginal.map(item => {
          item.isDragDisabled = false;
          item.highLight = item.label === self.highlightLabel && item.originalIndex === selectedItem.originalIndex;
          return item;
        });
        self.highlightLabel = "";

        self.showTheList = false;
        self.showSearchBox = false;
        timeoutPromise = $timeout(() => {
          self.showTheList = true;
          self.showSearchBox = true;
        }, 500);
      };
      self.handleFieldsOnRender = () => {
        const highlightElement = $(".table-config__body .sortable-highlight");
        const container = $(".table-config__body");
        if (highlightElement.length) {
          const position = highlightElement.offset().top - container.offset().top + container.scrollTop();
          container.animate({
            scrollTop: position,
          });
          highlightElement.focus();
          $timeout(() => {
            highlightElement.removeClass("sortable-highlight");
          }, 1500);
        }
      };
      self.handleSubmitClick = () => {
        UsageTracker.mark("dataTableConfig.submit");
        $scope.onSubmitClick({
          columnConfigs: angular.copy(self.columnConfigsChanged),
        });
      };

      self.handleCloseClick = () => {
        self.searchTerm = "";
        updateViewModelFromScope();
        angular.element(".action-buttons__configure-button").focus();
        $scope.onCloseClick();
      };

      self.handleCancelClick = () => {
        self.searchTerm = "";
        updateViewModelFromScope();
        angular.element(".action-buttons__configure-button").focus();
        $scope.onCancelClick();
      };
    }

    function filterFields(inputList, filterInput) {
      let filteredList = inputList.filter(item => item.label.match(new RegExp(filterInput, "i")));
      const dragDisabled = filterInput.length > 0;
      filteredList = filteredList.map(item => {
        item.isDragDisabled = dragDisabled;
        item.highLight = item.label === self.highlightLabel;
        return item;
      });
      return filteredList;
    }
    function areAllFieldsVisible() {
      return self.columnConfigs.filter(d => !d.visible()).length === 0;
    }

    function setAllFieldsVisible(v) {
      self.columnConfigs.forEach((d, itemIndex) => {
        d.visible(v);
        if (self.columnConfigsFormatted[itemIndex]) {
          self.columnConfigsFormatted[itemIndex].checked = v;
        }
      });
      self.columnConfigsChanged = angular.copy(self.columnConfigs);
    }
  }

  return {
    restrict: "E",
    replace: true,
    scope: {
      // FIXME: Change to one-way binding.
      columnConfigs: "=",
      fields: "=",
      onSubmitClick: "&",
      onCancelClick: "&",
      onCloseClick: "&",
    },
    templateUrl: "visualizer/js/modules/core/dataTableConfig/dataTableConfig.tpl.html",
    controller: DataTableConfigCtrl,
    controllerAs: "vm",
  };
});
