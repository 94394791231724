import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";

const CombinationChartDataConfigComponent = {
  bindings: {
    columnDefs: "<",
    dataConfig: "<",
    fieldNameToFieldObj: "<",
    onDataConfigChange: "&",
    onApplyClick: "&",
    removeTab: "&",
    showChartFormatOptions: "&",
    chartFieldFormatProps: "&",
    field: "&",
    fieldFormat: "&",
    vizId: "<",
    fieldFormatIdentifier: "<",
    displayConfigChanged: "&",
    displayConfig: "<",
    localize: "<",
    dataConfigChange: "&",
  },
  restrict: "E",
  templateUrl: "visualizer/js/modules/visualization/combinationChart/combinationChartDataConfig.tpl.html",
  controllerAs: "combinationChartConfig",
  controller: function CombinationChartDataConfig() {
    const combinationChartConfig = this;

    combinationChartConfig.viewModel = defaultViewModel();

    combinationChartConfig.isApplyButtonEnabled = false;

    combinationChartConfig.updateRows = selectedRows => {
      combinationChartConfig.viewModel.chartXAxis = selectedRows;
      onDataConfigChange();
    };

    combinationChartConfig.$onChanges = changeObj => {
      if (
        (changeObj.columnDefs || changeObj.dataConfig) &&
        combinationChartConfig.columnDefs &&
        combinationChartConfig.dataConfig
      ) {
        combinationChartConfig.viewModel = dataConfigToViewModel(combinationChartConfig.dataConfig);
        updateIsApplyButtonEnabled();
      }
    };

    combinationChartConfig.handleConfigFieldChange = (configKey, selectedField) => {
      combinationChartConfig.dataConfig[configKey] = selectedField;
      combinationChartConfig.viewModel[configKey] = selectedField;

      updateIsApplyButtonEnabled();
      onDataConfigChange();
    };

    combinationChartConfig.onChangeFieldFormatFirstYAxis = (field, fieldFormat) => {
      GlobalFieldFormatMap.setFieldFormat(combinationChartConfig.fieldFormatIdentifier, field.name(), fieldFormat);
      GlobalFieldFormatMap.setFieldType(combinationChartConfig.fieldFormatIdentifier, field.name(), field.type());

      // updating chart field format props so it can keep selection
      combinationChartConfig.chartFieldFormatPropsFirstYAxis.fieldFormat = fieldFormat;

      // serialize field format and set it in displayConfig
      const fieldFormatObj = fieldFormat.toJson();
      combinationChartConfig.displayConfig.valueFormattingOptionsFirstYAxis = fieldFormatObj;

      // call display config changed call back so it will update data model
      combinationChartConfig.firstYAxisAggregationSelectorChange(
        combinationChartConfig.viewModel.firstYAxisAggregationType,
        combinationChartConfig.viewModel.firstYAxisAggregationFieldName
      );
      combinationChartConfig.displayConfigChanged();
      combinationChartConfig.dataConfigChange();
    };

    combinationChartConfig.onChangeFieldFormatSecondYAxis = (field, fieldFormat) => {
      GlobalFieldFormatMap.setFieldFormat(combinationChartConfig.fieldFormatIdentifier, field.name(), fieldFormat);
      GlobalFieldFormatMap.setFieldType(combinationChartConfig.fieldFormatIdentifier, field.name(), field.type());

      // updating chart field format props so it can keep selection
      combinationChartConfig.chartFieldFormatPropsSecondYAxis.fieldFormat = fieldFormat;

      // serialize field format and set it in displayConfig
      const fieldFormatObj = fieldFormat.toJson();
      combinationChartConfig.displayConfig.valueFormattingOptionsSecondYAxis = fieldFormatObj;

      // call display config changed call back so it will update data model
      combinationChartConfig.displayConfigChanged();
      combinationChartConfig.dataConfigChange();
    };

    function dataConfigToViewModel(dataConfig) {
      const chartXAxis = dataConfig.chartXAxis || "";
      const chartSeries = dataConfig.chartSeries || "";

      if (!chartXAxis) {
        return defaultViewModel();
      }

      const viewModel = defaultViewModel();
      if (chartXAxis) {
        viewModel.chartXAxis = chartXAxis.fieldName;
      }
      if (chartSeries) {
        viewModel.chartSeries = chartSeries.fieldName;
      }

      const { chartValues } = dataConfig;
      if (chartValues) {
        viewModel.chartValues = chartValues;
        if (chartValues.length > 0) {
          const firstYAxisAggregationType = dataConfig.chartValues[0];
          viewModel.firstYAxisAggregationType = firstYAxisAggregationType.aggregationType;
          viewModel.firstYAxisAggregationFieldName = firstYAxisAggregationType.fieldName;
        }
        if (chartValues.length > 1) {
          const secondYAxisAggregationType = dataConfig.chartValues[1];
          viewModel.secondYAxisAggregationType = secondYAxisAggregationType.aggregationType;
          viewModel.secondYAxisAggregationFieldName = secondYAxisAggregationType.fieldName;
        }
      }
      setFieldFormat();
      combinationChartConfig.validateSecondYAxis();
      return viewModel;
    }

    function viewModelToDataConfig(viewModel) {
      const dataConfig = {
        chartXAxis: viewModel.chartXAxis,
        chartSeries: viewModel.chartSeries,
        chartValues: [
          (viewModel.firstYAxisAggregationType !== "count" && viewModel.firstYAxisAggregationFieldName) || "",
          (viewModel.secondYAxisAggregationType !== "count" && viewModel.secondYAxisAggregationFieldName) || "",
        ],
        aggregationTypes: [viewModel.firstYAxisAggregationType, viewModel.secondYAxisAggregationType],
      };
      return dataConfig;
    }

    combinationChartConfig.firstYAxisAggregationSelectorChange = (type, fieldName) => {
      combinationChartConfig.viewModel.firstYAxisAggregationType = type;
      combinationChartConfig.viewModel.firstYAxisAggregationFieldName = fieldName;
      updateChartValues();
      combinationChartConfig.validateSecondYAxis();
    };

    combinationChartConfig.secondYAxisAggregationSelectorChange = (type, fieldName) => {
      combinationChartConfig.viewModel.secondYAxisAggregationType = type;
      combinationChartConfig.viewModel.secondYAxisAggregationFieldName = fieldName;
      updateChartValues();
      combinationChartConfig.validateSecondYAxis();
    };

    function updateChartValues() {
      const values = [];
      const aggregationTypes = [];

      if (combinationChartConfig.viewModel.firstYAxisAggregationType === "count") {
        values.push("");
        aggregationTypes.push("count");
      } else if (combinationChartConfig.viewModel.firstYAxisAggregationFieldName) {
        values.push(combinationChartConfig.viewModel.firstYAxisAggregationFieldName);
        aggregationTypes.push(combinationChartConfig.viewModel.firstYAxisAggregationType);
      }

      if (values.length > 0) {
        if (combinationChartConfig.viewModel.secondYAxisAggregationType === "count") {
          values.push("");
          aggregationTypes.push("count");
        } else if (combinationChartConfig.viewModel.secondYAxisAggregationFieldName) {
          values.push(combinationChartConfig.viewModel.secondYAxisAggregationFieldName);
          aggregationTypes.push(combinationChartConfig.viewModel.secondYAxisAggregationType);
        }
      }

      combinationChartConfig.viewModel.chartValues = values;
      combinationChartConfig.viewModel.aggregationTypes = aggregationTypes;

      updateIsApplyButtonEnabled();
      onDataConfigChange();
      setFieldFormat();
    }

    function setFieldFormat() {
      if (combinationChartConfig.showChartFormatOptions) {
        let Field = combinationChartConfig.field();
        combinationChartConfig.chartFieldFormatPropsFirstYAxis = new Field();
        combinationChartConfig.chartFieldFormatPropsSecondYAxis = new Field();

        const chartValueFieldNameFirstYAxis = `${combinationChartConfig.vizId}-chart-value-FirstYAxis`;
        let FieldFormatFirstYAxis = combinationChartConfig.fieldFormat();
        let chartValueFieldFirstYAxis = new Field()
          .name(chartValueFieldNameFirstYAxis)
          .displayName(chartValueFieldNameFirstYAxis)
          .type("numeric");
        let chartValueFormattingOptionFirstYAxis =
          combinationChartConfig.displayConfig.valueFormattingOptionsFirstYAxis || {};
        combinationChartConfig.chartFieldFormatPropsFirstYAxis.field = chartValueFieldFirstYAxis;
        combinationChartConfig.chartFieldFormatPropsFirstYAxis.fieldFormat = FieldFormatFirstYAxis.fromJson(
          chartValueFormattingOptionFirstYAxis
        );
        combinationChartConfig.chartFieldFormatPropsFirstYAxis.linkLabel = combinationChartConfig.localize.getLocalizedString(
          "_MetricsConfig.FormatOptions.Label_"
        );

        const chartValueFieldNameSecondYAxis = `${combinationChartConfig.vizId}-chart-value-SecondYAxis`;
        let FieldFormat = combinationChartConfig.fieldFormat();
        let chartValueFieldSecondYAxis = new Field()
          .name(chartValueFieldNameSecondYAxis)
          .displayName(chartValueFieldNameSecondYAxis)
          .type("numeric");
        let chartValueFormattingOptionSecondYAxis =
          combinationChartConfig.displayConfig.valueFormattingOptionsSecondYAxis || {};
        combinationChartConfig.chartFieldFormatPropsSecondYAxis.field = chartValueFieldSecondYAxis;
        combinationChartConfig.chartFieldFormatPropsSecondYAxis.fieldFormat = FieldFormat.fromJson(
          chartValueFormattingOptionSecondYAxis
        );
        combinationChartConfig.chartFieldFormatPropsSecondYAxis.linkLabel = combinationChartConfig.localize.getLocalizedString(
          "_MetricsConfig.FormatOptions.Label_"
        );
      }
    }

    combinationChartConfig.validateSecondYAxis = () => {
      if (
        combinationChartConfig.viewModel.firstYAxisAggregationFieldName &&
        combinationChartConfig.viewModel.secondYAxisAggregationFieldName
      ) {
        if (
          combinationChartConfig.viewModel.firstYAxisAggregationFieldName ===
            combinationChartConfig.viewModel.secondYAxisAggregationFieldName &&
          combinationChartConfig.viewModel.firstYAxisAggregationType === "average"
        ) {
          combinationChartConfig.isSecondYAxisValid = false;
          return;
        }
        combinationChartConfig.isSecondYAxisValid = true;
      }
    };

    function updateIsApplyButtonEnabled() {
      combinationChartConfig.isApplyButtonEnabled = isValidDataConfig(
        viewModelToDataConfig(combinationChartConfig.viewModel)
      );
    }

    function isValidDataConfig(dataConfig) {
      if (!dataConfig) return false;

      const { chartXAxis, chartValues, aggregationTypes } = dataConfig;

      if (!chartXAxis) return false;
      if (!chartValues) return false;
      if (chartValues.length !== 2) return false;
      if (!isValidDataConfigValue(chartValues[0], aggregationTypes[0])) return false;
      if (!isValidDataConfigValue(chartValues[1], aggregationTypes[1])) return false;
      return true;
    }

    function isValidDataConfigValue(value, aggregationType) {
      return typeof value === "string" && (aggregationType === "count" || value !== "");
    }

    function onDataConfigChange() {
      combinationChartConfig.onDataConfigChange({
        dataConfig: viewModelToDataConfig(combinationChartConfig.viewModel),
      });
    }

    function defaultViewModel() {
      return {
        chartSeries: "",
        chartValues: [],
        chartXAxis: "",
        firstYAxisAggregationType: "sum",
        firstYAxisAggregationField: null,
        secondYAxisAggregationType: "sum",
        secondYAxisAggregationField: null,
        isSecondYAxisValid: false,
      };
    }
  },
};
export default CombinationChartDataConfigComponent;
