import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import UrlFlipper from "@viz-ui/services/urlFlipper/urlFlipper";

const Indicator = () => ({
  restrict: "E",
  replace: true,
  scope: {
    fontZoomRatio: "<",
    metricModel: "<",
    presentationMode: "<",
    sparklineModel: "<",
    showOnlyThreshold: "<",
    showInsufficientSparklineDataMessage: "<",
    locale: "<",
  },
  templateUrl: "visualizer/js/modules/visualization/indicator/indicator.tpl.html",
  controllerAs: "indicator",
  controller: IndicatorController,
  bindToController: true,
});

class IndicatorController {
  static configPath = {
    labelFontSize: [
      "plotOptions.series.dataLabels.style.fontSize",
      "tooltip.style.fontSize",
      "xAxis.labels.style.fontSize",
      "yAxis.labels.style.fontSize",
      "yAxis.plotLines.label.style.fontSize",
    ],
  };

  constructor($scope, HighChartLabelSizer) {
    "ngInject";
    this.handleRef = ref => {
      this.metricIndicator = ref;
    };

    this.$onChanges = changesObj => {
      if (changesObj.metricModel) {
        this.metric = changesObj.metricModel.currentValue.toJson();
        this.showSparkline = this.metric.show_sparkline;
      }

      if (changesObj.sparklineModel) {
        this.metricSparkline = changesObj.sparklineModel.currentValue.toJson();
      }
    };

    this.timezoneOffsetSeconds = GlobalValueFormatter.getTimezoneOffset().utcOffsetSeconds();

    $scope.$on("chartRedraw", () => {
      if (this.metricIndicator) {
        this.metricIndicator.reflow();
      }
    });
    let hcConfig = {
      plotOptions: {
        series: {
          animation: false,
        },
      },
    };
    this.fontConfig = HighChartLabelSizer.updateLabelFontSize(
      UrlFlipper.isRenderMode("export") ? hcConfig : {}, //disable animation for exporting as pdf
      this.presentationMode ? "large" : undefined,
      IndicatorController.configPath.labelFontSize,
      this.fontZoomRatio
    );
  }
}

export default Indicator;
