import React from "react";
import logger from "@viz-ui/services/logger/logger";
import PropTypes from "prop-types";
import keys from "lodash/keys";
import Field from "@viz-ui/models/field/field";
import ColumnConfig from "@viz-ui/models/tableConfig/columnConfig";
import FieldFormatAdapter from "@viz-ui/models/field/fieldFormatAdapter";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";
import SummaryTableDataGenerator from "@viz-ui/services/charts/summaryTableChart/summaryTableDataGeneratorService";
import DataGrid from "@viz-ui/components/dataGrid/dataGrid";
import pubsub from "pubsub-js";
import { isEqual, sortBy } from "lodash";
import SummaryTableAdapter from "@viz-ui/models/summaryTable/summaryTableAdapter";

class SummaryTableChart extends React.Component {
  DATA_PAGE_SIZE = 200;

  columnConfigs;

  fields;

  fieldFormatMap = new Map();

  nestedHeaders;

  skipCellValueFormatting = true;

  summaryTableModel;

  tableData = [];

  totalRow;

  summaryTable = {
    tableId: Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1),
    fieldFormatIdentifier: this.props.chartViz.id,
    displayConfig: this.props.chartViz.config.displayConfig,
  };

  pendingSummaryTableData = null;

  static propTypes = {
    index: PropTypes.number,
    chartModel: PropTypes.any,
    redrawIndex: PropTypes.number,
    zoomInHandler: PropTypes.func,
    appConfig: PropTypes.any.isRequired,
    chartViz: PropTypes.any.isRequired,
    eventService: PropTypes.any.isRequired,
    dataModel: PropTypes.any.isRequired,
  };

  static defaultProps = {
    index: 0,
    chartModel: {},
    redrawIndex: 0,
    zoomInHandler: () => {},
  };

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.summaryTable.data = this.getSummaryTableData(this.props.chartModel);
    this.handleDataChange();
    this.state = {
      showTotals: true,
      currentData: this.getRowsForDataGrid(this.tableData, 0, this.DATA_PAGE_SIZE - 1),
    };
  }

  componentDidMount() {
    const eventService = this.props.eventService.register("summaryChart");

    pubsub.subscribe("chartRedraw", () => {
      if (this.tableRef.current) this.tableRef.current.redraw();
    });

    eventService.subscribe("dataTable.formattingChange", () => {
      if (this.isActiveTab()) {
        this.summaryTable.data = this.getSummaryTableData(this.props.chartModel);
      } else {
        const summaryTableData = this.getSummaryTableData(this.props.chartModel);
        this.setPendingSummaryTableData(summaryTableData);
      }
      this.handleDataChange();
    });

    eventService.subscribe("dataTable.columnResized", () => {
      this.setPendingSummaryTableData(this.getSummaryTableData(this.props.chartModel));
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.summaryTable.data = this.getSummaryTableData(nextProps.chartModel);
    this.summaryTable.fixedColumnsLeftCount =
      nextProps.chartModel && nextProps.chartModel.config && nextProps.chartModel.config.dataConfig
        ? this.getFixedColumnsLeftCount(nextProps.chartModel.config.dataConfig)
        : 0;

    if (nextProps.chartModel && nextProps.chartModel.config && nextProps.chartModel.config.displayConfig) {
      this.summaryTable.displayConfig = {
        ...nextProps.chartModel.config.displayConfig,
      };
    }

    if (!isEqual(this.props.chartModel, nextProps.chartModel)) {
      this.handleDataChange();
      this.handleEmptySummaryTable({ previousValue: { ...this.props }, currentValue: { ...nextProps } });
      this.setState({
        showTotals: nextProps.chartModel.config.displayConfig.showTotals,
        currentData: this.getRowsForDataGrid(this.tableData, 0, this.DATA_PAGE_SIZE - 1),
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (isEqual(nextState.chartModel, nextProps.chartModel)) {
      return false;
    }
    return true;
  }

  isActiveTab() {
    return this.props.index === this.props.dataModel.interpretation.currentTabIndex();
  }

  setPendingSummaryTableData(newProps) {
    this.pendingSummaryTableData = newProps;
  }

  updateSummaryTableData() {
    if (this.pendingSummaryTableData) {
      this.summaryTable.data = this.pendingSummaryTableData;
      this.pendingSummaryTableData = null;
    }
  }

  getSummaryTableData(chartModel) {
    if (this.props.appConfig.features.queryService) {
      return this.props.data;
    }
    return {
      table: this.props.dataModel.table.model(),
      formattingOptions: this.props.dataModel.tableConfig.formattingOptions(),
      rawData: chartModel.data,
      columns: this.props.dataModel.tableConfig.columns(),
    };
  }

  getFixedColumnsLeftCount(dataConfig) {
    const { freezeRowHeaders, chartRows } = dataConfig;
    return freezeRowHeaders && chartRows ? chartRows.length : 0;
  }

  handleEmptySummaryTable(changesData) {
    let previousConfig;
    let currentConfig;
    let currentValues;
    const hasPreviousRawData = changesData.previousValue && changesData.previousValue.rawData;
    const hasCurrentRawData = changesData.currentValue && changesData.currentValue.rawData;
    if (!this.props.appConfig.features.queryService) {
      previousConfig = hasPreviousRawData ? changesData.previousValue.rawData.config : {};
      currentConfig = hasCurrentRawData ? changesData.currentValue.rawData.config : {};
      currentValues = hasCurrentRawData ? changesData.currentValue.rawData.values : [];
    } else {
      previousConfig = hasPreviousRawData ? changesData.previousValue.rawData().config : {};
      currentConfig = hasCurrentRawData ? changesData.currentValue.rawData().config : {};
      currentValues = hasCurrentRawData ? changesData.currentValue.rawData().values.summarizedData : [];
    }
    if (currentValues.length === 0 || !angular.equals(previousConfig, currentConfig)) {
      this.unmountDataGrid();
    }
  }

  handleDataChange() {
    const { data } = this.summaryTable;
    if (!data) return;
    this.skipCellValueFormatting = false;
    if (this.props.appConfig.features.queryService) {
      const rawDataConfig = data.rawData().config;
      this.setupQueryServiceData(data);
      this.setupColumnConfigs(rawDataConfig.chartRows, data.columns);
      this.setupValuesConfigs(rawDataConfig.chartValues, data.valueColumnMap(), data.columns);
    } else {
      const dataFieldFormatMap = FieldFormatAdapter.deserializeFieldFormatMap(data.formattingOptions);
      if (data.table && data.rawData && data.rawData.values.length) {
        this.summaryTableModel = new SummaryTableDataGenerator().createSummaryTable(
          data.table.fields(),
          dataFieldFormatMap,
          data.rawData,
          this.displayTotals()
        );
        this.setupHeadersAndFields(this.summaryTableModel.columnDefs(), data.columns);
        this.initData();
      }
    }
  }

  setupQueryServiceData(data) {
    logger.log(`${this.summaryTable.fieldFormatIdentifier} - summary table setupQueryServiceData`);
    this.summaryTableModel = SummaryTableAdapter.processTableData(data, this.DATA_PAGE_SIZE);
    this.totalRow = this.summaryTableModel.totalRow();
    this.fields = this.summaryTableModel.headers();
    this.fieldFormatMap = this.summaryTableModel.fieldFormatMap();
    this.nestedHeaders = this.summaryTableModel.nestedHeaders();
    this.setState({ currentData: this.summaryTableModel.tableData() });
    logger.log(`${this.summaryTable.fieldFormatIdentifier} - this.fields: ${this.fields ? this.fields.length : null}`);
    logger.log(
      `${this.summaryTable.fieldFormatIdentifier} - this.nestedHeaders: ${
        this.nestedHeaders ? this.nestedHeaders.length : null
      }`
    );
    logger.log(
      `${this.summaryTable.fieldFormatIdentifier} - this.currentData: ${
        this.state.currentData ? this.state.currentData.length : null
      }`
    );

    const rawData = this.summaryTableModel.rawData();
    this.tableData = rawData ? rawData.values.summarizedData : [];

    GlobalFieldFormatMap.setFieldFormats(this.summaryTable.fieldFormatIdentifier, this.fieldFormatMap);
    GlobalFieldFormatMap.setFieldTypes(this.summaryTable.fieldFormatIdentifier, this.fields);
  }

  setupValuesConfigs(chartValues = [], valueColumnMap = [], columns = []) {
    chartValues.forEach((chartValue, index) => {
      const columnConfigObj = columns.find(column => column.fieldName === chartValue.fieldName);
      if (columnConfigObj) {
        const cols = Object.fromEntries(
          Object.entries(valueColumnMap).filter(([key]) => JSON.parse(key).value === `__valueField${index}`)
        );
        Object.values(cols).forEach(name => {
          this.columnConfigs.push(
            new ColumnConfig()
              .name(name)
              .visible(true)
              .wordWrap(columnConfigObj.wordWrap)
              .width(columnConfigObj.columnWidth)
          );
        });
      }
    });
    this.columnConfigs = sortBy(this.columnConfigs, [oColumn => Number(oColumn._data.name.match(/\d*$/)[0])]);
  }

  setupColumnConfigs(chartRows = [], columns = []) {
    this.columnConfigs = chartRows
      .map((rowField, index) => {
        const columnConfigObj = columns.find(column => column.fieldName === rowField.fieldName);
        if (columnConfigObj) {
          return new ColumnConfig()
            .name(`col${index + 1}`)
            .visible(true)
            .wordWrap(columnConfigObj.wordWrap)
            .width(columnConfigObj.columnWidth);
        }
      })
      .filter(columnConfig => columnConfig);
  }

  getSkipFormattingOnCells() {
    if (this.displayTotals() && this.totalRow) {
      const lastRowIndex = this.state.currentData.length;
      let totalCellFieldName = "col_0";
      if (this.props.appConfig.features.queryService) {
        totalCellFieldName = "col1";
      }
      return [{ rowIndex: lastRowIndex, fieldName: totalCellFieldName }];
    }
    return [];
  }

  setupHeadersAndFields(columnDefs, columnConfigObjs) {
    this.nestedHeaders = this.initNestedHeader();
    this.fields = [];
    this.columnConfigs = [];
    columnDefs.forEach(columnDef => {
      const columnConfigObj = columnConfigObjs.find(columnConfig => columnConfig.fieldName === columnDef.fieldName);
      const field = new Field()
        .name(columnDef.field)
        .displayName(columnDef.displayName)
        .type(columnDef.type);
      this.addFieldToHeader(field);
      this.addColumnConfig(field, columnDef, columnConfigObj);
      this.addColumnDefToNestedHeaders(field, columnDef);
    });

    this.fieldFormatMap = this.summaryTableModel.fieldFormatMap();
    GlobalFieldFormatMap.setFieldFormats(this.summaryTable.fieldFormatIdentifier, this.fieldFormatMap);
    GlobalFieldFormatMap.setFieldTypes(this.summaryTable.fieldFormatIdentifier, this.fields);
  }

  initData() {
    this.tableData = this.summaryTableModel.tableData();
    this.totalRow = this.hasTotalRow() ? this.tableData.pop() : undefined;
  }

  getRowsForDataGrid(data, start, end) {
    return data.slice(start, end);
  }

  initNestedHeader() {
    let headerRowspan = this.getHeaderRowspan();
    const newNestedHeaders = [];
    while (headerRowspan > 1) {
      newNestedHeaders.push([]);
      headerRowspan -= 1;
    }
    return newNestedHeaders;
  }

  addColumnConfig(field, columnDef, columnConfigObj = {}) {
    this.columnConfigs.push(
      new ColumnConfig()
        .name(field.name())
        .visible(true)
        .wordWrap(!!columnConfigObj.wordWrap)
        .width(columnConfigObj.columnWidth)
    );
  }

  addFieldToHeader(field) {
    this.fields.push(field);
  }

  addColumnDefToNestedHeaders(field, columnDef) {
    if (columnDef.column) {
      this.nestedHeaders.forEach((headerRow, index) => {
        let nestedColumnField;
        if (index === 0) {
          nestedColumnField = new Field()
            .name(columnDef.column.displayName)
            .displayName(columnDef.column.displayName)
            .type(columnDef.column.type);
        } else {
          nestedColumnField = new Field()
            .name(columnDef.column.displayValue)
            .displayName(columnDef.column.displayValue)
            .type(columnDef.column.type);
        }
        this.addNestedColspanHeader(headerRow, nestedColumnField);
      });
    } else {
      const headerRowspan = this.getHeaderRowspan();
      this.nestedHeaders.forEach((headerRow, index) => {
        const nestedHeaderObj = {};
        nestedHeaderObj.field = field;
        if (index === 0) nestedHeaderObj.rowspan = headerRowspan;
        headerRow.push(nestedHeaderObj);
      });
    }
  }

  addNestedColspanHeader(headerRow, nestedColumnField) {
    const lastHeaderItem = headerRow[headerRow.length - 1];
    if (lastHeaderItem && lastHeaderItem.field && lastHeaderItem.field.name() === nestedColumnField.name()) {
      lastHeaderItem.colspan += 1;
    } else {
      headerRow.push({ field: nestedColumnField, colspan: 1 });
    }
  }

  onScrollToBottom(that) {
    if (that.state.currentData.length < that.tableData.length) {
      if (!that.props.appConfig.features.queryService) {
        const nextPatchEndIndex =
          that.state.currentData.length + that.DATA_PAGE_SIZE > that.tableData.length
            ? that.tableData.length
            : that.state.currentData.length + that.DATA_PAGE_SIZE;
        const nextPatchData = that.tableData.slice(that.state.currentData.length, nextPatchEndIndex);
        const updatedData = [...that.state.currentData, ...that.getRowsForDataGrid(nextPatchData)];

        this.setState({ currentData: updatedData });
      } else {
        that.summaryTableModel = SummaryTableAdapter.processTableData(that.summaryTableModel, that.DATA_PAGE_SIZE);

        this.setState({ currentData: that.summaryTableModel.tableData() });
      }
    }
  }

  getHeaderRowspan() {
    const rawData = this.summaryTableModel.rawData();
    const config = this.getConfig(rawData);
    let rowspan = 1;
    if (keys(config.column).length) {
      rowspan += 1;
      if (config.values.length) {
        rowspan += 1;
      }
    }
    return rowspan;
  }

  mergeCells() {
    if (this.hasTotalRow()) {
      const rawData = this.summaryTableModel.rawData();
      const config = this.getConfig(rawData);
      return [{ col: 0, row: this.state.currentData.length, colspan: config.rows.length, rowspan: 1 }];
    }

    return [];
  }

  hasTotalRow() {
    if (!this.displayTotals()) return false;
    const summTableData = this.summaryTableModel.tableData();
    const rawData = this.summaryTableModel.rawData();
    const config = this.getConfig(rawData);
    return summTableData.length > 1 && config.values.length > 0;
  }

  displayTotals() {
    return this.summaryTable.displayConfig.showTotals;
  }

  getConfig(rawData) {
    if (!this.props.appConfig.features.queryService) {
      return rawData.config;
    }
    return {
      rows: rawData.config.chartRows,
      column: rawData.config.chartColumns.length ? rawData.config.chartColumns[0] : undefined,
      values: rawData.config.chartValues,
    };
  }

  unmountDataGrid() {
    // TODO: need to fix this
    // if (this.tableRef && $element[0]) {
    //   ReactDOM.unmountComponentAtNode($element[0]);
    // }
  }

  getOrgId(props) {
    if (props.initialOrganizationId) return props.initialOrganizationId;
    return undefined;
  }

  render() {
    const componentProps = {
      columnConfigs: this.columnConfigs,
      data:
        this.displayTotals() && this.totalRow ? this.state.currentData.concat(this.totalRow) : this.state.currentData,
      fieldFormatIdentifier: this.summaryTable.fieldFormatIdentifier,
      fields: this.fields,
      manualColumnResize: false,
      fixedRowsBottom: this.hasTotalRow() ? 1 : 0,
      fontZoomRatio: this.summaryTable.fontZoomRatio,
      isPresentationMode: this.summaryTable.isPresentationMode,
      isRowHighlightingEnabled: true,
      isShrinkWrapEnabled: this.summaryTable.isShrinkWrapEnabled,
      mergeCells: this.mergeCells(),
      nestedHeaders: this.nestedHeaders,
      onScrollToBottom: () => this.onScrollToBottom(this),
      outsideClickDeselects: this.summaryTable.outsideClickDeselects,
      skipCellValueFormatting: this.skipCellValueFormatting,
      skipFormattingOnCells: this.getSkipFormattingOnCells(),
      fixedColumnsLeft: this.summaryTable.fixedColumnsLeft,
      getOrgId: () => this.getOrgId(this.props),
      sourcePage: "summary_table",
    };
    return (
      <div className="visualizer__summaryTable react__summaryTable">
        <DataGrid {...componentProps} ref={this.tableRef} />
      </div>
    );
  }
}

export default SummaryTableChart;
