angular
  .module("acl.visualizer.charts.controllers")
  .controller("StatisticsVizController", function($scope, InheritController, StatisticsViz) {
    var base = InheritController("ChartBaseController", $scope)
      .override("chartType", "StatisticsViz")
      .override("defaultDisplayConfig", StatisticsViz.defaultDisplayConfig)
      .override("getChartDirectiveConfig", StatisticsViz.getChartDirectiveConfig)
      .override("populateChartConfigColumnDefs", StatisticsViz.populateChartConfigColumnDefs)
      .override("chartConfigColumnDefs", StatisticsViz.chartConfigColumnDefs)
      .override("isValidDataConfig", StatisticsViz.isValidDataConfig);

    $scope.displayPopup = {};

    $scope.setDisplayForPopup = function(attribute, popup, showOrHide) {
      if ($scope.displayPopup[attribute] === undefined) {
        $scope.displayPopup[attribute] = {};
      }
      $scope.displayPopup[attribute][popup] = showOrHide;
    };

    $scope.showPopup = function(statistic, type) {
      return (
        statistic[type] &&
        statistic[type].length > 1 &&
        $scope.displayPopup[statistic.fieldName] &&
        $scope.displayPopup[statistic.fieldName][type]
      );
    };

    base.initChart();
  });
