import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import merge from "lodash/merge";
import i18n from "@viz-ui/i18n/i18n";
import findLastIndex from "lodash/findLastIndex";
import { AREA_CHART } from "@visualizer/modules/visualization/highCharts/boost/boostThresholds";
import pubsub from "pubsub-js";
import ReactHighChart from "../highChart/reactHighChart";
import {
  canAnimate,
  updateHcConfig,
  updateLabelFontSize,
  getBoostConfig,
  clickWrapper,
  baseConfig,
  configPath,
} from "../services/highChartService";

const stacking = {
  stack: "normal",
  expand: "percent",
  overlap: undefined,
};

class AreaChart extends React.Component {
  trimInterpolated(values) {
    return values.find(value => !value.interpolated)
      ? values.slice(
          values.findIndex(value => !value.interpolated),
          findLastIndex(values, value => !value.interpolated) + 1
        )
      : [];
  }

  static propTypes = {
    index: PropTypes.number,
    config: PropTypes.object,
    rawData: PropTypes.array,
    redrawIndex: PropTypes.number,
    zoomInHandler: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    config: {},
    rawData: [],
    redrawIndex: 0,
    zoomInHandler: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      hcConfig: {},
      chartReDraw: false,
    };
  }

  componentDidMount() {
    pubsub.subscribe("chartRedraw", () => {
      this.setState({ chartReDraw: !this.state.chartReDraw });
    });

    this.setState({ hcConfig: this.getConfig(this.props.config, this.props.rawData) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.config !== nextProps.config)
      this.setState({ hcConfig: this.getConfig(nextProps.config, nextProps.rawData) });
  }

  onLegendItemClick = item => {
    const showAll = !this.state.hcConfig.series.some(entry => entry.visible && entry.name !== item.name);
    const series = this.state.hcConfig.series.map(entry =>
      Object.assign({}, entry, {
        visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
      })
    );
    this.setState({
      hcConfig: updateHcConfig(this.state.hcConfig, { series }),
    });
  };

  onLegendItemDoubleClick = item => {
    const series = this.state.hcConfig.series.map(entry =>
      Object.assign({}, entry, { visible: entry.name === item.name })
    );
    this.setState({
      hcConfig: updateHcConfig(this.state.hcConfig, { series }),
    });
  };

  getConfig = (config, rawData) => {
    let categories;
    if ((config.xAxis.axisType === "character" || config.xAxis.axisType === "logical") && rawData.length) {
      categories = rawData[0].values.map(d => d.x);
    }
    const data = rawData
      .map(series =>
        Object.assign({}, series, {
          values: config.interpolate
            ? this.trimInterpolated(series.values)
            : series.values.filter(value => !value.interpolated),
        })
      )
      .reverse();

    let hcConfig = {
      legend: {
        enabled: config.showLegend && config.hasMultipleSeries,
        align: "right",
        verticalAlign: "top",
        reversed: true,
        labelFormatter: function() {
          return config.labelFormatter && this.options.name !== "(blank)"
            ? config.labelFormatter(this.options.legendDisplayName)
            : this.options.legendDisplayName;
        },
      },
      boost: getBoostConfig(config),
      tooltip: {
        formatter: function() {
          const header = config.hasMultipleSeries
            ? `
              <span style="text-decoration: underline grey;">${
                config.labelFormatter && this.series.options.name !== "(blank)"
                  ? config.labelFormatter(this.series.options.legendDisplayName)
                  : this.series.options.legendDisplayName
              }</span>
              <br/>
            `
            : "";
          const body = `
            <span>${config.xAxis.tickFormatter(this.point.xValue)}</span>
            <br/>
            <b>${config.yAxis.tickFormatter(this.point.yValue)}</b>
          `;
          return this.point.isInterpolated ? false : header + body;
        },
      },
      plotOptions: {
        series: {
          stacking: stacking[config.chartStyle],
          marker: {
            enabled: config.displayDataLabels,
          },
          dataLabels: {
            enabled: config.displayDataLabels,
            formatter: function() {
              return config.yAxis.tickFormatter(this.y);
            },
          },
          connectNulls: config.interpolate,
          point: {
            events: {
              click: clickWrapper(null, point => {
                if (point && !point.isInterpolated) {
                  this.zoomIn(config.hasMultipleSeries ? [point.series.name, point.xValue] : [point.xValue]);
                }
              }),
            },
          },
          events: {
            legendItemClick: clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
          },
          turboThreshold: 0,
          animation: canAnimate(),
        },
      },
      xAxis: {
        categories: categories,
        labels: {
          formatter: function() {
            return config.xAxis.tickFormatter(this.value);
          },
        },
        tickmarkPlacement: "on",
        title: {
          text: config.xAxis.label,
        },
      },
      yAxis: {
        title: {
          text: config.yAxis.label,
        },
        labels: {
          formatter: function() {
            return config.chartStyle === "expand" ? `${this.value}%` : config.yAxis.tickFormatter(this.value);
          },
        },
        min: config.yAxis.minimum === null ? undefined : config.yAxis.minimum,
        max: config.yAxis.maximum === null ? undefined : config.yAxis.maximum,
      },
      // Adding legendDisplayName property to handle (blank) value  & to localize the string (issue GRCPRD-4135)
      series: data.map((series, index) => ({
        type: "area",
        name: series.key,
        legendDisplayName: series.key === "(blank)" ? i18n.t("_Filter.BlankValue.Label_") : series.key,
        color:
          !getBoostConfig(
            config,
            categories && categories.length > data.length ? categories.length >= AREA_CHART : data.length >= AREA_CHART
          ).enabled && this.isPatternFill
            ? this.patternFillPalettes(index)
            : series.color,
        visible: series.visible !== false,
        boostThreshold: AREA_CHART,
        data: series.values.map(value => ({
          x: categories ? categories.indexOf(value.x) : value.x,
          xValue: value.x,
          y: value.y,
          yValue: value.y,
          isInterpolated: value.interpolated,
          dataLabels: { enabled: config.displayDataLabels && !value.interpolated },
          marker: {
            enabled: config.displayDataLabels && !value.interpolated,
            states: { hover: { enabled: !value.interpolated } },
          },
        })),
      })),
    };
    hcConfig = updateLabelFontSize(hcConfig, configPath);
    // return Object.assign(baseConfig(), hcConfig);

    hcConfig.showValue = config.displayDataLabels;
    return merge({}, baseConfig(), hcConfig);
  };

  zoomIn = keyArray => {
    if (isFunction(this.props.zoomInHandler)) {
      this.props.zoomInHandler(keyArray);
    }
  };

  render() {
    return (
      <ReactHighChart
        className={`redraw-index-${this.props.redrawIndex}`}
        config={this.state.hcConfig}
        redrawIndex={this.props.redrawIndex}
        chartReDraw={this.state.chartReDraw}
      />
    );
  }
}

export default AreaChart;
