import "core-js";

require("../../assets/css/dv.scss");

require("../../results_container/services/apiCall/apiCall");
require("../../results_container/services/downloadReportsBackend/downloadReportsBackend");

require("./common/aclAngularUI/aclAngularUI.module");
require("./common/charts/chart.module");
require("./common/charts/chartController.module");
require("./common/dataModel/dataModel.module");
require("./common/dataTable/dataTable.module");
require("./common/dataVisualizer/dataVisualizer.module");
require("./common/event/event.module");
require("./common/event/eventLogger.module");
require("./common/saveViz/saveVisualization.module");
require("./common/session/session.module");
require("./common/shareToGRC/shareToGRC.module");
require("./common/summary/Summary.module");
require("./common/tabState/tabState.module");
require("./common/charts/barChart/barChart.controller");
require("./common/charts/bubbleChart/bubbleChart.controller");
require("./common/charts/chartBase.controller");
require("./common/charts/lineChart/lineChart.controller");
require("./common/charts/pieChart/pieChart.controller");
require("./common/charts/stackedAreaChart/stackedAreaChart.controller");
require("./common/charts/statisticsViz/statisticsViz.controller");
require("./common/dataVisualizer/chartSelector.controller");
require("./common/dataVisualizer/dataVisualizer.controller");
require("./common/saveViz/saveVisualizationModal.controller");
require("./common/session/session.controller");
require("./common/shareToGRC/accordion.controller");
require("./common/shareToGRC/shareToGRC.controller");
require("./common/summary/summaryModal.controller");
require("./common/charts/barChart/barChart.service");
require("./common/charts/bubbleChart/bubbleChart.service");
require("./common/charts/chart.service");
require("./common/charts/dataDownsampler.service");
require("./common/charts/lineChart/lineChart.service");
require("./common/charts/pieChart/pieChart.service");
require("./common/charts/stackedAreaChart/stackedAreaChart.service");
require("./common/charts/statisticsViz/statisticsViz.service");
require("./common/dataModel/dataModel.service");
require("./common/dataModel/dataModelFormat.service");
require("./common/dataTable/dataTable.service");
require("./common/dataVisualizer/dataVisualizer.service");
require("./common/event/event.service");
require("./common/saveViz/saveVisualization.service");
require("./common/services/dataFilter.service");
require("./common/services/dataMessage.service");
require("./common/services/inheritController.service");
require("./common/services/localize.service");
require("./common/services/locationManager.service");
require("./common/services/queuedHttp.service");
require("./common/services/simpleDate.service");
require("./common/services/userAgent.service");
require("./common/services/csv.service");
require("./common/shareToGRC/shareToGRC.service");
require("./common/tabState/tabState.service");
require("./common/charts/chartDataSorter");
require("./common/i18n/dictionaries");
require("./common/controllers");
require("./common/directives");
require("./common/filters");

require("./modules/core/aggregationSelector/aggregationSelector.module");
require("./modules/core/locationSelector/locationSelector.module");
require("./modules/core/asyncCall/asyncCallManager.module");
require("./modules/core/archivePill/archivePill.module");
require("./modules/core/biView/biView.module");
require("./modules/core/highbondNavigation/highbondNavigation.module");
require("./modules/core/chartConfigPanel/chartConfigPanel.module");
require("./modules/core/checkboxFilter/checkboxFilter.module");
require("./modules/core/colorPicker/colorPicker.module");
require("./modules/core/conditionalCriteriaSelector/conditionalCriteriaSelector.module");
require("./modules/core/conditionalFormatting/conditionalFormatting.module");
require("./modules/core/condition/condition.module");
require("./modules/core/conditionalOperators/conditionalOperators.module");
require("./modules/core/chartColorPicker/chartColorPicker.module");
require("./modules/core/dataGrid/dataGrid.module");
require("./modules/core/dataRequester/dataRequester.module");
require("./modules/core/dataTableConfig/dataTableConfig.module");
require("./modules/core/dataTypeFunctions/dataTypeFunctions.module");
require("./modules/core/fieldFormatOptions/fieldFormatOptions.module");
require("./modules/core/fieldSort/fieldSort.module");
require("./modules/core/filterHacks/filterHacks.module");
require("./modules/core/filterPanel/filterPanel.module");
require("./modules/core/formatters/formatters.module");
require("./modules/core/globalNavHeader/globalNavHeader.module");
require("./modules/core/indicatorConfigPanel/indicatorConfigPanel.module");
require("./modules/core/input/input.module");
require("./modules/core/loadingAnimation/loadingAnimation.module");
require("./modules/core/metricApp/metricApp.module");
require("./modules/core/aclui/aclui.module");
require("./modules/core/actionButtons/actionButtons.module");
require("./modules/core/downloadXlsModal/downloadXlsModal.module");
require("./modules/core/multiSelect/multiSelect.module");
require("./modules/core/notification/notification.module");
require("./modules/core/quickMenu/quickMenu.module");
require("./modules/core/renameMetricPrompt/renameMetricPrompt.module");
require("./modules/core/slidingNavTabs/slidingNavTabs.module");
require("./modules/core/style/field/fieldStyle.module");
require("./modules/core/criteriaFilter/criteriaFilter.module");
require("./modules/core/valueFilter/valueFilter.module");
require("./modules/model/accessControl/accessControl.module");
require("./modules/model/color/color.module");
require("./modules/model/conditionalFormatting/conditionalFormatting.module");
require("./modules/model/field/field.module");
require("./modules/model/fieldValue/fieldValue.module");
require("./modules/model/filter/filter.module");
require("./modules/model/interpretation/interpretation.module");
require("./modules/model/metric/metric.module");
require("./modules/model/model/model.module");
require("./modules/model/summaryTable/summaryTable.module");
require("./modules/model/table/table.module");
require("./modules/model/tableConfig/tableConfig.module");
require("./modules/util/aclInput/aclInput.module");
require("./modules/util/aclUiDatepicker/aclUiDatpicker.module");
require("./modules/util/autoHeight/autoHeight.module");
require("./modules/util/postAndDownload/postAndDownload.module");
require("./modules/util/resizeListener/resizeListener.module");
require("./modules/util/tabSwitcher/tabSwitcher.module");
require("./modules/visualization/heatmap/heatmap.module");
require("./modules/visualization/indicator/indicator.module");
require("./modules/visualization/statistics/statistics.module");
require("./modules/visualization/statistics/statisticsDataConfig.module");
require("./modules/visualization/summaryTable/summaryTable.module");
require("./modules/visualization/summaryTable/summaryTableDataConfig.module");
require("./modules/visualization/highCharts/highCharts.module");
require("./modules/visualization/treemap/treemap.module");
require("./modules/visualization/treemap/treemapDataConfig.module");
require("./modules/visualization/combinationChart/combinationChart.module");
require("./modules/visualization/combinationChart/combinationChartDataConfig.module");
require("./modules/core/conditionalFormatting/conditionalFormatting.controller");
require("./modules/visualization/heatmap/heatmap.controller");
require("./modules/visualization/summaryTable/summaryTable.controller");
require("./modules/visualization/treemap/treemap.controller");
require("./modules/visualization/combinationChart/combinationChart.controller");
require("./modules/core/aggregationSelector/aggregationSelector.component");
require("./modules/core/locationSelector/locationSelector.component");
require("./modules/core/conditionalCriteriaSelector/conditionalCriteria.component");
require("./modules/core/dataGrid/dataGrid.component");
require("./modules/core/dataTableConfig/dataTableConfig.component");
require("./modules/core/dataTableRecordCount/dataTableRecordCount.component");
require("./modules/core/fieldFormatOptions/characterFormatOptions.component");
require("./modules/core/fieldFormatOptions/dateFormatOptions.component");
require("./modules/core/fieldFormatOptions/datetimeFormatOptions.component");
require("./modules/core/fieldFormatOptions/fieldFormatOptions.component");
require("./modules/core/fieldFormatOptions/numericFormatOptions.component");
require("./modules/core/fieldFormatOptions/timeFormatOptions.component");
require("./modules/core/fieldSort/fieldSort.component");
require("./modules/core/globalNavHeader/globalNavHeader.component");
require("./modules/core/indicatorConfigPanel/indicatorConfigPanel.component");
require("./modules/core/loadingAnimation/loadingModal.component");
require("./modules/core/metricApp/metricApp.component");
require("./modules/core/multiSelect/multiSelect.component");
require("./modules/core/multiSelect/selectedItem.component");
require("./modules/core/notification/notification.component");
require("./modules/core/quickMenu/quickMenu.component");
require("./modules/util/aclInput/aclInput.component");
require("./modules/util/aclUiDatepicker/aclUiDatepicker.component");
require("./modules/util/autoHeight/autoHeight.component");
require("./modules/visualization/indicator/indicator.directive");
require("./modules/visualization/summaryTable/summaryTableDataConfig.component");
require("./modules/visualization/treemap/treemapDataConfig.component");
require("./modules/visualization/combinationChart/combinationChartDataConfig.component");
require("./modules/core/biView/biView.directive");
require("./modules/core/chartConfigPanel/chartConfigPanel.component");
require("./modules/core/colorPicker/colorPicker.directive");
require("./modules/core/chartColorPicker/chartColorPicker.directive");
require("./modules/core/input/checkboxInput.directive");
require("./modules/core/input/checklistCheckboxInput.directive");
require("./modules/core/input/checklistInput.directive");
require("./modules/core/input/focusModel.directive");
require("./modules/core/input/numberInput.directive");
require("./modules/core/input/timeInput.directive");
require("./modules/core/multiSelect/popoverContent.directive");
require("./modules/core/multiSelect/popoverTrigger.directive");
require("./modules/util/tabSwitcher/tabSwitcher.directive");
require("./modules/util/resizeListener/resizeListener.directive");
require("./modules/visualization/statistics/statistics.directive");
require("./modules/core/asyncCall/asyncCallManager.service");
require("./modules/core/biView/biView.service");
require("./modules/core/chartConfigPanel/chartDisplayConfigAdapter.service");
require("./modules/core/chartConfigPanel/sectionValidator.service");
require("./modules/core/dataRequester/dataRequester.service");
require("./modules/core/fieldFormatOptions/fieldFormatter.service");
require("./modules/core/filterHacks/filterHacks.service");
require("./modules/core/highbondNavigation/highbondNavigation.service");
require("./modules/core/metricApp/promptSaveChangesConfirmer.service");
require("./modules/core/metricApp/unsavedChangesConfirmer.service");
require("./modules/core/deleteMetricConfirmer/deleteMetricConfirmer.service");
require("./modules/core/quickMenu/quickMenuToggler.service");
require("./modules/core/renameMetricPrompt/renameMetricPrompter.service");
require("./modules/core/sorters/sorter.module");
require("./modules/model/accessControl/accessChecker.service");
require("./modules/model/accessControl/accessControlAdapter.service");
require("./modules/model/accessControl/accessControlManager.service");
require("./modules/model/summaryTable/summaryTableDataGenerator.service");
require("./modules/visualization/heatmap/heatmap.service");
require("./modules/visualization/histogram/histogram.service");
require("./modules/visualization/statistics/statistics.service");
require("./modules/visualization/summaryTable/summaryTable.service");
require("./modules/visualization/treemap/treemap.service");
require("./modules/visualization/combinationChart/combinationChart.service");
require("./modules/model/accessControl/accessControl");
require("./modules/model/chart/chartBaseInterface.factory");
require("./modules/model/table/recordsetManager");
require("./modules/model/table/tableManager");
require("./modules/core/formatters/truncate.filter");
require("./modules/core/style/field/typeIconClassName.filter");

require("./modules/core/dataTablePanel/dataTablePanel.module");
require("./modules/core/dataTablePanel/dataTablePanel.component");
require("./modules/core/dataTablePanel/dataTablePanel.controller");
require("./modules/core/promiseCache/promiseCache.module");
require("./modules/core/promiseCache/promiseCache.service");

require("./modules/model/chart/chartData.module");
require("./modules/model/chart/chartDataManager");

require("./modules/core/temporalDiff/temporalDiff.module");

require("./modules/core/processPanel/processPanel.module");

require("./modules/core/observer/observer.module");

require("./modules/visualization/mapChart/mapChart.module");
require("./modules/visualization/mapChart/mapChart.controller");
require("./modules/visualization/mapChart/mapChart.service");
require("./modules/visualization/mapChart/mapChartDataConfig.module");
require("./modules/visualization/mapChart/mapChartDataConfig.component");

// download report side panel
require("./modules/core/downloadReport/downloadReport.module");

// Integrating $state, $q, $rootScope
require("./common/services/angularServiceEmitter");

angular.module("templates-visualizer", []);
