import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import EventService from "@viz-ui/services/eventService/eventService";

const quickMenuWidth = 707;
const edgePadding = 16;

function constrain(pos) {
  const windowWidth = $(window).width();
  const result = { ...pos };
  result.left = parseInt(result.left, 10);
  result.top = parseInt(result.top, 10);

  if (result.left < edgePadding) {
    result.left = edgePadding;
  }
  if (result.left > windowWidth - edgePadding - quickMenuWidth) {
    result.left = windowWidth - edgePadding - quickMenuWidth;
  }

  return result;
}

class QuickMenuToggler {
  constructor() {
    this.eventService = EventService.register("acl.visualizer.quickMenu.QuickMenuToggler");
  }

  toggle(menuPosition, fieldName, applyFilter) {
    UsageTracker.mark("quickMenu.toggle");
    this.eventService.publish("dataGrid.toggleQuickMenu", constrain(menuPosition), fieldName, applyFilter);
  }
}

export default new QuickMenuToggler();
