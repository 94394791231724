import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import uniq from "lodash/uniq";
import isNumber from "lodash/isNumber";
import i18n from "@viz-ui/i18n/i18n";
import { BAR_CHART } from "@visualizer/modules/visualization/highCharts/boost/boostThresholds";
import pubsub from "pubsub-js";

import ReactHighChart from "../highChart/reactHighChart";
import {
  canAnimate,
  updateHcConfig,
  updateLabelFontSize,
  getBoostConfig,
  clickWrapper,
  baseConfig,
  configPath,
} from "../services/highChartService";
import { reorderValues } from "../services/highChartDataSorter";

const stacking = {
  stack: "normal",
  expand: "percent",
  group: undefined,
};

class BarChart extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    config: PropTypes.object,
    rawData: PropTypes.array,
    redrawIndex: PropTypes.number,
    zoomInHandler: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    config: {},
    rawData: [],
    redrawIndex: 0,
    zoomInHandler: () => {},
  };

  constructor(props) {
    super(props);
    this.state = { hcConfig: {}, chartReDraw: false };
  }
  componentDidMount() {
    pubsub.subscribe("chartRedraw", () => {
      this.setState({ chartReDraw: !this.state.chartReDraw });
    });
    this.setState({ hcConfig: this.getConfig(this.props.config, this.props.rawData) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.config !== nextProps.config)
      this.setState({ hcConfig: this.getConfig(nextProps.config, nextProps.rawData) });
  }

  findPercentage = (xValue, data) => {
    const barSlice = data.find(d => d.xValue === xValue);
    return barSlice.yPercent;
  };

  getPercentage = (data, categories, multipleSeries) => {
    let keysTotal = [];
    if (multipleSeries) {
      categories.forEach(category => {
        let total = 0;
        data.forEach(d => {
          d.values.forEach(i => {
            if (i.key === category) {
              total += Math.abs(parseFloat(i.values === null ? 0 : i.values));
            }
          });
        });
        keysTotal.push({ key: category, total: total.toFixed(2) });
      });
      keysTotal.forEach(item => {
        data = data.map(d => ({
          ...d,
          values: d.values.map(i => {
            if (i.key === item.key) {
              let value = i.values === null ? 0 : i.values;
              value = value < 0 ? -1 * value : value;
              let percentage = item.total !== "0.00" ? (100 * value) / item.total : 0;
              percentage = percentage.toFixed(2);
              return { ...i, percentage };
            }
            return i;
          }),
        }));
      });
    } else {
      let total = 0;
      data[0].values.forEach(d => {
        total += Math.abs(parseFloat(d.values === null ? 0 : d.values));
      });
      data = data.map(d => ({
        ...d,
        values: d.values.map(i => {
          let value = i.values === null ? 0 : i.values;
          value = value < 0 ? -1 * value : value;
          let percentage = total !== "0.00" ? (100 * value) / total : 0;
          percentage = percentage.toFixed(2);
          return { ...i, percentage };
        }),
      }));
    }
    return data;
  };

  onLegendItemClick = item => {
    const showAll = !this.state.hcConfig.series.some(entry => entry.visible && entry.name !== item.name);
    const series = this.state.hcConfig.series.map(entry =>
      Object.assign({}, entry, {
        visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
      })
    );
    this.setState({
      hcConfig: updateHcConfig(this.state.hcConfig, { series }),
    });
  };

  onLegendItemDoubleClick = item => {
    const series = this.state.hcConfig.series.map(entry =>
      Object.assign({}, entry, { visible: entry.name === item.name })
    );
    this.setState({
      hcConfig: updateHcConfig(this.state.hcConfig, { series }),
    });
  };

  getConfig = (config, data) => {
    const { findPercentage } = this;
    data = reorderValues(data, config);
    if (stacking[config.chartStyle]) {
      data = data.reverse();
    }

    let categories = uniq(data[0].values.map(d => d.key));

    if (config.showPercentageTicks) {
      data = this.getPercentage(data, categories, config.chartSeries);
    }

    let hcConfig = {
      legend: {
        enabled: config.showLegend && config.hasMultipleSeries,
        showLegend: config.showLegend,
        displayDataLabels: config.displayDataLabels,
        showPercentageTicks: config.showPercentageTicks,
        align: "right",
        verticalAlign: "top",
        reversed: !!stacking[config.chartStyle],
        labelFormatter: function() {
          return config.labelFormatter && this.options.name !== "(blank)"
            ? config.labelFormatter(this.options.legendDisplayName)
            : this.options.legendDisplayName;
        },
      },
      boost: getBoostConfig(config, categories.length >= BAR_CHART),
      xAxis: {
        categories: categories,
        labels: {
          formatter: function() {
            return config.xAxis.tickFormatter(this.value);
          },
        },
        title: {
          text: config.xAxis.label,
        },
      },
      yAxis: {
        title: {
          text: config.yAxis.label,
        },
        labels: {
          formatter: function() {
            return config.chartStyle === "expand" ? `${this.value}%` : config.yAxis.tickFormatter(this.value);
          },
        },
        min: isNumber(config.yAxis.minimum) ? config.yAxis.minimum : undefined,
        max: isNumber(config.yAxis.maximum) ? config.yAxis.maximum : undefined,
        startOnTick: !isNumber(config.yAxis.minimum),
        endOnTick: !isNumber(config.yAxis.maximum),
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: config.displayDataLabels || config.showPercentageTicks,
            crop: false,
            formatter: function() {
              const showPercentage = config.showPercentageTicks;
              let percentage = "";
              let value = "";
              if (config.displayDataLabels) {
                value = config.yAxis.tickFormatter(this.y);
              }
              if (showPercentage) {
                percentage = `<br/>(${findPercentage(this.x, this.series.data)}%)`;
              }
              return `${value} ${percentage}`;
            },
          },
          stacking: stacking[config.chartStyle],
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              const header = config.hasMultipleSeries
                ? `
              <span style="text-decoration: underline grey;">${
                config.labelFormatter && this.series.options.name !== "(blank)"
                  ? config.labelFormatter(this.series.options.legendDisplayName)
                  : this.series.options.legendDisplayName
              }</span>
              <br/>
              `
                : "";
              let body = `
              <span>${config.xAxis.tickFormatter(this.xValue)}</span>
              <br/>
              <b>${config.yAxis.tickFormatter(this.y)}</b>
              `;
              if (config.showPercentageTicks) {
                body = `${body}
                 <br/>
                <b>(${this.yPercent}%)</b>
                `;
              }

              return header + body;
            },
          },
          point: {
            events: {
              click: clickWrapper(null, point => {
                this.zoomIn(config.hasMultipleSeries ? [point.series.name, point.xValue] : [point.xValue]);
              }),
            },
          },
          events: {
            legendItemClick: clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
          },
          turboThreshold: 0,
          animation: canAnimate(),
        },
      },

      series: data.map((series, index) => ({
        type: config.horizontal ? "bar" : "column",
        name: series.key,
        legendDisplayName: series.key === "(blank)" ? i18n.t("_Filter.BlankValue.Label_") : series.key,
        color: this.isPatternFill ? this.patternFillPalettes(index) : series.color,
        boostThreshold: BAR_CHART,
        visible: series.visible !== false,
        data: series.values.map(value => ({
          x: categories.indexOf(value.key),
          y: value.values,
          xValue: value.key,
          yPercent: value.percentage,
        })),
      })),
    };

    hcConfig = updateLabelFontSize(hcConfig, configPath);
    return Object.assign(hcConfig, baseConfig());
  };

  zoomIn = keyArray => {
    if (isFunction(this.props.zoomInHandler)) {
      this.props.zoomInHandler(keyArray);
    }
  };

  render() {
    return (
      <ReactHighChart
        className={`redraw-index-${this.props.redrawIndex}`}
        config={this.state.hcConfig}
        redrawIndex={this.props.redrawIndex}
        chartReDraw={this.state.chartReDraw}
      />
    );
  }
}
export default BarChart;
