import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import values from "lodash/values";
import intersection from "lodash/intersection";
import FieldTypeChecker from "@visualizer/modules/core/fieldTypeChecker/fieldTypeChecker.service";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";

/* jshint loopfunc: true */
angular
  .module("acl.visualizer.charts")
  .factory("ChartService", function($filter, AppConfig, ColorPalette, DataModel, EventService, Localize, UserAgent) {
    const HIGH_CHART_URL = "visualizer/js/modules/visualization/highCharts";
    const CHART_TYPES = [
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.BarChart.StackedColumn.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/barChart/barChart.tpl.html`,
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-chart-stacked-column",
        config: {
          displayConfig: {
            horizontal: false,
            chartStyle: "stack",
          },
        },
      },
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.BarChart.Stacked.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/barChart/barChart.tpl.html`,
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-chart-stacked",
        config: {
          displayConfig: {
            horizontal: true,
            chartStyle: "stack",
          },
        },
      },
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.BarChart.GroupedColumn.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/barChart/barChart.tpl.html`,
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-chart-grouped-column",
        config: {
          displayConfig: {
            horizontal: false,
            chartStyle: "group",
          },
        },
      },
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.BarChart.Grouped.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/barChart/barChart.tpl.html`,
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-chart-grouped",
        config: {
          displayConfig: {
            horizontal: true,
            chartStyle: "group",
          },
        },
      },
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.BarChart.100StackedColumn.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/barChart/barChart.tpl.html`,
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-chart-stacked-column-100",
        config: {
          displayConfig: {
            horizontal: false,
            chartStyle: "expand", //100% stacked
          },
        },
      },
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.BarChart.100Stacked.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/barChart/barChart.tpl.html`,
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-charts-stacked-100",
        config: {
          displayConfig: {
            horizontal: true,
            chartStyle: "expand", //100% stacked
          },
        },
      },
      {
        type: "LineChart",
        vizType: "LineChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.LineChart.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/lineChart/lineChart.tpl.html`,
        tabIcon: "line-chart",
        tabIndex: "",
        thumbnailIcon: "line-chart",
      },
      {
        type: "StackedAreaChart",
        vizType: "StackedAreaChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.AreaChart.Stacked.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/areaChart/areaChart.tpl.html`,
        tabIcon: "area-chart-stacked",
        tabIndex: "",
        thumbnailIcon: "area-chart-stacked",
        config: {
          displayConfig: {
            chartStyle: "stack",
          },
        },
      },
      {
        type: "StackedAreaChart",
        vizType: "StackedAreaChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.AreaChart.Standard.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/areaChart/areaChart.tpl.html`,
        tabIcon: "area-chart-stacked",
        tabIndex: "",
        thumbnailIcon: "area-chart-standard",
        config: {
          displayConfig: {
            chartStyle: "overlap", // standard
          },
        },
      },
      {
        type: "StackedAreaChart",
        vizType: "StackedAreaChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.AreaChart.Stacked100.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}areaChart/areaChart.tpl.html`,
        tabIcon: "area-chart-stacked",
        tabIndex: "",
        thumbnailIcon: "area-chart-stacked-100",
        config: {
          displayConfig: {
            chartStyle: "expand", //100% stacked
          },
        },
      },
      {
        type: "PieChart",
        vizType: "PieChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.PieChart.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/pieChart/pieChart.tpl.html`,
        tabIcon: "pie-chart",
        tabIndex: "",
        thumbnailIcon: "pie-chart",
        icon: "pie-chart",
      },
      {
        type: "BubbleChart",
        vizType: "BubbleChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.BubbleChart.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/bubbleChart/bubbleChart.tpl.html`,
        tabIcon: "bubble-chart",
        tabIndex: "",
        thumbnailIcon: "bubble-chart",
      },
      {
        type: "Heatmap",
        vizType: "Heatmap",
        tooltip: Localize.getLocalizedString("_ChartTypes.Heatmap.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/heatMap/heatMap.tpl.html`,
        tabIcon: "heatmap-chart",
        tabIndex: "",
        thumbnailIcon: "heatmap-chart",
      },
      {
        type: "SummaryTable",
        vizType: "SummaryTable",
        tooltip: Localize.getLocalizedString("_ChartTypes.SummaryTable.Tooltip_"),
        contentUrl: "visualizer/views/summaryTable.html",
        tabIcon: "summary-table-chart",
        tabIndex: "",
        thumbnailIcon: "summary-table-chart",
      },
      {
        type: "SummaryTable",
        vizType: "Treemap",
        tooltip: Localize.getLocalizedString("_ChartTypes.Treemap.Tooltip_"),
        contentUrl: "visualizer/js/modules/visualization/highCharts/treemap/treemap.tpl.html",
        tabIcon: "treemap-chart",
        tabIndex: "",
        thumbnailIcon: "treemap-chart",
      },
      {
        type: "StatisticsViz",
        vizType: "StatisticsViz",
        tooltip: Localize.getLocalizedString("_ChartTypes.StatisticsViz.Tooltip_"),
        contentUrl: "visualizer/views/statistics/statistics.html",
        tabIcon: "statistics-chart",
        tabIndex: "",
        thumbnailIcon: "statistics-chart",
      },
      {
        type: "SummaryTable",
        vizType: "CombinationChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.CombinationChart.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/combinationChart/combinationChart.tpl.html`,
        tabIcon: "combination-chart",
        tabIndex: "",
        thumbnailIcon: "combination-chart",
        disabled: !AppConfig.features.combinationChart,
      },
      {
        type: "MapChart",
        vizType: "MapChart",
        tooltip: Localize.getLocalizedString("_ChartTypes.MapChart.Tooltip_"),
        contentUrl: `${HIGH_CHART_URL}/mapChart/mapChart.tpl.html`,
        tabIcon: "map-chart",
        tabIndex: "",
        thumbnailIcon: "map-chart",
        disabled: !AppConfig.features.mapChart,
      },
    ];

    var AGGREGATION_TYPES = {
      average: {
        labelTemplate: Localize.getLocalizedString("_Chart.AverageAxisLabel.Template_"),
      },
      min: {
        labelTemplate: Localize.getLocalizedString("_Chart.MinAxisLabel.Template_"),
      },
      max: {
        labelTemplate: Localize.getLocalizedString("_Chart.MaxAxisLabel.Template_"),
      },
      sum: {
        labelTemplate: Localize.getLocalizedString("_Chart.SumAxisLabel.Template_"),
      },
      count: {
        labelTemplate: Localize.getLocalizedString("_Chart.CountAxisLabel.Template_"),
      },
    };

    const BUBBLE_SCALE_INFO = {
      "1": { minSize: "8", maxSize: "10%" }, //It's the default scaling values to align with the existing bubble charts
      "2": { minSize: "12", maxSize: "20%" },
      "3": { minSize: "16", maxSize: "30%" },
    };

    var globalChartElementsColorsTable = {};

    var eventService = EventService.register("charts");

    var chartService = {
      getAllChartTypes: function() {
        return CHART_TYPES;
      },

      getFieldLabel: function(field) {
        return field.aggregationType
          ? $filter("format")(AGGREGATION_TYPES[field.aggregationType].labelTemplate, field.displayName)
          : field.displayName;
      },

      getChartByType: function(type) {
        var chart = {};

        for (var iChart = 0; iChart < CHART_TYPES.length; iChart++) {
          if (CHART_TYPES[iChart].vizType === type) {
            chart = cloneDeep(CHART_TYPES[iChart]);
            break;
          }
        }

        return chart;
      },

      getDefaultChartTitle: function() {
        return "";
      },

      getDataItemLimit: function() {
        return AppConfig.data_item_limits[UserAgent.browser] || AppConfig.data_item_limits.default;
      },

      getFormatterForType: function(fieldType) {
        switch (fieldType) {
          case "date":
            return function(d) {
              const parsedValue = parseValue(d, fieldType);
              return d === null ? "" : moment.utc(parsedValue).format("YYYY-MM-DD");
            };

          case "datetime":
            return function(d) {
              const parsedValue = parseValue(d, fieldType);
              return d === null ? "" : moment.utc(parsedValue).format("YYYY-MM-DD HH:mm:ss");
            };

          case "time":
            return function(d) {
              return d === null ? "" : moment(d, "X").format("HH:mm:ss");
            };

          case "numeric":
            return function(d) {
              return d;
            };

          case "character":
          case "logical":
            return function(d) {
              return d === Localize.getLocalizedString("_Filter.BlankValue.Label_") ? "" : d;
            };

          default:
            throw new Error("Unknown data type: " + fieldType);
        }
      },

      getValueFormatter: function(interpretationId, fieldName, fieldType) {
        if (!fieldType) {
          return value => value;
        }

        return value => {
          let formatBlanks = true;
          let unformattedValue = parseValue(value, fieldType);
          let result;
          if (GlobalValueFormatter.isUrlFormatted(interpretationId, fieldName)) {
            result = GlobalValueFormatter.getUrlFormattedValue(unformattedValue);
          } else {
            result = GlobalValueFormatter.formatValue(unformattedValue, interpretationId, fieldName, formatBlanks);
          }
          return result;
        };
      },

      parseDataConfig: function(dataConfigWithConfigFieldNames, fieldObjsByConfigKey) {
        if (!dataConfigWithConfigFieldNames || !fieldObjsByConfigKey) return;

        // A "config key" is a string like "chartXAxis", "chartSeries", or "chartValues".

        const fieldObjConfigKeys = Object.keys(fieldObjsByConfigKey);
        const dataConfigConfigKeys = Object.keys(dataConfigWithConfigFieldNames);
        const definedConfigKeys = intersection(fieldObjConfigKeys, dataConfigConfigKeys);

        return definedConfigKeys.reduce((dataConfigWithConfigFieldObjs, configKey) => {
          const configFieldObj = getConfigFieldObj(dataConfigWithConfigFieldNames, configKey, fieldObjsByConfigKey);
          if (configFieldObj || (angular.isArray(configFieldObj) && !configFieldObj.includes(undefined))) {
            dataConfigWithConfigFieldObjs[configKey] = configFieldObj;
          }
          return dataConfigWithConfigFieldObjs;
        }, {});
      },

      parseValue,

      publishDrawComplete: function() {
        eventService.publish("charts.drawComplete");
      },

      getGlobalChartElementsColorsTable: function() {
        return globalChartElementsColorsTable;
      },

      setGlobalChartElementsColorsTableByKey: function(key, color) {
        if (key && color && globalChartElementsColorsTable[key] !== color) {
          globalChartElementsColorsTable[key] = color;
        }
      },

      // TODO: remove this when colour picker supports all colours and directly call it from each graph directive
      getKeyColor: function(colorByFieldName, colorMapping) {
        if (!colorMapping) colorMapping = DataModel.visualizationConfig.colorMapping() || {};
        let colorPalette = new ColorPalette();
        return function(data) {
          // TODO: this should be in the directive and passed into ColorPalette
          let key;
          if (data.rows) {
            key = data.rows[0][colorByFieldName];
          } else {
            let d = data.key ? data : data.data;
            key = d && d.key ? d.key : "";
          }
          let color = colorPalette.build(colorMapping, colorByFieldName, key);
          chartService.setGlobalChartElementsColorsTableByKey(colorByFieldName + key, color);
          return color;
        };
      },

      getYDomain: function(chartType, currConfig, data) {
        var maxsCollection = [];
        var min = currConfig.yAxis.minimum || 0;
        var max = 0;

        if (!currConfig.yAxis.maximum) {
          maxsCollection = getAxisMaxValuesByChartType(chartType, data);
          max = getChartMax(maxsCollection, chartType);
        } else {
          max = currConfig.yAxis.maximum;
        }

        return [min, max];
      },

      chartHasError: vizIndex => {
        const visualization = DataModel.visualization(vizIndex);
        const vizDataConfig = visualization && visualization.config && visualization.config.dataConfig;
        return vizDataConfig && chartService.dataConfigHasError(vizDataConfig);
      },

      dataConfigHasError: dataConfig => {
        const fields = DataModel.table.fields();
        const fieldsLoaded = values(fields).length > 0;
        const vizHasDeletedField = fieldsLoaded && !chartService.dataConfigFieldsExist(dataConfig);
        const vizHasDataTypeChanged = chartService.dataConfigHasFieldTypeMismatch(dataConfig);
        return vizHasDeletedField || vizHasDataTypeChanged;
      },

      dataConfigFieldsExist: dataConfig => DataModel.table.fieldsExist(getDataConfigFieldNames(dataConfig)),

      dataConfigHasFieldTypeMismatch: dataConfig => {
        const fields = DataModel.table.fields();
        const fieldTypeChecker = FieldTypeChecker.create().setFieldsFromMap(fields);
        return fieldTypeChecker.chartDataConfigHasFieldTypeMismatch(dataConfig);
      },

      isSortTabEnabledForType: chartType => {
        const SORT_TAB_CHART_TYPES = ["BarChart"];
        if (AppConfig.features.sortingForLineCharts) {
          SORT_TAB_CHART_TYPES.push("LineChart");
        }
        return SORT_TAB_CHART_TYPES.includes(chartType);
      },
      getLabelFormatter: (chartConfig, interpretationId) => {
        const result =
          chartConfig.dataConfig &&
          chartConfig.dataConfig.chartSeries &&
          chartConfig.dataConfig.chartSeries.type &&
          chartConfig.dataConfig.chartSeries.type === "logical"
            ? chartService.getValueFormatter(
                interpretationId,
                chartConfig.dataConfig.chartSeries.fieldName,
                chartConfig.dataConfig.chartSeries.type
              )
            : null;
        return result;
      },

      BubbleScaleInfo: {
        options: Object.keys(BUBBLE_SCALE_INFO),
        minSize: option => BUBBLE_SCALE_INFO[option].minSize,
        maxSize: option => BUBBLE_SCALE_INFO[option].maxSize,
      },
    };

    function getDataConfigFieldNames(dataConfig) {
      const fieldNames = [];
      for (var prop in dataConfig) {
        if (Object.prototype.hasOwnProperty.call(dataConfig, prop)) {
          // For some chart's dataconfig the configs are arrays like for
          // summary table and statistics. If it isn't an array make it one
          const configArray = Array.isArray(dataConfig[prop]) ? dataConfig[prop] : [dataConfig[prop]];

          configArray.forEach(config => {
            if (config && config.fieldName) {
              fieldNames.push(config.fieldName);
            }
          });
        }
      }
      return fieldNames;
    }

    function getConfigFieldObj(dataConfigWithConfigFieldNames, propName, chartConfigColumnDefs) {
      const propColumnDefs = chartConfigColumnDefs[propName];
      const fieldNameOrFieldNames = dataConfigWithConfigFieldNames[propName];
      if (angular.isArray(fieldNameOrFieldNames)) {
        return configFieldNamesToConfigFieldObjs(
          fieldNameOrFieldNames,
          dataConfigWithConfigFieldNames.aggregationTypes,
          propName,
          propColumnDefs
        );
      }
      return configFieldNameToConfigFieldObj(
        fieldNameOrFieldNames,
        dataConfigWithConfigFieldNames.aggregationType,
        propName,
        propColumnDefs
      );
    }

    function configFieldNamesToConfigFieldObjs(fieldNames, aggregationTypes, propName, propColumnDefs) {
      if (propName === "chartValues") {
        return fieldNames.reduce((accumulator, fieldName, index) => {
          const fieldNameToFieldObj = getFieldNameToFieldObjConverter(propColumnDefs, aggregationTypes[index]);
          const fieldObj = fieldNameToFieldObj(fieldName);
          if (fieldObj) {
            accumulator.push(fieldObj);
          }
          return accumulator;
        }, []);
      }
      return fieldNames.map(getFieldNameToFieldObjConverter(propColumnDefs));
    }

    function configFieldNameToConfigFieldObj(fieldName, aggregationType, propName, propColumnDefs) {
      if (propName === "chartValue") {
        const fieldNameToFieldObj = getFieldNameToFieldObjConverter(propColumnDefs, aggregationType);
        return fieldNameToFieldObj(fieldName);
      }
      return getFieldNameToFieldObjConverter(propColumnDefs)(fieldName);
    }

    function getFieldNameToFieldObjConverter(propColumnDefs, dataConfigAggregationType) {
      return fieldName => {
        if (dataConfigAggregationType === "count") {
          return { type: "numeric", aggregationType: "count" };
        }

        if (fieldName === undefined) {
          return undefined;
        }

        let fieldObj = propColumnDefs.find(field => field.fieldName === fieldName);
        if (fieldObj) {
          fieldObj = cloneDeep(fieldObj);
          if (dataConfigAggregationType) {
            fieldObj.aggregationType = dataConfigAggregationType;
          }
        }
        return fieldObj;
      };
    }

    function parseValue(value, fieldType) {
      switch (fieldType) {
        case "datetime":
          return value ? moment.unix(value).toDate() : null;

        case "date":
          return value
            ? moment
                .unix(value)
                .utc()
                .format("YYYY-MM-DD")
            : null;

        case "time":
          return value ? moment.unix(value).format("HH:mm:ss") : null;

        case "numeric":
        case "character":
        case "logical":
          return value;

        default:
          throw new Error("Unknown data type: " + fieldType);
      }
    }

    function getAxisMaxValuesByChartType(chartType, data) {
      var maxsCollection = [];
      var key = chartType === "LineChart" ? "y" : "values";
      for (var i in data) {
        var m = Math.max.apply(
          null,
          data[i].values.map(function(item) {
            return item[key];
          })
        );
        maxsCollection.push(m);
      }
      return maxsCollection;
    }

    function getChartMax(maxsCollection, chartType) {
      var max = 0;
      switch (chartType) {
        case "stackedAreaChart":
          for (var ind in maxsCollection) {
            max += maxsCollection[ind];
          }
          break;
        default:
          max = Math.max.apply(null, maxsCollection);
      }
      return max;
    }

    return chartService;
  });
