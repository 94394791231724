import React from "react";
import PropTypes from "prop-types";
import Modal from "@paprika/modal";
import i18n from "@viz-ui/i18n/i18n";
import Button from "@paprika/button";
import Spinner from "acl-ui/components/Spinner";
import Styler from "@paprika/stylers";
import ExclamationCircle from "@acl-services/wasabicons/lib/ExclamationCircle";
import "./DownloadReportModal.scss";

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  reportGeneratedDetails: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
  isOpen: false,
  reportGeneratedDetails: undefined,
};

const getModalContent = reportGeneratedDetails => {
  if (reportGeneratedDetails) {
    if (reportGeneratedDetails.reportStatus) {
      return (
        <div className="download-report-modal__generated-content">
          {i18n.t("_ReportDownloadModal.reportGeneratedContent.Label_")}
        </div>
      );
    }
    return (
      <div className="download-report-modal__generated-content-error">
        <div className="download-report-modal__generated-content-icon">
          <ExclamationCircle size={Styler.spacer(3.5)} />
        </div>
        <div className="download-report-modal__generated-content-text">
          <b>{i18n.t("_ReportDownloadModal.failedToGenerateReportContentHeader.Label_")}</b>
          <div>{i18n.t("_ReportDownloadModal.failedToGenerateReportContentText.Label_")}</div>
        </div>
      </div>
    );
  }
  return (
    <div className="download-report-modal__loading-content">
      <div className="download-report-modal__loading-content-spinner">
        <Spinner />
      </div>
      <div className="download-report-modal__loading-content-text">
        {i18n.t("_ReportDownloadModal.loadingContent.Label_")}
      </div>
    </div>
  );
};

export default function DownloadReportModal(props) {
  const { isOpen, onClose, reportGeneratedDetails } = props;
  const isReportReady = reportGeneratedDetails && reportGeneratedDetails.reportStatus;
  const refHeading = React.useRef(null);
  const modalTitle = isReportReady
    ? i18n.t("_ActionButtons.ReportDownload.Label_")
    : i18n.t("_ReportDownloadModal.loadingHeading.Label_");
  const modalContent = getModalContent(reportGeneratedDetails);
  const handleFocus = () => {
    if (refHeading.current) refHeading.current.focus();
  };
  const cancelButtonText =
    reportGeneratedDetails && !reportGeneratedDetails.reportStatus
      ? i18n.t("_ReportDownloadPanel.Close.Label_")
      : i18n.t("_ReportDownloadPanel.Cancel.Label_");

  // checking isOpen is false, then to return an empty div
  if (!isOpen) {
    return null;
  }
  return (
    <Modal
      a11yText={modalTitle}
      className="download-report-modal"
      isOpen={isOpen}
      onClose={() => onClose}
      onAfterOpen={() => handleFocus}
      size="small"
      zIndex={220}
    >
      <Modal.Header refHeading={refHeading} level={2} hasCloseButton={false}>
        {modalTitle}
      </Modal.Header>
      <Modal.Content>{modalContent}</Modal.Content>
      <Modal.Footer>
        <Button
          kind="Button.types.kind.PRIMARY"
          className="primary"
          onClick={() => {
            window.open(reportGeneratedDetails.s3SignedUrl, "_blank");
            onClose();
          }}
          isDisabled={!isReportReady}
        >
          {i18n.t("_ReportDownloadModal.downloadButton.Label_")}
        </Button>
        <Button className="cancel" kind="minor" onClick={onClose}>
          {cancelButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DownloadReportModal.propTypes = propTypes;
DownloadReportModal.defaultProps = defaultProps;
