import isEqual from "lodash/isEqual";
import Model from "../model";
import Condition from "./condition";

class ConditionalFormat extends Model {
  static modelType = "ConditionalFormat";

  constructor(data) {
    super({ condition: new Condition(), ...data });
  }

  condition(...args) {
    return this.accessor("condition", args, Condition);
  }

  textColor(...args) {
    return this.accessor("textColor", args, "color");
  }

  textColorEnabled(...args) {
    return this.accessor("textColorEnabled", args, "boolean");
  }

  backgroundColor(...args) {
    return this.accessor("backgroundColor", args, "color");
  }

  backgroundColorEnabled(...args) {
    return this.accessor("backgroundColorEnabled", args, "boolean");
  }

  icon(...args) {
    return this.accessor("icon", args, "string");
  }

  iconColor(...args) {
    return this.accessor("iconColor", args, "color");
  }

  iconEnabled(...args) {
    return this.accessor("iconEnabled", args, "boolean");
  }

  equals(other) {
    return other && isEqual(this._data, other._data);
  }

  static fromJson(json) {
    return new ConditionalFormat()
      .condition(deserializeCondition(json))
      .textColor(deserializeColor(json.textColor))
      .textColorEnabled(json.textColorEnabled)
      .backgroundColor(deserializeColor(json.backgroundColor))
      .backgroundColorEnabled(json.backgroundColorEnabled)
      .icon(json.icon)
      .iconColor(deserializeColor(json.iconColor))
      .iconEnabled(json.iconEnabled);
  }

  toJson() {
    const condition = this.condition();
    return this._deleteUndefinedProperties({
      textColor: this.textColor(),
      textColorEnabled: this.textColorEnabled(),
      backgroundColor: this.backgroundColor(),
      backgroundColorEnabled: this.backgroundColorEnabled(),
      icon: this.icon(),
      iconColor: this.iconColor(),
      iconEnabled: this.iconEnabled(),
      operator: condition.operator(),
      values: condition.values(),
    });
  }

  static jsonToConditionalFormatMap(json) {
    const result = new Map();
    Object.keys(json).forEach(fieldName => {
      if (json[fieldName].conditions) {
        result.set(
          fieldName,
          json[fieldName].conditions.map(conditionalFormatData => ConditionalFormat.fromJson(conditionalFormatData))
        );
      }
    });
    return result;
  }

  static conditionalFormatMapToJson(conditionalFormatModelsMap) {
    const result = {};
    conditionalFormatModelsMap.forEach((_, fieldName) => {
      if (!result[fieldName]) {
        result[fieldName] = {};
      }

      result[fieldName].conditions = conditionalFormatModelsMap
        .get(fieldName)
        .map(conditionalFormatModel => conditionalFormatModel.toJson());
    });
    return result;
  }

  clone() {
    return new ConditionalFormat(this._data);
  }
}

function deserializeCondition(conditionData) {
  return new Condition().operator(conditionData.operator).values(conditionData.values);
}

function deserializeColor(color) {
  if (typeof color === "undefined" || color === null) {
    return color;
  }
  if (color.match(/^#[0-9a-fA-F]{3}([0-9a-fA-F]{3})?$/)) {
    return color;
  }

  const rgbMatches = color.match(/^rgba?\( *(\d{1,3}) *, *(\d{1,3}) *, *(\d{1,3}) *(,.*)?\)$/);
  if (rgbMatches && rgbMatches.length > 3) {
    let r = parseInt(rgbMatches[1], 10).toString(16);
    if (r.length < 2) r = `0${r}`;
    let g = parseInt(rgbMatches[2], 10).toString(16);
    if (g.length < 2) g = `0${g}`;
    let b = parseInt(rgbMatches[3], 10).toString(16);
    if (b.length < 2) b = `0${b}`;
    return `#${r}${g}${b}`;
  }
  throw new Error(`Unexpected color format. color: ${color}`);
}
export default ConditionalFormat;
