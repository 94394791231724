import Reveal from "reveal.js/js/reveal";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";

const presenter = {
  bindings: {
    boardsRow: "<",
    boardModels: "<",
    storyboardModel: "<",
    globalFilters: "<",
    onLoadMoreData: "&",
  },
  controller: PresenterController,
  controllerAs: "presenter",
  template: `
    <div class="reveal">
        <keyboard-shortcut class="keyboardButton" re-cb-on-click="presenter.toggleKeyboardHelp()"></keyboard-shortcut>   
        <div class="slides">
        <section class="center cover-slide">
          <div class="storyboard-title">
            {{presenter.storyboardTitle}}
          </div>
        </section>
        <section ng-repeat="board in presenter.flattenedBoards" class="{{board.type}} displayShapes" data-board-id={{board.id}}>
          <acl-storyboard-board
            ng-if="presenter.isBoardVisible(board)"
            board="board"
            board-model="presenter.boardModels[board.id]"
            can-drilldown="false"
            edit-mode="false"
            font-zoom-ratio="presenter.zoomRatio"
            global-filters="presenter.globalFilters"
            on-load-more-data="presenter.handleLoadMoredata(board)"
            presentation-mode="true"
            storyboard-model="presenter.storyboardModel"
          ></acl-storyboard-board>
        </section>
      </div>
    </div>
  `,
  restrict: "E",
};

function PresenterController($element, $scope, $timeout) {
  "ngInject";
  const self = this;

  const RENDERED_BOARD_BUFFER = 5;

  let boardsToRender = [];
  let isUsingTransformScale = false;
  let isOverviewMode = false;
  let currentSlideIndex = 0;

  $scope.$watch(getZoomRatio, zoomRatio => updateBoardFontZoomRatio(zoomRatio));

  self.zoomRatio = 1;

  self.$onChanges = changesObj => {
    if (changesObj.boardsRow) {
      self.flattenedBoards = changesObj.boardsRow.currentValue.reduce(
        (flattenedBoards, currBoards) => flattenedBoards.concat(currBoards.boards),
        []
      );
    }
    if (changesObj.storyboardModel) {
      self.storyboardTitle = changesObj.storyboardModel.currentValue.getName();
    }
    initReveal();
  };

  self.isBoardVisible = board => boardsToRender.includes(board.id);

  self.handleLoadMoredata = board => {
    self.onLoadMoreData({ board: board });
  };

  self.toggleKeyboardHelp = () => {
    Reveal.toggleHelp();
  };

  function initReveal() {
    window.Reveal = Reveal; // expose for post-init configuration
    $timeout(() => {
      if (!Reveal.getCurrentSlide()) {
        Reveal.initialize({
          controlsTutorial: false,
          dependencies: [],
        });
        Reveal.addEventListener("ready", () => {
          addNoZoomOrTransformClass();
          updateBoardsToShow(0);
          Reveal.slide(0);
          UsageTracker.createEvent(`.loaded`);
        });

        Reveal.addEventListener("slidechanged", event => {
          trackSlideChanged(event.indexh);
          updateBoardsToShow(event.indexh);
          currentSlideIndex = event.indexh;
        });
        Reveal.addEventListener("overviewshown", () => {
          isOverviewMode = true;
          trackOverviewChanged();
          $scope.$apply();
        });
        Reveal.addEventListener("overviewhidden", () => {
          isOverviewMode = false;
          trackOverviewChanged();
          $scope.$apply();
        });

        document.addEventListener("keydown", event => {
          if (event.ctrlKey && event.keyCode === 191) {
            Reveal.toggleHelp();
          }
        });
      }
    });
  }

  function addNoZoomOrTransformClass() {
    if (isUsingTransformScale) {
      $element.find(".slides").addClass("no-transform-scale");
      $element.find(".slides").removeClass("no-zoom");
    } else {
      $element.find(".slides").addClass("no-zoom");
      $element.find(".slides").removeClass("no-transform-scale");
    }
  }

  function updateBoardFontZoomRatio(zoomRatio) {
    if (zoomRatio) {
      self.zoomRatio = zoomRatio;
    }
  }

  function getZoomRatio() {
    if (isOverviewMode) return;

    let elementStyle = $element.find(".slides").attr("style");
    if (elementStyle) {
      let elementStyleList = elementStyle.split(";");
      let transformCss = elementStyleList.find(cssStyle => cssStyle.indexOf("transform:") > -1);
      let zoomCss = elementStyleList.find(cssStyle => cssStyle.indexOf("zoom:") > -1);
      if (transformCss) {
        isUsingTransformScale = true;
        return Number.parseFloat(transformCss.match(/scale\((.*)\)$/)[1]);
      }
      if (zoomCss) {
        isUsingTransformScale = false;
        return Number.parseFloat(zoomCss.split(":")[1].trim());
      }
    }
  }

  function updateBoardsToShow(currentIndex) {
    boardsToRender = [];
    let startBoardIndex = Math.max(currentIndex - RENDERED_BOARD_BUFFER, 0);
    let endBoardIndex = Math.min(currentIndex + RENDERED_BOARD_BUFFER, self.flattenedBoards.length);
    boardsToRender = self.flattenedBoards.slice(startBoardIndex, endBoardIndex).map(board => board.id);
    $scope.$apply();
  }

  function trackOverviewChanged() {
    let overviewState = isOverviewMode ? ".opened" : ".exited";
    UsageTracker.createEvent(`.overview${overviewState}`);
  }

  function trackSlideChanged(slideIndex) {
    let direction = getDirection(slideIndex);
    let overviewFragment = isOverviewMode ? ".overview" : "";
    if (direction) {
      UsageTracker.createEvent(`${overviewFragment}${direction}`);
    }
  }

  function getDirection(slideIndex) {
    switch (slideIndex - currentSlideIndex) {
      case 1:
        return ".forward";
      case -1:
        return ".backward";
    }
  }
}

export default presenter;
