import isString from "lodash/isString";
import Model from "@viz-ui/models/model";

const RECOVERABLE_ERRORS = [
  "InterpretationFieldNotExist",
  "InterpretationFilterConfigFieldMissing",
  "InterpretationFilterTypeChanged",
  "VisualizationNoData",
  "VisualizationInvalid",
  "VisualizationFieldNotExist",
  "VisualizationFilterConfigFieldMissing",
  "VisualizationDataConfigFieldMissing",
  "VisualizationFieldTypeChanged",
  "MetricFieldDeleted",
  "MetricFieldNotExist",
  "MetricFilterConfigFieldMissing",
  "MetricConfigFieldMissing",
];

const LOCALIZE_KEY_MAPPER = {
  InterpretationDeleted: "_Storyboards.Interpretation.NotFound_",
  InterpretationFieldNotExist: "_Storyboards.Interpretation.FieldNotExist_",
  InterpretationError: "_Storyboards.Interpretation.Error_",
  InterpretationFilterConfigFieldMissing: "_Storyboards.Interpretation.FilterConfigFieldMissing_",
  InterpretationFilterTypeChanged: "_Storyboards.Interpretation.FieldTypeChanged_",
  VisualizationDeleted: "_Storyboards.Visualization.NotAvailable_",
  VisualizationNoData: "_Storyboards.Visualization.NoData_",
  VisualizationInvalid: "_Storyboards.Visualization.Invalid_",
  VisualizationFieldNotExist: "_Storyboards.Visualization.FieldNotExist_",
  VisualizationError: "_Storyboards.Visualization.Error_",
  VisualizationFilterConfigFieldMissing: "_Storyboards.Visualization.FilterConfigFieldMissing_",
  VisualizationDataConfigFieldMissing: "_Storyboards.Visualization.DataConfigFieldMissing_",
  VisualizationFieldTypeChanged: "_Storyboards.Visualization.FieldTypeChanged_",
  MetricDeleted: "_Storyboards.Metric.NotAvailable_",
  MetricNoPermission: "_Storyboards.Metric.NoPermission_",
  MetricFieldDeleted: "_Storyboards.Metric.fieldDeleted_",
  MetricFieldNotExist: "_Storyboards.Metric.fieldDeleted_",
  MetricError: "_Storyboards.Metric.Error_",
  MetricFilterConfigFieldMissing: "_Storyboards.Metric.FilterConfigFieldMissing_",
  MetricConfigFieldMissing: "_Storyboards.Metric.ConfigFieldMissing_",
};

const INTERPRETATION_ERROR = {
  INTERPRETATION_DELETED: "InterpretationDeleted",
  FieldNotExist: "InterpretationFieldNotExist",
  FILTER_CONFIG_FIELD_MISSING: "InterpretationFilterConfigFieldMissing",
  FilterTypeChanged: "InterpretationFilterTypeChanged",
  ERROR: "InterpretationError",
};

const VISUALIZATION_ERROR = {
  VISUALIZATION_DELETED: "VisualizationDeleted",
  INTERPRETATION_DELETED: "InterpretationDeleted",
  VISUALIZATION_NO_DATA: "VisualizationNoData",
  VISUALIZATION_INVALID: "VisualizationInvalid",
  FieldNotExist: "VisualizationFieldNotExist",
  ERROR: "VisualizationError",
  FILTER_CONFIG_FIELD_MISSING: "VisualizationFilterConfigFieldMissing",
  FieldTypeChanged: "VisualizationFieldTypeChanged",
  DATA_CONFIG_FIELD_MISSING: "VisualizationDataConfigFieldMissing",
};

const METRIC_ERROR = {
  404: "MetricDeleted",
  403: "MetricNoPermission",
  METRIC_FIELD_DELETED: "MetricFieldDeleted",
  FieldNotExist: "MetricFieldNotExist",
  FILTER_CONFIG_FIELD_MISSING: "MetricFilterConfigFieldMissing",
  ERROR: "MetricError",
};

export default class BoardError extends Model {
  static modelType = "BoardError";
  constructor(data) {
    super(data);
  }

  static RECOVERABLE_ERRORS = RECOVERABLE_ERRORS;

  boardType(...args) {
    return this.accessor("boardType", args, "string");
  }

  error(...args) {
    return this.accessor("error", args, "string");
  }

  originalError(...args) {
    return this.accessor("originalError", args, "string");
  }

  setExceptionHandler(handlerFn) {
    this._exceptionHandler = handlerFn;
    return this;
  }

  setErrorFromCode(errorCode = "ERROR") {
    const boardType = this.boardType();
    const ERROR_MAP = this.getErrorMap(boardType);
    const errorCodeString = isString(errorCode) ? errorCode : JSON.stringify(errorCode);
    const boardErrorCode = ERROR_MAP[errorCodeString] || ERROR_MAP.ERROR;
    this.originalError(errorCodeString);
    this.error(boardErrorCode);

    return this;
  }

  log(logginInfo) {
    if (this.isKnownError()) {
      console.info(
        `Board Error - error: ${this.originalError()}, type: ${this.boardType()}, info: ${JSON.stringify(logginInfo)}`
      );
    } else {
      const originalError = this.originalError();
      this._exceptionHandler(originalError);
    }
  }

  isKnownError() {
    const boardType = this.boardType();
    const originalError = this.originalError();
    const ERROR_MAP = this.getErrorMap(boardType);
    return ERROR_MAP && !!ERROR_MAP[originalError];
  }

  getErrorMap(boardType) {
    switch (boardType) {
      case "visualization":
        return VISUALIZATION_ERROR;
      case "table":
        return INTERPRETATION_ERROR;
      case "metric":
        return METRIC_ERROR;
    }
  }

  localizeKey() {
    return LOCALIZE_KEY_MAPPER[this._data.error];
  }

  isRecoverable() {
    return RECOVERABLE_ERRORS.includes(this._data.error);
  }
}
