import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";
import MetricTypes from "@viz-ui/services/metrics/metricTypesService";
import MetricTimePeriods from "@viz-ui/services/metrics/metricTimePeriodsService";

angular
  .module("acl.visualizer.indicatorConfigPanel")
  .directive("indicatorConfigPanel", function(AggregatorArgs, AppConfig, MetricConfig) {
    return {
      restrict: "E",
      replace: true,
      controllerAs: "panel",
      scope: {
        canManageTriggers: "<",
        fields: "<",
        functionPreview: "<",
        metricModel: "<",
        onClose: "&",
        onFieldFormatChange: "&",
        onFormChange: "&",
        onOpenConditionalFormat: "&",
        openTriggersDialog: "&",
        selectedFieldName: "=",
        sparklineModel: "<",
        tableModel: "<",
        timeFields: "<",
        locale: "<",
      },
      bindToController: true,
      templateUrl: "visualizer/js/modules/core/indicatorConfigPanel/indicatorConfigPanel.tpl.html",
      controller: function($scope, $timeout, DataTypeFunctions, Localize) {
        var self = this;
        self.config = {};
        self.showIndicatorConfigForm = false;
        self.canViewTriggersButton = AppConfig.features.metricTriggers;
        self.metricIndicatorDataLoaded = false;
        $scope.$watch("panel.metricModel", function(newValue) {
          $scope.indicatorConfigForm.$setPristine();
          if (newValue) {
            self.loadMetric(newValue);
            updateFieldFormatOptionsProps();
          } else {
            self.config = {
              type: self.metricTypes[0].fieldName,
              timePeriod: self.timePeriods[0].fieldName,
            };
          }
        });

        $scope.$watch("panel.sparklineModel", function(newValue) {
          if (newValue.toJson().valueType) {
            self.metricIndicatorDataLoaded = true;
          }
        });

        // FIXME: What is this for? Can't it just use `fieldName`?
        $scope.$watch("panel.selectedFieldName", function(newValue) {
          if (self.config.fieldName !== newValue) {
            self.config.fieldName = newValue;
            $scope.indicatorConfigForm.$setDirty();
          }
        });

        // watched because color-picker does not update model before ng-change
        $scope.$watch("panel.config.baseColor", function(newValue, oldValue) {
          if (newValue !== oldValue) {
            $scope.indicatorConfigForm.$setDirty();
            self.handleDisplayConfigChange();
          }
        });

        self.colorPickerDefaults = {
          showPaletteOnly: true,
        };

        self.toggleViewThresholds = function() {
          self.config.viewThresholds = !self.config.viewThresholds;
          $scope.indicatorConfigForm.$setDirty();
          self.handleDataConfigChange();
        };

        self.toggleShowSparkline = function() {
          self.config.showSparkline = !self.config.showSparkline;
          $scope.indicatorConfigForm.$setDirty();
          self.handleDisplayConfigChange();
        };

        self.getFunctionPreviewValue = function(functionName) {
          if (self.functionPreview && self.functionPreview[functionName]) {
            const metricId = self.metricModel.id();
            const { fieldName } = self.config;
            const value = self.functionPreview[functionName]();
            const valueType = GlobalFieldFormatMap.getFieldType(metricId, fieldName);
            const fieldFormat = GlobalFieldFormatMap.getFieldFormat(metricId, fieldName);
            const fieldFormatJson = fieldFormat && fieldFormat.toJson();
            const utcOffset = GlobalValueFormatter.getTimezoneOffsetForMetric(self.tableModel, self.metricModel);
            const utcOffsetMinutes = utcOffset ? utcOffset.utcOffsetMinutes() : 0;
            return DataTypeFunctions.formatTypedMetricValueByFunctionWithFormatting(
              functionName,
              value,
              valueType,
              fieldFormatJson,
              utcOffsetMinutes
            );
          }
          return null;
        };

        self.getFunctionDataTypeByFieldName = function(functionName, fieldName) {
          var type;
          if (fieldName) {
            type = self.getTypeByFieldName(fieldName);
            return DataTypeFunctions.getFunctionDataType(functionName, type);
          }
          return null;
        };

        self.getFieldDisplayName = function(field) {
          return field.displayName();
        };

        self.getPeriodDisplayName = function(period) {
          return period.displayName;
        };

        self.getTypeDisplayName = function(type) {
          return type.displayName;
        };

        self.metricTypes = MetricTypes.getMetricTypes();

        self.timePeriods = MetricTimePeriods.getMetricTimePeriods();

        self.loadMetric = function(metric) {
          self.showIndicatorConfigForm = true;
          var metricConfig = metric.metricConfig();
          var dataType = self.getFunctionDataTypeByFieldName(self.config.function, self.config.fieldName);

          this.metricData = metric;

          self.config = self.config || {};
          self.config.fieldName = metricConfig.fieldName();
          self.config.timeFieldName = metricConfig.timeFieldName();
          self.config.timePeriod = metricConfig.interval() ? metricConfig.interval() : self.timePeriods[1].fieldName;
          self.config.function = metricConfig.func();
          self.config.functionPercentOf = serializeAggregatorArgs(
            metricConfig.aggregatorArgs(),
            metricConfig.func(),
            dataType
          );
          self.config.type = metricConfig.type() ? metricConfig.type() : self.metricTypes[0].fieldName;
          self.config.viewThresholds =
            metricConfig.viewThresholds() === undefined ? true : metricConfig.viewThresholds();
          self.config.showSparkline = metricConfig.showSparkline() === undefined ? true : metricConfig.showSparkline();
          self.config.id = metric.id();
          self.config.baseColor = metricConfig.baseColor() || "#3F3D3C";

          if (self.config.id) {
            $scope.indicatorConfigForm.$setDirty();
          }
        };

        self.getTypeByFieldName = function(fieldName) {
          var field = self.getFieldByFieldName(fieldName);
          return field ? field.type() : null;
        };

        self.getFieldByFieldName = function(fieldName) {
          return self.fields.filter(function(item) {
            return item.name() === fieldName;
          })[0];
        };

        self.getTimeFieldByFieldName = function(fieldName) {
          return self.timeFields.filter(function(item) {
            return item.name() === fieldName;
          })[0];
        };

        self.getFunctionsByFieldName = function(fieldName) {
          var filtered = self.getFieldByFieldName(fieldName);
          return filtered && filtered.type() ? DataTypeFunctions.getFunctionsByDataType(filtered.type().trim()) : null;
        };

        self.getFieldDisplayNameByFieldName = function(fields, val) {
          var filtered;
          if (fields && fields.length) {
            filtered = fields.filter(function(item) {
              return item.name() === val;
            });
            return filtered.length && filtered[0].displayName() ? filtered[0].displayName().trim() : "";
          }
          return "";
        };

        self.fieldNameChanged = function() {
          $scope.panel.selectedFieldName = self.config.fieldName;

          ensureTimeFieldSelected();
          ensureFunctionSelected();

          updateFieldFormatOptionsProps();

          waitForFormValidationThenCall(function() {
            self.handleDataConfigChange();
          });
        };

        self.fieldFormatOptionsProps = {
          linkLabel: Localize.getLocalizedString("_MetricsConfig.FormatOptions.Label_"),
          onFieldFormatChange: function(field, fieldFormat) {
            self.onFieldFormatChange({ field: field, fieldFormat: fieldFormat });
          },
          onConditionalFormattingClick: function(field) {
            self.onOpenConditionalFormat({ field: field });
          },
        };

        $scope.$watchGroup(["panel.fields", "panel.config.fieldName"], function() {
          if (self.fields && self.fields.length && self.config.fieldName) {
            updateFieldFormatOptionsProps();
          }
        });

        function updateFieldFormatOptionsProps() {
          var { fieldName } = self.config;
          if (self.config.fieldName) {
            self.fieldFormatOptionsProps.field = self.getFieldByFieldName(fieldName);
          }
          if (self.metricModel) {
            self.fieldFormatOptionsProps.fieldFormat = self.metricModel.fieldFormatByFieldName(fieldName);
          }
        }

        function waitForFormValidationThenCall(callback) {
          $timeout(callback);
        }

        self.handleDisplayConfigChange = function() {
          dataChanged({ reloadData: false });
        };

        self.handleDataConfigChange = function() {
          dataChanged();
        };

        function dataChanged(options = {}) {
          var form;
          var metricConfig;

          metricConfig = self.metricModel ? self.metricModel.metricConfig().clone() : new MetricConfig();
          metricConfig = getMetricConfigWithFormModel(metricConfig, self.config);

          if (self.onFormChange) {
            form = $scope.indicatorConfigForm;
            self.onFormChange({
              metricConfig: metricConfig,
              valid: form.$valid,
              dirty: form.$dirty,
              reloadData: options.reloadData,
            });
          }
        }

        function ensureTimeFieldSelected() {
          if (self.config.fieldName && !self.config.timeFieldName && self.timeFields && self.timeFields.length) {
            self.config.timeFieldName = self.timeFields[0].name();
          }
        }

        function ensureFunctionSelected() {
          var fieldType;
          if (self.config.fieldName) {
            fieldType = self.getTypeByFieldName(self.config.fieldName);
            if (
              !self.config.function ||
              !DataTypeFunctions.canFunctionAcceptDataType(self.config.function, fieldType)
            ) {
              selectFirstFunction();
            }
          }
        }

        function selectFirstFunction() {
          var functions = self.getFunctionsByFieldName(self.config.fieldName);
          if (functions) {
            self.config.function = functions[0].fieldName;
          }
        }

        function serializeAggregatorArgs(args, func, type) {
          if (args && func === "percent-of") {
            return {
              operator: args.operator(),
              values: serializeValues(args.values(), args.operator(), type),
            };
          }
          return undefined;
        }

        function serializeValues(values, operator) {
          if (values) {
            switch (operator) {
              case "between":
              case "not-between":
                return {
                  0: values[0],
                  1: values[1],
                };

              default:
                return {
                  0: values[0],
                };
            }
          }
          return undefined;
        }
      },
    };

    function getMetricConfigWithFormModel(metricConfig, model) {
      return metricConfig
        .clone()
        .func(model.function)
        .aggregatorArgs(deserializeAggregatorArgs(model.functionPercentOf, model.function))
        .interval(model.timePeriod)
        .fieldName(model.fieldName)
        .timeFieldName(model.timeFieldName)
        .viewThresholds(model.viewThresholds)
        .showSparkline(model.showSparkline)
        .baseColor(model.baseColor)
        .type(model.type);
    }

    function deserializeAggregatorArgs(argsModel, func) {
      if (argsModel && func === "percent-of") {
        return new AggregatorArgs()
          .operator(argsModel.operator)
          .values(deserializeValues(argsModel.values, argsModel.operator));
      }
      return undefined;
    }

    function deserializeValues(values, operator) {
      if (values) {
        switch (operator) {
          case "between":
          case "not-between":
            return [values["0"], values["1"]];

          default:
            return [values["0"]];
        }
      }
      return undefined;
    }
  });
