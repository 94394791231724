import ReactDirective from "acl-ui/angular/reactDirective.umd";
import aclFilterPanel from "./filterPanel.component";
import addFilterDropdown from "@viz-ui/components/addFilterDropdown/addFilterDropdown";
import visualIndicator from "@viz-ui/components/visualIndicator/visualIndicator";
import ValueFilter from "@viz-ui/components/vizFilter/quickFilter/valueFilter";

angular
  .module("acl.visualizer.filterPanel", [
    "acl.common.event",
    "acl.common.localize",
    "acl.visualizer.aclui",
    "acl.visualizer.biView",
    "acl.visualizer.checkboxFilter",
    "acl.visualizer.conditionalFormatting",
    "acl.visualizer.conditionalOperators",
    "acl.visualizer.criteriaFilter",
    "acl.visualizer.dataFilter",
    "acl.visualizer.dataModel",
    "acl.visualizer.dataTable",
    "acl.visualizer.fieldSort",
    "acl.visualizer.model.field",
    "acl.visualizer.valueFilter",
    "ui.bootstrap",
  ])
  .component("aclFilterPanel", aclFilterPanel)
  .directive("addFilterDropdown", ReactDirective.getFactory(addFilterDropdown))
  .directive("visualIndicator", ReactDirective.getFactory(visualIndicator))
  .directive("valueFilter", ReactDirective.getFactory(ValueFilter));
