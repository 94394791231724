/* eslint no-undef: 0*/
import { coneService } from "@results/services/apiPath/coneService";
import { ENV, Environments } from "../config/environments";
import integrations from "../config/integrations";
import { storyboardFlippers } from "../results_container/flippers";
import UrlFlipper from "@viz-ui/services/urlFlipper/urlFlipper";

function isAnonymous(url) {
  return url.split("/").includes("storyboard_links");
}

const appConfig = {
  application: {
    name: "Storyboards",
  },
  environment: ENV,
  environments: Environments,
  angularLocaleLocation: "/assets/angular-i18n/angular-locale_{{locale}}.js",
  supportedLanguages: ["de", "es", "en", "fr", "pt", "zh", "ja", "it", "nl"],
  dictionary_de: ["cmn_msg_de", "dv_msg_de"],
  dictionary_en: ["cmn_msg_en", "dv_msg_en"],
  dictionary_fr: ["cmn_msg_fr", "dv_msg_fr"],
  dictionary_es: ["cmn_msg_es", "dv_msg_es"],
  dictionary_pt: ["cmn_msg_pt", "dv_msg_pt"],
  dictionary_zh: ["cmn_msg_zh", "dv_msg_zh"],
  dictionary_ja: ["cmn_msg_ja", "dv_msg_ja"],
  dictionary_it: ["cmn_msg_it", "dv_msg_it"],
  dictionary_nl: ["cmn_msg_nl", "dv_msg_nl"],
  data_item_limits: {
    Chrome: 30000,
    Safari: 30000,
    default: 5000,
  },
  get pageHeaderProps() {
    return window.AclExceptionPageHeaderProps;
  },
  isAnonymous: isAnonymous(window.location.href),
  language: "",
  storyboard: {},
  highbondNavBarProps: undefined,
  querySlug() {
    return this.isAnonymous && this.storyboard.uid;
  },
  features: storyboardFlippers(),
  airbrake: integrations.airbrake[ENV],
  attachmentUrl(controlTestId) {
    return function(id) {
      return coneService.downloadExceptionAttachmentPath({
        control_test_id: controlTestId,
        id: id,
      });
    };
  },
  get apiQueryString() {
    return this.storyboard && this.storyboard.getId() ? `?storyboard_id=${this.storyboard.getId()}` : "";
  },
};

const urlEnabledFlippers = UrlFlipper.getUrlEnabledFlippers();
appConfig.features = { ...appConfig.features, ...urlEnabledFlippers };
appConfig.coneService = coneService;

export default appConfig;
