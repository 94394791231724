import UsageTracker from "@viz-ui/services/tracking/usageTracker";
import fill from "custom-event-polyfill"; //for IE11

class UsageTrackerHelper {
  static mode = ".view";

  static setTrackerMode(newMode) {
    this.mode = `.${newMode}`;
  }

  static setupStoryboardEventListener(userRole) {
    UsageTracker.setAppName("storyboard");
    UsageTracker.addListener({
      onEvent: (name, eventDetails) => {
        let aggregateName = "";
        if (name === ".editMode") {
          this.mode = ".edit";
          aggregateName = `storyboard.edit`;
        } else if (name === ".viewMode") {
          this.mode = ".view";
          aggregateName = `storyboard.view`;
        } else if (name.startsWith(".present")) {
          aggregateName = `storyboard${name}`;
        } else {
          aggregateName = `storyboard${this.mode}${name}`;
        }

        eventDetails = { role: userRole, ...eventDetails };

        dispatchEvent(aggregateName, eventDetails);
      },
    });
  }

  static setupVisualizerEventListener() {
    UsageTracker.setAppName("visualizer");
    UsageTracker.addListener({
      onEvent: (name, eventDetails, eventDuration) => {
        let aggregateName = `${UsageTracker.appName}${name}`;
        dispatchEvent(aggregateName, eventDetails, eventDuration);
      },
    });
  }
}

function dispatchEvent(name, eventDetails, eventDuration) {
  let event = new CustomEvent("usage_tracking", { detail: { name, eventDetails, eventDuration } });
  document.dispatchEvent(event);
}

export { UsageTracker, UsageTrackerHelper };
