import React from "react";
import PropTypes from "prop-types";
import PageHeader from "@acl-services/sriracha-page-header";
import Breadcrumbs from "@paprika/breadcrumbs";
import { getLoganBasePath } from "@viz-ui/services/storyboardUrlDetection/storyboardUrlDetection";
import InterpretationSummaryPopover from "../InterpretationSummaryPopover/InterpretationSummaryPopover";
import i18n from "../../i18n/i18n";
import "./PageHeader.scss";

export const PAGE_HEADER_TITLE_ID = "PageHeaderTitle";

class PageHeaderContainer extends React.Component {
  static propTypes = {
    collection: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      isArchived: PropTypes.bool,
    }),
    title: PropTypes.string.isRequired,
    interpretationSummaryProps: PropTypes.shape({
      summary: PropTypes.string.isRequired,
      canUpdateSummary: PropTypes.bool.isRequired,
      onSaveSummary: PropTypes.func,
    }),
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
    collection: null,
    interpretationSummaryProps: null,
  };

  getUrlParams() {
    const url = window.location.pathname;
    return url.split("/").filter(param => param !== "");
  }

  getApplicationTitle(isStoryBoardsPage) {
    if (isStoryBoardsPage) {
      return i18n.t("_PageHeader.Storyboards_");
    }
    return i18n.t("_PageHeader.Results_");
  }

  getRootPath(isStoryBoardsPage) {
    if (isStoryBoardsPage) {
      return `${getLoganBasePath()}/storyboards`;
    }
    return "/";
  }

  renderInterpretationSummary() {
    if (this.props.interpretationSummaryProps) {
      return (
        <span className="page-header__summary-popover">
          <InterpretationSummaryPopover {...this.props.interpretationSummaryProps} />
        </span>
      );
    }
    return null;
  }

  renderBreadCrumbs(isStoryBoardsPage) {
    const rootPath = this.getRootPath(isStoryBoardsPage);
    const applicationTitle = this.getApplicationTitle(isStoryBoardsPage);
    return (
      <PageHeader.Breadcrumbs className="page-header_breadcrumbs">
        <Breadcrumbs isAutoCollapsed={false}>
          <Breadcrumbs.Link href={rootPath}>{applicationTitle}</Breadcrumbs.Link>
          {this.props.collection && (
            <Breadcrumbs.Link href={this.props.collection.url}>
              {this.props.collection.name}
              <Breadcrumbs.Link.Tooltip zIndex={400} />
            </Breadcrumbs.Link>
          )}
        </Breadcrumbs>
      </PageHeader.Breadcrumbs>
    );
  }

  renderPageHeaderIcon(isStoryBoardsPage) {
    return (
      <PageHeader.Icon>
        {isStoryBoardsPage ? <PageHeader.Icon.Kinds.STORYBOARD /> : <PageHeader.Icon.Kinds.RESULTS />}
      </PageHeader.Icon>
    );
  }

  render() {
    const urlParams = this.getUrlParams();
    const isStoryBoardsPublicPage = urlParams.includes("storyboard_links");
    const isStoryBoardsInternalPage = urlParams.includes("storyboards");
    const isStoryBoardsPage = isStoryBoardsPublicPage || isStoryBoardsInternalPage;
    return (
      <PageHeader className="page-header__container" data-pendo-anchor="page-header">
        {this.renderPageHeaderIcon(isStoryBoardsPage)}
        {!isStoryBoardsPublicPage ? this.renderBreadCrumbs(isStoryBoardsPage) : null}
        <PageHeader.Title titleHelper={this.renderInterpretationSummary()}>
          <span title={this.props.title} data-qa-anchor="page-header__title" id={PAGE_HEADER_TITLE_ID}>
            {this.props.title}
          </span>
        </PageHeader.Title>
        {this.props.children && <PageHeader.Side>{this.props.children}</PageHeader.Side>}
      </PageHeader>
    );
  }
}

export default PageHeaderContainer;
