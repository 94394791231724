angular.module("acl.visualizer.charts.controllers", [
  "acl.common.asyncCallManager",
  "acl.common.inheritController",
  "acl.common.queuedHttp",
  "acl.common.userAgent",
  // FIXME: chart controllers shouldn't depend on chart config panel.
  "acl.visualizer.chartConfigPanel",
  "acl.visualizer.charts",
  "acl.visualizer.dataFilter",
  "acl.visualizer.dataModel",
  "acl.visualizer.tabState",
  "ui.router",
  "ui.sortable",
]);
