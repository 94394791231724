import React from "react";
import PropTypes from "prop-types";
import Switch from "@paprika/switch";
import Button from "@paprika/button";
import InfoIcon from "@paprika/icon/lib/InfoCircle";
import ToolTip from "@viz-ui/components/ToolTip";
import { ShirtSizes } from "@paprika/helpers/lib/customPropTypes";
import "./switchItem.scss";

const propTypes = {
  text: PropTypes.string,
  toolTip: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  text: "",
  toolTip: "",
  isChecked: false,
};

function SwitchItem(props) {
  const [isChecked, setIsChecked] = React.useState(props.isChecked);

  function handleChange() {
    setIsChecked(!isChecked);
    props.onChange(!isChecked);
  }

  React.useEffect(() => {
    setIsChecked(props.isChecked);
  }, [props.isChecked]);

  return (
    <div className="switch-item__container">
      <label
        htmlFor="switch-item__toggle"
        data-testid="_switch-item__label_"
        className="switch-item__label"
        onClick={handleChange}
      >
        {props.text}
      </label>
      <ToolTip
        data-testid="_switch-item__tooltip_"
        content={props.toolTip}
        isDark={false}
        className="switch-item__tooltip"
      >
        <Button
          className="switch-item__tooltip-infoicon"
          data-testid="_switch-item__tooltip-infoicon_"
          aria-label="infoIcon"
          icon={<InfoIcon />}
          onClick={handleChange}
        />
      </ToolTip>
      <Switch
        id="switch-item__toggle"
        aria-label={props.text}
        data-testid="_switch-item__toggle_"
        isChecked={isChecked}
        size={ShirtSizes.SMALL}
        onChange={handleChange}
      />
    </div>
  );
}

SwitchItem.displayName = "switchItem";
SwitchItem.propTypes = propTypes;
SwitchItem.defaultProps = defaultProps;

export default SwitchItem;
