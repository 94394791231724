import React from "react";
import Spinner from "acl-ui/components/Spinner";
import "./loadingAnimation.scss";

const LoadingAnimation = () => (
  <div className="acl-loading">
    <Spinner />
  </div>
);
export default LoadingAnimation;
