/**
 * <acl-number-input name="valueName"
 *                   class="whatever-classes-you-want"
 *                   ng-model="whateverScopeValueYouWantToBindTheValueTo"></acl-number-input>
 * Optional attributes:
 *  - I guess name & class are optional, even though I wrote them above
 *  - required, title, etc.
 *  - placeholder="Some string"
 *      - When placeholder is not given, the directive provides a default (localized) one.
 *        But if you specify placeholder in the markup, that one is the one that wins. Yay!
 * How to use the value & validation:
 *  - Value is accesible by the scope you passed to ng-model
 *  - Styles:
 *      - "ng-valid" when it's a valid number / "ng-invalid" when it's not
 *      - "ng-dirty" when the user has touched the value (i.e. this style is *not* there when
 *        the form is first opened, so use this style to avoid highlighting required values
 *        until after the user enters something
 * Validity test
 *  - Per-input "{form-name}.{input-name}.$valid" (true/false)
 *  - Whole form "{form-name}.$valid" (true when all inputs are $valid)
 * Conditional scope to bind to show when there are errors, if desired
 *  - "{form-name}.{input-name}.$error.pattern" is true when there's a value and the pattern does not match
 *  - "{form-name}.{input-name}.$error.required" is true when a required input has no value (when this is false,
 *    that doesn't mean the value is valid, just that a value has been entered)
 */
angular.module("acl.common.input").directive("aclNumberInput", function(FieldFormat, Localize, NumberFormatter) {
  return {
    restrict: "E",
    replace: true,
    require: "ngModel",
    template: templateFn,
    link: linkFn,
  };

  function templateFn(tElem, attrs) {
    var defaultPlaceholder = Localize.getLocalizedString("_Input.Number.Placeholder_");
    var placeholderAttribute = angular.isDefined(attrs.placeholder) ? "" : ' placeholder="' + defaultPlaceholder + '"';
    return `<input type="text" ng-focus="handleFocus()" ng-blur="handleBlur()" ${placeholderAttribute}>`;
  }

  function linkFn(scope, element, attrs, ngModel) {
    var isFocused = false;
    var fieldFormat = new FieldFormat();

    scope.$watch(
      function() {
        return element.attr("formatting-options");
      },
      function(value) {
        fieldFormat = value ? FieldFormat.fromJson(JSON.parse(value)) : new FieldFormat();
        reformatValue();
      }
    );

    function reformatValue() {
      ngModel.$viewValue = ngModel.$formatters
        .slice(0)
        .reverse()
        .reduce(function(prev, curr) {
          return curr(prev);
        }, ngModel.$modelValue);
      ngModel.$render();
    }

    scope.handleFocus = function() {
      isFocused = true;
      reformatValue();
    };

    scope.handleBlur = function() {
      isFocused = false;
      reformatValue();
    };

    ngModel.$formatters.push(patternValidator);
    ngModel.$formatters.push(focusDependentFieldFormatter);

    ngModel.$parsers.push(patternValidator);
    ngModel.$parsers.push(fieldParser);

    function patternValidator(value) {
      const fieldFormatJson = fieldFormat.toJson();
      const result = ngModel.$isEmpty(value) || NumberFormatter.isFormattedNumber(value, fieldFormatJson);
      ngModel.$setValidity("pattern", result);
      return result ? value : undefined;
    }

    function focusDependentFieldFormatter(value) {
      const tempFieldFormat = fieldFormat.clone();
      tempFieldFormat.abbreviate(false);

      if (isFocused) {
        tempFieldFormat.thousandsDelimiter(false);
      }

      const fieldFormatJson = tempFieldFormat.toJson();
      return NumberFormatter.format(value, fieldFormatJson);
    }

    function fieldParser(value) {
      var result = Localize.getApiNumberString(value);
      return result === "" ? undefined : result;
    }
  }
});
