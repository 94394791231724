export default class TableValuesDataSource {
  constructor() {
    this._values = [];
  }

  hasValues(startIndex, count) {
    return this._totalRecordCount != undefined && startIndex + count <= this._values.length;
  }

  addData(tableValuesData) {
    this._values = this._values.concat(tableValuesData.values);
    this._lastRecordIndex = tableValuesData.lastRowOffset;
    this._totalRecordCount = tableValuesData.totalRowCount;
  }

  getValue(index) {
    return this._values[index];
  }

  getLastRecordIndex() {
    return this._lastRecordIndex || 0;
  }

  getTotalRecordCount() {
    return this._totalRecordCount;
  }

  hasMoreValues(startIndex) {
    return startIndex < this._totalRecordCount;
  }
}
