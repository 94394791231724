import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";
import Sorter from "@viz-ui/services/sorters/sorter";
import keys from "lodash/keys";
import services from "../../services/glue/appGlue";
import Check from "@acl-services/wasabicons/lib/Check";
import stylers from "@paprika/stylers";

window.VizDropdown = createReactClass({
  propTypes: {
    hasDataError: PropTypes.bool,
    interpretations: PropTypes.object,
    chartIconClass: PropTypes.object,
    onSelectVisualization: PropTypes.func,
    onSelectInterpretation: PropTypes.func,
    wrapperRef: PropTypes.func,
  },

  getInitialState() {
    return {
      filterValue: "",
    };
  },

  close() {
    if (this.refs.searchDropdown) {
      this.setState({ filterValue: "" });
      this.refs.searchDropdown.close();
    }
  },

  toggle(button) {
    this.refs.searchDropdown.toggle(button);
  },

  makeListItems() {
    var that = this;
    if (that.props.interpretations) {
      var listItems = [];
      var projects = keys(this.props.interpretations);
      var sortedProjects = this.sortVisualizationsByName(projects);
      _(sortedProjects).each(function(projectName) {
        var interpretations = that.props.interpretations[projectName];
        var interpretationListItems = that.getInterpretationsList(interpretations);
        if (interpretationListItems.length > 0) {
          var projectSection = (
            <div className="project-section" key={projectName}>
              <li className="project-row">
                <span>{projectName}</span>
              </li>
              {interpretationListItems}
            </div>
          );
          listItems.push(projectSection);
        }
      });
      return listItems;
    }
  },

  getInterpretationsList(interpretations) {
    var interpretationList = [];
    var that = this;
    if (interpretations) {
      var sortedInterpretations = this.sortByTitle(interpretations);
      sortedInterpretations.forEach(function(interpretation) {
        var matchVisualizations = that.matchVisualizations(interpretation);
        var visualizationList = that.getVisualizationList(interpretation, matchVisualizations);
        var showCheckMark =
          services.AppConfig.features.boardSelectorSelectedCheckmark &&
          that.checkBoardInUse("table", interpretation.id);
        var checkMark = showCheckMark ? (
          <Check className="board-selector__selected-check-mark" aria-label="Check" size={stylers.spacer(1.5)}></Check>
        ) : null;
        var interpretationAnchorClass = showCheckMark ? "interpretation-table in-use" : "interpretation-table";
        if (matchVisualizations.length > 0 || that.matchInterpretation(interpretation)) {
          var interpretationSection = (
            <div className="interpretation-section" key={interpretation.id}>
              <li className="interpretation-row">
                <a className={interpretationAnchorClass} onClick={that.handleSelectInterpretation(interpretation)}>
                  {checkMark}
                  <i className="acl-i-table" />
                  {interpretation.title}
                </a>
              </li>
              {visualizationList}
            </div>
          );
          interpretationList.push(interpretationSection);
        }
      });
    }
    return interpretationList;
  },

  checkBoardInUse(type, interpretationId, visualizationId) {
    if (type === "visualization") {
      return this.props.vizBoards.find(
        item =>
          item.visualization_id === visualizationId && item.interpretation_id === interpretationId && item.type === type
      );
    }
    return this.props.vizBoards.find(item => item.interpretation_id === interpretationId && item.type === type);
  },

  getVisualizationList(interpretation, visualizations) {
    var visualizationList = [];
    var that = this;
    var sortedVisualizations = this.sortByTitle(visualizations);
    sortedVisualizations.forEach(function(visualization) {
      let chartType = visualization.vizType || visualization.type;
      if (that.visualizationSupported(chartType)) {
        var iconClass = that.props.chartIconClass[chartType] + " datatable-tab-icon";
        var visualizationTitle =
          visualization.title || services.Localize.getLocalizedString("_Storyboards.Visualization.Untitled_");
        var visualizationRowClass = visualization.valid ? "visualization-row" : "visualization-row invalid-viz has-tip";
        var showCheckMark =
          services.AppConfig.features.boardSelectorSelectedCheckmark &&
          that.checkBoardInUse("visualization", interpretation.id, visualization.id);
        var checkMark = showCheckMark ? (
          <Check className="board-selector__selected-check-mark" aria-label="Check" size={stylers.spacer(1.5)}></Check>
        ) : null;
        var vizualisationAnchorClass = showCheckMark ? "visualization in-use" : "visualization";
        var visualizationTooltip = visualization.valid
          ? ""
          : services.Localize.getLocalizedString("_Storyboards.Visualization.Invalid_");
        visualizationList.push(
          <li
            className={visualizationRowClass}
            key={visualization.id}
            title={visualizationTooltip}
            data-toggle="tooltip"
            data-placement="left"
          >
            <a
              className={vizualisationAnchorClass}
              onClick={that.handleSelectVisualization(interpretation, visualization.id, visualization.valid)}
            >
              {checkMark}
              <i className={iconClass} />
              {visualizationTitle}
            </a>
          </li>
        );
      }
    });
    return visualizationList;
  },

  matchInterpretation(interpretation) {
    var filterValue = this.state.filterValue;
    return filterValue === "" || interpretation.title.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
  },

  matchVisualizations(interpretation) {
    var that = this;
    var filterValue = that.state.filterValue;
    return interpretation.visualizations.filter(function(visualization) {
      if (!that.visualizationSupported(visualization.type)) return false;
      var title = visualization.title;
      return (
        (!title && !that.state.filterValue) ||
        (!!title && (!that.state.filterValue || title.toLowerCase().indexOf(filterValue.toLowerCase()) > -1))
      );
    });
  },

  visualizationSupported(type) {
    return type in this.props.chartIconClass;
  },

  handleSelectInterpretation(interpretation) {
    return e => {
      e.preventDefault();
      this.refs.searchDropdown.close();
      this.props.onSelectInterpretation(interpretation);
    };
  },

  handleSelectVisualization(interpretation, visualizationId, visualizationValid) {
    var that = this;
    return function(e) {
      e.preventDefault();
      if (visualizationValid) {
        that.refs.searchDropdown.close();
        that.props.onSelectVisualization(interpretation, visualizationId);
      }
    };
  },

  sortVisualizationsByName(projects) {
    var sortedArray = _(projects).sortBy(function(project) {
      return project.toLowerCase();
    });
    return sortedArray;
  },

  sortByTitle(arr) {
    return Sorter.sort(arr, { valueParser: item => item.title });
  },

  filterList(value) {
    this.setState({ filterValue: value });
  },

  render() {
    return (
      <div className="board-selector__visualizations-dropdown">
        <Dropdown
          ref="searchDropdown"
          errorMessage={
            this.props.hasDataError
              ? services.Localize.getLocalizedString("_Storyboards.Dropdown.Visualization.Error_")
              : null
          }
          filterList={this.filterList}
          listItems={this.makeListItems()}
          placeholder={services.Localize.getLocalizedString("_Storyboards.Visualization.SearchPlaceholder_")}
          boardSelectorWrapperRef={this.props.boardSelectorWrapperRef}
        />
      </div>
    );
  },
});
