(() => {
  function TruncateFilter(StringFormatter) {
    return function(text, length, end) {
      if (typeof text !== "string") return "";
      return StringFormatter.truncate(text, length, end);
    };
  }

  angular.module("acl.visualizer.formatters").filter("aclTruncate", TruncateFilter);
})();
