import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import keys from "lodash/keys";
import services from "../../services/glue/appGlue";
import Check from "@acl-services/wasabicons/lib/Check";
import stylers from "@paprika/stylers";

window.MetricDropdown = createReactClass({
  propTypes: {
    hasDataError: PropTypes.bool,
    metrics: PropTypes.object,
    onSelectMetric: PropTypes.func,
    wrapperRef: PropTypes.string,
  },

  getInitialState() {
    return {
      filterValue: "",
    };
  },

  close() {
    if (this.refs.searchDropdown) {
      this.setState({ filterValue: "" });
      this.refs.searchDropdown.close();
    }
  },

  toggle(button) {
    this.refs.searchDropdown.toggle(button);
  },

  makeListItems() {
    var that = this;
    if (that.props.metrics) {
      var listItems = [];
      var projects = keys(this.props.metrics);
      var sortedProjects = this.sortProjectsByName(projects);
      _(sortedProjects).each(function(projectName) {
        var allMetrics = that.props.metrics[projectName];
        var filteredMetrics = that.filterMetrics(allMetrics);
        var metricList = that.buildMetricList(allMetrics);

        if (filteredMetrics.length > 0 || that.metricHasFilteredTriggers(allMetrics)) {
          var projectSection = (
            <div key={projectName}>
              <li className="project-row">
                <span>{projectName}</span>
              </li>
              {metricList}
            </div>
          );
          listItems.push(projectSection);
        }
      });
      return listItems;
    }
  },

  buildMetricList(metrics) {
    var that = this;
    var metricList = [];
    var sortedMetrics = this.sortByName(metrics);

    sortedMetrics.forEach(function(metric) {
      var thresholds = that.matchThresholdBy(metric);
      var triggersList = that.buildThresholdList(metric, thresholds);
      var showCheckMark =
        services.AppConfig.features.boardSelectorSelectedCheckmark && that.checkBoardInUse("metric", metric.id);
      var checkMark = showCheckMark ? (
        <Check className="board-selector__selected-check-mark" size={stylers.spacer(1.5)}></Check>
      ) : null;
      var metricAnchorClass = showCheckMark ? "metric in-use" : "metric";
      if (that.matchMetricBy(metric) || thresholds.length > 0) {
        var metricItem = (
          <div key={metric.id}>
            <li>
              <a onClick={that.handleSelectMetric(metric)} className={metricAnchorClass}>
                {checkMark}
                <i className="acl-i-metric" />
                {metric.name}
              </a>
            </li>
            {triggersList}
          </div>
        );
        metricList.push(metricItem);
      }
    });
    return metricList;
  },

  buildThresholdList(metric, thresholds) {
    var that = this;
    var triggersList = [];
    var enabledThresholds = this.filterEnabledThresholds(thresholds);
    var sortedThresholds = this.sortByThresholdName(enabledThresholds);
    sortedThresholds.forEach(function(threshold) {
      var showCheckMark =
        services.AppConfig.features.boardSelectorSelectedCheckmark &&
        that.checkBoardInUse("trigger", metric.id, threshold.trigger.id);
      var checkMark = showCheckMark ? (
        <Check className="board-selector__selected-check-mark" size={stylers.spacer(1.5)}></Check>
      ) : null;
      var triggerAnchorClass = showCheckMark ? "trigger in-use" : "trigger";
      var triggerItem = (
        <li key={threshold.trigger.id}>
          <a onClick={that.handleSelectTrigger(metric, threshold)} href="#" className={triggerAnchorClass}>
            {checkMark}
            <i className="icon-line-chart datatable-tab-icon" />
            {threshold.trigger.name}
          </a>
        </li>
      );
      triggersList.push(triggerItem);
    });
    return triggersList;
  },

  filterMetrics(metrics) {
    var that = this;
    var filterValue = this.state.filterValue;
    if (!filterValue) return metrics;
    var filteredMetrics = _.filter(metrics, function(metric) {
      return that.matchMetricBy(metric) && that.matchThresholdBy(metric);
    });
    return filteredMetrics;
  },

  matchMetricBy(metric) {
    var filterValue = this.state.filterValue;
    if (!filterValue) return true;
    var hasValue = this.matchBy(filterValue, metric.name);
    return hasValue;
  },

  metricHasFilteredTriggers(metrics) {
    var filterValue = this.state.filterValue;
    var hasTrigger = false;
    var that = this;
    if (!filterValue) return true;
    _.each(metrics, function(metric) {
      if (that.matchThresholdBy(metric).length > 0) hasTrigger = true;
    });
    return hasTrigger;
  },

  matchThresholdBy(metric) {
    var filterValue = this.state.filterValue;
    var that = this;
    var thresholds = metric.thresholds;
    if (!filterValue) return thresholds;
    var filtered = _.filter(thresholds, function(threshold) {
      return that.matchBy(filterValue, threshold.trigger.name);
    });
    return filtered;
  },

  matchBy(filterValue, name) {
    return name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
  },

  sortProjectsByName(projects) {
    var sortedArray = _.sortBy(projects, function(project) {
      return project.toLowerCase();
    });
    return sortedArray;
  },

  sortByName(arr) {
    var sortedArray = _.sortBy(arr, function(item) {
      return item.name.toLowerCase();
    });

    return sortedArray;
  },

  filterEnabledThresholds(thresholds) {
    return _.filter(thresholds, function(threshold) {
      return threshold.trigger.enabled;
    });
  },

  sortByThresholdName(arr) {
    var sortedArray = _.sortBy(arr, function(item) {
      return item.trigger.name.toLowerCase();
    });

    return sortedArray;
  },

  filterList(value) {
    this.setState({ filterValue: value });
  },

  handleSelectMetric(metric) {
    var that = this;
    return function(e) {
      e.preventDefault();
      that.close();
      that.props.onSelectMetric(metric);
    };
  },

  handleSelectTrigger(metric, threshold) {
    var that = this;
    return function(e) {
      e.preventDefault();
      that.close();
      that.props.onSelectTrigger(metric, threshold);
    };
  },

  checkBoardInUse(type, metricId, triggerId) {
    if (type === "trigger") {
      return this.props.metricBoards.find(
        item => item.triggerId === triggerId && item.metricId === metricId && item.type === type
      );
    }
    return this.props.metricBoards.find(item => item.metricId === metricId && item.type === type);
  },

  render() {
    return (
      <div className="board-selector__metrics-dropdown">
        <Dropdown
          ref="searchDropdown"
          errorMessage={
            this.props.hasDataError ? services.Localize.getLocalizedString("_Storyboards.Dropdown.Metric.Error_") : null
          }
          filterList={this.filterList}
          listItems={this.makeListItems()}
          placeholder={services.Localize.getLocalizedString("_Storyboards.Metric.SearchPlaceholder_")}
          boardSelectorWrapperRef={this.props.boardSelectorWrapperRef}
        />
      </div>
    );
  },
});
