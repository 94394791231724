import moment from "moment";
import { cloneDeep, values, isArray, intersection } from "lodash";
import FieldTypeChecker from "@visualizer/modules/core/fieldTypeChecker/fieldTypeChecker.service";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import i18n from "@viz-ui/i18n/i18n";
import { userAgent } from "./userAgent";

/* jshint loopfunc: true */
// TODO: need to use pubsub or equivalent service to replace EventService, this possible when upper levels are migrated to react.
class ChartService {
  constructor(ColorPalette, DataModel, AppConfig, EventService) {
    this.ColorPalette = ColorPalette;
    this.DataModel = DataModel;
    this.AppConfig = AppConfig;
    this.eventService = EventService.register("charts");
    this.chartEventService = EventService.register("slidingNavTabs.SlidingNavTabsController");
    this.BubbleScaleInfo = {
      options: Object.keys(BUBBLE_SCALE_INFO),
      minSize: option => BUBBLE_SCALE_INFO[option].minSize,
      maxSize: option => BUBBLE_SCALE_INFO[option].maxSize,
    };

    this.CHART_TYPES = [
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: i18n.t("_ChartTypes.BarChart.StackedColumn.Tooltip_"),
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-chart-stacked-column",
        config: {
          displayConfig: {
            horizontal: false,
            chartStyle: "stack",
          },
        },
      },
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: i18n.t("_ChartTypes.BarChart.Stacked.Tooltip_"),
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-chart-stacked",
        config: {
          displayConfig: {
            horizontal: true,
            chartStyle: "stack",
          },
        },
      },
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: i18n.t("_ChartTypes.BarChart.GroupedColumn.Tooltip_"),
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-chart-grouped-column",
        config: {
          displayConfig: {
            horizontal: false,
            chartStyle: "group",
          },
        },
      },
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: i18n.t("_ChartTypes.BarChart.Grouped.Tooltip_"),
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-chart-grouped",
        config: {
          displayConfig: {
            horizontal: true,
            chartStyle: "group",
          },
        },
      },
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: i18n.t("_ChartTypes.BarChart.100StackedColumn.Tooltip_"),
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-chart-stacked-column-100",
        config: {
          displayConfig: {
            horizontal: false,
            chartStyle: "expand", //100% stacked
          },
        },
      },
      {
        type: "BarChart",
        vizType: "BarChart",
        tooltip: i18n.t("_ChartTypes.BarChart.100Stacked.Tooltip_"),
        tabIcon: "bar-charts",
        tabIndex: "",
        thumbnailIcon: "bar-charts-stacked-100",
        config: {
          displayConfig: {
            horizontal: true,
            chartStyle: "expand", //100% stacked
          },
        },
      },
      {
        type: "LineChart",
        vizType: "LineChart",
        tooltip: i18n.t("_ChartTypes.LineChart.Tooltip_"),
        tabIcon: "line-chart",
        tabIndex: "",
        thumbnailIcon: "line-chart",
      },
      {
        type: "StackedAreaChart",
        vizType: "StackedAreaChart",
        tooltip: i18n.t("_ChartTypes.AreaChart.Stacked.Tooltip_"),
        tabIcon: "area-chart-stacked",
        tabIndex: "",
        thumbnailIcon: "area-chart-stacked",
        config: {
          displayConfig: {
            chartStyle: "stack",
          },
        },
      },
      {
        type: "StackedAreaChart",
        vizType: "StackedAreaChart",
        tooltip: i18n.t("_ChartTypes.AreaChart.Standard.Tooltip_"),
        tabIcon: "area-chart-stacked",
        tabIndex: "",
        thumbnailIcon: "area-chart-standard",
        config: {
          displayConfig: {
            chartStyle: "overlap", // standard
          },
        },
      },
      {
        type: "StackedAreaChart",
        vizType: "StackedAreaChart",
        tooltip: i18n.t("_ChartTypes.AreaChart.Stacked100.Tooltip_"),
        tabIcon: "area-chart-stacked",
        tabIndex: "",
        thumbnailIcon: "area-chart-stacked-100",
        config: {
          displayConfig: {
            chartStyle: "expand", //100% stacked
          },
        },
      },
      {
        type: "PieChart",
        vizType: "PieChart",
        tooltip: i18n.t("_ChartTypes.PieChart.Tooltip_"),
        tabIcon: "pie-chart",
        tabIndex: "",
        thumbnailIcon: "pie-chart",
        icon: "pie-chart",
      },
      {
        type: "BubbleChart",
        vizType: "BubbleChart",
        tooltip: i18n.t("_ChartTypes.BubbleChart.Tooltip_"),
        tabIcon: "bubble-chart",
        tabIndex: "",
        thumbnailIcon: "bubble-chart",
      },
      {
        type: "Heatmap",
        vizType: "Heatmap",
        tooltip: i18n.t("_ChartTypes.Heatmap.Tooltip_"),
        tabIcon: "heatmap-chart",
        tabIndex: "",
        thumbnailIcon: "heatmap-chart",
      },
      {
        type: "SummaryTable",
        vizType: "SummaryTable",
        tooltip: i18n.t("_ChartTypes.SummaryTable.Tooltip_"),
        tabIcon: "summary-table-chart",
        tabIndex: "",
        thumbnailIcon: "summary-table-chart",
      },
      {
        type: "SummaryTable",
        vizType: "Treemap",
        tooltip: i18n.t("_ChartTypes.Treemap.Tooltip_"),
        tabIcon: "treemap-chart",
        tabIndex: "",
        thumbnailIcon: "treemap-chart",
      },
      {
        type: "StatisticsViz",
        vizType: "StatisticsViz",
        tooltip: i18n.t("_ChartTypes.StatisticsViz.Tooltip_"),
        tabIcon: "statistics-chart",
        tabIndex: "",
        thumbnailIcon: "statistics-chart",
      },
      {
        type: "SummaryTable",
        vizType: "CombinationChart",
        tooltip: i18n.t("_ChartTypes.CombinationChart.Tooltip_"),
        tabIcon: "combination-chart",
        tabIndex: "",
        thumbnailIcon: "combination-chart",
        disabled: !this.AppConfig.features.combinationChart,
      },
      {
        type: "MapChart",
        vizType: "MapChart",
        tooltip: i18n.t("_ChartTypes.MapChart.Tooltip_"),
        tabIcon: "map-chart",
        tabIndex: "",
        thumbnailIcon: "map-chart",
        disabled: !this.AppConfig.features.mapChart,
      },
    ];

    this.AGGREGATION_TYPES = {
      average: {
        labelTemplate: i18n.t("_Chart.AverageAxisLabel.Template_"),
      },
      min: {
        labelTemplate: i18n.t("_Chart.MinAxisLabel.Template_"),
      },
      max: {
        labelTemplate: i18n.t("_Chart.MaxAxisLabel.Template_"),
      },
      sum: {
        labelTemplate: i18n.t("_Chart.SumAxisLabel.Template_"),
      },
      count: {
        labelTemplate: i18n.t("_Chart.CountAxisLabel.Template_"),
      },
    };
  }

  getAllChartTypes = () => this.CHART_TYPES;

  getFieldLabel = field => {
    const fieldLabel = field.aggregationType
      ? this.AGGREGATION_TYPES[field.aggregationType].labelTemplate.replace("$0", field.displayName)
      : field.displayName;
    return fieldLabel;
  };

  getChartByType = type => this.CHART_TYPES.filter(chart => chart.vizType === type)[0];

  getDefaultChartTitle = () => "";

  getDataItemLimit = () =>
    this.AppConfig.data_item_limits[userAgent.browser] || this.AppConfig.data_item_limits.default;

  getFormatterForType = fieldType => {
    switch (fieldType) {
      case "date":
        return d => {
          const parsedValue = valueParser(d, fieldType);
          return d === null ? "" : moment.utc(parsedValue).format("YYYY-MM-DD");
        };

      case "datetime":
        return d => {
          const parsedValue = valueParser(d, fieldType);
          return d === null ? "" : moment.utc(parsedValue).format("YYYY-MM-DD HH:mm:ss");
        };

      case "time":
        return d => (d === null ? "" : moment(d, "X").format("HH:mm:ss"));

      case "numeric":
        return d => d;

      case "character":
      case "logical":
        return d => (d === i18n.t("_Filter.BlankValue.Label_") ? "" : d);

      default:
        throw new Error("Unknown data type: " + fieldType);
    }
  };

  getValueFormatter = (interpretationId, fieldName, fieldType) => {
    if (!fieldType) {
      return value => value;
    }

    return value => {
      const formatBlanks = true;
      const unformattedValue = valueParser(value, fieldType);
      let result;
      if (GlobalValueFormatter.isUrlFormatted(interpretationId, fieldName)) {
        result = GlobalValueFormatter.getUrlFormattedValue(unformattedValue);
      } else {
        result = GlobalValueFormatter.formatValue(unformattedValue, interpretationId, fieldName, formatBlanks);
      }
      return result;
    };
  };

  /* eslint-disable */
  parseDataConfig = (dataConfigWithConfigFieldNames, fieldObjsByConfigKey) => {
    if (!dataConfigWithConfigFieldNames || !fieldObjsByConfigKey) return;

    // A "config key" is a string like "chartXAxis", "chartSeries", or "chartValues".

    const fieldObjConfigKeys = Object.keys(fieldObjsByConfigKey);
    const dataConfigConfigKeys = Object.keys(dataConfigWithConfigFieldNames);
    const definedConfigKeys = intersection(fieldObjConfigKeys, dataConfigConfigKeys);

    return definedConfigKeys.reduce((dataConfigWithConfigFieldObjs, configKey) => {
      const configFieldObj = getConfigFieldObj(dataConfigWithConfigFieldNames, configKey, fieldObjsByConfigKey);
      if (configFieldObj || (isArray(configFieldObj) && !configFieldObj.includes(undefined))) {
        dataConfigWithConfigFieldObjs[configKey] = configFieldObj;
      }
      return dataConfigWithConfigFieldObjs;
    }, {});
  };
  /* eslint-enable */

  publishDrawComplete = () => {
    this.eventService.publish("charts.drawComplete");
  };

  getGlobalChartElementsColorsTable = () => globalChartElementsColorsTable;

  //TODO: Need to check if this implementation works, else need to pass globalChartElementColorsTable as attribute
  setGlobalChartElementsColorsTableByKey = (key, color) => {
    if (key && color && globalChartElementsColorsTable[key] !== color) {
      globalChartElementsColorsTable[key] = color;
    }
  };

  /* eslint-disable */
  // TODO: remove this when colour picker supports all colours and directly call it from each graph directive
  getKeyColor = (colorByFieldName, colorMapping) => {
    if (!colorMapping) colorMapping = this.DataModel.visualizationConfig.colorMapping() || {};
    const colorPalette = new this.ColorPalette();
    return data => {
      // TODO: this should be in the directive and passed into ColorPalette
      let key;
      if (data.rows && data.rows.length > 0) {
        key = data.rows[0][colorByFieldName];
      } else {
        const d = data.key ? data : data.data;
        key = d && d.key ? d.key : "";
      }
      const color = colorPalette.build(colorMapping, colorByFieldName, key);
      this.setGlobalChartElementsColorsTableByKey(colorByFieldName + key, color);
      /*
       * TODO: Need to remove below event
       * Publishing the event in order to keep the getKeyColors sync with angular chart.service
       */
      this.chartEventService.publish(
        "react.chart.setGlobalChartElementsColorsTableByKey",
        colorByFieldName + key,
        color
      );
      return color;
    };
  };
  /* eslint-enable */

  getYDomain = (chartType, currConfig, data) => {
    let maxsCollection = [];
    const min = currConfig.yAxis.minimum || 0;
    let max = 0;

    if (!currConfig.yAxis.maximum) {
      maxsCollection = getAxisMaxValuesByChartType(chartType, data);
      max = getChartMax(maxsCollection, chartType);
    } else {
      max = currConfig.yAxis.maximum;
    }

    return [min, max];
  };

  chartHasError = vizIndex => {
    const visualization = this.DataModel.visualization(vizIndex);
    const vizDataConfig = visualization && visualization.config && visualization.config.dataConfig;
    return vizDataConfig && this.dataConfigHasError(vizDataConfig);
  };

  dataConfigHasError = dataConfig => {
    const fields = this.DataModel.table.fields();
    const fieldsLoaded = values(fields).length > 0;
    const vizHasDeletedField = fieldsLoaded && !this.dataConfigFieldsExist(dataConfig);
    const vizHasDataTypeChanged = this.dataConfigHasFieldTypeMismatch(dataConfig);
    return vizHasDeletedField || vizHasDataTypeChanged;
  };

  dataConfigFieldsExist = dataConfig => this.DataModel.table.fieldsExist(getDataConfigFieldNames(dataConfig));

  dataConfigHasFieldTypeMismatch = dataConfig => {
    const fields = this.DataModel.table.fields();
    const fieldTypeChecker = FieldTypeChecker.create().setFieldsFromMap(fields);
    return fieldTypeChecker.chartDataConfigHasFieldTypeMismatch(dataConfig);
  };

  isSortTabEnabledForType = chartType => {
    const SORT_TAB_CHART_TYPES = ["BarChart"];
    if (this.AppConfig.features.sortingForLineCharts) {
      SORT_TAB_CHART_TYPES.push("LineChart");
    }
    return SORT_TAB_CHART_TYPES.includes(chartType);
  };

  getLabelFormatter = (chartConfig, interpretationId) => {
    const result =
      chartConfig.dataConfig &&
      chartConfig.dataConfig.chartSeries &&
      chartConfig.dataConfig.chartSeries.type &&
      chartConfig.dataConfig.chartSeries.type === "logical"
        ? this.getValueFormatter(
            interpretationId,
            chartConfig.dataConfig.chartSeries.fieldName,
            chartConfig.dataConfig.chartSeries.type
          )
        : null;
    return result;
  };

  parseValue = (value, fieldType) => valueParser(value, fieldType);
}

const BUBBLE_SCALE_INFO = {
  "1": { minSize: "8", maxSize: "10%" }, //It's the default scaling values to align with the existing bubble charts
  "2": { minSize: "12", maxSize: "20%" },
  "3": { minSize: "16", maxSize: "30%" },
};

const globalChartElementsColorsTable = {};

const getDataConfigFieldNames = dataConfig => {
  const fieldNames = [];
  for (const prop in dataConfig) {
    if (Object.prototype.hasOwnProperty.call(dataConfig, prop)) {
      // For some chart's dataconfig the configs are arrays like for
      // summary table and statistics. If it isn't an array make it one
      const configArray = Array.isArray(dataConfig[prop]) ? dataConfig[prop] : [dataConfig[prop]];

      configArray.forEach(config => {
        if (config && config.fieldName) {
          fieldNames.push(config.fieldName);
        }
      });
    }
  }
  return fieldNames;
};

const getConfigFieldObj = (dataConfigWithConfigFieldNames, propName, chartConfigColumnDefs) => {
  const propColumnDefs = chartConfigColumnDefs[propName];
  const fieldNameOrFieldNames = dataConfigWithConfigFieldNames[propName];
  if (isArray(fieldNameOrFieldNames)) {
    return configFieldNamesToConfigFieldObjs(
      fieldNameOrFieldNames,
      dataConfigWithConfigFieldNames.aggregationTypes,
      propName,
      propColumnDefs
    );
  }
  return configFieldNameToConfigFieldObj(
    fieldNameOrFieldNames,
    dataConfigWithConfigFieldNames.aggregationType,
    propName,
    propColumnDefs
  );
};

const configFieldNamesToConfigFieldObjs = (fieldNames, aggregationTypes, propName, propColumnDefs) => {
  if (propName === "chartValues") {
    return fieldNames.reduce((accumulator, fieldName, index) => {
      const fieldNameToFieldObj = getFieldNameToFieldObjConverter(propColumnDefs, aggregationTypes[index]);
      const fieldObj = fieldNameToFieldObj(fieldName);
      if (fieldObj) {
        accumulator.push(fieldObj);
      }
      return accumulator;
    }, []);
  }
  return fieldNames.map(getFieldNameToFieldObjConverter(propColumnDefs));
};

const configFieldNameToConfigFieldObj = (fieldName, aggregationType, propName, propColumnDefs) => {
  if (propName === "chartValue") {
    const fieldNameToFieldObj = getFieldNameToFieldObjConverter(propColumnDefs, aggregationType);
    return fieldNameToFieldObj(fieldName);
  }
  return getFieldNameToFieldObjConverter(propColumnDefs)(fieldName);
};

/* eslint-disable */
const getFieldNameToFieldObjConverter = (propColumnDefs, dataConfigAggregationType) => {
  return fieldName => {
    if (dataConfigAggregationType === "count") {
      return { type: "numeric", aggregationType: "count" };
    }

    if (fieldName === undefined) {
      return undefined;
    }

    let fieldObj = propColumnDefs.find(field => field.fieldName === fieldName);
    if (fieldObj) {
      fieldObj = cloneDeep(fieldObj);
      if (dataConfigAggregationType) {
        fieldObj.aggregationType = dataConfigAggregationType;
      }
    }
    return fieldObj;
  };
};
/* eslint-enable */

const valueParser = (value, fieldType) => {
  switch (fieldType) {
    case "datetime":
      return value ? moment.unix(value).toDate() : null;

    case "date":
      return value
        ? moment
            .unix(value)
            .utc()
            .format("YYYY-MM-DD")
        : null;

    case "time":
      return value ? moment.unix(value).format("HH:mm:ss") : null;

    case "numeric":
    case "character":
    case "logical":
      return value;

    default:
      throw new Error("Unknown data type: " + fieldType);
  }
};

const getAxisMaxValuesByChartType = (chartType, data) => {
  const maxsCollection = [];
  const key = chartType === "LineChart" ? "y" : "values";
  for (const i in data) {
    const m = Math.max.apply(
      null,
      data[i].values.map(item => item[key])
    );
    maxsCollection.push(m);
  }
  return maxsCollection;
};

const getChartMax = (maxsCollection, chartType) => {
  let max = 0;
  switch (chartType) {
    case "stackedAreaChart":
      for (const ind in maxsCollection) {
        max += maxsCollection[ind];
      }
      break;
    default:
      max = Math.max.apply(null, maxsCollection);
  }
  return max;
};

export default ChartService;
