import Clipboard from "clipboard";
import PropTypes from "prop-types";
import React from "react";
import services from "../../../services/glue/appGlue";

class PublicLink extends React.Component {
  constructor(props) {
    super(props);

    this.getCopiedNotice = this.getCopiedNotice.bind(this);
    this.getCopyButton = this.getCopyButton.bind(this);

    this.state = {
      copied: false,
    };
  }

  componentDidMount() {
    let linkClipboardElem = $(ReactDOM.findDOMNode(this.refs.storyboardLinkClipboard));

    this.linkClipboard = new Clipboard(".storyboard-link__copy-clipboard");
    this.linkClipboard.on("success", () => {
      this.setState({ copied: true });
      setTimeout(() => {
        this.setState({ copied: false });
      }, 5000);
    });
  }

  getCopiedNotice() {
    let copied;
    if (this.state.copied) {
      copied = (
        <div className="storyboard-link__public-link-copied">
          <i className="acl-i-check" /> {services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.Copied_")}
        </div>
      );
    }
    return copied;
  }

  getCopyButton() {
    return (
      <span
        className="storyboard-link__clipboard-wrapper tip-top"
        data-tooltip=""
        aria-haspopup="true"
        tooltip-placement="top"
        title={services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.CopyTooltip_")}
      >
        <a
          data-clipboard-target="#storyboard-link"
          ref="storyboardLinkClipboard"
          className="storyboard-link__copy-clipboard"
          data-clipboard-text={this.props.link}
        >
          <i className="acl-i-clipboard" />
        </a>
      </span>
    );
  }

  render() {
    return (
      <div className="storyboard-link__public-link-wrapper">
        <input
          id="storyboard-link"
          className="storyboard-link__public-link"
          type="text"
          value={this.props.link}
          readOnly
        />
        {this.getCopyButton()}
        {this.getCopiedNotice()}
      </div>
    );
  }
}

PublicLink.propTypes = {
  link: PropTypes.string,
};

window.PublicLink = PublicLink;
