const sortConfigPanel = {
  bindings: {
    chartData: "<",
    dataConfig: "<",
    displayConfig: "<",
    fieldFormatIdentifier: "<",
    chartType: "<",
    onSortConfigChange: "&",
    onDiscardSortConfigChange: "&",
  },
  templateUrl: "visualizer/js/modules/core/chartConfigPanel/sortConfigPanel.tpl.html",
  controllerAs: "sortConfigPanel",
  controller: class SortConfigPanelController {
    constructor(ChartDataSorter, ChartService) {
      "ngInject";

      this.ChartDataSorter = ChartDataSorter;
      this.ChartService = ChartService;

      this.setDefaultSortConfigProps();
    }

    setDefaultSortConfigProps() {
      this.sortConfigProps = {
        sortOrder: "default",
        dataType: undefined,
        fieldName: undefined,
        values: [],
        onChange: this.handleSortConfigChange.bind(this),
      };
    }

    $onChanges(changesObj) {
      let pendingSortConfigProps = { ...this.sortConfigProps };

      if (changesObj.chartType) {
        pendingSortConfigProps.chartType = changesObj.chartType.currentValue;
      }

      if (changesObj.dataConfig) {
        const dataConfig = changesObj.dataConfig.currentValue;
        if (dataConfig && dataConfig.chartXAxis) {
          pendingSortConfigProps.fieldName = dataConfig.chartXAxis.fieldName;
          pendingSortConfigProps.dataType = dataConfig.chartXAxis.type;
        } else {
          pendingSortConfigProps.fieldName = undefined;
          pendingSortConfigProps.dataType = undefined;
          pendingSortConfigProps.values = [];
        }
      }

      if (changesObj.displayConfig) {
        const displayConfig = changesObj.displayConfig.currentValue;
        const { order: sortOrder, valuesOrder } = displayConfig;
        pendingSortConfigProps.sortOrder = sortOrder || "default";
        pendingSortConfigProps.valuesOrder = valuesOrder;
      }

      if (changesObj.chartData) {
        pendingSortConfigProps = {
          ...pendingSortConfigProps,
          ...this.getChartValues(),
        };
      }

      this.updateSortConfigProps(pendingSortConfigProps);
    }

    updateSortConfigProps(pendingNewSortConfigProps) {
      this.sortConfigProps = { ...pendingNewSortConfigProps };
    }

    getChartValues() {
      if (this.dataConfig && this.chartData && this.displayConfig) {
        const { type: dataType } = this.dataConfig.chartXAxis;
        const { order: sortOrder } = this.displayConfig;
        const values = this.getReorderedValues(sortOrder, dataType);
        return { values };
      }
      return {};
    }

    handleSortConfigChange(sortConfig) {
      const pendingSortConfigProps = { ...this.sortConfigProps };
      const dataType = this.dataConfig.chartXAxis.type;
      const { sortOrder } = sortConfig;
      pendingSortConfigProps.values = this.getReorderedValues(sortOrder, dataType);
      pendingSortConfigProps.sortOrder = sortConfig.sortOrder;
      pendingSortConfigProps.valuesOrder = sortConfig.valuesOrder;
      this.sortConfigProps = { ...pendingSortConfigProps };
      this.onSortConfigChange({ sortConfig });
    }

    getReorderedValues(sortOrder, dataType) {
      switch (sortOrder) {
        case "asc":
        case "desc": {
          const reorderedValues = this.ChartDataSorter.sortChartDataByTotals(this.chartData, sortOrder);
          return this.parseChartValues(reorderedValues[0].values, dataType);
        }
        default: {
          switch (this.chartType) {
            case "BarChart":
              return this.parseChartValues(this.chartData[0].values, dataType);
            default:
              const reorderedValues = this.ChartDataSorter.getXAxisDataPointsInAscOrder(this.chartData, dataType);
              return this.parseChartValues(reorderedValues, dataType, false);
          }
        }
      }
    }

    parseChartValues(values, dataType, byValueKey = true) {
      return values.map(value => this.ChartService.parseValue(byValueKey ? value.key : value, dataType));
    }

    handleDiscardClick() {
      this.onDiscardSortConfigChange();
    }
  },
};

export default sortConfigPanel;
