import merge from "lodash/merge";

export default function ChartTabFactory(
  ChartService,
  DataModel,
  BarChart,
  BubbleChart,
  HeatmapService,
  LineChart,
  PieChart,
  StackedAreaChart,
  StatisticsViz,
  SummaryTableService,
  Treemap,
  MapChart
) {
  "ngInject";

  class ChartTab {
    setCallbackInterface(callbackInterface) {
      this.callbackInterface = callbackInterface;
    }

    openNewVisualization(vizConfig) {
      const vizConfigWithDefaults = getConfigWithDefaults(vizConfig);
      const vizId = DataModel.addVisualization(vizConfigWithDefaults);
      const tabIndex = DataModel.getTabIndexByVizId(vizId);
      DataModel.interpretation.currentTabIndex(tabIndex);
    }

    createChartTabConfigs(visualizations, callbackInterface) {
      return visualizations.map(viz => {
        return {
          id: viz.id,
          data: ChartService.getChartByType(viz.vizType),
          title: viz.title,
          callbackInterface: callbackInterface,
        };
      });
    }
  }

  return {
    getInstance: () => new ChartTab(),
  };

  function getConfigWithDefaults(vizConfig) {
    const displayConfig = getDefaultDisplayConfig(vizConfig.vizType);
    return merge({}, { config: { displayConfig } }, vizConfig);
  }

  function getDefaultDisplayConfig(chartType) {
    switch (chartType) {
      case "BarChart":
        return BarChart.defaultDisplayConfig();

      case "BubbleChart":
        return BubbleChart.defaultDisplayConfig();

      case "Heatmap":
        return HeatmapService.defaultDisplayConfig();

      case "LineChart":
        return LineChart.defaultDisplayConfig();

      case "PieChart":
        return PieChart.defaultDisplayConfig();

      case "StackedAreaChart":
        return StackedAreaChart.defaultDisplayConfig();

      case "StatisticsViz":
        return StatisticsViz.defaultDisplayConfig();

      case "SummaryTable":
        return SummaryTableService.defaultDisplayConfig();

      case "Treemap":
        return Treemap.defaultDisplayConfig();

      case "CombinationChart":
        /** As this ticket does not contain the work related to chartConfig,
         *** to avoid unit test errors using Treemap here for now.
         ***/
        return Treemap.defaultDisplayConfig();

      case "MapChart":
        return MapChart.defaultDisplayConfig();

      default:
        throw new Error(`Unexpected chart type. type=${chartType}`);
    }
  }
}
