class SectionAdapter {
  getSection(chartType) {
    switch (chartType) {
      case "SummaryTable":
        return getSummaryTableSection();
      case "BarChart":
        return getBarChartSection();
      case "LineChart":
        return getLineChartSection();
      case "StackedAreaChart":
        return getStackedAreaChartSection();
      case "PieChart":
        return getPieChartSection();
      case "BubbleChart":
        return getBubbleChartSection();
      case "StatisticsViz":
        return getStatisticsVizSection();
      case "Heatmap":
        return getHeatmapSection();
      case "Treemap":
        return undefined;
      case "MapChart":
        return getMapChartSection();
      case "CombinationChart":
        return undefined;
      default:
        throw new Error("Unexpected chart type: " + chartType);
    }
  }
}

const getBarChartSection = () => {
  return {
    titleKey: "_ChartConfig.XAxis.Label_",
    titleKey2: "_ChartConfig.YAxis.Label_",
    titleKey3: "_ChartConfig.Display.CustomizeYAxis.Label_",
    titleKey4: "_ChartConfig.Display.Format.Label_",
    selectId: "subCategoryDropdown",
    option: "chartXAxis",
  };
};

const getLineChartSection = () => {
  return {
    titleKey: "_ChartConfig.XAxis.Label_",
    titleKey2: "_ChartConfig.YAxis.Label_",
    titleKey3: "_ChartConfig.YAxis.Label_",
    titleKey4: "_ChartConfig.Display.Format.Label_",
    selectId: "subCategoryDropdown",
    option: "chartXAxis",
  };
};

const getStackedAreaChartSection = () => {
  return {
    titleKey: "_ChartConfig.XAxis.Label_",
    titleKey2: "_ChartConfig.YAxis.Label_",
    titleKey3: "_ChartConfig.YAxis.Label_",
    titleKey4: "_ChartConfig.Display.Format.Label_",
    selectId: "subCategoryDropdown",
    option: "chartXAxis",
  };
};

const getPieChartSection = () => {
  return {
    titleKey: "_ChartConfig.Category.Label_",
    titleKey2: "_ChartConfig.Value.Label_",
    selectId: "categoryDropdown",

    option: "chartCategory",
  };
};

const getBubbleChartSection = () => {
  return {
    titleKey: "_ChartConfig.XAxis.Label_",
    titleKey2: "_ChartConfig.Size.Label_",
    titleKey3: "_ChartConfig.YAxis.Label_",
    titleKey4: "_ChartConfig.Display.Format.Label_",
    selectId: "categoryDropdown",
    option: "chartXAxis",
  };
};

const getStatisticsVizSection = () => {
  return {
    titleKey: "_ChartConfig.Category.Label_",
  };
};

const getHeatmapSection = () => {
  return {
    titleKey: "_ChartConfig.XAxis.Label_",
    titleKey2: "_ChartConfig.Series.Label_",
    titleKey3: "_ChartConfig.YAxis.Label_",
    selectId: "categoryDropdown",
    option: "chartXAxis",
  };
};

const getMapChartSection = () => {
  return {
    titleKey: "_ChartConfig.Location.Label_",
    titleKey2: "_ChartConfig.Value.Label_",
    selectId: "categoryDropdown",
    option: "chartXAxis",
  };
};

const getSummaryTableSection = () => {
  return {
    titleKey: "_ChartConfig.Rows.Label_",
    titleKey2: "_ChartConfig.Values.Label_",
    titleKey3: "_ChartConfig.Column.Label_",
  };
};

export default new SectionAdapter();
