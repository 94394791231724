import values from "lodash/values";
import FieldAdapter from "@viz-ui/models/field/fieldAdapter";

export default class FieldTypeChecker {
  constructor(fields = []) {
    this._fields = fields;
  }

  static create(fields) {
    return new FieldTypeChecker(fields);
  }

  setFieldsFromMap(fieldsMap) {
    this._fields = FieldAdapter.deserializeFieldMapToArray(fieldsMap);
    return this;
  }

  filterListTypeMismatch(filterList = []) {
    return filterList.some(filter => this.filterTypeMismatch(filter));
  }

  filterTypeMismatch(filter) {
    const field = this.findField(filter.name);
    return !!field && !!field.type() && field.type() !== filter.type;
  }

  chartDataConfigHasFieldTypeMismatch(dataConfig) {
    const configFields = values(dataConfig);
    const hasMismatchFieldType = configFields.some(this.chartConfigFieldHasFieldTypeMismatch.bind(this));
    return this._fields.length > 0 && hasMismatchFieldType;
  }

  chartConfigFieldHasFieldTypeMismatch(configField) {
    const field = this.findField(configField.fieldName);
    return !!field && !!field.type() && field.type() !== configField.type;
  }

  chartConfigFieldTypeInvalid(configFieldName, columnDefs) {
    return columnDefs && !columnDefs.find(field => field.fieldName === configFieldName);
  }

  findField(fieldName) {
    return this._fields.find(field => field.name() === fieldName);
  }
}
