import { HighChartDirective, HighChartController } from "../highChartDirective";
import { TREE_CHART } from "../boost/boostThresholds";

class TreemapDirective extends HighChartDirective {
  controller = Controller;
  static factory = () => new TreemapDirective();
}

const baseFontSizeConfigPath = [
  "plotOptions.series.dataLabels.style.fontSize",
  "tooltip.style.fontSize",
  "xAxis.labels.style.fontSize",
  "xAxis.title.style.fontSize",
  "yAxis.labels.style.fontSize",
  "yAxis.title.style.fontSize",
];
const colorByFontSizeConfigPath = ["colorAxis.labels.style.fontSize", "legend.itemStyle.fontSize"];

export class Controller extends HighChartController {
  static configPath = {
    labelFontSize: baseFontSizeConfigPath,
    labelFontSizeWithColorBy: [...colorByFontSizeConfigPath, ...baseFontSizeConfigPath],
  };

  constructor($scope, $timeout, HighChartLabelSizer, Treemap) {
    "ngInject";
    super($scope, $timeout, HighChartLabelSizer);

    this.Treemap = Treemap;
  }

  getConfig = (config, data) => {
    const that = this;
    let hcConfig = {
      legend: {
        enabled: config.showLegend,
        align: "right",
        verticalAlign: "top",
      },
      boost: this.getBoostConfig(config),
      colorAxis: this.Treemap.getHighChartsColorAxis(config, data),
      plotOptions: {
        series: {
          point: {
            events: {
              click: this.clickWrapper(null, point => {
                this.zoomIn(that.Treemap.getNodeAndParentsDescending(point.node, point.series).map(node => node.name));
              }),
            },
          },
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              return that.Treemap.tooltipPointFormatter(config, this.series, this.node, this.value, this.colorValue);
            },
          },
          turboThreshold: 0,
          animation: this.canAnimate(),
        },
      },
      series: [
        {
          type: "treemap",
          layoutAlgorithm: "squarified",
          allowDrillToNode: true,
          alternateStartingDirection: true,
          animationLimit: 1000,
          boostThreshold: TREE_CHART,
          colorIndex: 0,
          data: this.Treemap.convertToHighChartData(data, config),
          levelIsConstant: true,
          levels: [
            {
              level: 1,
              dataLabels: {
                enabled: config.showFirstGroupLabels,
                formatter: function() {
                  return config.chartRows[0].valueFormatter(this.key);
                },
                align: "left",
                verticalAlign: "top",
                style: {
                  fontSize: "20px",
                  fontWeight: "bold",
                },
              },
              borderWidth: 3,
            },
            {
              level: 2,
              dataLabels: {
                enabled: config.showSecondGroupLabels,
                formatter: function() {
                  return config.chartRows[1].valueFormatter(this.key);
                },
              },
              borderWidth: 2,
            },
          ],
        },
      ],
    };
    // pattern-fill does not support gradient axis
    if (!this.getBoostConfig(config).enabled && this.$scope.isPatternFill) {
      if (!hcConfig.colorAxis)
        for (const [index, v] of hcConfig.series[0].data.entries()) {
          if (v.color) {
            v.color = this.patternFillPalettes(index);
          } else break;
        }
      else if (hcConfig.colorAxis.dataClasses) {
        Object.entries(hcConfig.colorAxis.dataClasses).forEach((v, index) => {
          v[1].color = this.patternFillPalettes(index);
        });
      }
    }

    const dataConfig = this.getDataConfig(data);
    const labelFontSizeConfigPaths = this.getLabelFontSizeConfigPaths(dataConfig);
    hcConfig = this.updateLabelFontSize(hcConfig, labelFontSizeConfigPaths);
    return hcConfig;
  };

  getLabelFontSizeConfigPaths = config => {
    const { labelFontSizeWithColorBy, labelFontSize } = Controller.configPath;
    return hasColorBy(config) ? labelFontSizeWithColorBy : labelFontSize;
  };

  zoomIn = values => {
    if (angular.isFunction(this.$scope.zoomInHandler)) {
      this.$scope.zoomInHandler(values);
    }
  };

  getDataConfig = data => {
    if (data.modelType === "ChartData") {
      const dataConfig = data.dataConfig();
      return { rows: dataConfig.chartRows, values: dataConfig.chartValues };
    }
    return data.config;
  };
}

function hasColorBy(config) {
  return config.values.length > 1;
}

export default TreemapDirective;
