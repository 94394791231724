angular
  .module("acl.storyboard.visualization")
  .factory("Visualization", function(
    BarChart,
    BubbleChart,
    HeatmapService,
    LineChart,
    PieChart,
    StackedAreaChart,
    SummaryTableService,
    Treemap,
    CombinationChart,
    MapChart
  ) {
    let visualization = {
      getChartService: function(type) {
        var map = {
          BarChart: BarChart,
          BubbleChart: BubbleChart,
          Heatmap: HeatmapService,
          LineChart: LineChart,
          PieChart: PieChart,
          StackedAreaChart: StackedAreaChart,
          SummaryTable: SummaryTableService,
          Treemap: Treemap,
          CombinationChart: CombinationChart,
          MapChart: MapChart,
        };

        return map[type];
      },
    };

    return visualization;
  });
