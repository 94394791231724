import isEqual from "lodash/isEqual";
import i18n from "@viz-ui/i18n/i18n";

const empty = {};

function isFormatUndefined(v) {
  return v === undefined || v === null || isEqual(v, empty);
}

const sectionService = {
  getDateFormatLabelKey: formatObj => {
    var template = key => `_Format.Date.${key}.Label_`;
    if (formatObj) {
      if (formatObj.isRaw) {
        return template("Raw");
      }

      if (formatObj.dateFormat) {
        return template(formatObj.dateFormat);
      }
    }
    return template("Default");
  },

  getLocalizedTimeFormat: () => i18n.t("_Format.Time.Default.Config_"),

  extractDateFormat: formatString => {
    if (typeof formatString === "undefined") {
      return undefined;
    }
    return formatString.split(" ")[0];
  },

  compareFormattingOptions: (a, b) => {
    if (a && b) {
      if (a.isRaw === true && b.isRaw === true) {
        return true;
      }

      return (
        (a.hasOnlyRadixSeparator === true) === (b.hasOnlyRadixSeparator === true) &&
        ((!a.dateFormat && !b.dateFormat) || a.dateFormat === b.dateFormat) &&
        ((!a.datetimeFormat && !b.datetimeFormat) ||
          sectionService.extractDateFormat(a.datetimeFormat) === sectionService.extractDateFormat(b.datetimeFormat)) &&
        ((!a.postfix && !b.postfix) || a.postfix === b.postfix) &&
        ((!a.prefix && !b.prefix) || a.prefix === b.prefix) &&
        ((a.sign === "(" && b.sign === "(") || (a.sign !== "(" && b.sign !== "("))
      );
    }

    return isFormatUndefined(a) && isFormatUndefined(b);
  },

  compareRoundingOptions: (a, b) =>
    (a &&
      b &&
      a.precision === b.precision &&
      ((!a.keepTrailingZeros && !b.keepTrailingZeros) || a.keepTrailingZeros === b.keepTrailingZeros) &&
      ((!a.abbreviate && !b.abbreviate) || a.abbreviate === b.abbreviate)) ||
    ((a === undefined || a === null || isEqual(a, empty)) && (b === undefined || b === null || isEqual(b, empty))),
};

export default sectionService;
