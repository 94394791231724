import ApiPath from "@viz-ui/services/apiPath/apiPathService";

angular.module("acl.visualizer.charts.backend").service("SummaryTableBackendService", (queuedHttp, DataModel) => {
  function url(tableId, chartIndex) {
    return ApiPath.table.getSummaryTableDataUrl(tableId) + "?chart_index=" + chartIndex;
  }

  return {
    fetchJson: (tableId, filter, config) => {
      const myRecords = DataModel.filterConfig.myRecords();
      const openStatuses = DataModel.filterConfig.openStatuses();
      const raw = DataModel.toSaveViz();
      const requestParams = { raw, myRecords, openStatuses };
      const chartIndex = requestParams.raw.visualizations.findIndex(d => {
        return angular.equals(d.config.dataConfig, config);
      });

      return queuedHttp.post(url(tableId, chartIndex), requestParams);
    },

    getRepresentation: resultData => resultData,
  };
});
