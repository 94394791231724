import RecordProcessPanel from "@viz-ui/components/RecordProcessPanel/RecordProcessPanel";
import ReactDirective from "acl-ui/angular/reactDirective.umd";
import ProcessPanelComponent from "./processPanel.component";
import ProcessPanelService from "./processPanel.service";

angular
  .module("acl.visualizer.processPanel", ["acl.common.config", "acl.visualizer.dataModel", "acl.visualizer.formatters"])
  .component("aclProcessPanel", ProcessPanelComponent)
  .directive("recordProcessPanel", ReactDirective.getFactory(RecordProcessPanel))
  .service("ProcessPanelService", ProcessPanelService);
