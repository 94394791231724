angular
  .module("acl.visualizer.charts")
  .factory("StackedAreaChart", function(ChartService, DataModel, Localize, AppConfig) {
    var defaultMargin = { top: 90, right: 90, bottom: 120, left: 100 };

    function getYAxisFormatter(interpretationId, chartConfig) {
      const { vizId, dataConfig, displayConfig } = chartConfig;
      if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
        const chartValueFieldName = `${vizId}-chart-value`;
        const chartValueFieldType = "numeric";
        return ChartService.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
      }
      return ChartService.getValueFormatter(
        interpretationId,
        dataConfig.chartValue.fieldName,
        dataConfig.chartValue.type
      );
    }
    return {
      defaultDisplayConfig: function() {
        return {
          showLegend: true,
          displayDataLabels: true,
          showControls: true,
          interpolate: false,
          enableBoost: true,
          xAxis: {
            showLabel: true,
          },
          yAxis: {
            showLabel: true,
            minimum: null,
            maximum: null,
          },
          chartStyle: "overlap",
        };
      },

      getChartDirectiveConfig: function(interpretationId, chartConfig) {
        var getColorByField = function() {
          return (
            (chartConfig &&
              chartConfig.dataConfig &&
              chartConfig.dataConfig.chartSeries &&
              chartConfig.dataConfig.chartSeries.fieldName) ||
            ""
          );
        };

        return {
          isInterpolated: !!chartConfig.dataConfig.isInterpolated,
          hasMultipleSeries: !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName),
          showLegend: chartConfig.displayConfig.showLegend,
          displayDataLabels: chartConfig.displayConfig.displayDataLabels,
          interpolate: chartConfig.displayConfig.interpolate,
          enableBoost: chartConfig.displayConfig.enableBoost,
          showControls: chartConfig.displayConfig.showControls,
          xAxis: {
            label: chartConfig.displayConfig.xAxis.showLabel
              ? chartConfig.displayConfig.xAxis.title
                ? chartConfig.displayConfig.xAxis.title
                : chartConfig.dataConfig.chartXAxis && chartConfig.dataConfig.chartXAxis.fieldName
                ? chartConfig.dataConfig.chartXAxis.displayName
                : chartConfig.dataConfig.chartSeries.displayName
              : "",
            tickFormatter:
              chartConfig.dataConfig.chartXAxis && chartConfig.dataConfig.chartXAxis.fieldName
                ? ChartService.getValueFormatter(
                    interpretationId,
                    chartConfig.dataConfig.chartXAxis.fieldName,
                    chartConfig.dataConfig.chartXAxis.type
                  )
                : ChartService.getValueFormatter(
                    interpretationId,
                    chartConfig.dataConfig.chartSeries.fieldName,
                    chartConfig.dataConfig.chartSeries.type
                  ),
            title: chartConfig.displayConfig.xAxis.title,
            axisType: chartConfig.dataConfig.chartXAxis.type,
          },
          yAxis: {
            label: chartConfig.displayConfig.yAxis.showLabel
              ? chartConfig.displayConfig.yAxis.title
                ? chartConfig.displayConfig.yAxis.title
                : ChartService.getFieldLabel(chartConfig.dataConfig.chartValue)
              : "",
            tickFormatter: getYAxisFormatter(interpretationId, chartConfig),
            title: chartConfig.displayConfig.yAxis.title,
            minimum: chartConfig.displayConfig.yAxis.minimum,
            maximum: chartConfig.displayConfig.yAxis.maximum,
          },
          DATA_ITEM_LIMIT: ChartService.getDataItemLimit(),
          colorByField: getColorByField(),
          labelFormatter: ChartService.getLabelFormatter(chartConfig, interpretationId),
          tooLargeMessage: Localize.getLocalizedString("_Chart.DataSetTooLarge.Label_"),
          chartStyle: chartConfig.displayConfig.chartStyle,
          controlLabels: {
            stacked: Localize.getLocalizedString("_Chart.ControlLabel.Stacked"),
            stream: Localize.getLocalizedString("_Chart.ControlLabel.Stream"),
            expanded: Localize.getLocalizedString("_Chart.ControlLabel.Expanded"),
          },
        };
      },

      populateChartConfigColumnDefs: function(chartConfigColumnDefs) {
        var tempColumnDef;
        var fields = DataModel.table.fields();
        if (fields) {
          for (var fieldName in fields) {
            tempColumnDef = {};
            tempColumnDef.displayName = fields[fieldName].displayName;
            tempColumnDef.fieldId = fields[fieldName].colId;
            tempColumnDef.fieldName = fieldName;
            tempColumnDef.type = fields[fieldName].type;

            switch (tempColumnDef.type) {
              case "character":
              case "logical":
                if (AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
                  chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                  chartConfigColumnDefs.chartSeries.push(tempColumnDef);
                }
                break;

              case "numeric":
                chartConfigColumnDefs.chartValue.push(tempColumnDef);
                chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                break;

              case "date":
              /* Falls through */
              case "datetime":
              /* Falls through */
              case "time":
                chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                break;
            }
          }
        }
        return chartConfigColumnDefs;
      },

      chartConfigColumnDefs: function() {
        return {
          chartSeries: [],
          chartXAxis: [],
          chartValue: [],
        };
      },

      isValidDataConfig: function(dataConfig) {
        return !!(
          dataConfig &&
          dataConfig.chartXAxis &&
          dataConfig.chartValue &&
          ((dataConfig.chartValue.aggregationType && dataConfig.chartValue.fieldName) ||
            dataConfig.chartValue.aggregationType === "count")
        );
      },
    };
  });
