import ChartDataAdapter from "@viz-ui/models/chart/chartDataAdapter";
import ChartData from "@viz-ui/models/chart/chartData";

angular
  .module("acl.visualizer.model.chart", [
    "acl.common.config",
    "acl.common.dataRequester",
    "acl.visualizer.model.filter",
    "acl.visualizer.model.model",
    "acl.visualizer.promiseCache",
  ])
  .factory("ChartDataAdapter", () => ChartDataAdapter)
  .factory("ChartData", () => ChartData);
