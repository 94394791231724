import i18n from "@viz-ui/i18n/i18n";
import { NumberFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import SectionService from "./sectionService";

export default function(fieldFormatObject) {
  const formattingObject = fieldFormatObject
    ? {
        hasOnlyRadixSeparator: fieldFormatObject.hasOnlyRadixSeparator,
        thousandsDelimiter: fieldFormatObject.thousandsDelimiter,
        prefix: fieldFormatObject.prefix,
        postfix: fieldFormatObject.postfix,
        sign: fieldFormatObject.sign,
      }
    : {};

  const roundingObject = fieldFormatObject
    ? {
        abbreviate: fieldFormatObject.abbreviate,
        keepTrailingZeros: fieldFormatObject.keepTrailingZeros,
        precision: fieldFormatObject.precision,
      }
    : {};

  const formatSection = {
    heading: i18n.t("_Format.Section.Label_"),
    items: getNumericFormattingOptions(formattingObject, roundingObject),
  };

  const roundingSection = {
    heading: i18n.t("_Format.Numeric.RoundingSection.Label_"),
    items: getNumericRoundingOptions(formattingObject, roundingObject),
  };

  return [formatSection, roundingSection];
}

function getNumericFormattingOptions(selectedFormattingObject, selectedRoundingObject) {
  var exampleNumber = -1234;

  const optionDefs = [
    {
      labelKey: "_Format.Numeric.None.Label_",
      format: {
        hasOnlyRadixSeparator: true,
        thousandsDelimiter: false,
        prefix: undefined,
        sign: undefined,
        postfix: undefined,
      },
    },
    {
      labelKey: "_Format.Numeric.Number.Label_",
      format: {
        hasOnlyRadixSeparator: false,
        thousandsDelimiter: true,
        prefix: undefined,
        sign: undefined,
        postfix: undefined,
      },
    }, // Default format.
    {
      labelKey: "_Format.Numeric.Currency.Label_",
      format: {
        hasOnlyRadixSeparator: false,
        thousandsDelimiter: true,
        prefix: "$",
        sign: undefined,
        postfix: undefined,
      },
    },
    {
      labelKey: "_Format.Numeric.Financial.Label_",
      format: {
        hasOnlyRadixSeparator: false,
        thousandsDelimiter: true,
        prefix: undefined,
        sign: "(",
        postfix: undefined,
      },
    },
    {
      labelKey: "_Format.Numeric.Percent.Label_",
      format: {
        hasOnlyRadixSeparator: false,
        thousandsDelimiter: true,
        prefix: undefined,
        sign: undefined,
        postfix: "%",
      },
    },
  ];

  return optionDefs.map(function(optionDef) {
    // The displayed formatting options should incorporate THIS formatting option, plus the selected ROUNDING option.
    var mergedFieldFormatJson = Object.assign({}, selectedRoundingObject, optionDef.format);
    var example = NumberFormatter.format(exampleNumber, mergedFieldFormatJson);

    return {
      example: example,
      format: mergedFieldFormatJson,
      isHeading: false,
      label: i18n.t(optionDef.labelKey),
      selected: SectionService.compareFormattingOptions(optionDef.format, selectedFormattingObject),
    };
  });
}

function getNumericRoundingOptions(selectedFormattingObject, selectedRoundingObject) {
  var exampleNumber = 1234.567;
  var optionDefs = [
    {
      labelKey: "_Format.Numeric.Exact.Label_",
      format: { precision: 5, keepTrailingZeros: false, abbreviate: false },
    },
    {
      labelKey: "_Format.Numeric.2Decimals.Label_",
      format: { precision: 2, keepTrailingZeros: true, abbreviate: false },
    },
    {
      labelKey: "_Format.Numeric.NoDecimals.Label_",
      format: { precision: 0, keepTrailingZeros: false, abbreviate: false },
    },
    {
      labelKey: "_Format.Numeric.Rounded.Label_",
      format: { precision: 2, keepTrailingZeros: false, abbreviate: true },
    },
  ];

  return optionDefs.map(function(optionDef) {
    // The displayed rounding options should incorporate THIS rounding option, plus the selected FORMAT option.
    var mergedFieldFormatJson = Object.assign({}, selectedFormattingObject, optionDef.format);

    return {
      example: NumberFormatter.format(exampleNumber, mergedFieldFormatJson),
      format: mergedFieldFormatJson,
      isHeading: false,
      isDisabled: selectedFormattingObject.isRaw,
      label: i18n.t(optionDef.labelKey),
      selected: SectionService.compareRoundingOptions(optionDef.format, selectedRoundingObject),
    };
  });
}
