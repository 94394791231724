angular.module("acl.storyboard.storyboard").directive("boardRowResizable", function() {
  return {
    restrict: "A",
    scope: {
      onResize: "=",
    },
    link: function($scope, $elem, $attrs) {
      let boardRow = $elem;
      boardRow.resizable({
        handles: "s",
        minHeight: 200,
        maxHeight: 700,
        start: function(event, ui) {
          ui.element.find(".text-board").each(function() {
            let textBoard = $(this);
            let editor = textBoard.find(".wysihtml5-input");
            textBoard.append(
              $(
                '<div class="ui-resizable-iframeFix" style="opacity:0;position:absolute;background:#fff;width:' +
                  editor.width() +
                  "px;height:" +
                  editor.height() +
                  'px;top:30px"></div>'
              )
            );
          });
        },
        stop: function(event, ui) {
          boardRow.find(".ui-resizable-iframeFix").remove();
          let rowHeight = ui.element.height();
          let rowIndex = ui.element.find("ul.sortable").attr("data-row-index");
          $scope.onResize(rowIndex, rowHeight);
        },
      });
    },
  };
});
