angular
  .module("acl.visualizer.summary")
  .controller("SummaryModalController", ($modalInstance, $modalStack, $scope, title, summary) => {
    $scope.model = {
      title: title,
      summary: summary,
    };

    $scope.forms = {};

    $scope.$watch("forms.summaryForm.$dirty", dirty => {
      if (dirty) {
        $modalStack.getTop().value.backdrop = "static";
      }
    });

    $scope.close = () => {
      $modalInstance.dismiss();
    };

    $scope.submit = () => {
      $modalInstance.close($scope.model.summary);
    };
  });
