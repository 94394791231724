import keys from "lodash/keys";
import sortBy from "lodash/sortBy";
import DataGrid from "@viz-ui/components/dataGrid/dataGrid";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";
import logger from "@viz-ui/services/logger/logger";

let hotSummaryTable = {
  restrict: "E",
  replace: true,
  controllerAs: "summaryTable",
  bindings: {
    data: "<",
    fieldFormatIdentifier: "<",
    fontZoomRatio: "<",
    isPresentationMode: "<",
    isShrinkWrapEnabled: "<",
    outsideClickDeselects: "<",
    fixedColumnsLeft: "<",
    displayConfig: "<",
  },
  controller: SummaryTableController,
};

function SummaryTableController(
  $element,
  $scope,
  AppConfig,
  ColumnConfig,
  Field,
  FieldFormatAdapter,
  SummaryTableAdapter,
  SummaryTableDataGenerator
) {
  "ngInject";

  let summaryTable = this;

  const DATA_PAGE_SIZE = 200;

  let currentData = [];
  let columnConfigs;
  let fields;
  let fieldFormatMap = new Map();
  let nestedHeaders;
  let skipCellValueFormatting = true;
  let summaryTableModel;
  let tableData = [];
  let totalRow;

  let tableRef;

  summaryTable.$onInit = function() {
    handleDataChange(summaryTable.data);
  };

  summaryTable.$onChanges = function(changesObj) {
    if (changesObj.data) {
      handleEmptySummaryTable(changesObj.data);
      handleDataChange(changesObj.data.currentValue);
    }
  };

  summaryTable.$onDestroy = function() {
    unmountDataGrid();
  };

  $scope.$on("chartRedraw", () => {
    if (tableRef) tableRef.redraw();
  });

  function handleEmptySummaryTable(changesData) {
    let previousConfig;
    let currentConfig;
    let currentValues;
    let hasPreviousRawData = changesData.previousValue && changesData.previousValue.rawData;
    let hasCurrentRawData = changesData.currentValue && changesData.currentValue.rawData;
    if (!AppConfig.features.queryService) {
      previousConfig = hasPreviousRawData ? changesData.previousValue.rawData.config : {};
      currentConfig = hasCurrentRawData ? changesData.currentValue.rawData.config : {};
      currentValues = hasCurrentRawData ? changesData.currentValue.rawData.values : [];
    } else {
      previousConfig = hasPreviousRawData ? changesData.previousValue.rawData().config : {};
      currentConfig = hasCurrentRawData ? changesData.currentValue.rawData().config : {};
      currentValues = hasCurrentRawData ? changesData.currentValue.rawData().values.summarizedData : [];
    }
    if (currentValues.length === 0 || !angular.equals(previousConfig, currentConfig)) {
      unmountDataGrid();
    }
  }

  function handleDataChange(data) {
    if (!data) return;
    skipCellValueFormatting = false;
    if (AppConfig.features.queryService) {
      let rawDataConfig = data.rawData().config;
      setupQueryServiceData(data);
      setupColumnConfigs(rawDataConfig.chartRows, data.columns);
      setupValuesConfigs(rawDataConfig.chartValues, data.valueColumnMap(), data.columns);
      renderTable();
    } else {
      let dataFieldFormatMap = FieldFormatAdapter.deserializeFieldFormatMap(data.formattingOptions);
      if (data.table && data.rawData && data.rawData.values.length) {
        summaryTableModel = SummaryTableDataGenerator.createSummaryTable(
          data.table.fields(),
          dataFieldFormatMap,
          data.rawData,
          displayTotals()
        );
        setupHeadersAndFields(summaryTableModel.columnDefs(), data.columns);
        initData();
        renderTable();
      }
    }
  }

  function setupQueryServiceData(data) {
    logger.log(`${summaryTable.fieldFormatIdentifier} - summary table setupQueryServiceData`);
    summaryTableModel = SummaryTableAdapter.processTableData(data, DATA_PAGE_SIZE);
    totalRow = summaryTableModel.totalRow();
    fields = summaryTableModel.headers();
    fieldFormatMap = summaryTableModel.fieldFormatMap();
    nestedHeaders = summaryTableModel.nestedHeaders();
    currentData = summaryTableModel.tableData();
    logger.log(`${summaryTable.fieldFormatIdentifier} - fields: ${fields ? fields.length : null}`);
    logger.log(`${summaryTable.fieldFormatIdentifier} - nestedHeaders: ${nestedHeaders ? nestedHeaders.length : null}`);
    logger.log(`${summaryTable.fieldFormatIdentifier} - currentData: ${currentData ? currentData.length : null}`);

    const rawData = summaryTableModel.rawData();
    tableData = rawData ? rawData.values.summarizedData : [];

    GlobalFieldFormatMap.setFieldFormats(summaryTable.fieldFormatIdentifier, fieldFormatMap);
    GlobalFieldFormatMap.setFieldTypes(summaryTable.fieldFormatIdentifier, fields);
  }

  function setupValuesConfigs(chartValues = [], valueColumnMap = [], columns = []) {
    chartValues.map((chartValue, index) => {
      const columnConfigObj = columns.find(column => column.fieldName === chartValue.fieldName);
      if (columnConfigObj) {
        const cols = Object.fromEntries(
          Object.entries(valueColumnMap).filter(([key]) => JSON.parse(key).value === `__valueField${index}`)
        );
        Object.values(cols).map(name => {
          columnConfigs.push(
            new ColumnConfig()
              .name(name)
              .visible(true)
              .wordWrap(columnConfigObj.wordWrap)
              .width(columnConfigObj.columnWidth)
          );
        });
      }
    });
    columnConfigs = sortBy(columnConfigs, [oColumn => Number(oColumn._data.name.match(/\d*$/)[0])]);
  }

  function setupColumnConfigs(chartRows = [], columns = []) {
    columnConfigs = chartRows
      .map((rowField, index) => {
        const columnConfigObj = columns.find(column => column.fieldName === rowField.fieldName);
        if (columnConfigObj) {
          return new ColumnConfig()
            .name(`col${index + 1}`)
            .visible(true)
            .wordWrap(columnConfigObj.wordWrap)
            .width(columnConfigObj.columnWidth);
        }
      })
      .filter(columnConfig => columnConfig);
  }

  function renderTable() {
    const skipFormattingOnCells = getSkipFormattingOnCells();
    const componentProps = {
      columnConfigs: columnConfigs,
      data: displayTotals() && totalRow ? currentData.concat(totalRow) : currentData,
      fieldFormatIdentifier: summaryTable.fieldFormatIdentifier,
      fields: fields,
      manualColumnResize: false,
      fixedRowsBottom: hasTotalRow() ? 1 : 0,
      fontZoomRatio: summaryTable.fontZoomRatio,
      isPresentationMode: summaryTable.isPresentationMode,
      isRowHighlightingEnabled: true,
      isShrinkWrapEnabled: summaryTable.isShrinkWrapEnabled,
      mergeCells: mergeCells(),
      nestedHeaders: nestedHeaders,
      onScrollToBottom: onScrollToBottom,
      outsideClickDeselects: summaryTable.outsideClickDeselects,
      skipCellValueFormatting,
      skipFormattingOnCells,
      fixedColumnsLeft: summaryTable.fixedColumnsLeft,
      sourcePage: "summary_table",
    };

    tableRef = ReactDOM.render(React.createElement(DataGrid, componentProps), $element[0]);
  }

  function getSkipFormattingOnCells() {
    if (displayTotals() && totalRow) {
      const lastRowIndex = currentData.length;
      let totalCellFieldName = "col_0";
      if (AppConfig.features.queryService) {
        totalCellFieldName = "col1";
      }
      return [{ rowIndex: lastRowIndex, fieldName: totalCellFieldName }];
    }
    return [];
  }

  function setupHeadersAndFields(columnDefs, columnConfigObjs) {
    nestedHeaders = initNestedHeader();
    fields = [];
    columnConfigs = [];
    columnDefs.forEach(columnDef => {
      const columnConfigObj = columnConfigObjs.find(columnConfig => columnConfig.fieldName === columnDef.fieldName);
      let field = new Field()
        .name(columnDef.field)
        .displayName(columnDef.displayName)
        .type(columnDef.type);
      addFieldToHeader(field);
      addColumnConfig(field, columnDef, columnConfigObj);
      addColumnDefToNestedHeaders(field, columnDef);
    });

    fieldFormatMap = summaryTableModel.fieldFormatMap();
    GlobalFieldFormatMap.setFieldFormats(summaryTable.fieldFormatIdentifier, fieldFormatMap);
    GlobalFieldFormatMap.setFieldTypes(summaryTable.fieldFormatIdentifier, fields);
  }

  function initData() {
    tableData = summaryTableModel.tableData();
    totalRow = hasTotalRow() ? tableData.pop() : undefined;
    currentData = getRowsForDataGrid(tableData, 0, DATA_PAGE_SIZE - 1);
  }

  function getRowsForDataGrid(data, start, end) {
    return data.slice(start, end);
  }

  function initNestedHeader() {
    let headerRowspan = getHeaderRowspan();
    let newNestedHeaders = [];
    while (headerRowspan > 1) {
      newNestedHeaders.push([]);
      headerRowspan -= 1;
    }
    return newNestedHeaders;
  }

  function addColumnConfig(field, columnDef, columnConfigObj = {}) {
    columnConfigs.push(
      new ColumnConfig()
        .name(field.name())
        .visible(true)
        .wordWrap(!!columnConfigObj.wordWrap)
        .width(columnConfigObj.columnWidth)
    );
  }

  function addFieldToHeader(field) {
    fields.push(field);
  }

  function addColumnDefToNestedHeaders(field, columnDef) {
    if (columnDef.column) {
      nestedHeaders.forEach((headerRow, index) => {
        let nestedColumnField;
        if (index === 0) {
          nestedColumnField = new Field()
            .name(columnDef.column.displayName)
            .displayName(columnDef.column.displayName)
            .type(columnDef.column.type);
        } else {
          nestedColumnField = new Field()
            .name(columnDef.column.displayValue)
            .displayName(columnDef.column.displayValue)
            .type(columnDef.column.type);
        }
        addNestedColspanHeader(headerRow, nestedColumnField);
      });
    } else {
      let headerRowspan = getHeaderRowspan();
      nestedHeaders.forEach((headerRow, index) => {
        let nestedHeaderObj = {};
        nestedHeaderObj.field = field;
        if (index === 0) nestedHeaderObj.rowspan = headerRowspan;
        headerRow.push(nestedHeaderObj);
      });
    }
  }

  function addNestedColspanHeader(headerRow, nestedColumnField) {
    let lastHeaderItem = headerRow[headerRow.length - 1];
    if (lastHeaderItem && lastHeaderItem.field && lastHeaderItem.field.name() === nestedColumnField.name()) {
      lastHeaderItem.colspan += 1;
    } else {
      headerRow.push({ field: nestedColumnField, colspan: 1 });
    }
  }

  function onScrollToBottom() {
    if (currentData.length < tableData.length) {
      if (!AppConfig.features.queryService) {
        let nextPatchEndIndex =
          currentData.length + DATA_PAGE_SIZE > tableData.length
            ? tableData.length
            : currentData.length + DATA_PAGE_SIZE;
        let nextPatchData = tableData.slice(currentData.length, nextPatchEndIndex);
        currentData = currentData.concat(getRowsForDataGrid(nextPatchData));
      } else {
        summaryTableModel = SummaryTableAdapter.processTableData(summaryTableModel, DATA_PAGE_SIZE);
        currentData = summaryTableModel.tableData();
      }
      renderTable();
    }
  }

  function getHeaderRowspan() {
    let rawData = summaryTableModel.rawData();
    let config = getConfig(rawData);
    let rowspan = 1;
    if (keys(config.column).length) {
      rowspan += 1;
      if (config.values.length) {
        rowspan += 1;
      }
    }
    return rowspan;
  }

  function mergeCells() {
    if (hasTotalRow()) {
      let rawData = summaryTableModel.rawData();
      let config = getConfig(rawData);
      return [{ col: 0, row: currentData.length, colspan: config.rows.length, rowspan: 1 }];
    }

    return [];
  }

  function hasTotalRow() {
    if (!displayTotals()) return false;
    let summTableData = summaryTableModel.tableData();
    let rawData = summaryTableModel.rawData();
    let config = getConfig(rawData);
    return summTableData.length > 1 && config.values.length > 0;
  }

  function displayTotals() {
    return summaryTable.displayConfig.showTotals;
  }

  function getConfig(rawData) {
    if (!AppConfig.features.queryService) {
      return rawData.config;
    }
    return {
      rows: rawData.config.chartRows,
      column: rawData.config.chartColumns.length ? rawData.config.chartColumns[0] : undefined,
      values: rawData.config.chartValues,
    };
  }

  function unmountDataGrid() {
    if (tableRef && $element[0]) {
      ReactDOM.unmountComponentAtNode($element[0]);
    }
  }
}

angular.module("acl.visualizer.summaryTable").component("hotSummaryTable", hotSummaryTable);
