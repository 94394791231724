import User from "../services/user.service";

const aclDrilldownButton = {
  bindings: {
    boardModel: "<",
    editMode: "<",
    onDrilldownClick: "&",
    onMouseEnterDrilldown: "&",
    onMouseLeaveDrilldown: "&",
  },
  controllerAs: "drilldownButton",
  template: `
    <a
      class="storyboard-icon storyboard-goto-board"
      ng-if="drilldownButton.showDrilldown"
      ng-click="drilldownButton.onDrilldownClick()"
      ng-mouseenter="drilldownButton.onMouseEnterDrilldown()"
      ng-mouseleave="drilldownButton.onMouseLeaveDrilldown()">
      <i class="acl-i-graph-examine"/>
    </a>`,
  controller: DrilldownButtonController,
};

const ACCESS_CHECKER_ENTITIES_MAP = {
  visualization: "interpretation",
  table: "interpretation",
  metric: "metric",
  trigger: "metric",
};

function DrilldownButtonController(AccessChecker) {
  "ngInject";

  let drilldownButton = this;

  drilldownButton.showDrilldown = false;

  drilldownButton.$onChanges = () => {
    const { boardModel } = drilldownButton;
    if (boardModel && !shouldErrorHideDrilldown(boardModel)) {
      setDrilldown(boardModel);
    }
  };

  function shouldErrorHideDrilldown(boardModel) {
    return boardModel.boardError() && !boardModel.boardError().isRecoverable();
  }

  async function setDrilldown(boardModel) {
    const isAnonymous = User.isAnonymous();
    const currentUser = User.getCurrentUser();
    if (isAnonymous) {
      if (currentUser) {
        drilldownButton.showDrilldown = await retrieveDrilldown(boardModel);
      } else {
        drilldownButton.showDrilldown = true;
      }
    } else if (boardModel) {
      const { editMode } = drilldownButton;
      const canDrilldown = isMetricOrTrigger(boardModel)
        ? await retrieveDrilldown(boardModel)
        : boardModel.canDrilldown();
      drilldownButton.showDrilldown = !editMode && canDrilldown;
    }
  }

  function isMetricOrTrigger(boardModel) {
    return ["metric", "trigger"].includes(boardModel.type());
  }

  function retrieveDrilldown(boardModel) {
    const entityType = AccessChecker.entities[ACCESS_CHECKER_ENTITIES_MAP[boardModel.type()]];
    const identifier = getAccessCheckerIdenifier(boardModel);
    return AccessChecker.can(AccessChecker.actions.drilldown, entityType, identifier);
  }

  function getAccessCheckerIdenifier(boardModel) {
    switch (boardModel.type()) {
      case "visualization":
      case "table":
        return boardModel.interpretation() ? boardModel.interpretation().id : undefined;
      case "metric":
      case "trigger":
        return boardModel.metricId();
    }
  }
}

export default aclDrilldownButton;
