import React, { Component } from "react";
import PropTypes from "prop-types";
import Confirmation from "@paprika/confirmation";
import TrashIcon from "@paprika/icon/lib/Trashbin";
import i18n from "@viz-ui/i18n/i18n";
import "./DeleteButton.scss";

export default class DeleteButton extends Component {
  static propTypes = {
    align: PropTypes.string,
    isDisabled: PropTypes.bool,
    onDeleted: PropTypes.func,
  };

  static defaultProps = {
    align: "top",
    isDisabled: false,
    onDeleted: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      isDeleting: false,
    };
  }

  handleClickDeleteRecord = () => {
    this.setState({
      isDeleting: true,
    });
    this.props.onDeleted().then(() => {
      this.setState({ isDeleting: false });
    });
  };

  render() {
    return (
      <div className="record-processing__delete-button">
        <Confirmation
          align={this.props.align}
          confirmLabel={i18n.t("_RecordProcessing.DeleteRecords.Destroy_")}
          onConfirm={this.handleClickDeleteRecord}
          isPending={this.state.isDeleting}
          body={i18n.t("_RecordProcessing.DeleteRecordsBody_")}
          confirmButtonType="destructive"
          heading={i18n.t("_RecordProcessing.DeleteRecordsHeading_")}
          zIndex={302}
        >
          <Confirmation.TriggerButton isDisabled={this.props.isDisabled} buttonType="icon">
            <TrashIcon />
          </Confirmation.TriggerButton>
        </Confirmation>
      </div>
    );
  }
}
