export default function CombinationChartService(
  $filter,
  ChartDataSorter,
  ChartService,
  AppConfig,
  DataModel,
  Localize
) {
  "ngInject";

  return {
    defaultDisplayConfig,
    getChartDirectiveConfig,
    populateChartConfigColumnDefs,
    chartConfigColumnDefs,
    isValidDataConfig,
    reorderValues,
  };

  function getXAxisLabel(dataConfig, displayConfig, labelTemplate, hasMultipleSeries) {
    if (displayConfig.xAxis.showLabel) {
      if (displayConfig.xAxis.title) {
        return displayConfig.xAxis.title;
      }
      if (hasMultipleSeries) {
        return $filter("format")(labelTemplate, [
          dataConfig.chartXAxis.displayName,
          dataConfig.chartSeries.displayName,
        ]);
      }
      return dataConfig.chartXAxis.displayName;
    }
    return "";
  }

  function getYAxisLabel(dataConfig, displayConfig, index) {
    if (displayConfig.yAxis[index].showLabel) {
      if (displayConfig.yAxis[index].title) {
        return displayConfig.yAxis[index].title;
      }
      return ChartService.getFieldLabel(dataConfig.chartValues[index]);
    }
    return "";
  }

  function defaultDisplayConfig() {
    return {
      smoothEdged: false,
      showLegend: true,
      displayDataLabels: true,
      showControls: true,
      enableBoost: true,
      showDualYAxis: false,
      xAxis: {
        showAxis: true,
        showLabel: true,
      },
      yAxis: [
        {
          showAxis: true,
          showLabel: true,
          minimum: null,
          maximum: null,
        },
        {
          showAxis: true,
          showLabel: true,
          minimum: null,
          maximum: null,
        },
      ],
      chartStyle: "group",
    };
  }

  function getChartDirectiveConfig(interpretationId, chartConfig) {
    var getColorByField = function() {
      return (
        (chartConfig &&
          chartConfig.dataConfig &&
          chartConfig.dataConfig.chartSeries &&
          chartConfig.dataConfig.chartSeries.fieldName) ||
        ""
      );
    };
    var categoryLabelTemplate = Localize.getLocalizedString("_Chart.SingleNestedAxisLabel.Template_");
    var hasMultipleSeries = !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName);

    function getFirstYAxisFormatter(interpretationId, chartConfig) {
      const { vizId, displayConfig } = chartConfig;
      if (
        displayConfig.valueFormattingOptionsFirstYAxis &&
        chartConfig.dataConfig.chartValues[0].aggregationType === "average"
      ) {
        const chartValueFieldName = `${vizId}-chart-value-FirstYAxis`;
        const chartValueFieldType = "numeric";
        return ChartService.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
      }
      return ChartService.getValueFormatter(
        interpretationId,
        chartConfig.dataConfig.chartValues[0].fieldName,
        chartConfig.dataConfig.chartValues[0].type
      );
    }

    function getSecondYAxisFormatter(interpretationId, chartConfig) {
      const { vizId, displayConfig } = chartConfig;
      if (
        displayConfig.valueFormattingOptionsSecondYAxis &&
        chartConfig.dataConfig.chartValues[1].aggregationType === "average"
      ) {
        const chartValueFieldName = `${vizId}-chart-value-SecondYAxis`;
        const chartValueFieldType = "numeric";
        return ChartService.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
      }
      return ChartService.getValueFormatter(
        interpretationId,
        chartConfig.dataConfig.chartValues[1].fieldName,
        chartConfig.dataConfig.chartValues[1].type
      );
    }

    return {
      order: chartConfig.displayConfig.order,
      valuesOrder: chartConfig.displayConfig.valuesOrder,
      hasMultipleSeries: hasMultipleSeries,
      showLegend: chartConfig.displayConfig.showLegend,
      displayDataLabels: chartConfig.displayConfig.displayDataLabels,
      showControls: chartConfig.displayConfig.showControls,
      smoothEdged: chartConfig.displayConfig.smoothEdged,
      enableBoost: chartConfig.displayConfig.enableBoost,
      showDualYAxis: chartConfig.displayConfig.showDualYAxis,
      xAxis: {
        label: getXAxisLabel(
          chartConfig.dataConfig,
          chartConfig.displayConfig,
          categoryLabelTemplate,
          hasMultipleSeries
        ),
        tickFormatter: ChartService.getValueFormatter(
          interpretationId,
          chartConfig.dataConfig.chartXAxis.fieldName,
          chartConfig.dataConfig.chartXAxis.type
        ),
        title: chartConfig.displayConfig.xAxis.title,
        showAxis: chartConfig.displayConfig.xAxis.showAxis,
        fieldType: chartConfig.dataConfig.chartXAxis.type,
      },
      yAxis: [
        {
          label: getYAxisLabel(chartConfig.dataConfig, chartConfig.displayConfig, 0),
          tickFormatter: getFirstYAxisFormatter(interpretationId, chartConfig),
          minimum: chartConfig.displayConfig.yAxis[0].minimum,
          maximum: chartConfig.displayConfig.yAxis[0].maximum,
          title: chartConfig.displayConfig.yAxis[0].title,
          showAxis: chartConfig.displayConfig.yAxis[0].showAxis,
        },
        {
          label: getYAxisLabel(chartConfig.dataConfig, chartConfig.displayConfig, 1),
          tickFormatter: getSecondYAxisFormatter(interpretationId, chartConfig),
          minimum: chartConfig.displayConfig.yAxis[1].minimum,
          maximum: chartConfig.displayConfig.yAxis[1].maximum,
          title: chartConfig.displayConfig.yAxis[1].title,
          showAxis: chartConfig.displayConfig.yAxis[1].showAxis,
        },
      ],
      chartSeries: chartConfig.dataConfig.chartSeries,
      DATA_ITEM_LIMIT: ChartService.getDataItemLimit(),
      colorByField: getColorByField(),
      labelFormatter: ChartService.getLabelFormatter(chartConfig, interpretationId),
      chartStyle: chartConfig.displayConfig.chartStyle,
    };
  }

  function populateChartConfigColumnDefs(chartConfigColumnDefsObj) {
    var tempColumnDef;
    var fields = DataModel.table.fields();
    if (fields) {
      for (var fieldName in fields) {
        tempColumnDef = {};
        tempColumnDef.displayName = fields[fieldName].displayName;
        tempColumnDef.fieldName = fieldName;
        tempColumnDef.type = fields[fieldName].type;

        switch (tempColumnDef.type) {
          case "character":
          case "logical":
            if (AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
              chartConfigColumnDefsObj.chartSeries.push(tempColumnDef);
              chartConfigColumnDefsObj.chartXAxis.push(tempColumnDef);
            }
            break;
          case "numeric":
            chartConfigColumnDefsObj.chartValues.push(tempColumnDef);
            chartConfigColumnDefsObj.chartXAxis.push(tempColumnDef);
            break;
          case "date":
          case "datetime":
          case "time":
            chartConfigColumnDefsObj.chartXAxis.push(tempColumnDef);
            break;
        }
      }
    }
    return chartConfigColumnDefsObj;
  }

  function chartConfigColumnDefs() {
    return {
      chartSeries: [],
      chartXAxis: [],
      chartValues: [],
    };
  }

  function isValidDataConfig(dataConfig) {
    let result = !!(
      dataConfig &&
      dataConfig.chartXAxis &&
      dataConfig.chartValues &&
      dataConfig.chartValues.length === 2 &&
      (dataConfig.chartValues[0].aggregationType === "count" ||
        (dataConfig.chartValues[0].aggregationType && dataConfig.chartValues[0].fieldName)) &&
      (dataConfig.chartValues[1].aggregationType === "count" ||
        (dataConfig.chartValues[1].aggregationType && dataConfig.chartValues[1].fieldName))
    );
    return result;
  }

  function reorderValues(chartData, sortConfig) {
    return ChartDataSorter.reorderValues(chartData, sortConfig);
  }
}
