angular
  .module("acl.visualizer.charts")
  .factory(
    "ChartBaseInterface",
    (
      BarChart,
      BubbleChart,
      HeatmapService,
      LineChart,
      PieChart,
      StackedAreaChart,
      StatisticsViz,
      SummaryTableService,
      Treemap,
      CombinationChart,
      MapChart
    ) => {
      return {
        populateChartConfigColumnDefs: populateChartConfigColumnDefs,
      };

      function populateChartConfigColumnDefs(chartType, fields) {
        var columnDefs = {};

        switch (chartType) {
          case "BarChart":
            columnDefs = BarChart.chartConfigColumnDefs();
            BarChart.populateChartConfigColumnDefs(columnDefs);
            break;
          case "BubbleChart":
            columnDefs = BubbleChart.chartConfigColumnDefs();
            BubbleChart.populateChartConfigColumnDefs(columnDefs);
            break;
          case "LineChart":
            columnDefs = LineChart.chartConfigColumnDefs();
            LineChart.populateChartConfigColumnDefs(columnDefs);
            break;
          case "StatisticsViz":
            columnDefs = StatisticsViz.chartConfigColumnDefs();
            StatisticsViz.populateChartConfigColumnDefs(columnDefs);
            break;
          case "PieChart":
            columnDefs = PieChart.chartConfigColumnDefs();
            PieChart.populateChartConfigColumnDefs(columnDefs);
            break;
          case "StackedAreaChart":
            columnDefs = StackedAreaChart.chartConfigColumnDefs();
            StackedAreaChart.populateChartConfigColumnDefs(columnDefs);
            break;
          case "SummaryTable":
            columnDefs = SummaryTableService.populateChartConfigColumnDefs(fields);
            break;
          case "Heatmap":
            columnDefs = HeatmapService.chartConfigColumnDefs();
            HeatmapService.populateChartConfigColumnDefs(columnDefs);
            break;
          case "Treemap":
            columnDefs = Treemap.chartConfigColumnDefs();
            Treemap.populateChartConfigColumnDefs(columnDefs);
            break;
          case "CombinationChart":
            columnDefs = CombinationChart.chartConfigColumnDefs();
            CombinationChart.populateChartConfigColumnDefs(columnDefs);
            break;
          case "MapChart":
            columnDefs = MapChart.chartConfigColumnDefs();
            MapChart.populateChartConfigColumnDefs(columnDefs);
            break;
          default:
            throw new Error("Unexpected chart type: " + chartType);
        }

        return columnDefs;
      }
    }
  );
