import Button from "@paprika/button";
import Modal from "@paprika/modal";
import { zValue } from "@paprika/stylers/lib/helpers";
import PropTypes from "prop-types";
import React from "react";
import i18n from "@viz-ui/i18n/i18n";
import "./confirmationModal.scss";

class ConfirmationModal extends React.Component {
  static propTypes = {
    openModal: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    confirmButtonText: PropTypes.string.isRequired,
    confirmButtonType: PropTypes.string.isRequired,
    headerText: PropTypes.string.isRequired,
    bodyText: PropTypes.string,
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    openModal: false,
    bodyText: "",
    zIndex: zValue(6),
  };

  constructor(props) {
    super(props);
    this.confirmButtonRef = React.createRef();
  }

  componentDidUpdate() {
    this.focusConfirmButton();
  }

  focusConfirmButton() {
    if (this.confirmButtonRef.current) {
      setTimeout(() => {
        this.confirmButtonRef.current && this.confirmButtonRef.current.focus();
      }, 300);
    }
  }

  render() {
    return (
      <Modal zIndex={this.props.zIndex} isOpen={this.props.openModal} onClose={this.props.onCancel}>
        <Modal.Header>{this.props.headerText}</Modal.Header>
        <Modal.Content>{this.props.bodyText}</Modal.Content>
        <Modal.Footer className="modal-footer">
          <Button
            ref={this.confirmButtonRef}
            className="footer-button--confirm"
            onClick={this.props.onConfirm}
            kind={this.props.confirmButtonType}
            data-testid="primary-button"
          >
            {this.props.confirmButtonText}
          </Button>
          <Button
            data-testid="cancel-button"
            onClick={this.props.onCancel}
            kind="minor"
            className="footer-button--cancel"
          >
            {i18n.t("_General.Cancel.Label_")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmationModal;
window.ConfirmationModal = ConfirmationModal;
