import React from "react";
import PropTypes from "prop-types";
import Button from "@paprika/button";
import GraphExamine from "@acl-services/wasabicons/lib/GraphExamine";
import "./storyboardDrilldownButton.scss";
import User from "@storyboards/modules/services/user.service";
import MetricBoard from "@storyboards/modules/models/metricBoard";
import TableBoard from "@storyboards/modules/models/tableBoard";
import VisualizationBoard from "@storyboards/modules/models/visualizationBoard";

const propTypes = {
  boardModel: PropTypes.oneOfType([
    PropTypes.instanceOf(VisualizationBoard),
    PropTypes.instanceOf(TableBoard),
    PropTypes.instanceOf(MetricBoard),
  ]),
  editMode: PropTypes.bool.isRequired,
  onDrilldownClick: PropTypes.func,
  onMouseEnterDrilldown: PropTypes.func.isRequired,
  onMouseLeaveDrilldown: PropTypes.func.isRequired,
  presentationMode: PropTypes.bool.isRequired,
  isDrilldown: PropTypes.bool,
};

const defaultProps = {
  boardModel: null,
  editMode: false,
  onDrilldownClick: () => {},
  onMouseEnterDrilldown: () => {},
  onMouseLeaveDrilldown: () => {},
  presentationMode: false,
  isDrilldown: false,
};

export default function StoryBoardDrilldownButton(props) {
  const {
    boardModel,
    editMode,
    onDrilldownClick,
    onMouseEnterDrilldown,
    onMouseLeaveDrilldown,
    presentationMode,
    isDrilldown,
  } = props;
  let showDrilldown = false;
  if (boardModel && !shouldErrorHideDrilldown()) {
    setDrilldown(boardModel);
  }
  function setDrilldown() {
    const isAnonymous = User.isAnonymous();
    const currentUser = User.getCurrentUser();
    if (isAnonymous) {
      if (currentUser) {
        showDrilldown = isDrilldown;
      } else {
        showDrilldown = true;
      }
    } else if (boardModel) {
      const canDrilldownbtn = isMetricOrTrigger() ? isDrilldown : boardModel.canDrilldown();
      showDrilldown = !editMode && canDrilldownbtn;
    }
    if (presentationMode) {
      showDrilldown = false;
    }
  }
  function shouldErrorHideDrilldown() {
    return boardModel.boardError() && !boardModel.boardError().isRecoverable();
  }
  function isMetricOrTrigger() {
    return ["metric", "trigger"].includes(boardModel.type());
  }
  if (!showDrilldown) {
    return " ";
  } else {
    return (
      <div
        className="storyboard-drilldown-button"
        aria-label="drilldown-container"
        data-testid="_storyboard-drilldown__button"
      >
        <Button.Icon
          aria-label="drilldown-button"
          kind="primary"
          onClick={() => onDrilldownClick()}
          onMouseEnter={onMouseEnterDrilldown}
          onMouseLeave={onMouseLeaveDrilldown}
        >
          <GraphExamine />
        </Button.Icon>
      </div>
    );
  }
}
StoryBoardDrilldownButton.propTypes = propTypes;
StoryBoardDrilldownButton.defaultProps = defaultProps;
StoryBoardDrilldownButton.displayName = "StoryBoardDrilldownButton";
