import backendApi from "@results/services/apiCall/backendApi";
import ApiPath from "@viz-ui/services/apiPath/apiPathService";

export default ($q, Metric, MetricSparkline, MetricSummary) => {
  "ngInject";

  class MetricBackendService {
    metricRequested(metricId) {
      var url = ApiPath.metric.getMetricUrl(metricId);

      return backendApi.get(url).then(function(response) {
        if (response.data) {
          return Metric.fromJson(response.data);
        }
        return $q.reject();
      });
    }

    metricCreated(metric) {
      var url = ApiPath.metric.createMetricUrl();
      var data = {
        metric: metric.toJson(),
      };

      return backendApi.post(url, {}, data).then(
        function(response) {
          if (response.data) {
            return Metric.fromJson(response.data);
          }
          return $q.reject();
        },
        function(error) {
          return $q.reject(error);
        }
      );
    }

    metricChanged(metric) {
      var data = {
        metric: metric.toJson(),
      };
      var url = ApiPath.metric.getMetricUrl(metric.id());

      return backendApi.put(url, {}, data).then(
        function(response) {
          if (response.data) {
            return Metric.fromJson(response.data);
          }
          return $q.reject();
        },
        function(error) {
          return $q.reject(error);
        }
      );
    }

    metricDeleted(metric) {
      var url = ApiPath.metric.getMetricUrl(metric.id());
      return backendApi.delete(url);
    }

    metricsRequested() {
      var url = ApiPath.metric.getMetricsUrl();

      return backendApi.get(url).then(
        function(response) {
          if (response.data) {
            return response.data.map(function(metricData) {
              return Metric.fromJson(metricData);
            });
          }
          return $q.reject();
        },
        function(error) {
          return $q.reject(error);
        }
      );
    }

    metricSummaryRequested(metric) {
      var url = ApiPath.metric.getMetricSummaryUrl();
      var data = {
        metric: metric.toJson(),
      };

      return backendApi.post(url, {}, data).then(
        function(response) {
          if (response.data) {
            return MetricSummary.fromJson(metric, response.data);
          }
          return $q.reject();
        },
        function(error) {
          return $q.reject(error);
        }
      );
    }

    metricSparklineRequested(metric, timezoneOffset, controlTestId, globalFilters) {
      const url = ApiPath.metric.getMetricTimelineUrl(metric.id());
      const utcOffsetString = timezoneOffset ? timezoneOffset.utcOffsetString() : undefined;
      const data = {
        metric: metric.toJson(),
        globalFilters,
        timezone_offset: utcOffsetString,
      };

      return backendApi.post(url, {}, data).then(
        function(response) {
          if (response.data) {
            return MetricSparkline.fromJson(response.data);
          }
          return $q.reject();
        },
        function(error) {
          return $q.reject(error);
        }
      );
    }
  }

  return new MetricBackendService();
};
