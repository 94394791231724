import services from "../../services/glue/appGlue";

(() => {
  class VisualizationValidator {
    constructor(
      AppConfig,
      BarChart,
      BubbleChart,
      HeatmapService,
      LineChart,
      PieChart,
      StackedAreaChart,
      SummaryTableService,
      Treemap,
      MapChart
    ) {
      this._AppConfig = AppConfig;
      this._chartService = {
        BarChart: BarChart,
        BubbleChart: BubbleChart,
        Heatmap: HeatmapService,
        LineChart: LineChart,
        PieChart: PieChart,
        StackedAreaChart: StackedAreaChart,
        SummaryTable: SummaryTableService,
        Treemap: Treemap,
        MapChart: MapChart,
      };
    }

    isValid(visualization) {
      if (
        !visualization ||
        !visualization.id ||
        !visualization.type ||
        !visualization.config ||
        !visualization.config.dataConfig
      ) {
        return false;
      }

      return (
        !!this._chartService[visualization.type] &&
        this._chartService[visualization.type].isValidDataConfig(visualization.config.dataConfig)
      );
    }

    hasVisualizationData(visualization, visualizationData) {
      var chartType = visualization.vizType || visualization.type;
      if (visualization) {
        switch (chartType) {
          case "SummaryTable":
            return this.summaryTableHasData(visualizationData);
          case "Treemap":
            return this.treemapHasData(visualizationData);
          case "MapChart":
            return this.mapChartHasData(visualizationData);
          default:
            return visualizationData.representation().length > 0;
        }
      }
    }

    interpretationError(interpretation, visualization) {
      if (interpretation && visualization) {
        if (interpretation.error || visualization.error) {
          return interpretation.error || visualization.error;
        } else if (!this.isValid(visualization)) {
          return services.Localize.getLocalizedString("_Storyboards.Visualization.Invalid_");
        }
      } else {
        return "";
      }
    }

    summaryTableHasData(visualizationData) {
      if (this._AppConfig.features.queryService) {
        let summaryTableModel;
        summaryTableModel = visualizationData;
        return summaryTableModel.rawData().values.summarizedData.length > 0;
      }
      return visualizationData.values.length > 0;
    }

    treemapHasData(visualizationData) {
      if (this._AppConfig.features.queryService) {
        const leafLayerIndex = visualizationData.data().config.chartRows.length - 1;
        return visualizationData.data().values[`layer${leafLayerIndex}`].length > 0;
      }
      return visualizationData.values.length > 0;
    }

    mapChartHasData(visualizationData) {
      if (this._AppConfig.features.queryService) {
        return visualizationData.data().values.length > 0;
      }
      return visualizationData.values.length > 0;
    }
  }

  angular.module("acl.storyboard.visualization").service("VisualizationValidator", VisualizationValidator);
})();
