import pick from "lodash/pick";
import values from "lodash/values";
import isEqual from "lodash/isEqual";
import moment from "moment";

const AGGREGATION_TYPE_MAP = { average: "avg" };

export default function(ChartService, Localize, Sorter) {
  "ngInject";

  class TreemapQueryDataConverter {
    constructor(chartDataModel, vizConfig) {
      this._chartDataModel = chartDataModel;
      this._vizConfig = vizConfig;
    }

    convertToHighChartData() {
      let resultData = [];
      const rawData = this._chartDataModel.data();
      const layerFields = rawData.config.chartRows;
      const valueFields = rawData.config.chartValues;

      if (!hasColorValue(valueFields)) this._getDiscreteColorGenerator(layerFields);

      layerFields.forEach((layerField, layerIndex) => {
        const layerData = rawData.values[`layer${layerIndex}`];
        const currentLayerFields = layerFields.slice(0, layerIndex + 1);
        sortData(layerData, currentLayerFields);
        const convertedLayerData = layerData.map(layerRowData =>
          this._convertLayerRowData(layerRowData, layerIndex, layerFields, valueFields)
        );
        resultData = [...resultData, ...convertedLayerData];
      });

      return resultData;
    }

    getFlattenedColorValues() {
      const config = this._chartDataModel.data().config;
      const valueFields = config.chartValues;
      const rawData = this._chartDataModel.data().values;
      const leafLayerIndex = config.chartRows.length - 1;
      const aggregationType = config.chartValues[1].aggregationType;
      const colorValueAggFunction = AGGREGATION_TYPE_MAP[aggregationType] || aggregationType;
      const colorValueKey = isValueAndColorValueEqual(valueFields) ? "__value" : "__colorValue";

      if (colorValueAggFunction === "count") {
        return rawData[`layer${leafLayerIndex}`].map(value => Number(value[colorValueKey]));
      }
      return rawData[`layer${leafLayerIndex}`].map(value => Number(value[colorValueKey][colorValueAggFunction]));
    }

    _getDiscreteColorGenerator(layerFields) {
      const discreteColorByFieldName = layerFields[0].fieldName;
      this._generateDiscreteColor = ChartService.getKeyColor(discreteColorByFieldName, this._vizConfig.colorMapping);
    }

    _convertLayerRowData(layerRowData, layerIndex, layerFields, valueFields) {
      const layerValueFields = layerFields.slice(0, layerIndex + 1);
      const parentLayerValueFields = layerFields.slice(0, layerIndex);
      const layerValueFieldNames = layerValueFields.map(layerValueField => layerValueField.fieldName);
      const parentLayerValueFieldNames = parentLayerValueFields.map(
        parentLayerValueField => parentLayerValueField.fieldName
      );

      const layerValues = values(pick(layerRowData, layerValueFieldNames));
      const parentLayerValues = values(pick(layerRowData, parentLayerValueFieldNames));

      const layerValueParser = getParserForType(layerValueFields[layerIndex].type);
      const layerName = layerValueParser(layerRowData[layerValueFieldNames[layerIndex]]);

      const convertedNode = {
        id: JSON.stringify(layerValues),
        name: layerName,
      };

      if (layerIndex === 0 && !hasColorValue(valueFields)) {
        // If no colour by field is selected, top-level nodes are assigned colours.
        convertedNode.color = this._generateDiscreteColor({ key: layerName });
      }

      if (parentLayerValueFieldNames.length) {
        convertedNode.parent = JSON.stringify(parentLayerValues);
      }
      if (isLeafLayer(layerFields, layerIndex)) {
        convertedNode.value = getValue(valueFields, 0, layerRowData);
        if (hasColorValue(valueFields)) {
          convertedNode.colorValue = getValue(valueFields, 1, layerRowData);
        }
      }

      return convertedNode;
    }
  }

  function sortData(layerData, layerFields) {
    return layerData.sort((a, b) => {
      var i;
      var result;
      for (i = 0; i < layerFields.length; i++) {
        let comparator = Sorter.getTypedComparator({ dataType: layerFields[i].type });
        result = comparator(a[layerFields[i].fieldName], b[layerFields[i].fieldName]);
        if (result !== 0) {
          return result;
        }
      }
      return 0;
    });
  }

  function getParserForType(fieldType) {
    switch (fieldType) {
      case "date":
      case "datetime":
      case "time":
        return d => {
          const DATETIME_TYPE_FORMAT = {
            date: "YYYY-MM-DD",
            datetime: "YYYY-MM-DD HH:mm:ss",
            time: "HH:mm:ss",
          };
          const val = moment(d, DATETIME_TYPE_FORMAT[fieldType]).unix();
          return isNaN(val) ? null : val;
        };

      case "numeric":
        return d => {
          const val = parseFloat(d);
          return isNaN(val) ? null : val;
        };

      case "character":
        return d => d || Localize.getLocalizedString("_Filter.BlankValue.Label_");

      default:
        return () => {};
    }
  }

  return TreemapQueryDataConverter;
}

function hasColorValue(valueFields) {
  return valueFields.length > 1;
}

function isLeafLayer(layerFields, layerIndex) {
  return layerFields.length === layerIndex + 1;
}

function getValue(valueFields, valueFieldIndex, layerRowData) {
  const valueAggType = getValueFieldAggregationType(valueFields, valueFieldIndex);
  const valueKey = valueFieldIndex === 0 || isValueAndColorValueEqual(valueFields) ? "__value" : "__colorValue";
  return valueFields[valueFieldIndex].aggregationType === "count"
    ? Number(layerRowData[valueKey])
    : Number(layerRowData[valueKey][valueAggType]);
}

function getValueFieldAggregationType(valueFields, valueFieldIndex) {
  const aggType = valueFields[valueFieldIndex].aggregationType;
  return AGGREGATION_TYPE_MAP[aggType] || aggType;
}

function isValueAndColorValueEqual(valueFields) {
  return isEqual(valueFields[0], valueFields[1]);
}
