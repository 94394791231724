function countAggregationFragment() {
  return `count`;
}

function columnAggregationFragment(fieldName, functions) {
  return `
    column(fieldName: "${fieldName}") {
      ${functions}
    }
  `;
}

function aliasCountAggregationFragment(aliasName) {
  return `${aliasName}: count`;
}

function aliasColumnAggregationFragment(aliasName, fieldName, functions) {
  return `
    ${aliasName}: column(fieldName: "${fieldName}") {
      ${functions}
    }
  `;
}

export {
  aliasCountAggregationFragment,
  aliasColumnAggregationFragment,
  countAggregationFragment,
  columnAggregationFragment,
};
