import React, { Component } from "react";
import PropTypes from "prop-types";
import PrevNextArrows from "acl-ui/components/PrevNextArrows";
import i18n from "@viz-ui/i18n/i18n";

import "./Header.scss";

export default class Header extends Component {
  static propTypes = {
    isChecked: PropTypes.bool.isRequired,
    isCheckboxVisible: PropTypes.bool,
    onCheck: PropTypes.func.isRequired,
    activeRowId: PropTypes.number.isRequired,
    isFirstRecord: PropTypes.bool.isRequired,
    isLastRecord: PropTypes.bool.isRequired,
    onClickPrevArrow: PropTypes.func.isRequired,
    onClickNextArrow: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isCheckboxVisible: true,
  };

  render() {
    return (
      <header className="record-processing__header">
        <label>
          <h3>
            {i18n.t("_RecordProcessing.Record_")} #{this.props.activeRowId}
          </h3>
          {this.props.isCheckboxVisible ? (
            <input type="checkbox" onClick={this.props.onCheck} checked={this.props.isChecked} />
          ) : null}
        </label>
        <PrevNextArrows
          ariaPrevArrow={i18n.t("_RecordProcessing.Previous_")}
          ariaNextArrow={i18n.t("_RecordProcessing.Next_")}
          isPrevDisabled={this.props.isFirstRecord}
          isNextDisabled={this.props.isLastRecord}
          keyTriggerNext=""
          keyTriggerPrev=""
          onClickPrevArrow={this.props.onClickPrevArrow}
          onClickNextArrow={this.props.onClickNextArrow}
          tooltipTextNextArrow={i18n.t("_RecordProcessing.Previous_")}
          tooltipTextPrevArrow={i18n.t("_RecordProcessing.Next_")}
          tooltipTextNextArrowKey=""
          tooltipTextPrevArrowKey=""
        />
      </header>
    );
  }
}
