import intersection from "lodash/intersection";
import difference from "lodash/difference";
import CriteriaFilter from "@viz-ui/models/filter/criteriaFilter";
import StoryboardFilterTypes from "@viz-ui/models/storyboardFilter/storyboardFilterTypes";
import Field from "@viz-ui/models/field/field";
import FieldTypes from "@viz-ui/models/field/fieldTypes";
import FieldFilterSet from "@viz-ui/models/filter/fieldFilterSet";
import ValueFilter from "@viz-ui/models/filter/valueFilter";
import RelativeDateTranslator from "@viz-ui/models/relativeDate/relativeDateTranslator";
import checkboxState from "@viz-ui/models/checkboxState";

export default class StoryboardDrilldownFiltersTransformer {
  static transformToVizFilterSets = storyboardFilters => {
    if (!storyboardFilters) {
      return null;
    }

    return storyboardFilters.reduce((vizFilterSets, storyboardFilter) => {
      const transformedFilterSetIndex = vizFilterSets.findIndex(
        vizFilterSet => vizFilterSet.field().name() === storyboardFilter.filterFields()[0].name()
      );

      if (transformedFilterSetIndex !== -1) {
        this.mergeToTransformedVizFilterSet(storyboardFilter, vizFilterSets[transformedFilterSetIndex]);
      } else {
        vizFilterSets.push(this.transformToVizFilterSet(storyboardFilter));
      }

      return vizFilterSets;
    }, []);
  };

  static transformToVizFilterSet = storyboardFilter => {
    const fieldFilterSet = new FieldFilterSet()
      .field(new Field().name(storyboardFilter.filterFields()[0].name()).type(storyboardFilter.fieldType()))
      .isActive(storyboardFilter.isActive())
      .showStoryboardIndicator(true);

    switch (storyboardFilter.type()) {
      case StoryboardFilterTypes.MULTISELECT:
      case StoryboardFilterTypes.MANDATORY_SINGLESELECT:
      case StoryboardFilterTypes.OPTIONAL_SINGLESELECT:
        fieldFilterSet.valueFilter(
          this.getValueFilter(storyboardFilter, storyboardFilter.type() === StoryboardFilterTypes.MULTISELECT)
        );
        break;
      case StoryboardFilterTypes.CONDITIONAL:
        fieldFilterSet.criteriaFilters([this.getCriteriaFilter(storyboardFilter)]);
        break;
    }

    return fieldFilterSet;
  };

  static mergeToTransformedVizFilterSet = (storyboardFilter, transformedFilterSet) => {
    switch (storyboardFilter.type()) {
      case StoryboardFilterTypes.MULTISELECT:
      case StoryboardFilterTypes.MANDATORY_SINGLESELECT:
      case StoryboardFilterTypes.OPTIONAL_SINGLESELECT: {
        const isMultiSelect = storyboardFilter.type() === StoryboardFilterTypes.MULTISELECT;
        const valueFilter = this.getValueFilter(storyboardFilter, isMultiSelect);

        if (transformedFilterSet.valueFilter()) {
          const mergedValues = this.mergeValues(valueFilter, transformedFilterSet.valueFilter());
          valueFilter.values(mergedValues.values);
          valueFilter.unCheckedValues(mergedValues.unCheckedValues);
          valueFilter.selectAllState(mergedValues.selectAllState);
          transformedFilterSet.valueFilter(valueFilter);
        } else {
          transformedFilterSet.valueFilter(valueFilter);
        }
        break;
      }
      case StoryboardFilterTypes.CONDITIONAL: {
        const criteriaFilter = this.getCriteriaFilter(storyboardFilter);

        if (criteriaFilter) {
          let transformedCriteriaFilters = transformedFilterSet.criteriaFilters();
          if (transformedCriteriaFilters && transformedCriteriaFilters.length > 0) {
            transformedCriteriaFilters.push(criteriaFilter);
          } else {
            transformedCriteriaFilters = [criteriaFilter];
          }

          transformedFilterSet.criteriaFilters(transformedCriteriaFilters);
        }
        break;
      }
    }

    return transformedFilterSet;
  };

  static mergeFilterSets = (vizFilterSets, transformedFilterSets) => {
    if (!transformedFilterSets || transformedFilterSets.length === 0) {
      return vizFilterSets;
    }

    if (!vizFilterSets || vizFilterSets.length === 0) {
      return transformedFilterSets;
    }

    vizFilterSets.forEach(vizFilterSet => {
      this.updateLastCriteriaFilterConnector(vizFilterSet, "and");
    });

    return transformedFilterSets.reduce((vizFilterList, transformedFilterSet) => {
      const commonFilterSetIndex = vizFilterList.findIndex(
        vizFilterSet => vizFilterSet.field().name() === transformedFilterSet.field().name()
      );

      if (commonFilterSetIndex === -1) {
        vizFilterList.push(transformedFilterSet);
      } else {
        const commonFilterSet = vizFilterList[commonFilterSetIndex];

        if (!commonFilterSet.isActive()) {
          vizFilterList[commonFilterSetIndex] = transformedFilterSet;
        } else {
          let filterSetStoryboardIndicator = true;
          if (transformedFilterSet.valueFilter()) {
            const transformedValueFilter = transformedFilterSet.valueFilter();
            if (commonFilterSet.valueFilter()) {
              const mergedValues = this.mergeValues(commonFilterSet.valueFilter(), transformedValueFilter);
              transformedValueFilter.values(mergedValues.values);
              transformedValueFilter.unCheckedValues(mergedValues.unCheckedValues);
              transformedValueFilter.selectAllState(mergedValues.selectAllState);
              transformedValueFilter.indeterminateValues(mergedValues.indeterminateValues);
              transformedValueFilter.storyboardIndicatorValues(mergedValues.indeterminateValues);
              filterSetStoryboardIndicator = mergedValues.showStoryboardIndicator;
            }
            commonFilterSet.valueFilter(transformedValueFilter);
          }

          if (transformedFilterSet.criteriaFilters()) {
            const criteriaFilters = commonFilterSet.criteriaFilters() || [];
            commonFilterSet.criteriaFilters([...criteriaFilters, ...transformedFilterSet.criteriaFilters()]);
            filterSetStoryboardIndicator = true;
          }

          commonFilterSet.showStoryboardIndicator(filterSetStoryboardIndicator);
        }
      }

      return vizFilterList;
    }, vizFilterSets || []);
  };

  // Notes
  // - Needs to intersect the values when both the sets has explicit selections.
  // - Needs to set the values when any one of the set has explicit selections by excluding the unChecked values of other set.
  // - No need to set the unChecked values if any one of the set has explicit selections.
  // - Needs to union the unChecked values when both set's are in select all state.
  static mergeValues = (visualizerValueFilter, storyboardValueFilter) => {
    const mergedValues = {
      values: [],
      unCheckedValues: [],
      selectAllState: checkboxState.INDETERMINATE,
      indeterminateValues: [],
      showStoryboardIndicator: false,
    };

    const visualizerSelectAllState =
      visualizerValueFilter.selectAllState() == checkboxState.CHECKED ||
      (visualizerValueFilter.unCheckedValues() && visualizerValueFilter.unCheckedValues().length > 0);
    const storyboardSelectAllState =
      storyboardValueFilter.selectAllState() == checkboxState.CHECKED ||
      (storyboardValueFilter.unCheckedValues() && storyboardValueFilter.unCheckedValues().length > 0);

    if (!visualizerSelectAllState && storyboardSelectAllState) {
      // When visualizer has explicit selections, needs to exclude the storyboard unChecked values from the visualizer selected values.
      const storyboardUnCheckedValues = storyboardValueFilter.unCheckedValues() || [];

      mergedValues.values = visualizerValueFilter
        .values()
        .filter(x => storyboardUnCheckedValues.findIndex(y => y == x) == -1);

      mergedValues.indeterminateValues = storyboardUnCheckedValues.filter(
        x => visualizerValueFilter.values().findIndex(y => y == x) > -1
      );

      if (mergedValues.indeterminateValues && mergedValues.indeterminateValues.length > 0) {
        mergedValues.showStoryboardIndicator = true;
      }
    } else if (visualizerSelectAllState && !storyboardSelectAllState) {
      // When storyboard has explicit selections, needs to exclude the visualizer unChecked values from the storyboard selected values.
      const visualizerUnCheckedValues = visualizerValueFilter.unCheckedValues() || [];

      mergedValues.values = storyboardValueFilter
        .values()
        .filter(x => visualizerUnCheckedValues.findIndex(y => y == x) == -1);

      mergedValues.indeterminateValues = ["ALL_UN_CHECKED_VALUES"];
      mergedValues.showStoryboardIndicator = true;
    } else if (!visualizerSelectAllState && !storyboardSelectAllState) {
      // When both sets has explicit selections
      mergedValues.values = intersection(visualizerValueFilter.values(), storyboardValueFilter.values());

      mergedValues.indeterminateValues = difference(
        visualizerValueFilter.values() || [],
        storyboardValueFilter.values() || []
      );

      if (mergedValues.indeterminateValues && mergedValues.indeterminateValues.length > 0) {
        mergedValues.showStoryboardIndicator = true;
      }
    } else {
      // When both sets are in select all state
      const visualizerUnCheckedValues = visualizerValueFilter.unCheckedValues() || [];
      const storyboardUnCheckedValues = storyboardValueFilter.unCheckedValues() || [];
      mergedValues.unCheckedValues = [...new Set([...visualizerUnCheckedValues, ...storyboardUnCheckedValues])];

      if (mergedValues.unCheckedValues && mergedValues.unCheckedValues.length == 0) {
        mergedValues.selectAllState = checkboxState.CHECKED;
      }

      if (visualizerUnCheckedValues.length > 0 && storyboardUnCheckedValues.length > 0) {
        mergedValues.indeterminateValues = storyboardUnCheckedValues.filter(
          x => visualizerUnCheckedValues.findIndex(y => y == x) == -1
        );
      } else if (storyboardUnCheckedValues.length > 0) {
        mergedValues.indeterminateValues = storyboardUnCheckedValues;
      }

      if (mergedValues.indeterminateValues && mergedValues.indeterminateValues.length > 0) {
        mergedValues.showStoryboardIndicator = true;
      }
    }

    return mergedValues;
  };

  static getValueFilter = (storyboardFilter, isMultiSelect) => {
    if (!storyboardFilter) {
      return null;
    }

    let values = null;
    let unSelectedValues = null;
    if (isMultiSelect) {
      values = storyboardFilter.values() ? storyboardFilter.values().map(filterValue => filterValue.value()) : null;
      unSelectedValues = storyboardFilter.unSelectedValues()
        ? storyboardFilter.unSelectedValues().map(filterValue => filterValue.value())
        : null;
    } else {
      values = storyboardFilter.value() ? [storyboardFilter.value().value()] : null;
    }

    return new ValueFilter()
      .name(storyboardFilter.filterFields()[0].name())
      .operator("=")
      .selectAllState(storyboardFilter.selectAllState())
      .values(values)
      .unCheckedValues(unSelectedValues)
      .indeterminateValues(undefined)
      .storyboardIndicatorValues(values);
  };

  static getCriteriaFilter = storyboardFilter => {
    if (!storyboardFilter) {
      return null;
    }
    const operator = storyboardFilter.operator() === "relative" ? "between" : storyboardFilter.operator();

    return new CriteriaFilter()
      .connector("and")
      .name(storyboardFilter.filterFields()[0].name())
      .operator(operator)
      .values(
        this.getCriteriaFilterValues(
          storyboardFilter.values() && storyboardFilter.values().length > 0
            ? storyboardFilter.values().map(value => value.value())
            : null,
          storyboardFilter.fieldType(),
          storyboardFilter.operator(),
          storyboardFilter.excludeToday()
        )
      )
      .showStoryboardIndicator(true);
  };

  static getCriteriaFilterValues = (storyboardFilterValues, fieldType, operator, excludeToday = false) => {
    let filterValues = [null];

    if (
      fieldType === FieldTypes.FILE ||
      fieldType === FieldTypes.SIGNATURE ||
      !storyboardFilterValues ||
      storyboardFilterValues.length === 0 ||
      !operator ||
      operator === "is_blank" ||
      operator === "is_not_blank"
    ) {
      return filterValues;
    }

    switch (fieldType) {
      case FieldTypes.CHARACTER:
        filterValues = this.toSlice(storyboardFilterValues, 1);
        break;
      case FieldTypes.NUMERIC:
        if (operator !== "between") {
          filterValues = this.toSlice(storyboardFilterValues, 1);
        } else {
          filterValues = this.toSlice(storyboardFilterValues, 2);
        }
        break;
      case FieldTypes.DATETIME:
      case FieldTypes.DATE:
      case FieldTypes.TIME:
        if (operator === "relative") {
          const { startDate, endDate } = RelativeDateTranslator.getRelativeDate(
            storyboardFilterValues,
            fieldType,
            false,
            excludeToday
          );
          filterValues = [startDate, endDate];
        } else if (operator === "between") {
          filterValues = this.toSlice(storyboardFilterValues, 2);
        } else {
          filterValues = this.toSlice(storyboardFilterValues, 1);
        }
        break;
      default:
        break;
    }

    return filterValues;
  };

  static updateLastCriteriaFilterConnector = (vizFilter, connector) => {
    if (vizFilter && vizFilter.criteriaFilters() && vizFilter.criteriaFilters().length > 0) {
      const criteriaFilters = vizFilter.criteriaFilters();
      const lastCriteriaFilter = criteriaFilters[criteriaFilters.length - 1];
      lastCriteriaFilter.connector(connector);
      vizFilter.criteriaFilters(criteriaFilters);
    }
  };

  static toSlice = (filterValues, sliceRange) =>
    filterValues && filterValues.length > sliceRange - 1 ? filterValues.slice(0, sliceRange) : [null];
}
