import flatten from "lodash/flatten";

export default class GridHeaderModifier {
  constructor(fields = [], nestedHeaders = []) {
    this._initHeaders(fields, nestedHeaders);
  }

  _initHeaders(fields, nestedHeaders) {
    this._headers = [];
    if (fields.length) {
      this._constructHeaderCells(fields, nestedHeaders);
      this._setupModifier(nestedHeaders);
    }
  }

  _constructHeaderCells(fields, nestedHeaders) {
    for (let headerRowIndex = 0; headerRowIndex <= nestedHeaders.length; headerRowIndex++) {
      const headerRow = [];
      for (let headerColIndex = 0; headerColIndex < fields.length; headerColIndex++) {
        headerRow.push([cleanupAttr("rowspan")]);
      }
      this._headers.push(headerRow);
    }
  }

  _setupModifier(nestedHeaders) {
    nestedHeaders.forEach((nestedHeaderRow, rowIndex) => {
      nestedHeaderRow.forEach((nestedHeader, colIndex) => {
        if (nestedHeader.rowspan) {
          this._headers[rowIndex][colIndex].push(addAttr("rowspan", nestedHeader.rowspan));
          for (let rowSpanIndex = rowIndex + 1; rowSpanIndex < rowIndex + nestedHeader.rowspan; rowSpanIndex++) {
            this._headers[rowSpanIndex][colIndex].push(addClass("hiddenRowsHeader"));
          }
        }
      });
    });
  }

  _getModifier({ row, col }) {
    return this._headers.length > row && this._headers[row].length > col ? this._headers[row][col] : [];
  }

  applyModifier(headerElement, { row, col }) {
    if (row !== undefined && col !== undefined) {
      const headerModifiersFn = this._getModifier({ row, col }) || {};
      headerModifiersFn.forEach(modifierFn => modifierFn(headerElement));
    }
  }
}

const cleanupAttr = key => {
  return TH => {
    if (TH.hasAttribute(key)) TH.removeAttribute(key);
  };
};
const addAttr = (key, value) => {
  return TH => TH.setAttribute(key, value);
};
const addClass = className => {
  return TH => TH.classList.add(className);
};
