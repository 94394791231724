import React from "react";
import Button from "@paprika/button";
import Modal from "@paprika/modal";
import FormElement from "@paprika/form-element";
import Textarea from "@paprika/textarea";
import Input from "@paprika/input";
import PropTypes from "prop-types";
import L10n from "@paprika/l10n";
import i18n from "@viz-ui/i18n/i18n";
import "./saveVisualizationModal.scss";

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  vizTitle: PropTypes.string,
  vizSummary: PropTypes.string,
  isRenameInterpretation: PropTypes.bool,
  refreshCounter: PropTypes.number,
};

const defaultProps = {
  isOpen: false,
  vizTitle: "",
  vizSummary: "",
  isRenameInterpretation: false,
  refreshCounter: 0,
};

const SaveVisualizationModal = props => {
  const {
    isOpen,
    onClose,
    onSave,
    vizTitle: vizTitleInput,
    vizSummary: vizSummaryInput,
    isRenameInterpretation,
    refreshCounter: refreshCounterInp,
  } = props;

  const modalTitle = isRenameInterpretation
    ? i18n.t("_SaveViz.Modal.Rename.Interpretation.Title_")
    : i18n.t("_SaveViz.Modal.Title_");
  const vizTitleLabel = i18n.t("_SaveViz.Modal.Title.Label_");
  const vizTitlePlaceholder = i18n.t("_SaveViz.Modal.Title.Placeholder_");
  const vizSummaryLabel = i18n.t("_SaveViz.Modal.Summary.Label_");
  const vizSummaryPlaceholder = i18n.t("_SaveViz.Summary.Placeholder_");
  const [isDirty, setIsDirty] = React.useState({});
  const [vizTitle, setVizTitle] = React.useState(vizTitleInput);
  const [vizSummary, setVizSummary] = React.useState(vizSummaryInput);
  const [titleErrorText, setTitleErrorText] = React.useState("");
  const [summaryErrorText, setSummaryErrorText] = React.useState("");
  const [disableSave, disableSaveButton] = React.useState(isRenameInterpretation);
  const [refreshCount, refreshCounter] = React.useState(refreshCounterInp);

  React.useEffect(() => {
    setVizTitle(vizTitleInput);
    setVizSummary(vizSummaryInput);
    disableSaveButton(isRenameInterpretation);
    refreshCounter(refreshCounterInp);
  }, [vizTitleInput, vizSummaryInput, refreshCounterInp]);

  const handleOnCloseModal = () => {
    setVizTitle(vizTitleInput);
    setVizSummary(vizSummaryInput);
    setTitleErrorText("");
    setSummaryErrorText("");
    setIsDirty({});
    disableSaveButton(true);
    onClose();
  };

  const handleTitleChange = e => {
    if (vizTitleInput !== e.target.value || vizSummaryInput !== vizSummary) {
      setIsDirty({ onClose: () => {} });
      disableSaveButton(false);
    } else {
      if (isRenameInterpretation) {
        disableSaveButton(true);
      }
      setIsDirty({});
    }
    setVizTitle(e.target.value);
    setTitleErrorText(validateVizTitle(e.target.value));
  };

  const handleSummaryChange = e => {
    if (vizSummaryInput !== e.target.value || vizTitleInput !== vizTitle) {
      setIsDirty({ onClose: () => {} });
      disableSaveButton(false);
    } else {
      disableSaveButton(true);
      setIsDirty({});
    }
    setVizSummary(e.target.value);
    setSummaryErrorText(validateVizSummary(e.target.value));
  };

  const validateVizTitle = title => {
    const vizTitlePattern = '^[^\\\\/:*?"<>|]+$';
    const maxVizTitleLength = 80;
    let errorText = "";
    const titleLength = title.trim().length;
    if (!titleLength) {
      errorText = i18n.t("_SaveViz.Title.Error.Required.Message_");
    } else if (titleLength > maxVizTitleLength) {
      errorText = i18n.t("_SaveViz.Title.Error.MaxLength.Message_");
    } else if (!new RegExp(vizTitlePattern).test(title)) {
      errorText = i18n.t("_SaveViz.Title.Error.Invalid.Message_");
    }
    return errorText;
  };
  const validateVizSummary = summary => {
    const maxVizSummaryLength = 10000;
    return summary.length > maxVizSummaryLength ? i18n.t("_SaveViz.Summary.Error.MaxLength.Message_") : "";
  };
  const handleSubmit = event => {
    event.preventDefault();
    if (vizTitle.length === 0) return;
    if (titleErrorText.length || summaryErrorText.length) return;
    onSave({ title: vizTitle, summary: vizSummary });
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={handleOnCloseModal} zIndex={210}>
      <Modal.Overlay {...isDirty} data-testid="save-viz-modal-overlay" />
      <Modal.Header>{modalTitle}</Modal.Header>
      <Modal.Content className="save-viz-modal">
        <form onSubmit={handleSubmit}>
          <div className="save-viz-modal__row-separator">
            <FormElement label={vizTitleLabel} id="save-viz-title-input">
              <FormElement.Content>
                {({ idForLabel }) => (
                  <Input
                    type="text"
                    data-testid="save-viz-title-input-test"
                    id={idForLabel}
                    onChange={e => {
                      handleTitleChange(e);
                    }}
                    value={vizTitle}
                    placeholder={vizTitlePlaceholder}
                    hasError={Boolean(titleErrorText.length)}
                    data-autofocus
                  />
                )}
              </FormElement.Content>
              <FormElement.Error>{titleErrorText}</FormElement.Error>
            </FormElement>
          </div>
          <div>
            <L10n locale={i18n.locale}>
              <FormElement label={vizSummaryLabel} id="save-viz-modal-summary" hasOptionalLabel>
                <FormElement.Content>
                  {({ idForLabel }) => (
                    <Textarea
                      id={idForLabel}
                      data-testid="save-viz-modal-summary-test"
                      maxHeight="120px"
                      value={vizSummary}
                      placeholder={vizSummaryPlaceholder}
                      onChange={e => {
                        handleSummaryChange(e);
                      }}
                      hasError={Boolean(summaryErrorText.length)}
                    />
                  )}
                </FormElement.Content>
                <FormElement.Error>{summaryErrorText}</FormElement.Error>
              </FormElement>
            </L10n>
          </div>
        </form>
      </Modal.Content>
      <Modal.Footer className="modal-footer">
        <Button
          data-testid="viz-save-btn"
          kind="primary"
          isDisabled={
            !Boolean(vizTitle.length) ||
            Boolean(titleErrorText.length) ||
            Boolean(summaryErrorText.length) ||
            disableSave
          }
          onClick={handleSubmit}
          className="footer-button footer-button--save"
        >
          {i18n.t("_SaveButton.Save.Label_")}
        </Button>
        <Button onClick={handleOnCloseModal} kind="minor" className="footer-button footer-button--cancel">
          {i18n.t("_General.Cancel.Label_")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SaveVisualizationModal.propTypes = propTypes;

SaveVisualizationModal.defaultProps = defaultProps;

export default SaveVisualizationModal;
