import FilterValueAdapter from "@viz-ui/models/storyboardFilter/filterValueAdapter";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";

class FieldValueDataSource {
  // Should only be instantiated by FieldValueStore.
  constructor(requester, requestData) {
    this._requester = requester;
    this._requestData = requestData;
    this._valuesPromise = null;
    this._hasBlank = false;
    this._isFailed = false;
    this._isLoaded = false;
    this._isLoading = false;
    this._nextValuesPosition = 0;
    this._numTotalValues = null;
    this._validPromiseIndex = 0;
    this._values = [];
  }

  hasMoreValues() {
    // If we haven't loaded anything yet then there are more values.
    if (!this._isLoaded) return true;

    return this._hasMoreValues || (this._numTotalValues !== null && this._nextValuesPosition < this._numTotalValues);
  }

  isFailed() {
    return this._isFailed;
  }

  isLoaded() {
    return this._isLoaded;
  }

  isLoading() {
    return this._isLoading;
  }

  valuesRef() {
    return this._values;
  }

  countRef() {
    return this._numTotalValues;
  }

  reload() {
    this._hasBlank = false;
    this._isFailed = false;
    this._isLoaded = false;
    this._isLoading = false;
    this._numTotalValues = null;
    this._nextValuesPosition = 0;
    this._validPromiseIndex++;
    this._values = [];
    return this.load();
  }

  load() {
    if (this._isLoading) {
      return this._valuesPromise;
    }

    if (!this.hasMoreValues()) {
      return Promise.resolve(this._values);
    }

    UsageTracker.mark("fieldValue.loadValues");
    const index = this._validPromiseIndex;
    const responseHandler = response => this._responseHandler(response, index);
    const errorHandler = error => this._errorHandler(error, index);

    this._isFailed = false;
    this._isLoading = true;
    this._valuesPromise = this._requester
      .loadValues(this._requestData, this._nextValuesPosition)
      .then(responseHandler, errorHandler);
    return this._valuesPromise;
  }

  _responseHandler(response, promiseIndex) {
    if (promiseIndex < this._validPromiseIndex) {
      return;
    }

    let filterValueModels = FilterValueAdapter.deserializeFilterValues(response.values);

    this._hasMoreValues = response.hasMoreValues;

    // Need the amount of items backend sent disregarding if there was blank
    // values removed.
    this._nextValuesPosition += filterValueModels.length;

    filterValueModels = this._removeDuplicateBlankValues(filterValueModels);

    this._numTotalValues = response.count;

    this._isLoaded = true;
    this._values = this._values.concat(filterValueModels);

    this._isLoading = false;
    this._valuesPromise = null;

    UsageTracker.mark("fieldValue.valuesLoaded");

    return { values: this._values, count: this._numTotalValues };
  }

  _errorHandler(error, promiseIndex) {
    if (promiseIndex < this._validPromiseIndex) {
      return;
    }

    this._isFailed = true;
    this._isLoading = false;
    this._valuesPromise = null;
    throw new Error(error);
  }

  // @TODO This logic needed because logan backend can return null and blank
  // (""). Nulls are turned into blanks. This logic ensures there isn't
  // duplicate blank values ie a blank and a null blank. Query service should
  // not need this and it can be deleted once transferred over. This tracks
  // the blank for lazy loading.
  // Once full switched to Query service can delete:
  // _removeDuplicateBlankValues,  _nextValuesPosition, _hasBlank
  _removeDuplicateBlankValues(filterValues) {
    const blankIndex = filterValues.findIndex(filterValue => filterValue.isBlank());
    let returnFilterValues = filterValues;

    if (blankIndex !== -1) {
      if (this._hasBlank) {
        returnFilterValues = filterValues.filter(value => !value.isBlank());
      } else {
        this._hasBlank = true;
        // Remove all other blanks but leave one first one
        returnFilterValues = filterValues.filter((value, index) => index === blankIndex || !value.isBlank());
      }
    }

    return returnFilterValues;
  }
}

export default FieldValueDataSource;
