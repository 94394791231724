class LinkReducer {
  static link(state = "", action) {
    switch (action.type) {
      case "SET_LINK":
        return action.link;
      case "REMOVE_LINK":
        return "";
      default:
        return state;
    }
  }
}

window.LinkReducer = LinkReducer;