import logger from "@viz-ui/services/logger/logger";

angular
  .module("acl.visualizer.charts.backend")
  .service("BarChartBackendService", function(ChartBackendService, Sorter) {
    return {
      fetchJson: ChartBackendService.fetchJson,

      getRepresentation: function(resultData, config) {
        var data = [];

        var multipleSeries = config.chartSeries && config.chartSeries.fieldName;

        const seriesType = multipleSeries ? resultData.config.series.data_type : resultData.config.key.data_type;
        const xAxisType = resultData.config.key.data_type;
        const valueType = resultData.config.values.data_type;

        var seriesParser = ChartBackendService.getParserForType(seriesType);
        var xAxisParser = ChartBackendService.getParserForType(xAxisType);
        var valueParser = ChartBackendService.getParserForType(valueType);

        // d3.nest coerces strings to keys. Build a map so we can get the original value back.
        let uniqueXAxisKeyStringToKey = new Map();
        logger.log(`bar chart data - d3 nesting - Start`);
        data = ChartBackendService.nest()
          .key(d => {
            return multipleSeries ? seriesParser(d.series) : "";
          })
          .key(d => {
            var xAxisKey = xAxisParser(d.key);
            if ((xAxisType !== "date" && xAxisType !== "datetime") || xAxisKey) {
              uniqueXAxisKeyStringToKey.set(getKeyString(xAxisKey), xAxisKey);
            }
            return xAxisKey;
          })
          .excludeNullKeys(xAxisType === "date" || xAxisType === "datetime")
          .rollup(array => {
            if (array.length > 1) throw "Error: Found multiple data points for single category combination.";
            return valueParser(array[0].values[0]);
          })
          .entries(resultData.values);
        logger.log(`bar chart data - d3 nesting - End`);

        logger.log(`bar chart data - series iteration - Start`);
        // Create a Set of unique xAxisKeyStrings for faster lookup
        const uniqueXAxisKeyStrings = new Set(uniqueXAxisKeyStringToKey.keys());

        data.forEach(series => {
          const valuesMap = new Map(series.values.map(value => [getKeyString(value.key), value]));

          uniqueXAxisKeyStrings.forEach(xAxisKeyString => {
            if (!valuesMap.has(xAxisKeyString)) {
              const xAxisKey = uniqueXAxisKeyStringToKey.get(xAxisKeyString);
              series.values.push({
                key: xAxisKey,
                values: valueParser(null),
              });
            }
          });
          // Sort x-axis
          series.values = Sorter.sort(series.values, {
            dataType: xAxisType,
            valueParser: item => item.key,
          });
        });
        logger.log(`bar chart data - series iteration - END`);

        logger.log(`bar chart data - series sorting - Start`);
        // Sort the series
        data = Sorter.sort(data, {
          dataType: seriesType,
          valueParser: item => item.key,
        });
        logger.log(`bar chart data - series sorting - End`);

        return data;
      },
    };
  });

function getKeyString(key) {
  const isKeyDefined = ![undefined, null].includes(key);
  return isKeyDefined ? key.toString() : "";
}
