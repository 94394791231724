angular.module("acl.common.aclInput").directive("aclInput", function() {
  return {
    restrict: "E",
    transclude: true,
    replace: true,
    scope: {},
    templateUrl: "visualizer/js/modules/util/aclInput/aclInput.tpl.html",
    link: function(scope, element) {
      scope.field = element
        .find("input, select")
        .eq(0)
        .controller("ngModel");
    },
  };
});
