import backendApi from "@results/services/apiCall/backendApi";
import moment from "moment";
import ApiPath from "@viz-ui/services/apiPath/apiPathService";

angular
  .module("acl.visualizer.charts.backend")
  .service("StatisticsVizBackendService", function(DataModel, TemporalDiff) {
    function url(tableId, chartIndex) {
      return ApiPath.table.getStatisticsDataUrl(tableId) + "?chart_index=" + chartIndex;
    }

    return {
      fetchJson: function(tableId, filter, config, isBiView) {
        var requestParams;
        if (isBiView) {
          requestParams = { raw: DataModel.toBiView("StatisticsViz", config) };
        } else {
          requestParams = { raw: DataModel.toSaveViz() };
        }
        var chartIndex = requestParams.raw.visualizations
          .map(function(d) {
            return angular.equals(d.config.dataConfig, config);
          })
          .indexOf(true);

        requestParams.myRecords = DataModel.filterConfig.myRecords();
        requestParams.openStatuses = DataModel.filterConfig.openStatuses();

        return backendApi.post(url(tableId, chartIndex), {}, requestParams);
      },

      getRepresentation: function(resultData, config) {
        var statistics = resultData.values;

        let i;
        let j;
        let l;
        let m;
        const data = [];

        if (!statistics) {
          return data;
        }

        for (i = 0, l = statistics.length; i < l; i++) {
          var originalStatistic = statistics[i];
          var chartCategory = {};
          for (j = 0; j < config.chartCategory.length; j++) {
            if (config.chartCategory[j].fieldName === originalStatistic.field_name) {
              chartCategory = config.chartCategory[j];
              break;
            }
          }

          var formattedStatistic = {};
          if (originalStatistic) {
            if (chartCategory.type === "character") {
              formattedStatistic = {
                type: chartCategory.type,
                displayName: chartCategory.displayName,
                fieldName: originalStatistic.field_name,
                uniqueValues: originalStatistic.unique,
                blanks: originalStatistic.blanks,
              };
            } else if (chartCategory.type === "numeric") {
              formattedStatistic = {
                type: chartCategory.type,
                displayName: chartCategory.displayName,
                fieldName: originalStatistic.field_name,
                total: originalStatistic.total,
                average: originalStatistic.average,
                range: originalStatistic.range,
                stdDeviation: originalStatistic.std_dev,
                count: originalStatistic.count,
                positives: originalStatistic.positives,
                negatives: originalStatistic.negatives,
                zeros: originalStatistic.zeros,
                highest: [],
                lowest: [],
              };
              if (angular.isArray(originalStatistic.highest)) {
                for (j = 0, m = originalStatistic.highest.length; j < m; j++) {
                  formattedStatistic.highest.push({ value: originalStatistic.highest[j] });
                }
              } else {
                formattedStatistic.highest.push({ value: originalStatistic.highest });
              }
              if (angular.isArray(originalStatistic.lowest)) {
                for (j = 0, m = originalStatistic.lowest.length; j < m; j++) {
                  formattedStatistic.lowest.push({ value: originalStatistic.lowest[j] });
                }
              } else {
                formattedStatistic.lowest.push({ value: originalStatistic.lowest });
              }
            } else if (chartCategory.type === "date") {
              formattedStatistic = {
                type: chartCategory.type,
                displayName: chartCategory.displayName,
                fieldName: originalStatistic.field_name,
                average: getDateJSON(originalStatistic.average),
                range: originalStatistic.range,
                stdDeviation: originalStatistic.std_dev,
                count: originalStatistic.count,
                blanks: originalStatistic.blanks,
                highest: [],
                lowest: [],
              };
              if (angular.isArray(originalStatistic.highest)) {
                for (j = 0, m = originalStatistic.highest.length; j < m; j++) {
                  formattedStatistic.highest.push({ value: getDateJSON(originalStatistic.highest[j]) });
                }
              } else {
                formattedStatistic.highest.push({ value: getDateJSON(originalStatistic.highest) });
              }
              if (angular.isArray(originalStatistic.lowest)) {
                for (j = 0, m = originalStatistic.lowest.length; j < m; j++) {
                  formattedStatistic.lowest.push({ value: getDateJSON(originalStatistic.lowest[j]) });
                }
              } else {
                formattedStatistic.lowest.push({ value: getDateJSON(originalStatistic.lowest) });
              }
              if (formattedStatistic.lowest.length && formattedStatistic.highest.length) {
                formattedStatistic.formattedRange = TemporalDiff.getPreciseDiff(
                  formattedStatistic.highest[0].value,
                  formattedStatistic.lowest[0].value
                );
              }
            } else if (chartCategory.type === "time") {
              formattedStatistic = {
                type: chartCategory.type,
                displayName: chartCategory.displayName,
                fieldName: originalStatistic.field_name,
                average: getTimeJSON(originalStatistic.average),
                range: originalStatistic.range,
                stdDeviation: originalStatistic.std_dev,
                count: originalStatistic.count,
                blanks: originalStatistic.blanks,
                highest: [],
                lowest: [],
              };
              if (angular.isArray(originalStatistic.highest)) {
                for (j = 0, m = originalStatistic.highest.length; j < m; j++) {
                  formattedStatistic.highest.push({ value: getTimeJSON(originalStatistic.highest[j]) });
                }
              } else {
                formattedStatistic.highest.push({ value: getTimeJSON(originalStatistic.highest) });
              }
              if (angular.isArray(originalStatistic.lowest)) {
                for (j = 0, m = originalStatistic.lowest.length; j < m; j++) {
                  formattedStatistic.lowest.push({ value: getTimeJSON(originalStatistic.lowest[j]) });
                }
              } else {
                formattedStatistic.lowest.push({ value: getTimeJSON(originalStatistic.lowest) });
              }

              formattedStatistic.formattedRange = TemporalDiff.getPreciseDiff(
                "1900-01-01 " + formattedStatistic.highest[0].value,
                "1900-01-01 " + formattedStatistic.lowest[0].value
              );
            } else if (chartCategory.type === "datetime") {
              formattedStatistic = {
                type: chartCategory.type,
                displayName: chartCategory.displayName,
                fieldName: originalStatistic.field_name,
                average: getDateTimeJSON(originalStatistic.average),
                range: originalStatistic.range,
                stdDeviation: originalStatistic.std_dev,
                count: originalStatistic.count,
                blanks: originalStatistic.blanks,
                highest: [],
                lowest: [],
              };

              if (angular.isArray(originalStatistic.highest)) {
                for (j = 0, m = originalStatistic.highest.length; j < m; j++) {
                  formattedStatistic.highest.push({ value: getDateTimeJSON(originalStatistic.highest[j]) });
                }
              } else {
                formattedStatistic.highest.push({ value: getDateTimeJSON(originalStatistic.highest) });
              }
              if (angular.isArray(originalStatistic.lowest)) {
                for (j = 0, m = originalStatistic.lowest.length; j < m; j++) {
                  formattedStatistic.lowest.push({ value: getDateTimeJSON(originalStatistic.lowest[j]) });
                }
              } else {
                formattedStatistic.lowest.push({ value: getDateTimeJSON(originalStatistic.lowest) });
              }
              if (formattedStatistic.lowest.length && formattedStatistic.highest.length) {
                formattedStatistic.formattedRange = TemporalDiff.getPreciseDiff(
                  formattedStatistic.highest[0].value,
                  formattedStatistic.lowest[0].value
                );
              }
            }
          }

          data.push(formattedStatistic);
        }
        return data;
      },
    };

    function getDateJSON(date) {
      return moment.utc(date).toDate();
    }

    function getDateTimeJSON(dateTime) {
      return moment.utc(dateTime).toDate();
    }

    function getTimeJSON(time) {
      return time;
    }
  });
