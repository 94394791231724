import chartService from "@viz-ui/services/charts/chartService";
import Sorter from "@viz-ui/services/sorters/sorter";

class StatisticsVizService extends chartService {
  defaultDisplayConfig = () => {};

  getChartDirectiveConfig = (interpretationId, chartConfig) => {
    /* eslint-disable */
    return {
      interpretationId: interpretationId,
      fieldNames: chartConfig.dataConfig.chartCategory.map(category => category.fieldName),
    };

    /* eslint-enable */
  };

  populateChartConfigColumnDefs = chartConfigColumnDefs => {
    const fields = this.DataModel.table.fields();
    if (fields) {
      for (const fieldName in fields) {
        const tempColumnDef = {};
        tempColumnDef.displayName = fields[fieldName].displayName;
        tempColumnDef.fieldId = fields[fieldName].colId;
        tempColumnDef.fieldName = fieldName;
        tempColumnDef.type = fields[fieldName].type;

        if (
          tempColumnDef.type === "numeric" ||
          tempColumnDef.type === "character" ||
          tempColumnDef.type === "date" ||
          tempColumnDef.type === "datetime" ||
          tempColumnDef.type === "time" ||
          tempColumnDef.type === "logical"
        ) {
          chartConfigColumnDefs.chartCategory.push(tempColumnDef);
        }
      }
      chartConfigColumnDefs.chartCategory = Sorter.sort(chartConfigColumnDefs.chartCategory, {
        valueParser: item => item.displayName,
      });
    }
    return chartConfigColumnDefs;
  };

  chartConfigColumnDefs = () => ({
    chartCategory: [],
  });

  isValidDataConfig = dataConfig =>
    !!(
      dataConfig &&
      dataConfig.chartCategory &&
      dataConfig.chartCategory.length &&
      dataConfig.chartCategory[0] &&
      dataConfig.chartCategory[0].fieldName
    );
}

export default StatisticsVizService;
