angular.module("acl.visualizer.filterHacks", [
  "acl.common.event",
  "acl.common.localize",
  "acl.visualizer.conditionalFormatting",
  "acl.visualizer.conditionalOperators",
  "acl.visualizer.dataFilter",
  "acl.visualizer.dataModel",
  "acl.visualizer.dataTable",
  "acl.visualizer.model.conditionalFormatting",
  "acl.visualizer.model.filter",
  "acl.visualizer.saveViz",
  "acl.visualizer.sorters",
]);
