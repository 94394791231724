import React, { Component } from "react";
import PropTypes from "prop-types";
import stylers from "@paprika/stylers";
import Button from "@paprika/button";
import OverflowMenu from "@paprika/overflow-menu";
import CheckIcon from "@paprika/icon/lib/Check";
import i18n from "@viz-ui/i18n/i18n";
import ToolTip from "@viz-ui/components/ToolTip";
import "./hamburgerMenu.scss";

const TAB_ICON = {
  DataTable: "data-table",
  BarCharts: "bar-charts",
  LineChart: "line-chart",
  StackedAreaChart: "area-chart-stacked",
  PieChart: "pie-chart",
  BubbleChart: "bubble-chart",
  HeatMap: "heatmap-chart",
  SummaryTable: "summary-table-chart",
  TreeMap: "treemap-chart",
  StatisticsChart: "statistics-chart",
  CombinationChart: "combination-chart",
  MapChart: "map-chart",
};
const TAB_ICON_CLASS = {
  DataTable: "icon-data-table",
  BarCharts: "icon-bar-charts",
  LineChart: "icon-line-chart",
  StackedAreaChart: "icon-area-chart-stacked",
  PieChart: "icon-pie-chart",
  BubbleChart: "icon-bubble-chart",
  HeatMap: "icon-heatmap-chart",
  SummaryTable: "icon-summary-table-chart",
  TreeMap: "icon-treemap-chart",
  StatisticsChart: "icon-statistics-chart",
  CombinationChart: "icon-combination-chart",
  MapChart: "icon-map-chart",
};

export default class HamburgerMenu extends Component {
  static propTypes = {
    tabList: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func,
  };

  static defaultProps = {
    tabList: [],
    onClick: () => {},
  };

  constructor(props) {
    super(props);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.state = {
      checkedList: {},
    };
  }

  handleItemClick = (e, checkItem) => {
    if (checkItem.id !== this.state.checkedList.id) {
      this.setState({
        checkedList: checkItem,
      });
    } else {
      this.setState({
        checkedList: {},
      });
    }
    this.props.onClick({ tabIndex: checkItem.tabIndex, event: e });
  };

  overflowMenuTabIcon = tabIcon => {
    switch (tabIcon) {
      case TAB_ICON.DataTable:
        return TAB_ICON_CLASS.DataTable;
      case TAB_ICON.BarCharts:
        return TAB_ICON_CLASS.BarCharts;
      case TAB_ICON.LineChart:
        return TAB_ICON_CLASS.LineChart;
      case TAB_ICON.StackedAreaChart:
        return TAB_ICON_CLASS.StackedAreaChart;
      case TAB_ICON.PieChart:
        return TAB_ICON_CLASS.PieChart;
      case TAB_ICON.BubbleChart:
        return TAB_ICON_CLASS.BubbleChart;
      case TAB_ICON.HeatMap:
        return TAB_ICON_CLASS.HeatMap;
      case TAB_ICON.SummaryTable:
        return TAB_ICON_CLASS.SummaryTable;
      case TAB_ICON.TreeMap:
        return TAB_ICON_CLASS.TreeMap;
      case TAB_ICON.StatisticsChart:
        return TAB_ICON_CLASS.StatisticsChart;
      case TAB_ICON.CombinationChart:
        return TAB_ICON_CLASS.CombinationChart;
      case TAB_ICON.MapChart:
        return TAB_ICON_CLASS.MapChart;
      default:
        return "";
    }
  };

  renderOverflowMenuTabIcon(tabIcon) {
    return <span className={`hamburger__menu-item-icon ${this.overflowMenuTabIcon(tabIcon)}`} />;
  }

  renderOverflowMenuTabTitle(title) {
    return <span className="hamburger__menu-item-title">{title || i18n.t("_Chart.Title.Default_")}</span>;
  }

  renderOverflowMenuItem() {
    return this.props.tabList.map(item => (
      <OverflowMenu.Item
        key={item.id}
        onClick={e => this.handleItemClick(e, item)}
        className={`hamburger__menu-item ${item.active ? "active-checked-icon" : ""}`}
      >
        <CheckIcon aria-label="check" className="check-icon" size={stylers.spacer(3)} />
        <ToolTip content={item.title}>
          {this.renderOverflowMenuTabIcon(item.data.tabIcon)}
          {this.renderOverflowMenuTabTitle(item.title)}
        </ToolTip>
      </OverflowMenu.Item>
    ));
  }

  render() {
    const overflowMenuTooltip = i18n.t("_Chart.OverflowMenu.Tooltip_");
    return (
      <OverflowMenu className="hamburger__menu" edge="left" maxHeight={450} minWidth={250} maxWidth={300} zIndex={160}>
        <OverflowMenu.Trigger
          className="hamburger__menu-trigger"
          buttonType={OverflowMenu.Trigger.types.button.ICON}
          kind={Button.types.kind.MINOR}
          a11yText={overflowMenuTooltip}
        >
          <ToolTip content={overflowMenuTooltip} align="top">
            <span className="hamburger-menu-icon" />
          </ToolTip>
        </OverflowMenu.Trigger>
        {this.renderOverflowMenuItem()}
      </OverflowMenu>
    );
  }
}
