import AppConfig from "@visualizer/appConfig";
import DataRequester from "@viz-ui/services/dataRequester/dataRequester";

const loading = {};

function metricCreate(metric) {
  return DataRequester.getInstance("metricCreate")
    .request(AppConfig.timeout, metric)
    .then(function(responseMetric) {
      return responseMetric;
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
}

function metricRequest(id) {
  if (loading[id]) {
    return loading[id];
  }

  const promise = DataRequester.getInstance("metricRequest")
    .request(AppConfig.timeout, id)
    .then(function(responseMetric) {
      delete loading[id];
      return responseMetric;
    })
    .catch(function(error) {
      return Promise.reject(error);
    });

  loading[id] = promise;
  return promise;
}

function metricChange(metric) {
  return DataRequester.getInstance("metricChange")
    .request(AppConfig.timeout, metric)
    .then(function(responseMetric) {
      return responseMetric;
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
}

function metricDelete(metric) {
  return DataRequester.getInstance("metricDelete").request(AppConfig.timeout, metric);
}

function metricsRequest() {
  return DataRequester.getInstance("metricsRequest").request(AppConfig.timeout);
}

function metricSummaryRequest(metric) {
  return DataRequester.getInstance("metricSummaryRequest").request(AppConfig.timeout, metric);
}

function metricSparklineRequest(metric, timezoneOffset, tableId, globalFilter, queryScope) {
  return DataRequester.getInstance("metricSparklineRequest").request(
    AppConfig.timeout,
    metric,
    timezoneOffset,
    tableId,
    globalFilter,
    queryScope
  );
}

class MetricManager {
  loadMetric(id) {
    return metricRequest(id);
  }

  createMetric(metric) {
    if (!metric.isNew()) {
      return Promise.reject(new Error("New metric should not have ID."));
    }
    return metricCreate(metric);
  }

  saveMetric(metric) {
    if (metric.isNew()) {
      return Promise.reject(new Error("Need ID to save metric."));
    }
    return metricChange(metric);
  }

  deleteMetric(metric) {
    return metricDelete(metric);
  }

  loadMetricSummary(metric) {
    return metricSummaryRequest(metric);
  }

  loadMetrics() {
    return metricsRequest();
  }

  loadMetricSparkline(metric, timezoneOffset, tableId, globalFilter, queryScope) {
    return metricSparklineRequest(metric, timezoneOffset, tableId, globalFilter, queryScope);
  }
}

export default new MetricManager();
