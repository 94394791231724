angular
  .module("acl.visualizer.charts.controllers")
  .controller("StackedAreaChartController", function(
    $scope,
    $timeout,
    ChartService,
    DataDownsamplerService,
    InheritController,
    Localize,
    StackedAreaChart
  ) {
    var base = InheritController("ChartBaseController", $scope)
      .override("chartType", "StackedAreaChart")
      .override("defaultDisplayConfig", StackedAreaChart.defaultDisplayConfig)
      .override("getChartDirectiveConfig", StackedAreaChart.getChartDirectiveConfig)
      .override("populateChartConfigColumnDefs", StackedAreaChart.populateChartConfigColumnDefs)
      .override("chartConfigColumnDefs", StackedAreaChart.chartConfigColumnDefs)
      .override("isValidDataConfig", StackedAreaChart.isValidDataConfig)
      .override("filterResult", function(representation) {
        var cohortSize = DataDownsamplerService.reduceStackedAreaData(
          representation,
          ChartService.getDataItemLimit(),
          $scope.chartConfig.dataConfig.chartValue.aggregationType
        );
        if (cohortSize > 1) {
          $timeout(function() {
            $scope.setMessage({
              type: "notice",
              content: Localize.getLocalizedString("_Chart.DrawableDataLimitReached.Template_").replace(
                "$0",
                cohortSize
              ),
            });
          }, 1000);
        }
        return representation;
      })
      .override("chartZoomIn", function(keyArray) {
        var xFormatter = ChartService.getFormatterForType($scope.chartConfig.dataConfig.chartXAxis.type);
        var filters = [];
        if (keyArray.length > 1) {
          var seriesFormatter = ChartService.getFormatterForType($scope.chartConfig.dataConfig.chartSeries.type);
          filters.push({ field: $scope.chartConfig.dataConfig.chartSeries, value: seriesFormatter(keyArray[0]) });
        }
        filters.push({
          field: $scope.chartConfig.dataConfig.chartXAxis,
          value: xFormatter(keyArray[keyArray.length - 1]),
        });

        $scope.tab.callbackInterface.onZoomIn(filters);
      })
      .override("getKeyColor", config => config && ChartService.getKeyColor(config.colorByField, config.colorMapping));

    base.initChart();
  });
