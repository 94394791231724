export default class Services {
  static urlPattern({ projectId, controlId, controlTestId }) {
    return `/projects/${projectId}/controls/${controlId}/control_tests/${controlTestId}`;
  }

  static GetTestExceptionComments(projectId, controlId, controlTestId, teId) {
    const urlParams = { projectId, controlId, controlTestId };
    return new Promise(resolve => {
      const url = `${this.urlPattern(urlParams)}/test_exceptions/${teId}/comments`;
      const xhr = Services.buildXhr(url, { json: true, httpVerb: "GET" });

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      };
      xhr.send();
    });
  }

  static buildXhr(url, options) {
    const xhr = new XMLHttpRequest();

    xhr.open(options.httpVerb, url, true);
    if (options.json) {
      xhr.setRequestHeader("Accept", "application/json");
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    }
    Services.addAuthenticityToken(xhr);

    return xhr;
  }

  static addAuthenticityToken(xhr) {
    if (window._token) {
      xhr.setRequestHeader("X-CSRF-Token", window._token);
    }
  }

  static CreateTestExceptionComment(
    projectId,
    controlId,
    controlTestId,
    teId,
    totalExceptionsCount,
    comment,
    attachment
  ) {
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append("ids", teId);
      formData.append("total_exceptions_count", totalExceptionsCount);
      formData.append("comment", comment);
      if (attachment) {
        formData.append("attachment", attachment, attachment.name);
      }
      formData.append("fields", JSON.stringify({}));
      const urlParams = { projectId, controlId, controlTestId };
      const uri = `${this.urlPattern(urlParams)}/process_test_exceptions`;
      const xhr = Services.buildXhr(uri, { httpVerb: "POST" });
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve();
        }
      };
      xhr.send(formData);
    });
  }

  /**
   * @param {object} urlParams { projectId, controlId, controlTestId }
   * @param {array} recordIds
   * @param {string} mode "processOne" or "processAll"
   * @param {object} filterConfig
   */
  static GetGroupsForSelectedRecord(urlParams, recordIds, mode, filterConfig = {}) {
    return new Promise(resolve => {
      const url = `${this.urlPattern(urlParams)}/groups_for_records`;
      const filters = filterConfig.filterList ? JSON.stringify(filterConfig.filterList) : null;
      const openStatuses = filterConfig.openStatuses || null;
      const myRecords = filterConfig.myRecords || null;
      const xhr = Services.buildXhr(url, { json: true, httpVerb: "POST" });
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      };
      xhr.send(
        JSON.stringify({
          ids: recordIds.join(","),
          mode,
          filters,
          openStatuses,
          myRecords,
        })
      );
    });
  }

  static GetTestException(projectId, controlId, controlTestId, teId) {
    const urlParams = { projectId, controlId, controlTestId };
    return new Promise(resolve => {
      const url = `${this.urlPattern(urlParams)}/test_exceptions`;
      const xhr = Services.buildXhr(url, { json: true, httpVerb: "POST" });

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      };
      xhr.send(JSON.stringify({ ids: teId }));
    });
  }

  /**
   * @param {object} urlParams { projectId, controlId, controlTestId }
   * @param {array} recordIds
   * @param {integer} statusId
   * @param {string} mode "processOne" or "processAll"
   * @param {object} filterConfig
   */
  static GetStatusChangeCounts(urlParams, recordIds, statusId, mode, filterConfig = {}) {
    return new Promise(resolve => {
      const url = `${this.urlPattern(urlParams)}/status_change_counts`;
      const filters = filterConfig.filterList ? JSON.stringify(filterConfig.filterList) : null;
      const openStatuses = filterConfig.openStatuses || null;
      const myRecords = filterConfig.myRecords || null;
      const xhr = Services.buildXhr(url, { json: true, httpVerb: "POST" });
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      };
      xhr.send(
        JSON.stringify({
          ids: recordIds.join(","),
          status_id: statusId,
          mode,
          filters,
          openStatuses,
          myRecords,
        })
      );
    });
  }

  static GetStatuses(controlTestId) {
    return new Promise(resolve => {
      const xhr = Services.buildXhr(`/statuses?control_test_id=${controlTestId}`, {
        json: true,
        httpVerb: "GET",
      });

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      };
      xhr.send();
    });
  }

  static GetPriorities(controlTestId) {
    return new Promise(resolve => {
      const xhr = Services.buildXhr(`/priorities?control_test_id=${controlTestId}`, {
        json: true,
        httpVerb: "GET",
      });

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      };
      xhr.send();
    });
  }

  static GetGroups(controlTestId) {
    return new Promise(resolve => {
      const xhr = Services.buildXhr(`/groups?control_test_id=${controlTestId}`, {
        json: true,
        httpVerb: "GET",
      });

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      };
      xhr.send();
    });
  }

  static GetUsers(controlTestId) {
    return new Promise(resolve => {
      const xhr = Services.buildXhr(`/users?control_test_id=${controlTestId}`, {
        json: true,
        httpVerb: "GET",
      });

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      };
      xhr.send();
    });
  }

  /**
   * @param {object} urlParams { projectId, controlId, controlTestId }
   * @param {array} recordIds
   * @param {object} fields { statusId, priorityId, groupId, assigneeId, comment, attachment }
   * @param {string} mode "processOne" or "processAll"
   * @param {object} filterConfig
   */
  static ProcessTestExceptions(urlParams, recordIds, fields, mode, filterConfig = {}) {
    const url = `${this.urlPattern(urlParams)}/process_test_exceptions`;
    const groupId = fields.groupId === "" ? null : fields.groupId;
    const assigneeId = fields.assigneeId === "" ? null : fields.assigneeId;
    const filters = filterConfig.filterList ? JSON.stringify(filterConfig.filterList) : null;

    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("ids", recordIds.join(","));
      formData.append("total_exceptions_count", window.AclExceptionActiveTestExceptionsCount);
      formData.append(
        "fields",
        JSON.stringify({
          status_id: fields.statusId,
          priority_id: fields.priorityId,
          group_id: groupId,
          user_id: assigneeId,
        })
      );
      formData.append("mode", mode);
      if (filters) {
        formData.append("filters", filters);
      }
      formData.append("openStatuses", filterConfig.openStatuses);
      formData.append("myRecords", filterConfig.myRecords);
      if (fields.comment) {
        formData.append("comment", fields.comment);
      }
      if (fields.attachment) {
        formData.append("attachment", fields.attachment, fields.attachment.name);
      }

      const xhr = Services.buildXhr(url, { httpVerb: "POST" });
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      };
      xhr.send(formData);
    });
  }

  /**
   * @param {object} urlParams { projectId, controlId, controlTestId }
   * @param {array} recordIds
   * @param {string} mode "deleteOne" or "deleteAll"
   * @param {object} filterConfig
   */
  static DeleteTestExceptions(urlParams, recordIds, mode, filterConfig = {}) {
    let filtersAsHashMap = null;

    if (filterConfig.filterList) {
      filtersAsHashMap = filterConfig.filterList.reduce(function(map, obj, index) {
        if (obj.filters) {
          obj.filters = obj.filters.reduce(function(filtersMap, filtersObj, filtersIndex) {
            filtersMap[filtersIndex] = filtersObj;
            return filtersMap;
          }, {});
        }
        map[index] = obj;
        return map;
      }, {});
    }

    return new Promise(resolve => {
      const url = `${this.urlPattern(urlParams)}/test_exceptions`;
      const openStatuses = filterConfig.openStatuses || null;
      const myRecords = filterConfig.myRecords || null;
      const xhr = Services.buildXhr(url, { json: true, httpVerb: "DELETE" });
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      };
      xhr.send(
        JSON.stringify({
          ids: recordIds.join(","),
          mode,
          filters: filtersAsHashMap,
          openStatuses,
          myRecords,
        })
      );
    });
  }
}
