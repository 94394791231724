angular.module("acl.storyboard.visualization").factory("InterpretationValidator", function(VisualizationValidator) {
  let interpretation = {
    // FIXME: Task to ensure our database contains only valid interpretations, then we can remove this check.
    validatedInterpretation(interpretationMap) {
      for (let project in interpretationMap) {
        let interpretations = interpretationMap[project];
        for (let interpretationIndex in interpretations) {
          let visualizations = interpretations[interpretationIndex].visualizations;
          for (let visualizationIndex in visualizations) {
            let viz = visualizations[visualizationIndex];
            viz.valid = VisualizationValidator.isValid(viz);
          }
        }
      }
      return interpretationMap;
    },
  };

  return interpretation;
});
