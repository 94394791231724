import React from "react";
import PropTypes from "prop-types";
import i18n from "@viz-ui/i18n/i18n";
import L10n from "@paprika/l10n";
import Toast from "@paprika/toast";
import Collapsible from "@paprika/collapsible";
import CopyInput from "@paprika/copy-input";
import "./copyInterpretationToast.scss";

const ERROR_CODE = {
  MissingField: "missing_field",
  FieldTypeMismatch: "field_type_mismatch",
  TitleLength: "title_length",
};
export default class CopyInterpretationToast extends React.Component {
  static propTypes = {
    errorMessages: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    errorMessages: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
    };
  }

  setIsCollapsed = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  errorType(errorType) {
    switch (errorType) {
      case ERROR_CODE.MissingField:
        return i18n.t("_CopyInterpretationToast.MissingField.Label_");
      case ERROR_CODE.FieldTypeMismatch:
        return i18n.t("_CopyInterpretationToast.FieldTypeMismatch.Label_");
      case ERROR_CODE.TitleLength:
        return i18n.t("_CopyInterpretationToast.TitleExceedsMaxLimit.Label_");
      default:
        return errorType;
    }
  }

  renderErrorContent() {
    return (
      <div className="copy-interpretation-toast__content">
        {this.props.errorMessages.map(messages => (
          <div key={messages.table_id}>
            <strong title={i18n.t("_CopyInterpretationToast.TableName.Label_")}>{messages.table_name}</strong>
            <ul>
              {messages.errors.map(error =>
                error.code === ERROR_CODE.TitleLength ? (
                  <li key={error.field_name}>
                    <span title={i18n.t("_CopyInterpretationToast.FieldName.Label_")}>
                      {i18n.t("_CopyInterpretationToast.TitleExceedsLimit.Label_")}
                    </span>
                    &nbsp;-&nbsp;
                    <span title={i18n.t("_CopyInterpretationToast.ErrorType.Label_")}>
                      {this.errorType(error.code)}
                    </span>
                  </li>
                ) : (
                  <li key={error.field_name}>
                    <span title={i18n.t("_CopyInterpretationToast.FieldName.Label_")}>{error.field_name}</span>
                    &nbsp;-&nbsp;
                    <span title={i18n.t("_CopyInterpretationToast.ErrorType.Label_")}>
                      {this.errorType(error.code)}
                    </span>
                  </li>
                )
              )}
            </ul>
          </div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <L10n locale={i18n.locale}>
        <Toast isFixed kind="error" zIndex={360}>
          <span className="copy-interpretation-toast__message">{i18n.t("_CopyInterpretationToast.Error.Label_")}</span>
          {this.props.errorMessages.length > 0 ? (
            <>
              <Collapsible
                a11yText={i18n.t("_CopyInterpretationToast.Error.Label_")}
                isCollapsed={this.state.isCollapsed}
                isDisabled={false}
                onClick={() => this.setIsCollapsed()}
                className="copy-interpretation-toast__collapsible"
                label={
                  this.state.isCollapsed
                    ? i18n.t("_CopyInterpretationToast.Show.Label_")
                    : i18n.t("_CopyInterpretationToast.Hide.Label_")
                }
              >
                <CopyInput
                  hasValueContainer={false}
                  hasInputContainer={false}
                  value={JSON.stringify(this.props.errorMessages)}
                  className="copy-interpretation-toast__copy-input"
                >
                  <CopyInput.Button kind="default" isSemantic={false} data-testid="toast__copy-button" />
                  <CopyInput.Popover zIndex={360} />
                </CopyInput>
                {this.renderErrorContent()}
              </Collapsible>
            </>
          ) : null}
        </Toast>
      </L10n>
    );
  }
}
