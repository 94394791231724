import React, { Component } from "react";
import PropTypes from "prop-types";
import StoryboardFilter from "@viz-ui/models/storyboardFilter/storyboardFilter";
import VizUiMultiselect from "@viz-ui/components/stbGlobalFilter/multiselect/multiselect";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import checkboxState from "@viz-ui/models/checkboxState";
import SelectLoader from "../services/SelectLoader";
import wasFilterEdited from "../services/wasFilterEdited";
import dynamicFilter from "../services/dynamicFilter";

export default class Multiselect extends Component {
  static propTypes = {
    fieldFormatIdentifier: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    filter: PropTypes.instanceOf(StoryboardFilter).isRequired,
    flipQueryService: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    flipDynamicFilter: PropTypes.bool.isRequired,
    forceRerender: PropTypes.bool.isRequired,
    clearAllFilter: PropTypes.bool.isRequired,
    resetForceReRenderFlag: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      allItemsLoaded: true,
      filterString: "",
      isLoading: false,
      items: [],
      selectAllState: checkboxState.UNCHECKED,
      isApiCalled: false,
    };
  }

  componentDidMount() {
    this.setState({ isApiCalled: true });
    this.updateSelectLoader(this.props.filter, this.props.flipQueryService, this.props.flipDynamicFilter);
  }

  reloadAllData() {
    this.setState({ isApiCalled: true });
    this.updateSelectLoader(this.props.filter, this.props.flipQueryService, this.props.flipDynamicFilter);
  }

  componentWillReceiveProps(nextProps) {
    //if on apply, we have a parent filter, then hide unselected
    if (
      dynamicFilter.hideUnselectedValueForFrozenFilter(nextProps.filter, nextProps.flipDynamicFilter) &&
      nextProps.forceRerender && //this flag necessary to handle situation VRMRG 2551, to prevent rerender while clicking items
      !nextProps.filter.isCurrentEditedDynamicFilter() //flag needed to not hide unselected values when we are editing dynamic filter
    ) {
      this.setState({ items: nextProps.filter.values() });
      this.handleResetRerendering();
    }
    if (nextProps.filter) {
      //forceRerender will be true in order to make api calls when we are clearing filter
      if (!this.props.filter || wasFilterEdited(this.props.filter, nextProps.filter) || nextProps.forceRerender) {
        if (
          this.props.fieldName !== nextProps.fieldName ||
          (!nextProps.filter.isFrozenFilter() &&
            !nextProps.filter.isCurrentDynamicFilter() &&
            !this.state.isApiCalled) ||
          nextProps.clearAllFilter
        ) {
          this.setState({ isApiCalled: true });
          this.updateSelectLoader(
            nextProps.filter,
            nextProps.flipQueryService,
            nextProps.flipDynamicFilter,
            nextProps.forceRerender
          );
        }
      }
    }
  }

  //reset the render flag so filter will not be unnecessarily rendered
  //after clear filter process is done
  handleResetRerendering = () => {
    this.props.resetForceReRenderFlag();
  };

  updateSelectLoader(filter, flipQueryService, flipDynamicFilter, forceRerender) {
    this.setState({ filterString: "", isApiCalled: false });
    this.selectLoader = new SelectLoader(filter, flipQueryService, flipDynamicFilter, forceRerender);
    this.selectLoader.load().then(this.updateItemsState);
  }

  handleLoadMore = () => {
    this.setState({ isLoading: true });
    this.selectLoader.loadMore().then(this.updateItemsState);
  };

  handleChangeFilterString = filterString => {
    this.setState({ filterString });
  };

  handleProcessFilterChange = filterString => {
    this.setState({
      filterString,
      isLoading: true,
    });
    this.selectLoader.filterStringChange(filterString).then(this.updateItemsState);
  };

  updateItemsState = () => {
    //during initial loading, if filter is frozen, the item should be prop value
    this.setState({
      allItemsLoaded: this.selectLoader.allItemsLoaded(),
      isLoading: false,
      items: dynamicFilter.hideUnselectedValueForFrozenFilter(this.props.filter, this.props.flipDynamicFilter)
        ? this.props.filter.values()
        : this.selectLoader.valuesRef(),
    });

    if (!this.state.filterString) {
      this.setState({ totalItemsCount: this.selectLoader.totalRowsCountRef() });
    }
    //Reset the rerender flag inorder to prevent rerendering when not required
    //after clearing the filter
    this.handleResetRerendering();
  };

  handleClickItem = (filterValueModels, unFilterValueModels, selectAllState, clickedItemAction) => {
    this.props.onFilterChange(filterValueModels, unFilterValueModels, selectAllState, clickedItemAction);
    UsageTracker.createEvent(`.filterpanel.${this.props.filter.type()}.selectionchanged`);
  };

  isFrozenFilter = () =>
    this.props.flipDynamicFilter && this.props.filter.isFrozenFilter() && !this.props.filter.isCurrentDynamicFilter();

  render() {
    return (
      <VizUiMultiselect
        allItemsLoaded={this.isFrozenFilter() ? true : this.state.allItemsLoaded}
        fieldFormatIdentifier={this.props.fieldFormatIdentifier}
        fieldName={this.props.fieldName}
        filterString={this.state.filterString}
        isDisabled={this.props.isDisabled}
        isLoading={this.state.isLoading}
        items={this.state.items}
        totalItemsCount={this.state.totalItemsCount}
        onClickItem={this.handleClickItem}
        onChangeFilterString={this.handleChangeFilterString}
        onLoadMore={this.handleLoadMore}
        onProcessFilterChange={this.handleProcessFilterChange}
        selectedItems={(this.props.filter && this.props.filter.values()) || []}
        selectAllState={(this.props.filter && this.props.filter.selectAllState()) || checkboxState.UNCHECKED}
        unSelectedItems={(this.props.filter && this.props.filter.unSelectedValues()) || []}
        filterId={this.props.filter && this.props.filter.id()}
        isDynamicFilterEnabled={this.props.flipDynamicFilter}
      />
    );
  }
}
