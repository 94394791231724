function stripHtml(title) {
  let div = document.createElement("div");
  div.innerHTML = title;
  return div.textContent;
}

export function stripData(data) {
  if (data.fields && data.fields.length > 0) {
    data.fields.forEach(field => {
      return (field.columnTitle = stripHtml(field.columnTitle));
    });
  }
}

export function stripDataForQuery(data) {
  if (data.table && data.table.fieldDefs && data.table.fieldDefs.length > 0) {
    data.table.fieldDefs.forEach(column => {
      return (column.displayName = stripHtml(column.displayName));
    });
  }
}
