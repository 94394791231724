import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import uniq from "lodash/uniq";
import isNumber from "lodash/isNumber";
import i18n from "@viz-ui/i18n/i18n";
import { COMBINATION_CHART } from "@visualizer/modules/visualization/highCharts/boost/boostThresholds";
import pubsub from "pubsub-js";
import merge from "lodash/merge";

import ReactHighChart from "../highChart/reactHighChart";
import {
  canAnimate,
  updateHcConfig,
  updateLabelFontSize,
  getBoostConfig,
  clickWrapper,
  baseConfig,
  configPath,
} from "../services/highChartService";
import { reorderValues } from "../services/highChartDataSorter";

const stacking = {
  stack: "normal",
  expand: "percent",
  overlap: undefined,
};

class CombinationChart extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    config: PropTypes.object,
    rawData: PropTypes.array,
    redrawIndex: PropTypes.number,
    zoomInHandler: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    config: {},
    rawData: [],
    redrawIndex: 0,
    zoomInHandler: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      hcConfig: {},
      chartReDraw: false,
    };
  }

  componentDidMount() {
    pubsub.subscribe("chartRedraw", () => {
      this.setState({ chartReDraw: !this.state.chartReDraw });
    });

    this.setState({ hcConfig: this.getConfig(this.props.config, this.props.rawData) });
  }

  onLegendItemClick = item => {
    const showAll = !this.hcConfig.series.some(entry => entry.visible && entry.name !== item.name);
    const series = this.hcConfig.series.map(entry => ({
      ...entry,
      visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
    }));
    updateHcConfig({ series });
  };

  onLegendItemDoubleClick = item => {
    const series = this.hcConfig.series.map(entry => ({ ...entry, visible: entry.name === item.name }));
    updateHcConfig({ series });
  };

  zoomIn = keyArray => {
    if (isFunction(this.props.zoomInHandler)) {
      this.props.zoomInHandler(keyArray);
    }
  };

  getChartStyle = (chartStyle, type) => {
    if (chartStyle === "expand") {
      return type === "column" ? stacking[chartStyle] : "undefined";
    }
    return stacking[chartStyle];
  };

  getConfig = (config, rawData) => {
    const chartData = rawData;
    let data = reorderValues(chartData, config);
    if (stacking[config.chartStyle]) {
      data = data.reverse();
    }
    const categories = uniq(data[0].values.map(d => d.key));
    let hcConfig = {
      legend: {
        enabled: config.showLegend && config.hasMultipleSeries,
        align: "right",
        verticalAlign: "top",
        reversed: !!stacking[config.chartStyle],
        labelFormatter: function() {
          return this.options.legendDisplayName;
        },
      },
      boost: getBoostConfig(config),
      plotOptions: {
        series: {
          dataLabels: {
            enabled: config.displayDataLabels,
            formatter: function() {
              return this.series.type === "column"
                ? config.yAxis[0].tickFormatter(this.y)
                : config.yAxis[1].tickFormatter(this.y);
            },
          },
          marker: {
            enabled: config.displayDataLabels,
          },
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              const header = config.hasMultipleSeries
                ? `
                  <span style="text-decoration: underline grey;">${this.series.options.legendDisplayName}</span>
                  <br/>
                `
                : "";
              const body = `
                <span>${config.xAxis.tickFormatter(this.xValue)}</span>
                <br/>
                <b>${
                  this.series.type === "column"
                    ? config.yAxis[0].tickFormatter(this.y)
                    : config.yAxis[1].tickFormatter(this.y)
                }</b>
              `;
              return header + body;
            },
          },
          point: {
            events: {
              click: clickWrapper(null, point => {
                this.zoomIn(config.hasMultipleSeries ? [point.series.name, point.xValue] : [point.xValue]);
              }),
            },
          },
          events: {
            legendItemClick: clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
          },
          turboThreshold: 0,
          animation: canAnimate(),
        },
      },
      xAxis: {
        categories: categories,
        labels: {
          formatter: function() {
            return config.xAxis.tickFormatter(this.value);
          },
        },
        title: {
          text: config.xAxis.label,
        },
      },
      yAxis: [
        {
          title: {
            text: config.yAxis[0].label,
          },
          labels: {
            formatter: function() {
              return config.chartStyle === "expand" ? `${this.value}%` : config.yAxis[0].tickFormatter(this.value);
            },
          },
          min: isNumber(config.yAxis[0].minimum) ? config.yAxis[0].minimum : undefined,
          max: isNumber(config.yAxis[0].maximum) ? config.yAxis[0].maximum : undefined,
          startOnTick: !isNumber(config.yAxis[0].minimum),
          endOnTick: !isNumber(config.yAxis[0].maximum),
        },
        {
          title: {
            text: config.yAxis[1].label,
          },
          labels: {
            formatter: function() {
              return config.yAxis[1].tickFormatter(this.value);
            },
          },
          min: isNumber(config.yAxis[1].minimum) ? config.yAxis[1].minimum : undefined,
          max: isNumber(config.yAxis[1].maximum) ? config.yAxis[1].maximum : undefined,
          startOnTick: !isNumber(config.yAxis[1].minimum),
          endOnTick: !isNumber(config.yAxis[1].maximum),
          gridLineDashStyle: "DashDot",
          opposite: true,
          visible: config.showDualYAxis,
        },
      ],
      series: data.map((series, index) => {
        let { type } = series;
        let yAxis = 0;
        if (type === "line") {
          type = config.smoothEdged ? "spline" : "line";
        }
        if (config.showDualYAxis) {
          if (series.type === "column") {
            yAxis = 0;
          } else {
            yAxis = 1;
          }
        }
        // Adding legendDisplayName property to handle (blank) value  & to localize the string (issue GRCPRD-4135)
        let legendDisplayName =
          series.legendDisplayName === "(blank)" ? i18n.t("_Filter.BlankValue.Label_") : series.legendDisplayName;
        if (type === "column") {
          legendDisplayName =
            config.labelFormatter && series.key !== "(blank)"
              ? config.labelFormatter(legendDisplayName)
              : legendDisplayName;
        }
        return {
          type: type,
          name: series.key,
          legendDisplayName: legendDisplayName,
          yAxis: yAxis,
          color:
            !getBoostConfig(config, categories.length >= COMBINATION_CHART).enabled &&
            this.isPatternFill &&
            type !== "line"
              ? this.patternFillPalettes(index)
              : series.color,
          boostThreshold: COMBINATION_CHART,
          stacking: this.getChartStyle(config.chartStyle, type),
          zIndex: type === "column" ? 0 : 1, // adding zIndex to make line chart appear always on the top
          visible: series.visible !== false,
          data: series.values.map(value => ({
            x: categories.indexOf(value.key),
            y: value.values,
            xValue: value.key,
          })),
        };
      }),
    };
    hcConfig = updateLabelFontSize(hcConfig, configPath);
    return merge({}, baseConfig(), hcConfig);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.config !== nextProps.config)
      this.setState({ hcConfig: this.getConfig(nextProps.config, nextProps.rawData) });
  }

  render() {
    return (
      <ReactHighChart
        className={`redraw-index-${this.props.redrawIndex}`}
        config={this.state.hcConfig}
        redrawIndex={this.props.redrawIndex}
        chartReDraw={this.state.chartReDraw}
      />
    );
  }
}

export default CombinationChart;
