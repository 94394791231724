import logger from "@viz-ui/services/logger/logger";
import QueryScope from "@viz-ui/services/query/queryScope/queryScope";
import FieldTypeChecker from "@visualizer/modules/core/fieldTypeChecker/fieldTypeChecker.service";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";
import BaseDataLoader from "./baseDataLoader";
import VisualizationBoard from "../models/visualizationBoard";
import BoardError from "../models/boardError";
import { ERROR_CODES, getErrorCodeForVisualization } from "./errorCode.service";
import ErrorReporter from "@viz-ui/services/common/errorReporterService";

export default class VisualizationDataLoader extends BaseDataLoader {
  constructor(
    AppConfig,
    InterpretationBackend,
    InterpretationDataStore,
    InterpretationTableDataStore,
    Localize,
    TableManager,
    VisualizationBackend,
    VisualizationDataStore,
    VisualizationValidator
  ) {
    "ngInject";
    super();

    this._exceptionHandler = ErrorReporter.instance("Storyboards");
    this._AppConfig = AppConfig;
    this._InterpretationBackend = InterpretationBackend;
    this._InterpretationDataStore = InterpretationDataStore;
    this._InterpretationTableDataStore = InterpretationTableDataStore;
    this._Localize = Localize;
    this._TableManager = TableManager;
    this._VisualizationBackend = VisualizationBackend;
    this._VisualizationDataStore = VisualizationDataStore;
    this._VisualizationValidator = VisualizationValidator;
  }

  async load(board) {
    let visualizationBoard = this._newVisualizationBoard(board);
    if (!visualizationBoard.boardError()) {
      const tableId = visualizationBoard.tableId();
      const interpretation = visualizationBoard.interpretation();
      const visualization = visualizationBoard.visualization();
      const queryScope = QueryScope.getScope(board);
      try {
        const table = await this._loadTable(board);
        this._checkFieldTypes(table, interpretation, visualization);

        logger.log(`${visualization.id} chart data load - Start`);
        const visualizationData = await this._loadVisualizationData(tableId, interpretation, visualization, queryScope);
        logger.log(`${visualization.id} chart data load - End`);

        visualizationBoard = this._addVisualizationDataToBoard(visualizationBoard, visualizationData);
      } catch (error) {
        const boardError = this._getBoardError(error);
        boardError.log(this.retrieveBoardLogInfo(board));
        visualizationBoard.boardError(boardError);
      }
    }

    return visualizationBoard;
  }

  _newVisualizationBoard(board) {
    const { interpretation_id: interpretationId, visualization_id: visualizationId } = board;
    let visualizationBoard = new VisualizationBoard({ ...board, visualizationId });
    visualizationBoard = this._addInterpretationToBoard(visualizationBoard, interpretationId);
    visualizationBoard = this._checkAndAddBoardError(visualizationBoard, interpretationId);

    return visualizationBoard;
  }

  _addInterpretationToBoard(visualizationBoard, interpretationId) {
    const interpretation = this._InterpretationDataStore.getInterpretation(interpretationId);
    const canDrilldown = this._InterpretationDataStore.canDrilldown(interpretationId);
    visualizationBoard.interpretation(interpretation);
    visualizationBoard.canDrilldown(canDrilldown);
    return visualizationBoard;
  }

  _checkAndAddBoardError(visualizationBoard, interpretationId) {
    visualizationBoard = this._checkAndAddInterpretationError(visualizationBoard, interpretationId);
    if (!visualizationBoard.boardError()) {
      const visualization = visualizationBoard.visualization();
      visualizationBoard = this._checkAndAddVisualizationError(visualizationBoard, visualization);
    }
    return visualizationBoard;
  }

  _checkAndAddInterpretationError(visualizationBoard, interpretationId) {
    const interpretationError = this._InterpretationDataStore.getInterpretationError(interpretationId);
    if (interpretationError) {
      const boardError = this._getBoardError(interpretationError);
      visualizationBoard.boardError(boardError);
    }
    return visualizationBoard;
  }

  _checkAndAddVisualizationError(visualizationBoard, visualization) {
    const isVisualizationValid = this._VisualizationValidator.isValid(visualization);
    let visualizationErrorCode = getErrorCodeForVisualization(visualization);
    if (!visualization) {
      visualizationErrorCode = ERROR_CODES.VISUALIZATION_DELETED;
    } else if (!isVisualizationValid) {
      visualizationErrorCode = ERROR_CODES.VISUALIZATION_INVALID;
    }
    const boardError = visualizationErrorCode ? this._getBoardError(visualizationErrorCode) : undefined;
    return visualizationBoard.boardError(boardError);
  }

  async _loadVisualizationData(tableId, interpretation, visualization, queryScope) {
    let visualizationData = this._VisualizationDataStore.getVisualizationData(interpretation.id, visualization.id);
    if (!visualizationData) {
      try {
        visualizationData = await this._VisualizationBackend.loadVisualizationData(
          tableId,
          interpretation,
          visualization,
          queryScope
        );
      } catch (error) {
        return Promise.reject(error);
      }
      this._VisualizationDataStore.addVisualizationData(interpretation.id, visualization.id, visualizationData);
    }
    return Promise.resolve(visualizationData);
  }

  async _loadTable(board) {
    const { interpretation_id: interpretationId, tableId } = board;
    let table;
    if (this._AppConfig.features.graphqlFlipper) {
      table = this._InterpretationTableDataStore.getTable(tableId);
    } else {
      table = this._InterpretationTableDataStore.getTable(board.id);
    }
    if (!table) {
      if (this._AppConfig.features.queryService) {
        const queryScope = QueryScope.getScope(board);
        table = await this._TableManager.loadTable(tableId, queryScope);
      } else {
        table = await this._InterpretationBackend.loadTableMetadata(interpretationId);
      }
      if (this._AppConfig.features.graphqlFlipper) {
        this._InterpretationTableDataStore.addTable(tableId, table);
      } else {
        this._InterpretationTableDataStore.addTable(board.id, table);
      }
      GlobalFieldFormatMap.setFieldTypes(interpretationId, table.fields());
    }
    return Promise.resolve(table);
  }

  _checkFieldTypes(table, interpretation, visualization) {
    const { dataConfig } = visualization.config;
    const { filterList } = interpretation.filterConfig;
    const fieldTypeChecker = FieldTypeChecker.create(table.fields());
    const filterFieldTypeChanged = fieldTypeChecker.filterListTypeMismatch(filterList);
    const chartConfigTypeChanged = fieldTypeChecker.chartDataConfigHasFieldTypeMismatch(dataConfig);
    if (filterFieldTypeChanged || chartConfigTypeChanged) {
      throw "FieldTypeChanged";
    }
  }

  _addVisualizationDataToBoard(visualizationBoard, visualizationData) {
    const visualization = visualizationBoard.visualization();
    const hasVisualizationData = this._VisualizationValidator.hasVisualizationData(visualization, visualizationData);
    if (hasVisualizationData) {
      if (visualizationData.modelType === "ChartData") {
        visualizationBoard.chartData(visualizationData);
      } else if (visualizationData.modelType === "SummaryTable") {
        visualizationBoard.summaryTable(visualizationData);
      } else {
        visualizationBoard.visualizationData(visualizationData);
      }
    } else {
      throw ERROR_CODES.VISUALIZATION_NO_DATA;
    }
    return visualizationBoard;
  }

  _getBoardError(error) {
    const exceptionHandler = this._exceptionHandler;
    const boardType = "visualization";
    return new BoardError({ boardType }).setExceptionHandler(exceptionHandler).setErrorFromCode(error);
  }
}
