angular
  .module("acl.visualizer.dataVisualizer")
  .controller("ChartSelectorController", function($scope, $modalInstance, newTabIndex, Localize, ChartService) {
    $scope.chartTypes = ChartService.getAllChartTypes().filter(function(d) {
      return !d.disabled;
    });

    $scope.pickChart = function(chartType) {
      if (chartType) {
        var selectedChart = {};
        angular.copy(chartType, selectedChart);
        selectedChart.tabIndex = newTabIndex;

        $modalInstance.close(selectedChart);
      }
    };

    $scope.closeChartSelectorModal = function() {
      $modalInstance.dismiss();
    };
  });
