import find from "lodash/find";

window.AssignmentReducer = (function() {
  class AssignmentReducer {
    static users(state = [], action) {
      switch (action.type) {
        case "ADD_USER_ASSIGNMENT":
          return addUserAssignment(state, action.userAssignment);
        case "ADD_GROUP_ASSIGNMENT":
          return addGroupAssignment(state, action.groupAssignment);
        case "REMOVE_ASSIGNMENT":
          return removeAssignment(state, action.assignmentId);
        case "UPDATE_ASSIGNMENT_ROLE":
          return updateAssignmentRole(state, action.assignmentId, action.role);
        default:
          return state;
      }
    }
  }

  function findAssignmentIndex(assignments, assignmentId) {
    let assignmentIndex = -1;
    assignments.forEach(function(assignment, index) {
      if (assignment.assignmentId === assignmentId) assignmentIndex = index;
    });
    return assignmentIndex;
  }

  function findAssignmentByUser(assignments, userId) {
    return find(assignments, function(assignment) {
      return assignment.userId === userId;
    });
  }

  function addUserAssignment(assignments, userAssignment) {
    let assignment = findAssignmentByUser(assignments, userAssignment.userId);
    if (assignment) {
      return assignments;
    } else {
      return [
        ...assignments,
        {
          assignmentId: userAssignment.assignmentId,
          userId: userAssignment.userId,
          name: userAssignment.name,
          email: userAssignment.email,
          role: userAssignment.role,
          isAdmin: false,
        },
      ];
    }
  }

  function addGroupAssignment(assignments, groupAssignment) {
    return [
      ...assignments,
      {
        assignmentId: groupAssignment.assignmentId,
        groupId: groupAssignment.groupId,
        name: groupAssignment.name,
        role: groupAssignment.role,
        isAdmin: false,
      },
    ];
  }

  function removeAssignment(assignments, assignmentId) {
    let assignmentIndex = findAssignmentIndex(assignments, assignmentId);
    if (assignmentIndex > -1) {
      return [...assignments.slice(0, assignmentIndex), ...assignments.slice(assignmentIndex + 1)];
    } else {
      return assignments;
    }
  }

  function updateAssignmentRole(assignments, assignmentId, role) {
    let assignmentIndex = findAssignmentIndex(assignments, assignmentId);
    let assignment = { ...assignments[assignmentIndex], ...{ role: role } };
    if (assignmentIndex > -1) {
      return [...assignments.slice(0, assignmentIndex), assignment, ...assignments.slice(assignmentIndex + 1)];
    } else {
      return assignments;
    }
  }

  return AssignmentReducer;
})();
