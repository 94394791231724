import uniq from "lodash/uniq";
import isNumber from "lodash/isNumber";
import { HighChartDirective, HighChartController } from "../highChartDirective";
import { HISTOGRAM_CHART } from "../boost/boostThresholds";

class HistogramDirective extends HighChartDirective {
  controller = Controller;
  static factory = () => new HistogramDirective();
}

class Controller extends HighChartController {
  static $inject = ["$scope", "$timeout", "HighChartLabelSizer"];

  getConfig = (config, data) => {
    let allDataPoints = data.reduce((all, series) => all.concat(series.values), []);
    let categories = uniq(allDataPoints.map(d => d.key));

    return {
      legend: { enabled: false },
      tooltip: { shared: true },
      xAxis: {
        categories: categories,
        labels: {
          enabled: !config.yAxis.hide,
          formatter: function() {
            return config.xAxis.tickFormatter(this.value);
          },
        },
        title: { text: config.xAxis.label || "" },
        gridLineWidth: config.xAxis.hide ? 0 : undefined,
        minorGridLineWidth: config.xAxis.hide ? 0 : undefined,
        lineWidth: config.xAxis.hide ? 0 : undefined,
        minorTickLength: config.xAxis.hide ? 0 : undefined,
        tickLength: config.xAxis.hide ? 0 : undefined,
      },
      yAxis: {
        title: { text: config.yAxis.label || "" },
        labels: {
          enabled: !config.yAxis.hide,
          formatter: function() {
            return config.yAxis.tickFormatter(this.value);
          },
        },
        gridLineWidth: config.yAxis.hide ? 0 : undefined,
        minorGridLineWidth: config.yAxis.hide ? 0 : undefined,
        min: isNumber(config.yAxis.minimum) ? config.yAxis.minimum : undefined,
        max: isNumber(config.yAxis.maximum) ? config.yAxis.maximum : undefined,
        startOnTick: !isNumber(config.yAxis.minimum),
        endOnTick: !isNumber(config.yAxis.maximum),
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: !!config.displayDataLabels,
          },
          events: {
            click: this.handleClick,
          },
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              return `
                <span>${config.xAxis.tickFormatter(this.xValue)}</span>
                <br/>
                <b>${config.yAxis.tickFormatter(this.y)}</b>
              `;
            },
          },
          turboThreshold: 0,
          animation: this.canAnimate(),
        },
      },
      series: data.map((series, index) => ({
        type: "column",
        name: series.key,
        color: this.$scope.isPatternFill ? this.patternFillPalettes(index) : series.color,
        boostThreshold: HISTOGRAM_CHART,
        data: series.values.map(value => ({
          x: categories.indexOf(value.key),
          y: value.values,
          xValue: value.key,
        })),
      })),
    };
  };
}

export default HistogramDirective;
