import React, { Component } from "react";
import PropTypes from "prop-types";
import FieldModel from "../../../models/field/field";
import "./gridHeader.scss";

class GridHeader extends Component {
  static propTypes = {
    fieldModel: PropTypes.instanceOf(FieldModel),
    filterFieldNames: PropTypes.arrayOf(PropTypes.string),
    selectedFieldName: PropTypes.string,
    sortFieldName: PropTypes.string,
    sortOrder: PropTypes.string,
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.fieldModel !== this.props.fieldModel;
  }

  getColumnName() {
    let columnNameClass = "column-display-name";
    if (this.props.fieldModel.name() === this.props.sortFieldName) {
      columnNameClass += " grid-header__column-name--right-additional-padding";
    }
    return <span className={columnNameClass}>{this.props.fieldModel.displayName()}</span>;
  }

  getDataTypeIcon() {
    const iconClasses = {
      numeric: "acl-i-number-sign",
      time: "acl-i-clock",
      datetime: "acl-i-time-and-date",
      date: "acl-i-calendar",
      character: "acl-i-character",
      count: "acl-i-function-symbol",
      logical: "acl-i-boolean",
      file: "acl-i-file-text",
      digital_signature: "acl-i-digital-signature",
      key: "acl-i-key",
    };
    const iconType = this.props.fieldModel.isPrimaryKey() ? "key" : this.props.fieldModel.type();
    const iconClass = iconClasses[iconType] || "acl-i-caret-right";

    return <i className={"grid-header__icon " + iconClass} aria-hidden />;
  }

  getSortIcon() {
    let sortIcon = "";

    if (this.props.fieldModel.name() === this.props.sortFieldName) {
      switch (this.props.sortOrder) {
        case "asc":
          sortIcon = <i className="acl-i-caret-up grid-header__icon grid-header__icon--is-active" aria-hidden />;
          break;
        case "desc":
          sortIcon = <i className="acl-i-caret-down grid-header__icon grid-header__icon--is-active" aria-hidden />;
          break;
        default:
      }
    }
    return sortIcon;
  }

  getFilterIcon() {
    let isActiveClass = "";
    if (
      Array.isArray(this.props.filterFieldNames) &&
      this.props.filterFieldNames.indexOf(this.props.fieldModel.name()) >= 0
    ) {
      isActiveClass = "grid-header__icon--is-active";
    }

    return <i className={"acl-i-filter-solid grid-header__icon " + isActiveClass} aria-hidden />;
  }

  bodyClasses() {
    let classes = "grid-header__body grid-header__multiline";
    if (this.props.fieldModel.name() === this.props.selectedFieldName) {
      classes = `${classes} grid-header--is-active`;
    }
    if (this.props.fieldModel.type() === "numeric") {
      classes = `${classes} htRight`;
    }
    return classes;
  }

  getUrlParams() {
    const url = window.location.pathname;
    return url.split("/").filter(param => param !== "");
  }

  render() {
    const urlParams = this.getUrlParams();
    const isStoryBoardsPublicPage = urlParams.includes("storyboard_links");
    const isStoryBoardsInternalPage = urlParams.includes("storyboards");
    const isStoryBoardsPage = isStoryBoardsPublicPage || isStoryBoardsInternalPage;
    const isSummaryTable = this.props.sourcePage && this.props.sourcePage === "summary_table";
    const dataTypeIcon = isStoryBoardsPage || isSummaryTable ? this.getDataTypeIcon() : "";
    const displayName = this.getColumnName();
    const sortIcon = this.getSortIcon();
    const filterIcon = this.getFilterIcon();
    const bodyClasses = this.bodyClasses();

    return (
      <span
        className={bodyClasses}
        data-header-row-index={this.props.headerRowIndex}
        data-header-col-index={this.props.headerColIndex}
      >
        {dataTypeIcon}
        {displayName}
        <span className="grid-header__icon-container">
          {sortIcon}
          {filterIcon}
        </span>
      </span>
    );
  }
}

export default GridHeader;
