export default class LocalStorageHelper {
  static get = key => {
    try {
      return JSON.parse(window.localStorage.getItem(key));
    } catch (e) {
      return null;
    }
  };

  static set = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };
}
