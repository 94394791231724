angular.module("acl.common.tabSwitcher").directive("aclTabSwitcher", function() {
  return {
    restrict: "E",
    replace: true,
    scope: {
      tabData: "=",
    },
    templateUrl: "visualizer/js/modules/util/tabSwitcher/tabSwitcher.tpl.html",
  };
});
