import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

NameSection.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  value: PropTypes.string,
};

function NameSection({ className, name, onChange, options, title, value }) {
  const classes = classNames("filter-section__name", className);
  let optionsHtml = "";

  if (options) {
    optionsHtml = options.map((field, index) => (
      <option key={`${name}_${index}`} value={field}>
        {field}
      </option>
    ));
  }

  return (
    <div className={classes}>
      <label htmlFor={name}>{title}</label>
      <select
        className="viz-select"
        disabled={!options || options.length === 0}
        id={name}
        onChange={onChange}
        name={name}
        value={value || ""}
      >
        {optionsHtml}
      </select>
    </div>
  );
}

export default NameSection;
