import FieldFormatter from "@viz-ui/services/formatters/fieldFormatter";

(() => {
  var dateFormatOptions = {
    restrict: "E",
    bindings: {
      fieldFormat: "<",
      fieldName: "<",
      onFieldFormatChange: "&",
    },
    controllerAs: "dateOptions",
    controller: DateFormatOptionsController,
    templateUrl: "visualizer/js/modules/core/fieldFormatOptions/dateFormatOptions.tpl.html",
  };

  function DateFormatOptionsController(FieldFormat) {
    "ngInject";
    var dateOptions = this;

    dateOptions.$onInit = function() {
      setupOptions();
    };

    dateOptions.$onChanges = function(changes) {
      if (changes.fieldFormat) {
        setupOptions();
      }
    };

    dateOptions.handleFormatClick = function(format) {
      let fieldFormat;
      if (dateOptions.fieldFormat) {
        fieldFormat = dateOptions.fieldFormat.clone().assign(format);
      } else {
        fieldFormat = new FieldFormat(format);
      }
      dateOptions.onFieldFormatChange({ fieldFormat: fieldFormat });
    };

    function setupOptions() {
      dateOptions.formatOptions = FieldFormatter.getDateFormattingOptions(selectedFormattingOption());
    }

    function selectedFormattingOption() {
      return dateOptions.fieldFormat ? dateOptions.fieldFormat.toJson() : {};
    }
  }

  angular.module("acl.visualizer.fieldFormatOptions").component("dateFormatOptions", dateFormatOptions);
})();
