import React from "react";
import PropTypes from "prop-types";
import DropdownMenu from "@paprika/overflow-menu";
import Confirmation from "@paprika/confirmation";
import ToolTip from "@viz-ui/components/ToolTip";
import i18n from "@viz-ui/i18n/i18n";

const propTypes = {
  hasSave: PropTypes.bool,
  hasSaveAs: PropTypes.bool,
  hasSaveConfirmation: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  isSaveAsDisabled: PropTypes.bool,
  onClickSave: PropTypes.func,
  onClickSaveAs: PropTypes.func,
  saveAsTooltip: PropTypes.string,
  saveTooltip: PropTypes.string,
  tooltip: PropTypes.string,
};

const defaultProps = {
  hasSave: true,
  hasSaveAs: true,
  hasSaveConfirmation: false,
  isSaveDisabled: false,
  isSaveAsDisabled: false,
  onClickSave: () => {},
  onClickSaveAs: () => {},
  saveAsTooltip: "",
  saveTooltip: "",
  tooltip: "",
};

export default class SaveButton extends React.Component {
  dropdownToolTipProps = {
    align: "left",
    className: "save-button__drop-down-tooltip",
    isDark: false,
    maxWidth: 240,
  };

  saveLabelText = i18n.t("_SaveButton.Save.Label_");

  handleConfirmationSave = onClose => {
    onClose();
    this.handleSave();
  };

  handleSave = () => {
    this.props.onClickSave();
  };

  handleSaveAs = () => {
    this.props.onClickSaveAs();
  };

  renderWithTooltip(node, message, props = { align: "top" }) {
    return message ? (
      <ToolTip {...props} content={message}>
        {node}
      </ToolTip>
    ) : (
      node
    );
  }

  renderSaveConfirmation = onCloseMenu => (
    <Confirmation
      confirmButtonType="primary"
      confirmLabel={i18n.t("_SaveButton.Confirmation.Yes.Label_")}
      body={i18n.t("_SaveButton.Confirmation.Body.Label_")}
      onConfirm={() => this.handleConfirmationSave(onCloseMenu)}
      onClose={onCloseMenu}
      heading={i18n.t("_SaveButton.Confirmation.Title.Label_")}
    />
  );

  renderSave = () => {
    const { hasSave, hasSaveConfirmation, isSaveDisabled, saveTooltip } = this.props;
    const confirmation = hasSaveConfirmation ? onCloseMenu => this.renderSaveConfirmation(onCloseMenu) : null;
    if (!hasSave) return null;
    return (
      <DropdownMenu.Item
        className="save-button__save"
        isDisabled={isSaveDisabled}
        onClick={this.handleSave}
        renderConfirmation={confirmation}
      >
        {this.renderWithTooltip(this.saveLabelText, isSaveDisabled && saveTooltip, {
          ...this.dropdownToolTipProps,
          "aria-label": this.saveLabelText,
        })}
      </DropdownMenu.Item>
    );
  };

  renderSaveAs = () => {
    const { hasSaveAs, isSaveAsDisabled, saveAsTooltip } = this.props;
    if (!hasSaveAs) return null;
    const textContent = i18n.t("_SaveButton.SaveAs.Label_");
    return (
      <DropdownMenu.Item className="save-button__save-as" isDisabled={isSaveAsDisabled} onClick={this.handleSaveAs}>
        {this.renderWithTooltip(textContent, isSaveAsDisabled && saveAsTooltip, {
          ...this.dropdownToolTipProps,
          "aria-label": textContent,
        })}
      </DropdownMenu.Item>
    );
  };

  renderDropdownMenu() {
    const { isSaveDisabled, isSaveAsDisabled, tooltip } = this.props;
    const isDisabled = isSaveDisabled && isSaveAsDisabled;
    return (
      <DropdownMenu align="bottom" edge="right" minWidth={140} zIndex={350}>
        <DropdownMenu.Trigger
          className="save-button__dropdown-trigger"
          kind="primary"
          isDropdown
          isDisabled={isDisabled}
        >
          {this.renderWithTooltip(this.saveLabelText, isDisabled && tooltip)}
        </DropdownMenu.Trigger>
        {this.renderSave()}
        {this.renderSaveAs()}
      </DropdownMenu>
    );
  }

  render() {
    const { hasSave, hasSaveAs } = this.props;
    if (!hasSave && !hasSaveAs) return null;

    return <div className="save-button">{this.renderDropdownMenu()}</div>;
  }
}

SaveButton.propTypes = propTypes;
SaveButton.defaultProps = defaultProps;
