import ConfirmationModal from "@viz-ui/components/confirmationModal/confirmationModal.jsx";
import ReactDirective from "acl-ui/angular/reactDirective.umd";

angular
  .module("acl.visualizer.metricApp", [
    "acl.common.autoHeight",
    "acl.common.event",
    "acl.common.globalNav",
    "acl.common.localize",
    // FIXME: Define ConditionalOperators somewhere else and remove this dependency.
    "acl.core.conditionalCriteriaSelector",
    "acl.visualizer.archivePill",
    "acl.visualizer.dataGrid",
    "acl.visualizer.filterHacks",
    "acl.visualizer.filterPanel",
    "acl.visualizer.formatters",
    "acl.visualizer.indicatorConfigPanel",
    "acl.visualizer.indicator",
    "acl.visualizer.loadingAnimation",
    "acl.visualizer.model.filter",
    "acl.visualizer.model.metric",
    "acl.visualizer.metric.backend",
    "acl.visualizer.model.table",
    "acl.visualizer.quickMenu",
    "highbond.visualizer.actionButtons",
    "ngAnimate",
    "templates-visualizer",
    "ui.router",
  ])
  .directive("confirmationModal", ReactDirective.getFactory(ConfirmationModal));
