import Model from "../model";
import Field from "../field/field";
import ValueFilter from "./valueFilter";
import CriteriaFilter from "./criteriaFilter";

class FieldFilterSet extends Model {
  static modelType = "FieldFilterSet";

  field(...args) {
    return this.accessor("field", args, Field);
  }

  isActive(...args) {
    return this.accessor("isActive", args, "boolean");
  }

  criteriaFilters(...args) {
    return this.accessor("criteriaFilters", args, [CriteriaFilter]);
  }

  valueFilter(...args) {
    return this.accessor("valueFilter", args, ValueFilter);
  }

  showStoryboardIndicator(...args) {
    return this.accessor("showStoryboardIndicator", args, "boolean");
  }

  static fromJson(json) {
    return new FieldFilterSet()
      .field(new Field().name(json.name).type(json.type))
      .isActive(json.active)
      .criteriaFilters(deserializeCriteriaFilters(json.filters))
      .valueFilter(json.quickFilter ? ValueFilter.fromJson(json.quickFilter) : undefined)
      .showStoryboardIndicator(json.showStoryboardIndicator);
  }

  toJson() {
    const field = this.field();
    return this._deleteUndefinedProperties({
      name: field ? field.name() : undefined,
      type: field ? field.type() : undefined,
      active: this.isActive(),
      filters: serializeCriteriaFilters(this.criteriaFilters()),
      quickFilter: this.valueFilter() ? this.valueFilter().toJson() : undefined,
      showStoryboardIndicator: this.showStoryboardIndicator(),
    });
  }
}

function deserializeCriteriaFilters(data) {
  if (data) {
    return data.map(CriteriaFilter.fromJson);
  }
  return [];
}

function serializeCriteriaFilters(criteriaFilters) {
  if (criteriaFilters && criteriaFilters.length) {
    return criteriaFilters.map(criteriaFilter => criteriaFilter.toJson());
  }
  return undefined;
}

export default FieldFilterSet;
