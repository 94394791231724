import { HighChartDirective, HighChartController } from "../highChartDirective";
import { AREA_CHART } from "../boost/boostThresholds";

class AreaChartDirective extends HighChartDirective {
  controller = Controller;
  static factory = () => new AreaChartDirective();
}

export class Controller extends HighChartController {
  static stacking = {
    stack: "normal",
    expand: "percent",
    overlap: undefined,
  };

  static configPath = {
    labelFontSize: [
      "legend.itemStyle.fontSize",
      "plotOptions.series.dataLabels.style.fontSize",
      "tooltip.style.fontSize",
      "xAxis.labels.style.fontSize",
      "xAxis.title.style.fontSize",
      "yAxis.labels.style.fontSize",
      "yAxis.title.style.fontSize",
    ],
  };

  constructor($scope, $timeout, HighChartLabelSizer, Sorter, Localize) {
    "ngInject";
    super($scope, $timeout, HighChartLabelSizer);
    this.Sorter = Sorter;
    this.Localize = Localize;
  }

  onLegendItemClick = item => {
    const showAll = !this.hcConfig.series.some(entry => entry.visible && entry.name !== item.name);
    const series = this.hcConfig.series.map(entry =>
      angular.extend({}, entry, {
        visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
      })
    );
    this.updateHcConfig({ series });
  };

  onLegendItemDoubleClick = item => {
    const series = this.hcConfig.series.map(entry => angular.extend({}, entry, { visible: entry.name === item.name }));
    this.updateHcConfig({ series });
  };

  getConfig = (config, rawData) => {
    let categories;
    if ((config.xAxis.axisType === "character" || config.xAxis.axisType === "logical") && rawData.length) {
      categories = rawData[0].values.map(d => d.x);
    }
    let data = rawData
      .map(series =>
        angular.extend({}, series, {
          values: config.interpolate
            ? this.trimInterpolated(series.values)
            : series.values.filter(value => !value.interpolated),
        })
      )
      .reverse();

    let hcConfig = {
      legend: {
        enabled: config.showLegend && config.hasMultipleSeries,
        align: "right",
        verticalAlign: "top",
        reversed: true,
        labelFormatter: function() {
          return config.labelFormatter && this.options.name !== "(blank)"
            ? config.labelFormatter(this.options.legendDisplayName)
            : this.options.legendDisplayName;
        },
      },
      boost: this.getBoostConfig(config),
      tooltip: {
        formatter: function() {
          let header = config.hasMultipleSeries
            ? `
              <span style="text-decoration: underline grey;">${
                config.labelFormatter && this.series.options.name !== "(blank)"
                  ? config.labelFormatter(this.series.options.legendDisplayName)
                  : this.series.options.legendDisplayName
              }</span>
              <br/>
            `
            : "";
          let body = `
            <span>${config.xAxis.tickFormatter(this.point.xValue)}</span>
            <br/>
            <b>${config.yAxis.tickFormatter(this.point.yValue)}</b>
          `;
          return this.point.isInterpolated ? false : header + body;
        },
      },
      plotOptions: {
        series: {
          stacking: Controller.stacking[config.chartStyle],
          marker: {
            enabled: config.displayDataLabels,
          },
          dataLabels: {
            enabled: config.displayDataLabels,
            formatter: function() {
              return config.yAxis.tickFormatter(this.y);
            },
          },
          connectNulls: config.interpolate,
          point: {
            events: {
              click: this.clickWrapper(null, point => {
                if (point && !point.isInterpolated) {
                  this.zoomIn(config.hasMultipleSeries ? [point.series.name, point.xValue] : [point.xValue]);
                }
              }),
            },
          },
          events: {
            legendItemClick: this.clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
          },
          turboThreshold: 0,
          animation: this.canAnimate(),
        },
      },
      xAxis: {
        categories: categories,
        labels: {
          formatter: function() {
            return config.xAxis.tickFormatter(this.value);
          },
        },
        tickmarkPlacement: "on",
        title: {
          text: config.xAxis.label,
        },
      },
      yAxis: {
        title: {
          text: config.yAxis.label,
        },
        labels: {
          formatter: function() {
            return config.chartStyle === "expand" ? `${this.value}%` : config.yAxis.tickFormatter(this.value);
          },
        },
        min: config.yAxis.minimum === null ? undefined : config.yAxis.minimum,
        max: config.yAxis.maximum === null ? undefined : config.yAxis.maximum,
      },
      // Adding legendDisplayName property to handle (blank) value  & to localize the string (issue GRCPRD-4135)
      series: data.map((series, index) => ({
        type: "area",
        name: series.key,
        legendDisplayName:
          series.key === "(blank)" ? this.Localize.getLocalizedString("_Filter.BlankValue.Label_") : series.key,
        color:
          !this.getBoostConfig(
            config,
            categories && categories.length > data.length ? categories.length >= AREA_CHART : data.length >= AREA_CHART
          ).enabled && this.$scope.isPatternFill
            ? this.patternFillPalettes(index)
            : series.color,
        visible: series.visible !== false,
        boostThreshold: AREA_CHART,
        data: series.values.map(value => ({
          x: categories ? categories.indexOf(value.x) : value.x,
          xValue: value.x,
          y: value.y,
          yValue: value.y,
          isInterpolated: value.interpolated,
          dataLabels: { enabled: config.displayDataLabels && !value.interpolated },
          marker: {
            enabled: config.displayDataLabels && !value.interpolated,
            states: { hover: { enabled: !value.interpolated } },
          },
        })),
      })),
    };
    hcConfig = this.updateLabelFontSize(hcConfig, Controller.configPath.labelFontSize);
    return hcConfig;
  };

  zoomIn = keyArray => {
    if (angular.isFunction(this.$scope.zoomInHandler)) {
      this.$scope.zoomInHandler(keyArray);
    }
  };

  trimInterpolated(values) {
    return _(values).find(value => !value.interpolated)
      ? values.slice(
          _(values).findIndex(value => !value.interpolated),
          _(values).findLastIndex(value => !value.interpolated) + 1
        )
      : [];
  }
}

export default AreaChartDirective;
