import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Panel from "@paprika/panel";
import Button from "@paprika/button";
import i18n from "@viz-ui/i18n/i18n";
import ListBox from "@paprika/list-box";
import Radio from "@paprika/radio";
import Styler from "@paprika/stylers";
import PdfIcon from "@acl-services/wasabicons/lib/DownloadPdf";
import CsvIcon from "@acl-services/wasabicons/lib/Csv";
import DocxIcon from "@acl-services/wasabicons/lib/DownloadWord";
import XlsxIcon from "@acl-services/wasabicons/lib/DownloadExcel";
import PptIcon from "@acl-services/wasabicons/lib/DownloadPowerpoint";
import InfoCircle from "@acl-services/wasabicons/lib/InfoCircle";
import GraphqlPayloadGenerator from "@viz-ui/services/downloadReport/graphqlPayloadGenerator";

import "./DownloadReportPanel.scss";

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  generateReportDownload: PropTypes.func.isRequired,
  emailReport: PropTypes.func.isRequired,
  reportTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  vizDataModel: PropTypes.objectOf(PropTypes.any).isRequired,
  checkedRowIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  openStatuses: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const getIconFromFormatType = formatType => {
  switch (formatType) {
    case "pdf":
      return <PdfIcon size={Styler.spacer(2.3)} />;
    case "docx":
      return <DocxIcon size={Styler.spacer(2.3)} />;
    case "xlsx":
      return <XlsxIcon size={Styler.spacer(2.3)} />;
    case "csv":
      return <CsvIcon size={Styler.spacer(2.3)} />;
    case "pptx":
      return <PptIcon size={Styler.spacer(2.3)} />;
    default:
      return null;
  }
};

const DownloadReportPanel = props => {
  const [reportFor, setReportFor] = useState("all");
  const [reportFormatTypes, setReportFormatTypes] = useState([]);
  const [selectedReport, setSelectedReport] = useState();
  const [reportFormatType, setReportFormatType] = useState();

  const isButtonEnabled = () => !(reportFor && selectedReport && reportFormatType);

  const clearAndClose = () => {
    setReportFor("all");
    setReportFormatTypes([]);
    setSelectedReport();
    setReportFormatType();
    props.toggle();
  };

  const resetReportTypes = () => {
    setReportFormatTypes([]);
    setSelectedReport();
    setReportFormatType();
  };

  const generateReport = () => {
    const { report_id } = reportFormatType;
    const { report_group_by } = selectedReport;
    const report = {
      reportId: report_id,
      reportName: report_group_by,
    };
    const filterExpression = generateGraphqlQueryVariable();
    props.generateReportDownload(report, filterExpression);
    setReportFor("all");
    setReportFormatTypes([]);
    setSelectedReport();
    setReportFormatType();
  };

  const emailReport = () => {
    const { report_id } = reportFormatType;
    const { report_group_by } = selectedReport;
    const report = {
      reportId: report_id,
      reportName: report_group_by,
    };
    const filterExpression = generateGraphqlQueryVariable();
    props.emailReport(report, filterExpression);
    setReportFor("all");
    setReportFormatTypes([]);
    setSelectedReport();
    setReportFormatType();
  };

  const generateGraphqlQueryVariable = () => {
    const filterConfig = props.vizDataModel.getFilterConfig();
    const graphqlQueryVariables = new GraphqlPayloadGenerator(
      filterConfig,
      AclExceptionControlTestId,
      AclExceptionCurrentUser.name,
      reportFor === "selected" ? props.checkedRowIds : [],
      props.openStatuses
    ).getPayload();
    return graphqlQueryVariables;
  };

  const refHeading = useRef(null);
  const handleFocus = () => {
    if (refHeading.current) refHeading.current.focus();
  };

  return (
    <Panel
      zIndex={999}
      isOpen={props.isOpen}
      onClose={() => {
        clearAndClose();
        setReportFor("all"); // to reset the selection after each load
      }}
      onAfterOpen={() => {
        handleFocus();
      }}
      className="visualizer__download-report-panel"
    >
      <Panel.Overlay />
      <Panel.Header refHeading={refHeading} isHeaderSticky>
        {i18n.t("_ActionButtons.ReportDownload.Label_")}
      </Panel.Header>
      <Panel.Content>
        <div className="user-info-section">
          <div className="user-info-section__icon">
            <InfoCircle size={Styler.spacer(3)} />
          </div>
          <div className="user-info-section__text">{i18n.t("_ReportDownloadPanel.informationMessage.Label_")}</div>
        </div>
        <div className="download-report__section">
          <h4 className="download-report__form-heading">{i18n.t("_ReportDownloadPanel.GenerateReportFor.Label_")}</h4>
          <Radio.Group
            id="reportFor"
            onChange={e => {
              setReportFor(e === 1 ? "selected" : "all");
            }}
          >
            <Radio defaultIsChecked={reportFor === "all"}>
              <Radio.Input value="all" className="download-report__all-records-radio" />
              {i18n.t("_ReportDownloadPanel.radioAllRecords.Label_")}
            </Radio>
            <Radio
              defaultIsChecked={reportFor === "selected"}
              isDisabled={props.checkedRowIds && props.checkedRowIds.length === 0}
            >
              <Radio.Input value="selected" className="download-report__selected-records-radio" />
              {i18n.t("_ReportDownloadModal.radioSelectedRecords.Label_")}
            </Radio>
          </Radio.Group>
        </div>
        <div className="download-report__divider" />
        <div className="download-report__section">
          <h4 className="download-report__form-heading">{i18n.t("_ReportDownloadPanel.SelectTemplate.Label_")}</h4>
          <ListBox
            className="download-report__report-list-box"
            onChange={index => {
              setReportFormatTypes(props.reportTypes[index].formatTypes);
              setSelectedReport(props.reportTypes[index]);
            }}
          >
            <ListBox.Filter className="download-report__report-list-box-search-input" />
            <ListBox.Popover zIndex={9999} />
            <ListBox.Trigger
              onClickClear={() => {
                resetReportTypes();
              }}
            />
            {props.reportTypes.map(item => (
              <ListBox.Option
                className="download-report__report-list-box-item"
                key={item.report_group_by}
                value={item}
                defaultIsSelected={selectedReport === item}
              >
                {item.report_group_by}
              </ListBox.Option>
            ))}
          </ListBox>
        </div>
        <div className="download-report__divider" />
        <div className="download-report__section">
          <h4 className="download-report__form-heading">{i18n.t("_ReportDownloadPanel.SelectReportFormat.Label_")}</h4>
          {reportFormatTypes.length > 0 ? (
            <Radio.Group
              id="reportFormatTypes"
              onChange={e => {
                setReportFormatType(reportFormatTypes[e]);
              }}
            >
              {reportFormatTypes.map(reportFormat => (
                <Radio key={reportFormat.report_id}>
                  <Radio.Input value={reportFormat.report_id} className="download-report__report-format-type-radio" />
                  {getIconFromFormatType(reportFormat.report_generate)}
                  &nbsp;
                  {reportFormat.report_generate}
                </Radio>
              ))}
            </Radio.Group>
          ) : (
            <div className="download-report__form-default-message">
              <i>{i18n.t("_ReportDownloadPanel.SelectTemplateDefaultMessage.Label_")}</i>
            </div>
          )}
        </div>
      </Panel.Content>
      <Panel.Footer isSticky>
        <Button
          kind="Button.types.kind.PRIMARY"
          className="primary"
          isDisabled={isButtonEnabled()}
          onClick={() => {
            generateReport();
          }}
          title={i18n.t("_ActionButtons.ReportDownload.Label_")}
        >
          {i18n.t("_ActionButtons.ReportDownload.Label_")}
        </Button>
        <Button
          isDisabled={isButtonEnabled()}
          onClick={() => {
            emailReport();
          }}
          title={i18n.t("_ReportDownloadPanel.EmailReport.Label_")}
        >
          {i18n.t("_ReportDownloadPanel.EmailReport.Label_")}
        </Button>
        <Button
          className="cancel"
          kind="minor"
          onClick={() => {
            clearAndClose();
          }}
          title={i18n.t("_ReportDownloadPanel.Cancel.Label_")}
        >
          {i18n.t("_ReportDownloadPanel.Cancel.Label_")}
        </Button>
      </Panel.Footer>
    </Panel>
  );
};

DownloadReportPanel.displayName = "DownloadReportPanel";
DownloadReportPanel.propTypes = propTypes;

export default DownloadReportPanel;
