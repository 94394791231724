import uniq from "lodash/uniq";
import { aliasCountAggregationFragment, aliasColumnAggregationFragment } from "./queryFragment";

export default class TreemapQueryGenerator {
  static generate(tableId, filter, groupByFields, aggregationFields, scope) {
    return {
      query: query(groupByFields, aggregationFields),
      variables: { tableId, filter: JSON.stringify(filter), scope },
    };
  }
}

function query(groupByFields, aggregationFields) {
  return `
    query treemapData($tableId: Int!, $filter: String, $scope: Scope) {
      table(id: $tableId, scope: $scope) {
        ${columnAggs(groupByFields, aggregationFields)}
      }
    }
  `;
}

function columnAggs(groupByFields, aggregationFields) {
  return groupByFields
    .map((groupByField, layerIndex) => columnAggForLayer(groupByFields, aggregationFields, layerIndex))
    .join(",");
}

function columnAggForLayer(groupByFields, aggregationFields, layerIndex) {
  const currentLayerGroupByFields = groupByFields.slice(0, layerIndex + 1);
  const isLastLayer = groupByFields.length === layerIndex + 1;

  return `
    layer${layerIndex}: columnAgg(groupBy: "${groupByFieldString(currentLayerGroupByFields)}", filter: $filter) {
      ${isLastLayer ? aggregationValues(aggregationFields) : "count"}
    }
  `;
}

function groupByFieldString(fieldNames) {
  return JSON.stringify(fieldNames.map(fieldName => ({ field: fieldName }))).replace(/"/g, '\\"');
}

function aggregationValues(aggregationFields) {
  return aggregationFields.map((aggregationField, aggregationFieldIndex) => {
    const aliasName = aggregationFieldIndex === 0 ? "__value" : "__colorValue";
    const fieldName = aggregationField.fieldName;
    const functions = uniq(aggregationField.functions).join(",");
    return aggregationValueFragment(aliasName, fieldName, functions);
  });
}

function aggregationValueFragment(aliasName, fieldName, functions) {
  if (functions === "count") {
    return aliasCountAggregationFragment(aliasName);
  }
  return aliasColumnAggregationFragment(aliasName, fieldName, functions);
}
