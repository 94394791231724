import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Bubbly from "acl-ui/components/Bubbly";
import Sortable from "@paprika/sortable";
import i18n from "@viz-ui/i18n/i18n";
import Sorter from "../../services/sorters/sorter";
import "./valuesSortable.scss";
import GlobalValueFormatter from "../../services/formatters/globalValueFormatter";

const { arrayOf, func, instanceOf, number, oneOfType, string } = PropTypes;

const ValuesSortable = props => {
  const { dataType, fieldFormatIdentifier, fieldName, sortOrder, onChange, values, valuesOrder } = props;
  const getSortedValues = originalValues => {
    if (sortOrder === "custom" && valuesOrder.length)
      return Sorter.sort(originalValues, { dataType, valuesOrder, valueParser });

    return originalValues;
  };

  const [sortedItems, setSortedItems] = useState(getSortedValues(values));
  const [currentFieldName, setCurrentFieldName] = useState(fieldName);
  useEffect(() => setSortedItems(getSortedValues(values)), [values, sortOrder, valuesOrder]);
  useEffect(() => setCurrentFieldName(fieldName), [fieldName]);

  const changeHandler = valuesChange => {
    const { source: sourceIndex, destination: destinationIndex } = valuesChange;
    const movedItem = sortedItems[sourceIndex];
    const reorderedSortedItem = [...sortedItems];
    reorderedSortedItem.splice(sourceIndex, 1);
    reorderedSortedItem.splice(destinationIndex, 0, movedItem);
    setSortedItems(reorderedSortedItem);
    onChange(reorderedSortedItem.map(valueParser));
  };

  const valueParser = value => GlobalValueFormatter.normalizeValue(value, dataType);

  const getItemDisplay = item =>
    GlobalValueFormatter.formatValue(item, fieldFormatIdentifier, currentFieldName).toString();

  const getSortableItems = () =>
    sortedItems.map(item => (
      <Sortable.Item key={item} sortId={getItemDisplay(item)}>
        {getItemDisplay(item)}
      </Sortable.Item>
    ));

  return values.length ? (
    <Sortable onChange={changeHandler} hasNumbers={false}>
      {getSortableItems()}
    </Sortable>
  ) : (
    <Bubbly
      alignBubbly="center"
      bubblyType="noneAvailable"
      content={i18n.t("_SortConfig.NoValues.Body_")}
      footer=""
      header={i18n.t("_SortConfig.NoValues.Heading_")}
    />
  );
};

ValuesSortable.propTypes = {
  dataType: string.isRequired,
  fieldFormatIdentifier: string,
  fieldName: string,
  onChange: func,
  sortOrder: string,
  values: arrayOf(oneOfType([number, string, instanceOf(Date)])).isRequired,
  valuesOrder: arrayOf(oneOfType([number, string, instanceOf(Date)])).isRequired,
};
ValuesSortable.defaultProps = {
  fieldFormatIdentifier: "",
  fieldName: "",
  onChange: () => {},
  sortOrder: "default",
};

export default ValuesSortable;
