import React from "react";
import Toast from "@paprika/toast";
import i18n from "@viz-ui/i18n/i18n";
import "./summaryTableToast.scss";

export default class SummaryTableToast extends React.Component {
  static propTypes = {};

  render() {
    return (
      <Toast kind="info" className="summary-table-toast" hasCloseButton={false}>
        {i18n.t("_SummaryTable.ToastMessage_")}
      </Toast>
    );
  }
}
