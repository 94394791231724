(() => {
  var characterFormatOptions = {
    restrict: "E",
    bindings: {
      fieldFormat: "<",
      fieldName: "<",
      onFieldFormatChange: "&",
    },
    controllerAs: "characterOptions",
    controller: CharacterFormatOptionsController,
    templateUrl: "visualizer/js/modules/core/fieldFormatOptions/characterFormatOptions.tpl.html",
  };

  function CharacterFormatOptionsController(FieldFormat, AppConfig) {
    "ngInject";
    let characterOptions = this;

    characterOptions.$onInit = function() {
      setupOptions();
    };

    characterOptions.$onChanges = function(changes) {
      if (changes.field || changes.fieldFormat) {
        setupOptions();
      }
    };

    characterOptions.handleIsHtmlClick = function() {
      let fieldFormat;
      if (characterOptions.fieldFormat) {
        fieldFormat = characterOptions.fieldFormat.clone();
        fieldFormat.isHtml(!characterOptions.fieldFormat.isHtml());
        fieldFormat.isURL(false);
      } else {
        fieldFormat = new FieldFormat({ isHtml: false, isURL: false });
      }
      characterOptions.onFieldFormatChange({ fieldFormat: fieldFormat });
    };

    characterOptions.handleIsUrlClick = function() {
      let fieldFormat;
      if (characterOptions.fieldFormat) {
        fieldFormat = characterOptions.fieldFormat.clone();
        fieldFormat.isURL(!characterOptions.fieldFormat.isURL());
        fieldFormat.isHtml(false);
      } else {
        fieldFormat = new FieldFormat({ isURL: false, isHtml: false });
      }
      characterOptions.onFieldFormatChange({ fieldFormat: fieldFormat });
    };

    characterOptions.enableUrlFormatOption = function() {
      return AppConfig.features.urlFormatOptions;
    };

    function setupOptions() {
      characterOptions.isHtml = characterOptions.fieldFormat ? characterOptions.fieldFormat.isHtml() : false;
      characterOptions.isURL = characterOptions.fieldFormat ? characterOptions.fieldFormat.isURL() : false;
    }
  }

  angular.module("acl.visualizer.fieldFormatOptions").component("characterFormatOptions", characterFormatOptions);
})();
