import React from "react";
import PropTypes from "prop-types";
import tokens from "@paprika/tokens";
import Thumbsup from "@acl-services/wasabicons/lib/Thumbsup";
import StarFilled from "@acl-services/wasabicons/lib/StarFilled";
import Flag from "@acl-services/wasabicons/lib/Flag";
import ArrowUpCircle from "@acl-services/wasabicons/lib/ArrowUpCircle";
import ArrowDownCircle from "@acl-services/wasabicons/lib/ArrowDownCircle";
import ArrowLeftCircle from "@acl-services/wasabicons/lib/ArrowLeftCircle";
import ArrowRightCircle from "@acl-services/wasabicons/lib/ArrowRightCircle";
import PlusCircle from "@acl-services/wasabicons/lib/PlusCircle";
import MinusCircle from "@acl-services/wasabicons/lib/MinusCircle";
import CheckCircle from "@acl-services/wasabicons/lib/CheckCircle";
import TimesCircle from "@acl-services/wasabicons/lib/TimesCircle";

const ICON_COMPONENT_MAP = {
  star: StarFilled,
  flag: Flag,
  like: Thumbsup,
  "arrow-up": ArrowUpCircle,
  "arrow-down": ArrowDownCircle,
  "arrow-left": ArrowLeftCircle,
  "arrow-right": ArrowRightCircle,
  plus: PlusCircle,
  minus: MinusCircle,
  check: CheckCircle,
  times: TimesCircle,
};

const ConditionalFormattingIcon = props => {
  const { iconName, color } = props;

  const IconComponent = ICON_COMPONENT_MAP[iconName];
  return IconComponent ? (
    <IconComponent
      style={{ color: `${color} !important` }}
      color={color}
      data-qa-anchor={`condition-formatting-icon__${iconName}`}
      size="15px"
    />
  ) : (
    <div />
  );
};

ConditionalFormattingIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  color: PropTypes.string,
};
ConditionalFormattingIcon.defaultProps = {
  color: tokens.color.black,
};

export default ConditionalFormattingIcon;
