import ConditionalFormatting from "@viz-ui/services/quickMenu/conditionalFormat/conditionalFormattingService";
import FilterHacksService from "@viz-ui/services/metrics/filterHacksService";

angular
  .module("acl.visualizer.filterHacks")
  .service("FilterHacksService", function(
    ConditionalFormat,
    ConditionalOperators,
    DataFilter,
    DataModel,
    EventService,
    FieldFormatAdapter,
    FilterConfig,
    Localize,
    Sorter,
    AppConfig
  ) {
    var eventService = EventService.register("acl.visualizer.metricApp.FilterHacksService");
    //will be enabled once datamodel will be integrated
    if (AppConfig.features.migrateVizServices) {
      return FilterHacksService;
    }
    var filterHacks = {
      validateFilters: function(filterConfigObj) {
        var valid;
        if (!filterConfigObj) {
          filterConfigObj = DataModel.getFilterConfigDeprecated();
        }
        valid = DataModel.filtersValid() && !DataFilter.hasInvalidFilters(filterConfigObj);
        if (!valid) {
          eventService.publish("notify", "warning", Localize.getLocalizedString("_FilterConfig.Invalid.Error_"), true);
        }
        return valid;
      },

      applySort: function(sortField, sortOrder) {
        if (sortField && sortOrder) {
          DataFilter.setOrToggleSort(sortField, sortOrder);
          const dataModelSortFieldId = DataModel.filterConfig.sortFieldId();
          const dataModelSortOrder = DataModel.filterConfig.sortOrder();
          eventService.publish("dataTable.sortChange", dataModelSortFieldId, dataModelSortOrder);
          eventService.publish("dataTable.dataChange", DataModel.table.data());

          filterHacks.closeQuickMenu();
        }
      },

      closeQuickMenu: function() {
        eventService.publish("quickMenu.close");
      },

      getFilterConfig: function() {
        var filterConfigObj = DataModel.getFilterConfigDeprecated();
        return FilterConfig.fromJson(filterConfigObj);
      },

      getTableObj: function() {
        return DataModel.getTableObj();
      },

      filtersOpenChanged: isFilterPanelOpen => {
        DataModel.filtersOpen(isFilterPanelOpen);
      },

      onFiltersOpenChange: callback =>
        DataModel.subscribe("filtersOpen", () => {
          const newValue = DataModel.filtersOpen();
          if (!newValue) {
            // Force to keep the filter panel open.
            DataModel.filtersOpen(true);
          }
          callback(newValue);
        }),

      isFilterPanelOpen: function() {
        return DataModel.filtersOpen();
      },

      getFieldFormatModelsByName: DataModel.tableConfig.getFieldFormatModelsByName,

      filterConfigChanged: function(filterConfig) {
        var serialized = filterConfig.toJson();
        var filterConfigObj = DataModel.getFilterConfigDeprecated();
        filterConfigObj.filtersOpen = serialized.filtersOpen;
        filterConfigObj.sortField = serialized.sortField;
        filterConfigObj.params = serialized.params;
        filterConfigObj.filterList = serialized.filterList;
        if (filterConfigObj.filterList && filterConfigObj.filterList.length) {
          filterConfigObj.filterList[0].forceWatchToFire = Math.random();
        }
      },

      conditionalFormatsMapChanged: function(conditionalFormatsMap) {
        var serialized = ConditionalFormat.conditionalFormatMapToJson(conditionalFormatsMap);
        var tableConfigObj = DataModel.getTableConfigObj();
        mergeTableConfigFieldsTo(serialized, tableConfigObj.formatting);

        ConditionalFormatting.updateFormattingConditions(
          DataModel.table.fields(),
          DataModel.tableConfig.formattingOptions()
        );
      },

      fieldFormatMapChanged: function(fieldFormatMap) {
        var serialized = FieldFormatAdapter.serializeFieldFormatMap(fieldFormatMap);
        var tableConfigObj = DataModel.getTableConfigObj();
        mergeTableConfigFieldsTo(serialized, tableConfigObj.formatting);

        ConditionalFormatting.updateFormattingConditions(
          DataModel.table.fields(),
          DataModel.tableConfig.formattingOptions()
        );
      },

      tableChanged: function(table, appController) {
        var tableObj = DataModel.getTableObj();
        if (tableObj.metaData === undefined) tableObj.metaData = {};
        if (tableObj.metaData.name === undefined) tableObj.metaData.name = table.name();
        if (tableObj.metaData.recordCount === undefined) tableObj.metaData.recordCount = table.recordCount();

        filterHacks.updateHasStaticField();
        filterHacks.updateTableFields(table);
        filterHacks.updateSortFields(table, appController);

        filterHacks.updateFieldCheckedValues();
      },

      updateHasStaticField: () => {
        DataModel.table.hasStaticField(false);
      },

      updateTableFields: function(table) {
        var tableFields = {};
        table.fields().forEach(function(field, i) {
          var fieldName = field.name();
          var fieldObj = DataModel.table.field(fieldName) || {};
          if (fieldObj.fieldName === undefined) fieldObj.fieldName = fieldName;
          if (fieldObj.displayName === undefined) fieldObj.displayName = field.displayName();
          if (fieldObj.type === undefined) fieldObj.type = field.type();
          if (fieldObj.colId === undefined) fieldObj.colId = "col" + (i + 1);
          if (fieldObj.isSortable === undefined) fieldObj.isSortable = true;
          if (fieldObj.isQuickFilterable === undefined) fieldObj.isQuickFilterable = true;
          if (fieldObj.isPrimaryKey === undefined) fieldObj.isPrimaryKey = field.isPrimaryKey();
          if (fieldObj.criteriaFilter === undefined) {
            fieldObj.criteriaFilter = {
              newFilter: {},
              filters: [],
              operators: ConditionalOperators.getOperatorsByType(field.type()),
            };
          }

          tableFields[fieldName] = fieldObj;
        });
        DataModel.table.fields(tableFields);
        ConditionalFormatting.updateFormattingConditions(
          DataModel.table.fields(),
          DataModel.tableConfig.formattingOptions()
        );
      },

      updateSortFields: function(table, appController) {
        var sortFields = [];
        table.fields().forEach(function(field) {
          sortFields.push({
            fieldName: field.name(),
            displayName: field.displayName(),
            dataType: field.type(),
          });
        });
        sortFields = Sorter.sort(sortFields, {
          valueParser: item => item.displayName,
        });
        DataModel.table.sortFields(sortFields);
        appController.table.sortFields = sortFields;
      },

      updateFieldCheckedValues: function() {
        DataFilter.bindQuickFilterCheckedValues(DataModel.getFilterConfigDeprecated());
      },

      toggleFilterPanel: function() {
        eventService.publish("toggleFilterPanel");
      },

      getFilterFieldNames: function() {
        var filterList = DataModel.getFilterConfigDeprecated().filterList;
        if (filterList) {
          return filterList.map(d => d.name);
        }
        return [];
      },

      getSortFieldName: function() {
        return DataModel.getFilterConfigDeprecated().sortField
          ? DataModel.getFilterConfigDeprecated().sortField.field
          : null;
      },

      getSortOrder: function() {
        return DataModel.getFilterConfigDeprecated().sortField
          ? DataModel.getFilterConfigDeprecated().sortField.order
          : null;
      },

      getConditionalFormatsMap: function() {
        return ConditionalFormat.jsonToConditionalFormatMap(DataModel.tableConfig.formattingOptions());
      },
    };

    return filterHacks;

    function mergeTableConfigFieldsTo(newFieldFormattingMap, existingFieldFormattingMap) {
      var existingFieldFormatting;
      var fieldName;
      var formattingKey;
      var newFieldFormatting;

      for (fieldName in newFieldFormattingMap) {
        if (Object.prototype.hasOwnProperty.call(newFieldFormattingMap, fieldName)) {
          newFieldFormatting = newFieldFormattingMap[fieldName];

          existingFieldFormatting = existingFieldFormattingMap[fieldName];
          if (!existingFieldFormatting) {
            existingFieldFormatting = {};
            existingFieldFormattingMap[fieldName] = existingFieldFormatting;
          }

          for (formattingKey in newFieldFormatting) {
            existingFieldFormatting[formattingKey] = newFieldFormatting[formattingKey];
          }
        }
      }
    }
  });
