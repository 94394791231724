angular.module("acl.visualizer.tabState").factory("TabStateService", function($timeout, DataModel, EventService) {
  let instances = [];
  const eventService = EventService.register("tabState.TabStateService");

  return {
    /**
     * Instantiates a new tab state tracker. Each controller associated with a tab should
     * call `TabStateService.instance($scope)` during initialization.
     */
    instance: function(getTabIndex, reloadCallback, redrawCallback) {
      let isReloadPending = true;
      let isRedrawPending = true;
      let isActive = getTabIndex() === DataModel.interpretation.currentTabIndex();
      let triggeredFromEvent = null;

      /**
       * Reload when the tab is active.
       * @param {string} triggeredFrom The flag to ignore api call in case its not required(eg: for conditional formatting )
       */
      function scheduleReload(force, triggeredFrom) {
        isReloadPending = true;

        if (isActive || force === true) {
          if (triggeredFrom) {
            triggeredFromEvent = triggeredFrom;
          }
          doReload();
        }
      }

      function doReload() {
        reloadCallback && reloadCallback(triggeredFromEvent);
        isReloadPending = false;
        isRedrawPending = true;
        triggeredFromEvent = null;
      }

      /**
       * Redraw when the tab is active.
       */
      const scheduleRedraw = function(force) {
        isRedrawPending = true;

        if (isActive || force === true) {
          doRedraw();
        }
      };

      function doRedraw() {
        redrawCallback && redrawCallback();
        isRedrawPending = false;
      }

      function setSelectedTab(tabIndex) {
        isActive = tabIndex === getTabIndex();
        if (isActive) {
          if (isReloadPending) {
            triggeredFromEvent = "selectedTab";
            doReload();
          } else if (isRedrawPending) {
            doRedraw();
          }
        }
      }

      $timeout(function() {
        scheduleReload();
      });

      const instance = {
        tabIndex: getTabIndex(),

        isSelected: function() {
          return DataModel.interpretation.currentTabIndex() === getTabIndex();
        },

        scheduleReload: scheduleReload,
        scheduleRedraw: scheduleRedraw,
        setSelectedTab: setSelectedTab,
      };

      instances.push(instance);

      return {
        scheduleReload: scheduleReload,
        reloaded: function(isRedraw = true) {
          eventService.publish("tabState.reloaded", getTabIndex());
          isRedraw && scheduleRedraw();
        },
        scheduleRedraw: scheduleRedraw,
        redrew: function() {
          eventService.publish("tabState.redrew", getTabIndex());
        },
      };
    },

    setSelectedTab: function(tabIndex) {
      instances.forEach(instance => instance.setSelectedTab(tabIndex));
    },

    reload: function(tabIndex, force = false, triggeredFrom = null) {
      if (instances[tabIndex]) {
        instances[tabIndex].scheduleReload(force, triggeredFrom);
      }
    },

    reloadAll: function(force = false, triggeredFrom) {
      instances.forEach(function(instance) {
        instance.scheduleReload(force, triggeredFrom);
      });
    },

    reloadAllExcept: function(exceptTabIndices, force = false) {
      instances.forEach(function(instance, tabIndex) {
        if (!exceptTabIndices.includes(tabIndex)) {
          instance.scheduleReload(force);
        }
      });
    },

    redrawAll: function(force = false) {
      instances.forEach(function(instance) {
        instance.scheduleRedraw(force);
      });
    },
  };
});
