import cloneDeep from "lodash/cloneDeep";

export default class InterpretationTableDataStore {
  static _tables = new Map();
  static _interpretationRecordsets = new Map();

  static addTable(boardId, table) {
    this._tables.set(boardId, table);
  }

  static getTable(tableId) {
    return this._tables.get(tableId);
  }

  static setRecordset(interpretationId, recordset) {
    this._interpretationRecordsets.set(interpretationId, cloneDeep(recordset));
  }

  static addRecordset(interpretationId, recordset) {
    var currentRecordset = this._interpretationRecordsets.get(interpretationId);
    if (currentRecordset) {
      currentRecordset.concat(recordset);
      this._interpretationRecordsets.set(interpretationId, cloneDeep(currentRecordset));
    } else {
      this._interpretationRecordsets.set(interpretationId, cloneDeep(recordset));
    }
  }

  static getNextRecordIndex(interpretationId) {
    var recordset = this._interpretationRecordsets.get(interpretationId);
    if (recordset === undefined) {
      return 1;
    } else if (recordset.lastRecordIndex() >= recordset.recordCount()) {
      return undefined;
    } else {
      return recordset.lastRecordIndex() + 1;
    }
  }

  static getRecordset(interpretationId) {
    return this._interpretationRecordsets.get(interpretationId);
  }

  static resetData() {
    this._interpretationRecordsets = new Map();
  }
}
