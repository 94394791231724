import checkboxState from "@viz-ui/models/checkboxState";

export default () => {
  var checkboxFilterStateByFieldName = new Map();

  class CheckboxFilterStore {
    clearChanges(fieldName) {
      var checkboxFilterState = getOrCreateCheckboxFilterState(fieldName);
      checkboxFilterState.changedValues.clear();
      return this;
    }

    clearValues(fieldName) {
      var checkboxFilterState = getOrCreateCheckboxFilterState(fieldName);
      var values = checkboxFilterState.checkedValues;
      values.forEach(value => {
        toggleChangedValue(checkboxFilterState, value);
      });
      checkboxFilterState.checkedValues = new Set();
      checkboxFilterState.unCheckedValues = new Set();
      checkboxFilterState.selectAllState = checkboxState.UNCHECKED;
      checkedValuesChanged(checkboxFilterState);
      return this;
    }

    getCheckedValues(fieldName) {
      var checkboxFilterState = checkboxFilterStateByFieldName.get(fieldName);
      if (checkboxFilterState) {
        return Array.from(checkboxFilterState.checkedValues);
      }
      return null;
    }

    getCheckedValuesForWatch(fieldName) {
      var checkboxFilterState = checkboxFilterStateByFieldName.get(fieldName);
      if (checkboxFilterState) {
        return checkboxFilterState.checkedValuesForWatch;
      }
      return null;
    }

    getUnCheckedValues(fieldName) {
      var checkboxFilterState = checkboxFilterStateByFieldName.get(fieldName);
      if (checkboxFilterState) {
        return Array.from(checkboxFilterState.unCheckedValues);
      }
      return null;
    }

    hasChanges(fieldName) {
      var checkboxFilterState = checkboxFilterStateByFieldName.get(fieldName);
      return !!checkboxFilterState && checkboxFilterState.changedValues.size > 0;
    }

    bindCheckedValuesOnLoad(fieldName, values, unCheckedValues, selectAllState) {
      var checkboxFilterState = getOrCreateCheckboxFilterState(fieldName);
      checkboxFilterState.checkedValues = new Set(values);
      checkboxFilterState.checkedValuesPostApply = [...(values || [])];
      checkboxFilterState.unCheckedValues = new Set(unCheckedValues);
      checkboxFilterState.unCheckedValuesPostApply = [...(unCheckedValues || [])];
      checkboxFilterState.selectAllState = selectAllState;
      checkboxFilterState.selectAllStatePostApply = selectAllState;
      checkboxFilterState.changedValues = new Set();
      checkedValuesChanged(checkboxFilterState);
      return this;
    }

    revert(fieldName) {
      var checkboxFilterState = checkboxFilterStateByFieldName.get(fieldName);
      if (checkboxFilterState) {
        checkboxFilterState.checkedValues =
          checkboxFilterState.selectAllStatePostApply == checkboxState.CHECKED ||
          (checkboxFilterState.unCheckedValuesPostApply && checkboxFilterState.unCheckedValuesPostApply.length > 0)
            ? new Set()
            : new Set(checkboxFilterState.checkedValuesPostApply);

        checkboxFilterState.unCheckedValues = new Set(checkboxFilterState.unCheckedValuesPostApply);
        checkboxFilterState.selectAllState = checkboxFilterState.selectAllStatePostApply;
        checkboxFilterState.changedValues = new Set();
        checkedValuesChanged(checkboxFilterState);
      }
      return this;
    }

    revertAll() {
      var that = this;
      checkboxFilterStateByFieldName.forEach((state, fieldName) => {
        that.revert(fieldName);
      });
      return this;
    }

    toggleValue(fieldName, value, totalLoadedValuesLength, filterApplied) {
      var checkboxFilterState = getOrCreateCheckboxFilterState(fieldName);

      if (
        checkboxFilterState.selectAllState == checkboxState.CHECKED ||
        (checkboxFilterState.unCheckedValues && checkboxFilterState.unCheckedValues.size > 0)
      ) {
        toggleUnCheckedValue(checkboxFilterState, value);
      }

      toggleCheckedValue(checkboxFilterState, value);
      toggleChangedValue(checkboxFilterState, value);
      checkboxFilterState.selectAllState = this.getSelectAllStateProp(
        checkboxFilterState.checkedValues.size,
        totalLoadedValuesLength
      );
      checkedValuesChanged(checkboxFilterState);
      return this;
    }

    handleSelectAllClick(
      fieldName,
      action,
      visibleValues,
      totalLoadedValuesLength,
      filterApplied,
      selectAllStatePriorSearch
    ) {
      let checkboxFilterState = getOrCreateCheckboxFilterState(fieldName);
      let preViousCheckedValues = Array.from(checkboxFilterState.checkedValues);

      if (!filterApplied) {
        checkboxFilterState.unCheckedValues = new Set(); // Resetting unChecked values
        if (action == "add") {
          checkboxFilterState.checkedValues = new Set(visibleValues);
        }

        if (action == "remove") {
          checkboxFilterState.checkedValues = new Set();
        }
      } else {
        const checkedValuesPostSearch = getCheckedValuesPostSearch(visibleValues, checkboxFilterState.checkedValues);
        const unCheckedValuesPostSearch = getUncheckedValuesPostSearch(
          visibleValues,
          checkboxFilterState.checkedValues
        );
        const additionalCheckedValuesPostSearch = getAdditionalCheckedValuesPostSearch(
          checkedValuesPostSearch,
          checkboxFilterState.checkedValues
        );

        if (action == "add") {
          checkboxFilterState.checkedValues = new Set([
            ...unCheckedValuesPostSearch,
            ...checkedValuesPostSearch,
            ...additionalCheckedValuesPostSearch,
          ]);

          if (checkboxFilterState.unCheckedValues && checkboxFilterState.unCheckedValues.size > 0) {
            checkboxFilterState.unCheckedValues = new Set(
              [...checkboxFilterState.unCheckedValues].filter(
                x => unCheckedValuesPostSearch.findIndex(y => y === x) == -1
              )
            );
          }
        }

        if (action == "remove") {
          checkboxFilterState.checkedValues = new Set([...additionalCheckedValuesPostSearch]);

          if (
            selectAllStatePriorSearch == checkboxState.CHECKED ||
            (checkboxFilterState.unCheckedValues && checkboxFilterState.unCheckedValues.size > 0)
          ) {
            checkboxFilterState.unCheckedValues = new Set([
              ...checkedValuesPostSearch,
              ...checkboxFilterState.unCheckedValues,
            ]);
          }
        }
      }

      checkboxFilterState.selectAllState = this.getSelectAllStateProp(
        checkboxFilterState.checkedValues.size,
        totalLoadedValuesLength
      );

      toggleChangedValuesOnSelectAllClick(checkboxFilterState, visibleValues, preViousCheckedValues, action);
      checkedValuesChanged(checkboxFilterState);
    }

    // It needs to be invoked from checkbox filter component when select all state in checked or any unCheckedValues available.
    // Note - Don't invoke the checkedValuesChanged on this method as it's been invoking post setting the selected items watchers in component.
    resetCheckedValues(fieldName, values, unCheckedValues) {
      var checkboxFilterState = getOrCreateCheckboxFilterState(fieldName);
      if (checkboxFilterState) {
        checkboxFilterState.checkedValues = new Set(values);
        checkboxFilterState.unCheckedValues = new Set(unCheckedValues);
      }
      return this;
    }

    getSelectAllState(fieldName) {
      var checkboxFilterState = checkboxFilterStateByFieldName.get(fieldName);
      if (checkboxFilterState) {
        return checkboxFilterState.selectAllState;
      }
      return null;
    }

    resetSelectionsPostApplyFilter(fieldName) {
      var checkboxFilterState = checkboxFilterStateByFieldName.get(fieldName);
      if (checkboxFilterState) {
        checkboxFilterState.checkedValuesPostApply = [...checkboxFilterState.checkedValues];
        checkboxFilterState.unCheckedValuesPostApply = [...checkboxFilterState.unCheckedValues];
        checkboxFilterState.selectAllStatePostApply = checkboxFilterState.selectAllState;
      }
    }

    getSelectAllStateProp(selectedValuesLength, visibleValuesLength) {
      let selectAllStateProp = checkboxState.UNCHECKED;

      if (selectedValuesLength == 0) {
        selectAllStateProp = checkboxState.UNCHECKED;
      } else if (selectedValuesLength == visibleValuesLength) {
        selectAllStateProp = checkboxState.CHECKED;
      } else {
        selectAllStateProp = checkboxState.INDETERMINATE;
      }

      return selectAllStateProp;
    }
  }

  function toggleCheckedValue(checkboxFilterState, value) {
    if (checkboxFilterState.checkedValues.has(value)) {
      checkboxFilterState.checkedValues.delete(value);
    } else {
      checkboxFilterState.checkedValues.add(value);
    }
  }

  function toggleUnCheckedValue(checkboxFilterState, value) {
    if (checkboxFilterState.unCheckedValues.has(value)) {
      checkboxFilterState.unCheckedValues.delete(value);
    } else {
      checkboxFilterState.unCheckedValues.add(value);
    }
  }

  function toggleChangedValue(checkboxFilterState, value) {
    if (checkboxFilterState.changedValues.has(value)) {
      checkboxFilterState.changedValues.delete(value);
    } else {
      checkboxFilterState.changedValues.add(value);
    }
  }

  function toggleChangedValuesOnSelectAllClick(checkboxFilterState, visibleValues, previousCheckedValues, action) {
    if (action == "add") {
      visibleValues
        .filter(x => previousCheckedValues.findIndex(y => y == x) == -1) // Excluding the previous checked values from visible values
        .forEach(visibleValue => {
          toggleChangedValue(checkboxFilterState, visibleValue);
        });
    }

    if (action == "remove") {
      visibleValues.forEach(visibleValue => {
        toggleChangedValue(checkboxFilterState, visibleValue);
      });
    }
  }

  function checkedValuesChanged(checkboxFilterState) {
    checkboxFilterState.checkedValuesForWatch = Array.from(checkboxFilterState.checkedValues);
    Object.freeze(checkboxFilterState.checkedValuesForWatch);
  }

  function getOrCreateCheckboxFilterState(fieldName) {
    var checkboxFilterState = checkboxFilterStateByFieldName.get(fieldName);
    if (!checkboxFilterState) {
      checkboxFilterState = {
        checkedValues: new Set(),
        checkedValuesForWatch: [],
        changedValues: new Set(),
        unCheckedValues: new Set(),
        selectAllState: checkboxState.UNCHECKED,
        selectAllStatePostApply: checkboxState.UNCHECKED,
        unCheckedValuesPostApply: [],
        checkedValuesPostApply: [],
      };
      checkboxFilterStateByFieldName.set(fieldName, checkboxFilterState);
    }
    return checkboxFilterState;
  }

  function getCheckedValuesPostSearch(visibleValues, checkedValues) {
    return visibleValues.filter(x => checkedValues.has(x));
  }

  function getUncheckedValuesPostSearch(visibleValues, checkedValues) {
    return visibleValues.filter(x => !checkedValues.has(x));
  }

  function getAdditionalCheckedValuesPostSearch(checkedValuesPostSearch, checkedValues) {
    if (checkedValues && checkedValuesPostSearch) {
      //Previous selected items which are not available post search.
      return [...checkedValues].filter(x => checkedValuesPostSearch.findIndex(y => y == x) == -1);
    }
  }

  return new CheckboxFilterStore();
};
