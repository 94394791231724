import chartService from "@viz-ui/services/charts/chartService";
import cloneDeep from "lodash/cloneDeep";

class HeatmapService extends chartService {
  valueFormatter = (interpretationId, chartConfig) => {
    const { vizId, dataConfig, displayConfig } = chartConfig;
    if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
      const chartValueFieldName = `${vizId}-chart-value`;
      const chartValueFieldType = "numeric";
      return this.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
    }
    return this.getValueFormatter(interpretationId, dataConfig.chartValue.fieldName, dataConfig.chartValue.type);
  };

  defaultDisplayConfig = () => {
    const heatMapColors5 = new this.ColorPalette().heatMapColors().reverse();
    return {
      showLegend: true,
      displayDataLabels: true,
      binMode: false,
      reverseColors: false,
      xAxis: {
        showLabel: true,
      },
      yAxis: {
        showLabel: true,
      },
      colorAxis: {
        colorStops: [{ color: heatMapColors5[0] }, { color: heatMapColors5[2] }, { color: heatMapColors5[4] }],
        numBins: 5,
      },
    };
  };

  getChartDirectiveConfig = (interpretationId, chartConfig) => ({
    valueLabel: this.getFieldLabel(chartConfig.dataConfig.chartValue),
    hasMultipleSeries: !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName),
    showLegend: chartConfig.displayConfig.showLegend,
    displayDataLabels: chartConfig.displayConfig.displayDataLabels,
    xAxis: {
      displayName: chartConfig.dataConfig.chartXAxis.displayName,
      label: chartConfig.displayConfig.xAxis.showLabel
        ? chartConfig.displayConfig.xAxis.title || chartConfig.dataConfig.chartXAxis.displayName
        : "",
      valueFormatter: this.getValueFormatter(
        interpretationId,
        chartConfig.dataConfig.chartXAxis.fieldName,
        chartConfig.dataConfig.chartXAxis.type
      ),
      title: chartConfig.displayConfig.xAxis.title,
      axisType: chartConfig.dataConfig.chartXAxis.type,
    },
    yAxis: {
      displayName: chartConfig.dataConfig.chartYAxis.displayName,
      label: chartConfig.displayConfig.yAxis.showLabel
        ? chartConfig.displayConfig.yAxis.title || chartConfig.dataConfig.chartYAxis.displayName
        : "",
      valueFormatter: this.getValueFormatter(
        interpretationId,
        chartConfig.dataConfig.chartYAxis.fieldName,
        chartConfig.dataConfig.chartYAxis.type
      ),
      title: chartConfig.displayConfig.yAxis.title,
      axisType: chartConfig.dataConfig.chartYAxis.type,
    },
    colorAxis: {
      colorStops: chartConfig.displayConfig.colorAxis
        ? chartConfig.displayConfig.colorAxis.colorStops
        : this.defaultDisplayConfig().colorAxis.colorStops,
      minimum: chartConfig.displayConfig.colorAxis && chartConfig.displayConfig.colorAxis.minimum,
      maximum: chartConfig.displayConfig.colorAxis && chartConfig.displayConfig.colorAxis.maximum,
      numBins: chartConfig.displayConfig.colorAxis && chartConfig.displayConfig.colorAxis.numBins,
      valueFormatter: this.valueFormatter(interpretationId, chartConfig),
    },
    DATA_ITEM_LIMIT: this.getDataItemLimit(),
    binMode: chartConfig.displayConfig.binMode || false,
  });

  populateChartConfigColumnDefs = chartConfigColumnDefs => {
    const fields = this.DataModel.table.fields();
    if (fields) {
      let tempColumnDef = {};
      for (const fieldName in fields) {
        tempColumnDef = {};
        tempColumnDef.displayName = fields[fieldName].displayName;
        tempColumnDef.fieldId = fields[fieldName].colId;
        tempColumnDef.fieldName = fieldName;
        tempColumnDef.type = fields[fieldName].type;

        switch (tempColumnDef.type) {
          case "character":
          case "logical":
            if (this.AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
              chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            }
            break;

          case "numeric":
            chartConfigColumnDefs.chartValue.push(tempColumnDef);
            chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            break;

          case "date":
          case "datetime":
          case "time":
            chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            break;
        }
      }
      chartConfigColumnDefs.chartYAxis = cloneDeep(chartConfigColumnDefs.chartXAxis);
    }
    return chartConfigColumnDefs;
  };

  chartConfigColumnDefs = () => ({
    chartValue: [],
    chartXAxis: [],
    chartYAxis: [],
  });

  isValidDataConfig = dataConfig =>
    !!(
      dataConfig &&
      dataConfig.chartXAxis &&
      dataConfig.chartYAxis &&
      dataConfig.chartValue &&
      ((dataConfig.chartValue.aggregationType && dataConfig.chartValue.fieldName) ||
        dataConfig.chartValue.aggregationType === "count")
    );

  getFilters = (data, chartConfig) => {
    const chartXAxisFormatter = this.getFormatterForType(chartConfig.dataConfig.chartXAxis.type);
    const chartYAxisFormatter = this.getFormatterForType(chartConfig.dataConfig.chartYAxis.type);
    return [
      {
        field: chartConfig.dataConfig.chartXAxis,
        value: chartXAxisFormatter(data.xAxisName),
      },
      {
        field: chartConfig.dataConfig.chartYAxis,
        value: chartYAxisFormatter(data.yAxisName),
      },
    ];
  };
}

export default HeatmapService;
