function getErrorCodeForInterpretation(interpretation) {
  return getErrorCode(interpretation);
}

function getErrorCodeForVisualization(visualization) {
  return getErrorCode(visualization);
}

function getErrorCode(entityObject) {
  return entityObject && entityObject.error ? entityObject.error.code : null;
}

const INTERPRETATION_DELETED = "INTERPRETATION_DELETED";
const INTERPRETATION_ERROR = "INTERPRETATION_ERROR";
const METRIC_FIELD_DELETED = "METRIC_FIELD_DELETED";
const VISUALIZATION_DELETED = "VISUALIZATION_DELETED";
const VISUALIZATION_INVALID = "VISUALIZATION_INVALID";
const VISUALIZATION_NO_DATA = "VISUALIZATION_NO_DATA";

const ERROR_CODES = {
  INTERPRETATION_DELETED,
  INTERPRETATION_ERROR,
  METRIC_FIELD_DELETED,
  VISUALIZATION_DELETED,
  VISUALIZATION_INVALID,
  VISUALIZATION_NO_DATA,
};

export { ERROR_CODES, getErrorCodeForInterpretation, getErrorCodeForVisualization };
