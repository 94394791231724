import React from "react";
import i18n from "@viz-ui/i18n/i18n";
import LeftNavPanel from "@viz-ui/components/LeftNavPanel/LeftNavPanel";
import backendApi from "@results/services/apiCall/backendApi";
import Storage from "./utils/SessionStorageHelper";
import Services from "./services/Services";

const DEFAULT_PANEL_WIDTH = 350;
const defaultItems = [
  {
    id: 1,
    name: i18n.t("_LeftNavigation.storyboards.plural_"),
    type: "storyboards",
    path: `${storyboardsUrl}/storyboards`,
    loading: true,
    childItems: [],
  },
  {
    id: 1,
    name: i18n.t("_LeftNavigation.projects.title_"),
    type: "projects",
    path: `${resultsUrl}/`,
    loading: true,
    childItems: [],
  },
];
const resultsUrl = backendApi.getLoganUrl();
const storyboardsUrl = backendApi.getStoryboardsUrl();

const items = Storage.get("items") || defaultItems;
const expanded = Storage.get("expanded") || {};
const width = Storage.get("width") || 0; // collapsed
const backupWidth = Storage.get("backupWidth") || DEFAULT_PANEL_WIDTH;
const scrollTarget = Storage.get("scrollTarget") || null;
const canViewSettings = Storage.get("canViewSettings");

const LeftNavigation = props => {
  const refinedProps = {
    ...props,
    items,
    expanded,
    width,
    backupWidth,
    scrollTarget,
    canViewSettings,
    locale: i18n.locale,
    resultsUrl,
    storyboardsUrl,
    getNavItems: async () => Services.GetNavigationItems(),
    setProperty: (key, value) => {
      Storage.set(key, value);
    },
  };
  return <LeftNavPanel {...refinedProps} />;
};

export default LeftNavigation;

window.LeftNavigation = LeftNavigation;
