import AggregatorArgsValidator from "@viz-ui/services/metrics/aggregatorArgsValidatorService";

class MetricConfigValidator {
  isValid(metricConfig) {
    return !!(
      metricConfig.func() &&
      metricConfig.interval() &&
      metricConfig.fieldName() &&
      metricConfig.timeFieldName() &&
      metricConfig.type() &&
      (metricConfig.func() !== "percent-of" ||
        (metricConfig.aggregatorArgs() && AggregatorArgsValidator.isValid(metricConfig.aggregatorArgs())))
    );
  }
}

export default new MetricConfigValidator();
