/*
    Copyright ©2014 ACL Services Ltd.
    Use of this code is governed by the ACL Software License Agreement that applies to the Software of which this code is a part:
    http://www.acl.com/pdfs/ACL_License_Agreement.pdf
*/

angular
  .module("acl.visualizer.saveViz")
  .controller("SaveVizModalController", function($scope, $modalInstance, $modalStack, DataModel, SaveViz) {
    "use strict";
    var savedViz = DataModel.toSaveViz();
    $scope.linkMostRecentTable = SaveViz.canLinkMostRecentTableOnSave();

    $scope.vizToSave = {
      title: savedViz.visualizationInfo.title,
      summary: savedViz.visualizationInfo.summary,
      linkLatestTable: savedViz.visualizationInfo.linkLatestTable,
    };

    $scope.forms = {};

    $scope.$watch("forms.saveVizForm.$dirty", function(dirty) {
      if (dirty) {
        $modalStack.getTop().value.backdrop = "static";
      }
    });

    $scope.saveViz = function() {
      $modalInstance.close($scope.vizToSave);
    };

    $scope.close = function() {
      $modalInstance.dismiss();
    };

    $scope.toggleLinkLatest = function() {
      $scope.vizToSave.linkLatestTable = !$scope.vizToSave.linkLatestTable;
    };
  })
  /** Save Viz change source table controller **/
  .controller("ChooseSourceTableController", function(
    $scope,
    $modalInstance,
    Localize,
    SaveViz,
    table,
    analytic,
    introMessage
  ) {
    "use strict";
    $scope.introMessage = introMessage;

    $scope.compatibleTables = {
      columns: [
        { displayName: Localize.getLocalizedString("_SaveViz.CompatibleTable.Creator.Label_"), name: "createdBy" },
        {
          displayName: Localize.getLocalizedString("_SaveViz.CompatibleTable.CreatedOn.Label_"),
          name: "createdOn",
          type: "datetime",
        },
        {
          displayName: Localize.getLocalizedString("_SaveViz.CompatibleTable.RecordCount.Label_"),
          name: "records",
          type: "number",
        },
        {
          displayName: Localize.getLocalizedString("_SaveViz.CompatibleTable.Size.Label_"),
          name: "size",
          type: "size",
        },
      ],
      options: {
        noDataMessage: Localize.getLocalizedString("_SaveViz.CompatibleTable.Table.NoData.Message_"),
        allowSort: false,
        cssClass: {
          table: "compatible-table-list",
          actionLinkBody: "select-source",
        },
      },
    };

    $scope.table = table;

    SaveViz.compatibleTables(table.name, analytic.id).then(
      function(response) {
        var iTable, compatibleTables;
        var rawData = response.data;
        $scope.analytic = rawData.analytic;
        compatibleTables = rawData.jobResults;
        for (iTable = 0; iTable < compatibleTables.length; iTable++) {
          if (table.id === compatibleTables[iTable].id) {
            // do not have select link if this is currently linked table
            // and apply cssClass to highlight row.
            compatibleTables[iTable].rowCssClass = "current-source-table";
          } else {
            var selectLink = {
              onClick: $scope.selectSourceTable,
              onClickParam: compatibleTables[iTable],
              label: Localize.getLocalizedString("_SaveViz.CompatibleTable.Select.Label_"),
            };
            compatibleTables[iTable].actionLinks = [selectLink];
          }
        }
        $scope.compatibleTables.data = compatibleTables;
      },
      function() {
        $scope.compatibleTables.loadFailed = true;
      }
    );

    $scope.close = function() {
      $modalInstance.dismiss();
    };

    $scope.selectSourceTable = function(newTable) {
      $modalInstance.close(newTable);
    };
  })
  .controller("InvalidVizController", function($scope, $modalInstance, Localize) {
    $scope.close = function() {
      $modalInstance.dismiss();
    };
  });
