import FieldValueDataSource from "./fieldValueDataSource.service";

class FieldValueStore {
  constructor() {
    this._dataSourceMap = new Map();
  }

  getDataSource(requester, requestData, forceRerender) {
    let cacheKey = requester.getCacheKey(requestData);
    // Removing old matching value when force rerender is true
    if (forceRerender) {
      this._dataSourceMap.delete(cacheKey);
    }
    let ds = this._dataSourceMap.get(cacheKey);
    if (typeof ds === "undefined") {
      ds = new FieldValueDataSource(requester, requestData);
      this._dataSourceMap.set(cacheKey, ds);
      window.dataSourceMap = this._dataSourceMap;
    }
    return ds;
  }

  reloadAll() {
    this._dataSourceMap.forEach(ds => {
      ds.reload();
    });
  }
}

export default new FieldValueStore();
