import Model from "../model";

class MetricSparkline extends Model {
  static modelType = "MetricSparkline";

  values(...args) {
    return this.accessor("values", args, [Object]);
  }

  start(...args) {
    return this.accessor("start", args, "string");
  }

  end(...args) {
    return this.accessor("end", args, "string");
  }

  valueType(...args) {
    return this.accessor("valueType", args, "string");
  }

  static fromJson(json) {
    return new MetricSparkline(json)
      .values(json.values)
      .valueType(json.valueType)
      .start(json.range.start)
      .end(json.range.end);
  }

  toJson() {
    return {
      values: this.values(),
      valueType: this.valueType(),
      range: {
        start: this.start(),
        end: this.end(),
      },
    };
  }

  clone() {
    return new MetricSparkline(this._data);
  }
}

export default MetricSparkline;
