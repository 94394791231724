angular.module("acl.common.postAndDownload", []).run(function($window, $document, $timeout, EventService) {
  let errors = {
    timeout: "error.request.timeout",
    unknown: "unknown",
  };

  function findKey(object, value) {
    let result;
    angular.forEach(object, function(v, key) {
      if (v === value && !angular.isDefined(result)) {
        result = key;
      }
    });
    return result;
  }

  let eventService = EventService.register("acl.common.postAndDownload");

  eventService.subscribe("postAndDownload", function(event, deferred, url, data) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.responseType = "arraybuffer";
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("X-Requested-By", "visualizer");
    if ($window._token) {
      xhr.setRequestHeader("X-CSRF-TOKEN", $window._token);
    }
    xhr.send($.param(data));
    xhr.onload = function() {
      if (this.status === 200) {
        let filename = "";
        let disposition = xhr.getResponseHeader("Content-Disposition");
        if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches !== null && matches[1]) filename = matches[1].replace(/['"]/g, "");
        }
        let contentType = xhr.getResponseHeader("Content-Type");
        let blob = new Blob([this.response], { type: contentType });
        if (typeof $window.navigator.msSaveBlob !== "undefined") {
          $window.navigator.msSaveBlob(blob, filename);
        } else {
          let URL = $window.URL || $window.webkitURL;
          let downloadUrl = URL.createObjectURL(blob);

          let $a = angular.element("<a>");
          angular.element("body").append($a);
          $a.attr("href", downloadUrl);
          $a.attr("download", filename);
          $a.get(0).click();

          $timeout(function() {
            $window.URL.revokeObjectURL(downloadUrl);
            $a.remove();
          }, 100);
        }
        deferred.resolve();
      } else {
        let errorString = String.fromCharCode.apply(null, new Uint8Array(this.response));
        let errorObject = JSON.parse(errorString);
        let errorCode = findKey(errors, errorObject.error);
        deferred.reject(errorCode || errors.unknown);
      }
    };

    return deferred.promise;
  });
});
