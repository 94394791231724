import moment from "moment";
import Model from "../model";

class TimezoneOffset extends Model {
  static modelType = "TimezoneOffset";

  constructor(data = {}) {
    super();

    this.setData({
      ...data,
      utcOffsetSeconds: data.utcOffsetSeconds || 0,
    });
  }

  utcOffsetSeconds(...args) {
    return this.accessor("utcOffsetSeconds", args, "number");
  }

  utcOffsetMinutes() {
    return this.utcOffsetSeconds() ? this.utcOffsetSeconds() / 60 : 0;
  }

  utcOffsetHours() {
    return this.utcOffsetSeconds() ? this.utcOffsetSeconds() / 60 / 60 : 0;
  }

  utcOffsetString() {
    const timezoneOffsetMinutes = this.utcOffsetSeconds() / 60;
    return moment()
      .startOf("day")
      .utcOffset(timezoneOffsetMinutes)
      .format("Z")
      .replace("+", "");
  }
}

export default TimezoneOffset;
