import moment from "moment";
import i18n from "@viz-ui/i18n/i18n";
import SectionService from "./sectionService";
import { DatetimeFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";

export default function(fieldFormatObject, unformattedValues) {
  const formattingObject = fieldFormatObject || {};

  return [
    {
      heading: i18n.t("_Format.Section.Label_"),
      items: getDatetimeFormattingOptions(formattingObject, unformattedValues),
    },
    {
      heading: i18n.t("_Format.Datetime.TimeSection.Label_"),
      items: getTimeDisplayOptions(formattingObject),
    },
  ];
}

function getDatetimeFormattingOptions(selectedFormattingObject, unformattedValues) {
  var selectedDisplayTime =
    selectedFormattingObject.displayTime === undefined ? true : selectedFormattingObject.displayTime;
  var timeFormat = SectionService.getLocalizedTimeFormat();

  const formats = [
    { isRaw: false, datetimeFormat: undefined }, // Default format.
    { isRaw: false, datetimeFormat: "YYYY-MM-DD " + timeFormat },
    { isRaw: false, datetimeFormat: "MM-DD-YYYY " + timeFormat },
    { isRaw: false, datetimeFormat: "DD-MMM-YYYY " + timeFormat },
    { isRaw: false, datetimeFormat: "YYYY/MM/DD " + timeFormat },
    { isRaw: false, datetimeFormat: "MM/DD/YYYY " + timeFormat },
    { isRaw: false, datetimeFormat: "DD/MMM/YYYY " + timeFormat },
  ];

  if (unformattedValues) {
    formats.unshift({ isRaw: true, datetimeFormat: undefined });
  }

  return formats.map(function(fieldFormatJson) {
    var mergedFieldFormatJson = { ...fieldFormatJson, displayTime: selectedDisplayTime };
    var exampleDate = moment(new Date(new Date().getFullYear(), 0, 31, 22, 14, 42)).format("MM/DD/YYYY HH:mm:ss");
    var example = mergedFieldFormatJson.isRaw
      ? i18n.t("_Format.Datetime.Raw.Example_")
      : DatetimeFormatter.format(exampleDate, mergedFieldFormatJson);

    return {
      example: example,
      format: mergedFieldFormatJson,
      label: i18n.t(getDatetimeFormatLabelKey(mergedFieldFormatJson)),
      selected: SectionService.compareFormattingOptions(mergedFieldFormatJson, selectedFormattingObject),
    };
  });
}

function getDatetimeFormatLabelKey(fieldFormatJson) {
  var template = key => `_Format.Datetime.${key}.Label_`;
  if (fieldFormatJson) {
    if (fieldFormatJson.isRaw) {
      return template("Raw");
    }

    if (fieldFormatJson.datetimeFormat) {
      return template(SectionService.extractDateFormat(fieldFormatJson.datetimeFormat));
    }
  }
  return template("Default");
}

function getTimeDisplayOptions(selectedFormattingObject) {
  var selectedDisplayTime =
    selectedFormattingObject.isRaw ||
    (selectedFormattingObject.displayTime === undefined ? true : selectedFormattingObject.displayTime);
  var selectedDatetimeFormat = selectedFormattingObject.datetimeFormat || "";
  var exampleDate = moment(new Date(new Date().getFullYear(), 0, 31, 22, 14, 42)).format("MM/DD/YYYY HH:mm:ss");

  return [
    {
      example: DatetimeFormatter.format(exampleDate, { dateTimeFormat: selectedDatetimeFormat, displayTime: true }),
      format: {
        isRaw: selectedFormattingObject.isRaw,
        datetimeFormat: selectedDatetimeFormat,
        displayTime: true,
      },
      label: i18n.t("_Format.Datetime.Visible.Label_"),
      selected: selectedDisplayTime,
      isDisabled: selectedFormattingObject.isRaw,
    },
    {
      example: DatetimeFormatter.format(exampleDate, { dateTimeFormat: selectedDatetimeFormat, displayTime: false }),
      format: {
        isRaw: selectedFormattingObject.isRaw,
        datetimeFormat: selectedDatetimeFormat,
        displayTime: false,
      },
      label: i18n.t("_Format.Datetime.Hidden.Label_"),
      selected: !selectedDisplayTime,
      isDisabled: selectedFormattingObject.isRaw,
    },
  ];
}
