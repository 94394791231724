import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import RawButton from "acl-ui/components/RawButton";
import Icon from "acl-ui/components/Icon";
import Sorter from "@viz-ui/services/sorters/sorter";
import i18n from "@viz-ui/i18n/i18n";
import Table from "@viz-ui/models/table/table";
import Field from "@viz-ui/models/field/field";
import UsageTracker from "@viz-ui/services/tracking/usageTracker";

import "./fieldSelectSection.scss";

//TODO: This should be somewhere more global
const iconTypes = {
  numeric: "number-sign",
  time: "clock",
  datetime: "time-and-date",
  date: "calendar",
  character: "character",
  count: "function-symbol",
  logical: "boolean",
  file: "file-text",
  key: "key",
};

export default class FieldSelectSection extends Component {
  static propTypes = {
    className: PropTypes.string,
    initialTableId: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    selectedList: PropTypes.arrayOf(PropTypes.instanceOf(Field)),
    tables: PropTypes.arrayOf(PropTypes.instanceOf(Table)).isRequired,
  };

  static defaultProps = {
    className: "",
    initialTableId: null,
    selectedList: [],
  };

  constructor(props) {
    super(props);
    const isInitTableFound = !!this.findById(props.tables, props.initialTableId).length;
    this.state = {
      selectedTableId: isInitTableFound ? props.initialTableId : null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tables && this.findById(nextProps.tables, this.state.selectedTableId).length !== 1) {
      this.setState({ selectedTableId: null });
    }
  }

  onClickTableItem = table => {
    this.setState({ selectedTableId: table.id() });
  };

  onClickFieldItem = field => {
    if (this.isFieldSelected(field)) return;

    const newList = this.props.selectedList
      ? this.findByNotTableId(this.props.selectedList, this.state.selectedTableId)
      : [];

    newList.push(field);
    this.props.onChange(newList);
    UsageTracker.createEvent(".filterpanel.configpanel.fieldselected");
  };

  findById = (items, searchedId) => items.filter(item => item.id() === searchedId);
  findByName = (items, searchedName) => items.filter(item => item.name() === searchedName);
  findByTableId = (items, searchedTableId) => items.filter(item => item.tableId() === searchedTableId);
  findByNotTableId = (items, searchedTableId) => items.filter(item => item.tableId() !== searchedTableId);

  removeSelectedItem = item => {
    const newList = this.findByNotTableId(this.props.selectedList, item.tableId());
    this.props.onChange(newList);
    UsageTracker.createEvent(".filterpanel.configpanel.fielddeleted");
  };

  dataTypeIcon(type) {
    const iconType = iconTypes[type] || "caret-right";
    return (
      <span className="field-select-item__icon">
        <Icon type={iconType} />
      </span>
    );
  }

  isTableInSelectedList = tableId => {
    if (!this.props.selectedList.length) return false;
    return this.findByTableId(this.props.selectedList, tableId).length === 1;
  };

  isFieldSelected = field => {
    if (!this.props.selectedList.length) return false;
    const selectedTable = this.findByTableId(this.props.selectedList, this.state.selectedTableId);
    return this.findByName(selectedTable, field.name()).length;
  };

  selectedDataType = () => {
    if (!this.props.selectedList.length) return undefined;
    if (this.props.selectedList.length === 1 && this.state.selectedTableId === this.props.selectedList[0].tableId())
      return undefined;
    return this.props.selectedList[0].type();
  };

  renderTableItem = table => {
    const itemClass = classNames({
      "field-select-item--selected": this.state.selectedTableId === table.id(),
      "field-select-item--has-selection": this.isTableInSelectedList(table.id()),
    });

    return (
      <li className={itemClass} key={table.id()}>
        <RawButton
          ariaLabel={table.name()}
          className="field-select-item__button"
          onClick={() => this.onClickTableItem(table)}
        >
          <div className="field-select-item__label">{table.name()}</div>
        </RawButton>
      </li>
    );
  };

  renderFieldItem = field => {
    const selectedType = this.selectedDataType();
    const isDisabled = selectedType && selectedType !== field.type();
    const itemClass = classNames({
      "field-select-item--selected": this.isFieldSelected(field),
      "field-select-item--disabled": isDisabled,
    });

    return (
      <li className={itemClass} key={field.name()}>
        <RawButton
          ariaLabel={field.displayName()}
          className="field-select-item__button"
          disabled={isDisabled}
          onClick={() => this.onClickFieldItem(field)}
        >
          <div className="field-select-item__label">
            {this.dataTypeIcon(field.type())}
            <span>{field.displayName()}</span>
          </div>
        </RawButton>
      </li>
    );
  };

  renderSelectedItem = item => {
    const tableList = this.findById(this.props.tables, item.tableId());
    if (!tableList.length) return;
    const itemTable = tableList[0];
    const itemField = this.findByName(itemTable.fields(), item.name())[0];

    return (
      <li className="holding-item" key={`${item.name()}-${item.tableId()}`}>
        {this.dataTypeIcon(itemField.type())}
        <div className="holding-item__label">
          <div className="holding-item__field-name">{itemField.displayName()}</div>
          <div className="holding-item__table-name">
            {i18n.t("_GlobalFilter.FieldSelect.Table.Label_")} {itemTable.name()}
          </div>
        </div>
        <RawButton
          ariaLabel={i18n.t("_General.Remove.Label_")}
          className="holding-item__remove"
          onClick={() => this.removeSelectedItem(item)}
        >
          <Icon type="trashbin" />
        </RawButton>
      </li>
    );
  };

  renderTableList = () => {
    if (!this.props.tables.length) return;
    return (
      <ul>
        {Sorter.sort(this.props.tables, { valueParser: table => table.name() }).map(table =>
          this.renderTableItem(table)
        )}
      </ul>
    );
  };

  renderFieldList = () => {
    if (!this.props.tables.length || !this.state.selectedTableId) {
      return (
        <div
          className="field-select__no-items"
          dangerouslySetInnerHTML={{ __html: i18n.t("_GlobalFilter.FieldSelect.Fields.Placeholder_") }}
        />
      );
    }
    const selectedTable = this.findById(this.props.tables, this.state.selectedTableId)[0];
    return (
      <ul>
        {Sorter.sort(selectedTable.fields(), { valueParser: field => field.displayName() }).map(field =>
          this.renderFieldItem(field)
        )}
      </ul>
    );
  };

  renderSelectedList = () => {
    if (!this.props.selectedList.length) {
      return (
        <div
          className="field-select__no-items"
          dangerouslySetInnerHTML={{ __html: i18n.t("_GlobalFilter.FieldSelect.SelectedFields.Placeholder_") }}
        />
      );
    }
    return (
      <ul>
        {Sorter.sort(this.props.selectedList, { valueParser: listItem => listItem.displayName() }).map(item =>
          this.renderSelectedItem(item)
        )}
      </ul>
    );
  };

  render() {
    const rootClasses = classNames("field-select", this.props.className);
    return (
      <div className={rootClasses}>
        <div className="field-select__twins">
          <div className="field-select__panel field-select__tables">
            <div className="field-select__heading">{i18n.t("_GlobalFilter.FieldSelect.Tables.Label_")}</div>
            {this.renderTableList()}
          </div>
          <div className="field-select__panel field-select__fields">
            <div className="field-select__heading">{i18n.t("_GlobalFilter.FieldSelect.Fields.Label_")}</div>
            {this.renderFieldList()}
          </div>
        </div>
        <div className="field-select__panel field-select__holding">
          <div className="field-select__heading">{i18n.t("_GlobalFilter.FieldSelect.SelectedFields.Label_")}</div>
          {this.renderSelectedList()}
        </div>
      </div>
    );
  }
}
