import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import uniq from "lodash/uniq";
import isNumber from "lodash/isNumber";
import i18n from "@viz-ui/i18n/i18n";
import { visualizerAndMetricsFlippers } from "@results/flippers";
import { LINE_CHART } from "@visualizer/modules/visualization/highCharts/boost/boostThresholds";
import pubsub from "pubsub-js";

import ReactHighChart from "../highChart/reactHighChart";
import Sorter from "../../../services/sorters/sorter";
import {
  canAnimate,
  updateHcConfig,
  updateLabelFontSize,
  getBoostConfig,
  clickWrapper,
  baseConfig,
  configPath,
} from "../services/highChartService";
import { reorderXAxisDataPoints, reorderValues } from "../services/highChartDataSorter";

class LineChart extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    config: PropTypes.object,
    rawData: PropTypes.array,
    redrawIndex: PropTypes.number,
    zoomInHandler: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    config: {},
    rawData: [],
    redrawIndex: 0,
    zoomInHandler: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      hcConfig: {},
      chartReDraw: false,
    };
  }

  componentDidMount() {
    pubsub.subscribe("chartRedraw", () => {
      this.setState({ chartReDraw: !this.state.chartReDraw });
    });

    this.setState({ hcConfig: this.getConfig(this.props.config, this.props.rawData) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.config !== nextProps.config)
      this.setState({ hcConfig: this.getConfig(nextProps.config, nextProps.rawData) });
  }

  onLegendItemClick = item => {
    const showAll = !this.state.hcConfig.series.some(entry => entry.visible && entry.name !== item.name);
    const series = this.state.hcConfig.series.map(entry =>
      Object.assign({}, entry, {
        visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
      })
    );
    this.setState({
      hcConfig: updateHcConfig(this.state.hcConfig, { series }),
    });
  };

  onLegendItemDoubleClick = item => {
    const series = this.state.hcConfig.series.map(entry =>
      Object.assign({}, entry, { visible: entry.name === item.name })
    );
    this.setState({
      hcConfig: updateHcConfig(this.state.hcConfig, { series }),
    });
  };

  getConfig = (config, data) => {
    let categories;

    if (!visualizerAndMetricsFlippers().sortingForLineCharts) {
      if (config.xAxis.axisType === "character" || config.xAxis.axisType === "logical") {
        const allDataPoints = data.reduce((all, series) => all.concat(series.values), []);
        categories = Sorter.sort(uniq(allDataPoints.map(d => d.key)), {
          dataType: config.xAxis.axisType,
        });
      }
    } else {
      data = reorderValues(data, config);
      categories = reorderXAxisDataPoints(data, config);
    }

    let hcConfig = {
      legend: {
        enabled: config.showLegend && config.hasMultipleSeries,
        align: "right",
        verticalAlign: "top",
        labelFormatter: function() {
          return config.labelFormatter && this.options.name !== "(blank)"
            ? config.labelFormatter(this.options.legendDisplayName)
            : this.options.legendDisplayName;
        },
      },
      boost: getBoostConfig(config),
      plotOptions: {
        series: {
          dataLabels: {
            enabled: config.displayDataLabels,
            formatter: function() {
              return config.yAxis.tickFormatter(this.y);
            },
          },
          marker: {
            enabled: config.displayDataLabels,
          },
          connectNulls: config.interpolate,
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              const header = config.hasMultipleSeries
                ? `
                    <span style="text-decoration: underline grey;">${
                      config.labelFormatter && this.series.options.name !== "(blank)"
                        ? config.labelFormatter(this.series.options.legendDisplayName)
                        : this.series.options.legendDisplayName
                    }</span>
                    <br/>
                  `
                : "";
              const body = `
                  <span>${config.xAxis.tickFormatter(this.xValue)}</span>
                  <br/>
                  <b>${config.yAxis.tickFormatter(this.yValue)}</b>
                `;
              return header + body;
            },
          },
          point: {
            events: {
              click: clickWrapper(null, point => {
                this.zoomIn(config.hasMultipleSeries ? [point.series.name, point.xValue] : [point.xValue]);
              }),
            },
          },
          events: {
            legendItemClick: clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
          },
          turboThreshold: 0,
          animation: canAnimate(),
        },
      },
      xAxis: {
        categories: categories,
        labels: {
          formatter: function() {
            return config.xAxis.tickFormatter(this.value);
          },
        },
        tickmarkPlacement: "on",
        title: {
          text: config.xAxis ? config.xAxis.label : "", // Need to fix this
        },
      },
      yAxis: {
        title: {
          text: config.yAxis ? config.yAxis.label : "", // Need to fix this
        },
        labels: {
          formatter: function() {
            return config.yAxis.tickFormatter(this.value);
          },
        },
        min: isNumber(config.yAxis.minimum) ? config.yAxis.minimum : undefined,
        max: isNumber(config.yAxis.maximum) ? config.yAxis.maximum : undefined,
        startOnTick: !isNumber(config.yAxis.minimum),
        endOnTick: !isNumber(config.yAxis.maximum),
      },
      series: data.map(series => ({
        type: config.smoothEdged ? "spline" : "line",
        name: series.key,
        legendDisplayName: series.key === "(blank)" ? i18n.t("_Filter.BlankValue.Label_") : series.key,
        boostThreshold: LINE_CHART,
        color: series.color,
        visible: series.visible !== false,
        data: series.values.map(value => ({
          x: categories ? categories.indexOf(value.key) : value.key,
          y: value.values,
          xValue: value.key,
          yValue: value.values,
        })),
      })),
    };

    hcConfig = updateLabelFontSize(hcConfig, configPath);
    return Object.assign(hcConfig, baseConfig());
  };

  zoomIn = keyArray => {
    if (isFunction(this.props.zoomInHandler)) {
      this.props.zoomInHandler(keyArray);
    }
  };

  render() {
    return (
      <ReactHighChart
        className={`redraw-index-${this.props.redrawIndex}`}
        config={this.state.hcConfig}
        redrawIndex={this.props.redrawIndex}
        chartReDraw={this.state.chartReDraw}
      />
    );
  }
}
export default LineChart;
