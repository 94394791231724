import Model from "@viz-ui/models/model";
import BoardError from "./boardError";

export default class Board extends Model {
  static modelType = "Board";

  id(...args) {
    return this.accessor("id", args, "string");
  }

  type(...args) {
    return this.accessor("type", args, "string");
  }

  boardError(...args) {
    return this.accessor("boardError", args, BoardError);
  }

  showBoard(...args) {
    return this.accessor("showBoard", args, "boolean");
  }

  tableId(...args) {
    return this.accessor("tableId", args, "number");
  }
}
