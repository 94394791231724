import moment from "moment";
import i18n from "@viz-ui/i18n/i18n";
import { i18nFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import ngLocaleLoader from "./ngLocaleLoader";

(function() {
  "use strict";
  /** Localization Service
   *
   *  In your AppConfig:
   *  -   Declare supportedLanguages
   *          E.g. var supportedLanguages = ['en','fr','zh','de','es'];
   *  -   Declare a 'dictionary_xx' var for each of the two-letter languages in supportedLanguages.
   *          E.g. var dictionary_en = ['cmn_msg_en','dv_msg_en', 'myApp_msg_en'];
   *      Each 'dictionary_xx' is an array containing the names of global dictionary variables to load (in order).
   *      Typically each of those global dictionary variables, descrived in the next bullet point, are defined
   *      in separately-included js files.
   *      (Currently our localize service doesn't support locale-specific dictionaries, it just uses the letters
   *      before the hyphen, i.e. the first 2 letters. We could add support for locale-specific dictionaries
   *      if needed, e.g. for pt-PT/pt-BR.)
   *  -   Each of those global dictionary vars is an array of objects containing "key" and "value" properties.
   *      E.g. var cmn_msg_en = [
   *               {"key":"_Default.Datetime.Format_",        "value":"MMM d, y h:mm:ss a"},
   *               {"key":"_Loading.Label_",       "value":"Loading"},
   *               {"key":"_Menu.Library.Label_",    "value":"Library"}
   *           };
   *  -   If the selected two-letter language is not supported by NumeralJS, we'll default Numeral to use English.
   * */

  angular
    .module("acl.common.localize", ["acl.common.config", "ui.format", "tmh.dynamicLocale"])
    .constant("Dictionaries", {})
    .run(function(AppConfig, tmhDynamicLocale, tmhDynamicLocaleCache) {
      function getInjectedLocale() {
        var localInjector = angular.injector(["ngLocale"]);
        return localInjector.get("$locale");
      }

      ngLocaleLoader.loadLanguages(AppConfig.supportedLanguages);
      AppConfig.supportedLanguages.forEach(locale => tmhDynamicLocaleCache.put(locale, getInjectedLocale()));
    })
    .factory("Localize", function($window, $filter, AppConfig, Dictionaries, tmhDynamicLocale) {
      // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
      function localeCompareSupportsLocales() {
        try {
          "a".localeCompare("b", "i");
        } catch (e) {
          return e.name === "RangeError";
        }
        return false;
      }
      var localCompareAcceptsLocaleArg = localeCompareSupportsLocales();

      var localize = {
        defaultLanguageCode: "en",

        language: undefined, // (set by initLanguage, called at end of factory)

        subCode: undefined,

        dictionary: {},
        defaultDictionary: {},

        resourceFileLoaded: false,

        initLanguage: function() {
          var userLang = AppConfig.language || $window.navigator.userLanguage || $window.navigator.language;
          localize.language = localize.getPrimaryLanguageCode(userLang);
          localize.subCode = localize.getSubCode(userLang);
          if (localize.subCode === null) {
            localize.setDefaultSubcodeForLang();
          }

          tmhDynamicLocale
            .set(localize.language) // (Tell Angular-dynamic-locale to change the Angular $locale)
            .catch(() => {
              // need to catch rejection if language not available
            });

          i18n.locale = localize.language;
          i18nFormatter.locale = localize.language;
        },

        // allows setting of language on the fly
        setLanguage: function(languageString) {
          localize.language = localize.getPrimaryLanguageCode(languageString);
          localize.subCode = localize.getSubCode(languageString);
          if (localize.subCode === null) {
            localize.setDefaultSubcodeForLang();
          }

          tmhDynamicLocale
            .set(localize.language) // (Tell Angular-dynamic-locale to change the Angular $locale)
            .catch(() => {
              // need to catch rejection if language not available
            });

          localize.initLocalizedResources();

          i18n.locale = localize.language;
          i18nFormatter.locale = localize.language;
        },

        getPrimaryLanguageCode: function(ietfLanaguageCode) {
          var primaryCode = ietfLanaguageCode.split("-")[0];
          if (primaryCode.length) {
            return primaryCode;
          } else {
            return localize.defaultLanguageCode;
          }
        },

        getSubCode: function(ietfLanaguageCode) {
          var codes = ietfLanaguageCode.split("-");
          if (codes.length > 1) {
            return codes[1];
          } else {
            return null;
          }
        },

        setDefaultSubcodeForLang: function() {
          switch (localize.language) {
            case "zh":
              localize.subCode = "CN";
              break;
            default:
              localize.subCode = null;
          }
        },

        // loads the language resource file from the server
        initLocalizedResources: function() {
          var langSupported = AppConfig.supportedLanguages.some(function(supportedLanguage) {
            return supportedLanguage === localize.language;
          });
          if (!langSupported) {
            localize.language = localize.defaultLanguageCode;
          }
          localize.dictionary = {};
          AppConfig["dictionary_" + localize.language].forEach(function(dictionary) {
            angular.extend(localize.dictionary, Dictionaries[dictionary]);
          });
          localize.defaultDictionary = {};
          AppConfig["dictionary_" + localize.defaultLanguageCode].forEach(function(dictionary) {
            angular.extend(localize.defaultDictionary, Dictionaries[dictionary]);
          });

          localize.initNumeralJs(localize.language, localize.subCode);
          localize.initMomentJs(localize.language, localize.subCode);
        },

        initMomentJs: function(twoLetterLanguage, subCode) {
          // Set Moment to use the selected (supported) language
          if (subCode) {
            moment.locale(twoLetterLanguage + "-" + subCode.toLowerCase());
          } else {
            moment.locale(twoLetterLanguage);
          }
        },

        initNumeralJs: function(twoLetterLanguage, subCode) {
          // Set NumeralJS to use the selected (supported) language
          try {
            numeral.language(twoLetterLanguage);
          } catch (e) {
            try {
              if (subCode) {
                numeral.language(twoLetterLanguage + "-" + subCode.toLowerCase());
              } else {
                numeral.language(localize.defaultLanguageCode);
              }
            } catch (err) {
              numeral.language(localize.defaultLanguageCode);
            }
          }
          // overriding numeric abbreviations
          var abbreviations_override = {
            thousand: localize.getLocalizedString("_Numeric.Abbreviations.Thousand_"),
            million: localize.getLocalizedString("_Numeric.Abbreviations.Million_"),
            billion: localize.getLocalizedString("_Numeric.Abbreviations.Billion_"),
            trillion: localize.getLocalizedString("_Numeric.Abbreviations.Trillion_"),
          };
          for (var key in numeral.languageData().abbreviations) {
            numeral.languageData().abbreviations[key] = abbreviations_override[key];
          }
        },

        getLocalizedString: function(key, fallback) {
          return (
            localize.dictionary[key] ||
            localize.dictionary[fallback] ||
            localize.defaultDictionary[key] ||
            localize.defaultDictionary[fallback] ||
            key
          );
        },

        t: function(key, fallback) {
          return localize.getLocalizedString(key, fallback);
        },

        hasLocalizedKey: function(key) {
          return !!localize.dictionary[key];
        },

        getLocalizedStringWithTokenReplacement: function(key, tokens) {
          return $filter("format")(this.getLocalizedString(key), tokens);
        },

        /**
         * getLocalizedTemplateWithTokenGeneration
         *
         * @param key ACL localization key of the template string
         * @returns {template: String, tokens: Array}
         *
         * Takes a localization string with one or more tokens delimited by a dollar sign followed by brackets $(...),
         * and parses it, returning a template string and the tokens (ready to use with the 'format' filter).
         *
         * return value 'template' is the original string with token $(...) replaced by $0
         * When multiple tokens were in the original, they are numbered in order (($0, $1, etc.)
         *
         * return value 'tokens' is an array of the text found within the marked tokens, i.e. the '...' from $(...),
         * in the order they appear.
         *
         * @example
         * Assume foo_msg_en.js has key {"key": "token.example", "value": "This string contains $(one token) and $(another one too)"}
         * Then getLocalizedTemplateWithTokenGeneration("token.example") will return
         *
         * {
         *    template: "This string contains $0 and $1",
         *    tokens: ["one token", "another one too"]
         * }
         *
         */
        getLocalizedTemplateWithTokenGeneration: function(key) {
          var template = this.getLocalizedString(key);
          var tokens = [];
          var start = template.indexOf("$("),
            end;
          var count = 0;
          while (start > -1) {
            end = template.indexOf(")");
            tokens.push(template.slice(start + 2, end));
            template = template.slice(0, start) + "$" + count + template.slice(end + 1);
            count += 1;
            start = template.indexOf("$(");
          }
          return {
            template: template,
            tokens: tokens,
          };
        },

        /**
         * @param {string} number in localized format with no thousand separators (thousand separators are not supported by the system)
         * @returns {string} number in api format
         */
        getApiNumberString: function(localizedNumberString) {
          if (angular.isUndefined(localizedNumberString) || localizedNumberString === null) {
            return "";
          }

          return numeral()
            .unformat(localizedNumberString)
            .toString();
        },
      };

      // Set the language based on the browser settings
      localize.initLanguage();

      // force the load of the resource file
      localize.initLocalizedResources();

      // return the local instance when called
      return localize;
    });
})();
