import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";

window.SelectOptions = createReactClass({
  propTypes: {
    fieldName: PropTypes.string,
    onChange: PropTypes.func,
    onOpenDropdown: PropTypes.func,
    onCloseDropdown: PropTypes.func,
  },

  getDefaultProps: function() {
    return {
      onChange: function() {},
      onOpenDropdown: function() {},
      onCloseDropdown: function() {},
    };
  },

  componentDidMount: function() {
    var selectDropdown = ReactDOM.findDOMNode(this.refs.selectDropdown);
    this.initializeSelect2(selectDropdown);
    this.setupSelect2Event(selectDropdown);
  },

  initializeSelect2: function(element) {
    var select2_options = {
      dropdownCssClass: this.props.dropdownCssClass,
      containerCssClass: this.props.containerCssClass,
    };
    if (this.props.hideSearchBox) {
      select2_options["minimumResultsForSearch"] = Infinity;
    }
    $(element).select2(select2_options);
  },

  setupSelect2Event: function(element) {
    var that = this;
    $(element).on("change", function(e) {
      var selectedOptionText = e.target.options[e.target.selectedIndex].text;
      that.props.onChange(e.target.value, selectedOptionText);
    });
    $(element).on("select2-open", function() {
      that.props.onOpenDropdown();
    });
    $(element).on("select2-close", function(e) {
      that.props.onCloseDropdown(e.target.value);
    });
  },

  render: function() {
    var errorClass, errorPanel;
    var that = this;
    var options = this.props.options.map(function(option) {
      return (
        <option key={option.value} value={option.value} className={option.className} disabled={option.disabled}>
          {option.option}
        </option>
      );
    });
    if (this.props.errorText) {
      errorClass = " error";
      errorPanel = <small className="error-msg">{this.props.errorText}</small>;
    }

    return (
      <div className={!!errorClass ? "select-option" + errorClass : "select-option"}>
        <select name={this.props.fieldName} value={this.props.selectedValue} ref="selectDropdown">
          {options}
        </select>
        {errorPanel}
      </div>
    );
  },
});
