/**
 * This module exposes angular core services such as $state, $q, $rootScope into non-angular code, to be used in es6 module environments.
 */
let service = {};

angular.module("highbond.visualizer.angularServiceEmitter", []).run(function($state, $q, $rootScope) {
  service.state = $state;
  service.q = $q;
  service.rootScope = $rootScope;
});

module.exports = service;
