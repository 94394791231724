import flatten from "lodash/flatten";
import pick from "lodash/pick";
import values from "lodash/values";
import uniq from "lodash/uniq";

const DATA_CONFIG_MAPPER = {
  BarChart: {
    groupByFields: ["chartSeries", "chartXAxis"],
    aggregationFields: ["chartValue"],
  },
  LineChart: {
    groupByFields: ["chartSeries", "chartXAxis"],
    aggregationFields: ["chartValue"],
  },
  PieChart: {
    groupByFields: ["chartCategory"],
    aggregationFields: ["chartValue"],
  },
  StackedAreaChart: {
    groupByFields: ["chartSeries", "chartXAxis"],
    aggregationFields: ["chartValue"],
  },
  BubbleChart: {
    groupByFields: ["chartSeries", "chartXAxis", "chartYAxis"],
    aggregationFields: ["chartValue"],
  },
  Heatmap: {
    groupByFields: ["chartXAxis", "chartYAxis"],
    aggregationFields: ["chartValue"],
  },
  SummaryTable: {
    groupByFields: ["chartColumns", "chartRows"],
    aggregationFields: ["chartValues"],
  },
  Treemap: {
    groupByFields: ["chartRows"],
    aggregationFields: ["chartValues"],
  },
  CombinationChart: {
    groupByFields: ["chartSeries", "chartRows", "chartXAxis"],
    aggregationFields: ["chartValues"],
  },
  MapChart: {
    groupByFields: ["chartRows"],
    aggregationFields: ["chartValues"],
  },
};
const AGGREGATION_MAPPER = {
  average: "avg",
};

export default class ChartConfigTranslator {
  static groupByFields(chartType, dataConfig) {
    const configMapper = DATA_CONFIG_MAPPER[chartType];
    if (!configMapper) throw new Error("Unexpected chart type");

    const flattenValues = flatten(values(pick(dataConfig, configMapper.groupByFields)));
    return uniq(flattenValues.map(field => field.fieldName));
  }

  static aggregationFields(chartType, dataConfig) {
    const configMapper = DATA_CONFIG_MAPPER[chartType];
    if (!configMapper) throw new Error("Unexpected chart type");

    const aggregationFields = [];

    flatten(values(pick(dataConfig, configMapper.aggregationFields))).forEach(field => {
      let aggregationField = aggregationFields.find(
        _aggregationField => _aggregationField.fieldName === field.fieldName
      );
      const aggregationType = AGGREGATION_MAPPER[field.aggregationType] || field.aggregationType;
      if (aggregationField) {
        aggregationField.functions.push(aggregationType);
      } else {
        aggregationField = {
          fieldName: field.fieldName || "",
          functions: [aggregationType],
        };
        aggregationFields.push(aggregationField);
      }
    });

    return aggregationFields;
  }
}
