angular.module("acl.visualizer.mapChart.dataConfig").component("aclMapChartDataConfig", {
  bindings: {
    columnDefs: "<",
    dataConfig: "<",
    fieldNameToFieldObj: "<",
    onDataConfigChange: "&",
    onApplyClick: "&",
    removeTab: "&",
    showChartFormatOptions: "&",
    chartFieldFormatProps: "&",
  },
  restrict: "E",
  templateUrl: "visualizer/js/modules/visualization/mapChart/mapChartDataConfig.tpl.html",
  controllerAs: "mapChartConfig",
  controller: function MapChartDataConfig() {
    const mapChartConfig = this;

    mapChartConfig.viewModel = defaultViewModel();

    mapChartConfig.isApplyButtonEnabled = false;

    mapChartConfig.$onChanges = changeObj => {
      if ((changeObj.columnDefs || changeObj.dataConfig) && mapChartConfig.columnDefs && mapChartConfig.dataConfig) {
        mapChartConfig.viewModel = dataConfigToViewModel(mapChartConfig.dataConfig);
        updateIsApplyButtonEnabled();
      }
    };

    function dataConfigToViewModel(dataConfig) {
      let chartRows = [];
      let coordinates = {
        latitude: null,
        longitude: null,
        displayName: null,
      };
      if (dataConfig.chartRows) {
        if (dataConfig.chartRows.length === 1) {
          chartRows = dataConfig.chartRows || [];
          if (chartRows.includes(undefined)) {
            return defaultViewModel();
          }
        } else {
          coordinates.latitude = dataConfig.chartRows[0];
          coordinates.longitude = dataConfig.chartRows[1];
          coordinates.displayName = dataConfig.chartRows[2];
        }
      }
      const viewModel = defaultViewModel();
      viewModel.chartRows = chartRows;
      viewModel.coordinates = coordinates;
      const { chartValues } = dataConfig;
      if (chartValues) {
        if (chartValues.length > 0) {
          const valueAggregation = dataConfig.chartValues[0];
          viewModel.valueAggregationType = valueAggregation.aggregationType;
          viewModel.valueAggregationFieldName = valueAggregation.fieldName;
        }
      }
      viewModel.locationType = dataConfig.chartRows && dataConfig.chartRows.length > 1 ? "Coordinates" : "Region";
      return viewModel;
    }

    function viewModelToDataConfig(viewModel) {
      var chartRows = [];
      if (viewModel.coordinates.latitude) {
        chartRows.push(viewModel.coordinates.latitude.fieldName || viewModel.coordinates.latitude);
      }
      if (viewModel.coordinates.longitude) {
        chartRows.push(viewModel.coordinates.longitude.fieldName || viewModel.coordinates.longitude);
      }
      if (viewModel.coordinates.displayName) {
        chartRows.push(viewModel.coordinates.displayName.fieldName || viewModel.coordinates.displayName);
      }
      chartRows =
        chartRows.length > 0
          ? chartRows
          : viewModel.chartRows[0] && viewModel.chartRows[0].fieldName
          ? [viewModel.chartRows[0].fieldName]
          : [viewModel.chartRows[0]];
      const dataConfig = {
        aggregationTypes: [viewModel.valueAggregationType],
        chartRows: chartRows[0] ? chartRows : [],
        chartValues: [(viewModel.valueAggregationType !== "count" && viewModel.valueAggregationFieldName) || ""],
        coordinates: viewModel.coordinates,
      };
      return dataConfig;
    }

    mapChartConfig.handleLocationSelectorChange = (type, fieldName) => {
      if (type === "location") {
        mapChartConfig.viewModel.chartRows[0] = fieldName;
        mapChartConfig.viewModel.coordinates = {
          latitude: null,
          longitude: null,
          displayName: null,
        };
      } else if (type === "latitude" || type === "longitude" || type === "displayName") {
        mapChartConfig.viewModel.chartRows = [];
        mapChartConfig.viewModel.coordinates[type] = fieldName;
      } else {
        mapChartConfig.viewModel.locationType = type;
      }
      updateIsApplyButtonEnabled();
      onDataConfigChange();
    };

    mapChartConfig.onChangeValueAggregation = (type, fieldName) => {
      mapChartConfig.viewModel.valueAggregationType = type;
      mapChartConfig.viewModel.valueAggregationFieldName = fieldName;
      updateChartValues();
    };

    function updateChartValues() {
      const values = [];
      const aggregationTypes = [];

      if (mapChartConfig.viewModel.valueAggregationType === "count") {
        values.push("");
        aggregationTypes.push("count");
      } else if (mapChartConfig.viewModel.valueAggregationFieldName) {
        values.push(mapChartConfig.viewModel.valueAggregationFieldName);
        aggregationTypes.push(mapChartConfig.viewModel.valueAggregationType);
      }

      mapChartConfig.viewModel.chartValues = values;
      mapChartConfig.viewModel.aggregationTypes = aggregationTypes;

      updateIsApplyButtonEnabled();
      onDataConfigChange();
    }

    function updateIsApplyButtonEnabled() {
      mapChartConfig.isApplyButtonEnabled = isValidDataConfig(viewModelToDataConfig(mapChartConfig.viewModel));
    }

    function isValidDataConfig(dataConfig) {
      if (!dataConfig) return false;

      const rows = dataConfig.chartRows;
      if (!rows || rows.length === 0) return false;
      if (!isValidDataConfigRow(rows)) return false;
      if (
        rows.length === 1 &&
        (dataConfig.coordinates.latitude || dataConfig.coordinates.longitude || dataConfig.coordinates.displayName)
      )
        return false;
      if (
        rows.length === 2 &&
        dataConfig.coordinates.displayName &&
        (dataConfig.coordinates.latitude || dataConfig.coordinates.longitude)
      )
        return false;
      const { chartValues } = dataConfig;
      const { aggregationTypes } = dataConfig;
      if (!chartValues || !aggregationTypes) return false;
      if (!isValidDataConfigValue(chartValues[0], aggregationTypes[0])) return false;
      return true;
    }

    function isValidDataConfigRow(rows) {
      return rows.some(row => typeof row === "string");
    }

    function isValidDataConfigValue(value, aggregationType) {
      return typeof value === "string" && (aggregationType === "count" || value !== "");
    }

    function onDataConfigChange() {
      mapChartConfig.onDataConfigChange({ dataConfig: viewModelToDataConfig(mapChartConfig.viewModel) });
    }

    function defaultViewModel() {
      return {
        chartRows: [],
        chartValues: [],
        coordinates: {
          latitude: null,
          longitude: null,
          displayName: null,
        },
        valueAggregationType: "sum",
        valueAggregationField: null,
        locationType: "Region",
      };
    }
  },
});
