import React from "react";
import PropTypes from "prop-types";
import { JwtHighbondClient } from "@acl-services/jwt-highbond-client";

const propTypes = {
  origin: PropTypes.string,
  protocol: PropTypes.string,
};

const defaultProps = {
  origin: window.location.origin,
  protocol: window.location.protocol,
};

function VizMonitor(props) {
  const monitorScript = (domain, region) => `${props.protocol}//monitor${region}.${domain}/v1/monitor.js`;

  const getRegion = regionCode => {
    let region = ``;
    if (["ca", "eu", "ap", "au", "us", "af", "sa", "jp", "uk"].includes(regionCode)) {
      region = `-${regionCode}`;
    }
    return region;
  };

  React.useEffect(() => {
    const match = props.origin.match(/^(https?:[^\.]+)\.([^\.]+)\.([^/]+)/);

    if (match) {
      const domain = match[3];
      const [, domainName, intialGovSuffix, subDomain] = domain.match(
        /^(highbond|diligentoneplatform)(-gov|-gov2|-gov3|-mil)?(-[\w-]+)?\.(?:com|mil)$/
      );
      let govSuffix = intialGovSuffix;
      if (
        domainName === "diligentoneplatform" &&
        subDomain &&
        subDomain !== "-dev" &&
        subDomain !== "-staging" &&
        subDomain !== "-preprod"
      ) {
        const matchGovSuffix = subDomain.match(/(gov|gov2|gov3|mil)$/);
        govSuffix = matchGovSuffix[1];
      }

      const isGovCloud = govSuffix || domain === "highbond.mil" || domain === "diligentoneplatform.mil";

      if (!isGovCloud) {
        const regionCode = match[2].split("-").pop();
        const script = monitorScript(domain, getRegion(regionCode));
        const jwtClient = new JwtHighbondClient({
          autoRedirect: true,
        });
        jwtClient
          .fetchWithTokenRefresh(script, {
            method: "GET",
            credentials: "include",
          })
          .then(res => {
            if (res.status == 200) {
              return res.text();
            }
          })
          .then(scriptText => {
            if (scriptText) {
              const scriptTag = document.createElement("script");
              scriptTag.text = scriptText;
              document.head.appendChild(scriptTag);
            }
          });
      }
    }
  }, []);

  return "";
}

VizMonitor.displayName = "vizMonitor";
VizMonitor.propTypes = propTypes;
VizMonitor.defaultProps = defaultProps;

export default VizMonitor;
window.VizMonitor = VizMonitor;
