import backendApi from "@results/services/apiCall/backendApi";
import Sorter from "@viz-ui/services/sorters/sorter";
import { coneService } from "@results/services/apiPath/coneService";
import find from "lodash/find";
import filter from "lodash/filter";
import flatten from "lodash/flatten";
import clone from "lodash/clone";

const ItemType = {
  Table: {
    DataAnalytic: "DataTables::DataAnalytic",
    Survey: "DataTables::Survey",
    EventReport: "DataTables::EventReport",
  },
};

export default class CopyInterpretationService {
  constructor() {
    this.collectionList = null;
  }

  static setCollectionList(collectionList) {
    this.collectionList = collectionList;
  }

  static async getCopyInterpretationsCollections(copyConfigs, force = false) {
    let { projectId, controlId, controlTestId } = copyConfigs;

    projectId = parseFloat(projectId);
    controlId = parseFloat(controlId);
    controlTestId = parseFloat(controlTestId);

    if (!this.collectionList || force) {
      const data = await this.getCollectionMaps();
      const { childItems: projects } = data.items[1];

      const project = find(projects, { id: projectId });
      const control = find(project.childItems, { id: controlId });
      const table = find(control.childItems, { id: controlTestId });

      this.collectionList = this.sortByTitle(projects)
        .flatMap(item => ({
          id: item.id,
          name: item.name,
          type: item.type,
          childItems: flatten(
            item.childItems.map(childItem =>
              filter(childItem.childItems, function(controlTest) {
                if (table.type === ItemType.Table.DataAnalytic) return table.type === controlTest.type;
                else if (table.type === ItemType.Table.Survey || table.type === ItemType.Table.EventReport)
                  return controlTest.type === ItemType.Table.Survey || controlTest.type === ItemType.Table.EventReport;
              })
            )
          ),
        }))
        .filter(collectionItems => collectionItems.childItems.length > 0);
    }
    return this.collectionList;
  }

  static async getCollectionMaps() {
    return await backendApi.get(`/navigation_items`).then(response => {
      if (response.data) return response.data;
    });
  }

  static sortByTitle(arrItem) {
    return Sorter.sort(arrItem, { valueParser: item => String(item.name).trim() });
  }

  static getInterpretationName({ interpretationName, projectId, controlTestId }) {
    const project = find(this.collectionList, { id: projectId });
    const table = find(project.childItems, { id: controlTestId });
    const interpretationsGroup = find(table.childItems, { type: "interpretations" });
    const interpretations = interpretationsGroup.childItems;

    let postfix = 0;
    interpretations.forEach(interpretation => {
      const interpretationsMatch = interpretation.name.split(interpretationName);
      if (interpretation.name === interpretationName) {
        postfix = 1;
      } else if (interpretationsMatch.length === 2) {
        if (interpretationsMatch[1].match(/^([ (]+)([\d]+)([)])/) && interpretationsMatch[1].length === 4) {
          const [, , postfixDigit] = interpretationsMatch[1].match(/^([ (]+)([\d]+)([)])/);
          postfix = parseInt(postfixDigit) < postfix ? postfix : parseInt(postfixDigit) + 1;
        }
      }
    });
    return postfix ? `${interpretationName} (${postfix})` : interpretationName;
  }

  static async copyInterpretation(copyConfig) {
    const { selectedConfig, vizConfig } = copyConfig;
    const saveCopyInterpretationObj = await this.getCurrentInterpretation(vizConfig);
    if (!saveCopyInterpretationObj.interpretation) return;
    const interpretationName = saveCopyInterpretationObj.interpretation.visualizationInfo.title;

    return Promise.allSettled(
      selectedConfig.map(async config => {
        const { projectId, controlId, controlTestId } = config;
        const copyObject = clone(saveCopyInterpretationObj.interpretation);
        const newInterpretationName = this.getInterpretationName({
          interpretationName,
          projectId,
          controlTestId,
        });
        if (newInterpretationName.length > 80) {
          const error_response = {
            data: {
              table_id: null,
              table_name: config.name,
              errors: [
                {
                  code: "title_length",
                  field_name: "Title exceeds limit",
                  type: config.type,
                },
              ],
            },
            status: 422,
          };
          return error_response;
        }
        copyObject.control_test_id = controlTestId.toString();
        copyObject.visualizationInfo.title = newInterpretationName;
        const url = this.getSaveInterpretationUrl(projectId, controlId, controlTestId);
        return backendApi.post(url, {}, copyObject);
      })
    ).then(response => {
      this.getCopyInterpretationsCollections(vizConfig, true);
      return response;
    });
  }

  static async getCurrentInterpretation(vizConfig) {
    const url = this.getInterpretationUrl(vizConfig);
    return await backendApi.get(url).then(response => {
      if (response.data) return response.data;
    });
  }

  static getInterpretationUrl(vizConfig) {
    const { controlId, controlTestId, interpretationId, projectId } = vizConfig;
    return interpretationId === undefined
      ? coneService.projectControlControlTestDefaultInterpretationPath({
          project_id: projectId,
          control_id: controlId,
          control_test_id: controlTestId,
        })
      : `${coneService.projectControlControlTestInterpretationPath({
          project_id: projectId,
          control_id: controlId,
          control_test_id: controlTestId,
          id: interpretationId,
        })}/raw`;
  }

  static getSaveInterpretationUrl(projectId, controlId, controlTestId) {
    return coneService.projectControlControlTestInterpretationsPath({
      project_id: projectId,
      control_id: controlId,
      control_test_id: controlTestId,
    });
  }
}
