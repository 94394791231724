angular.module("acl.visualizer.dataTablePanel", [
  "acl.common.asyncCallManager",
  "acl.common.config",
  "acl.common.filters",
  "acl.common.localize",
  "acl.common.queuedHttp",
  "acl.visualizer.charts",
  "acl.visualizer.conditionalFormatting",
  "acl.visualizer.dataFilter",
  "acl.visualizer.dataGrid",
  "acl.visualizer.dataModel",
  "acl.visualizer.dataTable",
  "acl.visualizer.dataTableConfig",
  "acl.visualizer.dataVisualizer",
  "acl.visualizer.model.table",
  "acl.visualizer.quickMenu",
  "acl.visualizer.tabState",
  "templates-visualizer",
  "acl.visualizer.downloadReportPanel",
]);
