import LocationSelector from "./locationSelector.component";

angular
  .module("acl.visualizer.locationSelector", [
    "acl.common.filters",
    "acl.common.localize",
    "acl.common.directives",
    "acl.visualizer.dataModel",
    "acl.visualizer.style",
    "templates-visualizer",
    "ui.select",
  ])
  .component("aclLocationSelector", LocationSelector);
