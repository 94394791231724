import { isObject, isArray, forEach, cloneDeep } from "lodash";
import LineChartService from "@viz-ui/services/charts/lineChart/lineChartService";
import CombinationChartService from "@viz-ui/services/charts/combinationChart/combinationChartService";
import PieChartService from "@viz-ui/services/charts/pieChart/pieChartService";
import BarChartService from "../barChart/barChartService";
import BubbleChartService from "../bubbleChart/bubbleChartService";
import AreaChartService from "../stackedAreaChart/stackedAreaChartService";
import HeatMap from "../../../components/charts/heatMap/heatMap";
import SummaryTableChartService from "../summaryTableChart/summaryTableChartService";

class ChartDisplayConfigAdapter {
  constructor(ColorPallette, DataModel, AppConfig, EventService) {
    this.ColorPallette = ColorPallette;
    this.DataModel = DataModel;
    this.AppConfig = AppConfig;
    this.EventService = EventService;
  }

  configToViewModel = (chartType, config) => {
    switch (chartType) {
      case "StackedAreaChart":
        return this._areaChartConfigToViewModel(config);
      case "LineChart":
        return this._lineChartConfigToViewModel(config);
      case "BarChart":
        return this._barChartConfigToViewModel(config);
      case "BubbleChart":
        return this._bubbleChartConfigToViewModel(config);
      case "CombinationChart":
        return this._combinationChartConfigToViewModel(config);
      case "MapChart":
      case "StatisticsViz":
      case "Treemap":
      case "SummaryTable":
        return cloneDeep(config);
      case "PieChart":
        return this._pieChartConfigToViewModel(config);
      case "Heatmap":
        return this._heatMapConfigToModel(config);

      default:
        throw Error(`Unexpected chart type. type=${chartType}`);
    }
  };

  _lineChartConfigToViewModel = config => {
    const result = cloneDeep(config);
    const isPreHighCharts = typeof result.displayDataLabels === "undefined";
    if (isPreHighCharts) {
      result.displayDataLabels = false;
      if (!result.yAxis) {
        result.yAxis = {};
      }
      if (result.yAxis.minimum === 0) {
        result.yAxis.minimum = null;
      }
      result.interpolate = true;
      const lineChartService = new LineChartService(
        this.ColorPallette,
        this.DataModel,
        this.AppConfig,
        this.EventService
      );
      this.forceDefaultsDeep(result, lineChartService.defaultDisplayConfig());
    }
    return result;
  };

  _barChartConfigToViewModel = config => {
    const result = cloneDeep(config);
    const isPreHighCharts = typeof result.displayDataLabels === "undefined";
    if (isPreHighCharts) {
      result.displayDataLabels = false;
      if (!result.yAxis) {
        result.yAxis = {};
      }
      if (result.yAxis.minimum === 0) {
        result.yAxis.minimum = null;
      }
    }
    if (!result.chartStyle && result.isStacked) {
      result.chartStyle = "stack";
    }
    const barChartService = new BarChartService(this.ColorPallette, this.DataModel, this.AppConfig, this.EventService);
    this.forceDefaultsDeep(result, barChartService.defaultDisplayConfig());
    return result;
  };

  _pieChartConfigToViewModel(config) {
    const result = cloneDeep(config);
    const isPreHighCharts = typeof result.displayDataLabels === "undefined";
    const pieChartService = new PieChartService(this.ColorPallette, this.DataModel, this.AppConfig, this.EventService);
    if (isPreHighCharts) {
      this.forceDefaultsDeep(result, pieChartService.defaultDisplayConfig());
    }
    return result;
  }

  _bubbleChartConfigToViewModel(config) {
    const bubbleChartService = new BubbleChartService(
      this.ColorPallette,
      this.DataModel,
      this.AppConfig,
      this.EventService
    );
    const result = cloneDeep(config);
    const isPreHighCharts = typeof result.displayDataLabels === "undefined";
    if (isPreHighCharts) {
      result.displayDataLabels = false;
      this.forceDefaultsDeep(result, bubbleChartService.defaultDisplayConfig());
    }
    return result;
  }

  _areaChartConfigToViewModel(config) {
    const result = cloneDeep(config);
    const isPreHighCharts = typeof result.displayDataLabels === "undefined";
    const areaChartService = new AreaChartService(
      this.ColorPallette,
      this.DataModel,
      this.AppConfig,
      this.EventService
    );
    if (isPreHighCharts) {
      this.forceDefaultsDeep(result, areaChartService.defaultDisplayConfig());
    }
    return result;
  }

  _heatMapConfigToModel(config) {
    const result = cloneDeep(config);
    const isPreHighCharts = typeof result.displayDataLabels === "undefined";
    const HeatmapService = new HeatMap(this.ColorPallette, this.DataModel, this.AppConfig, this.EventService);
    if (isPreHighCharts) {
      result.displayDataLabels = false;
      result = this.forceDefaultsDeep(result, HeatmapService.defaultDisplayConfig());
      result.colorAxis.colorStops = result.colorAxis.colorStops.slice(
        0,
        HeatmapService.defaultDisplayConfig().colorAxis.colorStops.length
      );
      if (config.reverseColors) {
        result.colorAxis.colorStops = result.colorAxis.colorStops.reverse();
      }
    }
    if (!result.colorAxis) {
      result.colorAxis = {};
    }
    if (!result.colorAxis.colorStops) {
      result.colorAxis.colorStops = HeatmapService.defaultDisplayConfig().colorAxis.colorStops;
    }
    return result;
  }

  _combinationChartConfigToViewModel(config) {
    const result = cloneDeep(config);
    const isPreHighCharts = typeof result.displayDataLabels === "undefined";
    const combinationChartService = new CombinationChartService(
      this.ColorPallette,
      this.DataModel,
      this.AppConfig,
      this.EventService
    );
    if (isPreHighCharts) {
      result.displayDataLabels = false;
      if (!result.yAxis) {
        result.yAxis = [
          {
            minimum: null,
          },
          {
            minimum: null,
          },
        ];
      }
      if (result.yAxis[0].minimum === 0) {
        result.yAxis[0].minimum = null;
      }
      if (result.yAxis[1].minimum === 0) {
        result.yAxis[1].minimum = null;
      }
    }
    if (!result.chartStyle && result.isStacked) {
      result.chartStyle = "stack";
    }
    this.forceDefaultsDeep(result, combinationChartService.defaultDisplayConfig());
    return result;
  }

  //TODO: Need to add config based on Individual chart service

  forceDefaultsDeep(source, defaults) {
    const target = source;
    if (arguments.length > 2) {
      let result = this.forceDefaultsDeep(target, defaults);
      Array.prototype.slice.call(arguments, 2).forEach(moreDefaults => {
        result = this.forceDefaultsDeep(result, moreDefaults);
      });
      return result;
    }

    if (isObject(defaults)) {
      if (isArray(defaults)) {
        if (!isArray(target)) {
          const result = [];
          defaults.forEach(value => {
            result.push(this.forceDefaultsDeep(undefined, value));
          });
          return result;
        }

        target.forEach((value, i) => {
          target[i] = this.forceDefaultsDeep(value, defaults[i]);
        });
        return target;
      }

      const result = isObject(target) && !isArray(target) ? target : {};
      forEach(defaults, (value, key) => {
        result[key] = this.forceDefaultsDeep(result[key], value);
      });
      return result;
    }

    return typeof target === "undefined" ? defaults : target;
  }
}

export default ChartDisplayConfigAdapter;
