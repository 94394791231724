import CriteriaFilter from "@viz-ui/models/filter/criteriaFilter";
import FieldFilterSet from "@viz-ui/models/filter/fieldFilterSet";
import FilterConfig from "@viz-ui/models/filter/filterConfig";
import ValueFilter from "@viz-ui/models/filter/valueFilter";

angular
  .module("acl.visualizer.model.filter", ["acl.visualizer.model.model", "acl.visualizer.model.table"])
  .factory("CriteriaFilter", () => CriteriaFilter)
  .factory("FieldFilterSet", () => FieldFilterSet)
  .factory("FilterConfig", () => FilterConfig)
  .factory("ValueFilter", () => ValueFilter);
