export function storyboardIdFinder(url) {
  const id = urlSplitter(url);
  return id;
}

export function storyboardUidFinder(url) {
  const uuid = urlSplitter(url);
  return uuid;
}

export function isNewStoryboards(url) {
  const id = urlSplitter(url);
  return id === "new";
}

export function isPublicStoryboard(url) {
  return url.split("/").includes("storyboard_links");
}

export function getLoganBasePath(origin = window.location.origin) {
  return `${origin.replace(".storyboards", ".results")}`;
}

export function getStoryboardsSPABasePath(origin = window.location.origin) {
  return `${origin.replace(".results", ".storyboards")}`;
}

function urlSplitter(url) {
  const lastUrlElement = url.split("/").pop();
  const removedQueryString = lastUrlElement.split("?").shift();
  return removedQueryString;
}
