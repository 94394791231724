import React from "react";
import PropTypes from "prop-types";
import ResizeDetector from "element-resize-detector";
import $ from "jquery";
import isEqual from "lodash/isEqual";

import Toast from "@paprika/toast";
import i18n from "@viz-ui/i18n/i18n";
import ColumnConfig from "../../models/tableConfig/columnConfig";
import Field from "../../models/field/field";
import { transformHotData, shouldTransformData, shouldUpdateTable } from "./hotDataTransformer";
import { initializeHandsontable, updateHandsontable, checkFixedRowsBottom } from "./hotService";
import getContainerDimensions from "./getContainerDimensions";
import RowSelection from "./rowSelection";
import GridHeaderModifier from "./gridHeaderModifier";
import FieldFormatSubscriber from "./fieldFormatSubscriber";
import { highlightSelectedRow } from "./rowSelectionHelper";

import "./dataGrid.scss";

const DataGrid = class extends React.Component {
  static propTypes = {
    checkedRowIds: PropTypes.arrayOf(PropTypes.string),
    checkRowOnRowClick: PropTypes.bool,
    columnConfigs: PropTypes.arrayOf(PropTypes.instanceOf(ColumnConfig)),
    commentCounts: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    detectScrollToBottomByHeight: PropTypes.bool,
    fieldFormatIdentifier: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(PropTypes.instanceOf(Field)).isRequired,
    filteredRecordCount: PropTypes.number,
    filterFieldNames: PropTypes.arrayOf(PropTypes.string),
    fixedRowsBottom: PropTypes.number,
    fontZoomRatio: PropTypes.number,
    highlightSelectedRow: PropTypes.bool,
    isCommentsEnabled: PropTypes.bool,
    isHeaderIconEnabled: PropTypes.bool,
    isPositionEnabled: PropTypes.bool,
    isPresentationMode: PropTypes.bool,
    isRowHighlightingEnabled: PropTypes.bool,
    isRowSelectionEnabled: PropTypes.bool,
    isShrinkWrapEnabled: PropTypes.bool,
    isZebraStripingEnabled: PropTypes.bool,
    mergeCells: PropTypes.arrayOf(PropTypes.object),
    nestedHeaders: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.instanceOf(Field),
          colspan: PropTypes.number,
          rowspan: PropTypes.number,
        })
      )
    ),
    onColumnHeaderClick: PropTypes.func,
    onColumnResize: PropTypes.func,
    onRowClick: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onScrollToBottom: PropTypes.func,
    outsideClickDeselects: PropTypes.bool,
    positionKey: PropTypes.string,
    rowIdKey: PropTypes.string,
    rowSelectInverse: PropTypes.bool,
    selectedFieldName: PropTypes.string,
    selectedRowId: PropTypes.string,
    showCheckedRowsOnly: PropTypes.bool,
    skipCellValueFormatting: PropTypes.bool,
    skipFormattingOnCells: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string,
        rowIndex: PropTypes.number,
      })
    ),
    sortFieldName: PropTypes.string,
    sortOrder: PropTypes.string,
  };

  static defaultProps = {
    checkedRowIds: [],
    checkRowOnRowClick: false,
    columnConfigs: [],
    commentCounts: [],
    detectScrollToBottomByHeight: false,
    filteredRecordCount: 0,
    filterFieldNames: [],
    fixedRowsBottom: null,
    fontZoomRatio: 1,
    highlightSelectedRow: false,
    isCommentsEnabled: false,
    isHeaderIconEnabled: false,
    isPositionEnabled: false,
    isPresentationMode: false,
    isRowHighlightingEnabled: false,
    isRowSelectionEnabled: false,
    isShrinkWrapEnabled: false,
    isZebraStripingEnabled: true,
    mergeCells: [],
    nestedHeaders: [],
    onColumnHeaderClick: () => {},
    onColumnResize: () => {},
    onRowClick: () => {},
    onRowDoubleClick: () => {},
    onScrollToBottom: () => {},
    onSelectionChange: () => {},
    outsideClickDeselects: true,
    positionKey: null,
    rowIdKey: null,
    rowSelectInverse: false,
    selectedFieldName: undefined,
    selectedRowId: null,
    showCheckedRowsOnly: false,
    skipCellValueFormatting: false,
    skipFormattingOnCells: [],
    sortFieldName: null,
    sortOrder: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      rowSelection: new RowSelection(),
    };
    this.pendingTableUpdate = false;
    this.pendingSelectedRowIdChange = false;
    this.hot = null;
    this.fieldFormatSubscriber = new FieldFormatSubscriber();
  }

  async componentWillMount() {
    this.updateRowSelection(this.props, this.state.rowSelection);
    this.setState(prevState => ({ transformedData: transformHotData(this.props, prevState.rowSelection) }));
    this.setState({ gridHeaderModifier: this.getGridHeaderModifier(this.props) });
  }

  componentDidMount() {
    this.hot = initializeHandsontable(
      () => this.props,
      () => this.state.transformedData,
      () => this.state.rowSelection,
      () => this.state.gridHeaderModifier,
      this.targetElement
    );

    this.$gridParent = $(this.targetElement).closest(".data-grid");
    $($(this.targetElement).find(".wtHolder")[0]).attr("tabindex", "0");
    this.resizer = () => {
      this.resizeContainer(this.$gridParent);
      this.hot.render();
    };
    this.resizeDetector = ResizeDetector({
      strategy: "scroll", // <- For ultra performance.
    });
    this.resizeDetector.listenTo(this.dataGridContainer, this.resizer);

    this.updateFieldFormatSubscriber(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { rowSelection } = this.state;
    const selectedRowIdChanged = nextProps.selectedRowId !== rowSelection.getSelectedRowId();
    this.pendingSelectedRowIdChange = selectedRowIdChanged;
    this.updateRowSelection(nextProps, rowSelection);
    this.pendingTableUpdate = shouldUpdateTable(this.props, nextProps, rowSelection);
    this.setState({ gridHeaderModifier: this.getGridHeaderModifier(nextProps) });
    if (shouldTransformData(this.props, nextProps, rowSelection)) {
      this.setState(prevState => ({ transformedData: transformHotData(nextProps, prevState.rowSelection) }));
    }
    if (nextProps.skipCellValueFormatting && !this.props.skipCellValueFormatting) {
      this.fieldFormatSubscriber.unsubscribe();
    }
    if (
      this.fieldFormatSubscriber.shouldUpdateFormatListener(
        this.props.fieldFormatIdentifier,
        nextProps.fieldFormatIdentifier
      )
    ) {
      this.updateFieldFormatSubscriber(nextProps);
    }
  }

  componentDidUpdate() {
    if (this.pendingTableUpdate) {
      updateHandsontable(this.props, this.state.transformedData, this.state.rowSelection, this.hot);
      this.pendingTableUpdate = false;
    } else if (this.pendingSelectedRowIdChange) {
      const rowData = this.state.transformedData.find(
        data => data[this.props.rowIdKey].value === this.state.rowSelection.getSelectedRowId()
      );
      highlightSelectedRow(this.props, this.state.rowSelection, rowData);
      this.pendingSelectedRowIdChange = false;
    }
    if (this.props.isShrinkWrapEnabled) {
      this.resizeContainer(this.$gridParent);
      this.hot.render();
    }
  }

  componentWillUnmount() {
    this.resizeDetector.removeListener(this.dataGridContainer, this.resizer);
    this.fieldFormatSubscriber.unsubscribe();
  }

  getColsWidthUpTo(maxWidth) {
    let result = 0;
    const numCols = this.hot.countCols();
    for (let col = 0; col < numCols; col++) {
      result += this.hot.getColWidth(col);
      if (result >= maxWidth) {
        return maxWidth;
      }
    }
    return result;
  }

  getRowsHeightUpTo(maxHeight) {
    const plugin = this.hot.getPlugin("autoRowSize");
    let result = 0;

    const rowZoomRatio = this.props.fontZoomRatio * 1.1;
    const defaultColumnHeaderHeight = 30; // bug in the plugin when there is only one row
    const defaultRowHeight = 30 * rowZoomRatio;

    let headerRowHeight = plugin.getColumnHeaderHeight() || defaultColumnHeaderHeight;
    if (this.props.nestedHeaders && this.props.nestedHeaders.length) {
      headerRowHeight *= this.props.nestedHeaders.length + 1;
      headerRowHeight += this.props.nestedHeaders.length;
    }

    result += headerRowHeight;
    const numRows = this.hot.countRows();
    const tableRows = plugin.hot.container.querySelector(".htCore >tbody").children;
    for (let row = 0; row < numRows; row++) {
      const rowHeightWithInVisibleArea = plugin.getRowHeight(row);
      const rowHeightWithTextWrap = tableRows[row] ? tableRows[row].offsetHeight : 0;
      const rowHeight = Math.max(rowHeightWithInVisibleArea, rowHeightWithTextWrap);

      result += rowHeight ? rowHeight + 1 : defaultRowHeight;
      if (result >= maxHeight) {
        return maxHeight;
      }
    }
    return result;
  }

  getGridHeaderModifier(props) {
    return new GridHeaderModifier(props.fields, props.nestedHeaders);
  }

  updateFieldFormatSubscriber(props) {
    if (!props.skipCellValueFormatting) {
      this.fieldFormatSubscriber.subscribe(props.fieldFormatIdentifier, () => {
        this.pendingTableUpdate = true;
        this.setState(prevState => ({ transformedData: transformHotData(this.props, prevState.rowSelection) }));
      });
    }
  }

  updateRowSelection(props, rowSelection) {
    if (
      !isEqual(props.checkedRowIds, rowSelection.getCheckedRowIds()) ||
      props.rowSelectInverse !== rowSelection.getInverseMode()
    ) {
      rowSelection.setCheckedRowIds(props.checkedRowIds);
      rowSelection.setInverseMode(props.rowSelectInverse);
      if (props.checkedRowIds.length > 0) {
        rowSelection.setIndeterminateMode();
      } else if (props.rowSelectInverse) {
        rowSelection.setSelectAllMode();
      } else {
        rowSelection.resetCheckboxes();
      }
    }
    if (props.selectedRowId !== rowSelection.getSelectedRowId()) {
      rowSelection.selectRow(props.selectedRowId);
    }
  }

  resizeContainer($container) {
    if (this.props.isShrinkWrapEnabled) {
      const contentOverflow = 50;
      const containerWidth = $container.outerWidth(true);
      const containerHeight = $container.outerHeight(true);
      const contentWidth = this.getColsWidthUpTo(containerWidth + contentOverflow);
      const contentHeight = this.getRowsHeightUpTo(containerHeight + contentOverflow);
      $container
        .find(".data-grid__container")
        .css(getContainerDimensions({ x: containerWidth, y: containerHeight }, { x: contentWidth, y: contentHeight }));

      if (contentHeight < containerHeight) {
        checkFixedRowsBottom(this.props, this.hot);
        // Checking if the word wrap is enabled in table, if present not setting height to auto, else setting height to auto
        if (!this.checkWordWrapColumn()) {
          $container.find(".data-grid__container").css({
            height: "auto",
          });
        }
      }
    } else {
      $container.find(".data-grid__container").css({
        width: $container.width(),
        height: $container.height(),
      });
    }
  }

  checkWordWrapColumn() {
    return this.props.columnConfigs.find(column => column._data.wordWrap);
  }

  redraw() {
    this.hot.render();
  }

  dataGridClasses() {
    let classes = "data-grid";
    if (this.props.onColumnHeaderClick) classes += " data-grid--has-header-clicks";
    if (!this.props.isZebraStripingEnabled) classes += " data-grid--has-no-striping";
    if (this.props.isRowSelectionEnabled || this.props.isRowHighlightingEnabled)
      classes += " data-grid--has-row-highlighting";
    if (this.props.isHeaderIconEnabled) classes += " data-grid--has-header-icons";
    return classes;
  }

  render() {
    let columns = "";
    for (const column of this.props.columnConfigs) {
      columns += column._data.name + column._data.visible + "-";
    }
    return (
      <>
        <div
          className={this.dataGridClasses()}
          ref={node => {
            this.dataGridContainer = node;
          }}
        >
          <div className="data-grid__container">
            <div
              ref={node => {
                this.targetElement = node;
              }}
              className="data-grid__body"
            ></div>
          </div>
        </div>
        <Toast key={columns} kind={Toast.types.kind.VISUALLY_HIDDEN} isPolite canAutoClose>
          {i18n.t("_DataGrid.ConfigurationChangeMessage_")}
        </Toast>
      </>
    );
  }
};

export default DataGrid;
