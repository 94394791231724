angular.module("acl.visualizer.dataTable.backend", [
  "acl.common.config",
  "acl.common.event",
  "acl.common.queuedHttp",
  "acl.visualizer.dataFilter",
  "acl.visualizer.model.filter",
  "acl.visualizer.model.table",
]);

require("./dataTableBackendService");
require("./dataTableQueryBackend.service");

angular
  .module("acl.visualizer.dataTable.backend")
  .run(function(AppConfig, DataTableBackendService, DataTableQueryBackendService, EventService) {
    const eventService = EventService.register("acl.visualizer.dataTable.backend");
    const eventMapping = {
      "dataTable.getTableMetadata": DataTableBackendService.getTableMetadata,
      "dataTable.getTableData": DataTableBackendService.getTableData,
      "dataTable.getFilteredRecordCount": DataTableBackendService.getFilteredRecordCount,
      "dataTable.getCommentCounts": DataTableBackendService.getCommentCounts,
      "dataTable.deleteField": DataTableBackendService.deleteField,
    };

    const dataRequestMapping = {
      fieldValuesRequest: DataTableBackendService.fieldValuesRequested,
      tableRequest: DataTableBackendService.tableRequested,
      metricTableRequest: DataTableBackendService.tableRequestedForMetric,
      recordsetRequest: DataTableBackendService.recordsetRequested,
    };

    let queryDataRequestMapping = {
      tableRequest: DataTableQueryBackendService.getTableMetadata,
      recordsetRequest: DataTableQueryBackendService.recordsetRequested,
    };

    for (var eventKey in eventMapping) {
      eventService.subscribe(eventKey, delegate(eventKey));
    }

    for (var eventName in dataRequestMapping) {
      let requestFunction = dataRequestFunction(eventName);
      eventService.subscribe("request." + eventName, delegateDataRequest(eventName, requestFunction));
    }

    function delegate(key) {
      const delegateFunction = eventMapping[key];

      return function(_, promise) {
        var args = Array.prototype.slice.call(arguments, 2);
        var httpPromise = delegateFunction(...args);
        httpPromise.then(
          data => {
            promise.resolve(data);
          },
          data => {
            promise.reject(data);
          }
        );
      };
    }

    function dataRequestFunction(eventName) {
      return AppConfig.features.queryService && queryDataRequestMapping[eventName]
        ? queryDataRequestMapping[eventName]
        : dataRequestMapping[eventName];
    }

    function delegateDataRequest(eventName, requestFunction) {
      return function(_, token) {
        var args = Array.prototype.slice.call(arguments, 2);
        requestFunction(...args)
          .then(function(data) {
            eventService.publish("resolve." + eventName, token, data);
          })
          .catch(function(error) {
            eventService.publish("reject." + eventName, token, error);
          });
      };
    }
  });
