import ExportPane from "@storyboards/modules/storyboardSharing/components/exportPane.es6";
import classNames from "classnames";
import filter from "lodash/filter";
import find from "lodash/find";
import PropTypes from "prop-types";
import React from "react";
import StoryboardAssignmentService from "../services/storyboardAssignmentService";
import StoryboardLinkService from "../services/storyboardLinkService";
import backendApi from "@results/services/apiCall/backendApi";
import services from "../../../services/glue/appGlue";
import User from "@storyboards/modules/services/user.service";
import { StoryboardBackend } from "../../storyboard/storyboard.backend.es6";
class StoryboardShareContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleUpdateAssignments = this.handleUpdateAssignments.bind(this);
    this.updateAssignment = this.updateAssignment.bind(this);
    this.removeAssignment = this.removeAssignment.bind(this);
    this.generateLink = this.generateLink.bind(this);
    this.regenerateLink = this.regenerateLink.bind(this);
    this.deleteLink = this.deleteLink.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.addAssignmentsToStore = this.addAssignmentsToStore.bind(this);
    this.switchPane = this.switchPane.bind(this);

    this.initializeState(this.props);
  }

  componentDidUpdate(prevProps) {
    // if it is onpened once, then we need not call API
    if (this.props.isOpen !== prevProps.isOpen && !this.state.isOpenedOnce) {
      this.getUsersAndGroups(1);
    }
  }

  async getUsersAndGroups(page = 1, keyWord) {
    if (
      (backendApi.isStoryboardSPA() || services.AppConfig.environment === services.AppConfig.environments.DEV) &&
      !User.isAnonymous()
    ) {
      this.setState({ isLoading: true });
      //if flipper turned on then use new assigned entities api else revert to old apis
      if (services.AppConfig.features.enablePaginationOnCurrentUsers) {
        const assignedEntities = await StoryboardBackend.getStoryboardAssignedEntities(
          services.AppConfig.highbondNavBarProps.appSwitcherProps.initialOrganizationId,
          page,
          this.props.storyboardId,
          keyWord
        );
        const users = assignedEntities.user;
        const admins = assignedEntities.admin;
        const creatorId = this.props.creatorId;
        const creator = find(users, function(user) {
          return user.id === creatorId;
        });
        window.AclExceptionUsers = users;
        window.AclExceptionGroups = assignedEntities.workflowGroup;
        window.AclExceptionStoryboardAdmins = admins;
        window.creator = creator;
        window.currentUserPaginationDetails = assignedEntities.pagination;

        this.setState({
          assignments: this.getFormattedAdminUsers(),
          isOpenedOnce: true,
          paginationData: assignedEntities.pagination,
        });
        //setting up store values
        this.setUpStoreAndAssignments(this.props);
      } else {
        await Promise.all([
          StoryboardBackend.getUsers(),
          StoryboardBackend.getAdminUsers(),
          StoryboardBackend.getGroups(services.AppConfig.highbondNavBarProps.appSwitcherProps.initialOrganizationId),
          StoryboardBackend.getLaunchpadGroups(
            services.AppConfig.highbondNavBarProps.appSwitcherProps.initialOrganizationId
          ),
        ]).then(([users, admins, groups, launchpadGroups]) => {
          const creatorId = this.props.creatorId;
          const creator = find(users, function(user) {
            return user.id === creatorId;
          });

          window.AclExceptionUsers = users;
          window.AclExceptionStoryboardAdmins = admins;
          window.AclExceptionGroups = groups;
          window.launchpadGroups = launchpadGroups;
          window.creator = creator;
          if (window.AclExceptionStoryboardAdmins.length !== 0) {
            this.setState({ assignments: this.getFormattedAdminUsers(), isOpenedOnce: true });
            this.setUpStoreAndAssignments(this.props);
          }
        });
      }
    }
  }

  setUpStoreAndAssignments(props) {
    this.initializeReduxStore(props);
    this.initializeAssignments();
    ACLUISetup.aclPanel();
    this.storyboardShareStore.subscribe(this.handleModelChange);
    this.handleModelChange();
  }

  handleCurrentUsersPageChange = (page, searchKeyWord) => {
    this.getUsersAndGroups(page, searchKeyWord);
  };

  initializeReduxStore(props) {
    let reducers = Redux.combineReducers({
      assignments: AssignmentReducer.users,
      link: LinkReducer.link,
    });
    this.storyboardShareStore = Redux.createStore(
      reducers,
      {
        assignments:
          window.AclExceptionStoryboardAdmins && window.AclExceptionStoryboardAdmins.length !== 0
            ? this.getFormattedAdminUsers()
            : [],
        link: props.storyboardShareLink || props.storyboardLink,
      },
      window.devToolsExtension ? window.devToolsExtension() : f => f
    );
  }

  initializeState(props) {
    this.state = {
      showPanel: props.showPanel,
      paneMode: "users",
      userPaneMode: "currentUsers",
      assignments: [],
      link: props.storyboardShareLink || props.storyboardLink,
      storyboard_link: props.storyboardShareLink,
      isOpenedOnce: false,
      isLoading: false,
      paginationData: null,
    };
  }

  initializeAssignments() {
    if (this.props.storyboardId) {
      //check if flipper is enabled and we have pagination details then use pagination count as guard
      const hasUserPagination =
        window.currentUserPaginationDetails &&
        window.currentUserPaginationDetails.count !== 0 &&
        services.AppConfig.features.enablePaginationOnCurrentUsers;
      //if flipper is not enabled, then check if we have storyboardadmin values
      const hasStoryboardAdmins =
        window.AclExceptionStoryboardAdmins && window.AclExceptionStoryboardAdmins.length !== 0;

      if (hasUserPagination || hasStoryboardAdmins) {
        StoryboardAssignmentService.getAssignments(
          this.props.storyboardId,
          window.AclExceptionUsers,
          window.AclExceptionGroups
        )
          .then(({ userAssignments, groupAssignments }) => {
            this.addAssignmentsToStore(userAssignments, groupAssignments);
            this.setState({ isLoading: false });
          })
          .catch(() => {
            this.setState({ isLoading: false });
            /* deliberately ignore errors - happens when user has no access to permissions */
          });
      } else {
        this.setState({ isLoading: false });
      }
    }

    // Setting loader as false in all cases (including empty values)
  }

  getFormattedAdminUsers() {
    if (window.AclExceptionStoryboardAdmins && window.AclExceptionStoryboardAdmins.length > 0) {
      let isCreatorAdmin = false;
      let creator = window.creator;
      let admins = window.AclExceptionStoryboardAdmins.map(admin => {
        if (!isCreatorAdmin) isCreatorAdmin = creator && creator.id === admin.id;
        return {
          userId: admin.id,
          name: admin.name,
          email: admin.email,
          role: creator && creator.id === admin.id ? "creator" : "admin",
        };
      });
      if (creator && !isCreatorAdmin) {
        admins.push({
          userId: creator.id,
          name: creator.name,
          email: creator.email,
          role: "creator",
        });
      }
      return admins;
    }
  }

  findAssignmentByUserId(userId) {
    let model = this.storyboardShareStore.getState();
    let userAssignments = filter(model.assignments, function(assignment) {
      return !assignment.isAdmin;
    });
    return find(userAssignments, function(a) {
      return a.userId === userId;
    });
  }

  findAssignmentByGroupId(groupId) {
    let model = this.storyboardShareStore.getState();
    let userAssignments = filter(model.assignments, function(assignment) {
      return !assignment.isAdmin;
    });
    return find(userAssignments, function(a) {
      return a.groupId === groupId;
    });
  }

  handleModelChange() {
    let model = this.storyboardShareStore.getState();
    if (model.link.access_type) {
      this.setState({
        assignments: model.assignments,
        storyboard_link: model.link,
        link: model.link.uid,
      });
    } else {
      this.setState({
        assignments: model.assignments,
        link: model.link,
        storyboard_link: model.link,
      });
    }
  }

  async handleUpdateAssignments(users, groups, managedUsers) {
    //if handleupdate coming from manageUsers pane then call the getUsersAndGroups API with loader to reset values
    if (managedUsers) {
      this.setState({ isLoading: true });
      await this.addAssignments(users.added, groups.added);
      await this.removeUserAssignemnts(users.removed);
      await this.removeGroupAssignemnts(groups.removed);
      await this.getUsersAndGroups(1);
      this.setState({ isLoading: false });
    } else {
      await this.addAssignments(users.added, groups.added);
      await this.removeUserAssignemnts(users.removed);
      await this.removeGroupAssignemnts(groups.removed);
    }
  }

  handleClose() {
    this.props.onClose();
    this.setState({
      paneMode: "users",
      userPaneMode: "currentUsers",
    });
  }

  switchPane(pane) {
    this.setState({
      paneMode: pane,
      userPaneMode: "currentUsers",
    });
  }

  async addAssignments(usersAdded, groupsAdded) {
    const { userAssignments, groupAssignments } = await StoryboardAssignmentService.addAssignments(
      this.props.storyboardId,
      window.AclExceptionUsers,
      window.AclExceptionGroups,
      usersAdded,
      groupsAdded
    );

    this.addAssignmentsToStore(userAssignments, groupAssignments);
  }

  async removeUserAssignemnts(usersRemoved) {
    for (const user of usersRemoved) {
      let assignment = this.findAssignmentByUserId(user.id);
      if (assignment) {
        await this.removeAssignment(assignment.assignmentId);
      } else {
        //if assignment is not found in store get it from window object
        let requiredAssignmentID = find(window.assignments.userAssignments, function(a) {
          return a.userId === user.id;
        });
        await this.removeAssignment(requiredAssignmentID.assignmentId);
      }
    }
  }

  async removeGroupAssignemnts(groupsRemoved) {
    for (const group of groupsRemoved) {
      let assignment = this.findAssignmentByGroupId(group.id);
      if (assignment) {
        await this.removeAssignment(assignment.assignmentId);
      } else {
        //if assignment details not found in store then get it from window.assignments and then call remove assignment
        let requiredAssignmentID = find(window.assignments.groupAssignments, function(a) {
          return a.groupId === group.id;
        });
        await this.removeAssignment(requiredAssignmentID.assignmentId);
      }
    }
  }
  //converting these functions to async await to wait for these to happen and then call getusersandgroups function
  async removeAssignment(assignmentId, refreshFlag = false) {
    const removedID = await StoryboardAssignmentService.removeAssignment(this.props.storyboardId, assignmentId);
    this.removeAssignmentFromStore(removedID);
    //if refresh flag is set then call apis again
    if (refreshFlag) {
      this.getUsersAndGroups(1);
    }
  }
  //converting these functions to async await to wait for these to happen and then call getusersandgroups function
  async updateAssignment(assignmentId, role) {
    const assignment = await StoryboardAssignmentService.updateAssignment(this.props.storyboardId, assignmentId, role);
    this.updateAssignmentInStore(assignment.id, assignment.role);
  }

  generateLink(accessType, launchpadGroups, workflowGroups) {
    const link = this.state.storyboard_link ? this.state.storyboard_link.uid : this.state.link;
    StoryboardLinkService.generateLink(this.props.storyboardId, accessType, launchpadGroups, workflowGroups, link).then(
      storyboardLink => {
        this.storyboardShareStore.dispatch(LinkActions.setLink(storyboardLink));
      }
    );
  }

  regenerateLink(accessType, launchpadGroups, workflowGroups) {
    const link = this.state.storyboard_link ? this.state.storyboard_link.uid : this.state.link;
    StoryboardLinkService.regenerateLink(
      this.props.storyboardId,
      link,
      accessType,
      launchpadGroups,
      workflowGroups
    ).then(storyboardLink => {
      this.storyboardShareStore.dispatch(LinkActions.setLink(storyboardLink));
    });
  }

  deleteLink() {
    StoryboardLinkService.deleteLink(this.props.storyboardId, this.state.link).then(() => {
      this.storyboardShareStore.dispatch(LinkActions.removeLink());
    });
  }

  addAssignmentsToStore(userAssignments, groupAssignments) {
    userAssignments.forEach(assignment => {
      this.addUserToStore(
        assignment.assignmentId,
        assignment.userId,
        assignment.name,
        assignment.email,
        assignment.role
      );
    });
    groupAssignments.forEach(assignment => {
      this.addGroupToStore(assignment.assignmentId, assignment.groupId, assignment.name, assignment.role);
    });
  }

  addUserToStore(assignmentId, userId, userName, email, role) {
    this.storyboardShareStore.dispatch(
      AssignmentActions.addUserAssignment({
        assignmentId: assignmentId,
        userId: userId,
        name: userName,
        email: email,
        role: role,
      })
    );
  }

  addGroupToStore(assignmentId, groupId, groupName, role) {
    this.storyboardShareStore.dispatch(
      AssignmentActions.addGroupAssignment({
        assignmentId: assignmentId,
        groupId: groupId,
        name: groupName,
        role: role,
      })
    );
  }

  removeAssignmentFromStore(assignmentId) {
    this.storyboardShareStore.dispatch(AssignmentActions.removeAssignment(assignmentId));
  }

  updateAssignmentInStore(assignmentId, role) {
    this.storyboardShareStore.dispatch(AssignmentActions.updateAssignmentRole(assignmentId, role));
  }

  getPanelBody() {
    let panelBody;
    if (this.state.paneMode === "users") {
      panelBody = (
        <UserPane
          assignments={this.state.assignments}
          admins={this.getFormattedAdminUsers()}
          users={window.AclExceptionUsers}
          groups={window.AclExceptionGroups}
          paneMode={this.state.userPaneMode}
          onPermissionChange={this.updateAssignment}
          isLoading={this.state.isLoading}
          onRemoveUser={this.removeAssignment}
          onUpdateAssignment={this.handleUpdateAssignments}
          paginationData={this.state.paginationData}
          handleCurrentUsersPageChange={this.handleCurrentUsersPageChange}
        />
      );
    } else if (this.state.paneMode === "link") {
      const { storyboard_link } = this.state;
      const publicLink = storyboard_link
        ? StoryboardLinkService.storyboardFullPublicUrl(storyboard_link.uid)
        : StoryboardLinkService.storyboardFullPublicUrl(this.state.link);
      panelBody = (
        <LinkPane
          publicLink={publicLink}
          onGenerate={this.generateLink}
          onRegenerate={this.regenerateLink}
          onDelete={this.deleteLink}
          selectedGroups={storyboard_link ? storyboard_link.group_ids : []}
          selectedLaunchpadGroups={storyboard_link ? storyboard_link.launchpad_group_ids : []}
          linkType={storyboard_link.access_type || "anyone"}
        />
      );
    } else {
      panelBody = <ExportPane onExportStoryboardToPDFClick={this.showExportToPDFOptions} />;
    }
    return (
      <div className={this.state.paneMode === "users" ? "panel_content userPanel_content" : "panel__content"}>
        {panelBody}
      </div>
    );
  }

  showExportToPDFOptions = () => {
    this.handleClose();
    this.props.onExportStoryboardToPDFClick();
  };

  render() {
    const aclPanelClasses = classNames("share-panel acl-panel acl-panel--slide-right storyboard-share-panel", {
      "is-open": this.props.isOpen,
    });
    return (
      <div>
        <div className={aclPanelClasses} data-has-overlay="true" data-clickable-overlay="true">
          <button className="share-panel__close" onClick={this.handleClose}>
            <i className="acl-i-times close__icon" />
          </button>

          <div className="panel__body">
            <StoryboardShareHeader />
            <StoryboardShareNav
              exportStoryboardFlipper={this.props.exportStoryboardFlipper}
              paneMode={this.state.paneMode}
              onNavSelected={this.switchPane}
              showLinkNav={this.props.showLinkNav}
              showMemberNav={this.props.showMemberNav}
            />
            {this.getPanelBody()}
          </div>
        </div>
        <div className="acl-panel-overlay" onClick={this.handleClose} />
      </div>
    );
  }
}

StoryboardShareContainer.propTypes = {
  storyboardId: PropTypes.number,
  storyboardLink: PropTypes.string,
  linkType: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  exportStoryboardFlipper: PropTypes.bool,
  showLinkNav: PropTypes.bool,
  showMemberNav: PropTypes.bool,
  onExportStoryboardToPDFClick: PropTypes.func,
  creatorId: PropTypes.number,
};

window.StoryboardShareContainer = StoryboardShareContainer;
