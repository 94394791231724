import Model from "../model";

class TableCapability extends Model {
  static modelType = "TableCapability";

  constructor(data = {}) {
    super({ canManageTriggers: true, canDeleteColumns: true, canRemediateRecords: true, ...data });
  }

  canManageTriggers(...args) {
    return this.accessor("canManageTriggers", args, "boolean");
  }

  canDeleteColumns(...args) {
    return this.accessor("canDeleteColumns", args, "boolean");
  }

  canRemediateRecords(...args) {
    return this.accessor("canRemediateRecords", args, "boolean");
  }
}

export default TableCapability;
