import FieldValueStore from "@visualizer/modules/model/fieldValue/fieldValueStore.service";
import ValuesRequester from "./ValuesRequester";

export default class SelectLoader {
  constructor(filter, flipQueryService, flipDynamicFilter, forceRerender) {
    this.filter = filter;
    this.flipQueryService = flipQueryService;
    this.flipDynamicFilter = flipDynamicFilter;
    this.forceRerender = forceRerender;
  }

  load() {
    return this._setFieldValueDataSource();
  }

  loadMore = _.throttle(() => this.fieldValueDataSource.load(), 500);

  filterStringChange(filterString) {
    return this._setFieldValueDataSource(filterString);
  }

  valuesRef() {
    return this.fieldValueDataSource.valuesRef();
  }

  totalRowsCountRef() {
    return this.fieldValueDataSource.countRef();
  }

  allItemsLoaded() {
    return !this.fieldValueDataSource.hasMoreValues();
  }

  _setFieldValueDataSource = (filterString = "") => {
    const valuesKeyObject = {
      filterModel: this.filter,
      filterString: filterString,
      flipQueryService: this.flipQueryService,
    };
    this.fieldValueDataSource = FieldValueStore.getDataSource(
      ValuesRequester.getInstance(this.flipQueryService, this.flipDynamicFilter, this.forceRerender),
      valuesKeyObject,
      this.forceRerender
    );

    if (!this.fieldValueDataSource.isLoaded()) {
      return this.fieldValueDataSource.load();
    } else {
      return Promise.resolve();
    }
  };
}
