import uniq from "lodash/uniq";
import isNumber from "lodash/isNumber";
import { HighChartDirective, HighChartController } from "../highChartDirective";
import { BUBBLE_CHART } from "../boost/boostThresholds";

class BubbleChartDirective extends HighChartDirective {
  controller = Controller;
  static factory = () => new BubbleChartDirective();
}

class Controller extends HighChartController {
  static configPath = {
    labelFontSize: [
      "legend.itemStyle.fontSize",
      "plotOptions.series.dataLabels.style.fontSize",
      "tooltip.style.fontSize",
      "xAxis.labels.style.fontSize",
      "xAxis.title.style.fontSize",
      "yAxis.labels.style.fontSize",
      "yAxis.title.style.fontSize",
    ],
  };

  constructor($scope, $timeout, HighChartLabelSizer, Sorter, Localize) {
    "ngInject";
    super($scope, $timeout, HighChartLabelSizer);
    this.Sorter = Sorter;
    this.Localize = Localize;
  }

  onLegendItemClick = item => {
    const showAll = !this.hcConfig.series.some(entry => entry.visible && entry.name !== item.name);
    const series = this.hcConfig.series.map(entry =>
      angular.extend({}, entry, {
        visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
      })
    );
    this.updateHcConfig({ series });
  };

  onLegendItemDoubleClick = item => {
    const series = this.hcConfig.series.map(entry => angular.extend({}, entry, { visible: entry.name === item.name }));
    this.updateHcConfig({ series });
  };

  getConfig = (config, data) => {
    let allDataPoints = data.reduce((all, series) => all.concat(series.values), []);
    let xAxis = {};
    let yAxis = {};
    if (config.xAxis.axisType === "character" || config.xAxis.axisType === "logical") {
      xAxis.categories = this.Sorter.sort(uniq(allDataPoints.map(d => d.x)), {
        dataType: config.xAxis.axisType,
      });
    }
    if (config.yAxis.axisType === "character" || config.yAxis.axisType === "logical") {
      yAxis.categories = this.Sorter.sort(uniq(allDataPoints.map(d => d.y)), {
        dataType: config.yAxis.axisType,
      });
    }

    let hcConfig = {
      legend: {
        enabled: config.showLegend && config.hasMultipleSeries,
        align: "right",
        verticalAlign: "top",
        labelFormatter: function() {
          return config.labelFormatter && this.options.name !== "(blank)"
            ? config.labelFormatter(this.options.legendDisplayName)
            : this.options.legendDisplayName;
        },
      },
      boost: this.getBoostConfig(config),
      plotOptions: {
        series: {
          dataLabels: {
            enabled: config.displayDataLabels,
            formatter: function() {
              return config.valueFormatter(this.point.z);
            },
          },
          point: {
            events: {
              click: this.clickWrapper(null, point => {
                this.zoomIn(
                  config.hasMultipleSeries
                    ? [point.series.name, point.xValue, point.yValue]
                    : [point.xValue, point.yValue]
                );
              }),
            },
          },
          events: {
            legendItemClick: this.clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
          },
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              let header = config.hasMultipleSeries
                ? `
                  <span style="text-decoration: underline grey;">${
                    config.labelFormatter && this.series.options.name !== "(blank)"
                      ? config.labelFormatter(this.series.options.legendDisplayName)
                      : this.series.options.legendDisplayName
                  }</span>
                  <br/>
                `
                : "";
              let body = `
                <span>${config.xAxis.tickFormatter(this.xValue)}</span>
                <br/>
                <span>${config.yAxis.tickFormatter(this.yValue)}</span>
                <br/>
                <b>${config.valueFormatter(this.z)}</b>
              `;
              return header + body;
            },
          },
          turboThreshold: 0,
          animation: this.canAnimate(),
        },
        bubble: {
          minSize: config.bubbleScaleMinSize,
          maxSize: config.bubbleScaleMaxSize,
        },
      },
      xAxis: {
        categories: xAxis.categories,
        labels: {
          formatter: function() {
            // When yaxis is character and value is a number, highchart is trying to fill the axis tick so larger bubble would fit.  Returning empty string so we don't show the number on the axis
            if ((config.xAxis.axisType === "character" || config.xAxis.axisType === "logical") && isNumber(this.value))
              return "";
            return config.xAxis.tickFormatter(this.value);
          },
        },
        tickmarkPlacement: "on",
        title: {
          text: config.xAxis.label,
        },
      },
      yAxis: {
        categories: yAxis.categories,
        title: {
          text: config.yAxis.label,
        },
        labels: {
          formatter: function() {
            // When yaxis is character and value is a number, highchart is trying to fill the axis tick so larger bubble would fit.  Returning empty string so we don't show the number on the axis
            if ((config.yAxis.axisType === "character" || config.yAxis.axisType === "logical") && isNumber(this.value))
              return "";
            return config.yAxis.tickFormatter(this.value);
          },
        },
        min: isNumber(config.yAxis.minimum) ? config.yAxis.minimum : undefined,
        max: isNumber(config.yAxis.maximum) ? config.yAxis.maximum : undefined,
        startOnTick: config.yAxis.axisType !== "character" && !isNumber(config.yAxis.minimum),
        endOnTick: config.yAxis.axisType !== "character" && !isNumber(config.yAxis.maximum),
      },
      series: data.map((series, index) => ({
        type: "bubble",
        name: series.key,
        legendDisplayName:
          series.key === "(blank)" ? this.Localize.getLocalizedString("_Filter.BlankValue.Label_") : series.key,
        boostThreshold: BUBBLE_CHART,
        color:
          !this.getBoostConfig(config, data.length >= BUBBLE_CHART).enabled && this.$scope.isPatternFill
            ? this.patternFillPalettes(index)
            : series.color,
        visible: series.visible !== false,
        data: series.values.map(point => ({
          x: xAxis.categories ? xAxis.categories.indexOf(point.x) : point.x,
          y: yAxis.categories ? yAxis.categories.indexOf(point.y) : point.y,
          xValue: point.x,
          yValue: point.y,
          z: point.size,
        })),
      })),
    };
    hcConfig = this.updateLabelFontSize(hcConfig, Controller.configPath.labelFontSize);
    return hcConfig;
  };

  zoomIn = keyArray => {
    if (angular.isFunction(this.$scope.zoomInHandler)) {
      const data = {
        point: {
          x: keyArray[keyArray.length - 2],
          y: keyArray[keyArray.length - 1],
        },
      };
      if (keyArray.length > 2) {
        data.series = { key: keyArray[0] };
      }
      this.$scope.zoomInHandler(data);
    }
  };
}

export default BubbleChartDirective;
