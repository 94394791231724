import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";

class LoadingAnimationState {
  constructor() {
    this._timeout = null;
    this._isActive = false;
    this._isDebugMode = false;
    this._loadingNames = [];
  }

  start = (name = "") => {
    UsageTracker.mark("loadingAnimation.start");
    this._loadingNames.push(name);

    this._isActive = true;

    if (this._timeout) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }

    if (this._isDebugMode) {
      this.logStartDebugInfo(name);
    }
  };

  stop = (name = "") => {
    UsageTracker.mark("loadingAnimation.stop");
    this.removeLoadingName(name, this._loadingNames);

    if (this._loadingNames.length <= 0 && this._timeout === null) {
      this._timeout = setTimeout(() => {
        this._isActive = false;
        this._timeout = null;
      });
    }

    if (this._isDebugMode) {
      this.logStopDebugInfo(name);
    }
  };

  isActive = () => this._isActive;

  isDebug = newValue => {
    if (this.newValue === undefined) return this._isDebugMode;
    this._isDebugMode = !!newValue;
  };

  toString = () => "LoadingAnimationState(this._loadingNames=[" + this._loadingNames.join(", ") + "])";

  removeLoadingName = (name, _loadingNames) => {
    const index = _loadingNames.indexOf(name);
    if (index >= 0) {
      _loadingNames.splice(index, 1);
    } else if (name) {
      throw new Error("Couldn't find loading item to stop. label=" + name);
    } else {
      throw new Error("Couldn't find unlabeled loading item to stop.");
    }
  };

  logStartDebugInfo = name => {
    if (name) {
      // eslint-disable-next-line no-console
      console.log("Started loading item. label=" + name, this.toString());
    } else {
      // eslint-disable-next-line no-console
      console.log("Started loading item.", this.toString());
    }
  };

  logStopDebugInfo = name => {
    if (name) {
      // eslint-disable-next-line no-console
      console.log("Stopped loading item. label=" + name, this.toString());
    } else {
      // eslint-disable-next-line no-console
      console.log("Stopped loading item.", this.toString());
    }
  };
}
export default new LoadingAnimationState();
