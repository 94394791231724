import Model from "../model";

class Field extends Model {
  static modelType = "Field";

  name(...args) {
    return this.accessor("name", args, "string");
  }

  displayName(...args) {
    return this.accessor("displayName", args, "string");
  }

  type(...args) {
    return this.accessor("type", args, "string");
  }

  isPrimaryKey(...args) {
    return this.accessor("isPrimaryKey", args, "boolean");
  }

  tableId(...args) {
    return this.accessor("tableId", args, "*");
  }

  static fromJson(fieldName, json) {
    return new Field()
      .name(fieldName)
      .displayName(json.displayName)
      .type(json.type)
      .tableId(json.table_id);
  }

  toJson() {
    return this._deleteUndefinedProperties({
      displayName: this.displayName(),
      type: this.type(),
    });
  }
}
export default Field;
