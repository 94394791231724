import GlobalFieldFormatMap from "./globalFieldFormatMap";
import { TableDataFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import TimezoneOffset from "../../models/timezoneOffset/timezoneOffset";
import StringFormatter from "../../services/formatters/stringFormatter";

class GlobalValueFormatter {
  constructor() {
    this._timezoneOffset = new TimezoneOffset();
  }

  formatValue(value, fieldFormatIdentifier, fieldName, formatBlanks = false) {
    const fieldFormat = GlobalFieldFormatMap.getFieldFormat(fieldFormatIdentifier, fieldName);
    return this._formatValueWithFormat(value, fieldFormatIdentifier, fieldName, fieldFormat, formatBlanks);
  }

  normalizeValue(value, fieldType) {
    return TableDataFormatter.normalizeValue(value, fieldType);
  }

  setUtcOffset(utcOffsetSeconds) {
    this._timezoneOffset = new TimezoneOffset({ utcOffsetSeconds });
  }

  getTimezoneOffset() {
    return this._timezoneOffset;
  }

  getTimezoneOffsetForMetric(table, metric) {
    const metricConfig = metric.metricConfig();
    const timeFieldName = metricConfig.timeFieldName();
    const timeField = table.fieldByName(timeFieldName);
    if (!timeField || timeField.type() !== "datetime") return null;
    return this.getTimezoneOffset();
  }

  isHtmlFormatted(fieldFormatIdentifier, fieldName) {
    const fieldFormat = GlobalFieldFormatMap.getFieldFormat(fieldFormatIdentifier, fieldName);
    return (fieldFormat && fieldFormat.isHtml()) || false;
  }

  isUrlFormatted(fieldFormatIdentifier, fieldName) {
    const fieldFormat = GlobalFieldFormatMap.getFieldFormat(fieldFormatIdentifier, fieldName);
    return (fieldFormat && fieldFormat.isURL()) || false;
  }

  getUrlFormattedValue(value) {
    return StringFormatter.convertUrlLink(value);
  }
  // Allow for special cases that need to change format in a way before global
  // formatting
  _formatValueWithFormat(value, fieldFormatIdentifier, fieldName, fieldFormat, formatBlanks = false) {
    const fieldType = GlobalFieldFormatMap.getFieldType(fieldFormatIdentifier, fieldName);
    const fieldFormatJson = fieldFormat && fieldFormat.toJson();
    if (fieldType === "numeric" && fieldFormatJson && fieldFormatJson.isRaw) {
      /*
        Unformatted Value(isRaw) formatting option is removed for numeric fields as part of the ticket VRMRG-18.
        This logic is to maintain the backward compatibility for the fields that has Unformatted Value formatting option. Applying default formatting option 'Number' for the fields that has Unformatted Value
      */
      fieldFormatJson.isRaw = false;
      fieldFormatJson.thousandsDelimiter = true;
    }
    const utcOffsetMinutes = this._timezoneOffset.utcOffsetMinutes();
    return TableDataFormatter.formatValue(value, fieldType, fieldFormatJson, formatBlanks, utcOffsetMinutes);
  }
}

export default new GlobalValueFormatter();
