import { angularStoryboardBackend } from "./storyboard.backend";

angular
  .module("acl.storyboard.storyboard", [
    "acl.storyboard.board",
    "acl.storyboard.dataStore",
    "acl.storyboard.visualization",
    "acl.visualizer.dataGrid",
    "acl.visualizer.charts",
    "acl.visualizer.color",
    "acl.visualizer.heatmap",
    "acl.visualizer.summaryTable",
    "acl.visualizer.indicator",
    "acl.visualizer.treemap",
    "acl.visualizer.combinationChart",
    "acl.visualizer.mapChart",
    "highbond.visualizer.navigation",
  ])
  .factory("StoryboardBackend", angularStoryboardBackend);
