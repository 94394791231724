import Sorter from "../../../services/sorters/sorter";
import uniq from "lodash/uniq";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";

export const reorderXAxisDataPoints = (chartData, chartConfig) => {
  const {
    order,
    valuesOrder,
    xAxis: { fieldType },
  } = chartConfig;

  if (!order || order === "default") {
    return getXAxisDataPointsInAscOrder(chartData, fieldType);
  } else if (order === "custom") {
    return getXAxisDataPointsInCustomOrder(chartData, valuesOrder, fieldType);
  }
};
export const sortChartDataByPropertyName = (data, propertyName, dataType, order = "asc", isUnicode = true) => {
  return Sorter.sort(data, {
    dataType: dataType,
    order: order,
    unicode: isUnicode,
    valueParser: item => item[propertyName],
  });
};
export const getXAxisDataPointsInAscOrder = (chartData, dataType) => {
  if (chartData && chartData.length > 0) {
    const allXAxisDataPoints = chartData.reduce(
      (allXAxisDataPoints, series) => allXAxisDataPoints.concat(series.values),
      []
    );
    return Sorter.sort(uniq(allXAxisDataPoints.map(d => d.key)), { dataType: dataType });
  }
};

export const getXAxisDataPointsInCustomOrder = (chartData, valuesOrder, dataType) => {
  if (chartData && chartData.length > 0) {
    const allXAxisDataPoints = getXAxisDataPointsInAscOrder(chartData, dataType);
    return Sorter.sort(allXAxisDataPoints, {
      valueParser: value => {
        const parsedValue = parseValue(value, dataType);
        return GlobalValueFormatter.normalizeValue(parsedValue, dataType);
      },
      valuesOrder: valuesOrder,
      dataType: dataType,
    });
  }
};

export const reorderValues = (chartData, chartConfig) => {
  const {
    order,
    valuesOrder,
    xAxis: { fieldType },
  } = chartConfig;
  switch (order) {
    case "asc":
    case "desc":
      return sortChartDataByTotals(chartData, order);
    case "custom":
      return sortChartDataByCustomOrder(chartData, valuesOrder, fieldType);
    default:
      return chartData.slice(0);
  }
};
export const sortChartDataByTotals = (data, order) => {
  var valueTotalsByKey = {};

  // If values is array then its these values that should be sorted.
  if (data.length && Array.isArray(data[0].values)) {
    data.forEach(row => getValueTotalsByKey(row.values, valueTotalsByKey));
    return data.map(row => {
      const sortedValues = Sorter.sort(row.values, {
        valueParser: item => valueTotalsByKey[item.key],
        order,
        dataType: "numeric",
      });
      return { ...row, values: sortedValues };
    });
  }
  getValueTotalsByKey(data, valueTotalsByKey);
  return Sorter.sort(data, {
    valueParser: item => valueTotalsByKey[item.key],
    order: order,
    dataType: "numeric",
  });
};

export const getValueTotalsByKey = (data, valueTotalsByKey) => {
  data.map(value => {
    const { key } = value;
    valueTotalsByKey[key] = valueTotalsByKey[key] || 0;
    valueTotalsByKey[key] += value.values;
    return valueTotalsByKey[key];
  });
};

export const sortChartDataByCustomOrder = (data, valuesOrder, dataType) => {
  if (data.length && Array.isArray(data[0].values)) {
    return data.map(row => {
      const sortedValues = Sorter.sort(row.values, {
        valueParser: value => {
          const parsedValue = parseValue(value.key, dataType);
          return GlobalValueFormatter.normalizeValue(parsedValue, dataType);
        },
        valuesOrder,
        dataType,
      });
      return { ...row, values: sortedValues };
    });
  }
};

export const parseValue = (value, fieldType) => {
  switch (fieldType) {
    case "datetime":
      return value ? moment.unix(value).toDate() : null;

    case "date":
      return value
        ? moment
            .unix(value)
            .utc()
            .format("YYYY-MM-DD")
        : null;

    case "time":
      return value ? moment.unix(value).format("HH:mm:ss") : null;

    case "numeric":
    case "character":
    case "logical":
      return value;

    default:
      throw new Error("Unknown data type: " + fieldType);
  }
};
