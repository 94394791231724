import isEqual from "lodash/isEqual";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";
import StoryBoardDrilldownService from "@viz-ui/services/storyboardDrilldown/storyboardDrilldownService";
import FieldFilterSet from "@viz-ui/models/filter/fieldFilterSet";
import FilterConfig from "@viz-ui/models/filter/filterConfig";
import SessionStorageHelper from "@viz-ui/services/sessionStorage/sessionStorageHelper";
import {
  getStoryboardsSPABasePath,
  getLoganBasePath,
} from "@viz-ui/services/storyboardUrlDetection/storyboardUrlDetection";
import quickMenuToggler from "@viz-ui/services/quickMenu/quickMenuTogglerService";
import ConditionalCriteriaOperators from "@viz-ui/services/quickMenu/conditionalFormat/conditionalCriteriaOperatorsService";
import MetricValidator from "@viz-ui/services/metrics/metricValidatorService";
import LoadingAnimationState from "@viz-ui/services/common/loadingAnimationStateService";
import MetricNamer from "@viz-ui/services/metrics/metricNamerService";
import LoadingAnimationStateInitializer from "@viz-ui/services/metrics/loadingAnimationStateInitializerService";
import MetricManager from "@viz-ui/services/metrics/metricManagerService";
import addScript from "@results/services/addScripts/addScripts";
import backendApi from "@results/services/apiCall/backendApi";

angular.module("acl.visualizer.metricApp").directive("aclMetricApp", function() {
  function MetricAppCtrl(
    $q,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $window,
    $filter,
    $document,
    AggregatorArgs,
    AppConfig,
    AsyncCallManager,
    DeleteMetricConfirmer,
    EventService,
    FilterHacksService,
    HighbondNavigationService,
    Metric,
    MetricSparkline,
    PromptSaveChangesConfirmer,
    Recordset,
    RecordsetManager,
    RenameMetricPrompter,
    Table,
    TableManager,
    UnsavedChangesConfirmer,
    Localize,
    $modal
  ) {
    const metricApp = this;

    var currentMetric = new Metric();
    var currentTable = new Table();
    var currentRecordset = new Recordset();
    var currentSparkline = new MetricSparkline({ values: [] });
    var eventService = EventService.register("acl.visualizer.metricApp.aclMetricApp");
    var loadSummaryAndSparklineTimeout;
    var loadSummaryAndSparklineDebounceDuration = 500;
    var deregisterChangeStart;
    var synchronizedLoadMetric = AsyncCallManager.resolveOnlyLastCallTo(MetricManager.loadMetric);
    var synchronizedLoadMetricSparkline = AsyncCallManager.resolveOnlyLastCallTo(MetricManager.loadMetricSparkline);
    var synchronizedLoadMetricSummary = AsyncCallManager.resolveOnlyLastCallTo(MetricManager.loadMetricSummary);
    var synchronizedLoadRecordset = AsyncCallManager.resolveOnlyLastCallTo(RecordsetManager.loadRecordset);
    var synchronizedLoadTable = AsyncCallManager.resolveOnlyLastCallTo(TableManager.loadTable);

    $scope.showHighbondNavigation = AppConfig.features.highbondNavigationInVisualizer;
    let storyboardDrilldownFiltersExist = false;

    $scope.pageHeaderProps = AppConfig.pageHeaderProps;

    init();

    function init() {
      initGlobalNavBar();
      initFilterHacks();

      initSideNav();

      loadTable().then(function() {
        watchStateParamMetricId();
      });

      setTimezoneOffset();

      initControllerProperties();
      if (AppConfig.features.metricTriggers) initTriggersDialog();

      LoadingAnimationStateInitializer.init(eventService);

      if (typeof $state.params.metricId !== "undefined") {
        metricApp.metricId = parseInt($state.params.metricId, 10);
      }
    }

    async function initGlobalNavBar() {
      try {
        addScript(`${backendApi.getWebComponentsUrl()}/global-navigator/index.js`);
        $scope.highbondNavBarProps = await HighbondNavigationService.getGlobalNavProps();
      } catch (ex) {
        showNotification("error", $filter("aclLocalize")("_Chart.LoadFailed.Error_"));
      }
    }

    function watchStateParamMetricId() {
      $scope.$watch(
        () => $state.params.metricId,
        newValue => {
          if (newValue) {
            loadMetric(parseInt(newValue, 10));
          } else {
            loadNewMetric();
          }
        }
      );
    }

    function initTriggersDialog() {
      switch ($state.current.name) {
        case "openTrigger":
          metricApp.openTriggersDialog(parseInt($state.params.triggerId, 10));
          break;
        case "openTriggers":
          metricApp.openTriggersDialog();
          break;
        default:
          break;
      }
    }

    function setTimezoneOffset() {
      if (AppConfig.features.timezoneShifting && AppConfig.timezoneOffsetSeconds) {
        GlobalValueFormatter.setUtcOffset(AppConfig.timezoneOffsetSeconds);
      }
    }

    $scope.$watch(
      function() {
        return (
          (metricApp.hasUnsavedChanges && MetricValidator.isValid(currentMetric)) || storyboardDrilldownFiltersExist
        );
      },
      function(newValue) {
        metricApp.saveButtonEnabled = newValue;
        metricApp.saveButtonTip = metricApp.saveButtonEnabled
          ? ""
          : $filter("aclLocalize")("_ActionButtons.SaveNothing.Tooltip_");
      }
    );

    function initControllerProperties() {
      // @FIXME Metrics currently doesn't have access to permissions. Once
      // exposed hook these up to proper permissions
      metricApp.hasSaveButton = true;
      metricApp.hasNewButton = true;
      metricApp.hasRenameButton = true;
      metricApp.hasSaveAsButton = true;
      metricApp.canDeleteMetric = true;

      metricApp.hasUnsavedChanges = false;
      metricApp.saveButtonEnabled = false;
      metricApp.saveButtonTip = $filter("aclLocalize")("_ActionButtons.SaveNothing.Tooltip_");

      metricApp.notification = {
        message: undefined,
        type: undefined,
        onDismiss: function() {
          closeNotification();
        },
      };

      metricApp.filterPanel = {
        isOpen: false,
      };

      metricApp.showLoadingAnimation = function() {
        return LoadingAnimationState.isActive();
      };

      metricApp.handleOpenTriggersDialog = function() {
        if (metricApp.hasUnsavedChanges) {
          openPromptSaveChangesDialog();
        } else {
          metricApp.openTriggersDialog();
        }
      };

      metricApp.openTriggersDialog = function(editTriggerId) {
        var modalContainer = $("<div>").addClass("triggers-modal-container");

        var props = {
          projectId: AppConfig.project_id,
          controlId: AppConfig.control_id,
          controlTestId: AppConfig.control_test_id,
          metricId: metricApp.metricId,
          editTriggerId: editTriggerId,
          onCloseCallback: metricApp.onTriggersDialogClose,
        };

        $("body").prepend(modalContainer);
        $("html").addClass("scrollable");

        metricApp.triggersModal = $window.showTriggersModal(props, modalContainer[0]);
      };

      metricApp.onTriggersDialogClose = function() {
        $("html").removeClass("scrollable");
        loadMetric(currentMetric.id());
      };

      metricApp.$onDestroy = function() {
        metricApp.closeTriggersDialog();
      };

      metricApp.closeTriggersDialog = function() {
        if (metricApp.triggersModal) {
          metricApp.triggersModal.modalCloseHandler();
        }
      };

      metricApp.onDuplicate = function() {
        openRenameMetricDialog({ duplicateMode: true });
      };

      metricApp.handleOnSave = function() {
        if (currentMetric.isNew()) {
          openRenameMetricDialog({ mustRename: false });
        } else {
          const filterConfig = FilterHacksService.getFilterConfig().toJson();
          if (
            AppConfig.features.carryStoryboardFilters &&
            ($state.current.name === "savedMetric" || $state.current.name === "openTrigger") &&
            isStoryboardFilterAvailable(filterConfig.filterList || [])
          ) {
            saveConfirmationPostDrilldown();
          } else {
            metricApp.onSave();
          }
        }
      };

      metricApp.onSave = function(newMetricName, options = {}) {
        var filterConfig;

        if (typeof newMetricName !== "undefined") {
          currentMetric.name(newMetricName);
        }

        filterConfig = FilterHacksService.getFilterConfig();
        // Need to set the real filtersOpen because of hacks.
        filterConfig.filtersOpen(metricApp.filterPanel.isOpen);
        currentMetric.filterConfig(filterConfig);

        //cleaning-up storyboard visual indicator props on save, so it won't reappear post save.
        if (
          storyboardDrilldownFiltersExist &&
          currentMetric.filterConfig() &&
          currentMetric.filterConfig().fieldFilterSets()
        ) {
          const metricFilterConfig = currentMetric.filterConfig();
          const filterList = metricFilterConfig.fieldFilterSets().map(filterSet => filterSet.toJson());

          removeStoryboardVisualIndicatorProps(filterList);

          metricFilterConfig.fieldFilterSets(filterList.map(filter => FieldFilterSet.fromJson(filter)));
          currentMetric.filterConfig(metricFilterConfig);
        }

        if (!MetricValidator.isValid(currentMetric)) {
          return $q.reject();
        }

        if (currentMetric.isNew()) {
          return MetricManager.createMetric(currentMetric)
            .then(function(createdMetric) {
              metricApp.hasUnsavedChanges = false;
              $timeout(function() {
                if (options.openTriggersDialog) {
                  $state.go("openTriggers", { metricId: createdMetric.id() }, { location: true });
                } else if (!options.prevent_navigate) {
                  $state.go("savedMetric", { metricId: createdMetric.id() }, { location: true });
                }
              }, 1000);
              return createdMetric;
            })
            .catch(function(error) {
              if (isExistingNameError(error)) {
                openRenameMetricDialog({
                  displayDuplicateError: true,
                });
              } else {
                throw error;
              }
            });
        }

        return MetricManager.saveMetric(currentMetric)
          .then(function(savedMetric) {
            currentMetric = savedMetric;
            metricChanged(savedMetric, false);
            metricApp.hasUnsavedChanges = false;
            if (options.openTriggersDialog) metricApp.openTriggersDialog();
            if (storyboardDrilldownFiltersExist) {
              storyboardDrilldownFiltersExist = false;
              $timeout(function() {
                SessionStorageHelper.remove(getStoryboardDrilldownSessionStorageKey());
                $state.go("savedMetric", { metricId: currentMetric.id() }, { location: true });
              }, 1000);
            }
            return currentMetric;
          })
          .catch(function(error) {
            if (isExistingNameError(error)) {
              openRenameMetricDialog({
                displayDuplicateError: true,
              });
            } else {
              throw error;
            }
          });
      };

      function isExistingNameError(error) {
        return error && error.status === 422;
      }

      function openRenameMetricDialog(options = {}) {
        return RenameMetricPrompter.openModal(currentMetric.name(), options).then(function($modalScope) {
          if (options.duplicateMode) currentMetric = currentMetric.cloneExceptId();
          return metricApp.onSave($modalScope.newMetricName);
        });
      }

      metricApp.onDelete = function() {
        DeleteMetricConfirmer.openModal(currentMetric.name()).then(function(remove) {
          if (remove) {
            MetricManager.deleteMetric(currentMetric).then(function() {
              metricApp.hasUnsavedChanges = false;
              metricApp.metrics = [];
              $state.go("newMetric");
            });
          }
        });
      };

      metricApp.onRename = function() {
        openRenameMetricDialog();
      };

      metricApp.onBackButtonClick = function() {
        const url = AppConfig.projectUrl;
        metricApp.checkUnsavedNavigateTo(url);
      };

      //FIXME: this is going to cause issues with currentSparkline and currentMetric when changed
      metricApp.indicatorConfigPanel = {
        isOpen: true,
        fields: [],
        selectedFieldName: null,
        timeFields: [],
        sparklineModel: new MetricSparkline({ values: [] }),
        metric: currentMetric || {},
        onFieldFormatChange: function(field, fieldFormat) {
          currentMetric.fieldFormatByFieldName(field.name(), fieldFormat);
          metricChanged(currentMetric, true);
          recordsetChanged(currentRecordset);
          sparklineChanged(currentSparkline, currentMetric);
        },
        onOpenConditionalFormat: function(field) {
          $modal
            .open({
              templateUrl: "visualizer/views/conditionalFormattingModal.html",
              controller: "ConditionalFormattingController as conditionalFormatting",
              windowClass: "conditional-formatting-modal",
              resolve: {
                fieldFormatIdentifier: function() {
                  return metricApp.metricId;
                },
                fieldName: function() {
                  return field.name();
                },
              },
            })
            .result.catch(() => {});
        },
        onFormChange: function(metricConfig, valid, dirty, reloadData = true) {
          currentMetric.metricConfig(metricConfig);

          currentMetric.name(MetricNamer.getName(currentMetric, currentTable));

          metricChanged(currentMetric, false);

          metricApp.hasUnsavedChanges = valid && dirty;

          if (reloadData && MetricValidator.isValid(currentMetric) && valid) {
            return loadSummaryAndSparkline(currentMetric);
          }
          if (reloadData) {
            sparklineChanged(new MetricSparkline({ values: [] }), currentMetric);
          } else {
            sparklineChanged(currentSparkline, currentMetric);
          }
          return $q.resolve();
        },
      };

      metricApp.dataGrid = {
        data: [],
        fieldFormatMap: new Map(),
        fields: [],
        fixedColumnsLeft: 1,
        getAttachmentUrl: getAttachmentUrl,
        onColumnHeaderClick: (fieldName, offset) => quickMenuToggler.toggle(offset, fieldName, true),
        sortFieldName: null,
        sortOrder: null,
      };

      metricApp.dataGrid.onDataGridScrollToBottom = AsyncCallManager.mergeOverlappingCallsTo(function() {
        var deferred = $q.defer();
        var filterConfig = currentMetric.filterConfig();
        if (currentRecordset.lastRecordIndex() < currentRecordset.recordCount()) {
          filterConfig.startRecord(currentRecordset.lastRecordIndex() + 1);
          currentMetric.filterConfig(filterConfig);
          currentRecordset.filterConfig(filterConfig);
          FilterHacksService.filterConfigChanged(filterConfig);

          RecordsetManager.loadRecordset(currentTable, filterConfig)
            .then(function(recordset) {
              recordsetConcat(recordset);
              $timeout(function() {
                deferred.resolve();
              });
            })
            .catch(function() {
              showMetricError();
              deferred.resolve();
            });
        } else {
          deferred.resolve();
        }

        return deferred.promise;
      });

      metricApp.recordCount = {
        filtered: undefined,
        total: undefined,
      };

      metricApp.onHomeButtonClick = function() {
        var url = $window.location.origin;
        metricApp.checkUnsavedNavigateTo(url);
      };

      metricApp.checkUnsavedNavigateTo = function(url) {
        if (metricApp.hasUnsavedChanges) {
          UnsavedChangesConfirmer.openModal(currentMetric.isNew(), currentMetric.name()).then(function($modalScope) {
            if ($modalScope.save) {
              metricApp.onSave($modalScope.model.newName).then(function() {
                navigateTo(url);
              });
            } else if ($modalScope.skip) {
              navigateTo(url);
            }
          });
        } else {
          navigateTo(url);
        }
      };

      metricApp.startNewMetric = function() {
        if (metricApp.hasUnsavedChanges) {
          UnsavedChangesConfirmer.openModal(currentMetric.isNew(), currentMetric.name()).then(function($modalScope) {
            if ($modalScope.save) {
              metricApp.onSave($modalScope.model.newName).then(function() {
                $state.go("newMetric");
              });
            } else if ($modalScope.skip) {
              metricApp.hasUnsavedChanges = false;
              $state.go("newMetric");
            }
          });
        } else {
          $state.go("newMetric");
        }
      };

      metricApp.toggleIndicatorPanel = function() {
        metricApp.indicatorConfigPanel.isOpen = !metricApp.indicatorConfigPanel.isOpen;
      };

      metricApp.getIndicatorOpenClass = function() {
        if (metricApp.indicatorConfigPanel && metricApp.indicatorConfigPanel.isOpen) {
          return "is-open";
        }
        return "is-closed";
      };

      metricApp.toggleFilterPanel = function() {
        metricApp.filterPanel.isOpen = !metricApp.filterPanel.isOpen;
        FilterHacksService.filtersOpenChanged(metricApp.filterPanel.isOpen);

        setFiltersOpen(currentMetric, metricApp.filterPanel.isOpen);

        metricChanged(currentMetric, false);
      };

      metricApp.getFilterOpenClass = function() {
        if (metricApp.filterPanel.isOpen) {
          return "is-open";
        }
        return "is-closed";
      };
    }

    function initFilterHacks() {
      metricApp.table = FilterHacksService.getTableObj();

      $scope.toggleFilterPanel = function() {
        FilterHacksService.toggleFilterPanel();
      };

      eventService.subscribe("clearGridData", function() {
        recordsetChanged(new Recordset().records([]));
      });

      eventService.subscribe("sortUpdated", function(eventName, fieldName, sortOrder) {
        var filterConfigObj = angular.copy(FilterHacksService.getFilterConfig());
        filterConfigObj.sortField = {
          field: fieldName,
          order: sortOrder,
        };
        if (!FilterHacksService.validateFilters(filterConfigObj)) return;

        eventService.publish("clearGridData");
        FilterHacksService.applySort(fieldName, sortOrder);

        filterConfigObj = FilterHacksService.getFilterConfig().startRecord(1);
        currentMetric.filterConfig(filterConfigObj);
        metricChanged(currentMetric, false);
      });

      eventService.subscribe("filterPanel.filterChange", function(event, filterConfigObj, noReload) {
        var filterConfig;

        if (noReload || !FilterHacksService.validateFilters()) return;

        eventService.publish("clearGridData");
        filterConfig = FilterHacksService.getFilterConfig();
        filterConfig.startRecord(1);
        FilterHacksService.filterConfigChanged(filterConfig);

        eventService.publish("dataTable.dataChange");

        currentMetric.filterConfig(FilterHacksService.getFilterConfig());
        metricChanged(currentMetric, false);
        if (MetricValidator.isValid(currentMetric)) {
          loadSummaryAndSparkline(currentMetric);
        }
      });

      eventService.subscribe("dataTable.formattingChange", () => {
        formattingChanged();
      });

      eventService.subscribe("biView.columnSelected", function(event, fieldName) {
        $scope.$apply(() => {
          metricApp.dataGrid.selectedFieldName = fieldName;
        });
      });

      eventService.subscribe("dataTable.dataChange", function() {
        loadRecordset(currentTable, FilterHacksService.getFilterConfig());
      });

      eventService.subscribe("dataTable.configChange", function() {
        currentMetric.conditionalFormatsMap(FilterHacksService.getConditionalFormatsMap());
        loadRecordset(currentTable, FilterHacksService.getFilterConfig());
      });

      eventService.subscribe("filterPanel.close", () => {
        metricApp.filterPanel.isOpen = false;
        $scope.$apply();
      });

      FilterHacksService.onFiltersOpenChange(() => {
        const newValue = FilterHacksService.isFilterPanelOpen();
        if (!newValue) {
          metricApp.filterPanel.isOpen = false;
        }

        currentMetric.filterConfig(FilterHacksService.getFilterConfig());
        metricChanged(currentMetric, false);
      });

      eventService.subscribe("biView.chartClick", function(event, fieldName) {
        $scope.$apply(function() {
          metricApp.indicatorConfigPanel.selectedFieldName = fieldName;
        });
        eventService.publish("quickMenu.close");
      });

      eventService.subscribe("biView.statisticsClick", function(event, fieldName) {
        $scope.$apply(function() {
          metricApp.indicatorConfigPanel.selectedFieldName = fieldName;
        });
        eventService.publish("quickMenu.close");
      });
    }

    function initSideNav() {
      const element = angular.element(".metric-app__side-nav")[0];
      if ($window.renderReactSideSplit) {
        $window.renderReactSideSplit(element);
        $(".metric-app__side-nav").on("click", "a", e => {
          if (metricApp.hasUnsavedChanges) {
            e.preventDefault();
            const url = $(e.currentTarget).attr("href");
            metricApp.checkUnsavedNavigateTo(url);
          }
        });
      }
    }

    function navigateTo(url) {
      metricApp.hasUnsavedChanges = false;
      $window.onbeforeunload = null;
      $window.location.assign(url);
    }

    function setFiltersOpen(metric, value) {
      var filterConfig = metric.filterConfig();
      filterConfig.filtersOpen(value);
      metric.filterConfig(filterConfig);
    }

    function formattingChanged() {
      const fieldFormatModelsByName = FilterHacksService.getFieldFormatModelsByName();
      currentMetric.fieldFormatMap(fieldFormatModelsByName);

      metricChanged(currentMetric, true);
      recordsetChanged(currentRecordset);
      sparklineChanged(currentSparkline, currentMetric);
    }

    function loadNewMetric() {
      var metric = new Metric();
      metricChanged(metric, false);
      metricApp.hasUnsavedChanges = false;
      metricApp.filterPanel.isOpen = false;
    }

    function loadMetric(metricId) {
      const storyboardDrilldownStorageKey = getStoryboardDrilldownSessionStorageKey();
      if (!SessionStorageHelper.get(storyboardDrilldownStorageKey)) {
        let initiateDrilldown = true;
        let storyboardsSPAOrigin;
        let storyboardsLoganOrigin;
        if (AppConfig.environment !== AppConfig.environments.DEV) {
          storyboardsSPAOrigin = getStoryboardsSPABasePath();
          storyboardsLoganOrigin = getLoganBasePath();
        } else {
          storyboardsSPAOrigin = storyboardsLoganOrigin = $window.storyboardAppUrl;
        }

        //It's used to trigger from storyboard app.
        $window.addEventListener(
          "message",
          function(event) {
            if (
              (event.origin == storyboardsSPAOrigin || event.origin == storyboardsLoganOrigin) &&
              event.data.drilldownNotifier &&
              initiateDrilldown
            ) {
              initiateDrilldown = false;
              event.source.postMessage({ stopDrilldownNotifier: true }, event.origin); //Notifying storyboard app to stop the message event.
              SessionStorageHelper.set(storyboardDrilldownStorageKey, event.data.drilldownFilters);
              SessionStorageHelper.set(event.data.storyboardId, event.data.storyboardName);
            }
          },
          false
        );
      }

      $timeout(() => {
        synchronizeLoadMetric(metricId);
      }, 100);

      function synchronizeLoadMetric(metricId) {
        return synchronizedLoadMetric(metricId)
          .then(async function(metric) {
            if (AppConfig.features.carryStoryboardFilters) {
              await processDrilldownFilters(metric);
            }
            metricChanged(metric, true);
            metricApp.hasUnsavedChanges = false;

            loadSummaryAndSparkline(metric);
            if (FilterHacksService.isFilterPanelOpen()) {
              metricApp.filterPanel.isOpen = true;
            }
          })
          .catch(function(error) {
            if (error !== AsyncCallManager.CALL_DROPPED) {
              showMetricError();
              throw error;
            }
          });
      }
    }

    async function processDrilldownFilters(metric) {
      const sessionStorageKey = getStoryboardDrilldownSessionStorageKey();
      if (sessionStorageKey) {
        await StoryBoardDrilldownService.processDrilldownFiltersAsync(
          metric.filterConfig() && metric.filterConfig().fieldFilterSets()
            ? metric
                .filterConfig()
                .fieldFilterSets()
                .map(filterSet => filterSet.toJson())
            : [],
          sessionStorageKey
        ).then(data => {
          if (data.storyboardFiltersExists && data.updatedVizFilters && data.updatedVizFilters.length > 0) {
            const filterConfig = metric.filterConfig() || new FilterConfig();
            filterConfig.fieldFilterSets(data.updatedVizFilters.map(filterSet => FieldFilterSet.fromJson(filterSet)));
            metric.filterConfig(filterConfig);
            storyboardDrilldownFiltersExist = true;
          }
        });
      }
    }

    function showMetricError() {
      showNotification("error", $filter("aclLocalize")("_MetricsNotification.MetricError.Message_"));
    }

    function metricChanged(metric, fieldFormatChange) {
      currentMetric = metric;

      if (!isPercentOfFilterSelectionValid(metric)) {
        resetPercentOfFilterSelection(metric);
      }

      metricApp.title = metric.name() || $filter("aclLocalize")("_MetricsConfig.NewMetric.Label_");
      if (metricApp.title) {
        var platformName = $filter("aclLocalize")("_App.Name.Results_") + " - HighBond";
        $document[0].title = `${metricApp.title} - ${platformName}`;
      }
      metricApp.isNew = metric.isNew();
      metricApp.isProjectArchived = metric.isProjectArchived();

      if (fieldFormatChange) {
        GlobalFieldFormatMap.setFieldFormats(metricApp.metricId, metric.fieldFormatMap());
      }

      FilterHacksService.filterConfigChanged(metric.filterConfig());
      FilterHacksService.conditionalFormatsMapChanged(metric.conditionalFormatsMap());
      FilterHacksService.fieldFormatMapChanged(metric.fieldFormatMap());
      metricApp.dataGrid.sortFieldName = FilterHacksService.getSortFieldName();
      metricApp.dataGrid.sortOrder = FilterHacksService.getSortOrder();

      var filterFieldNames = FilterHacksService.getFilterFieldNames();
      if (!isEqual(metricApp.dataGrid.filterFieldNames, filterFieldNames)) {
        metricApp.dataGrid.filterFieldNames = filterFieldNames;
      }

      if (!metric.filterConfig().filterEquals(currentRecordset.filterConfig())) {
        loadRecordset(currentTable, metric.filterConfig());
      }
      metricApp.hasUnsavedChanges = true;
      metricApp.saveAsButtonEnabled = !currentMetric.isNew();
    }

    function isPercentOfFilterSelectionValid(metric) {
      var fieldType;
      var operator;

      if (metric.metricConfig().func() !== "percent-of") {
        return true;
      }

      if (!metric.metricConfig().aggregatorArgs()) {
        return false;
      }

      operator = metric
        .metricConfig()
        .aggregatorArgs()
        .operator();
      fieldType = currentTable.typeMap()[metric.metricConfig().fieldName()];

      if (operator && fieldType) {
        return ConditionalCriteriaOperators.canOperatorAcceptFilterType(operator, fieldType);
      }
      return false;
    }

    function resetPercentOfFilterSelection(metric) {
      var fieldType = currentTable.typeMap()[metric.metricConfig().fieldName()];
      var defaultOperator = ConditionalCriteriaOperators.getDefaultOperator(fieldType);
      metric.metricConfig().aggregatorArgs(new AggregatorArgs().operator(defaultOperator));
    }

    function loadSummaryAndSparkline(metric) {
      if (loadSummaryAndSparklineTimeout) {
        $timeout.cancel(loadSummaryAndSparklineTimeout);
      }

      loadSummaryAndSparklineTimeout = $timeout(function() {
        loadSummaryAndSparklineTimeout = undefined;
        return $q.all([loadMetricSummary(metric), loadMetricSparkline(metric)]);
      }, loadSummaryAndSparklineDebounceDuration);

      return loadSummaryAndSparklineTimeout;
    }

    function loadMetricSummary(metric) {
      return synchronizedLoadMetricSummary(metric)
        .then(function(metricSummary) {
          metricApp.indicatorConfigPanel.functionPreview = metricSummary;
          return metricSummary;
        })
        .catch(function(error) {
          if (error !== AsyncCallManager.CALL_DROPPED) {
            showMetricSummaryError();
            throw error;
          }
        });
    }

    function showMetricSummaryError() {
      showNotification("warning", $filter("aclLocalize")("_MetricsNotification.MetricSummaryError.Message_"));
    }

    function loadMetricSparkline(metric) {
      const timezoneOffset = GlobalValueFormatter.getTimezoneOffsetForMetric(currentTable, metric);
      return synchronizedLoadMetricSparkline(metric, timezoneOffset)
        .then(function(metricSparkline) {
          sparklineChanged(metricSparkline, metric);
        })
        .catch(function(error) {
          if (error !== AsyncCallManager.CALL_DROPPED) {
            showMetricSparklineError();
            throw error;
          }
        });
    }

    // this is deserialized data  (serialized via viz-ui service)
    function sparklineChanged(newMetricSparklineModel, metric) {
      currentSparkline = newMetricSparklineModel.clone();
      metricApp.indicatorConfigPanel.sparklineModel = currentSparkline;
      metricApp.indicatorConfigPanel.metric = metric.clone();
    }

    function showMetricSparklineError() {
      showNotification("warning", $filter("aclLocalize")("_MetricsNotification.MetricSparklineError.Message_"));
    }

    function loadTable() {
      return synchronizedLoadTable(AppConfig.control_test_id)
        .then(function(table) {
          tableChanged(table);
          loadRecordset(table, FilterHacksService.getFilterConfig());
        })
        .catch(function(error) {
          if (error !== AsyncCallManager.CALL_DROPPED) {
            showTableError();
            throw error;
          }
        });
    }

    function tableChanged(table) {
      currentTable = table;
      metricApp.dataGrid.fields = table.fields();

      metricApp.recordCount.total = table.recordCount();

      metricApp.indicatorConfigPanel.canManageTriggers = table.capabilities().canManageTriggers();
      metricApp.indicatorConfigPanel.fields = table.fieldsByTypes(["numeric", "character", "date", "time", "datetime"]);
      metricApp.indicatorConfigPanel.table = table.clone();
      metricApp.indicatorConfigPanel.timeFields = table.fieldsByTypes(["date", "datetime"]);

      GlobalFieldFormatMap.setFieldTypes(metricApp.metricId, table.fields());
      FilterHacksService.tableChanged(table, metricApp);
    }

    function showTableError() {
      showNotification("error", $filter("aclLocalize")("_MetricsNotification.TableError.Message_"));
    }

    function loadRecordset(table, filterConfig) {
      return synchronizedLoadRecordset(table, filterConfig)
        .then(function(recordset) {
          recordsetChanged(recordset);
          tableChanged(recordset.table());
        })
        .catch(function(error) {
          if (error !== AsyncCallManager.CALL_DROPPED) {
            showRecordsetError();
            throw error;
          }
        });
    }

    function recordsetChanged(recordset) {
      currentRecordset = recordset;
      metricApp.recordCount.filtered = recordset.recordCount();
      dataGridRecordChanged(currentRecordset);
    }

    function recordsetConcat(recordset) {
      currentRecordset.concat(recordset);
      dataGridRecordChanged(currentRecordset);
    }

    function dataGridRecordChanged(recordset) {
      metricApp.dataGrid.data = recordset.records();
    }

    function showRecordsetError() {
      showNotification("error", $filter("aclLocalize")("_MetricsNotification.RecordsetError.Message_"));
    }

    function showNotification(type, message) {
      metricApp.notification.type = type;
      metricApp.notification.message = message;
    }

    function closeNotification() {
      metricApp.notification.type = undefined;
      metricApp.notification.message = undefined;
    }

    function openMetricUnsavedChangesDialog(toState, toParams) {
      UnsavedChangesConfirmer.openModal(currentMetric.isNew(), currentMetric.name()).then(function($modalScope) {
        if ($modalScope.save) {
          metricApp.onSave($modalScope.model.newName, { prevent_navigate: true }).then(function() {
            $state.go(toState, toParams);
          });
        } else if ($modalScope.skip) {
          metricApp.hasUnsavedChanges = false;
          $state.go(toState, toParams);
        }
      });
    }

    function openPromptSaveChangesDialog() {
      PromptSaveChangesConfirmer.openModal(currentMetric.isNew(), currentMetric.name()).then(function($modalScope) {
        if ($modalScope.save) {
          metricApp.onSave($modalScope.model.newName, {
            openTriggersDialog: true,
          });
        }
      });
    }

    function getAttachmentUrl() {
      return AppConfig.getAttachmentUrl;
    }

    $scope.$on("$destroy", function() {
      eventService.unregister();
    });

    deregisterChangeStart = $rootScope.$on("$stateChangeStart", function(event, toState, toParams) {
      if (metricApp.hasUnsavedChanges) {
        event.preventDefault();
        openMetricUnsavedChangesDialog(toState, toParams);
      }
    });
    $rootScope.$on("$destroy", deregisterChangeStart);

    $window.onbeforeunload = function() {
      if (metricApp.hasUnsavedChanges) return metricApp.hasUnsavedChanges;
    };

    function saveConfirmationPostDrilldown() {
      const { storyboard_id } = $stateParams;
      const storyboardName = SessionStorageHelper.get(storyboard_id) || "";
      let that = ($scope.saveConfirmationPropsPostDrilldown = {
        zIndex: 999,
        openModal: true,
        confirmationButtonType: "primary",
        confirmButtonText: Localize.getLocalizedString("_Storyboard.Post.Drilldown.Save.Confirmation.ConfirmButton_"),
        headerText: Localize.getLocalizedString("_Storyboard.Post.Drilldown.Save.Confirmation.HeaderText_"),
        bodyText: Localize.getLocalizedStringWithTokenReplacement(
          "_Storyboard.Post.Drilldown.Save.Confirmation.BodyText_",
          storyboardName
        ),
        onConfirm: () => {
          that.openModal = false;
          metricApp.onSave();
        },
        onCancel: () => {
          that.openModal = false;
        },
      });
    }

    function isStoryboardFilterAvailable(filterList) {
      if (filterList.find(filter => filter.showStoryboardIndicator)) {
        return true;
      }
      return false;
    }

    function getStoryboardDrilldownSessionStorageKey() {
      let sessionStorageKey;
      let boardType;
      switch ($state.current.name) {
        case "savedMetric":
          boardType = "metric";
          break;
        case "openTrigger":
          boardType = "trigger";
          break;
      }

      if (boardType) {
        const { metricId, triggerId, storyboard_id } = $stateParams;
        sessionStorageKey = StoryBoardDrilldownService.getSessionStorageKey({
          type: boardType,
          metricId: metricId,
          triggerId: triggerId,
          storyboardId: storyboard_id,
        });
      }

      return sessionStorageKey;
    }

    function removeStoryboardVisualIndicatorProps(filterList) {
      if (filterList && filterList.length > 0) {
        filterList.forEach(filterSet => {
          if (Object.prototype.hasOwnProperty.call(filterSet, "showStoryboardIndicator")) {
            delete filterSet.showStoryboardIndicator;
          }

          if (filterSet.quickFilter) {
            if (Object.prototype.hasOwnProperty.call(filterSet.quickFilter, "storyboardIndicatorValues")) {
              delete filterSet.quickFilter.storyboardIndicatorValues;
            }

            if (Object.prototype.hasOwnProperty.call(filterSet.quickFilter, "indeterminateValues")) {
              delete filterSet.quickFilter.indeterminateValues;
            }
          }

          if (filterSet.filters && filterSet.filters.length > 0) {
            filterSet.filters.forEach(filter => {
              if (Object.prototype.hasOwnProperty.call(filter, "showStoryboardIndicator")) {
                delete filter.showStoryboardIndicator;
              }
            });
          }
        });
      }
    }
  }

  return {
    restrict: "E",
    replace: true,
    scope: {},
    templateUrl: "visualizer/js/modules/core/metricApp/metricApp.tpl.html",
    controller: MetricAppCtrl,
    controllerAs: "metricApp",
  };
});
