class TableCapability {
  constructor() {
    this._canDeleteColumns = false;
    this._canManageTriggers = false;
    this._canRemediateRecords = false;
  }

  setData({ canDeleteColumns, canManageTriggers, canRemediateRecords }) {
    this._canDeleteColumns = canDeleteColumns;
    this._canManageTriggers = canManageTriggers;
    this._canRemediateRecords = canRemediateRecords;
  }

  canDeleteColumns() {
    return this._canDeleteColumns;
  }

  canManageTriggers() {
    return this._canManageTriggers;
  }

  canRemediateRecords() {
    return this._canRemediateRecords;
  }
}

export default new TableCapability();
