import cloneDeep from "lodash/cloneDeep";

export default class StoryboardDataStore {
  static _visualizationData = new Map();

  static addVisualizationData(interpretationId, visualizationId, data) {
    const visualizationDataKey = visualizationDataDataKey(interpretationId, visualizationId);
    this._visualizationData.set(visualizationDataKey, cloneDeep(data));
  }

  static getVisualizationData(interpretationId, visualizationId) {
    const visualizationDataKey = visualizationDataDataKey(interpretationId, visualizationId);

    return this._visualizationData.has(visualizationDataKey)
      ? cloneDeep(this._visualizationData.get(visualizationDataKey))
      : null;
  }

  static resetData() {
    this._visualizationData = new Map();
  }
}

function visualizationDataDataKey(interpretationId, visualizationId) {
  return JSON.stringify({ interpretationId, visualizationId });
}
