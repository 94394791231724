import ReactDirective from "acl-ui/angular/reactDirective.umd";
import Breadcrumb from "@viz-ui/components/breadcrumb/breadcrumb";
import GlobalNavigationBar from "@viz-ui/components/globalNavBar/globalNavigationBar";
import PageHeader from "@viz-ui/components/PageHeader/PageHeader";

angular
  .module("highbond.visualizer.navigation", [])
  .directive("highbondBreadcrumb", ReactDirective.getFactory(Breadcrumb))
  .directive("highbondPageheader", ReactDirective.getFactory(PageHeader))
  .directive("highbondGlobalNavBar", ReactDirective.getFactory(GlobalNavigationBar));
