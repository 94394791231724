import React from "react";
import PropTypes from "prop-types";
import i18n from "@viz-ui/i18n/i18n";
import Icon from "acl-ui/components/Icon";
import Toggle from "acl-ui/components/Toggle";

const { bool, string, func } = PropTypes;

const chartConfigTabButtons = props => {
  const { selectedItem, showSortTab, onChange } = props;
  const dataTab = {
    value: "data",
    label: (
      <span>
        <Icon type="data" />
        {i18n.t("_ChartConfig.Data.Label_")}
      </span>
    ),
  };
  const displayTab = {
    value: "display",
    label: (
      <span>
        <Icon type="image" />
        {i18n.t("_ChartConfig.Display.Label_")}
      </span>
    ),
  };
  const sortTab = {
    value: "sort",
    label: (
      <span>
        <Icon type="rearrange-y" />
        {i18n.t("_ChartConfig.Sort.Label_")}
      </span>
    ),
  };

  const getItems = () => {
    return showSortTab ? [dataTab, displayTab, sortTab] : [dataTab, displayTab];
  };

  return (
    <Toggle
      selectedItemIds={selectedItem}
      items={getItems()}
      size={"medium"}
      selectedColor={"solid-green"}
      uppercase={false}
      onSelect={onChange}
    />
  );
};

chartConfigTabButtons.propTypes = {
  showSortTab: bool,
  selectedItem: string,
  onChange: func,
};
chartConfigTabButtons.defaultProps = {
  showSortTab: false,
  selectedItem: "data",
  onChange: () => {},
};

export default chartConfigTabButtons;
