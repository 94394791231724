import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";
import Spinner from "acl-ui/components/Spinner";
import Toast from "@paprika/toast";

window.Dropdown = createReactClass({
  propTypes: {
    errorMessage: PropTypes.string,
    filterList: PropTypes.func.isRequired,
    listItems: PropTypes.array,
    placeholder: PropTypes.string,
  },

  getDefaultProps: () => {
    return {
      errorMessage: null,
      listItems: null,
      placeholder: "",
    };
  },

  getInitialState() {
    return {
      mode: "closed",
      dropdownStyle: { height: 0, position: "fixed" },
      listStyle: {},
    };
  },

  open(button) {
    var dropdownHeight = Math.floor(($(window).height() * 2) / 3);
    var dropdownWidth = 350;
    var windowWidth = $(window).width();
    var align = "center";

    if (button.offset().left + dropdownWidth > windowWidth) {
      align = "right";
    } else if (button.offset().left < windowWidth / 3) {
      align = "left";
    }
    var openDropdownStyle = {
      height: dropdownHeight,
      display: "block",
      position: "fixed",
    };
    if (align === "right") {
      openDropdownStyle = { ...openDropdownStyle, ...{ left: "auto", right: 50 } };
    } else if (align === "left") {
      openDropdownStyle = { ...openDropdownStyle, ...{ left: button.offset().left } };
    } else {
      openDropdownStyle = {
        ...openDropdownStyle,
        ...{ left: button.offset().left - (dropdownWidth / 2 - button.width() / 2) },
      };
    }

    var buttonScrollTop = button.offset().top - $(window).scrollTop() + 35;
    if (buttonScrollTop + dropdownHeight > $(window).height()) {
      openDropdownStyle = { ...openDropdownStyle, ...{ top: "auto", bottom: 0 } };
    } else {
      openDropdownStyle = { ...openDropdownStyle, ...{ top: buttonScrollTop, bottom: "auto" } };
    }

    this.setState({
      dropdownOpened: true,
      dropdownStyle: openDropdownStyle,
      listStyle: { maxHeight: openDropdownStyle.height - 42 },
    });
    $(this.searchInput).focus();
  },

  close() {
    if (this.state.dropdownOpened) {
      this.setState({ dropdownOpened: false, dropdownStyle: { height: 0, position: "fixed" } });
    }
  },

  toggle(button) {
    if (this.state.dropdownOpened) {
      this.close();
    } else {
      this.open(button);
    }
  },

  filterList(e) {
    this.props.filterList(e.target.value);
  },

  handleKeyDown(e) {
    if (e.keyCode === 27) {
      this.close();
    }
  },

  getBackdropStyle() {
    var display = this.state.dropdownOpened ? "block" : "none";
    return {
      position: "fixed",
      background: "purple",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      background: "transparent",
      display: display,
    };
  },

  getDropdown() {
    return this.props.listItems ? (
      <div className="board-selector__list-items" style={this.state.listStyle}>
        {this.props.listItems}
      </div>
    ) : (
      <div className="board-selector__loading">
        <Spinner />
      </div>
    );
  },

  getError() {
    return (
      <div className="board-selector__error">
        <Toast kind="error" hasCloseButton={false}>
          {this.props.errorMessage}
        </Toast>
      </div>
    );
  },

  render() {
    if (this.state.dropdownOpened) {
      return (
        <div>
          <ul
            className="f-dropdown dropdown"
            ref={element => (this.dropdown = element)}
            style={this.state.dropdownStyle}
            onKeyDown={this.handleKeyDown}
          >
            <li className="interpretation-selector-search">
              <i className="icon-search" />
              <input
                type="text"
                ref={element => (this.searchInput = element)}
                placeholder={this.props.placeholder}
                onChange={this.filterList}
                autoFocus
              />
            </li>

            {this.props.errorMessage ? this.getError() : this.getDropdown()}
          </ul>
          <div className="backdrop" style={this.getBackdropStyle()} onClick={this.close} />
        </div>
      );
    } else {
      return <span />;
    }
  },
});
