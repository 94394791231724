import AppConfig from "@storyboards/appConfig";

angular
  .module("acl.common.config", [])
  .constant("AppConfig", AppConfig)
  .run(($location, HighbondNavigationService) => {
    let locale = $location.search()["locale"];
    const data = HighbondNavigationService.getGlobalNavPropsSync();
    AppConfig.highbondNavBarProps = data;

    if (!locale) {
      locale = data.locale;
    }
    AppConfig.language = locale || "en";
    document.documentElement.setAttribute("lang", AppConfig.language);
  });
