export const Environments = {
  DEV: "development",
  STAGING: "staging",
  PREPROD: "preprod",
  PRODUCTION: "production",
};
export default class Environment {
  static getBaseUrl() {
    return window.location.href;
  }

  static getEnvironment() {
    const baseUrl = this.getBaseUrl();
    const matchLocal =
      baseUrl.match(/^(http:\/\/localhost)([^/]*)/) || baseUrl.match(/^(http:\/\/(.*)\.highbond.local)([^/]*)/);
    const matchNgrok = baseUrl.match(/^https:\/\/(.*)\.ngrok.io\/(.*)$/);

    if (matchLocal || matchNgrok) {
      /* Development or Screener Tunnel*/
      return Environments.DEV;
    } else {
      const match = baseUrl.match(/^(https?:[^\.]+)\.([^\.]+)\.([^/]+)/);
      if (!match) {
        throw `Unable to parse window.location.href URL: ${location}`;
      }

      const urlSuffix = match[3];
      const [, , , subDomain] = urlSuffix.match(
        /^(highbond(-gov|-gov2|-gov3|-mil)?|diligentoneplatform)(-[\w-]+)?\.(com|mil)/
      );

      const devStageSubDomains = ["-s3", "-dev", "-dev-gov", "-s1", "-staging", "-staging-gov", "-staging-mil"];

      if (devStageSubDomains.includes(subDomain)) {
        /* Playground or Staging */
        return Environments.STAGING;
      } else if (subDomain === "-s2" || subDomain === "-preprod") {
        /* Pre-production */
        return Environments.PREPROD;
      } else {
        /* production */
        return Environments.PRODUCTION;
      }
    }
  }

  static isPicassoBackend() {
    const baseUrl = this.getBaseUrl();
    const matchLocal = baseUrl.match(/^(http:\/\/localhost)([^/]*)/);
    const matchNgrok = baseUrl.match(/^https:\/\/(.*)\.ngrok.io\/(.*)$/);

    if (matchLocal || matchNgrok) {
      return false;
    } else {
      const match = baseUrl.match(/^(https?:[^\.]+)\.([^\.]+)\.([^/]+)/);
      if (!match) {
        throw `Unable to parse window.location.href URL: ${location}`;
      }

      const isPicassoBackend = match[2].match(/^(storyboards|picasso)(-..)?$/);
      return !!isPicassoBackend;
    }
  }
}

export const ENV = Environment.getEnvironment();
export const IS_BACKEND_PICASSO = Environment.isPicassoBackend();

export const IS_PRODUCTION_LIKE = [Environments.PREPROD, Environments.PRODUCTION].includes(ENV);
