(() => {
  let aclBoardNotification = {
    restrict: "E",
    replace: true,
    bindings: {
      message: "<",
      type: "<",
    },
    controllerAs: "boardNotification",
    template: `<div
      class="acl-row storyboard-board__notification" ng-class="boardNotification.deletedBoard">
      <div class="acl-notification-msg">
        <i class="acl-notification-icon" ng-class="boardNotification.icon"></i>
        <p class="acl-notification-msg__body">
          {{boardNotification.message}}
        </p>
      </div>
    </div>`,
    controller: BoardNotificationController,
  };

  function BoardNotificationController() {
    "ngInject";
    let boardNotification = this;
    boardNotification.icon = "";
    if (boardNotification.type === "404" || boardNotification.type === "INTERPRETATION_DELETED") {
      boardNotification.icon = "acl-i-exclamation-circle";
      boardNotification.deletedBoard = "deletedBoard";
    } else {
      boardNotification.icon = "acl-i-info-circle";
      boardNotification.deletedBoard = "";
    }
  }

  angular.module("acl.storyboard.board").component("aclBoardNotification", aclBoardNotification);
})();
