import DataRequester from "@viz-ui/services/dataRequester/dataRequester";

class FieldValueManager {
  timeout = 30000; //Fix-Me - Needs to read this values from the AppConfig.

  getCacheKey(requestData) {
    const keyData = {
      fieldName: requestData.fieldName,
      search: requestData.search,
    };

    return JSON.stringify(keyData);
  }

  loadValues({ tableId, fieldName, fieldType, search, timezoneOffset }, position = 0) {
    return DataRequester.getInstance("fieldValuesRequest")
      .request(this.timeout, tableId, fieldName, fieldType, search, timezoneOffset, position)
      .then(rawData => {
        let values;
        let count;

        if (rawData.result) {
          values = rawData.result.data;
          count = rawData.result.count && rawData.result.count >= 0 ? rawData.result.count : null;
        }

        return {
          values,
          count,
        };
      });
  }
}

export default new FieldValueManager();
