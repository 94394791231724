import statisticsDataConfig from "./statisticsDataConfig.component";

angular
  .module("acl.visualizer.statistics.dataConfig", [
    "acl.common.localize",
    "acl.visualizer.dataModel",
    "acl.visualizer.multiSelect",
    "templates-visualizer",
  ])
  .component("aclStatisticsDataConfig", statisticsDataConfig);
