import i18n from "@viz-ui/i18n/i18n";

const timePeriods = [
  {
    displayName: i18n.t("_Chart.Metrics.Period.Daily.Label_"),
    fieldName: "day",
  },
  {
    displayName: i18n.t("_Chart.Metrics.Period.Monthly.Label_"),
    fieldName: "month",
  },
  {
    displayName: i18n.t("_Chart.Metrics.Period.Quarterly.Label_"),
    fieldName: "quarter",
  },
  {
    displayName: i18n.t("_Chart.Metrics.Period.Yearly.Label_"),
    fieldName: "year",
  },
];

class MetricTimePeriods {
  getMetricTimePeriods() {
    return timePeriods;
  }
}

export default new MetricTimePeriods();
