import objectValues from "lodash/values";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";

var aclValueFilter = {
  restrict: "E",
  bindings: {
    filterDisabled: "<",
    field: "<",
    fieldFormatIdentifier: "<",
    onIsLoadingChanged: "&",
    onToggle: "&",
    prevSelectedValues: "<",
    selectedValues: "<",
    tableId: "<",
    indeterminateValues: "<",
    storyboardIndicatorValues: "<",
    selectAllState: "<",
    onSelectAllClick: "&",
  },
  controllerAs: "valueFilter",
  controller: ValueFilterController,
  templateUrl: "visualizer/js/modules/core/valueFilter/valueFilter.tpl.html",
};

function ValueFilterController(
  $scope,
  $filter,
  AppConfig,
  AsyncCallManager,
  ChartDataSorter,
  FieldValueManager,
  FieldValueStore,
  FilterValueAdapter,
  Localize
) {
  "ngInject";

  const EAGER_LOADING_PAGE_SIZE = 100;

  let searchValue;
  let valueFilter = this;
  let dataSource;
  let doLoad;
  let eagerLoadedValues = [];
  let currEagerLoadingDisplayLimit;
  valueFilter.isDataLoading = true;
  $scope.migrateVizQuickFilter = AppConfig.features.migrateVizQuickFilter;

  valueFilter.$onInit = () => {
    updateDataSource();
  };

  valueFilter.$onChanges = changeObj => {
    if (changeObj.field) {
      searchValue = null;
      updateDataSource();
    }
  };

  valueFilter.isFilterDisabled = () => valueFilter.filterDisabled || (dataSource && dataSource.isFailed());

  valueFilter.handleToggle = (field, value, totalLoadedValuesLength, filterApplied, flipperSelectAll) => {
    if (valueFilter.onToggle) {
      valueFilter.onToggle({ field, value, totalLoadedValuesLength, filterApplied, flipperSelectAll });
    }
  };

  valueFilter.handleSelectAllClick = (
    field,
    action,
    visibleValues,
    totalLoadedValuesLength,
    filterApplied,
    selectAllStatePriorSearch,
    flipperSelectAll
  ) => {
    if (valueFilter.onSelectAllClick) {
      valueFilter.onSelectAllClick({
        field,
        action,
        visibleValues,
        totalLoadedValuesLength,
        filterApplied,
        selectAllStatePriorSearch,
        flipperSelectAll,
      });
    }
  };

  valueFilter.showLoadMoreButton = () => {
    if (isLazyLoadingEnabled()) {
      return dataSource && dataSource.hasMoreValues();
    }
    return currEagerLoadingDisplayLimit < getEagerLoadedValues().length;
  };

  valueFilter.handleLoadMoreClick = () => {
    if (isLazyLoadingEnabled()) {
      load();
    } else {
      currEagerLoadingDisplayLimit += EAGER_LOADING_PAGE_SIZE;
      updateFromEagerLoadedValues();
    }
  };

  valueFilter.handleSearchChange = value => {
    searchValue = value;

    if (isLazyLoadingEnabled()) {
      updateDataSource();
    } else {
      currEagerLoadingDisplayLimit = EAGER_LOADING_PAGE_SIZE;
      updateFromEagerLoadedValues();
    }
  };

  function updateDataSource() {
    let field = valueFilter.field;
    if (field) {
      valueFilter.isDataLoading = true;
      let requestData = getRequestData(field);

      if (isRequestDataValid(requestData)) {
        dataSource = FieldValueStore.getDataSource(FieldValueManager, requestData);
        dataSourceChanged();
      }
    }
  }

  function getRequestData(field) {
    const timezoneOffset = GlobalValueFormatter.getTimezoneOffset();
    let requestData = {
      fieldName: field.fieldName,
      fieldType: field.type,
      tableId: valueFilter.tableId,
    };

    if (isLazyLoadingEnabled()) {
      requestData.search = searchValue;
      requestData.timezoneOffset = timezoneOffset ? timezoneOffset.utcOffsetString() : undefined;
    }

    return requestData;
  }

  function isRequestDataValid(requestData) {
    return !objectValues(requestData).includes(undefined);
  }

  function dataSourceChanged() {
    if (!isLazyLoadingEnabled()) {
      currEagerLoadingDisplayLimit = EAGER_LOADING_PAGE_SIZE;
    }

    if (!valueFilter.field || !valueFilter.field.isQuickFilterable) {
      valueFilter.values = [];
      valueFilter.errorMessage = null;
      return;
    }

    if (dataSource.isLoaded()) {
      handleLoadedValues(dataSource.valuesRef(), dataSource.countRef());
    } else {
      load();
    }
  }

  function load() {
    onIsLoadingChanged(true);

    doLoad().then(data => {
      handleLoadedValues(data.values, data.count);
      valueFilter.errorMessage = null;
      UsageTracker.mark("valueFilter.loaded");
      onIsLoadingChanged(false);
    }, handleLoadError);
  }

  doLoad = AsyncCallManager.resolveOnlyLastCallTo(() => dataSource.load());

  function handleLoadedValues(valueModels, count) {
    // @TODO update this to utilize model when new multiselect component is used.
    let values = FilterValueAdapter.serializeFilterValues(valueModels);

    if (isLazyLoadingEnabled()) {
      valueFilter.values = values;
    } else {
      eagerLoadedValues = sortValues(values);
      updateFromEagerLoadedValues();
    }
    valueFilter.totalValuesCount = count;
    valueFilter.isDataLoading = false;
  }

  function sortValues(values) {
    return ChartDataSorter.sortChartDataByPropertyName(values, "value", valueFilter.field.type);
  }

  function updateFromEagerLoadedValues() {
    if (valueFilter.field) {
      let result = getEagerLoadedValues();

      valueFilter.values = $filter("aclCheckboxFilterLimitTo")(
        result,
        currEagerLoadingDisplayLimit,
        valueFilter.prevSelectedValues
      );
    } else {
      valueFilter.values = [];
    }
  }

  function getEagerLoadedValues() {
    if (valueFilter.field) {
      return $filter("aclFilterLocalized")(
        eagerLoadedValues,
        searchValue,
        valueFilter.fieldFormatIdentifier,
        valueFilter.field.fieldName
      );
    }
    return [];
  }

  function handleLoadError(error) {
    valueFilter.values = [];
    valueFilter.errorMessage = Localize.getLocalizedString(
      "_Filter.Values.Load.Error." + error + "_",
      "_Filter.Values.Load.Error_"
    );
    onIsLoadingChanged(false);

    if (error !== AsyncCallManager.CALL_DROPPED) {
      throw error;
    }
  }

  function onIsLoadingChanged(newValue) {
    if (valueFilter.onIsLoadingChanged) {
      valueFilter.onIsLoadingChanged({ isLoading: newValue });
    }
  }

  function isLazyLoadingEnabled() {
    return AppConfig.features.valueLazyLoading;
  }
}

export default aclValueFilter;
