export default class TableIndexMap {
  constructor(cacheKeys) {
    this._cacheKeys = cacheKeys;
    this._indexMap = new Map();
    cacheKeys.forEach(key => {
      this._indexMap.set(key, 0);
    });
  }

  getIndex(cacheKey) {
    return this._indexMap.get(cacheKey);
  }

  incrementIndex(cacheKey) {
    let currIndex = this._indexMap.get(cacheKey);
    this._indexMap.set(cacheKey, currIndex + 1);
  }
}
