import ConditionalCriteriaOperators from "@viz-ui/services/quickMenu/conditionalFormat/conditionalCriteriaOperatorsService";

class AggregatorArgsValidator {
  isValid(args) {
    if (!ConditionalCriteriaOperators.isValidOperator(args.operator())) {
      return false;
    }

    const argumentCount = ConditionalCriteriaOperators.getArgumentCount(args.operator());
    if (Array.isArray(args.values())) {
      return args.values().length === argumentCount;
    }

    return !args.values() && argumentCount === 0;
  }
}

export default new AggregatorArgsValidator();
