import ConditionalFilter from "@viz-ui/models/storyboardFilter/conditionalFilter";
import Conditional from "@viz-ui/components/stbGlobalFilter/conditional/conditional";

const aclCriteriaFilter = {
  restrict: "E",
  bindings: {
    canValidate: "<",

    fieldName: "<",
    fieldFormatIdentifier: "<",

    isDisabled: "<",
    isRemoveHighlighted: "<",

    onOperatorChange: "&",
    onValue1Change: "&",
    onValue2Change: "&",

    selectedOperator: "<",
    value1: "<",
    value2: "<",
  },
  controllerAs: "criteriaFilter",
  controller: CriteriaFilterController,
};

function CriteriaFilterController($element, $scope, AppConfig) {
  "ngInject";

  let criteriaFilter = this;
  let conditionalFilterModel = new ConditionalFilter();

  let componentRef;

  criteriaFilter.$onChanges = changeObj => {
    if (changeObj.selectedOperator) {
      conditionalFilterModel.operator(changeObj.selectedOperator.currentValue);
    }

    if (changeObj.value1) {
      conditionalFilterModel.value1(changeObj.value1.currentValue);
    }

    if (changeObj.value2) {
      conditionalFilterModel.value2(changeObj.value2.currentValue);
    }

    if (criteriaFilter.fieldName) {
      const componentProps = getProps();
      const conditionalComponent = React.createElement(Conditional, componentProps);
      unmountComponent(changeObj);
      componentRef = ReactDOM.render(conditionalComponent, $element[0]);
    }
  };

  function unmountComponent(changeObj) {
    const operator = changeObj.selectedOperator && changeObj.selectedOperator.currentValue;
    const value1 = changeObj.value1 && changeObj.value1.currentValue;
    const value2 = changeObj.value2 && changeObj.value2.currentValue;
    const isEmptyFilter = !operator && !value1 && !value2;
    if (isEmptyFilter && componentRef && $element[0]) {
      ReactDOM.unmountComponentAtNode($element[0]);
    }
  }

  function getProps() {
    return {
      canValidate: criteriaFilter.canValidate,
      fieldFormatIdentifier: criteriaFilter.fieldFormatIdentifier,
      fieldName: criteriaFilter.fieldName,
      filter: conditionalFilterModel,
      flipHasBlankFilter: AppConfig.features.hasBlankFilter,
      isDisabled: criteriaFilter.isDisabled,
      onFilterChange: handleFilterChange,
    };
  }

  function handleFilterChange(property, newValue) {
    $scope.$apply(() => {
      switch (property) {
        case "operator":
          criteriaFilter.onOperatorChange({ operator: newValue });
          break;
        case "value1":
          criteriaFilter.onValue1Change({ value: newValue });
          break;
        case "value2":
          criteriaFilter.onValue2Change({ value: newValue });
          break;
      }
    });
  }
}

export default aclCriteriaFilter;
