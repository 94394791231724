import throttle from "lodash/throttle";
import pubsub from "pubsub-js";

export default function slidingNavTabs() {
  return {
    restrict: "E",
    templateUrl: "visualizer/js/modules/core/slidingNavTabs/slidingNavTabs.tpl.html",
    scope: {
      disableAllTabs: "<",
      tabs: "<",
      isFilterPanelOpen: "<",
      isConfigPanelOpen: "<",
      isProcessPanelOpen: "<",
      selectedTabIndex: "<",
      tabProps: "<",
      tabChanged: "&",
      isTabUpdated: "<",
      isCopyInterpretationOpen: "<",
      fieldFormatIdentifier: "<",
    },
    controller: SlidingNavTabsController,
  };
}

function SlidingNavTabsController(
  $scope,
  $window,
  ChartService,
  EventService,
  DataModel,
  $timeout,
  AppConfig,
  TabStateService
) {
  "ngInject";
  const SCROLL_DIRECTION_LEFT = -1;
  const SCROLL_DIRECTION_RIGHT = 1;
  const TAB_CONTENT_REDRAW_DELAY = 300;

  let tabsContainer;
  let leftArrow;
  let rightArrow;
  const panelWidth = 350;
  const processPanelWidth = 440;
  const copyInterpretationPanelWidth = 440;
  let resizeTimer = null;
  let currentTotalPanelsWidth;
  // Slow down the calls to this function
  const throttledUpdateTabsClasses = throttle(updateTabsClasses, 500);

  // Tab reordering variables
  let tabsOrderChanged = false;
  let oldTabIds = [];
  let prevSelectedTab;

  const eventService = EventService.register("slidingNavTabs.SlidingNavTabsController", $scope);

  let unsubTableDataLoaded;

  if (!initTabScrolling()) {
    unsubTableDataLoaded = eventService.subscribe("tabsInitialized", initTabScrolling);
  }

  // Added to load chartBaseContainer instead of chartBase.controller
  if (AppConfig.features.migrateChartBaseController) {
    // TODO: Migrate below code to generic function, once pieChart service is migrated. Need to replace `isReactChart()` with this function and call it here.
    const migratedCharts = {
      LineChart: AppConfig.features.migrateLineChart,
      BarChart: AppConfig.features.migrateBarChart,
      MapChart: AppConfig.features.migrateMapchart,
      PieChart: AppConfig.features.migratePieChart,
      BubbleChart: AppConfig.features.migrateBubbleChart,
      StackedAreaChart: AppConfig.features.migrateStackedAreaChart,
      CombinationChart: AppConfig.features.migrateCombinationChart,
      Treemap: AppConfig.features.migrateTreemapChart,
      Heatmap: AppConfig.features.migrateHeatMapChart,
      StatisticsViz: AppConfig.features.migrateStatisticsChart,
      SummaryTable: AppConfig.features.migrateSummaryTableChart,
    };
    $scope.showChartBaseContainer = tabIndex => migratedCharts[$scope.tabs[tabIndex].data.vizType];

    // TODO: below angular services are passed as props to chartBaseContainer, should be replaced by equivalent react service in the future
    $scope.dataModel = DataModel;
    $scope.appConfig = AppConfig;
    $scope.eventService = EventService;
    $scope.tabStateService = TabStateService;

    // TODO: Need to remove this event in future. Used to keep angular and react chartServices in sync
    eventService.subscribe("react.chart.setGlobalChartElementsColorsTableByKey", (e, key, color) => {
      ChartService.setGlobalChartElementsColorsTableByKey(key, color);
    });
  }

  $scope.isTabsArrowVisible = false;
  $scope.isLeftArrowDisable = false;
  $scope.isRightArrowDisable = false;

  $scope.tabHasError = {};
  DataModel.subscribe("visualizations", updateTabErrorState);
  DataModel.subscribe("table.fields", updateTabErrorState);

  function updateTabErrorState() {
    const visualizations = DataModel.visualizations();
    visualizations.forEach((viz, vizIndex) => {
      $scope.tabHasError[vizIndex] = ChartService.chartHasError(vizIndex);
    });
  }

  $scope.$watch("selectedTabIndex", selectedTabIndex => {
    setContentWidthStyle(true);
    scrollToTab(selectedTabIndex);
    $scope.activeTabIndex = selectedTabIndex || 0;
  });

  $scope.$on("$destroy", function() {
    eventService.unregister();
  });

  $scope.$watch(
    "tabs",
    function saveTabOrder(_, oldTabs) {
      oldTabIds = oldTabs.map(tab => tab.id);
      throttledUpdateTabsClasses();
    },
    true
  );

  let getTotalPanelsWidth = () => {
    let panelsWidth = 0;

    if ($scope.isProcessPanelOpen) {
      panelsWidth += processPanelWidth;
    } else if ($scope.isCopyInterpretationOpen) {
      panelsWidth += copyInterpretationPanelWidth;
    } else {
      if ($scope.isConfigPanelOpen) {
        panelsWidth += panelWidth;
      }
      if ($scope.isFilterPanelOpen) {
        panelsWidth += panelWidth;
      }
    }

    return panelsWidth;
  };

  let setContentWidthStyle = force => {
    if (resizeTimer) {
      $timeout.cancel(resizeTimer);
    }
    let totalPanelsWidth = getTotalPanelsWidth();
    if (totalPanelsWidth !== currentTotalPanelsWidth || force) {
      // todo redraw event should be specific to tab
      resizeTimer = $timeout(function() {
        currentTotalPanelsWidth = totalPanelsWidth;
        let vizMainPanelWidth = angular.element(".visualizer__main-panel").width();
        $scope.contentWidthStyle = { width: totalPanelsWidth > 0 ? vizMainPanelWidth - totalPanelsWidth : "auto" };
        if (DataModel.interpretation.currentTabIndex() <= 0) {
          $timeout(() => {
            $scope.$broadcast("tableRedraw");
          });
        } else {
          $timeout(() => {
            if (isReactChart()) {
              pubsub.publish("chartRedraw");
            } else {
              $scope.$broadcast("chartRedraw");
            }
          });
        }
        resizeTimer = null;
      }, TAB_CONTENT_REDRAW_DELAY);
    }
  };

  eventService.subscribe("windowResizeEvent", () => {
    setContentWidthStyle(true);
  });

  eventService.subscribe("panelsStateChange", () => {
    setContentWidthStyle();
  });

  $scope.sortableOptions = {
    axis: "x",
    items: "dd.visualizationTab:not(:first)",
    update: function() {
      tabsOrderChanged = true;
    },
    stop: function() {
      if (tabsOrderChanged) {
        let newTabIds = $scope.tabs.map(tab => tab.id);
        let mapping = getVisualizationMapping(oldTabIds, newTabIds);
        DataModel.reorderVisualizations(mapping);

        tabsOrderChanged = false;
        eventService.publish("tabs.reordered", DataModel.interpretation.currentTabIndex());
      }
    },
  };

  $scope.tabClicked = (e, tabIndex) => {
    const target = e.currentTarget;
    const isDisabled = target && target.classList.contains("disabled");
    if (!isDisabled) {
      $scope.activeTabIndex = tabIndex;
      $scope.tabChanged({ tabIndex });
    }
  };

  $scope.tabKeyDown = (e, tabIndex) => {
    if (e.shiftKey) return;
    const target = e.currentTarget;
    switch (e.keyCode) {
      case 9:
        e.preventDefault();
        if ($scope.isTabsArrowVisible) document.querySelector("#addChartBtn").focus();
        else document.querySelector(".tab__navigation-arrows button.tabs__arrow.tabs__arrow--left").focus();
        break;
      case 13:
      case 32:
        $scope.activeTabIndex = tabIndex;
        $scope.tabClicked(e, tabIndex);
        break;
      case 37:
        if (target.previousElementSibling) target.previousElementSibling.focus();
        break;
      case 39:
        if (target.nextElementSibling) target.nextElementSibling.focus();
        break;
    }
  };

  function getVisualizationMapping(fromTabs, toTabs) {
    let fromVisualizations = fromTabs.slice(1);
    let toVisualizations = toTabs.slice(1);
    return getMapping(fromVisualizations, toVisualizations);
  }

  function getMapping(from, to) {
    return from.map(elem => to.indexOf(elem));
  }

  // Functionality for tab scrolling.
  function initTabScrolling() {
    tabsContainer = $(".tabs__wrapper");
    leftArrow = $(".tabs__arrow--left");
    rightArrow = $(".tabs__arrow--right");

    if (!(tabsContainer.length && leftArrow.length && rightArrow.length)) {
      // Couldn't find the arrows so none of this will work.. bail out.
      return false;
    }

    if (unsubTableDataLoaded) {
      // Don't need to init again.
      unsubTableDataLoaded();
    }

    // Call when the tabs container is scrolled
    tabsContainer.scroll(throttledUpdateTabsClasses);

    // Calls when whole window is resized
    $($window).on("resize", throttledUpdateTabsClasses);

    performScroll(leftArrow, SCROLL_DIRECTION_LEFT);
    performScroll(rightArrow, SCROLL_DIRECTION_RIGHT);

    // Scroll tabs horizontally when using vertical scroll wheel
    tabsContainer.mousewheel(function(event) {
      this.scrollLeft -= event.deltaY;
    });

    // After initialize update classes
    throttledUpdateTabsClasses();
    // Ensure latest selected tab is in view.
    scrollToTab();

    return true;
  }

  function performScroll(arrow, direction) {
    let scrolling;

    if (angular.isDefined(tabsContainer)) {
      arrow.click(function(e) {
        const containerWidth = tabsContainer.width();
        const maxScrollPosition = tabsContainer[0].scrollWidth - containerWidth;
        const currentScrollPosition = tabsContainer.scrollLeft();
        const arrowWidth = arrow.outerWidth() + 2;

        // Will start scroll to with current scroll value.
        scrolling = currentScrollPosition;
        // tabContainer's width. This is the display width.
        scrolling += containerWidth * direction;
        // Adjusting little extra padding to avoid cutoff any tab
        scrolling -= direction * (arrowWidth * 2);

        // Determine whether its maximum scroll value is scrolled in the past
        if (direction > 0) {
          scrolling = Math.min(scrolling, maxScrollPosition);
        } else if (direction < 0) {
          scrolling = Math.max(scrolling, 0);
        }

        // Animate it to its new scroll location that best presents the tab
        tabsContainer.animate({ scrollLeft: scrolling }, 800);
        e.stopPropagation();
      });
    }
  }

  function updateTabsClasses() {
    let maxScroll;
    let scroll;
    const ieBuffer = 1;

    if (
      angular.isUndefined(tabsContainer) ||
      angular.isUndefined(leftArrow) ||
      angular.isUndefined(rightArrow) ||
      !(tabsContainer.length && leftArrow.length && rightArrow.length)
    ) {
      return;
    }

    $timeout(() => {
      maxScroll = tabsContainer[0].scrollWidth - tabsContainer.outerWidth();
      scroll = tabsContainer.scrollLeft();

      // Putting some lenancy (ieBuffer) for IE which scrollWidth vs
      // outerWidth can be off by 1 shouldn't affect feel.
      if (scroll <= ieBuffer) {
        // if there is no tabs overflow, hide both arrows icon
        $scope.isTabsArrowVisible = true;
        $scope.isLeftArrowDisable = true;
      } else {
        // Enable left arrows icon when the scrolling reaches to end
        $scope.isLeftArrowDisable = false;
      }

      // Show both arrows icon if tabs overflow
      if (maxScroll - ieBuffer <= scroll) {
        // Disable right arrows icon when the scrolling reaches to end
        $scope.isRightArrowDisable = true;
      } else {
        $scope.isTabsArrowVisible = false;
        $scope.isRightArrowDisable = false;
      }
    });
  }

  function scrollToTab(tabIndex) {
    // Wait one frame so that the DOM has been updated and the tab elements are available.
    $timeout(() => {
      doScrollToTab(tabIndex);
    });
  }

  function doScrollToTab(tabIndex) {
    let tab;
    let arrowWidth;
    let tabPosition; // The position relative to the tabContainer
    let tabWidth; // Width of selected tab
    let currentScrollPosition; // tabContainer's current scrollLeft value
    let containerWidth; // tabContainer's width
    let maxScrollPosition; // Maximum value that scrollLeft can be
    let scrollTo; // The scrollLeft value the tabContainer will be

    // While the DOM isn't quite ready for scroll tabs the tab index
    // may still be changed. The tab index will be stored in prevSelectedTab
    // for when initTabScrolling makes the call without any params.
    if (angular.isUndefined(tabIndex)) {
      tabIndex = prevSelectedTab;
    } else {
      prevSelectedTab = tabIndex;
    }

    if (angular.isDefined(tabsContainer)) {
      tab = tabsContainer.find(".visualizationTab:eq(" + tabIndex + ")");
      if (tab.length === 0) {
        return;
      }
      arrowWidth = $(".tab__navigation-arrows").width() + 2; // Adding a little extra padding
      tabPosition = tab.position().left;
      tabWidth = tab.width();
      currentScrollPosition = tabsContainer.scrollLeft();
      containerWidth = tabsContainer.width();
      maxScrollPosition = tabsContainer[0].scrollWidth;

      // Will start scroll to with current scroll value.
      scrollTo = currentScrollPosition;

      // Determine if tab is full in view or could be scrolled more into view
      if (containerWidth - arrowWidth < tabPosition + tabWidth) {
        // Scroll to right

        // tab position is relative to tabContainer so need to negate the
        // tabContainer's width. This is the far right side.
        scrollTo += tabPosition - containerWidth;
        // Want the whole tab in view so must include it's width
        scrollTo += tabWidth;
        // Want to ensure its not covered by the arrow
        scrollTo += arrowWidth;
        // Determine if its scrolled past its maximum scroll value
        scrollTo = Math.min(scrollTo, maxScrollPosition);

        // Animate it to its new scroll location that best presents the tab
        tabsContainer.animate({ scrollLeft: scrollTo });
      } else if (tabPosition < arrowWidth) {
        // Scroll to left

        // tabPosition is negative value so by adding it it will scroll to the
        // left
        scrollTo += tabPosition;
        // Want to ensure it is not covered by the arrow.
        scrollTo -= arrowWidth;
        // Determine if its scrolled past its minimum scroll value
        scrollTo = Math.max(scrollTo, 0);

        // Animate to new scroll location
        tabsContainer.animate({ scrollLeft: scrollTo });
      }
    }
  }

  function isReactChart() {
    const chartType = $scope.tabs.filter(item => item.active === true).map(row => row.data.vizType)[0];
    const migratedCharts = {
      LineChart: AppConfig.features.migrateLineChart,
      BarChart: AppConfig.features.migrateBarChart,
      MapChart: AppConfig.features.migrateMapchart,
      BubbleChart: AppConfig.features.migrateBubbleChart,
      PieChart: AppConfig.features.migratePieChart,
      StackedAreaChart: AppConfig.features.migrateStackedAreaChart,
      CombinationChart: AppConfig.features.migrateCombinationChart,
      Treemap: AppConfig.features.migrateTreemapChart,
      Heatmap: AppConfig.features.migrateHeatMapChart,
      SummaryTable: AppConfig.features.migrateSummaryTableChart,
    };
    return migratedCharts[chartType];
  }
}
