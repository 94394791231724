import PasswordValidator from "password-validator";

class PasswordValidatorService {
  constructor() {
    this.pdfPasswordSchema = new PasswordValidator();
    this.initPdfPasswordSchema();
  }
  initPdfPasswordSchema = () => {
    this.pdfPasswordSchema
      .is()
      .min(6) // Minimum length 6
      .is()
      .max(32) // Maximum length 32
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits(1) // Must have at least 1 digit
      .has()
      .not()
      .spaces() // Should not have spaces
      .has()
      .symbols(1); // Must have symbol
  };
  validatePdfPassword = password => {
    return this.pdfPasswordSchema.validate(password, { list: true });
  };
}
export default new PasswordValidatorService();
