import React from "react";
import ReactQuill, { Quill } from "react-quill";
import PropTypes from "prop-types";

let aclColors = [
  "#79a551",
  "#7ec0cc",
  "#2b82bf",
  "#785cba",
  "#cd3c44",
  "#cb8133",
  "#eaba51",
  "#3f3d3c",
  "#666666",
  "#9a9a9a",
  "#d1d1d1",
  "#ffffff",
];

const toolbarTooltips = {
  font: "Font",
  size: "Size",
  header: "Style",
  bold: "Bold",
  italic: "Italic",
  underline: "Underline",
  strike: "Strikethrough",
  blockquote: "Block Quote",
  color: "Color",
  background: "Background color",
  script: {
    sub: "Subscript",
    super: "Superscript",
  },
  list: {
    ordered: "Numbered list",
    bullet: "Bulleted list",
  },
  indent: {
    "-1": "Decrease indent",
    "+1": "Increase indent",
  },
  direction: {
    rtl: "Text direction (right to left | left to right)",
    ltr: "Text direction (left ro right | right to left)",
  },
  align: "Text alignment",
  link: "Link",
  image: "Image",
  formula: "Formula",
  clean: "Clear format",
  "add-table": "Add a new table",
  "table-row": "Add a row to the selected table",
  "table-column": "Add a column to the selected table",
  "remove-table": "Remove selected table",
  help: "Show help",
};

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  palette: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
};

const defaultProps = {
  value: "",
  palette: aclColors,
  onChange: () => {},
  type: "text",
};
class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textValue: this.props.value,
    };
    aclColors = this.props.palette || aclColors;
    this.quillRef = React.createRef();
  }

  componentDidMount() {
    let toolbarElement = this.quillRef.current.editor.container.previousSibling;

    if (toolbarElement) {
      let matchesButtons = toolbarElement.querySelectorAll("button");
      for (let el of matchesButtons) {
        this.showTooltip("button", el);
      }
      //for submenus inside
      let matchesSpans = toolbarElement.querySelectorAll(".ql-toolbar > span > span");
      for (let el of matchesSpans) {
        this.showTooltip("span", el);
      }
    }
  }

  TextModule = {
    toolbar: {
      handlers: {
        link: function(value) {
          const selection = this.quill.getSelection();

          if (selection.length > 0) {
            //append selected text with http prefix for link
            const selectedContent = this.quill.getContents(selection.index, selection.length);
            const tempContainer = document.createElement("div");
            const tempQuill = new Quill(tempContainer);
            tempQuill.setContents(selectedContent);
            const selectedText = tempContainer.querySelector(".ql-editor").innerText;
            let tooltip = this.quill.theme.tooltip;
            tooltip.edit("link", `https://${selectedText}`);
          }
        },
      },
      container: [
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: aclColors }, { background: aclColors }],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link"],
        ["clean"],
      ],
    },
  };
  titleModule = {
    toolbar: {
      container: [
        [{ size: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ color: aclColors }, { background: aclColors }],
        ["clean"],
      ],
    },
  };
  getModule = () => {
    return this.props.type === "title" ? this.titleModule : this.TextModule;
  };
  formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "align",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
  ];

  handleValueChange = e => {
    this.setState({ textValue: e });
    this.props.onChange(e);
  };

  showTooltip = (which, el) => {
    let tool = "";
    if (which == "button") {
      tool = el.className.replace("ql-", "");
    } else if (which == "span") {
      tool = el.className.replace("ql-", "");
      tool = tool.substr(0, tool.indexOf(" "));
    }
    if (tool) {
      //if element has value attribute.. handling is different
      //buttons without value
      if (el.value == "") {
        if (toolbarTooltips[tool]) el.setAttribute("title", toolbarTooltips[tool]);
      }
      //buttons with value
      else if (typeof el.value !== "undefined") {
        if (toolbarTooltips[tool][el.value]) el.setAttribute("title", toolbarTooltips[tool][el.value]);
      }
      //default
      else el.setAttribute("title", toolbarTooltips[tool]);
    }
  };

  render() {
    return (
      <ReactQuill
        className={`${this.props.type}-editor-container`}
        theme="snow"
        value={this.state.textValue}
        onChange={e => {
          this.handleValueChange(e);
        }}
        modules={this.getModule()}
        formats={this.formats}
        ref={this.quillRef}
      ></ReactQuill>
    );
  }
}

export default RichTextEditor;
RichTextEditor.propTypes = propTypes;
RichTextEditor.defaultProps = defaultProps;
