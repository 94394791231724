import StatisticsService from "@viz-ui/services/statistics/statisticsService";

angular
  .module("acl.visualizer.statistics")
  .factory("StatisticsService", function($filter, DataModel, EventService, AppConfig) {
    var eventService = EventService.register("acl.visualizer.statistics.StatisticsService");
    var availableStatisticsByType = {
      character: ["uniqueValues", "blanks"],
      numeric: ["highest", "lowest", "average", "stdDeviation", "total", "zeros", "count", "positives", "negatives"],
      date: ["highest", "lowest", "blanks", "count"],
      time: ["highest", "lowest", "blanks", "count"],
      datetime: ["highest", "lowest", "blanks", "count"],
    };
    if (AppConfig.features.migrateVizServices) {
      return StatisticsService;
    }
    function createStatisticsViewConfig(fieldData) {
      return {
        chartCategory: [
          {
            displayName: fieldData.displayName,
            fieldId: fieldData.colId,
            fieldName: fieldData.fieldName,
            type: fieldData.type,
          },
        ],
      };
    }

    function loadData(config) {
      const filterConfig = DataModel.getFilterConfigDeprecated();
      eventService.publish("biView.configChanged", "StatisticsViz", DataModel.table.id(), filterConfig, config);
    }

    function formatData(rawData, fieldFormatIdentifier, fieldName) {
      var newRawData = rebuildLowestHighestValues(rawData);
      return extractValues(newRawData, fieldFormatIdentifier, fieldName);
    }

    function extractValues(data, fieldFormatIdentifier, fieldName) {
      var columns = availableStatisticsByType[data.type];
      var formattedData = [];

      for (var i in columns) {
        var col = columns[i];
        formattedData.push({
          id: col,
          label: localizeLabel(col),
          value: data[col],
          displayValue: applyFilters(data[col], col, fieldFormatIdentifier, fieldName),
        });
      }
      return formattedData;
    }

    function getFiltersMap(value, fieldFormatIdentifier, fieldName) {
      const decimal = $filter("number")(value, 2);
      const number = $filter("number")(value);
      const filterFieldFormat = $filter("aclLocalizeFieldValue")(value, fieldFormatIdentifier, fieldName);

      const filtersMap = {
        uniqueValues: number,
        blanks: number,
        count: number,
        positives: number,
        negatives: number,
        zeros: number,
        total: filterFieldFormat,
        average: filterFieldFormat,
        lowest: filterFieldFormat,
        highest: filterFieldFormat,
        range: decimal,
        stdDeviation: decimal,
      };
      return filtersMap;
    }

    function applyFilters(value, col, fieldFormatIdentifier, fieldName) {
      var filters = getFiltersMap(value, fieldFormatIdentifier, fieldName);
      var val = filters[col] || value;
      return val;
    }

    function rebuildLowestHighestValues(data) {
      var formattedData = angular.copy(data);
      if (formattedData.lowest && Array.isArray(formattedData.lowest) && formattedData.lowest.length > 0) {
        formattedData.lowest = formattedData.lowest.sort(function(a, b) {
          var aNumber = Number(a.value);
          var bNumber = Number(b.value);
          if (aNumber < bNumber) {
            return -1;
          }
          if (aNumber > bNumber) {
            return 1;
          }
          return 0;
        })[0].value;
      } else {
        formattedData.lowest = 0;
      }
      if (formattedData.highest && Array.isArray(formattedData.highest) && formattedData.highest.length > 0) {
        formattedData.highest = formattedData.highest.sort(function(a, b) {
          var aNumber = Number(a.value);
          var bNumber = Number(b.value);
          if (aNumber > bNumber) {
            return -1;
          }
          if (aNumber < bNumber) {
            return 1;
          }
          return 0;
        })[0].value;
      } else {
        formattedData.highest = 0;
      }
      return formattedData;
    }

    function localizeLabel(key) {
      var translations = {
        count: "_Chart.StatisticsViz.Count.Label_",
        blanks: "_Chart.StatisticsViz.Blanks.Label_",
        uniqueValues: "_Chart.StatisticsViz.UniqueValues.Label_",
        total: "_Chart.StatisticsViz.Total.Label_",
        average: "_Chart.StatisticsViz.Average.Label_",
        range: "_Chart.StatisticsViz.Range.Label_",
        stdDeviation: "_Chart.StatisticsViz.StdDeviation.Label_",
        positives: "_Chart.StatisticsViz.Positives.Label_",
        negatives: "_Chart.StatisticsViz.Negatives.Label_",
        zeros: "_Chart.StatisticsViz.Zeros.Label_",
        highest: "_Chart.StatisticsViz.Highest.Label_",
        lowest: "_Chart.StatisticsViz.Lowest.Label_",
      };
      return translations[key];
    }

    return {
      createStatisticsViewConfig: createStatisticsViewConfig,
      loadData,
      formatData,
    };
  });
