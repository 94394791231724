/*
 Copyright ©2014 ACL Services Ltd.
 Use of this code is governed by the ACL Software License Agreement that applies to the Software of which this code is a part:
 http://www.acl.com/pdfs/ACL_License_Agreement.pdf
 */

angular.module("acl.common.inheritController", []).factory("InheritController", function($controller) {
  "use strict";
  var inheritController = function(controllerName, scope) {
    var implementation = {};
    var childCtrlApi = {
      override: function(prop, value) {
        implementation[prop] = value;
        return childCtrlApi;
      },
    };
    angular.extend(childCtrlApi, $controller(controllerName, { $scope: scope, Implementation: implementation }));
    return childCtrlApi;
  };

  return inheritController;
});
