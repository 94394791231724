import moment from "moment";
import pick from "lodash/pick";
import values from "lodash/values";
import isEqual from "lodash/isEqual";
import Sorter from "@viz-ui/services/sorters/sorter";
import i18n from "@viz-ui/i18n/i18n";

const AGGREGATION_TYPE_MAP = { average: "avg" };

class TreemapQueryDataConverter {
  constructor(chartDataModel, vizConfig, treemapChartService) {
    this._chartDataModel = chartDataModel;
    this._vizConfig = vizConfig;
    this._treemapChartService = treemapChartService;
  }

  convertLayerRowData = (layerRowData, layerIndex, layerFields, valueFields) => {
    const layerValueFields = layerFields.slice(0, layerIndex + 1);
    const parentLayerValueFields = layerFields.slice(0, layerIndex);
    const layerValueFieldNames = layerValueFields.map(layerValueField => layerValueField.fieldName);
    const parentLayerValueFieldNames = parentLayerValueFields.map(
      parentLayerValueField => parentLayerValueField.fieldName
    );

    const layerValues = values(pick(layerRowData, layerValueFieldNames));
    const parentLayerValues = values(pick(layerRowData, parentLayerValueFieldNames));

    const layerValueParser = this.getParserForType(layerValueFields[layerIndex].type);
    const layerName = layerValueParser(layerRowData[layerValueFieldNames[layerIndex]]);

    const convertedNode = {
      id: JSON.stringify(layerValues),
      name: layerName,
    };

    if (layerIndex === 0 && !this.hasColorValue(valueFields)) {
      // If no colour by field is selected, top-level nodes are assigned colours.
      convertedNode.color = this._generateDiscreteColor({ key: layerName });
    }

    if (parentLayerValueFieldNames.length) {
      convertedNode.parent = JSON.stringify(parentLayerValues);
    }
    if (this.isLeafLayer(layerFields, layerIndex)) {
      convertedNode.value = this.getValue(valueFields, 0, layerRowData);
      if (this.hasColorValue(valueFields)) {
        convertedNode.colorValue = this.getValue(valueFields, 1, layerRowData);
      }
    }

    return convertedNode;
  };

  sortData = (layerData, layerFields) =>
    layerData.sort((a, b) => {
      var i;
      var result;
      for (i = 0; i < layerFields.length; i++) {
        const comparator = Sorter.getTypedComparator({ dataType: layerFields[i].type });
        result = comparator(a[layerFields[i].fieldName], b[layerFields[i].fieldName]);
        if (result !== 0) {
          return result;
        }
      }
      return 0;
    });

  convertToHighChartData = () => {
    let resultData = [];
    const rawData = this._chartDataModel.data();
    const layerFields = rawData.config.chartRows;
    const valueFields = rawData.config.chartValues;

    if (!this.hasColorValue(valueFields)) this.getDiscreteColorGenerator(layerFields);

    layerFields.forEach((layerField, layerIndex) => {
      const layerData = rawData.values[`layer${layerIndex}`];
      const currentLayerFields = layerFields.slice(0, layerIndex + 1);
      this.sortData(layerData, currentLayerFields);
      const convertedLayerData = layerData.map(layerRowData =>
        this.convertLayerRowData(layerRowData, layerIndex, layerFields, valueFields)
      );
      resultData = [...resultData, ...convertedLayerData];
    });

    return resultData;
  };

  getFlattenedColorValues = () => {
    const { config } = this._chartDataModel.data();
    const valueFields = config.chartValues;
    const rawData = this._chartDataModel.data().values;
    const leafLayerIndex = config.chartRows.length - 1;
    const { aggregationType } = config.chartValues[1];
    const colorValueAggFunction = AGGREGATION_TYPE_MAP[aggregationType] || aggregationType;
    const colorValueKey = this.isValueAndColorValueEqual(valueFields) ? "__value" : "__colorValue";

    if (colorValueAggFunction === "count") {
      return rawData[`layer${leafLayerIndex}`].map(value => Number(value[colorValueKey]));
    }
    return rawData[`layer${leafLayerIndex}`].map(value => Number(value[colorValueKey][colorValueAggFunction]));
  };

  getDiscreteColorGenerator = layerFields => {
    const discreteColorByFieldName = layerFields[0].fieldName;
    this._generateDiscreteColor = this._treemapChartService.getKeyColor(
      discreteColorByFieldName,
      this._vizConfig.colorMapping
    );
  };

  getParserForType = fieldType => {
    switch (fieldType) {
      case "date":
      case "datetime":
      case "time":
        return d => {
          const DATETIME_TYPE_FORMAT = {
            date: "YYYY-MM-DD",
            datetime: "YYYY-MM-DD HH:mm:ss",
            time: "HH:mm:ss",
          };
          const val = moment(d, DATETIME_TYPE_FORMAT[fieldType]).unix();
          return Number.isNaN(val) ? null : val;
        };

      case "numeric":
        return d => {
          const val = parseFloat(d);
          return Number.isNaN(val) ? null : val;
        };

      case "character":
        return d => d || i18n.t("_Filter.BlankValue.Label_");

      default:
        return () => {};
    }
  };

  hasColorValue = valueFields => valueFields.length > 1;

  isLeafLayer = (layerFields, layerIndex) => layerFields.length === layerIndex + 1;

  getValue = (valueFields, valueFieldIndex, layerRowData) => {
    const valueAggType = this.getValueFieldAggregationType(valueFields, valueFieldIndex);
    const valueKey = valueFieldIndex === 0 || this.isValueAndColorValueEqual(valueFields) ? "__value" : "__colorValue";
    return valueFields[valueFieldIndex].aggregationType === "count"
      ? Number(layerRowData[valueKey])
      : Number(layerRowData[valueKey][valueAggType]);
  };

  getValueFieldAggregationType = (valueFields, valueFieldIndex) => {
    const aggType = valueFields[valueFieldIndex].aggregationType;
    return AGGREGATION_TYPE_MAP[aggType] || aggType;
  };

  isValueAndColorValueEqual = valueFields => isEqual(valueFields[0], valueFields[1]);
}

export default TreemapQueryDataConverter;
