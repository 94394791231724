import React from "react";
import PropTypes from "prop-types";
import i18n from "@viz-ui/i18n/i18n";
import Toggle from "acl-ui/components/Toggle";

const { string, func } = PropTypes;

const mapChartToggleButton = props => {
  const { selectedItem, onChange } = props;
  const regionTab = {
    value: "Region",
    label: <span>{i18n.t("_ChartConfig.Data.Type.Region.Label_")}</span>,
  };
  const coordinatesTab = {
    value: "Coordinates",
    label: <span>{i18n.t("_ChartConfig.Data.Type.Coordinates.Label_")}</span>,
  };

  const getItems = () => {
    return [regionTab, coordinatesTab];
  };

  return (
    <Toggle
      selectedItemIds={selectedItem}
      items={getItems()}
      size={"medium"}
      selectedColor={"solid-green"}
      uppercase={false}
      onSelect={onChange}
    />
  );
};

mapChartToggleButton.propTypes = {
  selectedItem: string,
  onChange: func,
};
mapChartToggleButton.defaultProps = {
  selectedItem: "Region",
  onChange: () => {},
};

export default mapChartToggleButton;
