angular.module("acl.visualizer.multiSelect").component("aclSelectedItem", {
  templateUrl: "visualizer/js/modules/core/multiSelect/selectedItem.tpl.html",
  bindings: {
    item: "<",
  },
  controllerAs: "model",
  controller: function(Localize, DataModel) {
    const model = this;

    model.getDisplayName = () =>
      model.isValidField()
        ? model.item.displayName
        : Localize.getLocalizedString("_ChartConfig.CloseButton.DeletedOption_");

    model.isValidField = () =>
      !model.item.fieldName || model.item.type === "count" || DataModel.table.fieldExists(model.item.fieldName);
  },
});
