import logger from "@viz-ui/services/logger/logger";
import scrollmonitor from "scrollmonitor";
import debounce from "lodash/debounce";
import last from "lodash/last";

const DELAYED_RENDER_BUFFER = 2;
const DELAYED_DATA_LOADING_BUFFER = 2;

export default class ScrollMonitor {
  constructor(containerClass, watcherClass, stateChangeHandler) {
    this._containerClass = containerClass;
    this._watcherClass = watcherClass;
    this._stateChangeHandler = stateChangeHandler;

    this._containerMonitor = undefined;
    this._rowWatchers = [];

    this.priorityRenderRows = [];
    this.delayedRenderRows = [];
    this.delayedDataLoadingRows = [];
  }

  reset() {
    this._containerMonitor = undefined;
    this._rowWatchers.forEach(rowWatcher => rowWatcher.destroy());
    this._rowWatchers = [];
  }

  setup() {
    if (!this._containerMonitor) {
      let containerElement = document.getElementsByClassName(this._containerClass);
      if (containerElement.length) {
        this._containerMonitor = scrollmonitor.createContainer(containerElement[0]);
        this._setupWatchers();
      }
    }
    this._updateRowsInViewport();
  }

  _setupWatchers() {
    const watchElements = document.getElementsByClassName(this._watcherClass);
    let elementIndex;
    for (elementIndex = 0; elementIndex < watchElements.length; elementIndex++) {
      const watchElement = watchElements[elementIndex];
      const elementWatcher = this._containerMonitor.create(watchElement);
      this._rowWatchers.push(elementWatcher);
      elementWatcher.stateChange(this._updateRowsInViewport);
    }
  }

  _updateRowsInViewport = debounce(() => {
    this._updateRowsState();
    this._stateChangeHandler();
  }, 500);

  _updateRowsState() {
    this._updatePriorityRows();
    this._updateDelayedRenderRows();
    this._updateDelayedDataLoadingRows();
  }

  _updatePriorityRows() {
    logger.log(`scroll monitor update priority render rows - start`);
    this.priorityRenderRows = [];
    this._rowWatchers.forEach((watcher, rowIndex) => {
      if (watcher.isInViewport) {
        this.priorityRenderRows.push(rowIndex);
      }
    });
    this.priorityRenderRows.sort((a, b) => a - b);
    logger.log(`scroll monitor update priority render rows - end`, this.priorityRenderRows);
  }

  _updateDelayedRenderRows() {
    logger.log(`scroll monitor update delayed render rows - start`);
    let priorityLowerBoundIndex = this.priorityRenderRows[0];
    let priorityUpperBoundIndex = last(this.priorityRenderRows);
    this.delayedRenderRows = [];
    let currBufferIndex = 0;
    for (currBufferIndex = 0; currBufferIndex < DELAYED_RENDER_BUFFER; currBufferIndex++) {
      let currLowerBoundDelayedRenderRow = priorityLowerBoundIndex - (currBufferIndex + 1);
      let currUpperBoundDelayedRenderRow = priorityUpperBoundIndex + (currBufferIndex + 1);
      if (this._isValidRow(currUpperBoundDelayedRenderRow)) {
        this.delayedRenderRows.push(currUpperBoundDelayedRenderRow);
      }
      if (this._isValidRow(currLowerBoundDelayedRenderRow)) {
        this.delayedRenderRows.push(currLowerBoundDelayedRenderRow);
      }
    }
    logger.log(`scroll monitor update delayed render rows - end`, this.delayedRenderRows);
  }

  _updateDelayedDataLoadingRows() {
    logger.log(`scroll monitor update delayed data loading rows - start`);
    let priorityLowerBoundIndex = this.priorityRenderRows[0];
    let priorityUpperBoundIndex = last(this.priorityRenderRows);
    this.delayedDataLoadingRows = [];
    let currBufferIndex = 0;
    for (currBufferIndex = 0; currBufferIndex < DELAYED_DATA_LOADING_BUFFER; currBufferIndex++) {
      let currLowerBoundDelayedDataLoadingRow = priorityLowerBoundIndex - (currBufferIndex + DELAYED_RENDER_BUFFER + 1);
      let currUpperBoundDelayedDataLoadingRow = priorityUpperBoundIndex + (currBufferIndex + DELAYED_RENDER_BUFFER + 1);
      if (this._isValidRow(currUpperBoundDelayedDataLoadingRow)) {
        this.delayedDataLoadingRows.push(currUpperBoundDelayedDataLoadingRow);
      }
      if (this._isValidRow(currLowerBoundDelayedDataLoadingRow)) {
        this.delayedDataLoadingRows.push(currLowerBoundDelayedDataLoadingRow);
      }
    }
    logger.log(`scroll monitor update delayed data loading rows - end`, this.delayedDataLoadingRows);
  }

  _isValidRow(rowIndex) {
    return rowIndex >= 0 && rowIndex < this._rowWatchers.length;
  }
}
