import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import QueryScope from "@viz-ui/services/query/queryScope/queryScope";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";
import BaseDataLoader from "./baseDataLoader";
import BoardError from "../models/boardError";
import MetricBoard from "../models/metricBoard";
import globalFiltersStore from "../globalFilters/services/GlobalFiltersStore";
import { ERROR_CODES } from "./errorCode.service";
import ErrorReporter from "@viz-ui/services/common/errorReporterService";
import MetricManager from "@viz-ui/services/metrics/metricManagerService";

export default class MetricDataLoader extends BaseDataLoader {
  constructor(AppConfig, InterpretationTableDataStore, Localize, MetricDataStore, TableManager) {
    "ngInject";
    super();

    this._exceptionHandler = ErrorReporter.instance("Metrics");
    this._AppConfig = AppConfig;
    this._InterpretationTableDataStore = InterpretationTableDataStore;
    this._Localize = Localize;
    this._MetricDataStore = MetricDataStore;
    this._TableManager = TableManager;
  }

  async load(board) {
    let metricBoard = new MetricBoard(board);
    const metricId = metricBoard.metricId();
    const tableId = metricBoard.tableId();
    const queryScope = QueryScope.getScope(board);
    let metric;
    let table;
    try {
      [metric, table] = await Promise.all([
        this._loadMetric(metricId),
        this._loadTable(board.id, metricId, tableId, queryScope),
      ]);
      this._MetricDataStore.addMetric(metricId, metric);
      validateMetricFields(metric);
      metricBoard.metric(metric);
      metricBoard.table(table);
    } catch (error) {
      const boardError = this._getBoardError(error.status);
      boardError.log(this.retrieveBoardLogInfo(board));
      metricBoard.boardError(boardError);
      return metricBoard;
    }

    try {
      let metricSparkline = await this._loadMetricSparkline(metric, table, queryScope);
      return this._metricSparklineSuccessHandler(metricBoard, metricSparkline, table);
    } catch (error) {
      return this._metricSparklineErrorHandler(metricBoard, error);
    }
  }

  async _loadMetric(metricId) {
    let metric = this._MetricDataStore.getMetric(metricId);
    if (!metric) {
      metric = await MetricManager.loadMetric(metricId);
      GlobalFieldFormatMap.setFieldFormats(metricId, metric.fieldFormatMap());
    }
    return Promise.resolve(metric);
  }

  _loadMetricSparkline(metric, table, queryScope) {
    const metricId = metric.id();
    const tableId = table.id();
    const metricSparkline = this._MetricDataStore.getSparklineData(metricId);
    const globalFilters = globalFiltersStore.forQuery(tableId);
    const timezoneOffset = GlobalValueFormatter.getTimezoneOffsetForMetric(table, metric);
    if (metricSparkline) {
      return Promise.resolve(metricSparkline);
    }
    return MetricManager.loadMetricSparkline(metric, timezoneOffset, tableId, globalFilters, queryScope);
  }

  _metricSparklineSuccessHandler(metricBoard, metricSparkline, table) {
    const metricId = metricBoard.metricId();
    const boardId = metricBoard.id();
    this._MetricDataStore.addSparklineData(metricId, metricSparkline);
    this._InterpretationTableDataStore.addTable(boardId, table);

    metricBoard.metricSparkline(metricSparkline);

    return metricBoard;
  }

  _metricSparklineErrorHandler(metricBoard, error) {
    const boardError = this._getBoardError(error);
    boardError.log(this.retrieveBoardLogInfo(metricBoard));
    metricBoard.boardError(boardError);
    return metricBoard;
  }

  async _loadTable(boardId, metricId, tableId, queryScope) {
    let table = this._InterpretationTableDataStore.getTable(boardId);
    if (!table) {
      if (this._useQueryService()) {
        table = await this._TableManager.loadTable(tableId, queryScope);
      } else {
        table = await this._TableManager.loadTableForMetric(metricId);
      }
      GlobalFieldFormatMap.setFieldTypes(metricId, table.fields());
    }
    return Promise.resolve(table);
  }

  _useQueryService() {
    return this._AppConfig.features.queryService;
  }

  _getBoardError(error) {
    const exceptionHandler = this._exceptionHandler;
    const boardType = "metric";
    const errorString = error && error.toString();
    return new BoardError({ boardType }).setExceptionHandler(exceptionHandler).setErrorFromCode(errorString);
  }
}

function validateMetricFields(metric) {
  if (metric.error()) {
    const errorCode = metric.error();
    throw { status: errorCode };
  }
  if (metric.metricConfig().fieldName() === null || metric.metricConfig().timeFieldName() === null) {
    throw { status: ERROR_CODES.METRIC_FIELD_DELETED };
  }
}
