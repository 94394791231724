angular.module("acl.common.aclUiDatepicker").directive("aclUiDatepicker", function(Localize) {
  return {
    restrict: "A",
    scope: {
      aclUiDatepickerOptions: "<",
    },
    link: function(scope, element) {
      scope.aclUiDatepickerOptions = scope.aclUiDatepickerOptions || {};
      var defaultConfig = {
        timePicker: true,
        parentEl: element.parent(),
        singleDatePicker: true,
        timePickerIncrement: 1,
        showDropdowns: true,
        drops: "up",
        locale: {
          format: Localize.getLocalizedString("_Input.Datetime.Pattern.ForMomentJsParsing_"),
        },
      };
      $(element).daterangepicker({ ...defaultConfig, ...scope.aclUiDatepickerOptions });
      $(element).on("apply.daterangepicker", function() {
        scope.$apply();
      });
    },
  };
});
