let AppConfig;

function baseInterpretationPath(interpretationId) {
  return AppConfig.coneService.projectControlControlTestInterpretationPath({
    project_id: AppConfig.project_id,
    control_id: AppConfig.control_id,
    control_test_id: AppConfig.control_test_id,
    id: interpretationId,
  });
}

function baseControlTestMetricsPath() {
  return AppConfig.coneService.projectControlControlTestMetricsPath({
    project_id: AppConfig.project_id,
    control_id: AppConfig.control_id,
    control_test_id: AppConfig.control_test_id,
  });
}

function baseInterpretationsPath() {
  return AppConfig.coneService.projectControlControlTestInterpretationsPath({
    project_id: AppConfig.project_id,
    control_id: AppConfig.control_id,
    control_test_id: AppConfig.control_test_id,
  });
}

function baseDefaultInterpretationsPath() {
  return AppConfig.coneService.projectControlControlTestDefaultInterpretationPath({
    project_id: AppConfig.project_id,
    control_id: AppConfig.control_id,
    control_test_id: AppConfig.control_test_id,
  });
}

function isStoryboardAnonymous() {
  return AppConfig.isAnonymous && AppConfig.storyboard && AppConfig.storyboard.getUid();
}

class ApiPath {
  highbond = {
    getGlobalNavBarUrl: () => "/global_nav_bar",
  };

  metric = {
    getTableUrl: function(projectId, controlId, controlTestId) {
      let interpretationPath = "";
      if (projectId && controlId && controlTestId) {
        interpretationPath = AppConfig.coneService.projectControlControlTestInterpretationsPath({
          project_id: projectId,
          control_id: controlId,
          control_test_id: controlTestId,
        });
      }
      return interpretationPath + "/meta_data";
    },

    getTableForMetricUrl: function(metricId) {
      if (isStoryboardAnonymous()) {
        return AppConfig.coneService.publicStoryboardLinkMetricMetaDataPath({
          public_storyboard_link_id: AppConfig.storyboard.getUid(),
          id: metricId,
        });
      }
      return AppConfig.coneService.shortMetricMetaDataPath({ id: metricId }) + AppConfig.apiQueryString;
    },

    createMetricUrl: function() {
      return baseControlTestMetricsPath();
    },

    getMetricUrl: function(metricId) {
      if (isStoryboardAnonymous()) {
        return AppConfig.coneService.publicStoryboardLinkMetricPath({
          public_storyboard_link_id: AppConfig.storyboard.getUid(),
          id: metricId,
        });
      }

      if (AppConfig.project_id && AppConfig.control_id && AppConfig.control_test_id) {
        return baseControlTestMetricsPath() + "/" + metricId;
      }

      return (
        AppConfig.coneService.shortMetricPath({
          id: metricId,
        }) + AppConfig.apiQueryString
      );
    },

    getMetricsUrl: function() {
      return AppConfig.coneService.projectControlControlTestMetricsPath({
        project_id: AppConfig.project_id,
        control_id: AppConfig.control_id,
        control_test_id: AppConfig.control_test_id,
      });
    },

    getMetricTimelineUrl: function(metricId) {
      if (isStoryboardAnonymous()) {
        return AppConfig.coneService.publicStoryboardLinkMetricTimelinePath({
          public_storyboard_link_id: AppConfig.storyboard.getUid(),
          id: metricId,
        });
      }

      if (AppConfig.project_id && AppConfig.control_id && AppConfig.control_test_id) {
        return AppConfig.coneService.timelineProjectControlControlTestMetricsPath({
          project_id: AppConfig.project_id,
          control_id: AppConfig.control_id,
          control_test_id: AppConfig.control_test_id,
        });
      }

      if (metricId) {
        return (
          AppConfig.coneService.shortMetricTimelinePath({
            id: metricId,
          }) + AppConfig.apiQueryString
        );
      }
    },

    getMetricSummaryUrl: function() {
      return AppConfig.coneService.summaryProjectControlControlTestMetricsPath({
        project_id: AppConfig.project_id,
        control_id: AppConfig.control_id,
        control_test_id: AppConfig.control_test_id,
      });
    },
  };

  table = {
    getMetaDataUrl: function() {
      return baseInterpretationsPath() + "/meta_data";
    },

    getDataUrl: function() {
      return baseInterpretationsPath() + "/table_data";
    },

    getRecordCountUrl: function() {
      return baseInterpretationsPath() + "/recordcount";
    },

    getColumnValuesUrl: function() {
      return baseInterpretationsPath() + "/column_data";
    },

    getColumnSearchUrl: function() {
      return baseInterpretationsPath() + "/column_search";
    },

    getSummarizeDataUrl: function() {
      return baseInterpretationsPath() + "/summarize";
    },

    getStatisticsDataUrl: function() {
      return baseInterpretationsPath() + "/metrics";
    },

    getSummaryTableDataUrl: function() {
      return baseInterpretationsPath() + "/summary_table";
    },

    getTestExceptionsUrl: function() {
      return AppConfig.coneService.projectControlControlTestTestExceptionsPath({
        project_id: AppConfig.project_id,
        control_id: AppConfig.control_id,
        control_test_id: AppConfig.control_test_id,
      });
    },
  };

  saveViz = {
    getSaveVizUrl: () =>
      AppConfig.coneService.projectControlControlTestInterpretationsPath({
        project_id: AppConfig.project_id,
        control_id: AppConfig.control_id,
        control_test_id: AppConfig.control_test_id,
      }),
    getUpdateSavedVizUrl: info => baseInterpretationPath(info.savedVizId),
    getRetrieveSavedVizUrl: info => baseInterpretationPath(info.savedVizId) + "/raw",

    getSaveDefaultVizUrl: () => baseDefaultInterpretationsPath(),
    getUpdateDefaultVizUrl: () => baseDefaultInterpretationsPath(),
    getRetrieveDefaultVizUrl: () => baseDefaultInterpretationsPath(),

    getSaveVizListUrl: function() {
      return "Not supported";
    },

    getDeleteVizUrl: function(info) {
      return baseInterpretationPath(info.savedVizId);
    },
  };

  abilities = {
    getVisualizerAbilitiesUrl: function() {
      const { project_id, control_id, control_test_id } = AppConfig;
      return `/projects/${project_id}/controls/${control_id}/control_tests/${control_test_id}/visualizer_abilities`;
    },
  };

  reports = {
    getReportTypes: function(orgId) {
      const { control_test_id } = AppConfig;
      return `/v1/orgs/${orgId}/impact_report_targets/results_table/${control_test_id}`;
    },
    generateReportUrl: function(orgId, targetReportId, dispatcherId = null) {
      return dispatcherId
        ? `/v1/orgs/${orgId}/impact_reports/${targetReportId}/generate?dispatchId=${dispatcherId}`
        : `/v1/orgs/${orgId}/impact_reports/${targetReportId}/generate`;
    },
    getRecordStatuses: function(orgId) {
      return `/v1/orgs/${orgId}/record_statuses`;
    },
  };

  initialize = appConfig => {
    AppConfig = appConfig;
  };
}

export default new ApiPath();
