// FIXME: Merge this with conditionalCriteriaSelector/conditionalOperators.service.js
import i18n from "@viz-ui/i18n/i18n";

class ConditionalOperators {
  constructor() {
    //get value from appconfig
    this.hasBlankFilter = true;
  }
  getOperatorsByType(fieldType) {
    let operatorDefs = getOperatorDefs();
    return getOperatorsByType(fieldType)
      .filter(operatorName => this.hasBlankFilter || (operatorName !== "is_blank" && operatorName !== "is_not_blank"))
      .map(operatorName => {
        let def = operatorDefs.find(operatorDef => operatorDef.operator === operatorName);
        return {
          operator: def.operator,
          name: def.name,
        };
      });
  }

  getArgumentCount(operatorName) {
    let operatorDefs = getOperatorDefs();
    return operatorDefs.find(operator => operator.operator === operatorName).argumentCount;
  }

  isValid(operatorName, fieldType) {
    let operatorsByType = getOperatorsByType(fieldType);
    return operatorsByType.includes(operatorName);
  }
}

let getOperatorDefs = () => [
  { operator: ">", name: i18n.t("_CriteriaFilter.GreaterThan.Label_"), argumentCount: 1 },
  { operator: "<", name: i18n.t("_CriteriaFilter.LessThan.Label_"), argumentCount: 1 },
  {
    operator: ">=",
    name: i18n.t("_CriteriaFilter.GreaterThanAndEqual.Label_"),
    argumentCount: 1,
  },
  {
    operator: "<=",
    name: i18n.t("_CriteriaFilter.LessThanAndEqual.Label_"),
    argumentCount: 1,
  },
  { operator: "=", name: i18n.t("_CriteriaFilter.Equals.Label_"), argumentCount: 1 },
  { operator: "!=", name: i18n.t("_CriteriaFilter.NotEqual.Label_"), argumentCount: 1 },
  { operator: "between", name: i18n.t("_CriteriaFilter.Between.Label_"), argumentCount: 2 },
  { operator: "^=", name: i18n.t("_CriteriaFilter.BeginsWith.Label_"), argumentCount: 1 },
  { operator: "contain", name: i18n.t("_CriteriaFilter.Contains.Label_"), argumentCount: 1 },
  {
    operator: "!contain",
    name: i18n.t("_CriteriaFilter.NotContain.Label_"),
    argumentCount: 1,
  },
  { operator: "is_blank", name: i18n.t("_CriteriaFilter.IsBlank.Label_"), argumentCount: 0 },
  {
    operator: "is_not_blank",
    name: i18n.t("_CriteriaFilter.IsNotBlank.Label_"),
    argumentCount: 0,
  },
];

let getOperatorsByType = type => {
  let mapping = {
    character: ["^=", "contain", "!contain", "is_blank", "is_not_blank"],
    numeric: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank"],
    date: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank"],
    time: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank"],
    datetime: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank"],
    logical: ["="],
    file: ["is_blank", "is_not_blank"],
    digital_signature: ["is_blank", "is_not_blank"],
  };
  if (!mapping[type]) {
    throw new Error(`Unknown type. type=${type}`);
  }
  return mapping[type];
};

export default new ConditionalOperators();
