import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import FieldTypeChecker from "@visualizer/modules/core/fieldTypeChecker/fieldTypeChecker.service";
import DataModelFormat from "./dataModelFormatService";
import FieldFormat from "../../../models/field/fieldFormat";
import NamespacedObserver from "../../observer/namespacedObserver";
import EventService from "../../eventService/eventService";

const currentFormatVersion = "1.17";
const namespacedObserver = NamespacedObserver.getInstance();
const eventService = EventService.register("dataModel.DataModel");
const model = {
  saveViz: {
    formatVersion: currentFormatVersion,
    visualizationInfo: {
      title: "",
      summary: "",
      currentTabIndex: 0,
      linkLatestTable: false,
    },
    filterConfig: {
      filtersOpen: false,
      params: { start: 1 },
      myRecords: false,
      openStatuses: false,
    },
    visualizations: [],
    visualizationConfig: {
      colorMapping: {},
    },
    tableConfig: {
      columns: [],
      formatting: {},
    },
  },
  table: {
    metaDataLoaded: false,
    // FIXME: Collapse metadata property.
    metaData: {
      name: "",
      recordCount: 0,
      fields: {},
      hasStaticField: false,
      certificationsTable: false,
    },
    filteredRecordCount: 0,
    data: [],
    sortFields: [],
  },
  project: {
    archived: false,
  },
  hide_metadata: false,
};

const createGetterSetterObj = {
  filtersOpen: "saveViz.filterConfig.filtersOpen",
  "interpretation.title": "saveViz.visualizationInfo.title",
  "interpretation.linkLatestTable": "saveViz.visualizationInfo.linkLatestTable",
  "interpretation.summary": "saveViz.visualizationInfo.summary",

  visualizations: "saveViz.visualizations",
  "visualizationConfig.colorMapping": "saveViz.visualizationConfig.colorMapping",

  // FIXME: The data model shouldn't be aware of the implementation-specific IDs at all.
  "table.tableId": "saveViz.visualizationInfo.tableId",
  "table.tableName": "saveViz.visualizationInfo.tableName",
  "table.analyticName": "saveViz.visualizationInfo.analyticName",

  "filterConfig.sortFieldId": "saveViz.filterConfig.sortField.field",
  "filterConfig.sortOrder": "saveViz.filterConfig.sortField.order",
  "filterConfig.jobId": "saveViz.filterConfig.jobId",
  "filterConfig.myRecords": "saveViz.filterConfig.myRecords",
  "filterConfig.openStatuses": "saveViz.filterConfig.openStatuses",

  "table.metadataLoaded": "table.metaDataLoaded",
  "table.displayName": "table.metaData.name",
  "table.recordCount": "table.metaData.recordCount",
  "table.hasStaticField": "table.metaData.hasStaticField",
  "table.certificationsTable": "table.metaData.certificationsTable",
  "table.data": "table.data",
  "table.sortFields": "table.sortFields",
  "table.model": "table.model",

  "project.archived": "project.archived",
};

let tableIdForWatch;

function filterTypeMismatch(filter) {
  const { fields } = model.table.metaData;
  const fieldTypeChecker = FieldTypeChecker.create().setFieldsFromMap(fields);
  return fieldTypeChecker.filterTypeMismatch(filter);
}

const DataModel = {
  formatVersion: () => model.saveViz.formatVersion,

  subscribe: (propertyName, callback) => namespacedObserver.subscribe(propertyName, callback),

  getFilterConfigDeprecated: () => {
    // @TODO Need to remove all use of this function to use //getFilterConfig`
    removeInvalidSortField(model.saveViz);
    return model.saveViz.filterConfig;
  },

  getFilterConfig: () => {
    removeInvalidSortField(model.saveViz);
    return cloneDeep(model.saveViz.filterConfig);
  },

  // TODO: can dependencies be further namespaced for separate parts of .table?
  // FIXME: Get rid of this and the need for it.
  getTableObj: () => model.table,

  // FIXME: Get rid of this and the need for it.
  getTableConfigObj: () => model.saveViz.tableConfig,

  // FIXME: Get rid of this and the need for it.
  setTableConfigObj: x => {
    model.saveViz.tableConfig = Object.assign({}, cloneDeep(x));
    return model.saveViz.tableConfig;
  },

  fromSaveViz: saveViz => {
    const updateSaveViz = deFormatDataConfig(saveViz);
    if (updateSaveViz.formatVersion !== currentFormatVersion) {
      model.saveViz = DataModelFormat.convertSaveViz(updateSaveViz, updateSaveViz.formatVersion, currentFormatVersion);
    } else {
      model.saveViz = Object.assign({}, cloneDeep(updateSaveViz));
    }
    publishAllPropertiesChanged();
    return DataModel;
  },

  toSaveViz: () => {
    let result = Object.assign({}, cloneDeep(model.saveViz));
    result = removeInvalidSortField(result);
    result = cleanUpFilterList(result);
    result = removeLazyLoadParams(result);
    return result;
  },

  toBiView: (type, config) => {
    const result = Object.assign({}, cloneDeep(model.saveViz));
    result.visualizations = [
      {
        type: type,
        title: "",
        config: { dataConfig: config },
      },
    ];
    return result;
  },

  filterErrors: filterErrors(),

  filtersValid: () => filterErrors().length === 0,

  addVisualization: viz => {
    const vizId = addVisualizationToModel(viz);
    publishPropertyChanged("visualizations");
    return vizId;
  },

  removeAllVisualizations: () => {
    changeSaveVizModel(["visualizations"], []);
    publishPropertyChanged("visualizations");
  },

  reorderVisualizations: mapping => {
    const visualizations = DataModel.visualizations();
    changeSaveVizModel(["visualizations"], []);
    const sortedViz = [];
    mapping.forEach((newIndex, index) => {
      sortedViz[newIndex] = visualizations[index];
    });

    sortedViz.forEach(viz => {
      addVisualizationToModel(viz);
    });

    publishPropertyChanged("visualizations");
  },

  visualization: function(index, visualizationConfig) {
    if (arguments.length < 2) return cloneDeep(model.saveViz.visualizations[index]);
    changeSaveVizModel(["visualizations", index], Object.assign({}, cloneDeep(visualizationConfig)));
    publishPropertyChanged("visualizations");
    return DataModel;
  },

  visualizations: () => [...model.saveViz.visualizations],

  removeVisualization: index => {
    const visualizations = [...model.saveViz.visualizations];
    visualizations.splice(index, 1);
    changeSaveVizModel(["visualizations"], visualizations);
    publishPropertyChanged("visualizations");
  },

  getTabIndexByVizId: vizId => {
    if (DataModel.isDataTableTab(vizId)) return 0;

    const visualizations = DataModel.visualizations();
    const vizIdTabIndex = visualizations.findIndex(visualization => visualization.id === vizId);
    if (vizIdTabIndex === -1) return vizIdTabIndex;

    const vizIdTabIndexOffsetByDataTab = vizIdTabIndex + 1;
    return vizIdTabIndexOffsetByDataTab;
  },

  interpretation: {
    currentTabIndex: function(tabIndex) {
      if (!arguments.length) return DataModel.getTabIndexByVizId(model.saveViz.visualizationInfo.selectedVizId);
      if (isDataTableTabIndex(tabIndex)) {
        model.saveViz.visualizationInfo.selectedVizId = null;
      } else {
        const vizIdIndex = tabIndex - 1;
        model.saveViz.visualizationInfo.selectedVizId = model.saveViz.visualizations[vizIdIndex].id;
      }
      publishPropertyChanged("interpretation.currentTabIndex");
      return DataModel.interpretation;
    },
    selectedVisualizationIndex: function(visualizationIndex) {
      if (!arguments.length) return DataModel.interpretation.currentTabIndex() - 1;
      return DataModel.interpretation.currentTabIndex(visualizationIndex + 1);
    },
    grcInfo: function(x) {
      // FIXME: Freeze this before returning.
      if (!arguments.length) return model.saveViz.visualizationInfo.grcInfo;
      if (x === undefined) {
        delete model.saveViz.visualizationInfo.grcInfo;
      } else {
        model.saveViz.visualizationInfo.grcInfo = cloneDeep(x);
      }
      publishPropertyChanged("interpretation.grcInfo");

      return DataModel.interpretation;
    },
  },

  tableConfig: {
    columns: function(x) {
      if (!arguments.length) return cloneDeep(model.saveViz.tableConfig.columns);
      changeSaveVizModel(["tableConfig", "columns"], cloneDeep(x));
      publishPropertyChanged("tableConfig.columns");
      return DataModel.tableConfig;
    },

    getFieldFormatModelsByName: () => {
      const result = new Map();
      const fieldFormatObjsByName = model.saveViz.tableConfig.formatting;
      for (const fieldName in fieldFormatObjsByName) {
        const fieldFormatObj = fieldFormatObjsByName[fieldName];
        result.set(fieldName, FieldFormat.fromJson(fieldFormatObj));
      }
      return result;
    },

    dateFormat: getFieldFormattingAccessor("dateFormat"),
    datetimeFormat: getFieldFormattingAccessor("datetimeFormat"),
    precision: getFieldFormattingAccessor("precision"),
    prefix: getFieldFormattingAccessor("prefix"),
    postfix: getFieldFormattingAccessor("postfix"),
    sign: getFieldFormattingAccessor("sign"),
    isHtml: getFieldFormattingAccessor("isHtml"),
    isRaw: getFieldFormattingAccessor("isRaw"),
    abbreviate: getFieldFormattingAccessor("abbreviate"),
    keepTrailingZeros: getFieldFormattingAccessor("keepTrailingZeros"),
    displayTime: getFieldFormattingAccessor("displayTime"),
    isURL: getFieldFormattingAccessor("isURL"),
    hasOnlyRadixSeparator: getFieldFormattingAccessor("hasOnlyRadixSeparator"),
    thousandsDelimiter: getFieldFormattingAccessor("thousandsDelimiter"),
    formattingConditions: getFieldFormattingAccessor("conditions"),

    formattingOptions: function(fieldName) {
      if (!arguments.length) {
        if (typeof model.saveViz.tableConfig !== "object" || typeof model.saveViz.tableConfig.formatting !== "object")
          return undefined;
        return Object.assign({}, cloneDeep(model.saveViz.tableConfig.formatting));
      }
      if (
        typeof model.saveViz.tableConfig !== "object" ||
        typeof model.saveViz.tableConfig.formatting !== "object" ||
        typeof model.saveViz.tableConfig.formatting[fieldName] !== "object"
      )
        return undefined;
      return Object.assign({}, cloneDeep(model.saveViz.tableConfig.formatting[fieldName]));
    },

    removeDeletedFields: () => {
      const { tableConfig } = model.saveViz;
      if (tableConfig.columns) {
        const validColumns = tableConfig.columns.filter(col => DataModel.table.fieldExists(col.fieldName));
        model.saveViz.tableConfig.columns = validColumns;
      }
    },
  },

  table: {
    id: function(x) {
      const info = model.saveViz.visualizationInfo;
      const id = {};
      if (!arguments.length) {
        if (info.tableId) id.tableId = info.tableId;
        if (info.tableName) id.tableName = info.tableName;
        if (info.analyticName) id.analyticName = info.analyticName;
        if (!isEqual(tableIdForWatch, id)) {
          tableIdForWatch = id;
          Object.freeze(tableIdForWatch);
        }
        return tableIdForWatch;
      } else {
        info.tableName = x.tableName;
        info.tableId = x.tableId;
        info.analyticName = x.analyticName;
        publishPropertyChanged("table.id");
      }
      return DataModel.table;
    },
    data: () => DataModel.table.data,

    field: function(fieldName, x) {
      if (arguments.length === 1) return cloneDeep(model.table.metaData.fields[fieldName]);
      if (x === undefined) {
        delete model.table.metaData.fields[fieldName];
      } else {
        model.table.metaData.fields[fieldName] = cloneDeep(x);
        publishPropertyChanged("table.field");
      }
      return DataModel.table;
    },

    fieldForWatch: fieldName => model.table.metaData.fields[fieldName],

    fields: function(fieldsByNameObj) {
      if (arguments.length < 1) return cloneDeep(model.table.metaData.fields);
      model.table.metaData.fields = cloneDeep(fieldsByNameObj);
      publishPropertyChanged("table.fields");
      return DataModel.table;
    },

    fieldsForWatch: () => model.table.metaData.fields,

    filteredRecordCount: function(x) {
      if (arguments.length < 1) {
        return parseInt(model.table.filteredRecordCount, 10);
      }
      model.table.filteredRecordCount = String(x);
      publishPropertyChanged("table.filteredRecordCount");
      return DataModel.table;
    },

    fieldExists: fieldName => Object.prototype.hasOwnProperty.call(model.table.metaData.fields, fieldName),

    fieldsExist: fieldNamesArray => fieldNamesArray.every(DataModel.table.fieldExists),

    isMetadataField: fieldName => {
      const metadataFields = [
        "metadata.closed_at",
        "metadata.updated_at",
        "metadata.group",
        "metadata.assignee",
        "metadata.publisher",
        "metadata.publish_date",
        "metadata.status",
        "metadata.priority",
      ];
      return metadataFields.includes(fieldName);
    },

    reset: () => {
      const { table } = model;
      table.metaData.name = "";
      table.metaData.recordCount = 0;
      table.sortFields = [];
      table.metaData.hasStaticField = false;
      table.metaDataLoaded = false;
      table.data = [];
      table.filteredRecordCount = "0";
    },

    updateRecordBy: (recordIdKey, recordId, newFieldData) => {
      const { table } = model;
      let { data } = table;
      const recordIndex = data.findIndex(record => recordId === record[recordIdKey]);
      if (recordIndex !== -1) {
        data = [...data];
        data[recordIndex] = { ...data[recordIndex], ...newFieldData };
        table.data = data;
      }
      return DataModel.table.data();
    },
  },

  filterConfig: {
    disableInvalidFilterFields: () => {
      const { filterConfig } = model.saveViz;
      if (filterConfig.filterList) {
        filterConfig.filterList.forEach(filter => {
          const isDeletedField = !DataModel.table.fieldExists(filter.name);
          const isFieldTypeChanged = filterTypeMismatch(filter);
          if (isDeletedField || isFieldTypeChanged) {
            filter.active = false;
          }
        });
      }
    },

    hasDeletedField: () => {
      const { filterConfig } = model.saveViz;
      if (filterConfig.filterList) {
        return filterConfig.filterList.some(filter => !DataModel.table.fieldExists(filter.name));
      }
      return false;
    },

    hasFieldTypeMismatch: () => {
      const { filterConfig } = model.saveViz;
      if (filterConfig.filterList) {
        return filterConfig.filterList.some(DataModel.filterConfig.filterTypeMismatch);
      }
      return false;
    },

    filterTypeMismatch: filter => {
      const { fields } = model.table.metaData;
      const fieldTypeChecker = FieldTypeChecker.create().setFieldsFromMap(fields);
      return fieldTypeChecker.filterTypeMismatch(filter);
    },

    removeDeletedSortField: () => {
      const { filterConfig } = model.saveViz;
      if (filterConfig.sortField && !DataModel.table.fieldExists(filterConfig.sortField.field)) {
        filterConfig.sortField = undefined;
      }
    },

    removeFilter: fieldName => {
      let filterList = cloneDeep(model.saveViz.filterConfig.filterList);
      filterList = filterList.filter(filter => filter.name !== fieldName);
      changeSaveVizModel(["filterConfig", "filterList"], filterList);
      publishPropertyChanged("filterConfig.filterList");
    },

    toggleMyRecords: () => {
      const { filterConfig } = model.saveViz;
      return (filterConfig.myRecords = !filterConfig.myRecords);
    },

    toggleOpenStatuses: () => {
      const { filterConfig } = model.saveViz;
      return (filterConfig.openStatuses = !filterConfig.openStatuses);
    },

    setParams: newParams => {
      const { filterConfig } = model.saveViz;
      filterConfig.params = cloneDeep(newParams);
    },

    resetParams: () => {
      const { filterConfig } = model.saveViz;
      filterConfig.params = { start: 1 };
    },
  },

  dump: () => model,

  dumpString: spacer => JSON.stringify(model, null, spacer),

  deleteRows: testExceptionIdsForDelete => {
    const data = DataModel.table.data();
    const currentRecords = data.filter(record => !testExceptionIdsForDelete.includes(record["metadata.exception_id"]));
    return DataModel.table.data(currentRecords);
  },

  deleteRowsExcept: testExceptionIdsForDelete => {
    const data = DataModel.table.data();
    const currentRecords = data.filter(record => testExceptionIdsForDelete.includes(record["metadata.exception_id"]));
    return DataModel.table.data(currentRecords);
  },

  deleteAllRows: () => DataModel.table.data([]),

  isHideMetaData: () => model.hide_metadata,

  setHideMetaData: flag => {
    model.hide_metadata = flag;
  },

  isDataTableTab: vizId => vizId === null,
};

function filterErrors() {
  return [];
}

function isDataTableTabIndex(tabIndex) {
  return tabIndex === 0;
}

function addVisualizationToModel(viz) {
  let visualizations = cloneDeep(model.saveViz.visualizations);
  if (!visualizations) visualizations = [];

  const newViz = Object.assign({}, cloneDeep(viz));
  if (newViz.title === undefined) newViz.title = "";
  if (newViz.id === undefined) newViz.id = DataModelFormat.generateVisualizationId();
  if (!isObject(newViz.config)) newViz.config = {};
  if (!isObject(newViz.config.dataConfig)) newViz.config.dataConfig = {};
  if (!isObject(newViz.config.displayConfig)) newViz.config.displayConfig = {};
  visualizations.push(newViz);
  changeSaveVizModel(["visualizations"], visualizations);
  return newViz.id;
}

function publishAllPropertiesChanged() {
  const propAsObserverEventPath = "*";
  const propForSubscriberCallback = "*";
  namespacedObserver.broadcast(propAsObserverEventPath, propForSubscriberCallback);
}

function getFieldFormattingAccessor(formattingPropName) {
  return (function(propName) {
    return function(fieldName, newValue) {
      if (arguments.length < 2) {
        if (
          typeof model.saveViz.tableConfig !== "object" ||
          typeof model.saveViz.tableConfig.formatting !== "object" ||
          typeof model.saveViz.tableConfig.formatting[fieldName] !== "object"
        )
          return undefined;
        return model.saveViz.tableConfig.formatting[fieldName][propName];
      }
      if (typeof model.saveViz.tableConfig !== "object") {
        model.saveViz.tableConfig = {};
      }
      if (typeof model.saveViz.tableConfig.formatting !== "object") {
        model.saveViz.tableConfig.formatting = {};
      }
      if (typeof model.saveViz.tableConfig.formatting[fieldName] !== "object") {
        model.saveViz.tableConfig.formatting[fieldName] = {};
      }
      changeSaveVizModel(["tableConfig", "formatting", fieldName, propName], newValue);

      publishPropertyChanged("tableConfig.formatting." + propName);

      return DataModel.tableConfig;
    };
  })(formattingPropName);
}

function removeInvalidSortField(saveViz) {
  const { sortField } = saveViz.filterConfig;
  if (
    sortField &&
    (typeof sortField.field === "undefined" ||
      sortField.field === null ||
      typeof sortField.order === "undefined" ||
      sortField.order === null)
  ) {
    saveViz.filterConfig.sortField = undefined;
  }
  return saveViz;
}

function deFormatDataConfig(saveViz) {
  const { visualizations } = saveViz;
  if (!visualizations) return saveViz;
  visualizations.forEach(({ vizType, config }, index) => {
    if (vizType === "CombinationChart") {
      saveViz.visualizations[index].config.dataConfig.chartXAxis = config.dataConfig.chartRows[0];
      delete saveViz.visualizations[index].config.dataConfig.chartRows;
      if (config.dataConfig.chartColumns && config.dataConfig.chartColumns.length) {
        saveViz.visualizations[index].config.dataConfig.chartSeries = config.dataConfig.chartColumns[0];
      }
      delete saveViz.visualizations[index].config.dataConfig.chartColumns;
    }
  });
  return saveViz;
}

function publishPropertyChanged(prop) {
  const argument = ["this." + prop].concat(Array.prototype.slice.call(arguments, 2));
  eventService.publish(...argument);
  const propAsObserverEventPath = prop;
  const propForSubscriberCallback = prop;
  namespacedObserver.broadcast(propAsObserverEventPath, propForSubscriberCallback);
}

function changeSaveVizModel(targetKeys, value) {
  let targetRef = model.saveViz;
  targetKeys.forEach((key, index) => {
    if (!targetRef) {
      targetRef = cloneDeep(model.saveViz);
    }
    if (index === targetKeys.length - 1 && !isEqual(targetRef[key], value)) {
      targetRef[key] = value;
      if (targetKeys.join(".") !== "filterConfig.filtersOpen") {
        eventService.publish("saveViz.modelChange");
      }
    } else {
      targetRef = targetRef[key];
    }
  });
}

function cleanUpFilterList(saveViz) {
  saveViz = removeEmptyFilterList(saveViz);
  saveViz.filterConfig.filterList = removeStoryboardVisualIndicatorProps(saveViz.filterConfig.filterList);
  return saveViz;
}

function removeEmptyFilterList(saveViz) {
  const { filterList } = saveViz.filterConfig;
  // when saving filters with filterList == [] backend errors out with
  // "Invalid tree format" so set to null
  if (filterList && filterList.length === 0) delete saveViz.filterConfig.filterList;
  return saveViz;
}

function removeLazyLoadParams(saveViz) {
  delete saveViz.filterConfig.params;
  return saveViz;
}

function removeStoryboardVisualIndicatorProps(filterList) {
  if (filterList && filterList.length > 0) {
    filterList = filterList.map(filterSet => {
      if ("showStoryboardIndicator" in filterSet) delete filterSet.showStoryboardIndicator;

      if (filterSet.quickFilter) {
        if ("storyboardIndicatorValues" in filterSet.quickFilter) {
          delete filterSet.quickFilter.storyboardIndicatorValues;
        }
        if ("indeterminateValues" in filterSet.quickFilter) {
          delete filterSet.quickFilter.indeterminateValues;
        }
      }

      if (filterSet.filters && filterSet.filters.length > 0) {
        filterSet.filters = filterSet.filters.map(filter => {
          if ("showStoryboardIndicator" in filter) {
            delete filter.showStoryboardIndicator;
          }
          return filter;
        });
      }
      return filterSet;
    });
  }
  return filterList;
}

//  * Takes an object where property names are chained method names and
//  * values are string paths to the relevant property of the model object.
//  */
(function createGetterSetters(defs) {
  let chainPathStr, chainPath, chainRef, chainProp, dataPath, p, prevVal;
  for (chainPathStr in defs) {
    dataPath = defs[chainPathStr].split(".");

    if (prevVal !== dataPath[0]) {
      if (!prevVal) {
        prevVal = dataPath[0];
      }
      if (chainRef) {
        DataModel[prevVal] = DataModel[prevVal]
          ? Object.assign(cloneDeep(DataModel[prevVal]), cloneDeep(chainRef))
          : Object.assign({}, cloneDeep(chainRef));
        prevVal = dataPath[0];
      }
    }

    chainPath = chainPathStr.split(".");
    chainRef = DataModel || {};
    while (chainPath.length > 1) {
      p = chainPath.shift();
      if (chainRef[p] === undefined) chainRef[p] = {};
      chainRef = chainRef[p];
    }
    chainProp = chainPath[0];

    chainRef[chainProp] = (function(_chainRef, _chainPathStr, _dataRef, _dataPath) {
      var dataProp;
      return function(newValue) {
        var path = _dataPath.slice();
        var ref = _dataRef;
        while (path.length > 1) {
          p = path.shift();
          if (ref[p] === undefined) ref[p] = {};
          ref = ref[p];
        }
        dataProp = path[0];

        if (!arguments.length) {
          // FIXME: Copy the return value if it's an object.
          return ref[dataProp];
        }

        if (_dataPath.length && _dataPath[0] === "saveViz") {
          changeSaveVizModel(_dataPath.slice(1), newValue);
        } else {
          // FIXME: Copy the new value to protect ourselves from pointer fun.
          ref[dataProp] = newValue;
        }
        publishPropertyChanged(_chainPathStr);

        return _chainRef;
      };
    })(chainRef, chainPathStr, model, dataPath);
  }
})(createGetterSetterObj);
export default DataModel;
