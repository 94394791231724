(function($){
  $.fn.extend ({
    // Fan animated group buttons. When a button is clicked
    // expand its children buttons
    //
    /*
      Usage:
        Run fanButtons() on a DOM element with class
        .js-fan-button.
    */
    fanButtons: function(options){
      // Default option object
      var defaults = { },

      // Merge default options with user provided options
      options = $.extend(defaults, options),

      $this = $(this);

      // Iterate through all the buttons that have the classname
      $this.each(function(){
        var btn = this,
            options = $(this).siblings(".js-fan-button__option"),
            timeline = new TimelineMax({ paused: true, reversed: true });

        timeline.to(btn, 0.1, {width:"20px", height:"20px", x:-10, y:-10, rotation:"+=45", ease:Sine.easeOut}, 0)
        .staggerFrom(options, 0.2, {transform:"translate(-50%, -50%)", alpha:0, scale:0.5, ease:Back.easeOut}, 0.05);

        $(this).on('click', function() {
          if (timeline.reversed()) {
            $(this).trigger('fanButtons.open');
          } else {
            $(this).trigger('fanButtons.close');
          }
          $(this).parent().toggleClass('is-active');
          timeline.reversed() ? timeline.play() : timeline.reverse();
        });

      });

      return this;
    }
  });
}(jQuery));
