angular
  .module("acl.visualizer.summaryTable")
  .controller("SummaryTableController", function(
    $scope,
    DataModel,
    SummaryTableService,
    EventService,
    InheritController
  ) {
    const summaryTable = this;

    var base = InheritController("ChartBaseController", $scope)
      .override("chartType", "SummaryTable")
      .override("defaultDisplayConfig", SummaryTableService.defaultDisplayConfig)
      .override("getChartDirectiveConfig", SummaryTableService.getChartDirectiveConfig)
      .override("populateChartConfigColumnDefs", SummaryTableService.populateChartConfigColumnDefs)
      .override("chartConfigColumnDefs", SummaryTableService.chartConfigColumnDefs)
      .override("isDataConfigParsed", true)
      .override("isValidDataConfig", SummaryTableService.isValidDataConfig);

    function getSummaryTableData(chartModel) {
      return {
        table: DataModel.table.model(),
        formattingOptions: DataModel.tableConfig.formattingOptions(),
        rawData: chartModel.data,
        columns: DataModel.tableConfig.columns(),
      };
    }

    base.initChart();

    $scope.$watch(
      "chartModel",
      chartModel => {
        summaryTable.data = getSummaryTableData(chartModel);
        summaryTable.fixedColumnsLeftCount =
          chartModel && chartModel.config && chartModel.config.dataConfig
            ? getFixedColumnsLeftCount(chartModel.config.dataConfig)
            : 0;

        if (chartModel && chartModel.config && chartModel.config.displayConfig) {
          summaryTable.displayConfig = { ...{ showTotals: true }, ...chartModel.config.displayConfig };
        }
      },
      true
    );

    summaryTable.tableId = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    summaryTable.fieldFormatIdentifier = $scope.chartViz.id;
    summaryTable.displayConfig = $scope.chartViz.config.displayConfig;

    var eventService = EventService.register("SummaryTableController", $scope);

    eventService.subscribe("dataTable.formattingChange", () => {
      if (isActiveTab()) {
        summaryTable.data = getSummaryTableData($scope.chartModel);
      } else {
        let summaryTableData = getSummaryTableData($scope.chartModel);
        setPendingSummaryTableData(summaryTableData);
      }
    });
    eventService.subscribe("dataTable.columnResized", () => {
      setPendingSummaryTableData(getSummaryTableData($scope.chartModel));
    });

    function isActiveTab() {
      return $scope.$index === DataModel.interpretation.currentTabIndex();
    }

    let pendingSummaryTableData = null;
    function setPendingSummaryTableData(newProps) {
      pendingSummaryTableData = newProps;
    }

    function updateSummaryTableData() {
      if (pendingSummaryTableData) {
        summaryTable.data = pendingSummaryTableData;
        pendingSummaryTableData = null;
      }
    }

    DataModel.subscribe("interpretation.currentTabIndex", () => {
      if (isActiveTab()) {
        updateSummaryTableData();
      }
    });

    $scope.$on("$destroy", function() {
      eventService.unregister();
    });

    function getFixedColumnsLeftCount(dataConfig) {
      const { freezeRowHeaders, chartRows } = dataConfig;
      return freezeRowHeaders && chartRows ? chartRows.length : 0;
    }
  });
