import StoryboardDataStore from "@storyboards/modules/storyboardDataStore/storyboardDataStore.service";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import BoardOrganizer from "../boardOrganizer/boardOrganizer.service";
import RowConfigManager from "../rowConfig/rowConfig.service";
import UrlFlipper from "@viz-ui/services/urlFlipper/urlFlipper";
import "@viz-ui/components/confirmationModal/confirmationModal";

(() => {
  let aclStoryboard = {
    restrict: "E",
    replace: true,
    bindings: {
      boardsRow: "<",
      editMode: "<",
      globalFilters: "<",
      onLoadMoreData: "&",
      onAddBoardToRow: "&",
      onAddBoardToPosition: "&",
      onRemoveBoard: "&",
      onBoardMove: "&",
      storyboardModel: "<",
      boardModels: "<",
      organization: "<",
    },
    controllerAs: "storyboard",
    template: getTemplate,
    controller: StoryboardController,
  };

  function StoryboardController($element, $timeout, $window, InterpretationValidator, Localize, $scope) {
    "ngInject";
    let storyboard = this;

    let prevReceiver;

    let maxBoardsPerRow = BoardOrganizer.getMaxRowSize();

    storyboard.repeaterModel = [];
    storyboard.boardProps = {};
    storyboard.showHelpPanel = false;
    storyboard.learnMoreContent = getLearnMoreContent();
    storyboard.isRowFull = {};
    storyboard.isModeExport = UrlFlipper.isRenderMode("export");
    storyboard.showExportFilters = UrlFlipper.isEnabled("displayFilters");

    storyboard.sortableOptions = {
      connectWith: ".sortable",
      disabled: !storyboard.editMode,
      helper: "clone",
      placeholder: "storyboard-board storyboard-board-placeholder",
      revert: true,
      tolerance: "pointer",
      cancel:
        "input,button,.ql-format-group,.ql-editor, ql-formats,.quill-contents,.ql-container,.data-grid__container .manualColumnResizer",
      start: (event, ui) => {
        $(ui.helper).css("width", "");
      },
      stop: (event, ui) => {
        if (!storyboard.sortableModel) return;

        const boardId = ui.item.attr("data-board-id");
        let newPosition;

        storyboard.sortableModel.forEach((row, r) =>
          row.boards.forEach((board, c) => {
            if (board.id === boardId) {
              newPosition = { row: r, col: c };
            }
          })
        );

        if (newPosition) {
          BoardOrganizer.moveBoard(boardId, newPosition);
        } else {
          updateRowOfClass(ui.item.closest("ul.sortable"));
        }

        storyboard.onBoardMove();
        UsageTracker.createEvent(".move");
      },
      receive: (event, ui) => {
        if (isRowFull(getRowIndex(event.target))) {
          cancelSortable(ui);
          forceRepeaterRedraw(ui.item);
          updateRowOfClass(ui.sender);
          updateSortableModel();
        }
      },
      over: (event, ui) => {
        let receiver = $(event.target);
        let receiverRow = getRowIndex(receiver);
        let receiverCount = BoardOrganizer.numBoardsInRow(receiverRow);
        let sender = ui.sender ? $(ui.sender) : receiver;
        let senderRow = getRowIndex(sender);
        let senderCount = BoardOrganizer.numBoardsInRow(senderRow) - 1;
        let helper = $(ui.helper);
        let isSender = receiverRow === senderRow;
        let newCount = Math.min(maxBoardsPerRow, receiverCount + 1);

        if (isSender && prevReceiver) {
          let prevReceiverRow = prevReceiver.data("row-index");
          let prevReceiverCount = BoardOrganizer.numBoardsInRow(prevReceiverRow);
          setRowOfClass(prevReceiver, prevReceiverCount);
        }

        if (isSender) {
          setRowOfClass(receiver, receiverCount);
        } else {
          setRowOfClass(sender, senderCount);
          setRowOfClass(receiver, newCount);
        }
        setRowOfClass(helper, newCount);

        if (!isSender && isRowFull(receiverRow)) {
          hidePlaceholder();
          showRowFull(receiver);
        } else {
          showPlaceholder();
          hideRowFull(receiver);
        }
      },
      out: function(event) {
        prevReceiver = $(event.target);
        updateRowOfClass(prevReceiver);
        hideRowFull(prevReceiver);
      },
    };

    storyboard.$onChanges = function(changesObj) {
      if (storyboard.storyboardModel) {
        storyboard.showHelpPanel = !storyboard.storyboardModel.getId();

        if (storyboard.name !== storyboard.storyboardModel.getName()) {
          storyboard.name = storyboard.storyboardModel.getName();
        }
        storyboard.exportGlobalFilters = storyboard.storyboardModel.toExportGlobalFilters();
      }

      if (changesObj.editMode) {
        if (changesObj.editMode.currentValue) {
          StoryboardDataStore.fetchBoardSelectorData().catch(() => {
            boardSelectorHasError = true;
          });
        }
        enableSortable(changesObj.editMode.currentValue);
      }

      if (changesObj.boardsRow && changesObj.boardsRow.currentValue) {
        let boardsRow = changesObj.boardsRow.currentValue;
        storyboard.repeaterModel = boardsRow;
        storyboard.sortableModel = angular.copy(boardsRow);
        storyboard.isRowFull = boardsRow.reduce((isRowFullObj, currRow, currRowIndex) => {
          isRowFullObj[currRowIndex] = BoardOrganizer.isRowFull(currRowIndex);
          return isRowFullObj;
        }, {});
        updateRowStyle();
      }
    };

    storyboard.closeHelp = function() {
      storyboard.showHelpPanel = false;
    };

    storyboard.setStoryboardName = function() {
      if (storyboard.name !== storyboard.storyboardModel.getName()) {
        storyboard.storyboardModel.setName(storyboard.name);
      }
    };

    storyboard.openMetric = function(board) {
      storyboard.storyboardModel.openMetric(board);
    };

    storyboard.openTrigger = function(board) {
      storyboard.storyboardModel.openTrigger(board);
    };

    storyboard.onResize = function(rowIndex, rowHeight) {
      storyboard.storyboardModel.setRowHeight(rowIndex, rowHeight);
      updateRowStyle();
    };

    storyboard.onMouseEnterResize = function(event) {
      $(event.target)
        .closest(".storyboard-row")
        .addClass("is-active");
    };

    storyboard.onMouseLeaveResize = function(event) {
      $(event.target)
        .closest(".storyboard-row")
        .removeClass("is-active");
    };

    storyboard.handleLoadMoredata = function(board) {
      storyboard.onLoadMoreData({ board: board });
    };

    storyboard.getHorizontalSelectorProps = function(row, id) {
      const metricsMap = StoryboardDataStore.getMetricsMap();
      const interpretationsMap = StoryboardDataStore.getInterpretationsMap();
      return {
        id: id,
        hasDataError: boardSelectorHasError,
        positionToAdd: { row },
        projectInterpretations: getValidatedInterpretationsMap(interpretationsMap),
        projectMetrics: metricsMap,
        onAddTextBoard: addTextBoardToRow,
        onFanClose: onBoardSelectorClose,
        onFanOpen: onBoardSelectorOpen,
        onSelectInterpretation: addTableBoardToRow,
        onSelectMetric: addMetricBoardToRow,
        onSelectTrigger: addTriggerBoardToRow,
        onSelectVisualization: addBoardToRow,
        selectorOrientation: "horizontal",
        listOfBoards: BoardOrganizer.getBoards(), //facing problem when trying to pass results from a function, Hence using it directly
      };
    };

    let boardSelectorHasError = false;

    storyboard.getRowSelectorProps = function(row, col, id) {
      const metricsMap = StoryboardDataStore.getMetricsMap();
      const interpretationsMap = StoryboardDataStore.getInterpretationsMap();
      return {
        id: id,
        hasDataError: boardSelectorHasError,
        positionToAdd: { row, col },
        projectInterpretations: getValidatedInterpretationsMap(interpretationsMap),
        projectMetrics: metricsMap,
        onAddTextBoard: addTextBoardToPosition,
        onFanClose: onBoardSelectorClose,
        onFanOpen: onBoardSelectorOpen,
        onSelectInterpretation: addTableBoardToPosition,
        onSelectMetric: addMetricBoardToPosition,
        onSelectTrigger: addTriggerBoardToPosition,
        onSelectVisualization: addBoardToPosition,
        selectorOrientation: "vertical",
        listOfBoards: BoardOrganizer.getBoards(),
      };
    };

    let getValidatedInterpretationsMap = InterpretationValidator.validatedInterpretation;

    var hoverTimeoutMap = new Map();
    var openBoardSelectorsMap = new Map();
    var hoveredBoardSelectorsMap = new Map();

    function onBoardSelectorOpen(id) {
      openBoardSelectorsMap.set(id, true);
    }

    function onBoardSelectorClose(id) {
      $timeout(() => {
        openBoardSelectorsMap.delete(id);
        hoveredBoardSelectorsMap.delete(id);
      });
    }

    storyboard.showBoardSelector = function(id) {
      return openBoardSelectorsMap.get(id) || hoveredBoardSelectorsMap.get(id);
    };

    storyboard.boardSelectorEnter = function(event, id) {
      $timeout.cancel(hoverTimeoutMap.get(id));
      hoveredBoardSelectorsMap.set(id, true);
    };

    storyboard.boardSelectorLeave = function(event, id) {
      hoverTimeoutMap.set(
        id,
        $timeout(() => {
          hoveredBoardSelectorsMap.delete(id);
        })
      );
    };

    function enableSortable(enable) {
      storyboard.sortableOptions.disabled = !enable;
    }

    function forceRepeaterRedraw() {
      storyboard.repeaterModel = angular.copy(storyboard.repeaterModel);
    }

    function newVisualizationBoard(interpretation, visualizationId) {
      UsageTracker.createEvent(".add.visualization");
      return {
        type: "visualization",
        controlTestId: interpretation.control_test_id,
        tableId: interpretation.data_table_id,
        interpretation_id: interpretation.id,
        visualization_id: visualizationId,
      };
    }

    function newTableBoard(interpretation) {
      UsageTracker.createEvent(".add.table");
      return {
        type: "table",
        interpretation_id: interpretation.id,
        controlTestId: interpretation.control_test_id,
        tableId: interpretation.data_table_id,
      };
    }

    function newMetricBoard(metric) {
      UsageTracker.createEvent(".add.metric");
      return {
        type: "metric",
        projectId: metric.project_id,
        controlId: metric.control_id,
        controlTestId: metric.control_test_id,
        tableId: metric.data_table_id,
        metricId: metric.id,
      };
    }

    function newTriggerBoard(metric, conditionGroup) {
      UsageTracker.createEvent(".add.trigger");
      return {
        type: "trigger",
        projectId: metric.project_id,
        controlId: metric.control_id,
        controlTestId: metric.control_test_id,
        tableId: metric.data_table_id,
        metricId: metric.id,
        conditionGroupId: conditionGroup.id,
        triggerId: conditionGroup.trigger.id,
      };
    }

    function newTextBoard() {
      UsageTracker.createEvent(".add.text");
      return {
        type: "text",
        title: "",
        text: "",
      };
    }

    function addBoardToRow(interpretation, visualizationId, position) {
      var vizBoard = newVisualizationBoard(interpretation, visualizationId);
      var row = position && position.row !== undefined ? position.row + 1 : 0;
      storyboard.onAddBoardToRow({ board: vizBoard, row });
    }

    function addTableBoardToRow(interpretation, position) {
      var tableBoard = newTableBoard(interpretation);
      var row = position && position.row !== undefined ? position.row + 1 : 0;
      storyboard.onAddBoardToRow({ board: tableBoard, row });
    }

    function addMetricBoardToRow(metric, position) {
      var metricBoard = newMetricBoard(metric);
      var row = position && position.row !== undefined ? position.row + 1 : 0;
      storyboard.onAddBoardToRow({ board: metricBoard, row });
    }

    function addTriggerBoardToRow(metric, conditionGroup, position) {
      var triggerBoard = newTriggerBoard(metric, conditionGroup);
      var row = position && position.row !== undefined ? position.row + 1 : 0;
      storyboard.onAddBoardToRow({ board: triggerBoard, row });
    }

    function addTextBoardToRow(position) {
      var textBoard = newTextBoard();
      var row = position && position.row !== undefined ? position.row + 1 : 0;
      storyboard.onAddBoardToRow({ board: textBoard, row });
    }

    function addBoardToPosition(interpretation, visualizationId, position) {
      var vizBoard = newVisualizationBoard(interpretation, visualizationId);
      storyboard.onAddBoardToPosition({ board: vizBoard, position });
    }

    function addTableBoardToPosition(interpretation, position) {
      var tableBoard = newTableBoard(interpretation);
      storyboard.onAddBoardToPosition({ board: tableBoard, position });
    }

    function addMetricBoardToPosition(metric, position) {
      var metricBoard = newMetricBoard(metric);
      storyboard.onAddBoardToPosition({ board: metricBoard, position });
    }

    function addTriggerBoardToPosition(metric, conditionGroup, position) {
      var triggerBoard = newTriggerBoard(metric, conditionGroup);
      storyboard.onAddBoardToPosition({ board: triggerBoard, position });
    }

    function addTextBoardToPosition(position) {
      var textBoard = newTextBoard();
      storyboard.onAddBoardToPosition({ board: textBoard, position });
    }

    storyboard.handleRemoveBoard = board => {
      storyboard.onRemoveBoard({ board });
    };

    storyboard.handleDrilldownNoDataConfirmation = confirm => {
      let that = (storyboard.noDataConfirmationModalProps = {
        bodyText: Localize.getLocalizedString("_Storyboard.Drilldown.NoData.Confirmation.BodyText_"),
        headerText: Localize.getLocalizedString("_Storyboard.Drilldown.NoData.Confirmation.HeaderText_"),
        confirmButtonText: Localize.getLocalizedString("_Storyboard.Drilldown.NoData.Confirmation.ConfirmButton_"),
        openModal: true,
        confirmButtonType: "primary",
        onConfirm: () => {
          that.openModal = false;
          confirm();
        },
        onCancel: () => {
          that.openModal = false;
        },
      });
    };

    function updateSortableModel() {
      storyboard.sortableModel = BoardOrganizer.getBoardRows();
    }

    function updateRowOfClass(rowElem) {
      var senderRow = $(rowElem).data("row-index");
      setRowOfClass(rowElem, BoardOrganizer.numBoardsInRow(senderRow));
    }

    function setRowOfClass(rowElem, n) {
      $(rowElem).removeClass("row-of-0 row-of-1 row-of-2 row-of-3");
      if (n !== undefined && n !== null) {
        $(rowElem).addClass("row-of-" + n);
      }
    }

    function showPlaceholder() {
      $($element)
        .find(".storyboard-board-placeholder")
        .show();
    }

    function hidePlaceholder() {
      $($element)
        .find(".storyboard-board-placeholder")
        .hide();
    }

    function showRowFull(rowElem) {
      $(rowElem)
        .siblings(".storyboard-row-full")
        .show();
    }

    function hideRowFull(rowElem) {
      $(rowElem)
        .siblings(".storyboard-row-full")
        .hide();
    }

    function updateRowStyle() {
      storyboard.rowStyle = RowConfigManager.getRowConfigs();
    }

    function cancelSortable(ui) {
      ui.item.sortable.cancel();
    }

    function getRowIndex(row) {
      return $(row).data("row-index");
    }

    function isRowFull(rowIndex) {
      return BoardOrganizer.isRowFull(rowIndex);
    }

    function getLearnMoreContent() {
      const contentLocalizeKey = isHighbond() ? "_Storyboards.Help.LearnMore_" : "_Storyboards.Help.LearnMore.ACL_";
      return Localize.getLocalizedString(contentLocalizeKey);
    }
  }

  function isHighbond() {
    return window.AclVisualizerFeatureToggles && window.AclVisualizerFeatureToggles.galvanizeHighbond;
  }

  function getStoryboardHelpTemplate() {
    return `
      <div class="storyboard-help-wrapper clearfix" ng-show="storyboard.showHelpPanel">
        <div class="storyboard-help large-6 columns large-centered clearfix">
          <h2 class="storyboard-help-title">{{'_Storyboards.Help.Intro_' | aclLocalize}}</h2>
          <h2 class="storyboard-help-title" ng-bind-html="storyboard.learnMoreContent | aclTrustAsHtml"></h2>
          <div class="storyboard-help-example small-one-half column" ng-if="false">
            <img src="/assets/img-storyboard-help-video.png" ></img> <p><em>{{'_Storyboards.Help.30sDemo_' | aclLocalize}}</em></p> </div>
          <div class="storyboard-help-example small-one-half column" ng-if="false">
            <img src="/assets/img-storyboard-help-example.png"></img> <p><em>{{'_Storyboards.Help.ExampleStoryboard_' | aclLocalize}}</em></p> </div>
        </div>
        <a class="storyboard-icon storyboard-help-close" ng-click="storyboard.closeHelp()">
          <i class="acl-i-times-circle"></i>
        </a>
      </div>
    `;
  }

  function getStoryboardTitleTemplate() {
    return `
      <div class="storyboard-title">
        <input
          ng-show="storyboard.editMode"
          id="storyboard-name"
          placeholder="{{'_Storyboards.Name.Placeholder_' | aclLocalize}}"
          ng-change="storyboard.setStoryboardName()"
          ng-model="storyboard.name"
          maxlength="80"/>
        <h1 ng-show="!storyboard.editMode">{{storyboard.name}}</h1>
      </div>`;
  }

  function getRowBoardSelectorTemplate() {
    return `
      <div class="row-add-board left" ng-if="storyboard.editMode && !storyboard.isRowFull[$index]">
        <div class="board-selector-placeholder" ng-if="!storyboard.showBoardSelector('row-' + $index + '-left')">
          <div class="interpretation-selector js-fan-button"
              ng-mouseenter="storyboard.boardSelectorEnter($event, 'row-' + $index + '-left')"
              ng-mouseleave="storyboard.boardSelectorLeave($event, 'row-' + $index + '-left')">
            <span></span>
          </div>
        </div>
        <react-component
          name="BoardSelector"
          ng-if="storyboard.showBoardSelector('row-' + $index + '-left')"
          ng-mouseenter="storyboard.boardSelectorEnter($event, 'row-' + $index + '-left')"
          ng-mouseleave="storyboard.boardSelectorLeave($event, 'row-' + $index + '-left')"
          props="storyboard.getRowSelectorProps($index, 0, 'row-' + $index + '-left')"></react-component>
      </div>

      <div class="row-add-board right" ng-if="storyboard.editMode && !storyboard.isRowFull[$index]">
        <div class="board-selector-placeholder" ng-if="!storyboard.showBoardSelector('row-' + $index + '-right')">
          <div class="interpretation-selector js-fan-button"
              ng-mouseenter="storyboard.boardSelectorEnter($event, 'row-' + $index + '-right')"
              ng-mouseleave="storyboard.boardSelectorLeave($event, 'row-' + $index + '-right')">
            <span></span>
          </div>
        </div>
        <react-component
          name="BoardSelector"
          ng-if="storyboard.showBoardSelector('row-' + $index + '-right')"
          ng-mouseenter="storyboard.boardSelectorEnter($event, 'row-' + $index + '-right')"
          ng-mouseleave="storyboard.boardSelectorLeave($event, 'row-' + $index + '-right')"
          props="storyboard.getRowSelectorProps($index, row.boards.length, 'row-' + $index + '-right')"></react-component>
      </div>`;
  }

  function getStoryboardRowsTemplate() {
    return (
      `
      <div class="storyboard-row-full" ng-style="storyboard.rowStyle[$index]"></div>
      <ul
          ui-sortable="storyboard.sortableOptions"
          ng-model="storyboard.sortableModel[$index].boards"
          class="sortable row-of-{{row.boards.length}}"
          data-row-index="{{$index}}"
          ng-style="storyboard.rowStyle[$index]">` +
      getStoryboardBoardTemplate() +
      `</ul>`
    );
  }

  function getStoryboardBoardTemplate() {
    return `
      <li
          class="storyboard-board" tabindex="0"
          ng-repeat="board in row.boards track by board.id"
          data-board-id="{{board.id}}">
        <acl-storyboard-board
          on-drilldown-no-data-confirmation="storyboard.handleDrilldownNoDataConfirmation(confirm)"
          storyboard-model="storyboard.storyboardModel"
          board-model="storyboard.boardModels[board.id]"
          board="board"
          can-drilldown="storyboard.boardProps[board.id].canDrilldown"
          edit-mode="storyboard.editMode"
          global-filters="storyboard.globalFilters"
          on-remove-board="storyboard.handleRemoveBoard(board)"
          on-load-more-data="storyboard.handleLoadMoredata(board)"></acl-storyboard-board>
      </li>
    `;
  }

  function getResizeHandleTemplate() {
    return `
      <div ng-show="storyboard.editMode" class="ui-resizable-handle ui-resizable-s">
        <i id="sgrip"
          class="handle-grip acl-i-rearrange-y"
          ng-mouseenter="storyboard.onMouseEnterResize($event)"
          ng-mouseleave="storyboard.onMouseLeaveResize($event)">
        </i>
      </div>`;
  }

  function getInsertRowBoardSelectorTemplate() {
    return `
      <div ng-if="storyboard.editMode" class="storyboard-rows__interpretation-selector">
        <div class="interpretation-selector-wrapper selector-horiz" ng-if="!storyboard.showBoardSelector('row-' + $index)">
          <div class="interpretation-selector js-fan-button"
              ng-mouseenter="storyboard.boardSelectorEnter($event, 'row-' + $index)"
              ng-mouseleave="storyboard.boardSelectorLeave($event, 'row-' + $index)">
            <span></span>
          </div>
        </div>
        <react-component
            name="BoardSelector"
            ng-if="storyboard.showBoardSelector('row-' + $index)"
            ng-mouseenter="storyboard.boardSelectorEnter($event, 'row-' + $index)"
            ng-mouseenter="storyboard.boardSelectorLeave($event, 'row-' + $index)"
            props="storyboard.getHorizontalSelectorProps($index, 'row-' + $index)"></react-component>
      </div>`;
  }

  function getStoryboardContentTemplate() {
    return (
      `
      <div class="storyboard-rows-wrapper">
        <div class="storyboard-row-wrapper"
          ng-repeat="row in storyboard.repeaterModel track by row.id">
          <div
            class="storyboard-row storyboard-row-{{$index}}"
            board-row-resizable on-resize="storyboard.onResize">` +
      getRowBoardSelectorTemplate() +
      getStoryboardRowsTemplate() +
      getResizeHandleTemplate() +
      getInsertRowBoardSelectorTemplate() +
      `</div>
        </div>

        <div ng-if="storyboard.editMode && storyboard.repeaterModel.length === 0">
          <react-component name="BoardSelector" props="storyboard.getHorizontalSelectorProps()"></react-component>
        </div>
      </div>`
    );
  }

  function getNoDataDrilldownConfirmationModal() {
    return `
    <react-component name="ConfirmationModal" props="storyboard.noDataConfirmationModalProps"></react-component>
    `;
  }

  function getGlobalFilterExportTemplate() {
    return `<div ng-if="storyboard.isModeExport && storyboard.showExportFilters" class="global-filter-export">
              <div class="global-filter-icon"><span class="aclui-icon acl-i-filter"></span> {{'_Export.Header.FiltersApplied_' | aclLocalize}}</div>
              <div ng-repeat="filter in storyboard.exportGlobalFilters" class="filter-details">
                <span class="filter-name">{{filter.name}}</span>
                <span class="filter-operator">{{filter.operator}}</span>
                <span ng-repeat="value in filter.value track by $index" class="filter-value-wrapper">{{value}}</span>
              </div>
            </div>`;
  }

  function getHomepageExportTemplate() {
    return `<div ng-if="storyboard.isModeExport" class="export-home-page">
              <div class="align-page-center">
                <!-- <div><img onerror="this.style.display='none'" src="{{storyboard.organization.logo}}"></div> -->
                <div class="export-storyboard-title"><h1>{{storyboard.name}}</h1></div>
                <div class="export-org">{{storyboard.organization.name}}</div>
                <div id="export-date" class="export-date"></div>
              </div>
            </div>`;
  }

  function getTemplate() {
    return (
      `
      <div class="storyboard" ng-class="{'edit-mode': storyboard.editMode}">` +
      getNoDataDrilldownConfirmationModal() +
      getStoryboardHelpTemplate() +
      getStoryboardTitleTemplate() +
      getHomepageExportTemplate() +
      getGlobalFilterExportTemplate() +
      getStoryboardContentTemplate() +
      `</div>`
    );
  }

  angular.module("acl.storyboard.storyboard").component("aclStoryboard", aclStoryboard);
})();
