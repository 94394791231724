import React from "react";
import PropTypes from "prop-types";
import Modal from "@paprika/modal";
import i18n from "@viz-ui/i18n/i18n";
import DownloadXlsBody from "./DownloadXlsBody";
import "./DownloadXlsModal.scss";

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  isOpen: false,
};

export default function DownloadXlsModal(props) {
  const { isOpen, onClose } = props;
  const refHeading = React.useRef(null);
  const modalTitle = i18n.t("_ActionButtons.ExportXls.Label_");
  const handleFocus = () => {
    refHeading.current.focus();
  };

  return (
    <Modal
      a11yText={modalTitle}
      className="download-xls-modal"
      isOpen={isOpen}
      onClose={onClose}
      onAfterOpen={handleFocus}
      size="small"
      zIndex={220}
    >
      <Modal.Header refHeading={refHeading} level={2}>
        {modalTitle}
      </Modal.Header>
      <Modal.Content>
        <DownloadXlsBody />
      </Modal.Content>
    </Modal>
  );
}

DownloadXlsModal.propTypes = propTypes;
DownloadXlsModal.defaultProps = defaultProps;
