angular
  .module("acl.common.controllers", ["acl.common.sessionManager"])
  .controller("VisualizerAppController", function($scope, $location, AppConfig, EventService, $modal) {
    var eventService = EventService.register("app.Interceptor", $scope);
    $scope.$on("$destroy", function() {
      eventService.unregister();
    });

    eventService.subscribe("session.expired", function(event) {
      $modal.open({
        templateUrl: "visualizer/views/sessionExpiredModal.html",
        controller: "SessionController",
        windowClass: "modal--session-timeout",
      });
    });
  });
