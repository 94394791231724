import isEqual from "lodash/isEqual";
import i18n from "@viz-ui/i18n/i18n";
import { isRowChecked, getRowDataId } from "./rowSelectionHelper";
import GlobalValueFormatter from "../../services/formatters/globalValueFormatter";
import logger from "../../services/logger/logger";

const blankKey = "(blank)";

function transformHotData(props, rowSelection) {
  const { data, fields, fieldFormatIdentifier, skipCellValueFormatting, skipFormattingOnCells } = props;
  logger.log(`${fieldFormatIdentifier} - HoT data transformer`);
  logger.log(`${fieldFormatIdentifier} - fields length: ${fields.length}`);
  logger.log(`${fieldFormatIdentifier} - data length: ${data.length}`);
  const fieldModels = fields;
  let transformedData = data.map((rowData, rowIndex) => {
    let transformedRow = Object.assign({}, rowData);

    transformedRow = Object.assign(
      transformedRow,
      getRowSelectionValue(props, rowSelection.getCheckedRowIds(), rowData)
    );
    transformedRow = Object.assign(transformedRow, getRowCommentValue(props, rowData));
    fieldModels.forEach(fieldModel => {
      transformedRow = Object.assign(
        transformedRow,
        getRowFieldValue(
          rowData,
          rowIndex,
          fieldModel,
          fieldFormatIdentifier,
          skipCellValueFormatting,
          skipFormattingOnCells
        )
      );
    });

    return transformedRow;
  });

  transformedData = getFilteredRows(props, transformedData);

  logger.log(`${fieldFormatIdentifier} - transformedData length: ${transformedData.length}`);
  return transformedData;
}

function shouldTransformData(currProps, nextProps, rowSelection) {
  return (
    currProps.fields !== nextProps.fields ||
    currProps.fieldFormatIdentifier !== nextProps.fieldFormatIdentifier ||
    currProps.data !== nextProps.data ||
    currProps.showCheckedRowsOnly !== nextProps.showCheckedRowsOnly ||
    currProps.commentCounts !== nextProps.commentCounts ||
    !isEqual(currProps.checkedRowIds, rowSelection.getCheckedRowIds())
  );
}

function shouldUpdateTable(currProps, nextProps, rowSelection) {
  return (
    shouldTransformData(currProps, nextProps, rowSelection) ||
    !isEqual(currProps.columnConfigs, nextProps.columnConfigs)
  );
}

function getRowSelectionValue(props, checkedRowIds, rowData) {
  return {
    __checked: isRowChecked(props, checkedRowIds, rowData),
  };
}

function getRowCommentValue(props, rowData) {
  return {
    __comment: getCommentIconClassAndDescription(props, rowData),
  };
}

function getRowFieldValue(
  rowData,
  rowIndex,
  fieldModel,
  fieldFormatIdentifier,
  skipCellValueFormatting,
  skipFormattingOnCells
) {
  const fieldName = fieldModel.name();
  const fieldType = fieldModel.type();
  const fieldValueObj = {};
  fieldValueObj[fieldName] = {};
  if (Object.hasOwnProperty.call(rowData, fieldName)) {
    fieldValueObj[fieldName] = Object.assign(fieldValueObj[fieldName], rowData[fieldName]);
    let fieldValue = fieldValueObj[fieldName].value;
    const shouldSkipFormattingOnCell =
      isLogicalBlankValue(fieldValueObj, fieldName, fieldType) ||
      isValueBlank(fieldValue) ||
      getShouldSkipFormattingOnCell(rowIndex, fieldName, skipFormattingOnCells);
    if (isFieldTypeDate(fieldType) && isDateValueInvalid(fieldValue)) {
      fieldValue = "Invalid date";
    }
    if (!skipCellValueFormatting && !shouldSkipFormattingOnCell) {
      fieldValueObj[fieldName].formattedValue = GlobalValueFormatter.formatValue(
        fieldValue,
        fieldFormatIdentifier,
        fieldName
      );
    }
  } else {
    fieldValueObj[fieldName].value = null;
  }

  fieldValueObj[fieldName].toString = function() {
    return this.formattedValue || this.value;
  };

  return fieldValueObj;
}

function getShouldSkipFormattingOnCell(rowIndex, fieldName, skipFormattingOnCells) {
  return skipFormattingOnCells.findIndex(cellToSkip => isEqual(cellToSkip, { rowIndex, fieldName })) > -1;
}

function isValueBlank(value) {
  return value === blankKey;
}

function isDateValueInvalid(value) {
  let valueArr = value && value.split("-");
  if (valueArr && valueArr.length == 1) valueArr = value.split("/");
  return value && (valueArr.length <= 2 || valueArr.filter(val => isNaN(parseInt(val))).length > 1) ? true : false;
}

function isFieldTypeDate(type) {
  return type === "date" || type === "D" || type === "dateTime" || type === "datetime" || type === "DT";
}

function isLogicalBlankValue(fieldValueObj, fieldName, type) {
  return type === "logical" && !fieldValueObj[fieldName].value;
}

function getFilteredRows(props, data) {
  if (props.showCheckedRowsOnly && props.isRowSelectionEnabled) {
    return data.filter(rowData => rowData.__checked);
  }
  return data;
}

function getCommentIconClassAndDescription(props, rowData) {
  const { commentCounts, rowIdKey } = props;
  const rowDataId = getRowDataId(rowData, rowIdKey);
  if (commentCounts && rowDataId) {
    const comment = commentCounts.find(item => item.id === rowDataId);
    if (comment) {
      const attachmentCount = comment.attachments_count;
      const commentCount = comment.comments_count;

      if (commentCount > 0 && attachmentCount > 0) {
        return {
          iconClass: "acl-i-comment-attachment",
          description: i18n.t("_DataGrid.HasCommentsAndAttachments_"),
        };
      }
      if (commentCount > 0) {
        return {
          iconClass: "acl-i-comment",
          description: i18n.t("_DataGrid.HasComments_"),
        };
      }
    }
  }

  return { iconClass: "", description: "" };
}

export { transformHotData, shouldTransformData, shouldUpdateTable, isDateValueInvalid };
