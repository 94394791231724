import usageTiming from "./usageTiming";

class UsageTracker {
  static listeners = [];
  static appName = "";

  static addListener(listener) {
    this.listeners.push(listener);
  }

  static createEvent(name, detail, duration) {
    if (detail && typeof detail !== "object") {
      console.error(`${name} event details should be tracked as an object`);
    }

    this.listeners.map(listener => setTimeout(() => listener.onEvent(name, detail, duration)));
  }

  static mark(markerName) {
    usageTiming.mark(markerName);
  }

  static setTrackerEventList(eventList) {
    usageTiming.setEventList(eventList);
  }

  static setAppName(appName) {
    this.appName = appName;
  }
}

export default UsageTracker;
