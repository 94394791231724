import FieldFormatter from "@viz-ui/services/formatters/fieldFormatter";

(() => {
  var numericFormatOptions = {
    restrict: "E",
    bindings: {
      fieldFormat: "<",
      fieldName: "<",
      onFieldFormatChange: "&",
    },
    controllerAs: "numericOptions",
    controller: NumericFormatOptionsController,
    templateUrl: "visualizer/js/modules/core/fieldFormatOptions/numericFormatOptions.tpl.html",
  };

  function NumericFormatOptionsController(FieldFormat) {
    "ngInject";
    var numericOptions = this;

    numericOptions.roundingOptionsEnabled = true;

    numericOptions.$onInit = function() {
      setupOptions();
    };

    numericOptions.$onChanges = function(changes) {
      if (changes.fieldFormat) {
        setupOptions();
      }
    };

    numericOptions.handleFormatClick = function(format) {
      let fieldFormat;
      if (numericOptions.fieldFormat) {
        fieldFormat = numericOptions.fieldFormat.clone().assign(format);
      } else {
        fieldFormat = new FieldFormat(format);
      }

      //Auto selecting 'No Decimals' rounding option when user selects 'None' formatting option.
      if (fieldFormat._data.hasOnlyRadixSeparator) {
        fieldFormat._data.precision = 0;
        fieldFormat._data.abbreviate = false;
        fieldFormat._data.keepTrailingZeros = false;
      }

      numericOptions.onFieldFormatChange({ fieldFormat: fieldFormat });
    };

    numericOptions.handleRoundingClick = function(format) {
      let fieldFormat;
      if (numericOptions.fieldFormat) {
        fieldFormat = numericOptions.fieldFormat.clone().assign(format);
      } else {
        fieldFormat = new FieldFormat(format);
      }
      numericOptions.onFieldFormatChange({ fieldFormat: fieldFormat });
    };

    function setupOptions() {
      numericOptions.formatOptions = getAvailableNumericFormattingOptions();
      numericOptions.roundingOptions = getAvailableNumericRoundingOptions();
      numericOptions.roundingOptionsEnabled = !selectedFormattingOption().isRaw;
    }

    function getAvailableNumericFormattingOptions() {
      var heading = FieldFormatter.getNumericFormattingHeading();
      var options = FieldFormatter.getNumericFormattingOptions(selectedFormattingOption(), selectedRoundingOption());
      return [heading].concat(options);
    }

    function getAvailableNumericRoundingOptions() {
      var heading = FieldFormatter.getNumericRoundingHeading();
      var options = FieldFormatter.getNumericRoundingOptions(selectedRoundingOption(), selectedFormattingOption());
      return [heading].concat(options);
    }

    function selectedFormattingOption() {
      return numericOptions.fieldFormat
        ? {
            hasOnlyRadixSeparator: numericOptions.fieldFormat.hasOnlyRadixSeparator(),
            thousandsDelimiter: numericOptions.fieldFormat.thousandsDelimiter(),
            prefix: numericOptions.fieldFormat.prefix(),
            postfix: numericOptions.fieldFormat.postfix(),
            sign: numericOptions.fieldFormat.sign(),
          }
        : {};
    }

    function selectedRoundingOption() {
      return numericOptions.fieldFormat
        ? {
            abbreviate: numericOptions.fieldFormat.abbreviate(),
            keepTrailingZeros: numericOptions.fieldFormat.keepTrailingZeros(),
            precision: numericOptions.fieldFormat.precision(),
          }
        : {};
    }
  }

  angular.module("acl.visualizer.fieldFormatOptions").component("numericFormatOptions", numericFormatOptions);
})();
